<template>
    <div>
        <div class="fullwidth height240px flexbox border2pxgray paddingtop5 padleft5px padright5px">
            <div class="flexbox flexcontent flex-direction-column overflow-auto scrollbar-width">
                <div v-for="(item, i) in tablearray" :key="i" class="flexbox border2pxgray marginb5">
                    <span class="width150px flexcontent flex-shrink-zero overflow-hidden">
                        <div class="tabletexttop tablewordbreak table-cell-padding2 table-empty-block">
                            <el-table :data="[]" :header-cell-class-name="headerrowstyle" :border="true">
                                <el-table-column align="center" width="34">
                                    <template slot="header" slot-scope="{}">
                                        <i :class="item.isopened ? 'el-icon-arrow-down' : 'el-icon-arrow-right'"></i>
                                    </template>
                                </el-table-column>
                                <el-table-column :label="item.name" width="115" />
                            </el-table>
                        </div>
                    </span>

                    <span class="flex-grow-3 overflow-auto">
                        <div class="tabletexttop tablewordbreak table-cell-padding2 table-empty-block">
                            <el-table :data="item.tabledata" :border="true"
                                :header-cell-class-name="secondtableheadercellclass" :cell-class-name="tablecellcolor">
                                <el-table-column prop="alpha" align="center" width="45">
                                    <template slot="header">
                                        <div>
                                            <svg xmlns="http://www.w3.org/2000/svg"
                                                class="flexbox width-37px height-21px" viewBox="0 0 24 24">
                                                <path fill="#ffffff"
                                                    d="M18.08 17.8c-.46.13-.87.2-1.23.2c-1.2 0-2.01-.88-2.42-2.65h-.05c-.99 1.91-2.38 2.86-4.13 2.86c-1.31 0-2.36-.49-3.15-1.48S5.92 14.5 5.92 13c0-1.75.45-3.15 1.34-4.24s2.1-1.64 3.63-1.64c.82 0 1.56.23 2.2.68c.64.46 1.13 1.1 1.47 1.93h.04l.71-2.4h2.56l-2.14 5.32c.24 1.24.49 2.09.77 2.54c.24.45.58.68 1 .68c.24 0 .43-.04.6-.11zm-4.26-5.24c-.21-1.13-.55-2.01-1.01-2.61c-.45-.61-1-.91-1.63-.91c-.82 0-1.48.37-1.97 1.1c-.49.74-.71 1.65-.71 2.72c0 .98.19 1.79.62 2.45c.42.66.99.98 1.7.98c.6 0 1.15-.29 1.64-.84c.5-.57.91-1.4 1.24-2.49z" />
                                            </svg>
                                        </div>
                                    </template>
                                </el-table-column>

                                <el-table-column label="Project Document Name / Reference" prop="documentname"
                                    width="300" />
                                <el-table-column label="Activity Description Captured" prop="activity"
                                    min-width="300" />
                                <el-table-column label="[ ] Correct," align="center" width="80" />
                                <el-table-column label="[ ] User to Describe" align="center" width="130" />
                                <el-table-column label="" width="5" />
                                <el-table-column label="Yes" align="center" width="45">
                                    <template slot-scope="s">
                                        <el-checkbox
                                            class="checkborderblack checkblack checkbox-bordered-checked height24px padding5px fontsize8"
                                            :border="true" v-model="s.row.checked">
                                        </el-checkbox>
                                    </template>
                                </el-table-column>
                                <el-table-column label="Markup" align="center" width="60" />
                            </el-table>
                        </div>
                    </span>
                </div>
            </div>
            <div class="width30px flex-shrink-zero">
                <span class="floatright">
                    <button @click="closebox(currentrow)">&times;</button>
                </span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: { currentrow: Object },
    data() {
        return {
            // tablearray: [
            //     { tabledata: [], name: "BQ Code" },
            //     { tabledata: [], name: "Specification" },
            //     { tabledata: [], name: "Asterisk" },
            //     { tabledata: [], name: "End Notes" }
            // ],
            tablearray: [
                { tabledata: [], name: "BQ Code", isopened: false },
                {
                    tabledata: [
                        { alpha: "100%", documentname: "Specification 123-35E", activity: "; Cold deck roof coating by Sika; as NBS J31/110;", checked: true, markup: "12968" },
                        { alpha: "72%", documentname: "Specification 200 Ge", activity: "; Cold deck roof coating by Sika;", checked: false, markup: "12968" },
                        { alpha: "11%", documentname: "Plan 12345 (M)", activity: "Painting as specification M.60", checked: false, markup: "96845" },
                        { alpha: "1%", documentname: "Elevation 1234", activity: "Painting as specification M.40", checked: false, markup: "44578" }],
                    name: "Specification", isopened: true
                },
                { tabledata: [{ alpha: "100%", documentname: "Specification 123-35E", activity: "including PMMA self smooting waterproof coating (primer and main coat), Sik Ree", checked: true, markup: "" }], name: "Asterisk", isopened: true },
                { tabledata: [], name: "End Notes", isopened: false },
            ],
        };
    },
    methods: {
        headerrowstyle({ row, column, rowIndex, columnIndex }) {
            if (rowIndex === 0) {
                return 'background-color-class5 whitecolor';
            }
        },

        secondtableheadercellclass({ row, column, rowIndex, columnIndex }) {
            if (rowIndex === 0) {
                if (columnIndex == 0 || columnIndex == 6 || columnIndex == 7) {
                    return 'background-color-class1 whitecolor';
                }
                else if (columnIndex == 1) {
                    return 'background-color-class2 whitecolor';
                }
                else if (columnIndex == 2 || columnIndex == 3 || columnIndex == 4) {
                    return 'background-color-class3 blackcolor';
                }
            }
        },

        tablecellcolor({ columnIndex }) {
            if (columnIndex == 0) {
                return 'background-color-class4 blackcolor';
            }
        },

        closebox(currentrow) {
            this.$emit("closebox", currentrow);
        },
    },
};
</script>