import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui'
import locale from 'element-ui/lib/locale/lang/en'
import 'element-ui/lib/theme-chalk/index.css';
import { ElementTiptapPlugin } from 'element-tiptap';
import 'element-tiptap/lib/index.css';
import callhub from "./callhub";
import axios from 'axios'
import router from './router'
import store from './store'
import { sync } from 'vuex-router-sync'
import './css/site.css'
import '@neos21/bootstrap3-glyphicons/dist/css/bootstrap3-glyphicons.min.css';
import training from "./classes/training";
import directionutility from "./classes/directionutility";
import LoadingHelper from "./components/loadinghelper.vue"


import EventBus from './eventbus'

sync(store, router)


Vue.use(ElementUI, { locale, size:'mini' , zIndex: 3000 })
Vue.use(ElementTiptapPlugin);

Vue.config.productionTip = false

/*Common functions*/

Vue.filter('to2decimal', function (value) {
  if (value) {
      return value.toFixed(2);
  }
});

Vue.filter('to3decimal', function (value) {
  if (value) {
      return value.toFixed(3);
  }
});

Vue.filter('dateformat', function (value) {
  if (value) {
      var dt = new Date(value)
      return dt.toDateString();
  }
});

Vue.filter('datetimeformat', function (value) {
  if (value) {
      var dt = new Date(value)
      return dt.toLocaleString();
  }
});

import { globalmixin } from './mixins'
Vue.mixin(globalmixin);

Vue.use(callhub)

Vue.component('loading-helper', LoadingHelper);

// export const bus = new Vue();
import sortable from "sortablejs";
Vue.prototype.$sortable = sortable;
Vue.prototype.$http = axios;
Vue.prototype.$bus = EventBus;
Vue.prototype.$training= new training();
Vue.prototype.$directionutility = new directionutility();


// new Vue({
//   store: store,
//     router,
//   render: h => h(App),
// }).$mount('#app')

const app = new Vue({
  store,
  router,
  ...App,
})
app.$mount('#app')
