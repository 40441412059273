<template>
    <div class="checksectionwidth">
        <div class="fixdheader">
            <span class="headerstyle">External Check / QA</span>
            <div v-if="display" class="floatright spaceBetween1LR">
                <span>
                    <el-button type="primary" class="marginleft4px" @click="confirmview" :disabled="!editpermission">Add
                        View</el-button>
                </span>
                <span>
                    <el-button type="success" class="marginleft4px" :disabled="!editpermission"
                        v-loading.fullscreen.lock="saving"
                        @click="cellchanged('analysis_external_check_save', () => { save() }, false)">Save</el-button>
                </span>
            </div>

            <el-row>
                <div>
                    <span class="left ">
                        <projectselectpart :lastsavedshow="false" @projectsectionchange="projectsectionchange">
                        </projectselectpart>
                    </span>
                </div>
            </el-row>
            <hr class="customhrstyle" />

            <div v-if="display">
                <span class="widthlabel317">
                    <span>
                        <strong class="padright5px signoffgreen">Section Signed Off By:</strong>
                        <span class="padright5px">
                            <el-checkbox v-model="detailform.issignoff"
                                id="analysis_external_check_section_signed_off_by"
                                @change="signedoffchecked(detailform, null, 'maindetail')"></el-checkbox>
                        </span>
                        <span v-if="detailform.issignoff && detailform.signoff != null">{{ detailform.signoff.loginname
                            }}
                            {{ detailform.signoffon }}</span>
                    </span>
                </span>
                <hr class="spaceBetween groove" />
            </div>
        </div>

        <div v-if="uploadprogress > 0">
            <el-progress :text-inside="true" :stroke-width="24" :percentage="uploadprogress"
                status="success"></el-progress>
            <hr class="hrstyle" />
        </div>

        <div v-if="display">
            <div class="marginb25" v-for="(sc, index) in detailform.viewdetails" :key="index">
                <div>
                    <span>
                        <el-select v-model="sc.view" :clearable="false" placeholder="View"
                            v-bind:style="{ width: '130px' }" @change="changedview(sc, true)"
                            :id="`analysis_external_check_view_${index}`">
                            <el-option v-for="item in views" :key="item.id" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select>
                    </span>
                    <span>
                        <el-select v-model="sc.type" :clearable="false" style="width :70px" placeholder="Type"
                            class="marginleft4px" @change="editedchanged(sc, null, 'parentdetail')"
                            :id="'analysis_external_check_formtype_' + index">
                            <el-option v-for="item in formtypes" :key="item.id" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select>
                    </span>
                    <span class="marginleft4px">
                        <el-button type="danger" icon="el-icon-delete" :disabled="!editpermission" class="padding7"
                            @click="deleteview(sc, index)">
                        </el-button>
                    </span>
                    <span class="inline fontsize12 marginleft4px">
                        <strong class="padleft10px">External Check By: </strong>
                        <span v-if="sc.checker != undefined">{{ sc.checker.loginname }} {{ sc.checkedon }}</span>
                    </span>
                </div>

                <div class="spaceBetween" v-show="sc.isopen">
                    <span class="left">
                        <div class="mergeuploadcontrol">
                            <span class="uploadwidth124 left">
                                <el-upload ref="upload" action="" :drag="true" :show-file-list="false"
                                    class="dragupload uploadiconsize textupload"
                                    :http-request="(event) => { return addAttachment(event, sc, index, 'importedviewimages'); }"
                                    :on-change="() => { return editedchanged(sc, null, 'image'); }"
                                    accept=".png,.jpg,.jpeg,.gif,.mp4,.mp3">

                                    <em class="el-icon-upload"></em>
                                    <div class="el-upload__text">Drop file here or <em>click to upload</em></div>
                                </el-upload>
                            </span>

                            <span class="left">
                                <div @click="pasteimage(sc, index, 'importedviewimages')" class="copycontrol">
                                    <div class="copycontroltext">
                                        <span class="copycontrolfont">Paste the snip from the clipboard</span>
                                    </div>
                                </div>
                            </span>
                        </div>
                    </span>
                    <span class="left">
                        <div v-for="(f, i) in sc.importedviewimages" :key="i">
                            <span class="widthlabel300">
                                <div>
                                    <span class="uploadedfilenames cursorpointer">
                                        <em class="el-icon-document"></em>
                                        <span @click="beforeuploadpreview(f)">{{ f.originalname }}</span>
                                    </span>
                                    <span>
                                        <em class="el-icon-close deletefiles"
                                            @click="deleteAttachment(f.originalname, sc.importedviewimages)"></em>
                                    </span>
                                </div>
                            </span>
                        </div>
                    </span>
                </div>

                <div class="tablecellr2 tabletexttop spaceBetween">
                    <el-table :data="sc.details" :border="true" :header-cell-class-name="tableheadercolor"
                        :header-cell-style="{ 'padding': '2px 0 0 2px' }" :cell-style="tablecells">

                        <el-table-column align="center" label="Ref" width="36">
                            <template slot-scope="scope">
                                <el-input
                                    :class="checkactions(sc, scope.row) ? 'noactionandissues hvrinput' : 'hvrinput'"
                                    v-model="scope.row.reference" @change="editedchanged(sc, scope.row, 'childdetail')"
                                    :id="`analysis_external_check_table_reference_${index}_${scope.$index}`"
                                    onkeypress='return event.charCode >= 48 && event.charCode <= 57'>
                                </el-input>
                            </template>
                        </el-table-column>

                        <el-table-column label="Action / Issue" width="135">
                            <template slot-scope="scope">
                                <el-select :clearable="false"
                                    :class="checkactions(sc, scope.row) ? 'noactionandissues' : 'fullwidth'"
                                    v-model="scope.row.action" placeholder="Action / Issue"
                                    no-data-text="You need to select a View"
                                    @change="changeActionIssue(sc, scope.row, 'childdetail')"
                                    :id="`analysis_external_check_table_actionissue_${index}_${scope.$index}`">
                                    <el-option v-for="item in sc.actions" :key="item.id" :label="item.name"
                                        :value="item.id">
                                    </el-option>
                                </el-select>
                            </template>
                        </el-table-column>

                        <el-table-column label="Nr" align="center" width="36">
                            <template slot-scope="scope">
                                <el-input
                                    :class="checkactions(sc, scope.row) ? 'noactionandissues hvrinput' : 'hvrinput'"
                                    v-model="scope.row.nr" @change="editedchanged(sc, scope.row, 'childdetail')"
                                    onkeypress='return event.charCode >= 48 && event.charCode <= 57'
                                    :id="`analysis_external_check_table_nr_${index}_${scope.$index}`">
                                </el-input>
                            </template>
                        </el-table-column>

                        <el-table-column label="Ck Comment" width="300">
                            <template slot-scope="scope">
                                <el-input type="textarea" maxlength="255" v-model="scope.row.comment1"
                                    :rows="$training.calculaterows(scope.row.comment1, 50)"
                                    :style="{ height: $training.calculateheight(scope.row.comment1, 50) + 'px' }"
                                    @change="editedchanged(sc, scope.row, 'childdetail')"
                                    :id="`analysis_external_check_table_ck_comment_${index}_${scope.$index}`"
                                    :class="'hvrresizetxtarea padd3textarea ' + (checkactions(sc, scope.row) ? 'noactionandissues' : '')">
                                </el-input>
                            </template>
                        </el-table-column>

                        <el-table-column label="Done" align="center" width="40">
                            <template slot-scope="scope">
                                <el-checkbox v-model="scope.row.done1"
                                    :id="`analysis_external_check_table_done_one_${index}_${scope.$index}`"
                                    :class="checkactions(sc, scope.row) ? 'noactions noactionsgray' : 'checkborderblue checkblue'"
                                    @change="editedchanged(sc, scope.row, 'childdetail')">
                                </el-checkbox>
                            </template>
                        </el-table-column>

                        <el-table-column label="Val" align="center" width="65">
                            <template slot-scope="scope">
                                <el-select placeholder="Val" class="fullwidth" :clearable="false"
                                    v-model="scope.row.priority" @change="editedchanged(sc, scope.row, 'childdetail')"
                                    :id="`analysis_external_check_table_priority_${index}_${scope.$index}`">
                                    <el-option v-for="item in priorities" :key="item.id" :label="item.name"
                                        :value="item.id">
                                    </el-option>
                                </el-select>
                            </template>
                        </el-table-column>

                        <el-table-column label="Snip" header-align="center" width="65">
                            <template slot-scope="scope">
                                <div>
                                    <span
                                        v-if="!scope.row.viewdetailmedialist?.length && !scope.row.importedviewdetailimages?.length"
                                        class="flexbox justifycontentcenter">
                                        <el-upload class="custom-upload-with-drag inline" action="" :drag="true"
                                            :show-file-list="false"
                                            :http-request="(event) => { return addAttachment(event, scope.row, index, 'importedviewdetailimages'); }"
                                            accept=".png,.jpg,.jpeg,.gif,.mp4,.mp3">
                                            <el-button type="info" class="padding4px font-size-16"><i
                                                    class="font-size-16 el-icon-upload margin-unset line-height-unset"></i></el-button>
                                        </el-upload>

                                        <el-button type="info" icon="el-icon-document"
                                            class="padding4px marginleft4px font-size-16" :disabled="!editpermission"
                                            @click="pasteimage(scope.row, scope.$index, 'importedviewdetailimages')"></el-button>
                                    </span>

                                    <span v-else-if="scope.row.importedviewdetailimages?.length">
                                        <div v-for="(m2, m2index) in scope.row.importedviewdetailimages" :key="m2index">
                                            <span class="widthlabel300">
                                                <div>
                                                    <span class="uploadedfilenames cursorpointer">
                                                        <em class="el-icon-document"></em>
                                                        <span @click="beforeuploadpreview(m2)">{{ m2.originalname
                                                            }}</span>
                                                    </span>
                                                    <span>
                                                        <em class="el-icon-close deletefiles"
                                                            @click="deleteAttachment(m2.originalname, scope.row.importedviewdetailimages)"></em>
                                                    </span>
                                                </div>
                                            </span>
                                        </div>
                                    </span>

                                    <span v-else>
                                        <span v-for="svdmedia in scope.row.viewdetailmedialist" :key="svdmedia.id">
                                            <dropboxmediaview :type="'dropbox'" :media="svdmedia"
                                                @deletemedia="deletemedia">
                                            </dropboxmediaview>
                                        </span>
                                    </span>
                                </div>
                            </template>
                        </el-table-column>

                        <el-table-column label="Actions" align="center" width="70">
                            <template slot-scope="scope">
                                <el-button type="primary" class="padding7" @click="addRow(scope.row, scope.$index, sc)"
                                    icon="el-icon-circle-plus" :disabled="!editpermission">
                                </el-button>
                                <el-button type="danger" class="padding7"
                                    @click="deleteRow(scope.$index, scope.row, sc)" icon="el-icon-delete"
                                    :disabled="!editpermission">
                                </el-button>
                            </template>
                        </el-table-column>

                        <el-table-column width="15" class-name="emptycolumntable">
                        </el-table-column>

                        <el-table-column label="QA Solution" width="130">
                            <template slot-scope="scope">
                                <el-select :clearable="false" class="fullwidth" placeholder="QA Solution"
                                    v-model="scope.row.solution" @change="editedchanged(sc, scope.row, 'childdetail')"
                                    :id="`analysis_external_check_table_solution_${index}_${scope.$index}`">
                                    <el-option v-for="item in solutions" :key="item.id" :label="item.name"
                                        :value="item.id">
                                    </el-option>
                                </el-select>
                            </template>
                        </el-table-column>

                        <el-table-column label="QA Comment" width="300">
                            <template slot-scope="scope">
                                <el-input type="textarea" maxlength="255" v-model="scope.row.comment2"
                                    class="hvrresizetxtarea padd3textarea"
                                    :rows="$training.calculaterows(scope.row.comment2, 50)"
                                    @change="editedchanged(sc, scope.row, 'childdetail')"
                                    :style="{ height: $training.calculateheight(scope.row.comment2, 50) + 'px' }"
                                    :id="`analysis_external_check_table_qa_comment_${index}_${scope.$index}`">
                                </el-input>
                            </template>
                        </el-table-column>

                        <el-table-column label="Done" align="center" width="40">
                            <template slot-scope="scope">
                                <el-checkbox v-model="scope.row.done2" class="checkborderpurple checkpurple"
                                    :id="`analysis_external_check_table_done_two_${index}_${scope.$index}`"
                                    @change="editedchanged(sc, scope.row, 'childdetail')">
                                </el-checkbox>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>

                <div class="spaceBetween">
                    <span>
                        <el-button type="primary" :disabled="!editpermission" @click="gotobillpage(sc)">Bill
                            View</el-button>
                    </span>
                    <span>
                        <el-button type="primary" class="marginleft4px" :disabled="!editpermission"
                            @click="addSnip(sc)">Add Extra
                            Snip / File</el-button>
                    </span>
                    <span>
                        <span v-for="svmedia in sc.viewmedialist" :key="svmedia.id">
                            <dropboxmediaview :type="'dropbox'" :media="svmedia" @deletemedia="deletemedia">
                            </dropboxmediaview>
                        </span>
                    </span>
                </div>

                <div class="spaceBetween">
                    <span class="widthlabel765">
                        <el-checkbox class="checkborderblue checkblue" :value="sc.maxcorrecter ? true : false">
                        </el-checkbox>
                        <strong class="padright2px correctedblue"> Corrected By: </strong>
                        <span class="padright2px" v-if="sc.maxcorrecter && sc.maxcorrecter.correcter">{{
                            sc.maxcorrecter.correcter.loginname }}</span>
                        <span v-if="sc.maxcorrecter">{{ sc.maxcorrecter.correctionon }}</span>
                    </span>
                    <span class="widthlabel400">
                        <el-checkbox :value="sc.maxprocess ? true : false" class="checkborderpurple checkpurple">
                        </el-checkbox>
                        <strong class="padright2px processpurple"> Process Developed By: </strong>
                        <span class="padright2px" v-if="sc.maxprocess && sc.maxprocess.qacomplete">{{
                            sc.maxprocess.qacomplete.loginname }}</span>
                        <span v-if="sc.maxprocess">{{ sc.maxprocess.qasectionalcompletedon }}</span>
                    </span>
                </div>
            </div>
        </div>
        
        <dropboxmediaview v-if="beforemedia.show" :type="'preview'" :isvisible="beforemedia.show" :media="beforemedia"
            @closemedia="closemedia">
        </dropboxmediaview>
    </div>
</template>
<script>
import projectselectpart from './projectselectpart.vue';
import dropboxmediaview from './dropboxmediaview.vue';
import moment from "moment";
export default
    {
        components: { 'projectselectpart': projectselectpart, 'dropboxmediaview': dropboxmediaview },
        data() {
            return {
                display: false,
                formtypes: [],
                views: [],
                takeoff: [],
                compiling: [],
                billcheck: [],
                instructions: [],
                checkinglists: [],
                allstatus: [],
                rwccqc: [],
                priorities: [],
                solutions: [],
                extensions: [],
                detailform: {
                    viewdetails: []
                },
                sectionid: null,
                uploadprogress: 0,
                saving: false,
                beforemedia: {}
            };
        },

        computed: {
            checkactions() {
                return (data, row) => {
                    let iscompleted = data.actions.find(x => x.id == row.action);
                    if (iscompleted && iscompleted.name == 'No Actions / Issues') {
                        return true;
                    }
                    else {
                        return false;
                    }
                }
            }
        },

        methods: {
            buildFormData(formData, data, parentKey) {
                if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
                    Object.keys(data).forEach(key => {
                        this.buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
                    });
                } else {
                    const value = data == null ? '' : data;
                    formData.append(parentKey, value);
                }
            },

            jsonToFormData(data) {
                const formData = new FormData();
                this.buildFormData(formData, data);
                return formData;
            },

            getactionstatus() {
                this.$http.get('sectional/getstatus')
                    .then(response => {
                        this.allstatus = response.data;
                        response.data.forEach(s => {
                            if (s.optiontype == 'formtype') {
                                this.formtypes.push(s);
                            }
                            else if (s.optiontype == 'view') {
                                this.views.push(s);
                            }
                            else if (s.optiontype == 'action') {
                                if (s.parentid == 3) {
                                    this.takeoff.push(s);
                                }
                                else if (s.parentid == 4) {
                                    this.compiling.push(s);
                                }
                                else if (s.parentid == 5) {
                                    this.billcheck.push(s);
                                }
                                else if (s.parentid == 6) {
                                    this.instructions.push(s);
                                }
                                else if (s.parentid == 53) {
                                    this.checkinglists.push(s);
                                }
                                else if (s.parentid == 54) {
                                    this.rwccqc.push(s);
                                }
                            }
                            else if (s.optiontype == 'priority') {
                                this.priorities.push(s);
                            }
                            else if (s.optiontype == 'solution') {
                                this.solutions.push(s);
                            }
                            else if (s.optiontype == 'extension') {
                                this.extensions.push(s);
                            }
                        });
                    })
                    .catch(err => {
                        this.$bus.$emit('error', err);
                    });
            },

            projectsectionchange: function (Projectobj) {
                this.sectionid = Projectobj.sectionid;
                this.project = Projectobj.project;
                this.projectpart = Projectobj.part;
                this.clearform();
                this.refresh();
            },

            clearform() {
                this.detailform = {
                    viewdetails: []
                }
            },

            refresh() {
                if (this.project.id && this.sectionid) {
                    this.getsectionalcheck();
                }
                else {
                    this.clearform();
                    this.display = false;
                }
            },

            getsectionalcheck() {
                this.showloading();
                let data = { projectsectionid: this.sectionid };
                this.$http.post('sectional/getsectionalcheck', data)
                    .then(response => {
                        if (response.data != null) {
                            this.dateformatter(response.data, "signoffon", response.data.signoffon);
                            response.data.viewdetails.forEach(item => {
                                this.dateformatter(item, "checkedon", item.checkedon);
                                this.changedview(item, false);
                                if (item.details.length <= 0) {
                                    this.addRow({ reference: 0, edited: true }, 0, item);
                                }
                                this.srnochanged(item.details);

                                let newcorrecter = JSON.parse(JSON.stringify(item.details));
                                let correcterisdone = newcorrecter.find(c => c.done1 == false);
                                if (correcterisdone == undefined) {
                                    let maxcorrecters = newcorrecter.sort((a, b) => {
                                        return new Date(b.correctionon).getTime() - new Date(a.correctionon).getTime();
                                    });
                                    this.dateformatter(maxcorrecters[0], "correctionon", maxcorrecters[0].correctionon);
                                    this.$set(item, `maxcorrecter`, maxcorrecters[0]);
                                }

                                let newcompleted = JSON.parse(JSON.stringify(item.details));
                                let processisdone = newcompleted.find(p => p.done2 == false);
                                if (processisdone == undefined) {
                                    let maxprocess = newcompleted.sort((a, b) => {
                                        return new Date(b.qasectionalcompletedon).getTime() - new Date(a.qasectionalcompletedon).getTime();
                                    });
                                    this.dateformatter(maxprocess[0], "qasectionalcompletedon", maxprocess[0].qasectionalcompletedon);
                                    this.$set(item, `maxprocess`, maxprocess[0]);
                                }
                            });
                            this.detailform = response.data;
                            this.sortingascendingview(this.detailform.viewdetails);
                            this.uploadprogress = 0;
                        }
                        this.addingdefaultviews();
                        this.display = true;
                        this.hideloading();
                    })
                    .catch(err => {
                        this.hideloading();
                        this.$bus.$emit('error', err);
                    });
            },

            addingdefaultviews() {
                for (let v = 0; v < this.views.length; v++) {
                    const currentview = this.views[v];
                    let existview = this.detailform.viewdetails.find(x => x.view == currentview.id);
                    if (!existview) {
                        this.addView(currentview);
                    }
                }
            },

            tablecells() {
                return 'padding-top:1px; padding-bottom: 1px;';
            },

            tableheadercolor({ _row, _column, rowIndex, columnIndex }) {
                let cls = [];
                if (rowIndex === 0) {
                    cls.push("tableheadergray");
                }
                return cls.join(" ");
            },

            dateformatter: function (row, name, cellValue) {
                if (cellValue) {
                    row[name] = moment(cellValue).format("DD-MMM-YYYY HH:mm");
                }
                return cellValue;
            },

            sortingascendingview(newarray) {
                newarray.sort((a, b) => {
                    if (a.view === null && b.view === null) {
                        return 0;
                    } else if (a.view === null) {
                        return -1;
                    } else if (b.view === null) {
                        return 1;
                    } else {
                        const d1 = this.views.find(item => item.id === a.view);
                        const d2 = this.views.find(item => item.id === b.view);
                        if (d1 && d2) {
                            return d1.displayorder - d2.displayorder;
                        } else {
                            return 0;
                        }
                    }
                });
            },

            addView(event) {
                let itemdetail = { reference: "01", action: null, nr: null, comment1: "", comment2: "", priority: null, solution: null, done1: false, done2: false, importedviewdetailimages: [] };
                let itm = { id: 0, actions: [], view: null, type: null, details: [itemdetail], importedviewimages: [], viewmedialist: [] };
                const defaulttype = this.formtypes.find(x => x.name == 'Final');
                if (defaulttype != undefined) {
                    itm.type = defaulttype.id;
                }
                if (event != null) {
                    itm.view = event.id;
                    this.changedview(itm, false);
                    const currentaction = itm.actions.find(x => x.name == 'No Actions / Issues');
                    if (currentaction) {
                        itemdetail.action = currentaction.id;
                        this.changeActionIssue(itm, itemdetail, 'childdetail');
                    }
                }
                this.detailform.viewdetails.push(itm);
                this.sortingascendingview(this.detailform.viewdetails);
            },

            confirmview() {
                this.$confirm('Are you sure you want to add a new view?', 'Warning', {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                }).then(() => {
                    this.addView(null);
                }).catch(() => {
                    this.$message({
                        showClose: true,
                        type: 'info',
                        message: 'canceled'
                    });
                });
            },

            deleteview: function (row, index) {
                this.$confirm('Are you sure you want to Delete?', 'Warning', {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                }).then(() => {
                    if (row.id != undefined && row.id > 0) {
                        this.showloading();
                        this.$http.post("sectional/deleteexternalcheckentireview", { id: row.id })
                            .then(response => {
                                this.hideloading();
                                this.$message({
                                    showClose: true,
                                    message: response.data.message,
                                    type: "success"
                                });
                                this.refresh();
                            })
                            .catch(err => {
                                this.hideloading();
                                this.$bus.$emit('error', err);
                            });
                    } else {
                        this.detailform.viewdetails.splice(index, 1);
                    }
                }).catch(() => {
                    this.$message({
                        showClose: true,
                        type: 'info',
                        message: 'Delete canceled'
                    });
                });
            },

            addRow(row, rowindex, sc) {
                let itm = { reference: parseInt(row.reference) + 1, action: null, nr: null, comment1: "", comment2: "", priority: null, solution: null, done1: false, done2: false, importedviewdetailimages: [] };
                sc.details.splice(rowindex + 1, 0, itm);
                this.srnochanged(sc.details);
            },

            deleteRow: function (index, row, sc) {
                this.$confirm('Are you sure you want to Delete?', 'Warning', {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                }).then(() => {
                    if (row.id != undefined && row.id > 0) {
                        this.showloading();
                        this.$http.post("sectional/deletesectionviewdetail", { id: row.id })
                            .then(response => {
                                this.hideloading();
                                this.$message({
                                    showClose: true,
                                    message: response.data.message,
                                    type: "success"
                                });
                                this.refresh();
                            })
                            .catch(err => {
                                this.hideloading();
                                this.$bus.$emit('error', err);
                            });
                    } else {
                        sc.details.splice(index, 1);
                        this.srnochanged(sc.details);
                        if (sc.details.length <= 0) {
                            this.addRow({ reference: 0, edited: true }, 0, sc);
                        }
                    }
                }).catch(() => {
                    this.$message({
                        showClose: true,
                        type: 'info',
                        message: 'Delete canceled'
                    });
                });
            },

            addSnip(sc) {
                this.$set(sc, "isopen", sc.isopen == true ? false : true);
            },

            gotobillpage(sc) {
                if (sc.id <= 0) {
                    this.$confirm('To use the <span class="correctedblue">[Bill View]</span> button you will first need to <span class="signoffgreen">[Save]</span> the Action / Issue you selected.', 'Warning', {
                        confirmButtonText: 'OK',
                        showCancelButton: false,
                        dangerouslyUseHTMLString: true,
                        type: 'warning'
                    });
                }
                else {
                    this.showloading();
                    this.$http.post("project/setencryptid?data=" + sc.id, {})
                        .then(response => {
                            let base = window.location.href.substring(0, window.location.href.lastIndexOf('/') + 1);
                            var a = document.createElement('a');
                            a.href = `${base}externalbill?src=${response.data}`;
                            a.click();
                            this.hideloading();
                        })
                        .catch(err => {
                            this.hideloading();
                            this.$bus.$emit('error', err);
                        });
                }
            },

            srnochanged(details) {
                let newsrno = 1;
                details.forEach(r => {
                    r.reference = newsrno++;
                    r.reference = r.reference > 9 ? r.reference : "0" + r.reference;
                });
            },

            changedview(row, event) {
                row.actions = [];
                if (row.view == 3) {
                    row.actions = this.takeoff;
                }
                else if (row.view == 4) {
                    row.actions = this.compiling;
                }
                else if (row.view == 5) {
                    row.actions = this.billcheck;
                }
                else if (row.view == 6) {
                    row.actions = this.instructions;
                }
                else if (row.view == 53) {
                    row.actions = this.checkinglists;
                }
                else if (row.view == 54) {
                    row.actions = this.rwccqc;
                }
                if (event) {
                    row.details.forEach(i => {
                        i.action = null;
                    });
                }
                this.editedchanged(row, null, "parentdetail");
                row.edited = event;
            },

            changeActionIssue(sc, row, name) {
                let selectedaction = sc.actions.find(x => x.id == row.action);
                if (selectedaction && selectedaction.name == 'No Actions / Issues') {
                    row.nr = 0;
                    row.done1 = true;
                    row.done2 = false;
                    row.comment1 = "Check completed nothing found";
                    row.comment2 = "";
                    row.priority = null;
                    row.solution = null;
                }
                else if (selectedaction && selectedaction.parentid === 5 && selectedaction.name == "Bill View") {
                    row.nr = sc.ebvd.viewcommentcount;
                    row.done1 = sc.ebvd.isbillchecked;
                    row.comment1 = 'As Bill External Check - Comments';
                    row.comment2 = "";
                    row.done2 = false;
                    row.priority = null;
                    row.solution = null;
                }
                this.editedchanged(sc, row, name);
            },

            signedoffchecked(sc, row, name) {
                this.editedchanged(sc, row, name);
                if (sc.issignoff == true) {
                    var style = "<style> .el-message-box {width:30%;} </style>";
                    var issignofftrue = true;
                    sc.signoffbyid = null;
                    var type = this.formtypes.find(x => x.name == "Final");
                    if (!type) {
                        type = { id: 2 }
                    }
                    sc.viewdetails.forEach(s => {
                        if (s.details && s.details.length > 0) {
                            s.details.forEach(d => {
                                if (!d.done1) {
                                    this.$alert(`Corrected By cell has not be done. ${style}`,
                                        "warning", {
                                        dangerouslyUseHTMLString: true,
                                    });
                                    issignofftrue = false;
                                    return;
                                }
                            })
                        }
                        if (s.type != type.id) {
                            this.$alert(`You cannot sign of this section of until a 'Final' check has been made. ${style}`,
                                "warning", {
                                dangerouslyUseHTMLString: true,
                            });
                            issignofftrue = false;
                            return;
                        }
                    });
                    if (!issignofftrue) {
                        this.$nextTick(() => {
                            sc.issignoff = false;
                        });
                    }
                }
            },

            editedchanged(sc, row, name) {
                if (name == 'maindetail') {
                    sc.edited = true;
                }
                else if (name == 'parentdetail') {
                    sc.edited = true;
                    sc.details.forEach(d => {
                        d.edited = true;
                    });
                }
                else if (name == 'image') {
                    sc.edited = true;
                }
                else if (name == 'childdetail') {
                    sc.edited = true;
                    row.edited = true;
                }
            },

            addAttachment(file, row, index, column) {
                const name = file.file.name;
                const ext = name.substring(name.lastIndexOf('.') + 1).toLowerCase();
                const existextension = this.extensions.find(x => x.name == ext);
                const validext = this.extensions.map(e => { return e.name; }).join(", ");
                if (existextension == undefined) {
                    var style = "<style> .el-message-box {width:30%;} </style>";
                    this.$alert(`<span style='color:red;'>${name}</span> has invalid extension, allowed extensions are  ${validext}. ${style}`,
                        "Validate extension", {
                        dangerouslyUseHTMLString: true,
                    });
                    return;
                }
                const rename = `${index} || ${file.file.name}`;
                const renamefile = new File([file.file], rename);
                const f = {
                    // sectionalviewid: row.id,
                    originalname: name,
                    checkingname: rename,
                    content: renamefile
                };
                row[column].push(f);
            },



            deleteAttachment(name, imagelist) {
                let index = imagelist.findIndex(x => x.originalname == name);
                imagelist.splice(index, 1);
            },

            async pasteimage(row, index, column) {
                try {
                    const permission = await navigator.permissions.query({ name: 'clipboard-read' });
                    if (permission.state === 'denied') {
                        throw new Error('Please give the clipboard read permission.');
                    }
                    await navigator.clipboard.read().then((items) => {
                        for (let item of items) {
                            for (let type of item.types) {
                                if (type.startsWith("image/")) {
                                    const result = this.handleImage(type, item, row, index, column);
                                    if (result) {
                                        break;
                                    }
                                }
                            }
                        }
                    });
                }
                catch (error) {
                    var style = "<style> .el-message-box {width:50%;} </style>";
                    this.$alert(error.message + style, "Invalid Data", { dangerouslyUseHTMLString: true });
                }
            },

            handleImage(type, clipboardItem, row, index, column) {
                if (type.startsWith("image/")) {
                    clipboardItem.getType(type).then((myBlob) => {
                        let date = moment(new Date()).format("DD-MMM-YYYY HH:mm:ss");
                        const myfile = new File([myBlob], `Image ${date}.jpeg`, {
                            type: myBlob.type,
                        });
                        if (myfile != null) {
                            const existfile = row[column].find(x => x.originalname == myfile.name);
                            if (existfile == undefined) {
                                this.addAttachment(Object.assign({ file: myfile }), row, index, column);
                            }
                        }
                    });
                    return true;
                }
                return false;
            },

            beforeuploadpreview(i) {
                this.beforemedia = i;
                this.beforemedia.show = true;
            },

            closemedia(event) {
                this.beforemedia.show = event;
                this.beforemedia = {};
            },

            deletemedia(row) {
                this.$confirm('Are you sure you want to Delete?', 'Warning', {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                }).then(() => {
                    this.showloading();
                    this.$http.post("sectional/deletemediafromdropbox", { id: row.id })
                        .then(response => {
                            this.hideloading();
                            this.$message({
                                showClose: true,
                                message: response.data.message,
                                type: "success"
                            });
                            this.refresh();
                        })
                        .catch(err => {
                            this.hideloading();
                            this.$bus.$emit('error', err);
                        });
                }).catch(() => {
                    this.$message({
                        showClose: true,
                        type: 'info',
                        message: 'Delete canceled'
                    });
                });
            },

            validation(data) {
                var error = [];
                var isvalid = true;
                var isvalidview = true;
                var isvalidtype = true;
                let ckcomment = true;
                let priority = true;
                data.viewdetails.forEach((x) => {
                    if (x.type == null) {
                        isvalidtype = false;
                    }
                    if (x.view == null) {
                        isvalidview = false;
                    }
                    x.details.forEach((row) => {
                        let isnoissue = x.actions.find(a => a.id == row.action && a.name == "No Actions / Issues");
                        if (isnoissue == undefined) {
                            if (row.comment1 == undefined || row.comment1.trim() == "") {
                                ckcomment = false;
                            }
                            if (row.priority == undefined || row.priority == null) {
                                priority = false;
                            }
                        }
                    });
                });
                if (!isvalidtype) {
                    error.push("Please select 'Type' from dropdown");
                    isvalid = false;
                }
                if (!isvalidview) {
                    error.push("Please select 'View' from dropdown");
                    isvalid = false;
                }
                if (!ckcomment) {
                    error.push("Comment section must not be empty");
                    isvalid = false;
                }
                if (!priority) {
                    error.push("Value section must not be empty");
                    isvalid = false;
                }

                if (!isvalid) {
                    this.$confirm(error.join(" <br> "), 'Warning', {
                        confirmButtonText: 'OK',
                        dangerouslyUseHTMLString: true,
                        type: 'warning'
                    })
                }
                return isvalid;
            },

            getdirectionids() {
                return [
                    {
                        "column": "issignoff",
                        "id": "analysis_external_check_section_signed_off_by"
                    },
                    {
                        "column": "view",
                        "id": "analysis_external_check_view"
                    },
                    {
                        "column": "type",
                        "id": "analysis_external_check_formtype"
                    },
                    {
                        "column": "reference",
                        "id": "analysis_external_check_table_reference"
                    },
                    {
                        "column": "action",
                        "id": "analysis_external_check_table_actionissue"
                    },
                    {
                        "column": "nr",
                        "id": "analysis_external_check_table_nr"
                    },
                    {
                        "column": "comment1",
                        "id": "analysis_external_check_table_ck_comment"
                    },
                    {
                        "column": "priority",
                        "id": "analysis_external_check_table_priority"
                    },
                    {
                        "column": "solution",
                        "id": "analysis_external_check_table_solution"
                    },
                    {
                        "column": "comment2",
                        "id": "analysis_external_check_table_qa_comment"
                    },
                    {
                        "column": "done1",
                        "id": "analysis_external_check_table_done_one"
                    },
                    {
                        "column": "done2",
                        "id": "analysis_external_check_table_done_two"
                    }
                ]
            },

            cellchanged(cellaction, callback, cancall) {
                if (cancall) {
                    callback();
                    this.canmatchcellid(() => { }, cellaction, '', true, (array1) => { this.validatedirection(array1, callback) });
                } else {
                    this.canmatchcellid(callback, cellaction, '', true, (array1) => { this.validatedirection(array1, callback) });
                }
            },

            getcellmatcheddirection(direction, directionidvalue) {
                let matcheddirection = direction.directioncelldetails.find(x => x.pagecell.cellname == directionidvalue);
                let dir = JSON.parse(JSON.stringify(direction));
                if (matcheddirection) {
                    dir.includerule = matcheddirection.includerule;
                    dir.excluderule = matcheddirection.excluderule;
                    dir.formatrule = matcheddirection.formatrule;
                    dir.pagecell = matcheddirection.pagecell;
                    dir.pagecellid = matcheddirection.pagecellid;

                    dir.checkvalue = matcheddirection.checkvalue;
                    dir.operation = matcheddirection.operation;
                    dir.celltype = matcheddirection.pagecell.celltype;
                    dir.objectpath = matcheddirection.pagecell.objectpath;

                    return dir;
                }
            },

            getCellValueAndRegexRules(data, cellvalue) {
                if (data.includerule != null && data.includerule != "") {
                    data.inc_pattern = new RegExp(data.includerule, "i");
                }

                if (data.excluderule != null && data.excluderule != "") {
                    data.exc_pattern = new RegExp(data.excluderule, "i");
                }

                if (data.formatrule != null && data.formatrule != "") {
                    data.format_pattern = new RegExp(data.formatrule, "i");
                }

                data.enteredvalue = cellvalue;

                return data;
            },

            getformat(dr) {
                const data = {};
                var inc_pattern = null;
                var exc_pattern = null;
                var format_pattern = null;
                if (dr.includerule != null && dr.includerule != "") {
                    inc_pattern = new RegExp(dr.includerule, "i");
                }
                if (dr.excluderule != null && dr.excluderule != "") {
                    exc_pattern = new RegExp(dr.excluderule, "i");
                }
                if (dr.formatrule != null && dr.formatrule != "") {
                    format_pattern = new RegExp(dr.formatrule, "i");
                }
                data.format_pattern = format_pattern;
                data.inc_pattern = inc_pattern;
                data.exc_pattern = exc_pattern;
                return data;
            },

            getdirectionoption(FormData) {
                const option = JSON.parse(JSON.stringify({
                    visible: true,
                    sectionname: "",
                    isedit: false,
                    id: FormData.id,
                    boxtype: "Small 2 Part Pop up",
                    content: FormData.message,
                    mediacontent: FormData.mediacontent,
                    link: FormData.link,
                    offercloseoption: FormData.offercloseoption,
                    heading: FormData.directionname,
                    directiondegree: FormData.directiondegree,
                    direction: FormData,
                    usedata: true,

                    enteredvalue: FormData.enteredvalue,
                    celltype: FormData.celltype,
                    objectpath: FormData.objectpath,
                    checkvalue: FormData.checkvalue,
                    operation: FormData.operation,

                }));
                return option;
            },

            multicelllocationrulesmatch(celldirections, direction, errors) {
                let count = 0;
                let viewindex = null;
                let vdindex = null;
                let datacolumntype = null;
                for (let i = 0; i < celldirections.length; i++) {
                    const { columntype, viewindex: vindex, vdindex: dindex, value, celldirection } = celldirections[i];
                    if (celldirection != undefined) {
                        viewindex = vindex;
                        vdindex = dindex;
                        datacolumntype = columntype;
                        const ddata = this.getCellValueAndRegexRules(celldirection, value);
                        if (!this.$directionutility.directionvaluematch(ddata)) {
                            count++;
                        }
                    }
                }

                if (direction.directioncelldetails.length == count) {
                    errors.forEach(x => {
                        if (x.direction.columntype == 'main') {
                            x.ismatchedrulecell = true;
                        }
                        if (x.direction.columntype == datacolumntype && (x.direction.viewindex && x.direction.viewindex == viewindex)) {
                            x.ismatchedrulecell = true;
                        }
                        if ((x.direction.viewindex && x.direction.viewindex == viewindex) && (x.direction.vdindex && x.direction.vdindex == vdindex)) {
                            x.ismatchedrulecell = true;
                        }
                    });
                    return true;
                }
                else {
                    return false;
                }
            },

            validatedirectionsingle(data) {
                data.value = data.enteredvalue;
            },

            validatedirection(directions, callback) {
                var errors = [];
                const directionids = this.getdirectionids();
                for (let i = 0; i < directions.length; i++) {

                    const direction = directions[i];
                    let dbdirection = {};
                    for (let j = 0; j < directionids.length; j++) {
                        const row = directionids[j];
                        const columnValue = this.getcellmatcheddirection(direction, row.id);
                        dbdirection[row.column] = columnValue;
                    }

                    for (let viewindex = 0; viewindex < this.detailform.viewdetails.length; viewindex++) {
                        const currentview = this.detailform.viewdetails[viewindex];

                        for (let vdindex = 0; vdindex < currentview.details.length; vdindex++) {
                            const currentdetail = currentview.details[vdindex];
                            var currentval = {};
                            currentval.issignoff = this.detailform.issignoff == true ? true : false;
                            currentval.view = this.$directionutility.findbyid(currentview.view, this.views, "name");
                            currentval.type = this.$directionutility.findbyid(currentview.type, this.formtypes, "name");
                            currentval.action = this.$directionutility.findbyid(currentdetail.action, this.allstatus, "name");
                            currentval.priority = this.$directionutility.findbyid(currentdetail.priority, this.priorities, "name");
                            currentval.solution = this.$directionutility.findbyid(currentdetail.solution, this.solutions, "name");
                            currentval.reference = currentdetail.reference;
                            currentval.nr = currentdetail.nr;
                            currentval.comment1 = currentdetail.comment1;
                            currentval.comment2 = currentdetail.comment2;
                            currentval.done1 = currentdetail.done1;
                            currentval.done2 = currentdetail.done2;

                            let objdirections = [];
                            let maindirections = [];
                            for (let a = 0; a < directionids.length; a++) {
                                const obj = directionids[a];
                                if (dbdirection[obj.column] != undefined) {
                                    let columntype = '';
                                    if (obj.column == 'issignoff') {
                                        columntype = 'main';
                                    } else if (obj.column == 'view' || obj.column == 'type') {
                                        columntype = 'view';
                                    } else {
                                        columntype = '';
                                    }
                                    maindirections.push(obj);
                                    objdirections.push({ columntype: columntype, viewindex, vdindex, celldirection: dbdirection[obj.column], value: currentval[obj.column] });
                                }
                            }

                            for (let ids = 0; ids < maindirections.length; ids++) {
                                const idsrow = maindirections[ids];
                                let mydirection = dbdirection[idsrow.column];
                                let checkedcell = false;

                                if (idsrow.column == 'issignoff') {
                                    checkedcell = this.detailform.signoffbydirection;
                                } else {
                                    if (idsrow.column == 'view' || idsrow.column == 'type') {
                                        checkedcell = currentview[`${idsrow.column}direction`];
                                    }
                                }

                                if (mydirection != undefined && !checkedcell) {
                                    let iscellrulesmatched = this.multicelllocationrulesmatch(objdirections, direction, errors);
                                    const dr = this.getformat(mydirection);
                                    let ddata = this.getCellValueAndRegexRules(mydirection, currentval[idsrow.column]);

                                    if (this.$directionutility.directionvaluematch(ddata) == false) {
                                        const option = this.getdirectionoption(mydirection);
                                        Object.assign(option, dr);
                                        option.validatedirectionsingle = (data) => { this.validatedirectionsingle(data) }
                                        if (idsrow.column == 'issignoff') {
                                            this.$set(option, "elementid", idsrow.id);
                                        }
                                        else if (idsrow.column == 'view' || idsrow.column == 'type') {
                                            this.$set(option, "elementid", `${idsrow.id}_${viewindex}`);
                                        } else {
                                            this.$set(option, "elementid", `${idsrow.id}_${viewindex}_${vdindex}`);
                                        }
                                        if (iscellrulesmatched == false) {
                                            option.ismatchedrulecell = false;
                                        } else {
                                            option.ismatchedrulecell = true;
                                            // this.detailform.signoffbydirection = true;
                                            if (idsrow.column == 'issignoff') {
                                                this.detailform.signoffbydirection = true;
                                            } else {
                                                if (idsrow.column == 'view' || idsrow.column == 'type') {
                                                    currentview[`${idsrow.column}direction`] = true;
                                                }
                                            }
                                        }
                                        errors.push(option);
                                    }
                                }
                            }
                        }
                    }
                }

                errors = errors.filter(x => x.ismatchedrulecell == true);
                this.detailform.signoffbydirection = false;
                for (let vi = 0; vi < this.detailform.viewdetails.length; vi++) {
                    const element = this.detailform.viewdetails[vi];
                    element.viewdirection = false;
                    element.typedirection = false;
                }

                if (errors.length > 0) {
                    this.$store.state.directionsdialogue = errors;
                    this.$store.state.currentdirectionindex = 0;
                    this.$store.state.currentdirection = errors[0];
                } else {
                    callback();
                }
            },

            save() {
                this.saving = true;
                this.detailform.projectsectionid = this.sectionid;
                if (this.saving) {
                    var isvalid = this.validation(this.detailform);
                    if (!isvalid) {
                        this.saving = false;
                        return
                    }
                    var cansave = () => {
                        let formdata = this.jsonToFormData(this.detailform);
                        this.showloading();
                        let onprogress = {
                            onUploadProgress: (progress) => {
                                this.uploadprogress = Math.floor(100 / progress.total * progress.loaded);
                            }
                        };
                        this.$http.post("sectional/savesectionalcheck", formdata, onprogress)
                            .then((response) => {
                                this.$message({
                                    type: "success",
                                    showClose: true,
                                    message: response.data.message
                                });
                                this.hideloading();
                                this.saving = false;
                                this.refresh();
                            })
                            .catch((err) => {
                                this.hideloading();
                                this.saving = false;
                                this.$bus.$emit('error', err);
                            });
                    }
                    if (isvalid) {
                        cansave();
                    } else {
                        this.saving = false;
                        return
                    }
                }
                else {
                    this.saving = false;
                    this.$message({
                        type: "info",
                        showClose: true,
                        message: "Nothing to save"
                    });
                }
            },
        },

        created() {
            const params = new URLSearchParams(window.location.search);
            if (params.size > 0) {
                this.$training.getprojectusingparam(params);
            }
            this.getactionstatus();
        }
    }
</script>