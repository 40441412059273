<template>
    <div>
        <div class="fixdheader" style="max-width: 1100px;width:100%;top:43px">
            <el-row>
                <el-col :span="15" class="headerstyle"> <span>Independent Measure</span> </el-col>
                <el-col :span="9" style="text-align:right;margin-top:5px">
                        <el-button v-if="detail" :disabled="!editpermission" @click="cellchanged('save_independent_measure',()=>{save()},false)" id="setup_independentmeasure_save" type="success">Save</el-button>
                        <el-button icon="el-icon-back" type="info" @click="$router.go(-1)"  id="setup_independentmeasure_back">Back</el-button>
                </el-col>
            </el-row>

            <el-row>
                <el-col class="width110 margin2px">
                      <el-select  v-model="setuporehname" placeholder="Select" @change="setuporurchange">
                            <el-option
                                v-for="item in options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>
                </el-col >
                <el-col :span="20" v-if="setuporehname == 'As Setup'" style="max-width: 910px;">
                <el-row>
                    <el-col :span="15" class="width550px">
                     <selectpart :Similar="Similar" @projectpartchange="projectpartchange" ></selectpart>
                    </el-col>
                    <span class="left inputwidth45 margintop5">
                     <div v-if="project" class="el-input el-input--mini bold">
                        {{ project.library }}
                    </div>
                    </span>
                    <span class="left width80px margintop5">
                     <div class="el-input el-input--mini bold">
                        <div v-if="lastsavedproject">{{ lastsavedproject }}</div>
                    </div>
                    </span>
                </el-row>

                </el-col>
                <el-col :span="20" v-if="setuporehname == 'EH Estimating'" >
                <ehselectpart :EHSimilar="mainehproject" @ehprojectpartchange="mainehprojectchange" ></ehselectpart>
                </el-col>

            </el-row>

            <div>
                <hr class="hrstyle" />
                <el-row>
                    <div class="left" style="width:475px;">

                        <el-button type="primary"  id="setup_independentmeasure_independent_measure_template" @click="$router.push('/independentmeasuretemplate')" plain>
                            Independent Measure Template
                        </el-button>

                        <el-button type="primary" @click="$router.push('/pspsummary')" id="setup_independentmeasure_psp_summary" plain>
                            PSP Summary
                        </el-button>

                        <!-- <el-select v-model="setuporehname" placeholder="Select" @change="setuporurchange">
                            <el-option
                                v-for="item in options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select> -->

                        <el-button  id="setup_independentmeasure_import_estimate_hours" type="primary" @click="dialogVisible = true" v-show="setuporehname == 'As Setup'" plain>
                            Import Estimate Hours
                        </el-button>

                    </div>

                    <div v-if="detailform.completedby" style="float:right; padding-right: 14px;">
                        <div class="el-input el-input--mini bold">
                            Completed By: &nbsp; &nbsp;  {{detailform.completedbyuser.loginname}}&nbsp;&nbsp;{{lastsaved}}
                        </div>
                    </div>
                </el-row>
                <hr class="hrstyle" />
            </div>
          <!-- <div class="custom-table" >
            <el-row>
           <div style="width:100%;max-width:272px;padding-left: 10px;" class="left">Data Description</div>
           <div style="width:100%;max-width:79px; " class="left">UR</div>
           <div style="width:100%;max-width:76px; " class="left">Qty</div>
           <div style="width:100%;max-width:50px; " class="left">Unit</div>
           <div style="width:100%;max-width:283px;" class="left">Link</div>
           <div style="width:100%;max-width:194px;" class="left">Comment / Terms of Calculated</div>
           </el-row>
           <hr class="custom-table-hr">
        </div> -->
        </div>
        <div >
        <el-table  :show-header="true" :height="height"  :data="detailform.IMTtemplate" :span-method="arraySpanMethod" :row-class-name="tableRowClassName" :cell-style="tablecellClassName" class="tableBox fullwidth " row-key="srno">
            <el-table-column  prop="srno" label="Data Description" width="250" >
                <template slot-scope="scope">
                <div v-if="scope.row.type == 'GP'" class="dark-gray-inputIM">
                    <!-- <el-input size="mini" placeholder="Grope Neme" v-model="scope.row.datadescription"></el-input> -->
                    {{scope.row.datadescription}}
                </div>
                <div v-if="scope.row.type == 'NRM'" class="nrm-inputIM">
                    <!-- <el-input placeholder="NRM Neme" v-model="scope.row.datadescription"></el-input> -->
                    {{scope.row.datadescription}}
                </div>

                <div v-if="scope.row.type == 'MA'" class="detail-input" style="margin-left: 10px">
                    <!-- <el-input placeholder="datadescription" v-model="scope.row.datadescription"></el-input> -->
                    {{scope.row.datadescription}}
                </div>
                </template>
            </el-table-column>

            <el-table-column prop="ur" align="center" label="UR" width="60">
                <template slot-scope="scope">
                <div v-if="scope.row.type == 'MA'" class="detail-input">
                    <!-- <el-input placeholder="UR" width="10" :maxlength="3" v-model="scope.row.ur" onkeypress="return event.charCode >= 48 && event.charCode <= 57" @change="urchanged(scope.row)"></el-input> -->
                  {{scope.row.ur}}
                </div>
                </template>
            </el-table-column>

            <el-table-column prop="IM.qty" align="center" label="Qty" width="100">
                <template slot-scope="scope">
                <div v-if="scope.row.type == 'MA'" class="detail-input">
                    <!-- <el-input placeholder="Qty" style=" width: 50%;" v-model="scope.row.IM.qty"></el-input> -->
                    <!-- <el-input-number :disabled="!editpermission" :precision="1" :controls="false" placeholder="Qty" style=" width: 90%;" v-model="scope.row.IM.qty"  @change="qtychack(scope.row.IM)"></el-input-number> -->
                    <el-input :disabled="!editpermission" placeholder="Qty" :id="'setup_independentmeasure_qty_'+scope.$index" class="inputright" style="width: 90%;" v-model="scope.row.IM.qty" @change="qtychack(scope.row.IM)"></el-input>
                </div>
                </template>
            </el-table-column>

            <el-table-column prop="unit" align="center" label="Unit" width="55">
                <template slot-scope="scope">
                <div v-if="scope.row.type == 'MA'" class="detail-input">
                     {{scope.row.unit}}
                    <!-- <el-input placeholder="Unit" v-model="scope.row.unit"></el-input> -->
                </div>
                </template>
            </el-table-column>

            <el-table-column prop="IM.comment" label="Link" width="280">
                <template slot-scope="scope">
                    <div v-if="scope.row.type == 'MA'"  class="detail-input">
                        <el-dropdown :hide-on-click="false" trigger="click">
                            <span class="el-dropdown-link">
                                <el-input
                                    v-model="scope.row.IM.comment" placeholder="Link"
                                    :id="'setup_independentmeasure_link_'+scope.$index"
                                    :spellcheck="isspellcheck" :disabled="!editpermission"
                                    @change="commentorlinkchange(scope.row.IM, 'iscomment')"
                                    @focus="commentorlinkfocus(scope.row.IM)"
                                    class="width260px">
                                </el-input>
                            </span>
                            <el-dropdown-menu slot="dropdown" style="width: 280px;">
                                <el-dropdown-item>
                                    <div v-if="islinkedit">
                                        <el-row>
                                            <el-col :span="20">
                                                <el-input placeholder="Add or paste a link" :id="'setup_independentmeasure_dropdown_link_'+scope.$index" v-model="scope.row.IM.sourcelink" @input.native="linkinputchange(scope.row.IM)" @change="commentorlinkchange(scope.row.IM, 'islink')"></el-input>
                                            </el-col>

                                            <el-col :span="2">
                                                <el-button @click="applyclick(scope.row.IM)" :id="'setup_independentmeasure_dropdown_link_apply_'+scope.$index" type="text" :disabled="isbtndisable" style="margin-left: 6px; font-weight: bold; font-size: 13px;">Apply</el-button>
                                            </el-col>
                                        </el-row>
                                    </div>
                                    <div v-else>
                                        <el-row>
                                            <el-col :span="22">
                                                <a :href="scope.row.IM.sourcelink" target="_blank" class="linktextoverflowhide">{{scope.row.IM.sourcelink}}</a>
                                            </el-col>

                                            <el-col :span="2">
                                                <el-button icon="el-icon-edit" type="text" :id="'setup_independentmeasure_edit_'+scope.$index" @click="islinkedit = true" style="float: right;"></el-button>
                                            </el-col>
                                        </el-row>
                                    </div>
                                </el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </div>
                </template>
            </el-table-column>

            <el-table-column prop="terms" label="Comment / Terms of Calculated" width="350px">
                <template slot-scope="scope">
                <div v-if="scope.row.type == 'MA'" class="detail-input">
                    <!-- <el-input placeholder="Terms in which Qty Calculated" v-model="scope.row.terms"></el-input> -->
                    {{scope.row.terms}}
                </div>
                </template>
            </el-table-column>
        </el-table>
        </div>
        <div class="spaceBetween"></div>
        <el-row></el-row>


        <el-dialog title="Import Estimate Hours" :visible.sync="dialogVisible" top="25vh" width="680px" class="titlefontchange chngdialgpadding">
            <div class="fontandmargin">Add Project Hours (Estimating Form) PSP Data</div>
                <ehselectpart :EHSimilar="importehproject" @ehprojectpartchange="importehprojectchange"></ehselectpart>

            <div class="fontandmargin margintop20px">To Setup Project (Based on EH Data)</div>
                <selectpart :Similar="Similar2" @projectpartchange="projectpartchange2"></selectpart>

            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false" id="setup_independentmeasure_import_estimate_hours_cancel">Cancel</el-button>
                <el-button type="primary" id="setup_independentmeasure_import_estimate_hours_confirm" @click="importehtosetup">Confirm</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import ehselectpart from "./ehselectpart.vue";
import moment from 'moment'
import selectpart from "./selectpart.vue";
export default {
     components: {
        ehselectpart: ehselectpart,
        selectpart: selectpart,
    },
    data() {
        return {
             height: (document.documentElement.clientHeight -200),
            detail: false,
            Similar: {
                projectPlaceholder: "",
                partPlaceholder: "",
                lastsavedshow: false,
            },
            mainehproject: {
                projectPlaceholder: "",
                partPlaceholder: "",
                lastsavedshow: true,
                 projectpartshow:true,
            },
            Similar2: {
                projectPlaceholder: "",
                partPlaceholder: "",
                lastsavedshow: false,
            },
            importehproject: {
                projectPlaceholder: "",
                partPlaceholder: "",
                lastsavedshow: false,
            },
            project: {},
            projectpart: {},
            ehproject: {},
            ehprojectpart: {},
            detailform: { IMTtemplate: [] },
            options: [
                { value: 'As Setup', label: 'As Setup' },
                { value: 'EH Estimating', label: 'EH Estimating' }
            ],
            setuporehname: 'EH Estimating',
            dialogVisible: false,
            subindex: null,
            supindex: null,
            extindex: null,
            groupindex: { subindex: null, supindex: null, extindex: null },
            isimportedehprj: false,

            islinkedit: false,
            isbtndisable: false
        };
    },
    methods: {
        getsection(){
            return "";
        },
        cellchanged(cellaction,callback,cancall){
            if(cancall){
                callback();
                this.canmatchcellid(()=>{},cellaction,this.getsection());
            }else{
                this.canmatchcellid(callback,cellaction,this.getsection());
            }
        },
        iseditchack:function(row){
            row.isedit = true;
        },

        commentorlinkchange: function(row, checkvalue){
            if (checkvalue == "iscomment") {
                this.iseditchack(row);
            }
            if (checkvalue == "islink") {
                this.isbtndisable = false;
            }
        },

        commentorlinkfocus: function(row){
            if (row.comment == "" || row.sourcelink == "") {
                this.islinkedit = true;
                this.isbtndisable = true;
            } else {
                this.islinkedit = false;
            }
        },

        linkinputchange: function(row){
            if (row.sourcelink != "") {
                this.isbtndisable = false;
            } else {
                this.isbtndisable = true;
            }
        },

        applyclick: function(row){
            if (row.comment == "" || row.comment == null) {
                row.comment = row.sourcelink;
            }
            this.islinkedit = false;
        },

        importehtosetup: function(){
            if (this.project && this.projectpart) {
                this.Similar.projectids = { projectid:this.project.id, partid:this.projectpart.id }
            }
            this.$bus.$emit('addprojectpart',null);
            this.isimportedehprj = true;
            this.dialogVisible = false;
        },

        getforimportehtosetup: function(){
            var data = { projectid: this.ehproject.id, projectpartid: this.ehprojectpart.id };
            this.$http.post("hoursestimate/importehtosetup",data)
            .then((response)=>{
                this.detailform.ehprojectgroup = response.data;
                this.detailform.IMTtemplate.forEach((v, i) => {
                    var obj={};
                    obj =  v.IM
                    obj.isedit = false;
                    v.IM = obj
                });

                this.ehdatatosetup();

                this.detailform.IMTtemplate.forEach((v, i) => {
                    if (typeof v.IM.qty === 'string' && v.IM.qty.includes(",")) {
                        v.IM.qty = parseFloat(v.IM.qty.replace(/,/g, ''));
                    }
                    v.IM.qty = this.commaseparatednumber(parseFloat(v.IM.qty), 0);
                    if (v.IM.qty == undefined) {
                        v.IM.qty = "0";
                    }
                });
            })

            .catch((err)=>{
                this.$bus.$emit("error", err);
            });
        },

        qtychack: function (row) {
            if (row.qty == undefined) {
                row.qty = 0;
            }
            this.iseditchack(row);
        },

        refresh: function () {
            if (this.project.id &&  this.projectpart.id >= 0) {
                this.detail = true;
                this.getIM();
            } else {
                this.detailform = { IMTtemplate: [] };
                this.detail = false;
            }
        },

        projectpartchange: function (Projectobj) {
            this.project = Projectobj.project;
            this.projectpart = Projectobj.part;
            this.refresh();
        },

        mainehprojectchange: function (estimateproject){
            this.project = estimateproject.ehproject;
            this.projectpart = estimateproject.ehpart;
            this.refresh();
        },

        projectpartchange2: function (Projectobj) {
            this.project = Projectobj.project;
            this.projectpart = Projectobj.part;
        },

        importehprojectchange: function (estimateproject){
            this.ehproject = estimateproject.ehproject;
            this.ehprojectpart = estimateproject.ehpart;
        },

        setuporurchange: function (){
            this.detail = false;
            this.detailform = { IMTtemplate: [] };
        },

        ehdatatosetup: function () {
            //   this.$nextTick(this.mymakesortable);
            var gpn1 = ""; var gpn2 = ""; var gpn3 = "";
            var sub = this.detailform.ehprojectgroup.find((x, index) => {
                if (x.groupname == "Substructure") {
                    gpn1 = "buildingfootprint"
                    this.groupindex.subindex = index;
                    return x;
                }
            });

            var sup = this.detailform.ehprojectgroup.find((x, index) => {
                if (x.groupname == "Superstructure") {
                    gpn2 = "grossinternalfloorarea"
                    this.groupindex.supindex = index;
                    return x;
                }
            });

            var ext = this.detailform.ehprojectgroup.find((x, index) => {
                if (x.groupname == "External") {
                    gpn3 = "hardsurfaces"
                    this.groupindex.extindex = index;
                    return x;
                }
            });

            this.detailform.IMTtemplate.forEach((v, index)=>{
                var dsc = v.datadescription.toLowerCase().replace(/\s/g, "");
                if (dsc.includes(gpn1)) {
                    if (sub && sub.qty) {
                        v.IM.qty = parseInt(sub.qty);
                    }
                    v.IM.isedit = true;
                    this.subindex = index;
                }

                if (dsc.includes(gpn2)) {
                    if (sup && sup.qty) {
                        v.IM.qty = parseInt(sup.qty);
                    }
                    v.IM.isedit = true;
                    this.supindex = index;
                }

                if (dsc.includes(gpn3)) {
                    if (ext && ext.qty) {
                        v.IM.qty = parseInt(ext.qty);
                    }
                    v.IM.isedit = true;
                    this.extindex = index;
                }
            });
            if(sub && sub.complexityindex != null){
                var ci = sub.complexityindex.split("-");
                for (let index = this.subindex; index < this.detailform.IMTtemplate.length; index++) {
                    var element = this.detailform.IMTtemplate[index];
                    var dtdscription = element.datadescription.toLowerCase().replace(/\s/g, "");
                    if (element.type == "GP") {
                        break;
                    }
                    else {
                        if (dtdscription.includes("takeoff")) {
                            element.IM.qty = parseInt(ci[0]);
                            element.IM.isedit = true;
                        }
                        if (dtdscription.includes("billing")) {
                            element.IM.qty = parseInt(ci[1]);
                            element.IM.isedit = true;
                        }
                        if (dtdscription.includes("preview/rfi")) {
                            element.IM.qty = parseInt(ci[2]);
                            element.IM.isedit = true;
                        }
                    }
                }
            }

            if(sup && sup.complexityindex != null){
                var ci = sup.complexityindex.split("-");
                for (let index = this.supindex; index < this.detailform.IMTtemplate.length; index++) {
                    var element = this.detailform.IMTtemplate[index];
                    var dtdscription = element.datadescription.toLowerCase().replace(/\s/g, "");
                    if (element.type == "GP") {
                        break;
                    }
                    else {
                        if (dtdscription.includes("takeoff")) {
                            element.IM.qty = parseInt(ci[0]);
                            element.IM.isedit = true;
                        }
                        if (dtdscription.includes("billing")) {
                            element.IM.qty = parseInt(ci[1]);
                            element.IM.isedit = true;
                        }
                        if (dtdscription.includes("preview/rfi")) {
                            element.IM.qty = parseInt(ci[2]);
                            element.IM.isedit = true;
                        }
                    }
                }
            }

             if(ext && ext.complexityindex != null){
                 var ci = ext.complexityindex.split("-");
                 for (let index = this.extindex; index < this.detailform.IMTtemplate.length; index++) {
                     var element = this.detailform.IMTtemplate[index];
                     var dtdscription = element.datadescription.toLowerCase().replace(/\s/g, "");
                     if (element.type == "GP") {
                         break;
                     }
                     else {
                         if (dtdscription.includes("takeoff")) {
                             element.IM.qty = parseInt(ci[0]);
                             element.IM.isedit = true;
                         }
                         if (dtdscription.includes("billing")) {
                             element.IM.qty = parseInt(ci[1]);
                             element.IM.isedit = true;
                         }
                         if (dtdscription.includes("preview/rfi")) {
                             element.IM.qty = parseInt(ci[2]);
                             element.IM.isedit = true;
                         }
                     }
                 }
            }
        },

        getIM: function () {
            var data = { projectid: this.project.id, projectpartid: this.projectpart.id };
            this.setuporehname == "EH Estimating" ? data.isehproject = true : data.isehproject = false;
            this.$http
                .post("project/getIM",data)
                .then((response) => {
                    this.detailform = response.data;
                     this.detailform.IMTtemplate.forEach((v, i) => {
                        var obj={};
                        obj =  v.IM
                        obj.isedit = false;
                        v.IM = obj
                    });
                    if (this.detailform.isehproject == true) {
                        this.ehdatatosetup();
                    }
                    this.detailform.IMTtemplate.forEach((v, i) => {
                        v.IM.qty = this.commaseparatednumber(v.IM.qty, 0);
                        if (v.IM.qty == undefined) {
                            v.IM.qty = "0";
                        }
                    });
                if (this.isimportedehprj == true) {
                    this.getforimportehtosetup();
                    this.isimportedehprj = false;
                }
            })
            .catch((err) => {
                this.$bus.$emit("error", err);
            });
        },

        setimttoehgroup :function(){
            if (this.groupindex.subindex != null && this.detailform.ehprojectgroup.length != 0) {
                this.detailform.ehprojectgroup[this.groupindex.subindex].qty = this.detailform.IMTtemplate[this.subindex].IM.qty
                this.detailform.ehprojectgroup[this.groupindex.supindex].qty = this.detailform.IMTtemplate[this.supindex].IM.qty
                this.detailform.ehprojectgroup[this.groupindex.extindex].qty = this.detailform.IMTtemplate[this.extindex].IM.qty

                var subcomplexityindex = ""; var supcomplexityindex = ""; var extcomplexityindex = "";
                for (let index = this.subindex; index < this.detailform.IMTtemplate.length; index++) {
                    const element = this.detailform.IMTtemplate[index];
                    var dtdscription = element.datadescription.toLowerCase().replace(/\s/g, "");
                    if (element.type == "GP") {
                        break;
                    }
                    else {
                        if (dtdscription.includes("takeoff")) {
                            subcomplexityindex += element.IM.qty;
                        }
                        if (dtdscription.includes("billing")) {
                            subcomplexityindex += "-" + element.IM.qty;
                        }
                        if (dtdscription.includes("preview/rfi")) {
                            subcomplexityindex += "-" + element.IM.qty;
                        }
                    }
                }

                for (let index = this.supindex; index < this.detailform.IMTtemplate.length; index++) {
                    const element = this.detailform.IMTtemplate[index];
                    var dtdscription = element.datadescription.toLowerCase().replace(/\s/g, "");
                    if (element.type == "GP") {
                        break;
                    }
                    else {
                        if (dtdscription.includes("takeoff")) {
                            supcomplexityindex += element.IM.qty;
                        }
                        if (dtdscription.includes("billing")) {
                            supcomplexityindex += "-" + element.IM.qty;
                        }
                        if (dtdscription.includes("preview/rfi")) {
                            supcomplexityindex += "-" + element.IM.qty;
                        }
                    }
                }

                for (let index = this.extindex; index < this.detailform.IMTtemplate.length; index++) {
                    const element = this.detailform.IMTtemplate[index];
                    var dtdscription = element.datadescription.toLowerCase().replace(/\s/g, "");
                    if (element.type == "GP") {
                        break;
                    }
                    else {
                        if (dtdscription.includes("takeoff")) {
                            extcomplexityindex += element.IM.qty;
                        }
                        if (dtdscription.includes("billing")) {
                            extcomplexityindex += "-" + element.IM.qty;
                        }
                        if (dtdscription.includes("preview/rfi")) {
                            extcomplexityindex += "-" + element.IM.qty;
                        }
                    }
                }

                this.detailform.ehprojectgroup[this.groupindex.subindex].complexityindex = subcomplexityindex;
                this.detailform.ehprojectgroup[this.groupindex.supindex].complexityindex = supcomplexityindex;
                this.detailform.ehprojectgroup[this.groupindex.extindex].complexityindex = extcomplexityindex;
                this.groupindex = { subindex: null, supindex: null, extindex: null },
                this.subindex = null, this.supindex = null, this.extindex = null
            }
        },

        save: function () {
            var data =  this.detailform.IMTtemplate.filter((v, i) => {
                if (v.IM.qty == "" || v.IM.qty == undefined) {
                    v.IM.qty = 0;
                }
                if(v.IM.isedit == true){
                    return v;
                }
            });
            this.setimttoehgroup();
            this.detailform.IMTtemplate = data;
            this.detailform.projectid = this.project.id;
            this.detailform.projectpartid = this.projectpart.id;
            this.setuporehname == "EH Estimating" ? this.detailform.isehproject = true : this.detailform.isehproject = false;
            this.$http
            .post("project/saveIM", this.detailform)
            .then((response) => {
                this.$message({
                showClose: true,
                message: response.data,
                type: "success",
                });
                this.getIM();
            })
            .catch((err) => {
                this.$bus.$emit("error", err);
            });
        },

        arraySpanMethod({ row, column, rowIndex, columnIndex }) {
            if (row.type == "GP") {
                if (columnIndex === 0) {
                    return [1, 6];
                } else if (columnIndex === 1) {
                    return [0, 0];
                }
            }
            if (row.type == "NRM") {
                if (columnIndex === 0) {
                    return [1, 6];
                } else if (columnIndex === 1) {
                    return [0, 0];
                }
            }
        },

        tablecellClassName: function ({ row, column, rowIndex, columnIndex }) {
            if (row.type == "GP") {
                if(row.datadescription.includes('PART')){
                    return {
                    background: "rgb(105, 104, 104)",
                    color: "white",
                    "font-weight": "bold"
                };
                }
                return {
                    background: "rgb(105, 104, 104)",
                    color: "white",
                };
            }

            if (row.type == "NRM") {
                return "nrmclass";
            }
            if (row.type == "NRM") {
                return "";
            }
        },

        tableRowClassName({ row, rowIndex }) {
            if (row.type == "GP") {
                return "dark-gray-row";
            }
            if (row.type == "NRM") {
                return "nrmclass";
            }
            if (row.type == "MA") {
                return "";
            }
        },
        getheight() {
         this.height = (document.documentElement.clientHeight - 200);
       }
    },
    computed:{
        lastsaved: function () {
            if (this.detailform.completedon) {
                var dt = new Date(this.detailform.completedon);
                return   moment(dt).format('DD-MMM-YYYY');// dt.toLocaleString().replace(/:\d{2}\s/, " ");
            }
        },
          lastsavedproject: function () {
              if (this.project && this.project.createdon) {
                var dt = new Date(this.project.createdon);
                return moment(dt).format("DD-MMM-YYYY"); // dt.toLocaleString().replace(/:\d{2}\s/, " ");
            }
        },

    },
    destroyed() {
       window.removeEventListener('resize', this.getheight);
   },
   mounted() {
        window.addEventListener('resize', this.getheight);
       if(this.$store.state.ERproject.projectid != null && this.$store.state.ERproject.partid != null && this.$store.state.ERproject.version != ""){
          const data = {projectid:this.$store.state.ERproject.projectid,
             partid:this.$store.state.ERproject.partid,
             version:this.$store.state.ERproject.version}
          this.$store.state.ERproject.projectid = null;
          this.$store.state.ERproject.partid = null;
          this.$store.state.ERproject.version = "";
          this.$bus.$emit('addehprojectbyid',data);
          this.setuporehname = "EH Estimating";
      }else if(this.$store.state.ERproject.projectid != null && this.$store.state.ERproject.partid != null){
          const data = { projectid:this.$store.state.ERproject.projectid,
           partid:this.$store.state.ERproject.partid }
          this.Similar.projectids = data;
            this.$bus.$emit('addprojectpart',null);
            this.$store.state.ERproject.projectid = null;
            this.$store.state.ERproject.partid = null;
           this.setuporehname = "As Setup";
      }else{
          this.setuporehname = "As Setup";
      }
    },


};
</script>

<style>
.boder{
    border: black 1px solid;
}
.el-table .nrmclass {
  font-weight: bold;
  background: #f1f1f1;
  color: rgb(0, 0, 0);
}
.el-table .dark-gray-row {
  background: rgb(105, 104, 104);
  color: white;
}
/* // .el-table .el-table__body  tr>td{
//           background-color: #1e71ff!important;
//  }

// .tableBox {
//   th {
//     padding: 0 !important;
//     height: 30px;
//     line-height: 30px;
//     padding-top: 0;
//     padding-bottom: 0;

//   }
//   td {

//     padding-top: 0;
//     padding-bottom: 0;
//     padding: 0 !important;
//     height: 20px;
//     line-height: 20px;
//   }
// }
// .deleteicon {
//   font-size: 15px;
//   color: #f56c6c;
//   font-weight: bold;
// }
// .dark-gray-inputIM .el-input__inner {
//   background: rgb(105, 104, 104);
//   color: white;
//   border: none;
//   font-weight: bold;
//   border-radius: 0%;
// }
// .nrm-inputIM .el-input__inner {
//   background: #f1f1f1;
//   color: rgb(0, 0, 0);
//   border: none;
//   border-radius: 0%;
// } */

.tableBox.el-table--mini td, .el-table--mini th {
    padding: 1px 0;
}
.detail-input .el-input__inner{
      background: rgb(255, 255, 255);
     color: rgb(0, 0, 0);
}
.maroonborder{
    border: maroon 1px solid;
}



</style>
