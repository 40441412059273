<template>
  <div>
    <h2>Major Dimensions</h2>
    <el-row>
      <el-col :span="7"> Project </el-col>
      <el-col :span="17">
        <el-col :span="4"> Gropings </el-col>
        <el-col :span="4"> PSP Criteria 1 </el-col>
        <el-col :span="4"> PSP Criteria 2 </el-col>
        <el-col :span="4"> PSP Criteria 3 </el-col>
        <el-col :span="4"> PSP Criteria 4 </el-col>
        <el-col :span="4"> Chunck Size </el-col>
      </el-col>
    </el-row>
    <hr class="spaceBetween" />
    <el-row>
      <el-col :span="7">

          <!-- <el-select
            v-model="project"
            placeholder=""
            clearable
            class="fullwidth"
          >
            <el-option
              v-for="item in projects"
              :key="item.id"
              :label="item.value"
              :value="item.id"
            >
            </el-option>
          </el-select> -->
           <selectpart :Similar="Similar" @projectpartchange="projectpartchange" >
                 </selectpart>
      </el-col>
      <el-col :span="17">
        <el-col :span="4"> Substructure </el-col>
        <el-col :span="4">
          <div class="cr">
            <el-input
              v-model="criterias"
              placeholder="PSP Criteria 1"
            ></el-input>
          </div>
        </el-col>
        <el-col :span="4">
          <div class="cr">
            <el-input
              v-model="criterias"
              placeholder="PSP Criteria 2"
            ></el-input>
          </div>
        </el-col>
        <el-col :span="4">
          <div class="cr">
            <el-input
              v-model="criterias"
              placeholder="PSP Criteria 3"
            ></el-input>
          </div>
        </el-col>
        <el-col :span="4">
          <div class="cr">
            <el-input
              v-model="criterias"
              placeholder="PSP Criteria 4"
            ></el-input>
          </div>
        </el-col>
        <el-col :span="4">
          <div class="cr">
            <el-input v-model="criterias" placeholder="Cunck Size"></el-input>
          </div>
        </el-col>
      </el-col>
    </el-row>
  <div class="spaceBetween"></div>
    <el-row>
      <el-col :span="7">
        <div class="cr">24-Aug-2020</div>
      </el-col>
      <el-col :span="17">
        <el-col :span="4"> Superstructure </el-col>
        <el-col :span="4">
          <div class="cr">
            <el-input
              v-model="criterias"
              placeholder="PSP Criteria 1"
            ></el-input>
          </div>
        </el-col>
        <el-col :span="4">
          <div class="cr">
            <el-input
              v-model="criterias"
              placeholder="PSP Criteria 2"
            ></el-input>
          </div>
        </el-col>
        <el-col :span="4">
          <div class="cr">
            <el-input
              v-model="criterias"
              placeholder="PSP Criteria 3"
            ></el-input>
          </div>
        </el-col>
        <el-col :span="4">
          <div class="cr">
            <el-input
              v-model="criterias"
              placeholder="PSP Criteria 4"
            ></el-input>
          </div>
        </el-col>
        <el-col :span="4">
          <div class="cr">
            <el-input v-model="criterias" placeholder="Cunck Size"></el-input>
          </div>
        </el-col>
      </el-col>
    </el-row>
    <div  class="spaceBetween"/>
    <el-row>
      <el-col :span="7">
        <el-col :span="5"> Page View </el-col>
        <el-col :span="3">
          <el-button type="primary" size="mini" icon="el-icon-edit"></el-button>
        </el-col>
        <el-col :span="15">
          <div class="cr">
            <el-select
              v-model="project"
              placeholder=""
              clearable
              class="fullwidth"
            >
              <el-option
                v-for="item in projects"
                :key="item.id"
                :label="item.value"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </div>
        </el-col>
      </el-col>
      <el-col :span="17">
        <el-col :span="4"> Externals </el-col>
        <el-col :span="4">
          <div class="cr">
            <el-input
              v-model="criterias"
              placeholder="PSP Criteria 1"
            ></el-input>
          </div>
        </el-col>
        <el-col :span="4">
          <div class="cr">
            <el-input
              v-model="criterias"
              placeholder="PSP Criteria 2"
            ></el-input>
          </div>
        </el-col>
        <el-col :span="4">
          <div class="cr">
            <el-input
              v-model="criterias"
              placeholder="PSP Criteria 3"
            ></el-input>
          </div>
        </el-col>
        <el-col :span="4">
          <div class="cr">
            <el-input
              v-model="criterias"
              placeholder="PSP Criteria 4"
            ></el-input>
          </div>
        </el-col>
        <el-col :span="4">
          <div class="cr">
            <el-input v-model="criterias" placeholder="Cunck Size"></el-input>
          </div>
        </el-col>
      </el-col>
    </el-row>
    <hr class="spaceBetween"/>
  </div>
</template>
<script>
 import selectpart from "./selectpart.vue";
export default {
  data() {
    return {
         Similar:{
            projectPlaceholder:'',
            partPlaceholder:'',
            lastsavedshow:false,
            projectpartshow:true,
            projectsize:21,
            partsize:0,
        },
      criterias: "",
      project: "",
      projects: [],
    };
  },
  methods:{
       projectpartchange: function (Projectobj) {
    this.refresh();
  },
  },
   components: {
     selectpart: selectpart,
  },
};
</script>
<style scoped>
.cr {
  min-width: 30px;
  width: 90%;
  float: center;

}

</style>
