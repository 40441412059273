<template>
    <!-- <div class="form-horizontal">
        <h1 class="col-sm-offset-2">TSA Console</h1>
        <div class="form-group">
            <label class="control-label col-sm-2" for="loginname">Login Name:</label>
            <div class="controls col-sm-2">
                <input type="text" class="form-control" v-model="user.loginname" required>
            </div>
        </div>
        <div class="form-group">
            <label class="control-label col-sm-2" for="password">Password:</label>
            <div class="controls col-sm-2">
                <input type="password" class="form-control" v-model="user.password" required>
            </div>
        </div>
        <div class="form-group" v-if="error">
            <div class="controls col-sm-10 col-lg-offset-2">
                <label v-html="msg"></label>
            </div>
        </div>

        <div class="form-group">
            <div class="controls col-sm-offset-2 col-sm-2">
                <button type="submit" class="btn btn-default" v-on:click="login">Login</button>
            </div>
        </div>
    </div> -->
    <div>
        <div class="loginbox boxsadow">
            <h2>Login </h2>
            <div class="spaceBetween" />
            <p>Welcome to TSA Console</p>
            <div class="spaceBetween" />
            <form>
                <div class="inputBox">
                    <input type="text" :data-value="user.loginname" name="loginname" v-model="user.loginname">
                    <label for="loginname">Login Name</label>
                </div>
                <div class="spaceBetween" />
                <div class="inputBox">
                    <input type="password" name="password" :data-value="user.password" v-model="user.password">
                    <label for="password">Passward</label>
                </div>
                <div class="spaceBetween" />
                <div class="inputBox" style="margin: 0px 0 -0.125rem; text-align: center;">
                    <el-button type="primary" @click="login">Login </el-button>
                </div>

            </form>
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                user: {
                    loginname: "",
                    password: ""
                },
            }
        },
        methods: {
            login: function () {
                this.$http.post("account/login", this.user)
                    .then(response => {
                        this.$bus.$emit('login', response.data);
                    })
                    .catch(error => {
                        this.$bus.$emit('logout', null);
                        if (error.response != null && error.response.data == "Invalid username or password") {
                            this.$message.error(error.response.data);
                        }else{
                            this.$bus.$emit('error', error);
                        }
                    })
            }
        }
    }
</script>
<style>
.boxsadow{
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
   .loginbox {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 336px;
    padding: 2.5rem;
    box-sizing: border-box;
    border: 1px solid #dadce0;
    -webkit-border-radius: 8px;
    border-radius: 8px;

  }

  .loginbox h2 {
    margin: 0px 0 -0.125rem;
    padding: 0;
    color: #fff;
    text-align: center;
    color: #202124;
    font-family: 'Google Sans','Noto Sans Myanmar UI',arial,sans-serif;
    font-size: 24px;
    font-weight: 400;
  }

  .loginbox p {
    font-size: 16px;
    font-weight: 400;
    letter-spacing: .1px;
    line-height: 1.5;
    margin-bottom: 25px;
    text-align: center;
  }

  .loginbox .inputBox {
    position: relative;
  }

  .loginbox .inputBox input {
    width: 93%;
    padding: 0.625rem 10px;
    font-size: 17px;
    letter-spacing: 0.062rem;
    margin-bottom: 1.875rem;
    border: 1px solid #ccc;
    background: transparent;
    border-radius: 4px;

  }

  .loginbox .inputBox label {
    position: absolute;
    top: 0;
    left: 10px;
    padding: 0.625rem 0;
    font-size: 1rem;
    color: grey;
    pointer-events: none;
    transition: 0.5s;
  }

  .loginbox .inputBox input:focus ~ label,
  .loginbox .inputBox input:valid ~ label,
  .loginbox .inputBox input:not([data-value=""]) ~ label {
    top: -1.125rem;
    left: 10px;
    color: #1a73e8;
    font-size: 1.1rem;
    background-color: white;
    height: 10px;
    padding-left: 5px;
    padding-right: 5px;
  }
  .loginbox .inputBox input:focus {
    outline: none;
    border: 2px solid #1a73e8;
  }

</style>
