<template>
    <div >
        <!-- <h1> Welcome {{user.fullname}} to TSA Measurement Console</h1> -->
        <h1 class="font">Hello <span style="color:#800080"> {{user.fullname}} </span>, Welcome to TSA Measurement Console</h1>
        <div class="spaceBetween" />
        <el-row>
            <div v-if="mcupdate.version" style="font-style: italic;" class="el-input ">Version: <span class="bold">{{mcupdate.version}}</span> ({{lastsaved}})</div>
            </el-row>
            <div class="spaceBetween" />
        <el-row class="mcupdatebox">
            <div v-if="mcupdatehide" v-html="mcupdate.content"></div>
            <div v-if="mcupdatehide" class="el-input " style="color: #606266;">  Tick 
                   <span>
                     <el-checkbox v-model="confirm" @change="hidemcupdate"></el-checkbox>
                   </span>  to confirm that you have completed above notes. 
                </div>
              <div class="el-input" style="color: #606266;">
                    <p><span ><span ><span >Please, when using the new version take the time to:</span></span></span></p><ul><li><p><span ><span ><span >Check your work to ensure that everything is as it should be.</span></span></span></p></li><li><p><span ><span ><span >Report on anything that doesn't seem right.</span></span></span></p></li><li><p><span ><span ><span>Make suggestions as to how we could improve the MC.</span></span></span></p></li></ul>
                  </div>
        </el-row>
    </div>
</template>
<script>
import moment from "moment";
    export default {
        data() {
            return {
                confirm:false,
                user: this.user = this.$store.state.user,
                mcupdate:{},
            };
        },
        computed:{
            mcupdatehide(){
                if(this.mcupdate.userreminder == null){
                    return true;
                }else{
                    return false;
                }
            },
            lastsaved: function () {
            if (this.mcupdate.date != undefined) {
                var dt = new Date(this.mcupdate.date);
                return moment(dt).format("DD-MMM-YYYY");
            }
        },
        },
        methods:{
            hidemcupdate(){
                this.$http.post("Account/hidemcupdate",this.mcupdate)
                .then(response => {
                    this.getmcversion();
                })
                .catch(err => {
                    this.hideloading();
                    this.$bus.$emit('error', err);
                });
            },
            getmcversion(){
                this.$http.get("Account/getmcnewversion")
                .then(response => {
                    if(response.data != null){
                        this.$set(this,'mcupdate',response.data);
                        // this.mcupdate = response.data;
                        this.$forceUpdate();
                    }else{
                        this.$set(this,'mcupdate',{});
                    }
                })
                .catch(err => {
                    this.hideloading();
                    this.$bus.$emit('error', err);
                });
            },
        },
        mounted(){
          
        },
        created: function () {
            this.$nextTick(()=>{
                this.getmcversion();
            })
            this.$bus.$on('setuser', (user) => {
                this.$set(this,'user',user);
                // this.user = user;
            });
        }
    }
</script>
<style>
 .font{
      font: italic   30px/30px Georgia, serif;
 }
 .mcupdatebox{
    margin-top: 36px;
    margin-left: 126px;
    padding: 10px;
    max-width: 550px;
    width:100%;
    box-shadow: rgba(136, 165, 191, 0.48) 6px 2px 16px 0px, rgba(255, 255, 255, 0.8) -6px -2px 16px 0px;
 }
 .mcupdatebox a:hover{
    color: #337ab7;
    text-decoration: none;
 }
</style>
