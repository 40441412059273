<template>
    <div>
        <div class="fixdheader">
            <el-row>
                <span class="headerstyle">Dashboard - Sections</span>
            </el-row>
            <hr class="hrstyle" />
        </div>

        <el-row>
            <el-col :span="24">
                <div class="subheaderstyle">MC_T (Library) Created Per NRM Section</div>
                <div>
                    <span class="marginright5px"><b>Display Number Since:</b></span>

                    <span class="marginright5px floatleft">
                        <el-date-picker v-model="templateview.startdate" type="date" size="mini" format="ddd d-MMM-yyyy"
                            :editable="false" :clearable="false" class="displaydate datewidth135"
                            @change="changedatetemplate($event, 'startdate')" align="center" placeholder="From Date">
                        </el-date-picker>
                    </span>

                    <span class="paddingl5 floatleft">
                        <el-date-picker v-model="templateview.enddate" type="date" size="mini" format="ddd d-MMM-yyyy"
                            :editable="false" :clearable="false" class="displaydate datewidth135"
                            @change="changedatetemplate($event, 'enddate')" align="center" placeholder="To Date">
                        </el-date-picker>
                    </span>
                </div>
                <mcechart :options="chartoptions"></mcechart>
            </el-col>
        </el-row>

        <el-row>
            <el-col :span="24">
                <div class="subheaderstyle">BQ_Codes (Library) Created Per User</div>
                <div>
                    <span class="marginright5px"><b>Display Number Since:</b></span>

                    <span class="marginright5px floatleft">
                        <el-date-picker v-model="bqcodeview.startdate" type="date" size="mini" format="ddd d-MMM-yyyy"
                            :editable="false" :clearable="false" class="displaydate datewidth135"
                            @change="changedatebqcode($event, 'startdate')" align="center" placeholder="From Date">
                        </el-date-picker>
                    </span>

                    <span class="paddingl5 floatleft">
                        <el-date-picker v-model="bqcodeview.enddate" type="date" size="mini" format="ddd d-MMM-yyyy"
                            :editable="false" :clearable="false" class="displaydate datewidth135"
                            @change="changedatebqcode($event, 'enddate')" align="center" placeholder="To Date">
                        </el-date-picker>
                    </span>
                </div>
                <mcechart :options="chartoptionsBQ"></mcechart>
            </el-col>
        </el-row>
    </div>
</template>
<script>
import mcechart from "./mcechart.vue";
import moment from 'moment';
export default {
    components: {
        mcechart: mcechart,
    },
    data() {
        return {
            bqcodeview: {
                startdate: "",
                enddate: ""
            },
            templateview: {
                startdate: "",
                enddate: ""
            },
            chartoptions: {
                header: "Templates",
                id: 'MC_Template',
                rows: [],
                labels: { count: 'count', lable: 'section' },
                barcolor: '#569bb9'
            },
            chartoptionsBQ: {
                header: "BQ_Codes",
                id: 'BQ_Code',
                rows: [],
                labels: { count: 'count', lable: 'fullname' },
                barcolor: '#19d4ae'
            }
        }
    },
    methods: {
        getmonth() {
            var currentdate = new Date();
            var day = currentdate.getDate();
            var month = currentdate.getMonth() - 3;
            var year = currentdate.getFullYear();
            var lastthreemonth = new Date(year, month, day);

            this.templateview.startdate = moment(lastthreemonth).format("YYYY-MM-DD").toString();
            this.templateview.enddate = moment(new Date()).format("YYYY-MM-DD").toString();
            this.bqcodeview.startdate = moment(lastthreemonth).format("YYYY-MM-DD").toString();
            this.bqcodeview.enddate = moment(new Date()).format("YYYY-MM-DD").toString();
            this.refresh();
        },

        changedatetemplate(event, name) {
            if (event == null) {
                this.templateview[name] = "";
            }
            else {
                this.templateview[name] = moment(event).format("YYYY-MM-DD").toString();
            }
            this.gettemplates();
        },

        gettemplates() {
            this.$http.post('dashboard/GetTemplatesPerSection', this.templateview)
                .then(response => {
                    this.chartoptions.rows = response.data;
                })
                .catch(err => {
                    this.$bus.$emit('error', err);
                });
        },

        changedatebqcode(event, name) {
            if (event == null) {
                this.bqcodeview[name] = "";
            }
            else {
                this.bqcodeview[name] = moment(event).format("YYYY-MM-DD").toString();
            }
            this.getbqcodeperuser();
        },

        getbqcodeperuser() {
            this.$http.post('dashboard/GetBQCodesPeruser', this.bqcodeview)
                .then(response => {
                    this.chartoptionsBQ.rows = response.data;
                })
                .catch(err => {
                    this.$bus.$emit('error', err);
                });
        },

        refresh: function () {
            this.gettemplates();
            this.getbqcodeperuser();
        }
    },
    
    created: function () {
        this.getmonth();
    }
}
</script>