<template>
    <div :class="!isaddddt ? 'combinebillwidth' : 'combinebillwidthaddddt'">
        <div class="fixdheader">
            <span class="headerstyle">Combine Bills</span>
            <div class="floatright margintb1mm">
                <span v-if="displaymode">
                    <el-input placeholder="Bill Name" class="width290" v-model="view.billname">
                    </el-input>
                </span>

                <span v-if="!displaymode">
                    <el-select class="width155px marginleft4px" placeholder="Combine Method" :clearable="false"
                        v-model="view.combinemode" @change="changecombineformat">
                        <el-option v-for="item in billtypes" :key="item.value" :label="item.name" :value="item.value">
                        </el-option>
                    </el-select>
                </span>

                <span>
                    <el-select class="width100px marginleft4px" placeholder="Type" :clearable="false"
                        v-model="displaymode" @change="changedisplaymode">
                        <el-option v-for="item in pagedisplaymethod" :key="item.value" :label="item.name"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </span>

                <span v-if="displaymode">
                    <span>
                        <el-button type="primary" class="spellbtn marginleft4px btnwithswitch"
                            @click="spellcheckMethod()">
                            Spell Check:
                            <span>
                                <el-switch @change="spellchack()" v-model="spell" class="marginbottom2"
                                    active-color="primary" :active-value="true" :inactive-value="false">
                                </el-switch>
                            </span>
                        </el-button>
                    </span>

                    <span>
                        <el-button type="primary" icon="el-icon-refresh" class="padding7 marginleft4px"
                            @click="refresh()">Refresh</el-button>
                    </span>

                    <span>
                        <el-button type="success" icon="el-icon-document" class="padding7 marginleft4px"
                            @click="getBill()">
                            Create
                            Bill
                        </el-button>
                    </span>
                </span>

                <span>
                    <el-button type="info" icon="el-icon-back" class="padding7 marginleft4px"
                        @click="backpage">Back</el-button>
                </span>
            </div>
            <hr class="hrstyle" />
        </div>

        <div v-if="!displaymode">
            <div v-for="(row, index) in view.details" :key="index">
                <div class="displayflex">
                    <span>
                        <el-select class="inline-input width290" v-model="row.projectid"
                            @change="selectproject($event, row)" placeholder="Please select project" clearable
                            filterable remote popper-class="customselect" reserve-keyword
                            :remote-method="remoteMethodproject">
                            <el-option v-for="item in projectoptions" :key="item.id" :label="item.projectname"
                                :value="item.id">
                                <div>
                                    <span class="left">{{ item.projectname }}</span>
                                    <span class="floatright">{{ item.accessedon | dateformat }}</span>
                                </div>
                            </el-option>
                        </el-select>
                    </span>

                    <span class="marginleft4px">
                        <el-select class="width290" v-model="row.partid" clearable filterable
                            placeholder="Project parts" @change="changepart($event, row)">
                            <el-option v-for="item in row.projectparts" :key="item.id" :label="item.name"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </span>

                    <span class="marginleft4px">
                        <el-select class="width290" placeholder="Section" filterable multiple clearable
                            v-model="row.sectionid" @change="changesections($event, row)">
                            <el-option v-for="item in row.sections" :key="item.id" :label="item.sectiondisplay"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </span>

                    <span class="marginleft4px">
                        <el-button type="primary" icon="el-icon-circle-plus" class="padding7" @click="addnew(index + 1)"
                            :disabled="!editpermission"></el-button>
                    </span>

                    <span class="marginleft4px">
                        <el-button type="danger" icon="el-icon-delete" class="padding7"
                            @click="deleterow(view.details, index)" :disabled="!editpermission"></el-button>
                    </span>

                    <span class="marginleft4px">
                        <el-button type="primary" @click="toggleExpand(row)" class="padding7"
                            :icon="row.isexpanded ? 'el-icon-caret-top' : 'el-icon-caret-bottom'"></el-button>
                    </span>
                </div>
                <hr class="hrstyle" />
                <div class="tabletexttop tablepaddinglr5 tablewordbreak marginb25" v-if="row.isexpanded">
                    <div v-if="view.combinemode == 1 && !myorderingsort">
                        <div :class="myorderingsort ? 'bothcaret' : 'dessuccess2 ascsuccess2'">
                            <el-table :data="row.currentsections" :border="true" @sort-change="sortChange(row)"
                                :span-method="arraySpanMethod">
                                <el-table-column v-for="(column, c) in columns" :key="c" :label="column.label"
                                    :prop="column.prop" :width="column.width" :sortable="column.sortable">
                                    <template v-slot="scope">
                                        <template v-if="column.prop === 'createdon'">
                                            {{ dateformatter(scope.row[column.prop]) }}
                                        </template>
                                        <template v-else-if="column.label === ''">
                                            <el-button type="danger" icon="el-icon-delete" class="padding7"
                                                @click="hiderow(scope.row, index)"
                                                :disabled="!editpermission"></el-button>
                                        </template>
                                        <template v-else>
                                            <div v-html="scope.row[column.prop]"></div>
                                        </template>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                    </div>

                    <div v-else-if="view.combinemode == 2">
                        <el-table :data="row.combinesections" :border="true">
                            <el-table-column v-for="(column, c) in columns" :key="c" :label="column.label"
                                :prop="column.prop" :width="column.width">
                                <template v-slot="scope">
                                    <template v-if="column.prop === 'createdon'">
                                        {{ dateformatter(scope.row[column.prop]) }}
                                    </template>
                                    <template v-else-if="column.label === ''">
                                        <el-button type="danger" icon="el-icon-delete" class="padding7"
                                            @click="hiderow(scope.row, index)" :disabled="!editpermission"></el-button>
                                    </template>
                                    <template v-else>
                                        <div v-html="scope.row[column.prop]"></div>
                                    </template>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>

                    <div v-else>
                        <div :class="myorderingsort ? 'bothcaret' : 'dessuccess2 ascsuccess2'">
                            <el-table :data="[{}]" :border="true" class="tableBox-cell"
                                @header-dragend="handleheaderdragend" :span-method="arraySpanMethod"
                                @sort-change="sortChange(row)">
                                <el-table-column v-for="(column, c) in columns" :key="c" :label="column.label"
                                    :sortable="column.sortable" :width="column.width">
                                </el-table-column>
                            </el-table>
                        </div>

                        <div v-for="(group, j) in row.groupingsections" :key="j">
                            <div class="table-handle center expandcolumnrow cursorstylemove fontsize12 commongrey bold"
                                @dragstart="onDragStart(row, j)" @dragover="onDragOver($event)" @drop="onDrop(j, row)"
                                draggable="true">
                                {{ group.section }}
                            </div>
                            <el-table-draggable :animate="600">
                                <el-table :data="group.items" row-key="id" :show-header="false"
                                    :span-method="arraySpanMethod" :resizable="true" :border="true">
                                    <el-table-column v-for="(column, c) in columns" :key="c" :prop="column.prop"
                                        :width="column.width">
                                        <template v-slot="scope">
                                            <template v-if="column.prop === 'createdon'">
                                                {{ dateformatter(scope.row[column.prop]) }}
                                            </template>
                                            <template v-else-if="column.label === ''">
                                                <el-button type="danger" icon="el-icon-delete" class="padding7"
                                                    @click="hiderow(scope.row, index)"
                                                    :disabled="!editpermission"></el-button>
                                            </template>
                                            <template v-else>
                                                {{ scope.row[column.prop] }}
                                            </template>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </el-table-draggable>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-show="displaymode">
            <customdialog v-if="validationerror" @warnerror="warnerror" :err="qtyobject"></customdialog>

            <div ref="spell" :contenteditable="true" :spellcheck="spell" @click="onspellclick"
                class="spellstyle tablepaddinglr10px">
                <el-table ref="table" :data="detailform.rows" :class="'fullwidth ' + (spell ? 'back' : '')"
                    :cell-class-name="cellClassName" @row-click="rowClick" row-key="id" :show-header="false"
                    :cell-style="{ padding: '0', height: '25px' }">
                    <el-table-column label="" prop="srno" width="30" />
                    <el-table-column label="" prop="detail" width="600">
                        <template slot-scope="scope">
                            <div class="textalign">
                                <span v-html="setNoSpellCheck(scope.row.detail)"></span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="" prop="qty" width="80" align="right">
                        <template slot-scope="scope">
                            <div v-if="!scope.row.isaddddt">
                                {{ commaseparatednumber(scope.row.qty, null) }}
                            </div>
                            <div v-else>
                                {{ commaseparatednumber(scope.row.addddt.qty, null) }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="" prop="unit" width="80">
                        <template slot-scope="scope">
                            <div v-if="!scope.row.isaddddt">
                                <span v-html="setNoSpellCheck(scope.row.unit)"></span>
                            </div>
                            <div v-else>
                                <span v-html="setNoSpellCheck(scope.row.addddt.unitold)"></span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="Add" prop="addqty" width="80" align="right">
                        <template slot-scope="scope">
                            <div v-if="scope.row.isaddddt">
                                {{ commaseparatednumber(scope.row.addddt.addqty, null) }}
                            </div>
                        </template>
                    </el-table-column>

                    <el-table-column label="Ddt" prop="ddtqty" width="80" align="right">
                        <template slot-scope="scope">
                            <div v-if="scope.row.isaddddt">
                                {{ scope.row.addddt.ddtqty ? '(' + commaseparatednumber(scope.row.addddt.ddtqty, null) +
                                    ')' : scope.row.addddt.ddtqty }}
                            </div>
                        </template>
                    </el-table-column>

                    <el-table-column label="Outcome" prop="totalqty" width="90" align="right">
                        <template slot-scope="scope">
                            <div v-if="scope.row.isaddddt">
                                {{ commaseparatednumber(scope.row.addddt.totalqty, null) }}
                            </div>
                        </template>
                    </el-table-column>

                    <el-table-column label="Outcome Unit" prop="unit" width="100">
                        <template slot-scope="scope">
                            <div v-if="scope.row.isaddddt">
                                <span v-html="setNoSpellCheck(scope.row.addddt.unit)"></span>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import customdialogbox from "./customdialogbox.vue";
import ElTableDraggable from './CustomTableDraggable.vue';
export default {
    data() {
        return {
            columns: [],
            myorderingsort: false,
            isaddddt: false,
            height: (document.documentElement.clientHeight - 130),
            pagedisplaymethod: [{ name: "Bill Setup", value: false }, { name: "Bill View", value: true }],
            displaymode: false,
            billtypes: [{ name: "Combine as Separates", value: 1 }, { name: "Reorder and Combine", value: 2 }],
            view: {
                details: [],
                billname: "",
                combinemode: 1,
            },
            projects: [],
            projectoptions: [],
            spell: true,
            spellclick: null,
            togglespell: true,
            detailform: { rows: [] },
            qtyobject: {
                message: [],
                type: "",
                visible: false,
                sourceqty: "",
                qtydown: ""
            },
            validationerror: false,
            warncheck: false,
            usertick: false,
            excludewords: ""
        };
    },

    components: { customdialog: customdialogbox, ElTableDraggable },

    computed: {
        getusedsections() {
            return function (selectedid, sections) {
                let ids = [];
                this.view.details.forEach(x => {
                    if (x.sectionid.length > 0) {
                        ids = [...ids, ...x.sectionid];
                    }
                });
                let current = [];
                current = ids.filter(x => !selectedid.includes(x));
                sections.forEach(s => {
                    if (current.includes(s.id)) {
                        s.disable = true;
                    }
                    else {
                        s.disable = false;
                    }
                })
                return sections;
            }
        }
    },

    methods: {
        getheight() {
            this.height = (document.documentElement.clientHeight - 130);
        },

        onDragStart(row, index) {
            row.draggedtableindex = index;
        },

        onDragOver(event) {
            event.preventDefault();
        },

        onDrop(index, row) {
            if (row.draggedtableindex !== null && row.draggedtableindex !== index) {
                this.moveTable(row.draggedtableindex, index, row);
                row.draggedtableindex = null;
            }
        },

        moveTable(fromIndex, toIndex, row) {
            const movedTable = row.groupingsections.splice(fromIndex, 1)[0];
            row.groupingsections.splice(toIndex, 0, movedTable);
        },

        handleheaderdragend(newWidth, oldWidth, column, event) {
            const index = this.columns.findIndex(col => col.label === column.label);
            if (index !== -1) {
                this.$set(this.columns, index, { ...this.columns[index], width: newWidth });
            }
        },

        sortChange() {
            this.myorderingsort = this.myorderingsort == true ? false : true;
        },

        arraySpanMethod({ _row, columnIndex }) {
            if (columnIndex === 1) {
                return [1, 2];
            } else if (columnIndex === 2) {
                return [0, 0];
            }
        },

        addnew(index) {
            let itm = {
                p: {},
                pp: {},
                projectid: null,
                partid: null,
                projectparts: [],
                isexpanded: true,
                sections: [],
                sectionid: [],
                partsections: [],
                currentsections: [],
                groupingsections: [],
                combinesections: [],
                draggedtableindex: null,
            }
            this.view.details.splice(index, 0, itm);
        },

        remoteMethodproject(query) {
            if (query == "") {
                this.projectoptions = this.projects;
            }
            else {
                this.projectoptions = this.projects.filter(item => {
                    return item.projectname.toLowerCase().indexOf(query.toLowerCase()) > -1;
                });
            }
        },

        selectproject(item, row) {
            row.projectid = null;
            row.p = {};
            row.pp = {};
            row.projectparts = [];
            row.partid = null;
            row.sections = [];
            row.sectionid = [];
            row.partsections = [];
            row.currentsections = [];
            row.groupingsections = [];
            row.combinesections = [];
            row.addddtsectionsview = [];
            this.projectoptions = this.projects;
            if (item != "") {
                let newp = this.projects.find(x => x.id == item);
                row.projectid = item;
                row.p = JSON.parse(JSON.stringify(newp));
                row.p.projectmetadataview = { useaddddt: true };
                this.getsections(row);
            }
        },

        getsections(row) {
            this.$http.post("project/getsections", row.p)
                .then((response) => {
                    row.projectparts = response.data.projectparts;
                    if (row.projectparts.length == 0 && row.p.projectname != "") {
                        row.projectparts.unshift({ id: 0, name: "Single Bill" });
                    }
                    row.partsections = [...response.data.projectsectionsview, ...response.data.addddtsectionsview];
                    row.addddtsectionsview = response.data.addddtsectionsview;
                    let newpartsections = this.mergepartandsectionname(row.partsections, row.projectparts);
                    row.currentsections = this.ordersection(newpartsections);
                    row.groupingsections = this.grouping(row.currentsections);
                    row.combinesections = this.mergeSections(row.currentsections);
                }).catch((err) => {
                    this.$bus.$emit("error", err);
                });
        },

        changepart(event, row) {
            row.pp = {};
            row.partid = null;
            row.sections = [];
            row.sectionid = [];
            row.currentsections = [];
            row.groupingsections = [];
            row.combinesections = [];
            if (event !== "") {
                let part = row.projectparts.find(pp => pp.id == event);
                row.pp = part;
                if (event == 0) {
                    row.partid = 0;
                    row.sections = row.partsections.filter(s => s.projectPartId == 0);
                } else {
                    row.partid = event;
                    row.sections = row.partsections.filter(s => s.projectPartId == event);
                }

                let newpartsections = this.mergepartandsectionname(row.sections, row.projectparts);
                row.currentsections = this.ordersection(newpartsections);
            } else {
                let newpartsections = this.mergepartandsectionname(row.partsections, row.projectparts);
                row.currentsections = this.ordersection(newpartsections);
            }
            row.groupingsections = this.grouping(row.currentsections);
            row.combinesections = this.mergeSections(row.currentsections);
        },

        mergepartandsectionname(details, parts) {
            let newpartsections = JSON.parse(JSON.stringify(details));
            for (let pp = 0; pp < parts.length; pp++) {
                const part = parts[pp];
                newpartsections.forEach(ps => {
                    if (ps.projectPartId == part.id) {
                        let sectiondisplaywithpart = `${ps.sectiondisplay} - ${part.name}`;
                        ps.sectiondisplay = sectiondisplaywithpart;
                    }
                });
            }
            return newpartsections;
        },

        changesections(event, row) {
            row.sectionid = event;
            let newsections = row.sections.filter(s => row.sectionid.includes(s.id));
            let newpartsections = this.mergepartandsectionname(newsections, row.projectparts);
            row.currentsections = this.ordersection(newpartsections);
            row.groupingsections = this.grouping(row.currentsections);
            row.combinesections = this.mergeSections(row.currentsections);
        },

        grouping(details) {
            var newarray = details.slice();
            return newarray.reduce((acc, item) => {
                let group = acc.find(group => group.section === item.section);
                if (!group) {
                    group = { section: item.section, items: [] };
                    acc.push(group);
                }
                group.items.push(item);
                return acc;
            }, []);
        },

        ordersection(details) {
            return details.sort((a, b) => a.section.localeCompare(b.section));
        },

        dateformatter(cellValue) {
            if (cellValue) {
                return moment(cellValue).format("ddd DD-MMM-YYYY");
            }
            return cellValue;
        },

        toggleExpand(row) {
            row.isexpanded = row.isexpanded == true ? false : true;
        },

        deleterow(details, index) {
            details.splice(index, 1);
            if (details.length <= 0) {
                this.addnew(0);
            }
            // this.$confirm('Are you sure you want to Delete?', 'Warning', {
            //     confirmButtonText: 'OK',
            //     cancelButtonText: 'Cancel',
            //     type: 'warning'
            // }).then(() => {
            // }).catch(() => {
            //     this.$message({
            //         showClose: true,
            //         type: 'info',
            //         message: 'Delete canceled'
            //     });
            // });
        },

        hiderow(item, viewindex) {
            const rowIdToDelete = item.id;
            for (let i = 0; i < this.view.details.length; i++) {
                if (i === viewindex) {
                    const row = this.view.details[i];
                    if (this.view.combinemode == 2) {
                        row.currentsections = row.currentsections.filter(x => !rowIdToDelete.includes(x.id));
                        row.groupingsections = this.grouping(row.currentsections);
                        row.combinesections = row.combinesections.filter(obj => !rowIdToDelete.some(id => obj.id.includes(id)));
                        row.sectionid = row.sectionid.filter(val => !rowIdToDelete.includes(val));
                    } else {
                        row.sectionid = row.sectionid.filter(val => val != rowIdToDelete);
                        row.currentsections = row.currentsections.filter(x => x.id != rowIdToDelete);
                        row.combinesections = this.mergeSections(row.currentsections);

                        if (this.myorderingsort) {
                            let found = false;
                            for (let g = 0; g < row.groupingsections.length; g++) {
                                const group = row.groupingsections[g];
                                for (let j = 0; j < group.items.length; j++) {
                                    const obj = group.items[j];
                                    if (obj.id === rowIdToDelete) {
                                        group.items.splice(j, 1); // Remove the item
                                        found = true;
                                        break; // Break the inner loop
                                    }
                                }
                                if (found) break; // Break the outer loop if the item was found and removed
                            }
                            const filteredSections = row.groupingsections.filter(section => section.items.length > 0);
                            row.groupingsections = filteredSections;
                        }
                        else {
                            row.groupingsections = this.grouping(row.currentsections);
                        }
                    }
                }
            }
        },

        backpage() {
            if (this.displaymode) {
                this.isaddddt = false;
                this.displaymode = false;
            } else if (!this.displaymode) {
                this.$router.go(-1);
            }
        },

        validateview(callback) {
            let msg = [];
            for (let i = 0; i < this.view.details.length; i++) {
                const row = this.view.details[i];
                if (row.projectid == null) {
                    msg.push("Please select project.");
                }
                if (row.currentsections.length <= 0 && row.projectid != null) {
                    msg.push(`The section list for the <span style="color:red;">${row.p.projectname}</span> project does not contain any sections. Please remove this project.`);
                }
                // if (row.partid === null) {
                //     msg.push("Please select project part.");
                // }
                // if (row.sectionid.length <= 0) {
                //     msg.push("Please select section.");
                // }
            }
            var duplicates = this.findDuplicateObjects(this.view.details);
            if (duplicates.length > 0) {
                msg.push("<b>MC cannot combine the same bill and they need to delete identical bills from either row for it to work</b>");
                const str = duplicates.join('<br />');
                msg.push(str);
            }
            if (msg.length > 0) {
                this.displaymode = false;
                let style = '<style> .el-message-box {width:35%;} </style>';
                this.$alert(msg.join('<br />') + style, "Invalid Data", { dangerouslyUseHTMLString: true });
            }
            else {
                callback();
            }
        },

        findDuplicateObjects(detail) {
            const idCounts = {};
            const duplicates = [];
            detail.forEach(item => {
                const newsection = item.currentsections;
                newsection.forEach(s => {
                    const id = s.id;
                    idCounts[id] = (idCounts[id] || 0) + 1;
                    if (idCounts[id] > 1 && !duplicates.some(dup => dup.id === id)) {
                        duplicates.push(s.sectiondisplay);
                    }
                });
            });

            return duplicates;
        },

        changedisplaymode() {
            if (this.displaymode) {
                this.validateview(() => {
                    this.refresh();
                });
            }
        },

        settablecolumns() {
            return [{ label: 'Section', prop: 'section', sortable: false, width: 250 },
            { label: 'Section Display', prop: 'sectiondisplay', sortable: false, width: 150 },
            { label: 'Order', prop: '', sortable: true, width: 150 },
            { label: 'Comment', prop: 'comment', sortable: false, width: '' },
            { label: 'Created On', prop: 'createdon', sortable: false, width: 120 },
            { label: '', prop: '', sortable: false, width: 40 }];
        },

        changecombineformat(event) {
            let newcolumns = this.settablecolumns();
            if (event == 2) {
                for (let i = 0; i < this.view.details.length; i++) {
                    const element = this.view.details[i];
                    let mergedsections = this.mergeSections(element.currentsections);
                    element.combinesections = mergedsections;
                }
                this.columns.forEach(c => {
                    if (c.prop == 'sectiondisplay') {
                        c.width = 300;
                    }
                });
                newcolumns = this.columns.filter(x => x.label != 'Order');
            }
            this.columns = newcolumns;
        },

        mergeSections(sections) {
            const mergedSections = {};
            sections.forEach(section => {
                const { id, section: sectionKey, sectiondisplay, comment, createdon, projectid, isaddddt } = section;
                const key = `${sectionKey}-${isaddddt}`;
                if (!mergedSections[key]) {
                    mergedSections[key] = {
                        id: [id],
                        comment: comment,
                        section: sectionKey,
                        createdon: createdon,
                        projectid: projectid,
                        isaddddt: isaddddt,
                        sectiondisplay: [sectiondisplay]
                    };
                } else {
                    mergedSections[key].id.push(id);
                    mergedSections[key].sectiondisplay.push(sectiondisplay);
                }
            });

            const result = Object.values(mergedSections).map(section => {
                return {
                    id: section.id,
                    projectid: section.projectid,
                    section: section.section,
                    isaddddt: section.isaddddt,
                    sectiondisplay: section.id.length > 1 ? `(${section.sectiondisplay.join(') +<br /> (')})` : section.sectiondisplay[0],
                    comment: section.comment,
                    createdon: section.createdon,
                };
            });
            return result;
        },

        setuniquesectionsid(details, column) {
            const setunique = new Set();
            return details.filter(u => {
                if (!setunique.has(u[column])) {
                    setunique.add(u[column]);
                    return true;
                }
                return false;
            }).map(x => x[column]);
        },

        setcommonsection(details) {
            let filters = [];
            for (let j = 0; j < details.length; j++) {
                const element = details[j];
                let values = "";
                let sectiondisplay = "";
                if (this.view.combinemode == 2) {
                    values = element.id.join(',');
                    sectiondisplay = element.sectiondisplay.replace(/<br \/>/g, '');
                    filters.push({
                        projectsectionid: values,
                        sectiondisplay: sectiondisplay
                    });
                }
                else {
                    if (this.myorderingsort) {
                        for (let g = 0; g < element.items.length; g++) {
                            const group = element.items[g];
                            filters.push({
                                projectsectionid: group.id.toString(),
                                sectiondisplay: group.sectiondisplay
                            });
                        }
                    }
                    else {
                        values = element.id.toString();
                        sectiondisplay = element.sectiondisplay;
                        filters.push({
                            projectsectionid: values,
                            sectiondisplay: sectiondisplay
                        });
                    }
                }
            }
            return filters;
        },

        modifybillviewobject() {
            let projectids = [];
            let filters = [];
            let mapid = [];
            for (let i = 0; i < this.view.details.length; i++) {
                const row = this.view.details[i];
                let exists = projectids.find(val => val === row.projectid);
                if (exists == undefined) {
                    projectids.push(row.projectid);
                }
                if (this.view.combinemode == 1 && this.myorderingsort) {
                    let grouped = row.groupingsections.flatMap(section => section.items.map(item => item.id));
                    mapid = [...mapid, ...grouped];
                }
                else {
                    let newcurrent = row.currentsections.map(x => x.id);
                    mapid = [...mapid, ...newcurrent];
                }

                let obj = {
                    projectid: row.projectid,
                    combinebillsections: []
                }

                if (this.view.combinemode == 2) {
                    obj.combinebillsections = this.setcommonsection(row.combinesections);
                }
                else {
                    if (this.myorderingsort) {
                        obj.combinebillsections = this.setcommonsection(row.groupingsections);
                    }
                    else {
                        obj.combinebillsections = this.setcommonsection(row.currentsections);
                    }
                }
                filters.push(obj);
            }
            let projectIdsString = projectids.join(',');
            let sectionsIdsString = mapid.join(',');

            let data = {
                billname: this.view.billname,
                combinemode: this.view.combinemode,
                projectid: projectIdsString,
                projectsectionid: sectionsIdsString,
                sectionfilters: filters
            };
            return data;
        },

        refresh() {
            this.displaymode = false;
            this.isaddddt = false;
            let data = this.modifybillviewobject();
            this.showloading();
            this.$http.post("project/getcombinebillview", data)
                .then(response => {
                    var isaddddtcheck = false;
                    response.data.rows.forEach((v, i) => {
                        if (!isaddddtcheck && v.isaddddt) {
                            isaddddtcheck = true;
                        }
                        v.id = i + 1;
                    });
                    this.isaddddt = isaddddtcheck;
                    this.displaymode = true;
                    this.detailform = response.data;
                    this.$refs.spell.focus();
                    this.checkEmptyTemplate();
                    this.hideloading();

                    const allSections = [];
                    this.view.details.forEach(detail => {
                        detail.currentsections.forEach(section => {
                            allSections.push(section.section);
                        });
                    });

                    this.getallbillregexdetail(allSections);

                })
                .catch(err => {
                    this.hideloading();
                    this.$bus.$emit("error", err);
                });
        },

        spellcheckMethod() {
            if (this.togglespell) {
                this.spell = this.spell == true ? false : true;
            }
            this.spellchack();
            this.togglespell = true;
        },

        spellchack() {
            this.togglespell = false;
            this.$refs.spell.focus();
        },

        onspellclick() {
            if (this.spell) {
                this.spell = false;
            }
        },

        cellClassName({ row, column, rowIndex, columnIndex }) {
            if (!row.isaddddt) {
                var cls = [];
                if (columnIndex == 1) {
                    if (row.bold) {
                        cls.push("bold");
                    }
                    if (row.underline) {
                        cls.push("underline");
                    }
                }
                if (row.pagebreak && this.displaymode) {
                    cls.push("pagebreak");
                }
                return cls.join(" ");
            } else {
                var cls = [];
                if (columnIndex == 1) {
                    if (row.bold) {
                        cls.push("bold");
                    }
                    if (row.underline) {
                        cls.push("underline");
                    }
                    if (row.addddt.match == 0) { //row deleted //&& row.qty > 0
                        cls.push("red");
                    }
                    if (row.addddt.match == 1) { //row added //&& row.totalqty > 0
                        cls.push("green");
                    }
                    if (!row.detailmatch) {
                        //cls.push("blue");
                    }
                } else if (columnIndex == 4) { //Add
                    if (row.addddt.addqty) {
                        cls.push("bold green");
                    }
                }
                else if (columnIndex == 5) { //Ddt
                    if (row.addddt.ddtqty) {
                        cls.push("bold red");
                    }
                }
                return cls.join(" ");
            }
        },

        rowClick: function (row, event, column) {
            if (!row.isaddddt && !row.bqcode) return;
            if (row.isaddddt && !row.addddt.bqcode) return;
            this.$confirm("Would you like to edit this activity?", "Move to edit?",
                {
                    confirmButtonText: "Yes",
                    cancelButtonText: "Cancel",
                    type: ""
                }).then(() => {
                    var routname = "/projectpage";
                    if (row.origin != null) {
                        if (row.origin == 1) {
                            routname = "/projectamos";
                        }
                        if (row.origin == 2) {
                            routname = "/projectpage";
                        }
                    }
                    window.setTimeout(() => {
                        if (!row.isaddddt) {
                            this.$store.state.billbqcode = row.bqcode;
                            this.$store.state.billasterisk = row.asterisk;
                            this.$store.state.billspecification = row.specification;
                        } else {
                            this.$store.state.billbqcode = row.addddt.bqcode;
                            this.$store.state.billasterisk = row.addddt.asterisk;
                        }
                        this.$router.push(routname);
                    }, 100);
                }).catch(() => {
                    this.spell = true;
                });
        },

        checkEmptyTemplate: function () {
            if (this.displaymode) {
                let msg = [];
                for (let index = 0; index < this.detailform.sectionfilters.length; index++) {
                    const element = this.detailform.sectionfilters[index];
                    const p = this.projectoptions.find(x => x.id == element.projectid);
                    let warnings = [];
                    for (let j = 0; j < element.combinebillsections.length; j++) {
                        const cbs = element.combinebillsections[j];
                        if (cbs.emptytemplates.length > 0) {
                            let projectname = `<b>${p.projectname}</b>`;
                            let exist = warnings.find(x => x == projectname);
                            if (exist == undefined) {
                                warnings.push(projectname);
                            }
                            warnings.push(`<b>${cbs.sectiondisplay}</b>`);
                            let emp = cbs.emptytemplates.join("<br />");
                            warnings.push(emp + "<br />");
                        }
                    }
                    msg = [...msg, ...warnings];
                }
                if (msg.length > 0) {
                    msg.unshift("<b>Please check the template(s) given below for template rows</b> <br />");
                    let style = '<style> .el-message-box { width: 500px; } .el-message-box__content { height: 400px; overflow-y: auto; } </style>';
                    this.$alert(msg.join("<br />") + style, "Empty Templates", {
                        dangerouslyUseHTMLString: true
                    });
                } else {
                    let existminusqty = false;
                    for (let index = 0; index < this.detailform.rows.length; index++) {
                        const element = this.detailform.rows[index];
                        if (element.qty < 0) {
                            existminusqty = true;
                            break;
                        }
                    }

                    if (existminusqty) {
                        let msg = "This bill contains a negative quantity. Please confirm or edit quantity.";
                        let style = '<style> .el-message-box__content { height: 80px; overflow-y: auto; } </style>';
                        this.$alert(msg + style, "Warning", {
                            dangerouslyUseHTMLString: true
                        });
                    }
                }
            }
        },

        myvalidate(callback) {
            let msg = [];
            if (this.view.billname == null || this.view.billname.trim() == "") {
                msg.push("Bill Name is required.");
            }
            if (msg.length > 0) {
                let style = '<style> .el-message-box {width:35%;} </style>';
                this.$alert(msg.join('<br />') + style, "Invalid Data", { dangerouslyUseHTMLString: true });
            }
            else {
                callback();
            }
        },

        getBill: function () {
            this.myvalidate(() => {
                if (this.checkQty()) {
                    let data = this.modifybillviewobject();
                    data.ischeck = this.usertick;
                    let url = "project/exportCombineBill";
                    this.showloading();
                    this.$http.post(url, data, { responseType: "blob" })
                        .then(response => {
                            var blob = response.data;
                            this.warncheck = false;
                            let fileName = "";
                            if (this.view.billname != null && this.view.billname.trim() != "") {
                                fileName = `${this.view.billname}.xlsx`;
                            }
                            var link = document.createElement("a");
                            link.href = window.URL.createObjectURL(blob);
                            link.download = fileName;
                            link.click();
                            this.hideloading();
                        })
                        .catch(err => {
                            this.warncheck = false;
                            this.hideloading();
                            this.$bus.$emit("error", err);
                        });
                }
            });
        },

        warnerror: function (warncheck) {
            this.warncheck = warncheck;
            if (this.warncheck) {
                this.usertick = true;
                this.getBill();
                this.validationerror = false;
            }
        },

        checkQty: function () {
            var qtywarn = [];
            var boxfill = false;
            this.qtyobject = {
                message: [],
                type: "",
                visible: false,
                sourceqty: "",
                qtydown: ""
            };

            let bodymsg = [];
            let footermsg = [];
            let b1 = "The data set that you wish to view or export as a Bill of Quantities appears to <br>not match with last saved amos data <br> Please, repeat 'Save to project' action within amos tab<br><br>";
            let b2 = "The data set that you wish to view or export as a Bill of Quantities appears to contain RWC or CQC errors.<br> Please check the relevant RWC and CQC reports and resolve any issues before proceeding to create the bill<br><br>";
            let b3 = "The data set that you wish to view or export as a Bill of Quantities appears to contain Bill view errors.<br>";
            let f1 = "Amos-Project data mismatch Identified<br>";
            let f2 = "RWC / CQC Errors Identified<br>";
            let f3 = "Bill view Errors Identified<br>";

            for (let index = 0; index < this.detailform.sectionfilters.length; index++) {
                const element = this.detailform.sectionfilters[index];
                let warninghead = "<br/><b>Project page warnings<b/>";
                for (let j = 0; j < element.combinebillsections.length; j++) {
                    const cbs = element.combinebillsections[j];
                    let cbswarn = [];
                    let sectiondisplay = `<b>${cbs.sectiondisplay}<b/><br/>`;

                    cbs.sections.forEach(s => {
                        if (s.amoseditedon != s.amossavedtoprojecton) {
                            let b1exist = bodymsg.find(x => x == b1);
                            if (b1exist == undefined) {
                                bodymsg.push(b1);
                            }

                            let f1exist = footermsg.find(x => x == f1);
                            if (f1exist == undefined) {
                                footermsg.push(f1);
                            }

                            boxfill = true;
                        }
                        if (this.displaymode) {
                            if (s.errors != null) {
                                let existheading = qtywarn.find(x => x == warninghead);
                                if (existheading == undefined) {
                                    qtywarn.push(warninghead);
                                }
                                let exist = cbswarn.find(x => x == sectiondisplay);
                                if (exist == undefined) {
                                    cbswarn.push(sectiondisplay);
                                }
                                cbswarn.push(s.errors);
                            }
                        }
                    });
                    qtywarn = [...qtywarn, ...cbswarn];

                    var msgcheck = false;
                    let ids = cbs.projectsectionid.split(",");

                    for (let i = 0; i < ids.length; i++) {
                        const sectionid = ids[i];
                        let cqc = cbs.cqclist.find(c => c.projectsectionid == sectionid);
                        let rwc = cbs.rwclist.find(r => r.projectsectionid == sectionid);

                        if (!this.warncheck && (cqc == undefined || rwc == undefined)) {
                            let b2exist = bodymsg.find(x => x == b2);
                            if (b2exist == undefined) {
                                bodymsg.push(b2);
                            }

                            let f2exist = footermsg.find(x => x == f2);
                            if (f2exist == undefined) {
                                footermsg.push(f2);
                            }

                            boxfill = true;
                            msgcheck = true;
                        } else if (!this.warncheck && (cqc.signoff != true || rwc.signoff != true) && msgcheck == false) {
                            let b2exist = bodymsg.find(x => x == b2);
                            if (b2exist == undefined) {
                                bodymsg.push(b2);
                            }

                            let f2exist = footermsg.find(x => x == f2);
                            if (f2exist == undefined) {
                                footermsg.push(f2);
                            }
                            boxfill = true;
                        }
                    }
                }
            }

            if (this.displaymode) {
                this.detailform.rows.forEach((v, i) => {
                    v.id = i + 1;
                    if (v.detail == "*") {
                        qtywarn.push("Item " + v.srno + " has * within the description. Bill export is disabled. Please, fill the Asterisk cell within Project tab");
                    }
                    if (v.qty == 0) {
                        qtywarn.push("Undefined item has quantity 0. Bill export is disabled. Please, correct the Dim cell value within Project tab or reimport data(?)");
                    }
                });

                if (this.detailform.ssqty != this.detailform.billqty) {
                    qtywarn.push("<b>Qty Mismatch</b><br/>Sorting sheet qty total"
                        + this.detailform.ssqty.toFixed(3) + this.detailform.billqty.toFixed(3)
                        + "<br/>" + "difference " + (this.detailform.billqty - this.detailform.ssqty).toFixed(3)
                        + "<br/>" + "Total Round Up " + this.detailform.totalroundup.toFixed(3)
                        + "<br/>" + "Total Round Down " + this.detailform.totalrounddown.toFixed(3));
                }

                if (qtywarn.length > 0) {
                    let b3exist = bodymsg.find(x => x == b3);
                    if (b3exist == undefined) {
                        bodymsg.push(b3);
                    }

                    let f3exist = footermsg.find(x => x == f3);
                    if (f3exist == undefined) {
                        footermsg.push(f3);
                    }

                    this.qtyobject.message = qtywarn;
                    boxfill = true;
                }
            }
            this.qtyobject.sourceqty = bodymsg.join('');
            this.qtyobject.qtydown = footermsg.join('');
            this.qtyobject.visible = boxfill;
            this.qtyobject.type = "qty";

            this.validationerror = true;
            var dbtime = false;
            if (boxfill == false) {
                dbtime = this.warncheck;
                this.warncheck = true;
            }
            if (!this.warncheck) {
                this.warncheck = dbtime;
                return false;
            } else {
                this.warncheck = dbtime;
                return true;
            }
        },

        getallbillregexdetail: function (allsections) {
            this.$http.post("project/getAllBilSpellCheckExcludeWords", allsections)
                .then(response => {
                    if (response.data.length > 0) {
                        this.excludewords = response.data.join(' || ');
                    }
                })
                .catch(err => {
                    this.$bus.$emit("error", err);
                });
        },

        setNoSpellCheck(rowdetail) {
            if (rowdetail) {
                if (this.excludewords) {
                    return this.highlightWords(rowdetail, this.excludewords);
                }
            }

            return rowdetail;
        },

        highlightWords: function (inputString, regexValue) {
            // Split by '|' and trim spaces
            const patterns = regexValue.split('|').map(pattern => pattern.trim()).filter(pattern => pattern.length > 0);

            // Join patterns with '|' and escape special characters
            const escapedPatterns = patterns.map(pattern => pattern.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')).join('|');

            // Create a case-sensitive regex pattern with global flag
            const regexPattern = new RegExp(`\\b(${escapedPatterns})\\b`, 'g');

            // Replace matched words with span-wrapped words
            const resultString = inputString.replace(regexPattern, (match) => {
                return `<span spellcheck="false">${match}</span>`;
            });

            return resultString;
        },
    },

    destroyed() {
        window.removeEventListener('resize', this.getheight);
    },

    mounted: function () {
        window.addEventListener('resize', this.getheight);
        if (this.displaymode) {
            this.spell = true;
            this.$set(this.$refs.spell, 'autofocus', true);
            this.$set(this.$refs.spell, 'spellcheck', true);
            this.$refs.spell.focus();
        }
    },

    created() {
        this.$training.getallprojects().then((data) => {
            this.projectoptions = data;
            this.projects = data;
        });
        this.addnew(0);
        this.columns = this.settablecolumns();
    }
}
</script>

<style itemscope>
.spellbtn.el-button--mini,
.el-button--mini.is-round {
    padding: 3px 15px;
}

.pagebreak {
    border-top: double;
}

.el-row {
    margin-bottom: 0px;
}

.el-table::before {
    height: 0;
}

[contenteditable] {
    outline: 0px solid transparent;
}

.back {
    z-index: -1;
}

.spellstyle:focus {
    caret-color: transparent !important;
}

.bothcaret .el-table .sort-caret.descending {
    border-top-color: rgb(95, 236, 19) !important;
}

.bothcaret .el-table .sort-caret.ascending {
    border-bottom-color: rgb(95, 236, 19) !important;
}

.ascsuccess2 .el-table .sort-caret.ascending {
    border-bottom-color: #C0C4CC !important;
}

.dessuccess2 .el-table .sort-caret.descending {
    border-top-color: #C0C4CC !important;
}
</style>