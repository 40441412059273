<template>
    <div class="inline">
        <div>
            <el-popover placement="bottom" width="200" v-model="media.mousehover" trigger="hover">
                <div @click="showpreview(media)">
                    <span class="previewbox" v-if="media.extention == 'xlsx'">
                        <div class="fontsize12" :class="currentfile(media) ? 'urlinkred' : 'UR-Link'">
                            {{ media.name }}
                        </div>
                        <div class="center">
                            <i class="el-icon-document iconsize90"></i>
                        </div>
                    </span>

                    <span v-if="extentions.includes(media.extention)">
                        <img :src="media.link" class="previewbox" />
                    </span>

                    <span v-if="media.extention == 'mp4'">
                        <video controls class="previewbox">
                            <source :src="media.url" type="video/mp4">
                            Your browser does not support the video tag.
                        </video>
                    </span>

                    <span v-if="media.extention == 'mp3'">
                        <audio controls class="previewbox">
                            <source :src="media.link" type="audio/ogg">
                            <source :src="media.link" type="audio/mpeg">
                            Your browser does not support the audio element.
                        </audio>
                    </span>
                </div>

                <!-- style="overflow: hidden; text-overflow: ellipsis;" -->
                <div slot="reference"
                    class="verticalalignmiddle inline white-space-nowrap marginl5 marginright5px fontsize12"
                    :class="currentfile(media) ? 'urlinkred' : 'UR-Link'" @mouseenter="previewsnip(media)">{{
                        media.name }}</div>
            </el-popover>
        </div>

        <div>
            <el-dialog :title="selectedmedia.name" :visible="(selectedmedia.showdialog || newisible)"
                :before-close="mediadialogClose" class="dialogboxpadding" width="70%">
                <div>
                    <el-row v-if="type == 'dropbox'">
                        <el-col :span="24">
                            <el-button type="primary">
                                <el-link class="externallink" :underline="false" :href="selectedmedia.externalurl"
                                    target="_blank">External Link</el-link>
                            </el-button>

                            <el-button type="primary" icon="el-icon-download" @click="downloadmedia(selectedmedia)">
                                Download
                            </el-button>

                            <el-button type="danger" icon="el-icon-delete" class="marginl5" :disabled="!editpermission"
                                @click="deletemedia(selectedmedia)">
                            </el-button>
                        </el-col>
                    </el-row>

                    <span class="previewbox" v-if="selectedmedia.extention == 'xlsx'">
                        <div class="center">
                            <i class="el-icon-document iconsize90"></i>
                        </div>
                    </span>

                    <span v-if="extentions.includes(selectedmedia.extention)">
                        <img :src="selectedmedia.link" class="previewbox" />
                    </span>

                    <span v-if="selectedmedia.extention == 'mp4'">
                        <video controls class="previewbox">
                            <source :src="selectedmedia.url" type="video/mp4">
                            Your browser does not support the video tag.
                        </video>
                    </span>

                    <span v-if="selectedmedia.extention == 'mp3'">
                        <audio controls class="previewbox">
                            <source :src="selectedmedia.link" type="audio/ogg">
                            <source :src="selectedmedia.link" type="audio/mpeg">
                            Your browser does not support the audio element.
                        </audio>
                    </span>
                </div>
            </el-dialog>
        </div>
    </div>
</template>
<script>
export default
    {
        props: { isvisible: Boolean, media: Object, type: String },
        data() {
            return {
                extentions: ['jpg', 'jpeg', 'gif', 'png'],
                newisible: this.isvisible,
                selectedmedia: {},
            };
        },

        computed: {
            currentfile() {
                return (row) => {
                    if (this.$route.params.svi != undefined && row.name === this.$route.params.svi.name) {
                        return true;
                    } else {
                        return false;
                    }
                }
            }
        },

        methods: {
            showpreview(i) {
                this.selectedmedia = null;
                i.mousehover = false;
                i.showdialog = true;
                this.selectedmedia = i;
            },

            previewsnip(i) {
                i.mousehover = undefined;
                i.extention = i.name.substring(i.name.lastIndexOf('.') + 1).toLowerCase();
                i.mousehover = true;
                i.externalurl = `${i.link.substring(0, i.link.length - 1)}0`;
                if (i.extention === "mp4") {
                    i.url = `${i.link.substring(0, i.link.length - 4)}raw=1`;
                }
            },

            beforeuploadpreview(i) {
                if (this.type == 'preview') {
                    i.extention = i.originalname.substring(i.originalname.lastIndexOf('.') + 1).toLowerCase();
                    let url = URL.createObjectURL(i.content);
                    if (i.extention === 'mp4') {
                        i.url = url;
                    }
                    else {
                        i.link = url;
                    }
                    i.show = true;
                    URL.revokeObjectURL(i.content);
                    this.selectedmedia = i;
                    this.selectedmedia.name = i.originalname;
                }
            },

            downloadmedia(row) {
                var link = document.createElement("a");
                link.href = row.link;
                link.download = row.name;
                link.click();
            },

            deletemedia(row) {
                this.$emit("deletemedia", row);
            },

            closemedia() {
                this.$emit("closemedia", false);
            },

            mediadialogClose(done) {
                this.selectedmedia = {};
                this.newisible = false;
                if (this.type == 'preview') {
                    this.$emit("closemedia", false);
                }
                done();
            },
        },

        created() {
            if (this.type == 'preview') {
                this.beforeuploadpreview(this.media);
            }
        }
    }
</script>