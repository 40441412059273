<template>
    <div class="RDwidth">
        <div class="fixdheader">
            <el-row>
                <el-col :span="14" class="headerstyle">
                    Row Designation (RD) Selection</el-col
                >
                <el-col :span="10">
                    
                    <!-- <el-button  type="primary" class="right marginleft4px" @click="userGuide()">Guide</el-button> -->
                    <el-button icon="el-icon-back" type="info" class="right marginleft4px" @click="$router.go(-1)">Back</el-button>
                    <el-button  v-if="sectionid" :disabled="!editpermission" class="marginleft4px right" slot="" @click="saveRowDesignationCH" type="success" >Save</el-button>
                    </el-col>
            </el-row>
            <el-row>
                <div >
                     <el-row >
                             <el-col  :span="5">Project Name </el-col>
                             <el-col  :span="10">
                                 <el-input
                                 placeholder="Please input"
                                 v-model="this.project.projectname"
                                 :disabled="true">
                                 </el-input>
                             </el-col>
                             <div v-show="false">
                                 <projectselectpart
                                     :custom="custom"
                                     :lastsavedshow="true"
                                     @projectsectionchange="
                                         projectsectionchange
                                     "
                                 ></projectselectpart>
                         </div>
                         </el-row>
                         <el-row>
                         </el-row>
                         <div class="spaceBetween" />     
                    </div>
            </el-row>
        </div>
        <el-row >
            <hr class="hrstyle" />
            <div class="var_crt">
                <div  style="color:white;"> Column Header (CH) Selection Algorithm</div>
            </div>
         <el-row v-if="rdview.ExcludeDefault">
            
            <el-col :span="24">
                <el-row :gutter="20">
                    <el-col :span="5">
                      Section
                  </el-col>
                  <el-col :span="10">
                    <el-input
                       placeholder="Please input"
                       v-model="this.project.section.sectiondisplay"
                       :disabled="true">
                       </el-input>
                  </el-col>
                  <el-col :span="9">
                    <span>
                        (REGEX for All Projects)
                    </span>
                    <span class="right">
                        <i class="el-icon-info el-popover__reference"
                        id="info_amos_rd_allprojects"
                        @click="$training.Gettrainingpopup('info_amos_rd_allprojects')"
                            style="color: rgb(96, 98, 102);font-size: 15px"></i>
                    </span>
                  </el-col>
                </el-row>
                <el-row>
                    <el-col>
                          <el-row>
                              <el-col :span="5">
                                  Include
                              </el-col>
                                 <el-col :span="19">
                                    <span style="width:96%;" class="left">
                                         <el-input type="textarea" :disabled="readables.In_De || !editpermission" @input="checkvalidpattern(rdview.IncludeDefault)" :spellcheck="isspellcheck"
                                              :autosize="{ minRows: 3, maxRows: 7 }"
                                              v-model="rdview.IncludeDefault.regularexpression">
                                          </el-input>
                               <div class="red" v-if="rdview.IncludeDefault.regularexpressionvalid">
                                  <strong>Invalid Regular Expression.</strong>
                                  </div>
                                  
                            </span>
                            <span class="left">
                                <i class="el-icon-edit el-button--primary" @click="readables.In_De = readables.In_De == false ? true : false"></i>
                            </span>                
                                 </el-col>
                             </el-row>
                      </el-col>
                    <el-col >
                        <el-row>
                            <el-col :span="5">
                                Exclude
                            </el-col>
                            <el-col :span="19">
                                <span style="width:96%;" class="left">
                            <el-input  type="textarea" :disabled="readables.Ex_De || !editpermission" @input="checkvalidpattern(rdview.ExcludeDefault)" :spellcheck="isspellcheck"
                                                    :autosize="{ minRows: 3, maxRows: 7 }"
                                                    v-model="rdview.ExcludeDefault.regularexpression">
                                                </el-input>
                            <div class="red" v-if="rdview.ExcludeDefault.regularexpressionvalid">
                                <strong>Invalid Regular Expression.</strong>
                                </div>
                            </span>
                            <span class="left">
                                <i class="el-icon-edit el-button--primary"  @click="readables.Ex_De = readables.Ex_De == false ? true : false"></i>
                            </span>                
                            </el-col>
                         </el-row>
                    </el-col>
                     
                      </el-row>
                      <hr class="hrstyle" />
                      <el-row :gutter="20">
                        <el-col :span="5">
                            Part Name
                        </el-col>
                        <el-col :span="10">
                          <el-input
                             placeholder="Please input"
                             v-model="this.project.projectpart.name"
                             :disabled="true">
                             </el-input>
                        </el-col>
                        <el-col :span="9">
                            <span>
                                (REGEX for Project Part)
                            </span>
                            <span class="right">
                                <i class="el-icon-info el-popover__reference"
                                id="info_amos_rd_projectpart"
                                @click="$training.Gettrainingpopup('info_amos_rd_projectpart')"
                                    style="color: rgb(96, 98, 102);font-size: 15px"></i>
                            </span>
                        </el-col>
                      </el-row>
                      <el-row>
                        <el-col>
                           <el-row>
                               <el-col :span="5">  Include </el-col>
                                  <el-col :span="19">
                                    <span style="width:96%;" class="left">
                                          <el-input type="textarea" :disabled="readables.In_Pa" @input="checkvalidpattern(rdview.IncludePart)" :spellcheck="isspellcheck"
                                               :autosize="{ minRows: 3, maxRows: 7 }"
                                               v-model="rdview.IncludePart.regularexpression">
                                           </el-input>
                                  <div class="red" v-if="rdview.IncludePart.regularexpressionvalid">
                                       <strong>Invalid Regular Expression.</strong>
                                   </div> 
                            </span>
                            <span class="left">
                                <i class="el-icon-edit el-button--primary" @click="readables.In_Pa = readables.In_Pa == false ? true : false"></i>
                            </span>                                   
                                  </el-col>
                              </el-row>
                       </el-col>
                     <el-col >
                         <el-row>
                             <el-col :span="5"> Exclude </el-col>
                             <el-col :span="19">
                                <span style="width:96%;" class="left">
                             <el-input type="textarea" :disabled="readables.Ex_Pa" @input="checkvalidpattern(rdview.ExcludePart)" :spellcheck="isspellcheck"
                                                     :autosize="{ minRows: 3, maxRows: 7 }"
                                                     v-model="rdview.ExcludePart.regularexpression">
                                                 </el-input>
                             <div class="red" v-if="rdview.ExcludePart.regularexpressionvalid">
                                 <strong>Invalid Regular Expression.</strong>
                                 </div>
                            </span>
                            <span class="left">
                                <i class="el-icon-edit el-button--primary" @click="readables.Ex_Pa = readables.Ex_Pa == false ? true : false"></i>
                            </span>                                    
                             </el-col>
                          </el-row>
                     </el-col>
                      
                   </el-row>
               </el-col>
         </el-row>
        </el-row>
    </div>
</template>
<script>
import projectselectpart from "./projectselectpart.vue";
export default {
    data() {
        return {
            readables:{
                In_De:true,
                Ex_De:true,
                In_Pa:true,
                Ex_Pa:true
            },
            rdview:{},
            isValidPattern:true,
            sectionid: null,
            project: {},
            custom: {
                rowcol: "el-row",
                projectsid: null,
                projectdisabled:true,
                partdisabled:true,
                sectiondisabled:true,
            },
        };
    },
    components: {
        projectselectpart: projectselectpart,
    },
    methods: {
        // userGuide(){
        //     var style = "<style> .el-message-box {width:30%;} </style>";
        //         this.$alert("Under Development - See P.30 Measurement Console (Guide)"+style, "User Guide", {
        //             dangerouslyUseHTMLString: true,
        //         });
        // },
        validatsave(){
            var error = [];
            var cansave = true;
            if(this.rdview.ExcludeDefault.regularexpressionvalid == true){
                error.push("Regular Expression is invalid in Exclude (Default) box.");
                cansave = false;
            }
            // if(this.rdview.ExcludeDefault.regularexpression.length > 500){
            //     error.push("Your data within Exclude (Default) exceed 500 characters limitation.");
            //     cansave = false;
            // }

            if(this.rdview.IncludeDefault.regularexpressionvalid == true){
                error.push("Regular Expression is invalid in Include (Default) box.");
                cansave = false;
            }
            // if(this.rdview.IncludeDefault.regularexpression.length > 500){
            //     error.push("Your data within Include (Default) exceed 500 characters limitation.");
            //     cansave = false;
            // }
            if(this.rdview.ExcludePart.regularexpressionvalid == true){
                error.push("Regular Expression is invalid in Exclude (Part) box.");
                cansave = false;
            }
            // if(this.rdview.ExcludePart.regularexpression.length > 500){
            //     error.push("Your data within Exclude (Part) exceed 500 characters limitation.");
            //     cansave = false;
            // }
            if(this.rdview.IncludePart.regularexpressionvalid == true){
                error.push("Regular Expression is invalid in Include (Part) box.");
                cansave = false;
            }
            // if(this.rdview.IncludePart.regularexpression.length > 500){
            //     error.push("Your data within Include (Part) exceed 500 characters limitation.");
            //     cansave = false;
            // }
            if(!cansave){
                var style = "<style> .el-message-box {width:30%;} </style>";
                this.$alert(error.join(" ") +style, "Length Limitation", {
                    dangerouslyUseHTMLString: true,
                });
            }
            return cansave; 
        },
        saveRowDesignationCH(){
            if(this.validatsave()){
                this.post("rowdesignation/saveRowDesignationCH", this.rdview)
                    .then((response) => {
                        this.$message({
                            showClose: true,
                            message: 'Row Designation CH save successfully!',
                            type: 'success'
                            });
                        this.getRowDesignationCH();
                    });
            }
        },
        getRowDesignationCH(){
            var obj = {};
            obj.library = this.project.library;
            obj.section = this.project.section;
            obj.projectpart = this.project.projectpart;
            obj.id = this.project.id;
            this.post("rowdesignation/getRowDesignationCH", obj)
                .then((response) => {
                    this.rdview = response.data; 
                });
        },
        checkvalidpattern(data){
            this.isValidPattern = true;
            data['regularexpressionvalid'] = false;
           try {
               new RegExp(data['regularexpression']);
           } catch(e) {
               this.isValidPattern = false;
               data['regularexpressionvalid'] = true;
           }
        },
        projectsectionchange: function (Projectobj) {
            this.sectionid = Projectobj.sectionid;
            this.project = Projectobj.project;
            if (this.sectionid != null) {
                this.project.projectpart = Projectobj.part;
               this.getRowDesignationCH();
            }
        },
    },
    created() {        
    },
};
</script>
<style>
.RDwidth{
  width: 600px;
}
</style>
