<template>
    <div>
        <el-row><span class="headerstyle">Comparative Quantity Checks (CQC)</span></el-row>
        <el-table :data="detailform"
                  class="fullwidth" size="mini">
            <el-table-column prop="unit" label="Unit">
                <template slot-scope="scope">
                    <el-input v-model="scope.row.unit" @change="changed(scope.row)"></el-input>
                </template>
            </el-table-column>
            <el-table-column prop="nrmsection" label="NRM Section">
                <template slot-scope="scope">
                    <el-input v-model="scope.row.nrmsection" @change="changed(scope.row)"></el-input>
                </template>
            </el-table-column>
            <el-table-column prop="section" label="Section">
                <template slot-scope="scope">
                    <el-input v-model="scope.row.section" @change="changed(scope.row)"></el-input>
                </template>
            </el-table-column>
            <el-table-column prop="group" label="Group">
                <template slot-scope="scope">
                    <el-input v-model="scope.row.group" @change="changed(scope.row)"></el-input>
                </template>
            </el-table-column>
            <el-table-column prop="work" label="Work">
                <template slot-scope="scope">
                    <el-input v-model="scope.row.work" @change="changed(scope.row)"></el-input>
                </template>
            </el-table-column>
            <el-table-column prop="level1" label="Level 1">
                <template slot-scope="scope">
                    <el-input v-model="scope.row.level1" @change="changed(scope.row)"></el-input>
                </template>
            </el-table-column>
            <el-table-column prop="level2" label="Level 2">
                <template slot-scope="scope">
                    <el-input v-model="scope.row.level2" @change="changed(scope.row)"></el-input>
                </template>
            </el-table-column>
            <el-table-column prop="level3" label="Level 3">
                <template slot-scope="scope">
                    <el-input v-model="scope.row.level3" @change="changed(scope.row)"></el-input>
                </template>
            </el-table-column>
            <el-table-column label="Action"
                             fixed="right" width="70">
                <template slot-scope="scope">
                    <el-button size="mini" type="danger" icon="el-icon-delete"
                               @click="delRow(scope.$index, scope.row)"></el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-row>
            <el-col class="text-right">
                <el-button icon="el-icon-circle-plus" type="primary" @click="addRow(false)">Add New</el-button>
                <el-button icon="el-icon-circle-plus" type="primary" @click="addRow(true)">Add Copy</el-button>
            </el-col>
            <el-col abel="">
                <el-button @click="save()" type="success" icon="el-icon-circle-check">Save</el-button>
            </el-col>
        </el-row>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                detailform: [],
                loading: false
            }
        },
        methods: {
            addRow: function (copy) {
                var itm = {};
                if (copy) {
                    if (this.detailform.length > 0) {
                        var last = this.detailform[this.detailform.length - 1];
                        var last = JSON.parse(JSON.stringify(last))
                        last.id = 0
                        itm = last;
                    }
                }
                this.changed(itm);
                this.detailform.push(itm);
            },

            delRow: function (index, row) {
                this.$confirm('This will permanently delete this row. Continue?', 'Warning', {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                }).then(() => {
                    this.$http.post('comparativeanalysis/delete', row)
                        .then(response => {
                            this.$message({
                                showClose: true,
                                message: response.data,
                                type: "success"
                            });
                            //this.detailform.splice(index, 1);
                            this.refresh();
                        })
                        .catch(err => {
                            this.$bus.$emit('error', err);
                        });
                }).catch(() => {
                    this.$message({
                        showClose: true,
                        type: 'info',
                        message: 'Delete canceled'
                    });
                });
            },

            changed: function (row) {
                row.edited = true;
            },

            save: function (item, event) {
                this.$http.post('comparativeanalysis/save', this.detailform)
                    .then(response => {
                        this.$message({
                            showClose: true,
                            message: response.data,
                            type: "success"
                        });
                        this.refresh();
                    })
                    .catch(err => {
                        this.$bus.$emit('error', err);
                    });
            },

            refresh: function () {
                // this.loading = true;
                this.$store.state.isLoading = true;

                this.$http.post('comparativeanalysis/getall', this.grid)
                    .then(response => {
                        var json = JSON.parse(response.data);
                        this.detailform = json;
                        // this.loading = false;
                        this.$store.state.isLoading = false;
                    }).catch(err => {
                        // this.loading = false;
                        this.$store.state.isLoading = false;
                        this.$bus.$emit('error', err);
                    });
            }
        },

        created: function () {
            this.refresh();
        },
    }
</script>
<style>
</style>
