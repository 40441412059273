<template>
    <div>
        <transition name="fade" mode="in-out">
            <div ref="whiteBackground" id="whiteBackground" draggable="true"
                :class="'draggable ' + (isrowclicked ? 'positionright' : '')" v-if="err.visible">
                <div id="dlgbox">
                    <div class="dlg-body">
                        <div class="buttonsheader positionsticky flexbox justifycontentsb"
                            v-if="routenames.includes(this.$route.name)">
                            <span class="left">
                                <div class="paddingtop5 paddingbottom5px">
                                    <span>
                                        <el-button :type="isverify.checklistsection ? 'success' : 'info'"
                                            class="padding7" @click="selectedpage('checklistsection')">
                                            Go to Checklist - Section<i
                                                :class="'marginl5 ' + (isverify.checklistsection ? 'el-icon-circle-check' : 'el-icon-right')" />
                                        </el-button>
                                    </span>

                                    <span>
                                        <el-button :type="isverify.rwc ? 'success' : 'info'"
                                            class="marginleft4px padding7" @click="selectedpage('projectcs')">
                                            Go to RWC<i
                                                :class="'marginl5 ' + (isverify.rwc ? 'el-icon-circle-check' : 'el-icon-right')" />
                                        </el-button>
                                    </span>

                                    <span>
                                        <el-button :type="isverify.cqc ? 'success' : 'info'"
                                            class="marginleft4px padding7" @click="selectedpage('projectcqc')">
                                            Go to CQC<i
                                                :class="'marginl5 ' + (isverify.cqc ? 'el-icon-circle-check' : 'el-icon-right')" />
                                        </el-button>
                                    </span>

                                    <span>
                                        <el-button :type="isverify.signoffsection ? 'success' : 'info'"
                                            class="marginleft4px padding7" @click="selectedpage('signoffnrmelement')">
                                            Go to Sign Off - Section<i
                                                :class="'marginl5 ' + (isverify.signoffsection ? 'el-icon-circle-check' : 'el-icon-right')" />
                                        </el-button>
                                    </span>
                                </div>
                            </span>

                            <span class="floatright padding5px">
                                <button @click="dlgClose">&times;</button>
                            </span>
                        </div>

                        <div class="error-content">
                            <button @click="dlgClose" v-if="!routenames.includes(this.$route.name)"
                                class="positionsticky floatright">&times;</button>

                            <div ref="content">
                                <!-- :class="verticalcenter ? 'vertical-center' : ''" -->
                                <div v-if="err.type == 'required'">
                                    <b class="heading">
                                        <div v-html="err.sourcereq"></div>
                                    </b>
                                </div>

                                <div v-if="err.type == 'warning'">
                                    <b class="heading">
                                        <div v-html="err.sourcewarn"></div>
                                    </b>
                                </div>

                                <div v-if="err.type == 'qty'">
                                    <b class="heading">
                                        <div v-html="err.sourceqty"></div>
                                    </b>
                                </div>

                                <div v-if="err.message.length > 0">
                                    <br />
                                    <div v-if="err.objecttype == 'array_of_objects'">
                                        <div v-for="(row, index) in err.message" :key="index">
                                            <span :class="{
                                                'underline cursorpointer': true,
                                                'copycontrolfont': row.ishyperlink && !row.clicked,
                                                'errorlink bold': row.ishyperlink && row.clicked
                                            }">
                                                <span v-html="row.msg" @click="hyperlink(row, index)"></span>
                                            </span>
                                        </div>
                                    </div>
                                    <div v-else>
                                        <p v-for="(msg, index) in err.message" :key="index" v-html="msg"></p>
                                    </div>
                                </div>

                                <div v-if="err.type == 'warning' || err.type == 'qty'" class="center">
                                    <span class="warnfooter">
                                        <br />I have checked all the data issue listed<br />&nbsp;&nbsp; above and wish
                                        to ignore this warning
                                        <el-checkbox v-model="warncheck" @change="warnchanged" border></el-checkbox>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div v-if="err.type == 'required'" class="footerbox fontsize28 darkredcolor">
                        Critical Data Entry Errors
                    </div>

                    <div v-if="err.type == 'warning' || err.type == 'warningproject'"
                        class="footerbox fontsize28 greencolor">
                        Data Needs to be Checked
                    </div>

                    <div v-if="err.type == 'qty'" class="fontsize21 darkredcolor footerbox">
                        <div v-html="err.qtydown"></div>
                    </div>

                    <div>
                        <img class="newimg height180px pointerevent" src="../css/finalcartoon.png">
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>
<script>
export default {
    props: { err: Object, projectsection: Object },
    data() {
        return {
            warncheck: false,
            verticalcenter: false,
            routenames: ['projectamos', 'project'], // We are display header button only this pages.
            isverify: {},
            isrowclicked: false
        };
    },

    methods: {
        warnchanged: function () {
            if (this.warncheck) {
                this.dlgClose();
            }
        },

        hyperlink(row, index) {
            this.isrowclicked = true;
            this.err.message.forEach(element => {
                element.clicked = false;
            });
            this.err.message[index].clicked = true;
            this.err.message = JSON.parse(JSON.stringify(this.err.message));
            this.$emit("hyperlink", row, this.err.message);
        },

        selectedpage(pagename) {
            this.$emit('movespecificpage', pagename);
        },

        dlgClose: function () {
            this.isrowclicked = false;
            this.err.visible = false;
            this.$emit("warnerror", this.warncheck);
        },

        drag_start(event) {
            this.isrowclicked = false;
            var style = window.getComputedStyle(event.target, null);
            event.dataTransfer.setData("text/plain",
                (parseInt(style.getPropertyValue("left"), 10) - event.clientX) + ',' + (parseInt(style.getPropertyValue("top"), 10) - event.clientY));
        },

        drag_over(event) {
            this.isrowclicked = false;
            event.preventDefault();
            return false;
        },

        drop(event) {
            this.isrowclicked = false;
            var offset = event.dataTransfer.getData("text/plain").split(',');
            this.$refs.whiteBackground.style.left = (event.clientX + parseInt(offset[0], 10)) + 'px';
            this.$refs.whiteBackground.style.top = (event.clientY + parseInt(offset[1], 10)) + 'px';
            event.preventDefault();
            return false;
        },

        makeDraggable() {
            var dm = this.$refs.whiteBackground;
            if (dm) {
                dm.addEventListener('dragstart', this.drag_start, false);
                document.body.addEventListener('dragover', this.drag_over, false);
                document.body.addEventListener('drop', this.drop, false);
            }
        },

        heightCount() {
            this.height = this.$refs.content.clientHeight;
            if (this.height <= 318.5 && this.height != 0) {
                this.verticalcenter = true;
            }
            else {
                this.verticalcenter = false;
            }
        },

        async verifypages() {
            let data = { id: this.projectsection.id };
            this.isverify = await this.$training.verifycheckingpage(data);
        },
    },

    updated() {
        if (this.err.visible) {
            this.$nextTick(this.makeDraggable);
            this.$nextTick(this.heightCount);
        }
    },

    watch: {
        'projectsection.id': function () {
            if (this.routenames.includes(this.$route.name)) {
                this.verifypages();
            }
        }
    },

    async mounted() {
        if (this.routenames.includes(this.$route.name)) {
            await this.$signalr.client.on("checkingdialogboxheaderpage", () => {
                this.verifypages();
            });
        }
    },

    created() {
        if (this.routenames.includes(this.$route.name)) {
            this.verifypages();
        }
        this.$nextTick(this.makeDraggable);
        this.$nextTick(this.heightCount);
    }
}
</script>

<style scoped>
#whiteBackground {
    width: 50%;
    /* height: 75%;
    top: 50%; */
    top: 30%;
    left: 50%;
    position: fixed;
    transform: translate(-50%, -50%);
    opacity: 1;
    z-index: 9999;
    align-items: center;
}

#dlgbox {
    position: fixed;
    width: 100%;
    margin: auto;
    background-color: lightgray;
    z-index: 9999;
    border: 4px solid black;
}

.positionright {
    top: 4% !important;
    left: 75% !important;
}

.dlg-body {
    background-color: lightgray;
    color: black;
    font-size: 14px;
    border-left: 4px solid black;
    border-bottom: 4px solid black;
    margin-left: 58px;
    /* height: 58vh; */
    overflow-y: auto;
}

.error-content {
    background-color: white;
    padding: 5px 13px 30px 25px;
    /* min-height: auto; */
    min-height: 70px;
    max-height: 50vh;
    overflow-y: auto;
}

.newimg {
    bottom: 0;
    position: absolute;
    left: 0;
}

.footerbox {
    height: 100px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-left: 150px;
}

/* .additional-content {
    text-align: center;
    padding-left: 150px;
    padding-bottom: 32px;
    padding-top: 32px;
    font-size: 28px;
} */

.darkredcolor {
    color: darkred;
}

.greencolor {
    color: green;
}

/* .vertical-center {
    margin: 0;
    position: absolute;
    top: 38%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
} */

.el-checkbox.is-bordered.el-checkbox--mini {
    padding: 4px 8px 7px 8px;
    border-radius: 3px;
    height: 26px;
    border: 1px solid black;
}

.warnfooter {
    font-size: 18px;
    color: darkred;
    font-weight: bold;
}

.positionsticky {
    position: sticky;
    top: 0px;
}

.buttonsheader {
    min-height: 40px;
    background-color: white;
    padding-left: 25px;
    overflow: auto;
}

p {
    margin: 0 0 0px;
}

.heading {
    font-family: "Calibri", "Comic Sans MS", 'Times New Roman', "Arial";
    font-size: 17px;
}

.fade-enter-active {
    animation: fadein 0.5s;
    animation-iteration-count: 1;
}

.fade-leave-active {
    animation: fadeout 0.5s;
    animation-iteration-count: 1;
}

@keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fadeout {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

/* .messagedarkred {
    text-align: center;
    padding-left: 150px;
    padding-bottom: 21px;
    padding-top: 21px;
    font-size: 21px;
    color: darkred;
} */
</style>
