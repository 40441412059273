<template>
    <div class="externalbillwidth">
        <div>
            <el-row>
                <el-col :span="12" class="headerstyle">Bill View (External Check View)</el-col>
                <el-col :span="12">
                    <div v-show="buttonshow" class="paddingwithfloatright">
                        <el-button type="primary" class="spellbtn marginleft4px" style="padding: 3px 5px 1px 5px;"
                            @click="spellcheckMethod()">
                            Spell Check:
                            <span>
                                <el-switch @change="spellchack()" v-model="spell" style="margin-bottom:2px;"
                                    active-color="primary" :active-value="true" :inactive-value="false">
                                </el-switch>
                            </span>
                        </el-button>
                        <!-- <el-button @click="reloadfromdropbox()" type="primary" class="btnpaddinglr8 marginleft4px"
                            icon="el-icon-refresh">Reload from Dropbox</el-button> -->
                        <el-button @click="goback(null)" class="btnpaddinglr8 marginleft4px" type="info"
                            icon="el-icon-back">Back</el-button>
                        <el-button @click="save()" class="btnpaddinglr8 marginleft4px" type="success"
                            icon="el-icon-circle-check">Save</el-button>
                    </div>
                </el-col>
            </el-row>

            <el-row>
                <el-col>
                    <div>
                        <div class="row">
                            <div class="fixwidth hrspacebetween">
                                <el-input v-model="project.projectname" class="inline-input fullwidth bold padding2px"
                                    :disabled="true" placeholder="Please select Project"></el-input>
                            </div>

                            <div class="fixwidth hrspacebetween">
                                <el-input v-model="projectpart.name" class="fullwidth padding2px bold" :disabled="true"
                                    placeholder="Project Parts"></el-input>
                            </div>

                            <div
                                v-bind:style="{ width: sectionwidth + 'px', maxwidth: sectionwidth + 'px', 'float': 'left' }">
                                <div class="left hrspacebetween">
                                    <el-input v-model="section.sectiondisplay" :disabled="true" placeholder="Section"
                                        v-bind:style="{ width: sectionwidth }" class="padding2px bold"></el-input>
                                </div>
                            </div>
                        </div>
                    </div>
                </el-col>
            </el-row>
            <div class="spaceBetween" />
            <div class="spaceBetween" />
            <el-row>
                <div v-if="lastsavedinfo != null">
                    <div class="el-input el-input--mini">
                        <span class="bold"> {{ lastsavedinfo.library }}</span>
                        <b class="marginleft4px">Last saved:</b> {{ lastsavedinfo.lastsavedbyname }}
                        {{ lastsavedinfo.lastsaved }}
                    </div>
                </div>
            </el-row>

            <hr class="spaceBetween groove" />
        </div>
        <div>
            <div ref="spell" :contenteditable="true" :spellcheck="spell" @click="onspellclick"
                class="spellstyle tablepaddinglr10px">
                <el-table ref="table" v-show="detail" :data="detailform.rows" :height="height"
                    :class="'fullwidth ' + (spell ? 'back' : '')" :row-class-name="rowClassName"
                    :cell-class-name="cellClassName" row-key="rowid" :default-expand-all="false" :show-header="false"
                    :cell-style="{ padding: '0', height: '25px' }">
                    <el-table-column type="expand" :width="view == 'Bill View' ? 1 : 20">
                        <template slot-scope="b">
                            <div v-if="b.row.sslist.length > 0">
                                <el-table :data="b.row.sslist" class="fullwidth" header-cell-class-name="detailheader"
                                    cell-class-name="detailrow">
                                    <el-table-column label="Label" property="label" minwidth="100">
                                    </el-table-column>
                                    <el-table-column label="Subject" property="subject" minwidth="400">
                                    </el-table-column>
                                    <el-table-column label="Category" property="category" minwidth="300">
                                    </el-table-column>
                                    <el-table-column label="Measure" property="measure" width="70" align="right">
                                    </el-table-column>
                                    <el-table-column label="Dim" property="dim" width="70" align="right">
                                    </el-table-column>
                                    <el-table-column label="Qty" property="qty" width="80" align="right">
                                    </el-table-column>
                                    <el-table-column label="Unit" property="unit" width="80">
                                    </el-table-column>
                                </el-table>
                            </div>
                        </template>
                    </el-table-column>

                    <el-table-column label="" prop="srno" width="20">
                    </el-table-column>

                    <el-table-column label="" prop="detail" width="600">
                        <template slot-scope="scope">
                            <div class="textalign">
                                {{ scope.row.detail }}
                            </div>
                        </template>
                    </el-table-column>

                    <el-table-column label="" prop="qty" width="80" align="right">
                    </el-table-column>

                    <el-table-column label="" prop="unit" width="60">
                    </el-table-column>

                    <el-table-column label="" width="300">
                        <template slot-scope="{ row, $index }">
                            <div v-if="$index >= 3">
                                <el-input type="textarea" maxlength="255" placeholder="comment" v-model="row.comment"
                                    class="hvrresizetxtarea padd3textarea"
                                    :rows="$training.calculaterows(row.comment, 50)"
                                    :style="{ height: $training.calculateheight(row.comment, 50) + 'px' }">
                                </el-input>
                            </div>
                        </template>
                    </el-table-column>

                    <el-table-column label="" width="40" align="center">
                        <template slot-scope="{row, $index}">
                            <div v-if="$index >= 3">
                                <el-checkbox v-model="row.ischecked" class="checkborderblue checkblue"
                                    :class="(row.comment != null && row.comment != '') ? 'checkborder' : ''">
                                </el-checkbox>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <br />
        </div>
    </div>
</template>
<script>
import moment from "moment";
export default {
    data() {
        return {
            height: (document.documentElement.clientHeight - 140),
            buttonshow: false,
            togglespell: true,
            lastsavedinfo: null,
            spellclick: null,
            spell: true,
            detail: true,
            project: {},
            projectpart: {},
            section: {},
            detailform: { rows: [] },
            view: "Bill View",
            billview: {},
            sectionwidth: "290px",
            urlquery: ""

        }
    },

    mounted() {
        this.spell = true;
        this.$set(this.$refs.spell, 'autofocus', true);
        this.$set(this.$refs.spell, 'spellcheck', true);
        this.$refs.spell.focus();
    },

    methods: {
        getheight() {
            this.height = (document.documentElement.clientHeight - 140);
        },
        goback(row) {
            var partid = this.projectpart.id == null ? 0 : this.projectpart.id;
            this.$store.state.projectids = {
                projectid: this.project.id,
                partid: partid,
                sectionid: this.section.id
            };
            let data = this.$store.state.projectids;
            this.$bus.$emit("addproject", data);
            let backpage = {
                name: 'sectionalcheck',
                path: '/sectionalcheck',
                params: {}
            };
            if (row != null) {
                backpage.params = { svi: row };
            }
            this.$router.push(backpage);
        },

        save() {
            this.billview.rows = this.detailform.rows;
            let data = this.billview;
            let isbill = this.view == "Detailed Bill View" ? "true" : "false";
            var url = "project/savebillview?detailed=" + isbill;
            this.showloading();
            this.$http.post(url, data)
                .then(response => {
                    this.goback(response.data);
                    this.hideloading();
                })
                .catch(err => {
                    this.$bus.$emit("error", err);
                    this.hideloading();
                });
        },

        onspellclick() {
            if (this.spell) {
                this.spell = false;
            }
        },

        spellchack() {
            this.togglespell = false;
            this.$refs.spell.focus();
        },

        spellcheckMethod: function () {
            if (this.togglespell) {
                this.spell = this.spell == true ? false : true;
            }

            this.spellchack();
            this.togglespell = true;
        },

        // reloadfromdropbox: function () {
        //     let data = { id: this.billview.sv.id };
        //     this.showloading();
        //     this.$http.post("sectional/reloadbillviewfromdropbox", data)
        //         .then(response => {
        //             this.hideloading();
        //             this.$message({
        //                 showClose: true,
        //                 message: response.data.message,
        //                 type: "success"
        //             });
        //             this.refresh();
        //         })
        //         .catch(err => {
        //             this.hideloading();
        //             this.$bus.$emit("error", err);
        //         });
        // },

        refresh: function () {
            if (this.urlquery != "") {
                this.getProject();
            } else {
                this.detail = false;
                this.buttonshow = false;
            }
        },

        getProject: function () {
            this.showloading();
            this.$http.post("project/getexternalbillview?data=" + this.urlquery, {})
                .then(response => {
                    this.hideloading();
                    this.lastsavedinfo = {
                        library: response.data.p.library,
                        lastsaved: moment(response.data.p.section.editedon).format("DD-MMM-YYYY HH:mm"),
                        lastsavedbyname: response.data.p.editedbyuser.loginname
                    };
                    if (response.data.p.section) {
                        var size = this.measureTextWidth(response.data.p.section.sectiondisplay, 14) + 10;
                        size = size < 290 ? 290 : size;
                        this.sectionwidth = size.toString() + "px";
                    }
                    this.project = response.data.p;
                    this.projectpart = response.data.p.projectpart;
                    this.section = response.data.p.section;
                    var json = response.data.bill;
                    json.rows.forEach((v, i) => {
                        v.rowid = i + 1;
                        v.sectionalviewid = this.project.sv.id;
                    });
                    this.$set(this, 'detailform', json);
                    this.billview = {
                        p: this.project,
                        sv: this.project.sv,
                        projectsectionid: this.section.id,
                        rows: json.rows
                    }
                    this.detail = true;
                    this.buttonshow = true;
                    this.$refs.spell.focus();
                })
                .catch(err => {
                    this.detailform = {};
                    this.hideloading();
                    this.detail = false;
                    this.$bus.$emit("error", err);
                });
        },

        rowClassName: function (row, rowIndex) {
            var cls = [];
            if (row.row.sslist.length <= 0 || this.view == "Bill View") {
                cls.push("noexpand");
            }
            return cls.join(" ");
        },

        cellClassName({ row, column, rowIndex, columnIndex }) {
            var cls = [];
            if (columnIndex == 2) {
                if (row.bold) {
                    cls.push("bold");
                }
                if (row.underline) {
                    cls.push("underline");
                }
            }
            if (row.pagebreak && this.view == "Bill View") {
                cls.push("pagebreak");
            }
            return cls.join(" ");
        }
    },

    destroyed() {
        window.removeEventListener('resize', this.getheight);
    },

    mounted: function () {
        window.addEventListener('resize', this.getheight);
    },

    created() {
        const params = new URLSearchParams(window.location.search);
        let strquery = params.get('src');
        if (strquery) {
            this.urlquery = strquery;
            this.refresh();
        }
    }
}
</script>
<style itemscope>
.spellbtn.el-button--mini,
.el-button--mini.is-round {
    padding: 3px 15px;
}

.pagebreak {
    border-top: double;
}

.noexpand .el-icon {
    display: none;
}

.noexpand .el-table__expand-icon {
    pointer-events: none;
}

.detailrow {
    background-color: rgb(246, 240, 250) !important;
}

.detailheader {
    background-color: rgb(125, 119, 153) !important;
    color: white !important;
}

.el-row {
    margin-bottom: 0px;
}

.billtype {
    position: sticky;
    top: 111px;
    z-index: 1;
    background-color: white;
}

/*Remove the bottom border*/
.el-table::before {
    height: 0;
}

[contenteditable] {
    outline: 0px solid transparent;
}

.back {
    z-index: -1;
}

.spellstyle:focus {
    caret-color: transparent !important;
}
</style>