<template>
    <div>
        <div class="fixdheader">
            <el-row>
                <span class="headerstyle">Dashboard - Recent</span>
            </el-row>
            <hr class="hrstyle" />
        </div>

        <el-row>
            <el-col :span="24">
                <div class="headerstyle">Recent Jobs</div>
                <el-table :data="recentjobs">
                    <el-table-column prop="projectname" label="Project" sortable
                        :width="columnwidth(recentjobs, 90, 'projectname')">
                    </el-table-column>

                    <el-table-column prop="name" label="Project Part" sortable
                        :width="columnwidth(recentjobs, 120, 'name')">
                    </el-table-column>

                    <el-table-column prop="section" label="Section" sortable
                        :width="columnwidth(recentjobs, 100, 'section')">
                    </el-table-column>

                    <el-table-column prop="lastedit" label="Last Edited" sortable :formatter="formatter" width="120">
                    </el-table-column>

                    <el-table-column prop="fullname" label="Name" sortable :width="columnwidth(recentjobs, 60, 'fullname')">
                    </el-table-column>
                    <!-- <el-table-column prop="status" label="Status" sortable>
                        </el-table-column> -->
                </el-table>
            </el-col>
        </el-row>

        <el-row>
            <el-col :span="24">
                <div class="headerstyle">User Last Login</div>
                <el-table :data="lastlogin">
                    <el-table-column prop="fullname" label="Name" sortable
                        :width="columnwidth(lastlogin, 60, 'fullname')"></el-table-column>

                    <el-table-column prop="lastlogin" label="Last Loggedin" sortable :formatter="formatter" width="110">
                    </el-table-column>
                </el-table>
            </el-col>
        </el-row>
        <br />
    </div>
</template>
<script>
export default {
    data() {
        return {
            recentjobs: [],
            lastlogin: []
        }
    },
    methods: {
        formatter: function (row, column, value, index) {
            if (value) {
                var d = new Date(value)
                return d.toDateString();
            }
            return value;
        },

        refresh: function () {
            this.$http.post('dashboard/GetRecentJobs')
                .then(response => {
                    response.data.forEach(row => {
                        if (row.name == null) {
                            row.name = "Single Bill";
                        }
                    });
                    this.recentjobs = response.data;
                })
                .catch(err => {
                    this.$bus.$emit('error', err);
                });

            this.$http.post('dashboard/GetLastLogin')
                .then(response => {
                    this.lastlogin = response.data;
                })
                .catch(err => {
                    this.$bus.$emit('error', err);
                });
        },

        columnwidth: function (details, defaultwidth, column) {
            var maxwidth = 0;
            for (var i = 0; i < details.length; i++) {
                var row = details[i];
                var value = row[column] == null ? '' : row[column].toString();
                if (value && value.length != 0) {
                    if (value.length > maxwidth) {
                        maxwidth = value.length;
                        var str = value;
                    }
                }
            }
            if (maxwidth != 0) {
                var size = this.measureTextWidth(str) + 30;
                return size > defaultwidth ? size : defaultwidth;
            }
            else
                return 0;
        },
    },

    created: function () {
        this.refresh();
    }
}
</script>