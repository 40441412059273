import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

// TYPES
const MAIN_SET_COUNTER = 'MAIN_SET_COUNTER'

// STATE
const state = {
    counter: 0,
    roles:[
        'Take Off',
        'Billing',
        'Ext Checker',
        'Trainer',
        'Instructor'
    ],
    orders:[
        'Take Off',
        'Billing',
        'TO & Billing',
        'Ext Checker',
        'Preview'
    ],
    units: ['item', 'm', 'm2', 'm3', 'nr', 't', 'index', '%'],
    pagesizes: [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 100],
    sections:[],
 //   sections: [
 //       '0.0 Facilitating works',
 //       '1.0 Substructure',
 //       '2.0 Superstructure',
 //       '2.1 Frame',
 //       '2.2 Upper floors',
 //       '2.3 Roof',
 //       '2.4 Stairs and ramps',
 //       '2.5 External walls',
 //       '2.6 Windows and external doors',
 //       '2.7 Internal walls and partitions',
 //       '2.8 Internal doors',
 //       '3.0 Internal Finishes',
 //       '3.1 Wall finishes',
 //       '3.2 Floor finishes',
 //       '3.3 Ceiling finishes',
 //       '4.0 Fittings, furnishings and equipment',
 //       '5.1 Sanitary installations',
 //       '7.0 Works to existing buildings',
 //       '8.0 External Works',
 //       '8.1 Site preparation works',
 //       '8.2 Roads, paths, pavings and surfacings',
 //       '8.3 Soft landscaping, planting, irrigation',
 //       '8.4 Fencing, railings and walls',
 //       '8.5 External fixtures',
 //       '8.6 External drainage',
 //       '8.7 External services',
 //       '8.8 Minor building works, ancillary buildings'
	//],
    descriptiondata:{},
    rowtypes :['A', 'B','C','D','E','F','G','H'],
    libraries: ['SMM7', 'NRM2', "ROADWORK"],
    sortorders: ['NRM', 'CAW'],
    project: { projectname: ''},
    section: "",
    sectiondisplay: "",
    partname:'',
    projectpart:{},
    user:{},
    role:{},
    statuses: [
        { status: "In Progress", display: "In Progress", CanEdit: true },
        { status: "Variation", display: "Variation", CanEdit: true },
        { status: "Self Checked", display: "Self Checked", CanEdit: true },
        { status: "Correcting", display: "Correcting", CanEdit: true },
        { status: "Signed Off (Ex)", display: "Signed Off (Ex)", CanEdit: true }
    ],
    operations: [
        { label: "Greater than to", value: ">" },
        { label: "Less than to", value: "<" },
        { label: "Equals to", value: "==" },
        { label: "Greater than or equal to", value: ">=" },
        { label: "Less than or equal to", value: "<=" },
    ],
    billbqcode: "",
    billasterisk: "",
    billspecification:"",
    cqcreport:{},
    RDobj:new Object(),
    externalcheckview: new Object(),
    projectids: null,
    ERproject:{projectid:null,partid:null,version:""},
    ehproject: {
        project: {
            id: null,
            projectname: "",
            library: "",
            version:"",
        },
        part: {
            id: null,
            name: "",
            projectid: null
        },
        section: {
            id: null,
            sectionid: null
        },
        parts: [],
        sections:[],
        versions:[],
        version:'',
    },
    URs:[],
    URd:[],
    connection:null,
    projectsections:[],
    checklistids:{},
    pages: [],
    newfeaturedialgoue: { editedialgoue: false },
    directions:[],
    directionsdialogue:[],
    nrmbillsection:null,
    currentdirection:null,
    currentdirectionindex:0,
    isLoading: false,
    rfisections:[],
    rfisectionid:null,
    currentpage:null,
    amosuisetting: {
        amoscolumnsswapstate: null,
        amosviewmode: null
    }
}

// MUTATIONS
const mutations = {
    [MAIN_SET_COUNTER](state, obj) {
        state.counter = obj.counter
    }
}

// ACTIONS
const actions = ({
    setCounter({ commit }, obj) {
        commit(MAIN_SET_COUNTER, obj)
    },

})

export default new Vuex.Store({
    state,
    mutations,
    actions
});
