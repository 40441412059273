<template>
    <div class="row">
        <div class="fixwidth">
            <el-autocomplete popper-class="morewidth-autocomplete-project"
                             class="inline-input fullwidth bold padding2px"
                             v-model="project.projectname"
                             :fetch-suggestions="ehsearchproject"
                             @select="selectehproject"
                             value-key="projectname"
                             placeholder="Please Select Project">
                <el-button slot="suffix"
                           @click="closeproject"
                           type="text"
                           size="mini">&times;</el-button>
                <template slot-scope="{ item }">
                    <span style="float: left">{{ item.projectname }}</span>
                    <span style="float: right">{{ item.editedon | dateformat }}</span>
                </template>
            </el-autocomplete>
        </div>
            <div class="fixwidthvertion spaceLR">
                 <div  >
             <el-select v-model="version"
              placeholder="version"
               @change="changevertion">
               <el-option
                 v-for="item in versions"
                 :key="item"
                 :label="item"
                 :value="item">
               </el-option>
              </el-select>
                 </div>
             </div>
        <div class="fixwidth width255px" >
            <div class="left" v-if="EHSimilar.projectpartshow  == undefined ? true : EHSimilar.projectpartshow " >
                <el-select  v-model="ehprojectpartid"
                           filterable
                           placeholder="Project Parts"
                           class="fullwidth padding2px bold"
                           @change="changepart"
                           v-bind:style="{ width: '250px' }">
                    <el-option v-for="item in parts"
                               :key="item.id"
                               :label="item.name"
                               :value="item.id">
                    </el-option>
                </el-select>
            </div>
        </div>
        <div class="fixwidthlibrarydate">
            <div v-if="EHSimilar.lastsavedshow" class="left spaceLR margintop3px">
                <div class="el-input el-input--mini bold">
                    {{ project.library }}
                </div>
            </div>
            <div v-if="EHSimilar.lastsavedshow" class="left spaceLR margintop3px">
                <div class="el-input el-input--mini bold">
                    <span v-if="lastsaved">{{ lastsaved }}</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import moment from "moment";
    export default {
        props: {
             projectid:{ type: Number, default: null },
             partid:{ type: Number, default: null },
            EHSimilar: {
                projectPlaceholder: { type: String, default: "" },
                partPlaceholder: { type: String, default: "" },
                lastsavedshow: { type: Boolean, default: true },
                projectpartshow: { type: Boolean, default: true },
                projectsize: { type: Number, default: 5 },
                projectids: { type: Object, default: null },
                close: { type: Boolean, default: false }
            },
            remember: { type: Boolean, default: true }
        },
        data() {
            return {
                //ehproject: this.$store.state.ehproject,
                dataparts:[],
                project: this.$store.state.ehproject.project,
                parts: this.$store.state.ehproject.parts,
                part: this.$store.state.ehproject.part,
                versions:this.$store.state.ehproject.versions,
                ehprojectpartid: null,
                version:'',
                estimateproject: {
                    ehproject: {},
                    ehpart: {},
                    ehparts: [],
                    versions:[],
                    version:'',
                }
            }
        },
        computed: {
            lastsaved: function () {
                if (this.project && this.project.createdon) {
                    var dt = new Date(this.project.createdon);
                    return moment(dt).format("DD-MMM-YYYY");
                }
            }
        },
        methods: {
            changevertion:function(data){
                  if (this.remember) {
                      this.$store.state.ehproject.version = data;
                      this.part = { id: 0, name: "" };
                      this.$store.state.ehproject.part = this.part;
                  }
                  this.version = data;
                   this.ehprojectpartid = null;
                  this.parts = this.dataparts.filter((x)=>{
                     if(x.version == this.version)
                     {
                         return x;
                     }
                 })
                   this.estimateproject.version = this.version;
                 this.$emit("ehprojectpartchange", this.estimateproject);

            },
            changepart: function (event) {
                var projectpart = this.parts.find(p => p.id == event);
                if (projectpart && this.remember == true) {
                    this.part = projectpart;
                    this.part.name = projectpart.name;
                    this.$store.state.ehproject.part = projectpart;
                }
                this.part = projectpart;
                this.estimateproject.ehproject = this.project;
                this.estimateproject.ehpart = this.part;
                this.estimateproject.ehparts = this.parts;
                this.$emit("ehprojectpartchange", this.estimateproject);
            },

            selectehproject: function (item) {
                if (this.remember) {
                     this.estimateproject.ehproject = JSON.parse(JSON.stringify(item));
                    this.$store.state.ehproject.project =  JSON.parse(JSON.stringify(item));
                }
                this.project = item;
                this.getpart();
                 this.$emit("ehprojectpartchange", this.estimateproject);
            },

            closeproject: function () {
                this.project = { id: 0, projectname: "", library: "",version:"" };
                //this.part = null;
                // this.part = { id: 0, name: "" };
                this.dataparts = [];
                this.parts = [];
                this.version = "";
                this.versions = [];
                this.estimateproject.version = this.version;
                this.ehprojectpartid = null;
                this.estimateproject.ehproject = this.project;
                this.estimateproject.versions = this.versions;
                this.estimateproject.ehpart = this.part;
                this.$emit("ehprojectpartchange", this.estimateproject);
            },
            getcurrentvertion(){
                if(this.version){
                     var version = this.versions.find(s => s ==  this.version);
                     if(version){
                          this.version = version;
                          this.project.version = version;
                     }
                }else{
                 if( this.$store.state.ehproject.version != ""){
                      var version = this.versions.find(s => s ==  this.$store.state.ehproject.version);
                      if(version){
                          this.version = version;
                          this.project.version = version;
                      }
                 }
                }
                 this.parts = this.dataparts.filter((x)=>{
                     if(x.version == this.version)
                     {
                         return x;
                     }
                 })
                  this.estimateproject.version = this.project.version;
                   this.$emit("ehprojectpartchange", this.estimateproject);
                     this.getcurrentpart();
            },
            getcurrentpart: function () {
                this.ehprojectpartid = null;
                if (this.part.name && this.version) {
                    var part = this.parts.find(s => s.name == this.part.name && s.version == this.version);
                    if (part){
                        this.ehprojectpartid = part.id;
                        this.part = part;
                    }
                }
                if (this.ehprojectpartid == null) {
                    if (this.part && this.version) {
                        var part = this.parts.find(s => s.id == this.part.id && s.version == this.version);
                        if (part) {
                            this.ehprojectpartid = part.id;
                            this.part = part;
                        }
                    }
                }
                if (part != undefined) {
                    this.changepart(part.id);
                    this.estimateproject.ehproject = this.project;
                    this.estimateproject.ehpart = this.part;
                }
            },

            getpart: function () {
                this.$http.post("hoursestimate/getparts", this.project)
                    .then(response => {
                        this.dataparts = response.data.ehprojectparts;
                        this.project = response.data;
                        this.versions = response.data.versions;
                        if (this.dataparts.length == 0 && this.project.projectname != "") {
                            this.dataparts.unshift({ id: 0, name: "Single Bill" });
                        }
                        this.getcurrentvertion();
                    })
                    .catch(err => {
                        this.$bus.$emit("error", err);
                        this.loading = false;
                    });
            },
            addprojectbyid(proj){
             if(proj.projectid != null){
                 if(proj.partid){this.part.id = proj.partid; this.part.name = "";}
                 if(proj.version){this.version = proj.version;}
                 var data = {id:proj.projectid}
                 return this.post('HoursEstimate/getehprojectbyid',data).then((response)=>{
                   this.$nextTick(()=>{
                       this.project = response.data;
                       this.versions = response.data.versions;
                       this.$set(this.estimateproject,'ehproject',response.data);
                       this.$set(this.estimateproject.ehproject,'projectname',response.data.projectname);
                       this.$set(this.$store.state.ehproject,'project',response.data);
                       this.dataparts = response.data.ehprojectparts;
                       if (this.dataparts.length == 0 && this.project.projectname != ""){
                              this.dataparts.unshift({ id: 0, name: "Single Bill" });
                        }
                       this.getcurrentvertion();
                      // this.selectehproject(this.$store.state.ehproject.project);
                 });
               });
             }
            }
        },
        destroyed() {
            this.project = {};
            this.parts = [];
            this.part = {};
            this.dataparts = [];
            this.ehprojectpartid = null;
            this.estimateproject = {
                    ehproject: {},
                    ehpart: {},
                    ehparts: []
                }
          this.$bus.$off("addehprojectbyid")
        },
        created:function() {
            this.$bus.$on("addehprojectbyid", (data)=>{
                 if(data.projectid != undefined){
                     this.addprojectbyid(data);
                 }
             });
            if( this.$store.state.ehproject.project.projectname != ""  && this.remember == true){
               this.selectehproject(this.$store.state.ehproject.project);
            }
        },
    }
</script>
