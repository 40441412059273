<template>
    <div v-if="showdata">
        <div class="fixdheader">
            <div class="headerstyle">Super Admin</div>
        </div>
        <el-row>
            <el-tabs type="card" @tab-click="handletab">
                <el-tab-pane label="Page Cell's And Action's">
                    <el-row >
                        <el-col :span="12">
                             <span class="headerstyle">Page Cell's And Action's</span>
                        </el-col>
                       <el-col :span="12"> 
                        <el-row>
                                <el-col :span="12">
                                    <el-input placeholder="Search Page's Name" v-model="searchpages"></el-input>
                                </el-col>
                                <el-col :span="12">
                                    <span>
                                        <el-button @click="savepageaction()" type="success" icon="el-icon-circle-check" class="right">Save</el-button>
                                    </span>
                                </el-col>
                            </el-row>
                       </el-col>
                    </el-row>
                    
                    <el-row>
                        <el-table  :data="getpages" >
                            <el-table-column type="expand">
                                <template slot-scope="pc">
                                           <el-table
                                                :data="[{name:'Page Cells'},{name:'Cell Actions'}]">
                                                <el-table-column type="expand">
                                                <template slot-scope="ext">
                                                    <div v-if="ext.row.name == 'Page Cells'">
                                                          <div class="boxborder">
                                                            <el-row>
                                                                <el-col :span="12"><h3><b>Page cell's</b></h3></el-col>
                                                                <el-col :span="12">
                                                                    <el-button @click="addnewactionscells(pc.row,'pagecells')" title="Add New" class="padding7 right" icon="el-icon-circle-plus"  type="primary"></el-button>
                                                                </el-col>
                                                            </el-row>
                                                                <el-row>
                                                                    <el-table
                                                                        :data="pc.row.pagecells">
                                                                        <el-table-column
                                                                        prop="cellname"
                                                                        label="Cell name">
                                                                        <template slot-scope="scope">
                                                                            <el-input @change="canchanged(pc.row,scope.row)" placeholder="Cell name" v-model="scope.row.cellname"></el-input>
                                                                        </template>
                                                                        </el-table-column>
                                                                        <el-table-column
                                                                        prop="displayname"
                                                                        label="Display Name">
                                                                            <template slot-scope="scope">
                                                                                <el-input @change="canchanged(pc.row,scope.row)" placeholder="Cell Display Name" v-model="scope.row.displayname"></el-input>
                                                                            </template>
                                                                        </el-table-column>
                                                                        <!-- <el-table-column
                                                                                prop="id"
                                                                                label="Delete"
                                                                                width="50">
                                                                                <template slot-scope="scope">
                                                                                    <el-button  @click="deleteactionscells(pc.row,'pagecells',scope.$index)" title="Delete Row" class="padding7 right" size="mini" type="danger" icon="el-icon-delete"></el-button>
                                                                                </template>
                                                                            </el-table-column> -->
                                                                    </el-table>
                                                                </el-row>
                                                            </div>
                                                    </div>
                                                    <div v-else>
                                                        <div class="boxborder">
                                                                <el-row>
                                                                   <el-col :span="12"><h3><b>Cell Action's</b></h3></el-col>
                                                                   <el-col :span="12">
                                                                       <el-button @click="addnewactionscells(pc.row,'cellactions')" title="Add New" class="padding7 right" icon="el-icon-circle-plus"  type="primary"></el-button>
                                                                   </el-col>
                                                            </el-row>
                                                                <el-row>
                                                                    <el-table
                                                                        :data="pc.row.cellactions">
                                                                        <el-table-column
                                                                        prop="actionname"
                                                                        label="Action name">
                                                                        <template slot-scope="scope">
                                                                            <el-input @change="canchanged(pc.row,scope.row)" placeholder="Action name" v-model="scope.row.actionname"></el-input>
                                                                        </template>
                                                                        </el-table-column>
                                                                        <el-table-column
                                                                        prop="displayname"
                                                                        label="Display Name">
                                                                            <template slot-scope="scope">
                                                                                <el-input @change="canchanged(pc.row,scope.row)" placeholder="Action Display Name" v-model="scope.row.displayname"></el-input>
                                                                            </template>
                                                                        </el-table-column>
                                                                        <!-- <el-table-column
                                                                                prop="id"
                                                                                label="Delete"
                                                                                width="50">
                                                                                <template slot-scope="scope">
                                                                                    <el-button @click="deleteactionscells(pc.row,'cellactions',scope.$index)" title="Delete Row" class="padding7 right" size="mini" type="danger" icon="el-icon-delete"></el-button>
                                                                                </template>
                                                                            </el-table-column> -->
                                                                    </el-table>
                                                                </el-row>
                                                            </div>
                                                    </div>
                                                </template>
                                                </el-table-column>
                                                <el-table-column
                                                    prop="name" >
                                                </el-table-column>
                                            </el-table>
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="p.displayname"
                                label="Display Name">
                            </el-table-column>
                            <el-table-column
                                prop="p.name"
                                label="Page Name">
                            </el-table-column>
                            </el-table>
                    </el-row>
                </el-tab-pane>
            </el-tabs>
        </el-row>
    </div>
</template>
  
<script>
export default {
    data() {
        return {
            searchpages:'',
            pages:[],
            showdata:false,
            activetab: "Page Cell's And Action's",
        };
    },
    computed: {
        getpages:function(){
                if(!this.pages){
                    return [];
                }
                if(this.searchpages != ""){
                   return this.pages.filter(x => x.p.name.includes(this.searchpages.toLowerCase())); 
                }else{
                    return this.pages;
                }
        }
    },
    methods: {
        deleteactionscells(row,name,index){
            var newname = "";
            var isid = false;
            if(name == "pagecells"){
                isid = row.pagecells[index].id > 0;
                newname = "pagecell";
            }else{
                newname = "cellaction";
                isid = row.cellactions[index].id > 0;
            }
            if(isid){
            this.$confirm(`This will permanently delete the ${newname}. Continue?`, 'Warning', {
               confirmButtonText: 'OK',
               cancelButtonText: 'Cancel',
               type: 'warning'
               }).then(() => {
               var newobj = {
                   pagecells:[],
                   cellactions:[],
               }
               if(name == 'pagecells'){
                if(row.pagecells[index].id > 0){
                   newobj.pagecells.push(row.pagecells[index]);
                   this.deletepagecellandaction(newobj);
                }
               }else{
                if(row.cellactions[index].id > 0){
                   newobj.cellactions.push(row.cellactions[index]);
                   this.deletepagecellandaction(newobj);
                }
               }   

               }).catch(() => {
               this.$message({
                   type: 'info',
                   message: 'Delete canceled'
               });          
               });
            }else{
                if(name == "pagecells"){
                    row.pagecells.splice(index, 1);
                }else{
                    row.cellactions.splice(index, 1);
                }
                this.$message({
                    type: 'success',
                    message: 'Delete completed'
                });
            }
          
        },
        deletepagecellandaction(data){
            this.post("Direction/deletepagecellandaction",data).then((res)=>{
                    this.$message({
                            type: 'success',
                            message: 'Delete completed'
                        });
                    this.getpageview();
                })
        },
        canchanged(row,subrow){
            subrow.edit = true;
            row.edit = true;
        },
        findDuplicateValues(array, property) {
            let frequencies = {};
            let duplicates = [];

            for (let i = 0; i < array.length; i++) {
                let value = array[i][property];
                if (value !== "" && frequencies[value]) {
                frequencies[value]++;
                } else if (value !== "") {
                frequencies[value] = 1;
                }
            }

            for (let value in frequencies) {
                if (frequencies[value] > 1) {
                duplicates.push(value);
                }
            }

            return duplicates;
        },
        validate(data,callback){
            var error = [];
            if(data.length <= 0){
                return false;
            }
            data.forEach(pa => {
                pa.pagecells.forEach(pc => {
                    if(pc.edit){
                        if(pc.cellname == ""){
                            error.push( "Page:<b>" +  pa.p.displayname + "</b> Cell Name should not be empty in pagecells");
                        }
                        if(pc.displayname == ""){
                            error.push( "Page:<b>" +  pa.p.displayname + "</b> Display Name should not be empty in pagecells");
                        }
                    }
                });
                pa.cellactions.forEach(pc => {
                    if(pc.edit){
                        if(pc.actionname == ""){
                            error.push( "Page:<b>" +  pa.p.displayname + "</b> Action Name should not be empty in pagecells");
                        }
                        if(pc.displayname == ""){
                            error.push( "Page:<b>" +  pa.p.displayname + "</b> Display Name should not be empty in pagecells");
                        }
                    }
                });
                var actionnamedup =  this.findDuplicateValues(pa.cellactions,'actionname');
                var actiondisplaynamedup =  this.findDuplicateValues(pa.cellactions,'displayname');
                var cellnamedup =   this.findDuplicateValues(pa.pagecells,'cellname');
                var celldisplaynamedup =   this.findDuplicateValues(pa.pagecells,'displayname');
                if(actionnamedup.length >  0){
                    actionnamedup.forEach(e => {
                        error.push( `Page:<b> ${pa.p.displayname} </b> Action Name: <b>${e}</b> duplicate in cellactions`);
                    });
                }
                if(actiondisplaynamedup.length >  0){
                    actiondisplaynamedup.forEach(e => {
                        error.push( `Page:<b> ${pa.p.displayname} </b> Display Name: <b>${e}</b> duplicate in cellactions`);
                    });
                }
                if(cellnamedup.length >  0){
                    cellnamedup.forEach(e => {
                        error.push( `Page:<b> ${pa.p.displayname} </b> Cell Name: <b>${e}</b> duplicate in pagecells`);
                    });
                }
                if(celldisplaynamedup.length >  0){
                    celldisplaynamedup.forEach(e => {
                        error.push( `Page:<b> ${pa.p.displayname} </b> Display Name: <b>${e}</b> duplicate in pagecells`);
                    });
                }
                
            });
            if(error.length  > 0){
                this.$alert(error.join('<br />'), "! Warning", {
                    dangerouslyUseHTMLString: true,
                })
            }else{
                callback();
            }
        },
        savepageaction(){
             var newarray = [];
             newarray = this.pages.filter(x => x.edit && x.edit == true)
             var newsave = () => {
                this.post("Direction/savepageview",newarray).then((res)=>{
                    this.$message({
                        message: 'cell action and page call saved successfully',
                        type: 'success'
                        });
                    this.getpageview();
                })
             }
             this.validate(newarray,newsave);
            
        },
       
        addnewactionscells(row,name){
            if(name == 'pagecells'){
                row.pagecells.splice(0,0,{cellname:'',displayname:'',pageid:row.p.id,edit:true});
            }else{
                row.cellactions.splice(0,0,{actionname:'',displayname:'',pageid:row.p.id,edit:true});
            }
            row.edit = true;
        },
        getpageview(){
            this.get("Direction/getpageview").then((res)=>{
                this.pages = res.data;
            })
        },
        handletab(tab, event) {
            this.activetab = tab;
        },
        issuperadminuser() {
            if (this.$store.state.user.role == undefined) {
                this.$bus.$on('setuser', (user) => {
                    if (user.role == "A") {
                        this.getpageview();
                        this.showdata = true;
                    }
                    else {
                        this.showdata = false;
                        this.$router.push("/");
                    }
                });
            }
            else if (this.$store.state.user.role == 'A') {
                this.getpageview();
                this.showdata = true;
            }
            else {
                this.showdata = false;
                this.$router.push('/')
            }
        },
    },

    created: function () {
        this.issuperadminuser();
        
        
    },
};
</script>
  
<style scoped>
  .boxborder{
    padding: 5px;
    margin: 5px;
    border: 1px solid black;
    border-radius: 10px;
  }
</style>
  