<template>
    <div>
        <div id="templates">
            <div class="fixdheader mcamtwidth padright5px">
            <el-row class="right6px">
                <el-col :span="24" class="headerstyle"> <span >Automated Measurement Templates Library</span> </el-col>
            </el-row>

            <el-row class="right6px">
                <el-col :span="8">
                    <el-autocomplete
                        class="inline-input fullwidth bold paddinglr16px"
                        v-model="grid.section"
                        :spellcheck="isspellcheck"
                        :fetch-suggestions="querySection"
                        placeholder="Section"
                        @select="onSearch"
                        clearable
                        :clear="onSearch">
                    </el-autocomplete>
                </el-col>

                <el-col :span="3" class="widthrwclibrary">
                    <el-select v-model="grid.library"
                               class="fullwidth"
                               clearable
                               @change="onSearch"
                               @clear="onSearch"
                               placeholder="Library">
                        <el-option v-for="item in libraries"
                                   :key="item"
                                   :label="item"
                                   :value="item">
                        </el-option>
                    </el-select>
                </el-col>

                <el-col :span="8">
                    <el-input :spellcheck="isspellcheck" v-model="grid.search" placeholder="Template Name" @keyup.enter.native="onSearch()" class="bold paddinglr16px">
                        <el-button slot="append" icon="el-icon-search" class="paddingl13" circle @click="onSearch()"></el-button>
                    </el-input>
                </el-col>
            </el-row>

            <div class="spaceBetween" />

            <el-row>
                <el-col :span="20" align="center">
                    <el-pagination class="pagesizeswidth textalignlpage" 
                                    @size-change="rowperpages" 
                                    @current-change="currentChange" 
                                    :current-page.sync="grid.pageno" 
                                    :page-sizes="pagesizes" 
                                    :page-size="grid.pagesize"  
                                    layout="sizes, prev, pager, next" :total="grid.count">
                    </el-pagination>
                </el-col>

                <el-col :span="4" align="right">
                    <el-button type="primary" :disabled="!editpermission" @click="createNew()" icon="el-icon-circle-plus"></el-button>
                </el-col>
            </el-row>

             <hr class="hrstyle" />
            </div>
            <div class="tablepaddinglr3" style="width: 900px;">
            <el-table :data="tabledata" class="fullwidth" :height="height"
                      :default-sort="{prop: 'amtemplatename', order: 'ascending'}"
                      size="mini"
                      @sort-change="sortChange"
                      v-loading="loading">
                <el-table-column prop="section" label="Section" sortable="custom" width="300"></el-table-column>

                <el-table-column prop="library" label="Library" sortable="custom" width="100"></el-table-column>

                <el-table-column prop="amtemplatename" label="Template Name" sortable="custom" width="260"></el-table-column>

                <el-table-column prop="author.loginname" label="Last edited by" width="120"></el-table-column>

                <!--:label="'\xa0\xa0'+'Edit' +'\xa0\xa0\xa0\xa0\xa0\xa0'+'Delete' + '\xa0\xa0'" min-width="150" align="right"-->
                <el-table-column label="Edit" width="50" align="center">
                    <template slot-scope="scope">
                        <el-button size="mini" type="primary" :icon="editpermission == true ? 'el-icon-edit' : 'el-icon-view'"
                                   @click="handleEdit(scope.$index, scope.row)">
                        </el-button>
                        <!-- {{editpermission == true ? 'Edit' : 'View' }} -->
                    </template>
                </el-table-column>

                <el-table-column label="Delete" width="57" align="right">
                    <template slot-scope="scope">
                        <el-button size="mini" type="danger" icon="el-icon-delete"
                                   :disabled="!editpermission" @click="handleDelete(scope.$index, scope.row)">
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                loading: false,
                tabledata: [],
                grid: {},
                height: (document.documentElement.clientHeight - 178),
                pagesizes: this.$store.state.pagesizes,
                detailform: {},
                sections: this.$store.state.sections,
                libraries: this.$store.state.libraries,
            }
        },                     

        methods: {
            getheight() {
                this.height = (document.documentElement.clientHeight - 178);
            },
            rowperpages: async function (val) {
                try {
                    this.usergridrow.ugv.rowperpage = val;
                    this.usergridrow = await this.$training.savepagelayout(this.usergridrow);
                    this.grid.pageno = 1;
                    this.grid.pagesize = val;
                    this.refresh();
                } catch (error) {
                    this.$bus.$emit('error', error);
                }
            },
            createNew: function () {
                this.$router.push("/amtlibraryedit");
            },

            handleEdit: function (scope, row) {
                this.$router.push({ path: '/amtlibraryedit', query: { id: row.id } });
            },

            handleDelete: function (scope, row) {
                this.$confirm('This will permanently delete ' + row.amtemplatename + '. Continue?', 'Warning', {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                }).then(() => {
                    this.$http.post('amt/Delete', row)
                        .then(response => {
                            this.refresh();
                            this.$message({
                                showClose: true,
                                type: 'success', message: 'Delete completed'
                            });
                        })
                        .catch(err => {
                            this.$bus.$emit('error', err);
                        })
                }).catch(() => {
                    this.$message({
                        showClose: true,
                        type: 'info',
                        message: 'Delete canceled'
                    });
                });
            },

            refresh: function () {
                if (this.grid.section) {
                    this.$store.state.section = this.grid.section;
                }
                // this.loading = true;
                this.$store.state.isLoading = true;

                this.$http.post('amt/GetGrid', this.grid)
                    .then(response => {
                        var json = JSON.parse(response.data);
                        this.tabledata = json.data;
                        this.grid = json.grid;
                        // this.loading = false;

                        this.$store.state.isLoading = false;
                    }).catch(err => {
                        // this.loading = false;
                        this.$store.state.isLoading = false;
                        this.$bus.$emit('error', err);
                    });
            },

            querySection: function (query, done) {
                query = query ? query : ""; // if null set to empty string
                var result = [];
                this.sections.forEach(x => {
                    if (x.section.toLowerCase().indexOf(query.toLowerCase()) >= 0)
                        result.push({ value: x.section });
                });
                done(result);
            },
            onSearch: function () {
                this.grid.pageno = 1;
                this.refresh();
            },
            currentChange: function (val) {
                this.grid.pageno = val;
                this.refresh();
            },
            sortChange: function (sort) {
                this.grid.sortcol = sort.prop;
                this.grid.sortorder = sort.order;
                this.refresh()
            },
            beforewindowunload: function (e) {
                //Prevent accidently close/reload window
                if (this.detailform.changed) {
                    //return window.confirm('Do you really want to leave? you have unsaved changes!');
                    return "Do you really want to leave ? you have unsaved changes!"
                }
            }
        },

        created: function () {
            this.grid.section = this.$store.state.section;
            this.grid.library = this.$store.state.project.library;
            let gridrow = this.$training.getusergridrow(this.$route);
            this.grid.pagesize = gridrow.ugv.rowperpage;
            this.usergridrow = gridrow;
            this.refresh(); //not require here because default refersh is called from sortChange
            window.onbeforeunload = this.beforewindowunload;
        },

        destroyed() {
            window.removeEventListener('resize', this.getheight);
        },

        mounted: function () {
            window.addEventListener('resize', this.getheight);
        },
    }
</script>
