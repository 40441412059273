<template>
    <div >
        <div v-show="showdata">
            <el-row>
                <span class="headerstyle">Error Log</span>
            </el-row>
            <el-row :gutter="5">
                <el-col :span="6">
                    <el-date-picker v-model="view.filter[0].value"
                                    type="date"
                                    align="center"
                                    style="width:100%"
                                    :clearable="false"
                                    class="custom-date-picker"
                                    value-format="yyyy-MM-dd"
                                    @change="refresh"
                                    placeholder="Date">
                    </el-date-picker>
                </el-col>
                <el-col :span="6">
                    <el-input v-model="view.filter[1].value" placeholder="Level" @change="refresh">
                    </el-input>
                </el-col>
                <el-col :span="12">
                    <el-pagination layout="prev, pager, next"
                                   :total="view.count"
                                   :page-size="view.pagesize"
                                   @current-change="refresh"
                                   :current-page.sync="view.pageno">
                    </el-pagination>
                </el-col>
            </el-row>
            <div class="errorlogwraptext">
                <el-table :data="form"
                          :default-sort="{prop: 'l.date', order: 'descending'}"
                          @sort-change="sortchange"
                          :cell-style="{height: '28px'}"
                          border stripe>
                    <el-table-column label="Error Message" width="110" type="expand">
                        <template slot-scope="scope">
                            <div style="white-space:pre;">
                                {{ scope.row.l.message }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="Thread ID"
                                     prop="l.threadid"
                                     sortable="custom"
                                     min-width="40">
                    </el-table-column>

                    <el-table-column label="Error On"
                                     prop="l.date"
                                     :formatter="dateformatter"
                                     sortable="custom"></el-table-column>

                    <el-table-column label="Level"
                                     prop="l.level"
                                     sortable="custom" min-width="50"> </el-table-column>

                    <el-table-column label="Callsite" prop="l.callsite" sortable="custom">
                        <template slot-scope="scope">
                            <el-tooltip effect="light" placement="bottom-start" :content="scope.row.l.callsite">
                                <span>{{ scope.row.l.callsite }}</span>
                            </el-tooltip>
                        </template>
                    </el-table-column>

                    <el-table-column label="Stacktrace" prop="l.stacktrace" sortable="custom">
                        <template slot-scope="scope">
                            <el-tooltip effect="light" placement="bottom-start" :content="scope.row.l.stacktrace">
                                <span>{{ scope.row.l.stacktrace }}</span>
                            </el-tooltip>
                        </template>
                    </el-table-column>

                    <el-table-column label="Delete" fixed="right" width="70" align="center">
                        <template slot-scope="scope">
                            <el-button-group>
                                <el-button size="mini" type="primary" icon="el-icon-delete" v-if="scope.row.l.id > 0" @click="deleteRow(scope.row)"></el-button>
                            </el-button-group>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <br />
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                showdata: false,
                view: {
                    filter: [
                        { table: 'l', column: 'date', value: '' },
                        { table: 'l', column: 'level', value: '' }
                    ],
                },
                form: []
            }
        },

        methods: {
            dateformatter: function (row, column, value, index) {
                if (value) {
                    var d = new Date(value);
                    return d.toLocaleString();
                } else {
                    return "";
                }
                return value;
            },

            sortchange: function (sort) {
                this.view.sortcol = sort.prop;
                this.view.sortorder = sort.order;
                this.refresh();
            },

            refresh: function () {
                this.showloading();
                if (this.view.filter[0].value == null) {
                    this.view.filter[0].value = "";
                }
                this.$http.post('errorlog/getview', this.view)
                    .then(response => {
                        this.form = response.data.data;
                        this.view = response.data.view;
                        this.hideloading();
                    })
                    .catch(err => {
                        this.hideloading();
                        this.$bus.$emit('error', err);
                    });
            },

            deleteRow: function (row) {
                this.$confirm('Are you sure you want to Delete?', 'Warning', {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                }).then(() => {
                    this.showloading();
                    this.$http.post("/errorlog/deletelogerror", { id: row.l.id })
                        .then(response => {
                            this.hideloading();
                            this.$message({
                                showClose: true,
                                message: response.data.message,
                                type: "success"
                            });
                            this.refresh();
                        })
                        .catch(err => {
                            this.hideloading();
                            this.$bus.$emit('error', err);
                        });
                }).catch(() => {
                    this.$message({
                        showClose: true,
                        type: 'info',
                        message: 'Delete canceled'
                    });
                });
            }
        },

        created: function () {
            var sort = { prop: 'l.date', order: 'descending' };
            if (this.$store.state.user.role == undefined) {
                this.$bus.$on('setuser', (user) => {
                    if (user.role == "A") {
                        //this.refresh();
                        this.sortchange(sort);
                        this.showdata = true;
                    }
                    else {
                        this.$router.push("/");
                        this.showdata = false;
                    }
                });
            }
            else if (this.$store.state.user.role == 'A') {
                //this.refresh();
                this.sortchange(sort);
                this.showdata = true;
            }
            else {
                this.$router.push('/');
            }
        }
    };
</script>
<style itemscope>
    .errorlogwraptext .el-table .cell {
        white-space: pre;
        max-height: 28px;
    }

    .el-tooltip__popper.is-light {
        background: #FFF;
        border: 1px solid #303133;
        white-space: pre;
    }
</style>
