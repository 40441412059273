import { render, staticRenderFns } from "./pageguidance.vue?vue&type=template&id=853096ea&scoped=true"
import script from "./pageguidance.vue?vue&type=script&lang=js"
export * from "./pageguidance.vue?vue&type=script&lang=js"
import style0 from "./pageguidance.vue?vue&type=style&index=0&id=853096ea&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "853096ea",
  null
  
)

export default component.exports