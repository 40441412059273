<template>
    <div>
        <div class="dashboardlibrarywidth" v-if="!displaymode">
            <div class="fixdheader">
                <el-row>
                    <span class="headerstyle left paddingr83">Comparatives Library</span>
                </el-row>

                <div class="flexbox justifycontentsb">
                    <span>
                        <el-input :spellcheck="isspellcheck" v-model="view.filter[0].value" placeholder="Comparatives Name"
                            :id="'library_comparativeslibrary_filter_comparativename'" @keyup.enter.native="filtersearch()"
                            class="bold width260px">
                            <el-button slot="append" icon="el-icon-search" circle @click="refresh"></el-button>
                        </el-input>
                    </span>

                    <span>
                        <el-pagination layout="sizes, prev, pager, next" class="pagesizeswidth textalignlpage"
                            @size-change="rowperpages" :total="view.count" @current-change="currentchange"
                            :page-sizes="pagesizes" :current-page.sync="view.pageno" :page-size="view.pagesize">
                        </el-pagination>
                    </span>

                    <span class="floatright">
                        <el-button type="primary" :disabled="!editpermission" icon="el-icon-circle-plus"
                            @click="addnew">Create New</el-button>
                    </span>
                </div>
                <hr class="hrstyle3" />
            </div>

            <div class="tabletexttop tablepaddinglr0px tablewordbreak">
                <el-table :data="tabledata" :height="height" stripe :default-sort="{ prop: 'name', order: 'ascending' }"
                    @sort-change="sortchange">
                    <el-table-column label="Comparatives Name" class-name="columnpaddright" prop="name" sortable="custom"
                        width="280">
                    </el-table-column>

                    <el-table-column label="Detailed Description" class-name="columnpaddright" prop="comment"
                        sortable="custom">
                    </el-table-column>

                    <el-table-column label="Author" prop="author.loginname" width="88"></el-table-column>

                    <el-table-column label="Actions" header-align="center" class-name="columnpaddlr0px" width="96">
                        <template slot-scope="scope">
                            <el-button type="primary" :icon="editpermission == true ? 'el-icon-edit' : 'el-icon-view'"
                                class="padding7" @click="editrow(scope.row)"></el-button>

                            <el-button type="primary" icon="glyphicon glyphicon-duplicate" style="padding: 6px 7px 7px 7px;"
                                @click="copyrow(scope.row)" :disabled="!editpermission"></el-button>

                            <el-button type="danger" icon="el-icon-delete" class="padding7" @click="deleterow(scope.row)"
                                :disabled="!editpermission"></el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>

        <div v-if="displaymode">
            <div class="fixdheader dashboardlibrarywidth">
                <span class="headerstyle">Dashboard - Comparatives</span>
                <div class="floatright margintb1mm">
                    <span>
                        <el-button type="primary" :disabled="!editpermission" icon="el-icon-circle-plus"
                            @click="addnew"></el-button>
                    </span>
                    <span class="marginleft4px">
                        <el-button type="success"
                            @click="cellchanged('library_comparativeslibrary_addedit_createreport', () => { createreport() }, false)">Create
                            report</el-button>
                    </span>
                    <span class="marginleft4px">
                        <el-button type="success" :disabled="!editpermission"
                            @click="cellchanged('library_comparativeslibrary_addedit_export', () => { exporttoexcel() }, false)">Export</el-button>
                    </span>
                    <span class="marginleft4px">
                        <el-button type="success" :disabled="!editpermission" v-loading.fullscreen.lock="saving"
                            @click="cellchanged('library_comparativeslibrary_addedit_save', () => { save() }, false)">Save</el-button>
                    </span>
                    <span class="marginleft4px">
                        <el-button type="info" icon="el-icon-back" @click="goback">Back</el-button>
                    </span>
                </div>
                <hr class="hrstyle" />
            </div>

            <div>
                <div>
                    <!-- <el-input v-model="detailform.dc.name" class="marginb5 width422px" placeholder="Comparative Name">
                    </el-input> -->
                    <el-input type="textarea" placeholder="Comparative Name" class="bqtextarea width422px marginb5"
                        :id="'library_comparativeslibrary_addedit_comparativename'" v-model="detailform.dc.name"
                        :autosize="{ minRows: 2, maxRows: 4 }" maxlength="50" show-word-limit
                        @input="charcount($event, 50, 'name')" @change="changeinputs()">
                    </el-input>
                </div>

                <div>
                    <el-input type="textarea" placeholder="Detailed Description:" class="bqtextarea width422px marginb5"
                        :id="'library_comparativeslibrary_addedit_detaileddescription'" v-model="detailform.dc.comment"
                        :autosize="{ minRows: 2, maxRows: 4 }" maxlength="150" show-word-limit
                        @input="charcount($event, 150, 'comment')" @change="changeinputs()">
                    </el-input>
                </div>
            </div>

            <div class="displayflex">
                <div>
                    <span class="widthlabel102 inline bordergrey marginr10px">
                        <span class="labelbox width100px bold margintop50">
                            Activities
                        </span>
                        <span class="labelbox width100px lightpurple">
                            AMOS
                        </span>
                        <span class="labelbox width100px lightgreen marginbottom50">
                            Project
                        </span>
                        <span class="labelbox width100px bold">
                            Usage No
                        </span>
                        <span class="labelbox width100px lightpink">
                            BQ_Codes
                        </span>
                        <span class="labelbox width100px lightcyan marginbottom50">
                            MC_T
                        </span>
                        <span class="labelbox width100px">
                            Start Date
                        </span>
                        <span class="labelbox width100px">
                            Finish Date
                        </span>
                        <span class="labelbox width100px">
                            Project
                        </span>
                        <span class="labelbox width100px">
                            Project Part
                        </span>
                        <span class="labelbox width100px">
                            NRM Section
                        </span>
                        <span class="labelbox width100px">
                            Operative
                        </span>
                    </span>
                </div>

                <div class="divscroll">
                    <div id="singlechart" :style="{ width: customwidth + 'px' }" style="height: 310px;"
                        class="bordermedium bordergrey" />
                    <div class="maxcontent">
                        <div v-for="(row, i) in detailform.details" :key="i"
                            class="marginr10px margintop5 width150px verticalaligntop inline">
                            <span class="bordermedium bordergrey inline verticalaligntop">
                                <span>
                                    <el-date-picker type="date" align="center" v-model="row.dcd.startdate"
                                        :id="'library_comparativeslibrary_addedit_startdate_' + i" format="dd-MMM-yyyy"
                                        value-format="yyyy-MM-dd" :editable="false" :clearable="false"
                                        @change="changedatefilter($event, 'startdate', row)" placeholder="Start Date"
                                        class="custom-date-picker inputcenter bold width100primportant">
                                    </el-date-picker>
                                </span>

                                <span>
                                    <el-date-picker type="date" align="center" v-model="row.dcd.enddate"
                                        :id="'library_comparativeslibrary_addedit_enddate_' + i" format="dd-MMM-yyyy"
                                        value-format="yyyy-MM-dd" :editable="false" :clearable="false"
                                        @change="changedatefilter($event, 'enddate', row)" placeholder="Finish Date"
                                        class="custom-date-picker inputcenter width100primportant bold">
                                    </el-date-picker>
                                </span>

                                <span>
                                    <el-select class="inline-input bold inputcenter" :style="{ width: '100%' }"
                                        :id="'library_comparativeslibrary_addedit_project_' + i" v-model="row.p.id"
                                        @change="selectproject($event, row)" placeholder="Please select project" clearable
                                        filterable remote popper-class="customselect" reserve-keyword
                                        :remote-method="remoteMethodproject">
                                        <el-option v-for="item in projectoptions" :key="item.id" :label="item.projectname"
                                            :value="item.id">
                                            <div>
                                                <span class="left">{{ item.projectname }}</span>
                                                <span class="floatright">{{ item.accessedon | dateformat }}</span>
                                            </div>
                                        </el-option>
                                    </el-select>
                                </span>

                                <span>
                                    <el-popover placement="top-start" width="300" trigger="hover" :open-delay="500">
                                        <el-select slot="reference" v-model="row.pp.id" filterable
                                            :id="'library_comparativeslibrary_addedit_part_' + i"
                                            placeholder="Project parts" :style="{ width: '100%' }" class="bold inputcenter"
                                            @change="changepart($event, row)">
                                            <el-option v-for="item in row.projectparts" :key="item.id" :label="item.name"
                                                :value="item.id">
                                            </el-option>
                                        </el-select>
                                        <el-input type="textarea" readonly class="textareabold breakword" v-if="row.pp.name"
                                            :rows="2" placeholder="Project parts" v-model="row.pp.name">
                                        </el-input>
                                    </el-popover>
                                </span>

                                <span>
                                    <el-popover placement="top-start" width="300" trigger="hover" :open-delay="500">
                                        <el-select slot="reference" v-model="row.ps.id" filterable placeholder="Section"
                                            :id="'library_comparativeslibrary_addedit_nrmsection_' + i"
                                            :style="{ width: '100%' }" class="bold inputcenter"
                                            @change="sectionchange($event, row)">
                                            <el-option v-for="item in row.sections" :key="item.id"
                                                :label="item.sectiondisplay" :value="item.id">
                                            </el-option>
                                        </el-select>
                                        <el-input type="textarea" readonly class="textareabold breakword"
                                            v-if="row.ps.sectiondisplay" :rows="2" placeholder="Section"
                                            v-model="row.ps.sectiondisplay">
                                        </el-input>
                                    </el-popover>
                                </span>

                                <span>
                                    <el-autocomplete slot="reference" :style="{ width: '100%' }"
                                        :id="'library_comparativeslibrary_addedit_operative_' + i"
                                        class="inline-input bold inputcenter" v-model="row.su.loginname"
                                        popper-class="morewidth-autocomplete-project" :fetch-suggestions="searchuser"
                                        @select="selectuser($event, row)" value-key="loginname" placeholder="All users"
                                        clearable @clear="changeuser(row)">
                                        <template slot-scope="{ item }">
                                            <span class="left">{{ item.loginname }}</span>
                                        </template>
                                    </el-autocomplete>
                                </span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import echarts from 'echarts';
import moment from "moment";
export default {
    data() {
        return {
            displaymode: false,
            height: (document.documentElement.clientHeight - 130),
            view: {
                pagesize: 20,
                filter: [
                    { table: 'dc', column: 'name', value: "" }
                ],
                sortcol: "dc.name",
                sortorder: "ascending",
                defaultfilter: ""
            },
            pagesizes: this.$store.state.pagesizes,
            tabledata: [],
            projects: [],
            projectoptions: [],
            nrmsections: [],
            detailform: {
                dc: {
                    name: "",
                    comment: "",
                },
                details: []
            },
            saving: false,
            user: this.$store.state.user,
            chartbars: [],
            customwidth: 150,
            allproject: { id: -1, projectname: "All Projects" },
            allpart: { id: -1, name: "All Parts" },
            allsection: { id: -1, sectiondisplay: 'All NRM Sections' }
        }
    },

    methods: {
        cellchanged(cellaction, callback, cancall) {
            const app = this;
            this.$directionutility.setcomponent(app);
            if (cancall) {
                callback();
                this.canmatchcellid(() => { }, cellaction, "");
            } else {
                this.canmatchcellid(callback, cellaction, "");
            }
        },
        
        getheight() {
            this.height = (document.documentElement.clientHeight - 130);
        },

        getwidth() {
            this.customwidth = this.detailform.details.length * 160 - 10;
        },

        currentchange(val) {
            this.view.pageno = val;
            this.refresh();
        },

        rowperpages: async function (val) {
            try {
                this.usergridrow.ugv.rowperpage = val;
                this.usergridrow = await this.$training.savepagelayout(this.usergridrow);
                this.view.pageno = 1;
                this.view.pagesize = val;
                this.refresh();
            } catch (error) {
                this.$bus.$emit('error', error);
            }
        },

        filtersearch() {
            this.view.pageno = 1;
            this.refresh();
        },

        sortchange(sort) {
            this.view.sortcol = "dc." + sort.prop;
            this.view.sortorder = sort.order;
            this.refresh();
        },

        refresh() {
            this.showloading();
            this.$http.post('dashboard/getview', this.view)
                .then(response => {
                    this.tabledata = response.data.data;
                    this.view = response.data.view;
                    this.hideloading();
                })
                .catch((err) => {
                    this.hideloading();
                    this.$bus.$emit('error', err);
                });
        },

        deleterow(row) {
            this.$confirm('Are you sure you want to Delete?', 'Warning', {
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancel',
                type: 'warning'
            }).then(() => {
                this.showloading();
                this.$http.post("dashboard/Deletecomparatives", { id: row.id })
                    .then(response => {
                        this.hideloading();
                        this.$message({
                            showClose: true,
                            message: response.data.message,
                            type: "success"
                        });
                        this.refresh();
                    })
                    .catch(err => {
                        this.hideloading();
                        this.$bus.$emit('error', err);
                    });
            }).catch(() => {
                this.$message({
                    showClose: true,
                    type: 'info',
                    message: 'Delete canceled'
                });
            });
        },

        copyrow(row) {
            let data = { id: row.id };
            this.showloading();
            this.$http.post("dashboard/getdashboardbyid", data)
                .then((response) => {
                    this.detailform = response.data;
                    this.hideloading();
                    this.$prompt('Please enter new Comparative Name', 'Tip', {
                        confirmButtonText: 'OK',
                        cancelButtonText: 'Cancel',
                        inputValue: this.detailform.dc.name + " - Copy"
                    }).then(value => {
                        this.detailform.dc.id = 0;
                        this.detailform.dc.name = value.value;
                        this.detailform.dc.userid = this.user.id;

                        this.detailform.details.forEach(function (item) {
                            item.dcd.id = 0;
                            item.dcd.dashboardcomparativeid = 0;
                        });
                        this.save();
                    });
                })
                .catch((err) => {
                    this.hideloading();
                    this.$bus.$emit('error', err);
                });
        },

        editrow: async function (row) {
            try {
                let data = { id: row.id };
                this.showloading();
                const response = await this.$http.post("dashboard/getdashboardbyid", data);
                if (response.data.details.length > 0) {
                    for (const row of response.data.details) {
                        row.projectparts = [];
                        row.partsections = [];
                        row.sections = [];
                        if (row.dcd.projectid == -1) {
                            row.p = JSON.parse(JSON.stringify(this.allproject));
                            row.pp = JSON.parse(JSON.stringify(this.allpart));
                            row.projectparts.unshift(this.allpart);

                            row.sections = this.nrmsections;
                            let currentsec = row.sections.find(x => x.id == row.dcd.projectsectionid);
                            row.ps = JSON.parse(JSON.stringify(currentsec));
                            row.dcd.section = currentsec.section;
                        }
                        else {
                            await this.getprojectbyid(row.pp.id, row);
                        }
                    }
                    this.detailform = response.data;
                    this.getwidth();
                    this.createreport();
                } else {
                    this.addnew();
                }
                this.displaymode = true;
                this.hideloading();
            } catch (err) {
                this.hideloading();
                this.$bus.$emit('error', err);
            }
        },

        addnew: function () {
            let itm = {
                dcd: {
                    enddate: "",
                    startdate: "",
                    projectid: null,
                    projectpartid: null,
                    projectsectionid: null,
                    searchuserid: null
                },
                p: {},
                pp: {},
                ps: {},
                su: {},
                sections: [],
                projectparts: [],
                partsections: [],
                amoscount: 0,
                projectcount: 0,
                bqcodecount: 0,
                mctcount: 0
            }
            this.getcurrentmonth(itm);
            this.detailform.dc.userid = this.user.id;
            this.detailform.changed = true;
            this.detailform.details.unshift(itm);
            this.getwidth();
            this.displaymode = true;
            const changedlist = this.comparativesfilter();
            if (changedlist.length > 0) {
                this.createreport();
            }
        },

        comparativesfilter() {
            const data = [];
            for (const row of this.detailform.details) {
                if (row.dcd.projectsectionid !== null) {
                    data.push(row.dcd);
                }
            }
            return data;
        },

        getcurrentmonth: function (row) {
            let today = new Date();
            let d = today.getDate();
            let m = today.getMonth() - 1;
            let y = today.getFullYear();
            let previousmonth = new Date(y, m, d);
            row.dcd.enddate = moment(new Date()).format("YYYY-MM-DD").toString();
            row.dcd.startdate = moment(previousmonth).format("YYYY-MM-DD").toString();
        },

        changedatefilter(event, name, row) {
            if (event == null) {
                row.dcd[name] = "";
            }
            else {
                row.dcd[name] = moment(event).format("YYYY-MM-DD").toString();
            }
            this.detailform.changed = true;
        },

        changeinputs() {
            this.detailform.changed = true;
        },

        charcount: function (val, maxlen, colname) {
            if (val.length > maxlen && colname == 'name') {
                let style = "<style> .el-message-box {width:30%;} </style>";
                this.$alert(`You have reached the maximum limit of ${maxlen} characters. ${style}`, "Length Limitation", {
                    dangerouslyUseHTMLString: true,
                });
            }

            if (val.length > maxlen && colname == 'comment') {
                let style = "<style> .el-message-box {width:30%;} </style>";
                this.$alert(`You have reached the maximum limit of ${maxlen} characters. ${style}`, "Length Limitation", {
                    dangerouslyUseHTMLString: true,
                });
            }
        },

        remoteMethodproject(query) {
            if (query == "") {
                this.projectoptions = this.projects;
            }
            else {
                this.projectoptions = this.projects.filter(item => {
                    return item.projectname.toLowerCase().indexOf(query.toLowerCase()) > -1;
                });
            }
        },

        selectproject: function (item, row) {
            this.detailform.changed = true;
            row.pp = {};
            row.ps = {};
            row.sections = [];
            row.projectparts = [];
            row.partsections = [];
            this.projectoptions = this.projects;
            row.dcd.projectid = null;
            row.dcd.projectpartid = null;
            row.dcd.projectsectionid = null;
            if (item === -1) {
                row.p = JSON.parse(JSON.stringify(this.allproject));
                row.partsections = this.nrmsections;
                row.sections = this.nrmsections;
                row.projectparts.unshift(this.allpart);
                row.pp = this.allpart;
                row.dcd.projectid = item;
                row.dcd.projectpartid = item;
            }
            else {
                if (item != "") {
                    let newp = this.projects.find(x => x.id == item);
                    row.p = JSON.parse(JSON.stringify(newp));
                    row.dcd.projectid = item;
                    this.getsections(row);
                }
            }
        },

        getsections: function (row) {
            this.$http.post("project/getsections", row.p)
                .then((response) => {
                    row.partsections = response.data.projectsectionsview;
                    row.projectparts = response.data.projectparts;
                    if (row.projectparts.length == 0 && row.p.projectname != "") {
                        row.projectparts.unshift({ id: 0, name: "Single Bill" });
                    }
                    row.projectparts.unshift(this.allpart);
                }).catch((err) => {
                    this.$bus.$emit("error", err);
                });
        },

        getnrmsections() {
            this.$http.get('project/GetSectionsProjectCreate')
                .then(response => {
                    let newsections = response.data;
                    response.data.forEach(x => {
                        Object.assign(x, { sectiondisplay: x.section });
                    });
                    newsections.unshift(this.allsection);
                    this.nrmsections = newsections;
                }).catch(err => {
                    this.$bus.$emit('error', err);
                });
        },

        getprojectbyid: async function (event, row) {
            if (row.p.id != undefined) {
                try {
                    const response = await this.$http.post("project/getprojectbyid", { id: row.p.id });
                    row.partsections = response.data.projectsectionsview;
                    row.projectparts = response.data.projectparts;
                    if (row.projectparts.length == 0 && row.p.projectname != "") {
                        row.projectparts.unshift({ id: 0, name: "Single Bill" });
                    }
                    row.sections = [];
                    row.projectparts.unshift(this.allpart);
                    if (row.dcd.projectpartid == -1) {
                        row.pp = JSON.parse(JSON.stringify(this.allpart));
                        row.sections = this.nrmsections;
                        let currentsec = row.sections.find(x => x.id == row.dcd.projectsectionid);
                        row.ps = JSON.parse(JSON.stringify(currentsec));
                        row.dcd.section = currentsec.section;
                    }
                    else if (event == 0) {
                        row.sections = row.partsections.filter((s) => {
                            if (s.projectPartId == 0) {
                                return s;
                            }
                        });
                        row.sections.unshift(this.allsection);
                    } else {
                        row.sections = row.partsections.filter((s) => {
                            if (s.projectPartId == event) {
                                return s;
                            }
                        });
                        row.sections.unshift(this.allsection);
                    }
                } catch (err) {
                    this.$bus.$emit("error", err);
                }
            }
        },

        changepart: function (event, row) {
            this.detailform.changed = true;
            row.sections = [];
            row.ps = {};
            row.dcd.projectsectionid = null;
            let projectpart = row.projectparts.find((p) => p.id == event);
            row.pp = JSON.parse(JSON.stringify(projectpart));
            row.dcd.projectpartid = event;
            if (event == -1) {
                row.sections = this.nrmsections;
            }
            else if (event == 0) {
                row.sections = row.partsections.filter((s) => {
                    if (s.projectPartId == 0) {
                        return s;
                    }
                });
                row.sections.unshift(this.allsection);
            } else {
                row.sections = row.partsections.filter((s) => {
                    if (s.projectPartId == event) {
                        return s;
                    }
                });
                row.sections.unshift(this.allsection);
            }
        },

        sectionchange: function (event, row) {
            this.detailform.changed = true;
            let section = row.sections.find((s) => s.id == event);
            row.ps = JSON.parse(JSON.stringify(section));
            row.dcd.projectsectionid = event;
            row.dcd.section = section.section;
        },

        selectuser: function (item, row) {
            this.detailform.changed = true;
            row.dcd.searchuserid = item.id;
            row.su = item;
        },

        changeuser: function (row) {
            this.detailform.changed = true;
            row.dcd.searchuserid = null;
            row.su = {};
        },

        myvalidate: function (callback) {
            var msg = [];
            if (this.detailform.dc.name.trim() == "") {
                msg.push("Comparative Name is required.");
            }
            if (msg.length > 0) {
                var style = "<style> .el-message-box {width:50%;} </style>";
                this.$alert(msg.join("<br />") + style, "Invalid Data", {
                    dangerouslyUseHTMLString: true,
                });
            } else {
                callback();
            }
        },

        save() {
            this.myvalidate(() => {
                const changedlist = this.comparativesfilter();
                let data = {
                    dc: this.detailform.dc,
                    dcdetails: changedlist
                };

                this.saving = true;
                if (this.saving && changedlist.length > 0) {
                    this.showloading();
                    this.$http.post("dashboard/savecomparative", data)
                        .then((response) => {
                            this.$message({
                                type: "success",
                                showClose: true,
                                message: response.data.message
                            });
                            this.hideloading();
                            this.displaymode = false;
                            this.saving = false;
                            this.refresh();
                        })
                        .catch((err) => {
                            this.hideloading();
                            this.saving = false;
                            this.$bus.$emit('error', err);
                        });
                }
                else {
                    this.saving = false;
                    this.$message({
                        type: "info",
                        showClose: true,
                        message: "Nothing to save"
                    });
                }
            });
        },

        goback: function () {
            var answer = true;
            if (this.detailform.changed) {
                answer = window.confirm('Do you really want to leave? you have unsaved changes!');
            }
            if (answer == true) {
                this.detailform = {
                    dc: {
                        name: "",
                        comment: "",
                    },
                    details: []
                };
                this.chartbars = [];
                this.customwidth = 150;
                this.displaymode = false;
                this.refresh();
                this.detailform.changed = false;
            }

        },

        exporttoexcel: function () {
            const changedlist = this.detailform.details.filter((row) => {
                if (row.dcd.projectsectionid != null) {
                    row.dcd.projectname = row.p.projectname;
                    row.dcd.partname = row.pp.name
                    row.dcd.projectsectiondisplay = row.ps.sectiondisplay
                    row.dcd.username = row.su.loginname;
                    return row;
                }
            }).map((row) => row.dcd);

            let data = {
                dc: this.detailform.dc,
                exportview: changedlist
            };

            if (changedlist.length > 0) {
                this.showloading();
                let url = "dashboard/exportcomparatives";
                this.$http.post(url, data, { responseType: 'blob' })
                    .then((response) => {
                        this.hideloading();
                        let blob = response.data;
                        let currentdate = moment(new Date()).format("DD-MMM-YYYY HH:mm").toString();
                        let filename = `Dashboard - Comparatives ${currentdate}.xlsx`;
                        let link = document.createElement('a');
                        link.href = window.URL.createObjectURL(blob);
                        link.download = filename;
                        link.click();
                    })
                    .catch((err) => {
                        this.hideloading();
                        this.$bus.$emit('error', err);
                    });
            }
        },

        createreport: async function () {
            const changedlist = this.comparativesfilter();
            if (changedlist.length > 0) {
                try {
                    this.showloading();
                    const response = await this.$http.post("dashboard/getcomparativereport", changedlist);
                    if (response.data.length > 0) {
                        this.disposechart();
                        this.arrangechartvalue(response.data);
                        this.getwidth();
                        this.createchart();
                    }
                    this.hideloading();
                } catch (err) {
                    this.hideloading();
                    this.$bus.$emit('error', err);
                }
            }
            else {
                this.$message({
                    type: "info",
                    showClose: true,
                    message: "Please select section."
                });
            }
        },

        arrangechartvalue(arrchart) {
            this.chartbars = [];
            this.chartsections = [];
            for (let i = 0; i < this.detailform.details.length; i++) {
                const element = this.detailform.details[i];
                let start = moment(element.dcd.startdate).format("YYYY-MM-DD").toString();
                let enddate = moment(element.dcd.enddate).format("YYYY-MM-DD").toString();
                const predicaterow = (x) => {
                    let prestart = moment(x.dcd.startdate).format("YYYY-MM-DD").toString();
                    let preend = moment(x.dcd.enddate).format("YYYY-MM-DD").toString();
                    if (prestart == start && preend == enddate &&
                        x.dcd.projectid == element.dcd.projectid &&
                        x.dcd.projectpartid == element.dcd.projectpartid &&
                        x.dcd.projectsectionid == element.dcd.projectsectionid &&
                        x.dcd.searchuserid == element.dcd.searchuserid) {
                        return x;
                    }
                };
                var item = arrchart.find(predicaterow);
                if (item != undefined) {
                    this.detailform.details[i].dcd.amoscount = item.amoscount;
                    this.detailform.details[i].dcd.projectcount = item.projectcount;
                    this.detailform.details[i].dcd.bqcodecount = item.bqcodecount;
                    this.detailform.details[i].dcd.mctcount = item.mctcount;
                    let amos = { index: i, name: 'AMOS', count: item.amoscount };
                    let project = { index: i, name: 'Project', count: item.projectcount };
                    let bqcode = { index: i, name: 'BQ_codes', count: item.bqcodecount };
                    let mct = { index: i, name: 'MC_T', count: item.mctcount };
                    this.chartbars.push(amos, project, bqcode, mct);
                    this.chartsections.push(i);
                }
                else {
                    let amos = { index: i, name: 'AMOS', count: 0 };
                    let project = { index: i, name: 'Project', count: 0 };
                    let bqcode = { index: i, name: 'BQ_codes', count: 0 };
                    let mct = { index: i, name: 'MC_T', count: 0 };
                    this.chartbars.push(amos, project, bqcode, mct);
                    this.chartsections.push(i);
                }
            }
        },

        createchart() {
            const element = document.getElementById('singlechart');
            const chart = echarts.init(element);
            const colors = ['#d9d2e9', '#d9ead3', '#f4cccc', '#cfe2f3'];
            const chartlabel = ['AMOS', 'Project', 'BQ_codes', 'MC_T'];
            const seriesData = chartlabel.map((labelname, index) => ({
                name: labelname,
                type: 'bar',
                data: this.chartsections.map(cs => {
                    const dataPoint = this.chartbars.find(c => c.index === cs && c.name === labelname);
                    return dataPoint && dataPoint.count > 0 ? dataPoint.count : null;
                }),
                itemStyle: {
                    color: colors[index]
                },
                label: {
                    show: true,
                    position: 'top',
                    formatter: '{c}',
                    fontSize: 10,
                    color: '#000000',
                    offset: [0, 5]
                },

            }));
            const option = {
                tooltip: {
                    formatter: function (tool) {
                        return `${tool.seriesName} : ${tool.value}`;
                    },
                },
                xAxis: {
                    type: 'category',
                    data: this.chartsections,
                    show: false
                },
                yAxis: {
                    show: false
                },
                grid: {
                    left: 0,
                    right: 0,
                    top: 20,
                    bottom: 0
                },
                barGap: 0,
                barCategoryGap: '10',
                series: seriesData
            };
            chart.setOption(option);
        },

        disposechart() {
            const element = document.getElementById('singlechart');
            const chart = echarts.init(element);
            chart.dispose();
        }
    },

    destroyed() {
        window.removeEventListener('resize', this.getheight);
    },

    mounted: function () {
        window.addEventListener('resize', this.getheight);
    },

    beforeRouteLeave(to, from, next) {
        var answer = true;
        if (this.detailform.changed) {
            answer = window.confirm('Do you really want to leave? you have unsaved changes!');
        }
        if (answer == true) {
            next()
            window.onbeforeunload = null;
        } else {
            next(false)
        }
    },

    created() {
        this.$bus.$on("setuser", (user) => {
            this.user = user;
        });
        this.$training.getallprojects().then((data) => {
            data.unshift(this.allproject);
            this.projectoptions = data;
            this.projects = data;
        });
        this.getnrmsections();
        let gridrow = this.$training.getusergridrow(this.$route);
        this.view.pagesize = gridrow.ugv.rowperpage;
        this.usergridrow = gridrow;
        this.refresh();
    }
}
</script>