<template>
    <div ref="whiteBackground" id="whiteBackground" draggable="true" class="draggable" v-if="isvisible">
        <div id="dlgbox">
            <div class="dlg-body">
                <slot></slot>
            </div>
        </div>
    </div>
</template>
  
<script>

export default {
    name: "CustomPageDraggable",
    props: {
        isvisible: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {

        };
    },
    methods: {
        page_drag_start(event) {
            var style = window.getComputedStyle(event.target, null);
            event.dataTransfer.setData(
                "text/plain",
                "page_drag,"+(
                parseInt(style.getPropertyValue("left"), 10) -
                event.clientX )+
                "," +
                (parseInt(style.getPropertyValue("top"), 10) - event.clientY)
            );
        },
        page_drag_over(event) {
            event.preventDefault();
            return false;
        },
        page_drop(event) {
            var dm = this.$refs.whiteBackground;
            var offset = event.dataTransfer.getData("text/plain").split(",");
            if(offset[0] == "page_drag"){
                  //var dm = this.$refs.whiteBackground;
                  this.$nextTick(()=>{
                      dm.style.left =
                          event.clientX + parseInt(offset[1], 10) + "px";
                      dm.style.top =
                          event.clientY + parseInt(offset[2], 10) + "px";
                      event.preventDefault();
                  });
            }
            return false;
        },
        makeDraggable() {
             var dm = this.$refs.whiteBackground;
            if (dm) {
                dm.addEventListener("dragstart", this.page_drag_start, false);
                document.body.addEventListener("dragover", this.page_drag_over, false);
                document.body.addEventListener("drop", this.page_drop, false);
            }
        },
    },
    watch: {
        isvisible() {
        this.$nextTick(() => {
            this.makeDraggable();
        });
      }
    },
    created:function(){
        // this.$nextTick(this.makeDraggable);
    }
};
</script>
  <style scoped>
  #whiteBackground {
    padding: 4px 20px 20px 20px;
    resize: both;
    overflow: auto;
    width: 50%;
    height: 75%;
    top: 60%;
    left: 50%;
    position: fixed;
    transform: translate(-50%, -50%);
    opacity: 1;
    z-index: 999;
    box-shadow: rgba(0, 0, 0, 0.56) 0px 22px 70px 4px;
    align-items: center;
     /* border: 2px solid black; */
     background-color: white;
}

#dlgbox {
  /* position: fixed; */
  /* width: 90%;
  height: 50%; */
  margin: auto;
  /* background-color: lightgray; */
  z-index: 1;
}
</style>