export const globalmixin = {
    data() {
        return {
            loadingobj: null,
            loadingvisible: false
        };
    },
    computed:{
        isspellcheck(){
            return true
        },
        editpermission(){
            if(this.$store.state.role.rp.permission == 2){
                return true;
            }else{
               return false;
            }
        },
    },
    // created() {
    //     // Intercepting method calls
    //     const originalMethods = this.$options.methods;
    
    //     Object.keys(originalMethods).forEach(methodName => {
    //         const originalMethod = originalMethods[methodName];
    //         if(typeof(originalMethod) == "function"){
    //           console.log(methodName);
    //          this.$options.methods[methodName] = function (...args) {
    //          // this.myFunction(); // Call your desired function before the original method
    //          originalMethod.apply(this, args); // Call the original method
    //        }
    //       };
    //     });
    //   },
    methods: {
        addrfitoproject(rfilibrarys){
            return this.$http.post('RFI/addrfitoproject', rfilibrarys)
            .then(response => {
                this.$message({
                    showClose: true,
                    message: response.data, type: "success"
                });
                return true;
            })
            .catch(err => {
                this.$bus.$emit('error', err);
            });
        },
        getrifsections(){
            return this.$http
            .get("RFI/getrifsection")
            .then((response) => {
                this.$store.state.rfisections = response.data;
                return response.data; 
            })
            .catch((err) => {
            this.$bus.$emit("error", err);
            });
        },
        canmatchcellid(callback,actionname,section = "",testdata,testcallback){
            var isfind = this.$store.state.directions.find(x => x.cellaction && x.cellaction.actionname == actionname);
            if(isfind){
                this.$training.getdirectionbycellname(isfind.cellaction.actionname,callback,section,testdata,testcallback);
            } else{
                callback();
            }
        },
        getchildren(data,action,addarray){
            if(!data.children || data.children.length == 0)
            {
                return data;
            }else{

                data.children.forEach((c,index) => {
                    addarray.push(c.activity); 
                    this.getchildren(c,action,addarray);
                });
            }
        },
        getURsRemote(data){
            this.$store.state.URs = [];
            if (data !== '') {
                if(data != ""){
                    var arr2 = [];
                    if(!isNaN(data)){
                        if(data.length <= 4){
                                 while(arr2.length < 5){
                                     var r = Math.floor(Math.random()*90000) + 10000;
                                      var rrr = r.toString();
                                    //   var dd = this.$store.state.URd.find(x => x.ur == r);
                                      var tt = arr2.find(x => x.ur == rrr);
                                     if(!tt && r.includes(data.substring(0, 5))){
                                         arr2.push({id:0,ur:rrr});
                                      }
                                   }
                                   this.$store.state.URs.push(...arr2);
                         }else{
                             data = data.substring(0, 5);
                            // var dd = this.$store.state.URd.find(x => x.ur == data);
                            if( data != "00000"){
                              this.$store.state.URs= [{id:0,ur:data.substring(0, 5)}];
                            }
                         }

                    }
                }
            }else {
                 var arr22 = [];
                 while(arr22.length < 5){
                     var urs = Math.floor(Math.random()*90000) + 10000;
                     var rr = urs.toString();
                    // var dd = this.$store.state.URd.find(x => x.ur == r);
                    var tts = arr22.find(x => x.ur == rr);
                     if(!tts){
                        arr22.push({id:0,ur:rr});
                      }
                    }
                 this.$store.state.URs.push(...arr22);
            }
        },
        ehsearchproject: function (query, done) {
            if (query == undefined) {
                query = "";
            }
            this.$http.post("hoursestimate/search?q=" + query, {})
                .then(response => {
                    var ehtemplates = JSON.parse(response.data);
                    done(ehtemplates);
                })
                .catch(err => {
                    this.$bus.$emit("error", err);
                });
        },

        searchProject: function(query, done,training) {
            if(query == undefined){
                query = "";
            }
            if(training == undefined){
                training = false
            }
            this.$http
                .post("project/search?q=" + query+"&trainingproject="+training, {})
                .then(response => {
                    // var templates =  response.data//JSON.parse(response.data);
                    done(response.data);
                })
                .catch(err => {
                    this.$bus.$emit("error", err);
                });
        },
        searchuser(query, done) {
            if (query == undefined) {
                query = "";
            }
            this.$http.post("account/searchuser?q=" + query, {})
                .then(response => {
                    done(response.data);
                })
                .catch(err => {
                    this.$bus.$emit("error", err);
                });
        },
        showloading: function () {
            this.$store.state.isLoading = true;
            /*
            this.loadingvisible = true;
            setTimeout(() => {
                if (this.loadingvisible == true) {
                    this.loadingobj = this.$loading({
                        lock: true,
                        text: 'Loading',
                        spinner: 'el-icon-loading',
                        background: 'rgba(0, 0, 0, 0.7)'
                    });
                }
            }, 300);
            */
        },

        hideloading: function () {
            this.$store.state.isLoading = false;
            /*
            this.loadingvisible = false;
            if (this.loadingobj) {
                this.loadingobj.close();
            }
            */
        },
        binarySearch(key,value,list,cansort) {
            if(cansort){
                list.sort( (a, b )=>{
                    if ( a[key] < b[key] ){
                        return -1;
                      }
                      if ( a[key] > b[key] ){
                        return 1;
                      }
                      return 0;
                  });
            }
        
          let low = 0;    //left endpoint
          let high = list.length - 1;   //right endpoint
          let position = -1;
          let found = false;
          let mid;
        
          while (found === false && low <= high) {
              mid = Math.floor((low + high)/2);
              if (list[mid][key] == value) {
                  found = true;
                  position = mid;
              } else if (list[mid][key] > value) {  //if in lower half
                  high = mid - 1;
              } else {  //in in upper half
                  low = mid + 1;
              }
          }
          return position;
        },
        // async loadUsers () {
        //     const response = await this.$http.post("account/getuserdetails")
        //             .then(response => {

        //                 return response.data;
        //             })
        //             .catch(error => {
        //                 if (!this.validuser) {
        //                     if (this.$route.path != "/login") {
        //                         this.$router.push('/login')
        //                     }
        //                 }
        //             })
        //   },
        // getuserdata: function () {
        //         this.$http.post("account/getuserdetails")
        //             .then(response => {
        //                 return response.data;
        //             })
        //             .catch(error => {
        //                 if (!this.validuser) {
        //                     if (this.$route.path != "/login") {
        //                         this.$router.push('/login')
        //                     }
        //                 }
        //             })
        // },
       
        post(url,parm){
            return this.$http.post(url,parm)
            .then(response => {

              return response
            })
            .catch(err => {
                this.$bus.$emit('error', err);
            });
        },
        get(url){
            return this.$http.get(url)
            .then(response => {

              return response
            })
            .catch(err => {
                this.$bus.$emit('error', err);
            });
        },
         getstatus(){
            return this.$http.post('Sectional/getstatus')
            .then(response => {
                return response.data;
            })
            .catch(err => {
                this.$bus.$emit('error', err);
            });
        },
        getsections: function () {

           return this.$http.post('project/GetSectionsProjectCreate')
                .then(response => {

                    this.$store.state.sections = response.data;
                    this.$bus.$emit('setsection', response.data);
                    return response.data;
                })
                .catch(err => {
                    this.$bus.$emit('error', err);
                    this.$bus.$emit('logout', null);
                });
        },

        getPages: function () {
            this.$http.get('account/getpages')
                 .then(response => {
                     this.$store.state.pages = response.data;
                 })
                 .catch(err => {
                     this.$bus.$emit('error', err);
                 });
         },

        getUserGuideReminder: function(pageid) {
            return this.$http
                .post("sectional/getUserGuideReminder?pageid=" + pageid, {})
                .then(response => {
                    return response.data;
                })
                .catch(err => {
                    this.$bus.$emit("error", err);
                });
        },

        commaseparatednumber: function(value, precision){
            var vl = value; var afterPoint = '';
            if (precision) {
                value = value.toFixed(precision);
                afterPoint = value.substring(value.indexOf('.'),value.length);
                value = value.split(".")[0];
            }
            if (vl) {
                var n = Intl.NumberFormat('en-GB');
                var num = n.format(value) + afterPoint;
                return num;
            }
        },
         GetSerialNumbers:function(value, langth){
            var arr = [];
            if(typeof(value)=="string"){
                value = parseInt(value);
            }
            const zeroPad = (num, places) => {
              const numZeroes = places - num.toString().length + 1;
              if (numZeroes > 0) {
                return Array(+numZeroes).join("0") + num;
              }
              return num
            }
            while (arr.length < langth) {
              var obj = { id: 0, ur: zeroPad(value += 1, 5).toString()}
              arr.push(obj);
            }
            return arr;
          },

        //  ↓ To display numbers with leading zeros. ↓
        addLeadingZeros(num, totalLength) {
            if (num < 0) {
                const withoutMinus = String(num).slice(1);
                return '-' + withoutMinus.padStart(totalLength, '0');
            }
            return num ? String(num).padStart(totalLength, '0') : num;
        },

        measureTextWidth: function (str, fontSize = 12) {
            const widths = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0.2796875, 0.2765625, 0.3546875, 0.5546875, 0.5546875, 0.8890625, 0.665625, 0.190625, 0.3328125, 0.3328125, 0.3890625, 0.5828125, 0.2765625, 0.3328125, 0.2765625, 0.3015625, 0.5546875, 0.5546875, 0.5546875, 0.5546875, 0.5546875, 0.5546875, 0.5546875, 0.5546875, 0.5546875, 0.5546875, 0.2765625, 0.2765625, 0.584375, 0.5828125, 0.584375, 0.5546875, 1.0140625, 0.665625, 0.665625, 0.721875, 0.721875, 0.665625, 0.609375, 0.7765625, 0.721875, 0.2765625, 0.5, 0.665625, 0.5546875, 0.8328125, 0.721875, 0.7765625, 0.665625, 0.7765625, 0.721875, 0.665625, 0.609375, 0.721875, 0.665625, 0.94375, 0.665625, 0.665625, 0.609375, 0.2765625, 0.3546875, 0.2765625, 0.4765625, 0.5546875, 0.3328125, 0.5546875, 0.5546875, 0.5, 0.5546875, 0.5546875, 0.2765625, 0.5546875, 0.5546875, 0.221875, 0.240625, 0.5, 0.221875, 0.8328125, 0.5546875, 0.5546875, 0.5546875, 0.5546875, 0.3328125, 0.5, 0.2765625, 0.5546875, 0.5, 0.721875, 0.5, 0.5, 0.5, 0.3546875, 0.259375, 0.353125, 0.5890625]
            const avg = 0.5279276315789471
            return str
                .split('')
                .map(c => c.charCodeAt(0) < widths.length ? widths[c.charCodeAt(0)] : avg)
                .reduce((cur, acc) => acc + cur) * fontSize
        }
    }
}
