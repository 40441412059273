// DraggableDirective.js
export default {
  bind(el, binding, vnode) {
    vnode.context.$nextTick(() => {
      const dialogHeader = el.querySelector(".el-dialog__header");

      if (!dialogHeader) {
        return;
      }

      dialogHeader.style.cursor = "move";

      const dragMouseDown = (e) => {
        e = e || window.event;
        e.preventDefault();

        const startX = e.clientX;
        const startY = e.clientY;

        let dialog = dialogHeader.closest(".el-dialog");

        if (!dialog) {
          return;
        }

        const dialogX = dialog.offsetLeft - 150;
        const dialogY = dialog.offsetTop - 120;

        const mouseMoveHandler = (e) => {
          e = e || window.event;
          e.preventDefault();

          const deltaX = e.clientX - startX;
          const deltaY = e.clientY - startY;

          dialog.style.left = dialogX + deltaX + "px";
          dialog.style.top = dialogY + deltaY + "px";
        };

        const mouseUpHandler = () => {
          document.removeEventListener("mousemove", mouseMoveHandler);
          document.removeEventListener("mouseup", mouseUpHandler);
        };

        document.addEventListener("mousemove", mouseMoveHandler);
        document.addEventListener("mouseup", mouseUpHandler);
      };

      dialogHeader.addEventListener("mousedown", dragMouseDown);
    });
  },
};
