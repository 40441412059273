<template>
    <div>
      <div class="PHlistwidth">
        <!-- header -->
        <div class="fixdheader">
            <!--<el-row>
                <el-col :span="12" class="headerstyle"
                    ><span>Project Estimate Hours</span></el-col
                ></el-row>-->
		<div>
		    <span class="headerstyle">Project Estimate Hours</span>
				<div class="floatright margintb5">
						<el-button type="primary"
									@click="$router.push('/projectdescriptionform')"
                  id="setup_projecthourslist_project_description"
									plain>
							Project Description
						</el-button>
						<el-button type="success"
                  id="setup_projecthourslist_project_export_hours"
									@click="$router.push('/exportreport')">
							Export Hours
						</el-button>
						<el-button icon="el-icon-back"
									type="info"
                  id="setup_projecthourslist_project_back"
									@click="$router.go(-1)">Back</el-button>
				</div>
		</div>
        <el-row :gutter="2">
              <el-col :span="12">
                    <el-input class="padding2px fixwidth" :clearable="true"
                      v-model="view.filter[0].value" placeholder="Project Name"
                           size="mini" @change="refresh" id="setup_projecthourslist_project_name">
                      </el-input>
              </el-col >
                  <el-col :span="12">
                     <el-button class="right"  type="primary"
                       :disabled="!editpermission" @click="createnew"
                       id="setup_projecthourslist_add_project"
                       icon="el-icon-circle-plus" >
                       </el-button >
                </el-col>
      </el-row >
      <el-row>
          <el-pagination style="text-align:center"
            layout="prev, pager, next"
            :total="view.count"
            @current-change="refresh"
            :current-page.sync="view.pageno"
            :page-size="20"
          >
          </el-pagination>
      </el-row>
      <hr class="hrstyle" />
      </div>
        <el-row>
             <el-table
                      :data="tabledata"
                      :default-sort="{ prop: 'projectname', order: 'descending' }"
                      size="mini"
                      @sort-change="sortChange"
                      type="expand"
                      align="left"
                    >
                      <el-table-column
                        prop="ep.projectname"
                        label="Project Name"
                        sortable="custom"
                        width="270"
                      >
                      <template slot-scope="p">
                        {{p.row.ehp.projectname}}
                      </template>
                      </el-table-column>
                       <el-table-column
                        prop="cbu.loginname"
                        label="Created By"
                        sortable="custom"
                        width="110"
                        min-width="85"
                        max-width="102"
                      >
                       <template slot-scope="p">
                        {{p.row.createdbyuser.loginname}}
                      </template>
                      </el-table-column>
                       <el-table-column
                       prop="ep.createdon"
                        label="Created On"
                        sortable="custom"
                        width="125"
                        min-width="140"
                        max-width="170"
                      >
                       <template slot-scope="p">
                        {{dateformatter(p.row.ehp)}}
                      </template>
                      </el-table-column>
                       <el-table-column
                        prop="ep.library"
                        label="Library"
                        width="100"
                        min-width="68"
                        max-width="101"
                        sortable="custom"

                      >
                       <template slot-scope="p">
                        {{p.row.ehp.library}}
                      </template>
                      </el-table-column >
                       <el-table-column
                          label="Edit"
                          width="56"
                           min-width="56"
                           max-width="56"
                         >
                          <template slot-scope="scope" >
                            <el-button
                              title="Edit"
                              size="mini"
                              type="primary"
                              :id="'setup_projecthourslist_edit_data_'+scope.$index"
                              :icon="editpermission == true ? 'el-icon-edit' : 'el-icon-view'"
                              @click="handleEdit(scope.$index, scope.row)"
                              >  </el-button
                            >
                            <!-- {{editpermission == true ? 'Edit' : 'View' }} -->
                          </template>
                      </el-table-column>
             </el-table>
        </el-row>
    </div>
    </div>
</template>
<script>

export default{

 data() {
     return {
         tabledata:[],
         view: {
             pagesize:20,
             filter: [
                 { table: "ep", column: "projectname", value: "" },
             ]
          },
     }
 },
   computed: {
    dateformatter(){
      return function(value){
         if (value.createdon) {
           var d = new Date(value.createdon);
           return d.toDateString();
         }else{
           return "";
         }
      }
    },
 },
 methods: {
     createnew(){
         this.$router.push({ path: '/projecthours' });
     },
     handleEdit(index, data){
         this.$router.push({ path: '/projecthours', query: { id: data.ehp.id } });
     },

    sortChange: function (sort) {
      this.view.sortcol = sort.prop;
      this.view.sortorder = sort.order;
      this.refresh();
    },
     refresh(){
           this.post("HoursEstimate/getprojecthourslist", this.view).then(
                (response) => {
                   this.tabledata = response.data.data;
                   this.view = response.data.view;
                });
     },
 },
  created: function () {
    this.refresh();
  }
}
</script>
