<template>
    <div>
        <div class="fixdheader">
                <span class="headerstyle">Checklist-Summary</span>
                <selectpart :Similar="Similar" @projectpartchange="projectpartchange">
                </selectpart>
                <div class="spaceBetween" />
                <el-table v-if="isshow" :data="[{}]" class="tableBox-cell C_S_header_width">
                    <el-table-column label="NRM Element" width="436">
                        <template slot="header" slot-scope="scope">
                            <div class="customheader">NRM Element</div>
                            </template>
                    </el-table-column>
                    <el-table-column  width="4"></el-table-column>
                    <el-table-column label="Signed Off By" width="210">
                        <template slot="header" slot-scope="scope">
                            <div class="customheader">Signed Off By</div>
                            </template>
                    </el-table-column>
                </el-table>
                <div v-if="isshow" class="spaceBetween" />
                <el-table v-if="isshow" :data="[{}]" class="tableBox-cell C_S_header_width">
                    <el-table-column label="NRM Element" width="326"></el-table-column>
                    <el-table-column label="Last Bill Edit" width="110"></el-table-column>
                    <el-table-column  width="4"></el-table-column>
                    <el-table-column label="Signed Off By" width="100"></el-table-column>
                    <el-table-column label="Signed Off Time" width="110"></el-table-column>
                </el-table>
        </div>
        <div>
            <el-table v-if="isshow" :data="summarys" :border="true"  class="tableBox-cell hide_table_header C_S_header_width">
                    <el-table-column prop="ps.sectiondisplay"  width="325">
                        <template slot-scope="scope">
                           <div :class="scope.row.islinethrough == false ? 'islinethrough': ''">{{scope.row.ps.sectiondisplay}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="cs.billon" width="110">
                        <template slot-scope="scope">
                            {{getdateformat(scope.row.cs,"billon")}}
                        </template>
                    </el-table-column>
                    <el-table-column  width="4"></el-table-column>
                    <el-table-column prop="cu.loginname" width="100"></el-table-column>
                    <el-table-column prop="css.signoffon"  width="110">
                        <template slot-scope="scope">
                          <div :class="getcolor(scope.row)">{{getdateformat(scope.row.css,"signoffon")}}</div>
                        </template>
                    </el-table-column>
                </el-table>
        </div>
    </div>
</template>
<script>
    import moment from "moment";
    import selectpart from "./selectpart.vue";
    export default{
        components: {
            selectpart: selectpart
        },
        data(){
            return{
                isshow:false,
                summarys:[],
                project:{},
                Similar:{
                    projectPlaceholder: '',
                    partPlaceholder: '',
                    lastsavedshow: true,
                }
            }
        },
        computed:{
            getcolor(){
                return function(data){
                    if(data.css.signoffon != null && data.cs.billon != null && this.getdateformat(data.css,"signoffon") < this.getdateformat(data.cs,"billon")){
                        return "colorred";
                    }else{
                        return "";
                    }
                }
            }
        },
        methods:{
            getdateformat(data,name){
                if(data[name] != null){
                   var dt = new Date(data[name]);
                   return moment(dt).format("DD-MMM-YYYY hh:mm");
                }
            },
            refresh(){
                this.post("ElementList/getchecklistsummary",this.project.projectpart)
                .then((data)=>{
                    this.summarys = data.data.clsv;
                    this.summarys.forEach(s =>{
                        s.islinethrough = false;
                        data.data.elt.forEach(e =>{
                            if(s.ps.section == e.nrmelementid)
                            {
                                s.islinethrough = true;
                            }
                        })
                    })
                    this.isshow = true;
                });

            },
            projectpartchange: function (Projectobj) {
                this.project.id = Projectobj.project.id;
                this.project.projectname = Projectobj.project.projectname
                this.project.projectpart = Projectobj.part;
                this.summarys = [];
                if(this.project != null && this.project.projectpart != null && this.project.projectpart.projectid > 0 && this.project.projectpart.name != null)
                {
                    this.refresh();
                }else{
                    this.isshow = false;
                }
            },
        }
    }
</script>
<style>
    .customheader{
        background-color: #696868;
        color: white;
        padding-left: 5px;
        font-weight: bold;
    }
    .colorred{
        color: red;
    }
    .C_S_header_width{
        width: 650px !important;
    }
    .islinethrough{
      text-decoration: line-through;
   }
</style>