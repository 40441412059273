<template>
    <div v-show="showdata" >
         <div class="fixdheader">
         <el-row>
            <el-col :span="12" class="headerstyle"> <span>Access Permissions</span> </el-col>
            <el-col :span="12" class="textalignright">
                <el-button :disabled="!editpermission" @click="save()" type="success" icon="el-icon-circle-check">Save</el-button>
                <el-button :disabled="!editpermission" icon="el-icon-circle-plus" class="marginl5" type="primary" @click="addRow"></el-button>
                <el-button icon="el-icon-back" @click="$router.go(-1)">Back</el-button>
            </el-col>
        </el-row>
        <hr class="hrstyle" />
            </div>
        <el-table :data="detailform"
                  class="fullwidth" size="mini">

            <el-table-column prop="fullname" label="Full name" width="140">
                <template slot-scope="scope">
                    <el-input placeholder="Full name" :disabled="!editpermission" v-model="scope.row.fullname" @change="changed(scope.row)"></el-input>
                </template>
            </el-table-column>

            <el-table-column prop="loginname" label="Login name" width="115">
                <template slot-scope="scope">
                    <el-input placeholder="Login name" :disabled="!editpermission" v-model="scope.row.loginname" @change="changed(scope.row)"></el-input>
                </template>
            </el-table-column>

            <el-table-column prop="password" label="Password" width="100" >
                <template slot-scope="scope">
                    <el-input placeholder="Password" :disabled="!editpermission" v-model="scope.row.password" @change="changed(scope.row)"></el-input>
                </template>
            </el-table-column>

            <el-table-column prop="role" label="Type" width="60">
                <template slot-scope="scope">
                    <el-select  placeholder="Type" v-model="scope.row.role" @change="changed(scope.row)" >
                        <el-option label="A" value="A" :disabled="!editpermission"></el-option>
                        <el-option label="B" value="B" :disabled="!editpermission"></el-option>
                        <el-option label="C" value="C" :disabled="!editpermission"></el-option>
                    </el-select>
                </template>
            </el-table-column>

            <el-table-column prop="active" label="Active" align="center" width="60">
                <template slot-scope="scope">
                    <el-checkbox :disabled="!editpermission" v-model="scope.row.active" @change="changed(scope.row)"></el-checkbox>
                </template>
            </el-table-column>
            <el-table-column prop="reportstoid" label="Report's To" align="center" width="100">
                <template slot-scope="scope">
                    <el-select clearable placeholder="Report's To" :value="scope.row.reportstoid"  @change="reporttochange(scope.row,$event)">
                            <el-option v-for="item in getuserlist" :disabled="!editpermission"
                                    :key="item.id"
                                    :label="item.loginname"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                </template>
            </el-table-column>
            <el-table-column prop="roleid" label="Role" align="center" width="100">
                <template slot-scope="scope">
                    <el-select clearable placeholder="Role"  :value="scope.row.roleid" @change="rolechange(scope.row,$event)" >
                            <el-option v-for="item in roles" :disabled="!editpermission"
                                    :key="item.id"
                                    :label="item.name"
                                    :value="item.id">
                            </el-option>
                        </el-select>
                </template>
            </el-table-column>
            <el-table-column prop="role" min-width="400">
                <template slot="header" slot-scope="{}">
                      <div style="min-width: 300px; display: inline-block">
                        Section
                    </div>
                </template>

                <template slot-scope="scope">
                    <div >
                        <el-select :disabled="!editpermission"  multiple value-key="section" v-model="scope.row.sections" @change="changed(scope.row)" placeholder="Sections" class="fullwidth">
                            <el-option v-for="item in sections" :disabled="!editpermission"
                                    :key="item.section"
                                    :label="item.section"
                                    :value="{ section: item.section }">
                            </el-option>
                        </el-select>
                    </div>
                </template>
            </el-table-column>



            <!--<el-table-column label="Action"
                     fixed="right" width="70">
                <template slot-scope="scope">
                    <el-button size="mini" type="danger" icon="el-icon-delete"
                            @click="delRow(scope.$index, scope.row)"></el-button>
                </template>
            </el-table-column>-->

        </el-table>
        <!-- <el-row>
            <el-col class="text-right">
                <el-button :disabled="!editpermission" icon="el-icon-circle-plus" type="primary" @click="addRow">Add New</el-button>
            </el-col>
            <el-col abel="">
                <el-button :disabled="!editpermission" @click="save()" type="success" icon="el-icon-circle-check">Save</el-button>
            </el-col>
        </el-row> -->
    </div>
</template>
<script>

    export default {
        data() {
            return {
                roles:[],
                detailform: [],
                sections: this.$store.state.sections.map(x => { return { section: x.section }; }),
                options: [{value: 'UR Editing'}],

                loading: false,
                showsection:true,
                showrole:true,
                showdata: false
            }
        },
        computed:{
            getuserlist(){
                return this.detailform.filter((x) =>{
                    if(x.role == "A" || x.role == "B"){
                        return x;
                    }
                })
            },
            // getreportname(){
            //     return function(data){
            //         var user = this.getuserlist.find(x => x.id == data.reportstoid);
            //         if(user){
            //             return user.loginname;
            //         }
            //     }
            // },
            // getrolename(){
            //     return function(data){
            //         var role = this.roles.find(x => x.id == data.roleid);
            //         if(role){
            //             return role.name;
            //         }
            //     }
            // }
        },
        methods: {
            reporttochange(row,event){
                if(event == null){
                  row.reportstoid = null;
                }
                  row.reportstoid = event;
                  this.changed(row);
            },
            rolechange(row,event){
                if(event == null || event == ''){
                  row.roleid = null;
                }else{
                    row.roleid = event;
                }
                  this.changed(row);
            },
            addRow: function () {
                var itm = {role:'',
                    active: true,
                           superadmin: false,
                           fullname:'',
                           loginname:'',
                           password:'',
                           reportstoid:null,
                           roleid:null,
                           sections:[],
                           };
                this.changed(itm);
                this.detailform.unshift(itm);
            },

            delRow: function (index, row) {
                this.$confirm('This will permanently delete this row. Continue?', 'Warning', {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                }).then(() => {
                    this.$http.post('comparesearch/delete', row)
                        .then(response => {
                            this.$message({
                                showClose: true,
                                message: response.data, type: "success"
                            });
                            //this.detailform.splice(index, 1);
                            this.refresh();
                        })
                        .catch(err => {
                            this.$bus.$emit('error', err);
                        });
                }).catch(() => {
                    this.$message({
                        showClose: true,
                        type: 'info',
                        message: 'Delete canceled'
                    });
                });
            },

            changed: function (row) {
                row.edited = true;
            },

            save: function (item, event) {
                //save only edited on
                var warnings = [];

                var archanged = 
                    this.detailform.filter((x, index) => 
                    {
                        if (!x.loginname || !x.password || x.loginname.trim() == "" || x.password.trim() == "") {
                            warnings.push(`Loginame and password are required for User in Row - ${index + 1}.`);
                            return false;
                        }

                        if (x.edited == true) {
                            x.edited = false;
                            return x;
                        }
                    });

                if (warnings.length > 0) {
                    var style = "<style> .el-message-box </style>";
                    this.$alert(warnings.join("<br />") + style, "Missing Login Information", {
                        dangerouslyUseHTMLString: true,
                    });
                    return;
                }
                

                if (archanged.length > 0) {
                    this.$http.post('account/saveusers', archanged)
                        .then(response => {
                            this.$message({
                                showClose: true,
                                message: response.data, type: "success"
                            });
                            this.refresh();
                        })
                        .catch(err => {
                            this.$bus.$emit('error', err);
                        });
                }
                else {
                    this.$message({
                        showClose: true,
                        message: "Nothing to save", type: "info"
                    });
                }
            },
             getroles:function(){
              this.$http.get('account/getrole')
               .then(response => {
                    this.roles = response.data;

               }).catch(err => {
                   this.$bus.$emit('error', err);
               });
            },
            refresh: function () {
                 this.getroles();
                // this.loading = true;
                this.$store.state.isLoading = true;

                this.$http.post('account/getusers')
                    .then(response => {
                        var json = JSON.parse(response.data);

                        // json.forEach(user => {
                        //     user.sections.sort((a, b) =>
                        //         { 
                        //             const sectionNameA = a.section.toLowerCase();
                        //             const sectionNameB = b.section.toLowerCase();
                                    
                        //             if (sectionNameA < sectionNameB) {
                        //                 return -1;
                        //             }
                        //             if (sectionNameA > sectionNameB) {
                        //                 return 1;
                        //             }
                        //             return 0;
                        //         });
                        // });

                        this.detailform = json;
                        // this.loading = false;
                        this.$store.state.isLoading = false;
                    }).catch(err => {
                        // this.loading = false;
                        this.$store.state.isLoading = false;
                        this.$bus.$emit('error', err);
                    });
            }
        },

        created: function () {
            this.refresh();
                this.showdata = true;
            this.$bus.$on('setsection', (sections) => {
                this.sections = sections
            })
            // if (this.$store.state.user.role == undefined) {
            //      this.$bus.$on('setuser', (user) => {
            //         if (user.role == "A") {
            //             this.refresh();
            //             this.showdata = true;
            //         }
            //         else{
            //             this.$router.push("/");
            //             this.showdata = false;
            //         }
            //     });
            // }
            // else if(this.$store.state.user.role == 'A'){
            //     this.refresh();
            //     this.showdata = true;
            // }
            // else{
            //     this.$router.push('/')
            // }
        },
    }
</script>
<style>
</style>
