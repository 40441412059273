<template>
    <div>
        <div v-if="!detail" class="elementallist">
            <div class="fixdheader">
                <span class="headerstyle">Checklists Template Library</span>
                <hr class="hrstyle" />
            </div>

            <div class="botttomborder">
                <span class="widthlabel130 tablefont">Level of Detail</span>
                <!-- <span class="widthlabel88 padleft5px tablefont">Library</span> -->
                <span class="widthlabel110 tablefont marginleft4px">Add Template</span>
                <span class="widthlabel360 padleft5px tablefont marginleft4px">Template Selector</span>
                <!-- <span class="widthlabel130 tablefont">Date Last Edited</span>
                <span class="widthlabel88 tablefont">Author</span> -->
                <span class="widthlabel45 padleft5px tablefont marginleft4px"></span>
                <span class="widthlabel45 tablefont">Actions</span>
            </div>

            <div class="botttomborder">
                <span class="widthlabel130">1. Structure Only</span>

                <!-- <span class="widthlabel88">
                    <el-select clearable :style="{ width: '84px' }" placeholder="Library">
                        <el-option v-for="item in libraries" :disabled="!editpermission"
                                   :key="item"
                                   :label="item"
                                   :value="item">
                        </el-option>
                    </el-select>
                </span> -->

                <span class="widthlabel110 marginleft4px">
                    <el-button type="primary"
                               @click="createNewBase01()"
                               :disabled="!editpermission" class="padding7px18px">Create New</el-button>
                </span>

                <span class="widthlabel360 marginleft4px">
                    <!-- <el-select clearable
                               v-model="grid.baseOne"
                               @clear="onClearBase01($event,true)"
                               @change="onSearchBaseTwo"
                               :style="{ width: '350px' }"
                               placeholder="Select 1 Structure Template">
                        <el-option v-for="item in baseones"
                                   :disabled="!editpermission"
                                   :key="item.id"
                                   :label="item.projectname"
                                   :value="item.id">
                        </el-option>
                    </el-select> -->
                    <el-autocomplete
                        class="inline-input fullwidth bold paddinglr16px"
                        v-model="grid.baseOnename"
                        :fetch-suggestions="(query,done)=>{querysearchelementlist('1',query,done)}"
                        placeholder="Select 1 Structure Template"
                        @select=" ($event)=>{handelselecttemplate(grid,$event,'baseOne')}"
                        clearable
                        @clear="($event)=>{ handelselecttemplate(grid,$event,'baseOne')}">
                        <template slot-scope="{ item }">
                            {{item.projectname}}
                            </template>
                    </el-autocomplete>
                </span>

                <!-- <span class="widthlabel130">
                    {{ grid.parentTemplateLastModified }}
                </span>

                <span class="widthlabel88">
                    {{ grid.parentTemplateAuther }}
                </span> -->

                <el-button-group class="verticalalignbottom">
                    <el-button type="primary" icon="el-icon-edit" @click="editBase01()" :disabled="!editpermission" class="marginright4px borderradius3px"></el-button>
                    <el-button type="primary" icon="glyphicon glyphicon-duplicate" @click="copybasetemplates('baseOne', 1)" :disabled="!editpermission" class="marginright4px paddingbottom5px borderradius3px"></el-button>
                    <el-button type="danger" icon="el-icon-delete" @click="deletetemplates('baseOne')" :disabled="!editpermission" class="borderradius3px"></el-button>
                </el-button-group>


                    <!-- <el-button type="primary" class="marginleft4px"
                               @click="editBase01()"
                               :disabled="!editpermission"
                               icon="el-icon-edit"></el-button>



                    <el-button type="primary" class="marginleft4px"
                               @click="copybasetemplates('baseOne', 1)"
                               :disabled="!editpermission"
                               icon="glyphicon glyphicon-duplicate"></el-button> -->

            </div>

            <div class="botttomborder">
                <span class="widthlabel130">2. Section / Content</span>

                <!-- <span class="widthlabel88">
                    <el-select clearable
                               v-model="grid.librarytwo"
                               placeholder="Library"
                               :style="{ width: '84px' }">
                        <el-option v-for="item in libraries"
                                   :disabled="!editpermission"
                                   :key="item"
                                   :label="item"
                                   :value="item">
                        </el-option>
                    </el-select>
                </span> -->

                <span class="widthlabel110 marginleft4px">
                    <el-button type="primary"
                               @click="createNewBase02()"
                               :disabled="!editpermission">From Level 1</el-button>
                </span>

                <span class="widthlabel360 marginleft4px">
                    <!-- <el-select clearable :disabled="!grid.baseOne"
                               v-model="grid.basetwo"
                               @clear="onClearBase02(true)"
                               @change="onSearchBaseThree"
                               :style="{ width: '350px' }"
                               placeholder="Select 2 Section / Content">
                        <el-option v-for="item in basetwos"
                                   :disabled="!editpermission"
                                   :key="item.id"
                                   :label="item.projectname"
                                   :value="item.id">
                        </el-option>
                    </el-select> -->
                    <el-autocomplete
                        class="inline-input fullwidth bold paddinglr16px"
                        v-model="grid.basetwoname"
                        :fetch-suggestions="(query,done)=>{querysearchelementlist('2',query,done)}"
                        placeholder="Select 2 Section / Content"
                        @select=" ($event)=>{handelselecttemplate(grid,$event,'basetwo')}"
                        clearable
                        @clear="($event)=>{ handelselecttemplate(grid,$event,'basetwo')}">
                        <template slot-scope="{ item }">
                            {{item.projectname}}
                            </template>
                    </el-autocomplete>
                </span>

                <!-- <span class="widthlabel130">
                    {{ grid.parentTwoTemplateLastModified }}
                </span>

                <span class="widthlabel88">
                    {{ grid.parentTwoTemplateAuther }}
                </span> -->

                <el-button-group class="verticalalignbottom">
                    <el-button type="primary" icon="el-icon-edit" @click="editBase02()" :disabled="!editpermission" class="marginright4px borderradius3px"></el-button>
                    <el-button type="primary" icon="glyphicon glyphicon-duplicate" @click="copybasetemplates('basetwo', 2)" :disabled="!editpermission" class="marginright4px paddingbottom5px borderradius3px"></el-button>
                    <el-button type="danger" icon="el-icon-delete" @click="deletetemplates('basetwo')" :disabled="!editpermission" class="borderradius3px"></el-button>
                </el-button-group>


                    <!-- <el-button type="primary" class="marginleft4px"
                               @click="editBase02()"
                               :disabled="!editpermission"
                               icon="el-icon-edit"></el-button>

                    <el-button type="primary" class="marginleft4px"
                               @click="copybasetemplates('basetwo', 2)"
                               :disabled="!editpermission"
                               icon="glyphicon glyphicon-duplicate"></el-button> -->

            </div>

            <div class="botttomborder">
                <span class="widthlabel130">3. For Project Use</span>


                    <!-- <el-select class="marginleft4px" clearable
                               v-model="grid.librarythree"
                               placeholder="Library"
                               :style="{ width: '84px' }">
                        <el-option v-for="item in libraries"
                                   :disabled="!editpermission"
                                   :key="item"
                                   :label="item"
                                   :value="item">
                        </el-option>
                    </el-select> -->


                <span class="widthlabel110 marginleft4px">
                    <el-button type="primary"
                               @click="createNewBase03()"
                               :disabled="!editpermission">From Level 2</el-button>
                </span>

                <span class="widthlabel360 marginleft4px">
                    <!-- <el-select clearable :disabled="!grid.basetwo"
                               v-model="grid.basethree"
                               @change="onChangeBaseThree"
                               @clear="onClearBase03"
                               :style="{ width: '350px' }"
                               placeholder="Select 3 Project Template">
                        <el-option v-for="item in basethrees"
                                   :disabled="!editpermission"
                                   :key="item.id"
                                   :label="item.projectname"
                                   :value="item.id">
                        </el-option>
                    </el-select> -->

                    <el-autocomplete
                        class="inline-input fullwidth bold paddinglr16px"
                        v-model="grid.basethreename"
                        :fetch-suggestions="(query,done)=>{querysearchelementlist('3',query,done)}"
                        placeholder="Select 3 Project Template"
                        @select=" ($event)=>{handelselecttemplate(grid,$event,'basethree')}"
                        clearable
                        @clear="($event)=>{ handelselecttemplate(grid,$event,'basethree')}">
                        <template slot-scope="{ item }">
                            {{item.projectname}}
                            </template>
                    </el-autocomplete>
                </span>

                <!-- <span class="widthlabel130">
                    {{ grid.parentThreeTemplateLastModified }}
                </span>

                <span class="widthlabel88">
                    {{ grid.parentThreeTemplateAuther }}
                </span> -->

                <el-button-group class="verticalalignbottom">
                    <el-button type="primary" icon="el-icon-edit" @click="editBase03()" :disabled="!editpermission" class="marginright4px borderradius3px"></el-button>
                    <el-button type="primary" icon="glyphicon glyphicon-duplicate" @click="copybasetemplates('basethree', 3)" :disabled="!editpermission" class="marginright4px paddingbottom5px borderradius3px"></el-button>
                    <el-button type="danger" icon="el-icon-delete" @click="deletetemplates('basethree')" :disabled="!editpermission" class="borderradius3px"></el-button>
                </el-button-group>

                    <!-- <el-button type="primary" class="marginleft4px"
                               @click="editBase03()"
                               :disabled="!editpermission"
                               icon="el-icon-edit"></el-button>

                    <el-button type="primary" class="marginleft4px"
                               @click="copybasetemplates('basethree', 3)"
                               :disabled="!editpermission"
                               icon="glyphicon glyphicon-duplicate"></el-button> -->

            </div>
        </div>

        <div v-if="detail" class="Base_Template_add_edit" >
            <div id="base01Template" v-show="base01Detail">
                    <div class="fixdheader">
                          <div class="headerstyle">
                              <el-row>
                           <el-col :span="12">
                               Level {{base01Detailform.elementTemplateType}}: {{getTemplateName(this.base01Detailform.elementTemplateType)}}
                           </el-col>
                           <el-col :span="12">
                             <div class="right">

                                 <el-button  icon="el-icon-back" :disabled="!editpermission" type="info" @click="goback">Back</el-button>
                                 <!-- <el-button @click="$router.push('/qatimesubform')"   :disabled="!editpermission" type="info" >QU Times</el-button> -->

                                 <el-button  :disabled="!editpermission" @click="cellchanged('library_checklistslibrary_edit_save',()=>{saveBase1()},false)"  icon="el-icon-circle-check" type="success" >Save</el-button>

                             </div>
                           </el-col>
                           </el-row>
                           </div>
                    <el-row>
                        <span class="left">
                             <el-input @change="canedit" id="library_checklistslibrary_edit_templatename" style="width:280px" :disabled="!editpermission" v-model="base01Detailform.projectname" placeholder="Template Name"></el-input>
                        </span>

                        <span class="spacebetween_LR5 left">
                            <el-select style="width:280px" @change="canedit" :disabled="base01Detailform.elementTemplateType ==  1" v-model="base01Detailform.nrmelementid" filterable :placeholder="base01Detailform.elementTemplateType != 1 ?'Section (Available a Base '+(this.base01Detailform.elementTemplateType + 1)+')': ''" class="fullwidth">
                                    <el-option v-for="item in nrmElements"
                                               :key="item.section" :disabled="!editpermission"
                                               :label="item.section"
                                               :value="item.section">
                                    </el-option>
                                </el-select>
                        </span>
                        <span class="left" v-if="base01Detailform.elementTemplateType ==  3">
                             <el-select @change="canedit"   style="width:116px" v-model="base01Detailform.formtype" filterable :placeholder="'Library (Available a Base '+(base01Detailform.elementTemplateType + 1)+')' " class="fullwidth">
                                    <el-option v-for="lib in libraries" :disabled="!editpermission"
                                               :key="lib"
                                               :label="lib"
                                               :value="lib">
                                    </el-option>
                                </el-select>
                        </span>
                        <span class="left" v-if="base01Detailform.LastSavedByuser != null && base01Detailform.elementTemplateType !=  3">
                             <div class="el-input el-input--mini">
                                <b>Last Edited:&nbsp; &nbsp;</b> {{ base01Detailform.LastSavedByuser.loginname }} &nbsp; &nbsp;
                                {{ lastsaved }}
                            </div>
                        </span>
                    </el-row>
                    <hr class="hrstyle" />
                    <el-row>
                         <div v-if="base01Detailform.LastSavedByuser != null && base01Detailform.elementTemplateType ==  3">
                            <div class="el-input el-input--mini">
                                <b>Last Edited:&nbsp; &nbsp;</b> {{ base01Detailform.LastSavedByuser.loginname }} &nbsp; &nbsp;
                                {{ lastsaved }}
                            </div>
                            <hr class="hrstyle" />
                        </div>
                    </el-row>
                     <el-row>
                        <!-- <el-col v-if="base01Detailform.elementTemplateType != 2">
                            <div class="var_crt">
                                <span style="font:bold;">Sections</span>
                            </div>
                        </el-col> -->
                        <el-col >
                            <div>
                                <div class="var_crt_dark left" style="width: 747px;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Shown In Project Checklists</div>
                                <div class="var_crt_grey left" style="width: 254px;">&nbsp;&nbsp; Checklist Design</div>
                            </div>
                            <!-- <el-table :data="[{}]" class="tableBox-cell" >
                              <el-table-column  label="Shown In Project Checklists" >
                                <template slot="header" class="var_crt" slot-scope="scope">
                                    <div class="var_crt_dark"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Shown In Project Checklists </div>
                                </template>
                              </el-table-column>
                               <el-table-column  label="Checklist Design"  width="195">
                                 <template class="var_crt" slot="header" slot-scope="scope">
                                    <div class="var_crt"> Checklist Design</div>
                                </template>
                               </el-table-column>
                            </el-table> -->
                        </el-col>
                    </el-row>
                        <el-table :data="[{}]" class="tableBox-cell" >
                             <el-table-column   width="25"></el-table-column>
                             <el-table-column label="List" ></el-table-column>
                             <el-table-column label="Answer / Link" align="left"  width="200"></el-table-column>
                             <el-table-column label="Unit/UR(S)"  width="65"></el-table-column>
                             <el-table-column label="Library"   width="68">
                                 <template  slot="header" slot-scope="scope">
                                    <div style="border-left:2px #b9afaf solid !important"  >&nbsp; Library</div>
                                </template>
                             </el-table-column>
                             <el-table-column label="LR" align="center"  width="30"></el-table-column>
                             <el-table-column label="RT" align="left" width="40"></el-table-column>
                             <el-table-column label="Actions" align="center" width="120"></el-table-column>
                        </el-table>
                    </div>
                    <div  class="Base_Template_add_edit">
                <el-form ref="detailform2" size="mini">
                    <div class="spaceBetween" />
                        <el-table-draggable :rowclassname="'section-format'">
                        <el-table key="id" row-key="id" :expand-row-keys="expandedrowssection" default-expand-all :show-header="false" ref="sectiontable"  :data="base01Detailform.section"  class="fullwidth custom_padding tableBox section_table_header"
                          size="mini" :cell-style="mastertablecellClassName" :row-class-name="'section-format'">
                         <el-table-column type="expand" width="25"> 
                           <template slot-scope="pt" class="fullwidth">
                             <div v-if="pt.row.sectiondetails && pt.row.sectiondetails.length > 0">
                               <el-table-draggable :rowclassname="'sectiondetails'">
                                 <el-table :key="pt.row.id" :row-class-name="'sectiondetails'"  default-expand-all :show-header="false"   :data="pt.row.sectiondetails"
                                   class="fullwidth custom_padding tableBox"  :cell-style="tablecellClassName"
                                   ref="sectiondetail" >
                                   <el-table-column type="expand" width="25">
                                     <template slot-scope="Ascope">
                                        <div v-if="Ascope.row && Ascope.row.children && Ascope.row.children.length > 0">
                                          <el-table-draggable  :rowclassname="'Ascope'">
                                            <el-table :row-class-name="'Ascope'" :key="Ascope.row.id" default-expand-all :show-header="false"   :data="Ascope.row.children"
                                              class="fullwidth custom_padding tableBox" :cell-style="tablecellClassName"
                                              ref="Ascope" >
                                             <el-table-column type="expand" width="25">
                                               <template slot-scope="Bscope">
                                                 <div v-if="Bscope.row && Bscope.row.children && Bscope.row.children.length > 0">
                                                  <el-table-draggable :rowclassname="'Bscope'">
                                                    <el-table :row-class-name="'Bscope'" :key="Bscope.row.id" default-expand-all :show-header="false"  :data="Bscope.row.children"
                                                     class="fullwidth custom_padding tableBox" :cell-style="tablecellClassName"
                                                     ref="Bscope" >
                                                     <el-table-column type="expand" width="25">
                                                      <template slot-scope="Cscope">
                                                        <div v-if="Cscope.row && Cscope.row.children && Cscope.row.children.length > 0">
                                                         <el-table-draggable :rowclassname="'Cscope'">
                                                         <el-table :row-class-name="'Cscope'" :key="Cscope.row.id"  default-expand-all :show-header="false"   :data="Cscope.row.children"
                                                           class="fullwidth custom_padding tableBox" :cell-style="tablecellClassName"
                                                           ref="Cscope" >
                                                           <el-table-column  width="25"></el-table-column>
                                                           <el-table-column prop="activity" label="List" >
                                                             <template slot-scope="scope">
                                                                 <div v-if="scope.row.rowtype !== 'G' && scope.row.rowtype !== 'H'">
                                                                 <el-input type="textarea" :autosize="{ maxRows: 2 }" @change="canedit" :id="'library_checklistslibrary_edit_table_activity'+scope.row.rowtype+pt.$index+Ascope.$index+Bscope.$index+Cscope.$index+scope.$index" :class="'row_color_'+scope.row.rowtype"
                                                                 :disabled="!editpermission" placeholder="Activity" v-model="scope.row.activity" ></el-input>
                                                                 </div>
                                                                 <div v-if="scope.row.rowtype == 'H'">
                                                                 <el-input type="textarea" :autosize="{ maxRows: 2 }" @change="canedit" :id="'library_checklistslibrary_edit_table_activity'+scope.row.rowtype+pt.$index+Ascope.$index+Bscope.$index+Cscope.$index+scope.$index" :class="base01Detailform.elementTemplateType == 2 ? 'row_color_'+scope.row.rowtype+'_'+base01Detailform.elementTemplateType : 'row_color_'+scope.row.rowtype"
                                                                 :disabled="!editpermission" placeholder="Activity" v-model="scope.row.activity" ></el-input>
                                                                 </div>
                                                                 <div v-if="scope.row.rowtype === 'G'">
                                                                    <el-input
                                                                        type="textarea" :autosize="{ maxRows: 2 }"
                                                                        class="fullwidth"
                                                                            :id="'library_checklistslibrary_edit_table_activity'+scope.row.rowtype+pt.$index+Ascope.$index+Bscope.$index+Cscope.$index+scope.$index"
                                                                             v-model="scope.row.activity" placeholder="Activity"
                                                                             :spellcheck="isspellcheck" :disabled="!editpermission"
                                                                             :class="'row_color_'+scope.row.rowtype">
                                                                         </el-input>
                                                                      <!-- <el-dropdown :hide-on-click="false" class="fullwidth" trigger="click">
                                                                             <span class="el-dropdown-link">
                                                                                 <el-input
                                                                                 class="fullwidth"
                                                                                     v-model="scope.row.activity" placeholder="Link"
                                                                                     :spellcheck="isspellcheck" :disabled="!editpermission"
                                                                                     @change="commentorlinkchange(scope.row, 'isactivity')"
                                                                                     @focus="commentorlinkfocus(scope.row)"
                                                                                     :class="'row_color_'+scope.row.rowtype">
                                                                                 </el-input>
                                                                             </span>
                                                                             <el-dropdown-menu slot="dropdown" style="width: 280px;">
                                                                                 <el-dropdown-item>
                                                                                     <div v-if="islinkedit">
                                                                                         <el-row>
                                                                                             <el-col :span="20">
                                                                                                 <el-input placeholder="Add or paste a link" v-model="scope.row.sourcelink" @input.native="linkinputchange(scope.row)" @change="commentorlinkchange(scope.row.IM, 'islink')"></el-input>
                                                                                             </el-col>

                                                                                             <el-col :span="2">
                                                                                                 <el-button @click="applyclick(scope.row)" type="text" :disabled="isbtndisable" style="margin-left: 6px; font-weight: bold; font-size: 13px;">Apply</el-button>
                                                                                             </el-col>
                                                                                         </el-row>
                                                                                     </div>
                                                                                     <div v-else>
                                                                                         <el-row>
                                                                                             <el-col :span="22">
                                                                                                 <a :href="scope.row.sourcelink" target="_blank" class="linktextoverflowhide">{{scope.row.sourcelink}}</a>
                                                                                             </el-col>

                                                                                             <el-col :span="2">
                                                                                                 <el-button icon="el-icon-edit" type="text" @click="islinkedit = true" style="float: right;"></el-button>
                                                                                             </el-col>
                                                                                         </el-row>
                                                                                     </div>
                                                                                 </el-dropdown-item>
                                                                             </el-dropdown-menu>
                                                                         </el-dropdown> -->
                                                                 </div>
                                                             </template>
                                                           </el-table-column>
                                                           <el-table-column label="Answer/link" align="left" prop="method" width="200">
                                                             <template slot-scope="scope">
                                                               <div v-if="scope.row.rowtype === 'G'">
                                                                  <el-dropdown :hide-on-click="false" class="fullwidth" trigger="click">
                                                                   <span class="el-dropdown-link">
                                                                     <el-input
                                                                     class="fullwidth"
                                                                         v-model="scope.row.sourcelink" placeholder="Link to ......................................................"
                                                                         :spellcheck="isspellcheck" :readonly="false"
                                                                         @change="commentorlinkchange(scope.row, 'isactivity')"
                                                                         @focus="commentorlinkfocus(scope.row)"
                                                                         :class="'row_color_'+scope.row.rowtype">
                                                                     </el-input>
                                                                   </span>
                                                                   <el-dropdown-menu slot="dropdown" style="width: 280px;">
                                                                     <el-dropdown-item>
                                                                         <div v-if="islinkedit">
                                                                             <el-row>
                                                                                 <el-col :span="20">
                                                                                     <el-input placeholder="Add or paste a link" v-model="scope.row.sourcelink" @input.native="linkinputchange(scope.row)" @change="commentorlinkchange(scope.row.IM, 'islink')"></el-input>
                                                                                 </el-col>

                                                                                 <el-col :span="2">
                                                                                     <el-button @click="applyclick(scope.row)" type="text" :disabled="isbtndisable" style="margin-left: 6px; font-weight: bold; font-size: 13px;">Apply</el-button>
                                                                                 </el-col>
                                                                             </el-row>
                                                                         </div>
                                                                         <div v-else>
                                                                             <el-row>
                                                                                 <el-col :span="22">
                                                                                     <a :href="scope.row.sourcelink" target="_blank" class="linktextoverflowhide">{{scope.row.sourcelink}}</a>
                                                                                 </el-col>

                                                                                 <el-col :span="2">
                                                                                     <el-button icon="el-icon-edit" type="text" @click="islinkedit = true" style="float: right;"></el-button>
                                                                                 </el-col>
                                                                             </el-row>
                                                                         </div>
                                                                     </el-dropdown-item>
                                                                   </el-dropdown-menu>
                                                                 </el-dropdown>
                                                               </div>
                                                               <!-- <el-select
                                                                  style="width:70% ;"
                                                                   :class="'row_color_'+scope.row.rowtype"
                                                                   @change="canedit"
                                                                   v-model="scope.row.method"
                                                                   filterable
                                                                   placeholder="Library"
                                                                   class="fullwidth"
                                                                   v-if="scope.row.rowtype === 'D'">
                                                                   <el-option v-for="lib in libraries" :disabled="!editpermission"
                                                                           :key="lib"
                                                                           :label="lib"
                                                                           :value="lib">
                                                                   </el-option>
                                                               </el-select> -->
                                                                <el-input
                                                                 :class="'row_color_'+scope.row.rowtype"
                                                                 @change="canedit"       
                                                                 :disabled="!editpermission"
                                                                 v-if="scope.row.rowtype === 'E'"
                                                                 placeholder="Answer"
                                                                 v-model="scope.row.answer" >
                                                                </el-input>
                                                               <el-select
                                                                  style="width:100% ;"
                                                                     v-model="scope.row.answerarr"
                                                                     multiple
                                                                     filterable
                                                                     allow-create
                                                                     :clearable="false"
                                                                     default-first-option
                                                                    :class="'row_color_'+scope.row.rowtype"
                                                                    @change="answerchange($event,scope.row,Cscope.row)"
                                                                    placeholder="Answer"
                                                                     v-if="scope.row.rowtype === 'F'">
                                                                     <el-option
                                                                         v-for="(item,index) in scope.row.answerarroption"
                                                                         :key="index"
                                                                         :label="item"
                                                                         :value="item">
                                                                       </el-option>
                                                               </el-select>
                                                             </template>
                                                           </el-table-column>
                                                           <el-table-column label="Unit/UR(S)" align="center" prop="unit" width="65">
                                                             <template slot-scope="scope">
                                                                 <el-select
                                                                    :class="'row_color_'+scope.row.rowtype"
                                                                    @change="canedit"
                                                                     clearable
                                                                    filterable placeholder="Units"
                                                                    v-model="scope.row.unit"
                                                                    :id="`library_checklistslibrary_edit_table_${scope.row.rowtype}_unit`+pt.$index+Ascope.$index+Bscope.$index+Cscope.$index+scope.$index"
                                                                     v-if="scope.row.rowtype === 'D'">
                                                                     <el-option v-for="u in units" :disabled="!editpermission"
                                                                             :key="u"
                                                                             :label="u"
                                                                             :value="u">
                                                                     </el-option>
                                                                 </el-select>
                                                                   <el-select
                                                                     v-model="scope.row.URS"
                                                                     :class="'row_color_'+scope.row.rowtype"
                                                                     v-if="scope.row.rowtype === 'E' || scope.row.rowtype === 'F'"
                                                                     @change="canedit"
                                                                     clearable
                                                                     remote
                                                                     :remote-method="getURsRemote"
                                                                     :disabled="!editpermission"
                                                                     :id="`library_checklistslibrary_edit_table_${scope.row.rowtype}_UR`+pt.$index+Ascope.$index+Bscope.$index+Cscope.$index+scope.$index"
                                                                     reserve-keyword
                                                                     filterable
                                                                     placeholder="UR(S)">
                                                                     <el-option
                                                                         v-for="(item, index) in $store.state.URs"
                                                                         :key="index"
                                                                         :label="item.ur"
                                                                         :value="item.ur">
                                                                     </el-option>
                                                                     </el-select>
                                                             </template>
                                                           </el-table-column>
                                                           <el-table-column label="Library"  width="70">
                                                              <template slot-scope="scope">
                                                              <el-select
                                                                 style="width:100% ;"
                                                                 :class="'row_color_'+scope.row.rowtype"
                                                                 @change="canedit"
                                                                 v-model="scope.row.method"
                                                                 filterable
                                                                  clearable
                                                                  :id="`library_checklistslibrary_edit_table_${scope.row.rowtype}_library`+pt.$index+Ascope.$index+Bscope.$index+Cscope.$index+scope.$index"
                                                                 placeholder="Library"
                                                                 class="fullwidth"
                                                                >
                                                                 <el-option v-for="lib in libraries" :disabled="!editpermission"
                                                                         :key="lib"
                                                                         :label="lib"
                                                                         :value="lib">
                                                                 </el-option>
                                                                </el-select>
                                                              </template>
                                                           </el-table-column>
                                                           <el-table-column label="LR" prop="rowtype" align="left" width="34">
                                                             <template slot-scope="scope">
                                                                 <div style="font-size:12px;">
                                                                         <el-input :controls="false" minlength="0"  maxlength="3" :class="'row_color_'+scope.row.rowtype"  @change="addzerosinLR(scope.row,'string')"   class="LR-input-fixwidth"
                                                                 :disabled="!editpermission" :id="`library_checklistslibrary_edit_table_${scope.row.rowtype}_LR`+pt.$index+Ascope.$index+Bscope.$index+Cscope.$index+scope.$index" placeholder="LR" v-model="scope.row.LRs" ></el-input>
                                                                 </div>
                                                             </template>
                                                           </el-table-column>
                                                           <el-table-column label="RT" prop="rowtype" align="left" width="34">
                                                             <template slot-scope="scope">
                                                                <el-dropdown trigger="click">
                                                                <span style="font-size:12px;">
                                                                    {{scope.row.rowtype  }}  <i class="el-icon-arrow-down el-icon--right"></i>
                                                                </span>
                                                                <el-dropdown-menu slot="dropdown">
                                                                    <el-dropdown-item style="padding: 0px;text-align: center;margin: 10px;"> <div @click="changerowtype(scope.row,'D')">D</div></el-dropdown-item>
                                                                    <el-dropdown-item style="padding: 0px;text-align: center;margin: 10px;"><div @click="changerowtype(scope.row,'E')">E</div></el-dropdown-item>
                                                                    <el-dropdown-item style="padding: 0px;text-align: center;margin: 10px;"><div @click="changerowtype(scope.row,'F')">F</div></el-dropdown-item>
                                                                    <el-dropdown-item style="padding: 0px;text-align: center;margin: 10px;"><div @click="changerowtype(scope.row,'G')">G</div></el-dropdown-item>
                                                                    <el-dropdown-item style="padding: 0px;text-align: center;margin: 10px;"><div @click="changerowtype(scope.row,'H')">H</div></el-dropdown-item>
                                                                </el-dropdown-menu>
                                                                </el-dropdown>
                                                               </template>
                                                           </el-table-column>
                                                           <el-table-column label="Actions" align="right" width="120">
                                                             <template slot-scope="scope">
                                                                     <!-- <i class="el-icon-circle-plus" style="color:#409EFF;font-size: medium;" @click="AddRowSectionDetail(scope.row,Cscope.row.children,Cscope.row)"></i> -->
                                                                 <el-button size="mini" class="padding7TB" :disabled="!editpermission" type="primary" title="Add New Row" icon="el-icon-circle-plus"
                                                                         @click="AddRowSectionDetail(scope.row,Cscope.row.children,Cscope.row,scope.$index,'Cscope', 1)"></el-button>
                                                                 <el-button size="mini" class="padding3T5L5B" :disabled="!editpermission" type="primary" title="Add Row Copy" icon="glyphicon glyphicon-duplicate"
                                                                         @click="AddRowSectionDetail(scope.row,Cscope.row.children,Cscope.row,scope.$index,'Cscope', 2)"></el-button>
                                                                     <!-- <i class="el-icon-delete" style="color:#f78989;font-size: medium;" @click="delRowSectiondetail(scope.$index, Cscope.row.children)"></i> -->
                                                                 <el-button size="mini" class="padding7TB" :disabled="!editpermission" type="danger" title="Delete Row" icon="el-icon-delete"
                                                                         @click="delRowSectiondetail(scope.$index, Cscope.row.children,Cscope.row,'Bscope')"></el-button>
                                                                 <el-button size="mini" class="padding7TB" :disabled="!editpermission" :type="scope.row.hide == true ? 'primary' : ''" title="Hide Row" icon="glyphicon glyphicon-eye-close"
                                                                         @click="hideRowSection(scope.row)"></el-button>
                                                             </template>
                                                           </el-table-column>
                                                         </el-table>
                                                         </el-table-draggable>
                                                        </div>
                                                     </template>
                                                     </el-table-column>
                                                     <el-table-column prop="activity" label="List" >
                                                       <template slot-scope="scope">
                                                           <el-input type="textarea" :autosize="{ maxRows: 2 }" @change="canedit" :id="'library_checklistslibrary_edit_table_activityC'+pt.$index+Ascope.$index+Bscope.$index+scope.$index" :class="'row_color_'+scope.row.rowtype"
                                                           :disabled="!editpermission" placeholder="Activity" v-model="scope.row.activity" ></el-input>
                                                       </template>
                                                    </el-table-column>
                                                    <el-table-column label="Library"  width="70"></el-table-column>
                                                     <el-table-column label="LR" prop="rowtype" align="left" width="34">
                                                       <template slot-scope="scope">
                                                           <div style="font-size:12px;">
                                                             <el-input :controls="false" minlength="0" :id="'library_checklistslibrary_edit_table_C_LR'+pt.$index+Ascope.$index+Bscope.$index+scope.$index" maxlength="3" :class="'row_color_'+scope.row.rowtype"  @change="addzerosinLR(scope.row,'string')"   class="LR-input-fixwidth"
                                                             :disabled="!editpermission" placeholder="LR" v-model="scope.row.LRs" ></el-input>
                                                           </div>
                                                       </template>
                                                    </el-table-column>
                                                    <el-table-column label="RT" prop="rowtype" align="left" width="34">
                                                      <template slot-scope="scope">
                                                          <div style="font-size:12px;">
                                                             {{scope.row.rowtype}}
                                                          </div>
                                                      </template>
                                                    </el-table-column>
                                                    <el-table-column label="Actions" align="right" width="120">
                                                      <template slot-scope="scope">
                                                              <!-- <i class="el-icon-circle-plus" style="color:#409EFF;font-size: medium;" @click="AddRowSectionDetail(scope.row,Bscope.row.children,Bscope.row)"></i> -->
                                                          <el-button size="mini" class="padding7TB" :disabled="!editpermission" type="primary" title="Add New Row" icon="el-icon-circle-plus"
                                                                  @click="AddRowSectionDetail(scope.row,Bscope.row.children,Bscope.row,scope.$index,'Bscope', 1)"></el-button>
                                                          <el-button size="mini" class="padding3T5L5B" :disabled="!editpermission" type="primary" :title="scope.row.children && scope.row.children.length > 0 ? 'Add Row Copy' : 'Add Child Row'" :icon="setIconSubRws(scope.row)"
                                                                  @click="AddRowSectionDetail(scope.row,Bscope.row.children,Bscope.row,scope.$index,'Bscope', 2)"></el-button>
                                                              <!-- <i class="el-icon-delete" style="color:#f78989;font-size: medium;" @click="delRowSectiondetail(scope.$index,Bscope.row.children)"></i> -->
                                                          <el-button size="mini" class="padding7TB" :disabled="!editpermission" type="danger" title="Delete Row" icon="el-icon-delete"
                                                                  @click="delRowSectiondetail(scope.$index,Bscope.row.children,Bscope.row,'Ascope')"></el-button>
                                                          <el-button size="mini" class="padding7TB" :disabled="!editpermission" :type="scope.row.hide == true ? 'primary' : ''" title="Hide Row" icon="glyphicon glyphicon-eye-close"
                                                                  @click="hideRowSection(scope.row)"></el-button>
                                                      </template>
                                                      </el-table-column>
                                                    </el-table>
                                                  </el-table-draggable>
                                                 </div>
                                               </template>
                                             </el-table-column>
                                             <el-table-column prop="activity" label="List" >
                                               <template slot-scope="scope">
                                                   <el-input type="textarea" :autosize="{ maxRows: 2 }" @change="canedit" :id="'library_checklistslibrary_edit_table_activityB'+pt.$index+Ascope.$index+scope.$index" :class="'row_color_'+scope.row.rowtype"
                                                   :disabled="!editpermission" placeholder="Activity" v-model="scope.row.activity" ></el-input>
                                               </template>
                                             </el-table-column>
                                             <el-table-column label="Library"  width="70"></el-table-column>
                                              <el-table-column label="LR" prop="rowtype" align="left" width="34">
                                                <template slot-scope="scope">
                                                    <div style="font-size:12px;">
                                                      <el-input :controls="false" minlength="0" :id="'library_checklistslibrary_edit_table_B_LR'+pt.$index+Ascope.$index+scope.$index"  maxlength="3" :class="'row_color_'+scope.row.rowtype"  @change="addzerosinLR(scope.row,'string')"   class="LR-input-fixwidth"
                                                       :disabled="!editpermission" placeholder="LR" v-model="scope.row.LRs" ></el-input>
                                                    </div>
                                                </template>
                                             </el-table-column>
                                             <el-table-column label="RT" prop="rowtype" align="left" width="34">
                                                <template slot-scope="scope">
                                                   <div style="font-size:12px;">
                                                     {{scope.row.rowtype}}
                                                   </div>
                                               </template>
                                             </el-table-column>
                                             <el-table-column label="Actions" align="right" width="120">
                                                <template slot-scope="scope">
                                                     <!-- <i class="el-icon-circle-plus" style="color:#409EFF;font-size: medium;" @click="AddRowSectionDetail(scope.row,Ascope.row.children,Ascope.row)"></i> -->
                                                 <el-button size="mini" class="padding7TB" :disabled="!editpermission" type="primary" title="Add New Row" icon="el-icon-circle-plus"
                                                         @click="AddRowSectionDetail(scope.row,Ascope.row.children,Ascope.row,scope.$index,'Ascope', 1)"></el-button>
                                                 <el-button size="mini" class="padding3T5L5B" :disabled="!editpermission" type="primary" :title="scope.row.children && scope.row.children.length > 0 ? 'Add Row Copy' : 'Add Child Row'" :icon="setIconSubRws(scope.row)"
                                                         @click="AddRowSectionDetail(scope.row,Ascope.row.children,Ascope.row,scope.$index,'Ascope', 2)"></el-button>
                                                        <!-- <i class="el-icon-delete" style="color:#f78989;font-size: medium;" @click="delRowSectiondetail(scope.$index,Ascope.row.children)"></i> -->
                                                 <el-button size="mini" class="padding7TB" :disabled="!editpermission" type="danger" title="Delete Row" icon="el-icon-delete"
                                                         @click="delRowSectiondetail(scope.$index,Ascope.row.children,Ascope.row,'sectiondetail')"></el-button>
                                                 <el-button size="mini" class="padding7TB" :disabled="!editpermission" :type="scope.row.hide == true ? 'primary' : ''" title="Hide Row" icon="glyphicon glyphicon-eye-close"
                                                         @click="hideRowSection(scope.row)"></el-button>
                                                </template>
                                            </el-table-column>
                                            </el-table>
                                          </el-table-draggable>
                                         </div>
                                     </template>
                                   </el-table-column>
                                   <el-table-column prop="activity" label="List" >
                                     <template slot-scope="scope">
                                         <el-input type="textarea" :autosize="{ maxRows: 2 }" @change="canedit" :id="'library_checklistslibrary_edit_table_activityA'+pt.$index+scope.$index"   :class="'row_color_'+scope.row.rowtype"
                                          :disabled="!editpermission" placeholder="Activity" v-model="scope.row.activity" ></el-input>
                                     </template>
                                   </el-table-column>
                                   <el-table-column label="Library"   width="70"></el-table-column>
                                   <el-table-column label="LR" prop="rowtype" align="left" width="34">
                                      <template slot-scope="scope">
                                        <div style="font-size:12px;">
                                           <el-input :controls="false" minlength="0" :id="'library_checklistslibrary_edit_table_A_LR'+pt.$index+scope.$index"  maxlength="3" :class="'row_color_'+scope.row.rowtype"  @change="addzerosinLR(scope.row,'string')"   class="LR-input-fixwidth"
                                           :disabled="!editpermission" placeholder="LR" v-model="scope.row.LRs" ></el-input>
                                        </div>
                                     </template>
                                   </el-table-column>
                                   <el-table-column label="RT" prop="rowtype" align="left" width="34">
                                      <template slot-scope="scope">
                                         <div style="font-size:12px;">
                                            {{scope.row.rowtype}}
                                         </div>
                                     </template>
                                   </el-table-column>
                                    <el-table-column label="Actions" align="right" width="120">
                                      <template slot-scope="scope">
                                        <!-- <i class="el-icon-circle-plus" style="color:#409EFF;font-size: medium;" @click="AddRowSectionDetail(scope.row,pt.row.sectiondetails,pt.row)"></i> -->
                                         <el-button size="mini" class="padding7TB" :disabled="!editpermission" type="primary" title="Add New Row" icon="el-icon-circle-plus"
                                               @click="AddRowSectionDetail(scope.row,pt.row.sectiondetails,pt.row,scope.$index,'sectiondetail', 1)"></el-button>

                                         <el-button size="mini" class="padding3T5L5B" :disabled="!editpermission" type="primary" :title="scope.row.children && scope.row.children.length > 0 ? 'Add Row Copy' : 'Add Child Row'" :icon="setIconSubRws(scope.row)"
                                               @click="AddRowSectionDetail(scope.row,pt.row.sectiondetails,pt.row,scope.$index,'sectiondetail', 2)"></el-button>

                                         <!-- <i class="el-icon-delete" style="color:#f78989;font-size: medium;" @click="delRowSectiondetail(scope.$index, pt.row.sectiondetails)"></i> -->
                                         <el-button size="mini" class="padding7TB" :disabled="!editpermission" type="danger" title="Delete Row" icon="el-icon-delete"
                                               @click="delRowSectiondetail(scope.$index, pt.row.sectiondetails,pt.row,'sectiontable')"></el-button>
                                         <el-button size="mini" class="padding7TB" :disabled="!editpermission" :type="scope.row.hide == true ? 'primary' : ''" title="Hide Row" icon="glyphicon glyphicon-eye-close"
                                               @click="hideRowSection(scope.row)"></el-button>
                                      </template>
                                    </el-table-column>
                                 </el-table>
                               </el-table-draggable>
                             </div>
                           </template>
                         </el-table-column>
                         <el-table-column  label="List" prop="sectionname" :disabled="!editpermission"  style="font-size: small;font-weight: 600;">
                            <template slot-scope="scope">
                              <span class="left" style="margin-right: 7px;margin-top: 3px; font-size: 18px; line-height: 22px;">
                                  {{scope.row.srno}}
                              </span>
                              <span class="left">
                               <el-input type="textarea" :autosize="{ maxRows: 2 }" placeholder="Activity" @change="canedit" class="section_input width677px" :id="'library_checklistslibrary_edit_table_sectionname'+scope.$index"  :disabled="!editpermission" v-model="scope.row.sectionname" > </el-input>
                               </span>
                            </template>
                         </el-table-column>
                         <el-table-column label="Library"   width="75"></el-table-column>
                         <!-- <el-table-column label="LR" prop="rowtype" align="left" width="34"></el-table-column> -->
                         <el-table-column label="RT" prop="rowtype" align="right" width="63">
                            <template slot-scope="scope">
                                <div class="flexlabel">
                                    <el-popover v-if="scope.row.qutime && base01Detailform.elementTemplateType ==  3" placement="bottom-start" title="Open On" width="500" trigger="click">
                                        <el-row>
                                        <el-col :span="12" style="font-weight: bold;font-size: 14px;" >
                                          {{scope.row.srno}}&nbsp;&nbsp;{{scope.row.sectionname}}
                                        </el-col>
                                        <el-col :span="12">
                                            <i class="el-icon-edit right" title="Add/Edit Pages and Actions" @click="openEditPage" style="color:#409EFF"></i>
                                         </el-col>
                                         </el-row>
                                        <hr  class="hrstyle spaceBetween"/>
                                        <el-row>
                                            <el-table
                                                :data="scope.row.pages" :show-header="false">
                                                <el-table-column
                                                    prop="pagename"
                                                    label="Page"
                                                    width="50">
                                                </el-table-column>
                                                <el-table-column
                                                    prop="page"
                                                    label="Actions"
                                                    >
                                                    <template slot-scope="pa">
                                                        <el-tag
                                                        effect="plain"
                                                        :key="tag.id"
                                                        v-for="tag in pa.row.cta" >
                                                        <div>
                                                         <span clss="left">
                                                            <el-checkbox v-model="tag.ischeck"></el-checkbox>
                                                        </span>
                                                        <span clss="left">
                                                            {{tag.actionname}}
                                                        </span>
                                                        </div>
                                                        </el-tag>
                                                    </template>
                                                </el-table-column>
                                                </el-table>
                                        </el-row>
                                        <hr  class="hrstyle" style="margin-top: 0px;"/>
                                        <div class="spaceBetween" />
                                        <el-row>
                                        <el-select  v-model="scope.row.qutime.response" clearable placeholder="Response Type">
                                            <el-option
                                                v-for="item in [{name:'Reminder Only',value:'reminderonly'},{name:'Must Complete',value:'mustcomplete'}]"
                                                :key="item.value" :label="item.name" :value="item.value">
                                            </el-option>
                                        </el-select>
                                      </el-row>
                                      <div class="spaceBetween" />
                                      <el-row>
                                        <el-col :span="12">
                                        <el-select  v-model="scope.row.qutime.reminder" placeholder="Frequency">
                                            <el-option
                                                v-for="item in [{name:'First use (once)',value:'firstuse'},{name:'Everytime',value:'everytime'}]"
                                                :key="item.value" :label="item.name" :value="item.value">
                                            </el-option>
                                        </el-select>
                                    </el-col>
                                    <el-col :span="12">
                                        <el-button size="mini" @click="saveopenonbox(scope.row)" class="padding7TB right" icon="el-icon-circle-check"  :disabled="!editpermission" type="success" title="Save" >Save</el-button> 
                                    </el-col>
                                    </el-row>
                                        <el-button size="mini" @click="openonbox(scope.row)" slot="reference" class="padding7TB" :disabled="!editpermission" type="primary" title="Open On" >Open On</el-button> 
                                    </el-popover>
                                </div>
                            </template>
                         </el-table-column>
                            <!-- <el-table-column label="Library" class="border_left_1px"  width="65"></el-table-column> -->
                            <!-- <el-table-column label="MOM / Answer" align="left"  width="200"></el-table-column>
                            <el-table-column label="Unit/UR(S)" prop="unit" width="65"></el-table-column>
                             <el-table-column label="RT" prop="rowtype" align="right" width="10">
                             </el-table-column> -->
                         <el-table-column label="Actions" align="right" width="120">
                                <template slot-scope="scope">
                                  <!-- <i class="el-icon-circle-plus" style="color:#409EFF;font-size: medium;" @click="Addsection(scope.$index, scope.row)"></i> -->
                                <el-button size="mini" @click="Addsection(scope.$index, scope.row, 1)" class="padding7TB" :disabled="!editpermission" type="primary" title="Add New Row" icon="el-icon-circle-plus"></el-button>
                                <el-button size="mini" @click="Addsection(scope.$index, scope.row, 2)" class="padding3T5L5B" :disabled="!editpermission" type="primary" :title="scope.row.sectiondetails && scope.row.sectiondetails.length > 0 ? 'Add Row Copy' : 'Add Child Row'" :icon="seticonSections(scope.row)"
                                ></el-button>
                               <!-- <i class="el-icon-delete" style="color:#f78989;font-size: medium;" @click="delRowSection(scope.$index, scope.row)"></i> -->
                               <el-button size="mini" class="padding7TB" :disabled="!editpermission" type="danger" title="Delete Row" icon="el-icon-delete"
                                      @click="delRowSection(scope.$index, scope.row)"></el-button>
                               <el-button size="mini" class="padding7TB" :disabled="!editpermission" :type="scope.row.hide == true ? 'primary' : ''" title="Hide Row" icon="glyphicon glyphicon-eye-close"
                                      @click="hideRowSection(scope.row)"></el-button>
                                </template>
                        </el-table-column>
                        </el-table>
                        </el-table-draggable>
                </el-form>
              
                </div>
            </div>
        </div>
        <el-dialog title="Add/Edit Page Action" class="changedialgheader-billmatch chngdialgpadding-billmatch" :visible.sync="editpageaction">
            <el-row>
                <el-table :data="pages" >
                    <el-table-column prop="pagename" label="Page" width="120">
                        <template slot="header" slot-scope="scope">
                            
                            
                                <el-button  size="mini" class="min_size5px" 
                                           :disabled="!editpermission" 
                                           @click="addpage" 
                                           type="primary" title="Add New Row" 
                                           icon="el-icon-circle-plus"
                                            ></el-button>
                                           &nbsp;&nbsp; Page's
                        </template>
                        <template slot-scope="scope">
                            <!-- <el-input class="input-new-tag"  v-model="scope.row.pagename"
                                 size="mini">
                            </el-input> -->
                            <el-select v-model="scope.row.pagename" @change="changepagename(scope.row)" class="input-new-tag" placeholder="Select Page">
                                    <el-option
                                    v-for="item in pageoptions"
                                    :key="item.pagename"
                                    :label="item.pagename"
                                    :value="item.pagename">
                                    </el-option>
                                </el-select>
                        </template>
                    </el-table-column>
                    <el-table-column prop="page" label="Action's">
                        <template slot-scope="scope">
                            <span :key="tag.id" v-for="tag,tagindex in scope.row.cta">
                               <span class="left" style="margin-left: 10px !important;">
                                 <el-tag  v-if="!tag.isedit" closable :disable-transitions="false"
                                     @close="handleDelete(scope.row.cta,tag.id)"  @click="tagedit(tag,true,tagindex,scope.row.cta)">
                                        {{tag.actionname}}
                                 </el-tag>
                                 <!-- <el-input v-else class="input-new-tag"  v-model="tag.actionname"
                                      size="mini" :ref='"input_" + tagindex'
                                      @keyup.enter.native="tagedit(tag,false,tagindex,scope.row.cta)"
                                      @blur="tagedit(tag,false,tagindex,scope.row.cta)">
                                 </el-input> -->
                                 <el-select v-model="tag.actionname" v-else class="input-new-tag"  @change="tagedit(tag,false,tagindex,scope.row.cta)"  placeholder="Select Action">
                                    <el-option
                                    v-for="item in getpageactions(scope.row.pagename)"
                                    :key="item"
                                    :label="item"
                                    :value="item">
                                    </el-option>
                                    </el-select>
                                </span>
                           </span>
                            <!-- <el-input class="input-new-tag" v-if="scope.row.inputVisible" v-model="scope.row.inputvalue"
                                ref="saveTagInput" size="mini"
                                @keyup.enter.native="handleInputConfirm(scope.row.cta,scope.row)"
                                @blur="handleInputConfirm(scope.row.cta,scope.row)">
                            </el-input> -->
                            <el-select v-model="scope.row.inputvalue"  @change="handleInputConfirm(scope.row.cta,scope.row)" v-if="scope.row.inputVisible" class="input-new-tag" placeholder="Select Action">
                                    <el-option
                                    v-for="item in getpageactions(scope.row.pagename)"
                                    :key="item"
                                    :label="item"
                                    :value="item">
                                    </el-option>
                                </el-select>
                                <div v-else  style="margin-left: 10px;">
                                <el-button  size="mini" style="margin-left: 10px;" class="min_size5px"
                                           :disabled="!editpermission" 
                                           @click="showInput(scope.row)" 
                                           type="primary" title="Add New Row" 
                                           icon="el-icon-circle-plus"
                                            ></el-button>
                                     </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="page" width="45" >
                        <template slot-scope="scope">
                            <div>
                                <i class="el-icon-delete button-new-tag" 
                                @click="handleDelete(pages,scope.row.id)"
                                style="color:rgb(185, 122, 87)"></i>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="spaceBetween" />
                <el-button  :disabled="!editpermission" class="right" icon="el-icon-circle-check" type="success" @click="savepageaction">Save</el-button>       
            </el-row>
        </el-dialog>
    </div>
</template>

<script>
import ElTableDraggable from './CustomTableDraggable.vue';
import moment from "moment";
    export default {
        components: {
            ElTableDraggable,
        },
        data() {
            return {
                expandedrowssection:[],
                pageoptions:[{
                    pagename:'AMOS',
                    actions:['open','import','Save','Save to Project'],
                },
                {
                    pagename:'Project',
                    actions:['open','import','Save'],
                },
                {
                    pagename:'Bill',
                    actions:['open','Export'],
                },
                ],
                pages:[],
                editpageaction:false,
                isbtndisable:false,
                islinkedit: false,
                newsectiondetails:[{rowtype:'A',srno:1}],
                isedit:false,
                base01Detail: false,
                base02Detail: false,
                base03Detail: false,
                detail: false,
                tabledata: [],
                grid: {baseOne:"", basetwo:"", basethree:""},
                showheader: true,
                errormessage: "",
                nrmElements: this.$store.state.sections,
                libraries: this.$store.state.libraries,
                roles: this.$store.state.roles,
                orders: this.$store.state.orders,
                units: this.$store.state.units,
                rowtypes: this.$store.state.rowtypes,
                loading: false,
                projectbqcode: false,
                bqcodelibraryid: 0,
                elementTemplateType: '',
                baseones: '',
                basetwos: '',
                basethrees: '',
                elementlistsectionrow: {},
                elementSectionDetail: {},
                userName: this.$store.state.user.fullname,
                base01Detailform: {
                    parentTemplateId: null,
                    elementTemplateType: '',
                    section: []
                },
                directionids:[
                    'library_checklistslibrary_edit_templatename',
                    'library_checklistslibrary_edit_table_sectionname',
                    'library_checklistslibrary_edit_table_activityA',
                    'library_checklistslibrary_edit_table_A_LR',
                    'library_checklistslibrary_edit_table_activityB',
                    'library_checklistslibrary_edit_table_B_LR',
                    'library_checklistslibrary_edit_table_activityC',
                    'library_checklistslibrary_edit_table_C_LR',
                    'library_checklistslibrary_edit_table_activityD',
                    'library_checklistslibrary_edit_table_activityE',
                    'library_checklistslibrary_edit_table_activityF',
                    'library_checklistslibrary_edit_table_activityG',
                    'library_checklistslibrary_edit_table_activityH',
                    'library_checklistslibrary_edit_table_D_LR',
                    'library_checklistslibrary_edit_table_E_LR',
                    'library_checklistslibrary_edit_table_F_LR',
                    'library_checklistslibrary_edit_table_G_LR',
                    'library_checklistslibrary_edit_table_H_LR',
                    'library_checklistslibrary_edit_table_D_library',
                    'library_checklistslibrary_edit_table_E_library',
                    'library_checklistslibrary_edit_table_F_library',
                    'library_checklistslibrary_edit_table_G_library',
                    'library_checklistslibrary_edit_table_H_library',
                    'library_checklistslibrary_edit_table_D_unit',
                    'library_checklistslibrary_edit_table_E_UR',
                    'library_checklistslibrary_edit_table_F_UR',
                ],
            }
        },
        created: function () {
            if(this.$store.state.checklistids.basethree){
               this.grid.basethree = this.$store.state.checklistids.basethree;
               this.grid.basethreename = this.$store.state.checklistids.basethreename;
            }
            if(this.$store.state.checklistids.basetwo){
               this.grid.basetwo = this.$store.state.checklistids.basetwo;
               this.grid.basetwoname = this.$store.state.checklistids.basetwoname;
            }
            if(this.$store.state.checklistids.baseOne){
               this.grid.baseOne = this.$store.state.checklistids.baseOne;
               this.grid.baseOnename = this.$store.state.checklistids.baseOnename;
            }
            var newgrid = JSON.parse(JSON.stringify(this.grid));
            this.grid = JSON.parse(JSON.stringify(newgrid)); 
            // this.getusers();
            // this.getBaseOne();
            // this.onSearchBaseTwo();
             this.$bus.$on('setsection', (sections) => {
                    this.nrmElements = sections
              })
              this.getcheckliststemplatepageaction();
        },
         computed: {
            getpageactions(){
                return function(data){
                    var varispage =  this.pageoptions.find(x => x.pagename == data);
                    if(varispage){
                        return varispage.actions;
                    }else{
                        return [];
                    }
                }
            },
            setIconSubRws(){
                return function(data){
                    if(data.children && data.children.length > 0)
                    {
                        return 'glyphicon glyphicon-duplicate';
                    }else{
                        return 'glyphicon glyphicon-circle-arrow-down';
                    }
                }
            },
            seticonSections(){
                return function(data){
                    if(data.sectiondetails && data.sectiondetails.length > 0)
                    {
                        return 'glyphicon glyphicon-duplicate';
                    }else{
                        return 'glyphicon glyphicon-circle-arrow-down';
                    }
                }
            },
            getChildren(){
                return function(data){
                    if(!data.children || data.children.length == 0)
                    {
                        return [];
                    }
                    return data.children;
                }
            },
            getTemplateName(){
              return function(data){
                    if(data == 1){return "Structure Templates - Edit"}
                    if(data == 2){return "Content Templates - Edit"}
                    if(data == 3){return "Templates For Projects - Edit"}

                }
            },
             lastsaved: function() {
                 if (this.base01Detailform && this.base01Detailform.LastSavedOn) {
                     var dt = new Date(this.base01Detailform.LastSavedOn);
                     return moment(dt).format("DD-MMM-YYYY HH:mm"); // dt.toLocaleString().replace(/:\d{2}\s/, " ");
                 }
             }
         },
        methods: {
            hideRowSection(row){
                this.$nextTick(()=>{
                    row.hide == true ? row.hide = false : row.hide = true
                })
            },
            getPropertyByString(obj, propString) {
             const propArray = propString.split('.');
             let currentObj = obj;
             for (let i = 0; i < propArray.length; i++) {
                 const prop = propArray[i];
                 currentObj = currentObj[prop];
                 if (currentObj === undefined) {
                 return undefined;
                 }
             }
             return currentObj;
         },
            validatedirectionsingle(data){
                var vall = "";
                if(data.rowtype == "section"){
                    vall = this.getPropertyByString(this.base01Detailform.section[data.sectionindex],data.checkstr)
                }
                if(data.rowtype == "sectiondetail"){
                    vall = this.getPropertyByString(this.base01Detailform.section[data.sectionindex].sectiondetails[data.sectiondetailindex],data.checkstr)
                }
                if(data.rowtype == "B"){
                    vall = this.getPropertyByString(this.base01Detailform.section[data.sectionindex].sectiondetails[data.sectiondetailindex].children[data.Bindex],data.checkstr)
                }
                if(data.rowtype == "C"){
                    vall = this.getPropertyByString(this.base01Detailform.section[data.sectionindex].sectiondetails[data.sectiondetailindex].children[data.Bindex].children[data.Cindex],data.checkstr)
                }
                if(data.rowtype == "D" || data.rowtype == "E" || data.rowtype == "F" || data.rowtype == "G" || data.rowtype == "H"){
                    vall = this.getPropertyByString(this.base01Detailform.section[data.sectionindex].sectiondetails[data.sectiondetailindex].children[data.Bindex].children[data.Cindex].children[data.Dindex],data.checkstr)
                }
                if(vall != undefined){
                    data.value = vall;
                }
            },
            checkrdmatch(data,dr){
                return this.$training.RG_Direction_Match(data,dr.inc_pattern,dr.exc_pattern,dr.format_pattern)
            },
            getformat(dr){
                const data = {};
                var inc_pattern = null;
                var exc_pattern = null;
                var format_pattern = null;
                if(dr.includerule != null && dr.includerule != ""){
                    inc_pattern = new RegExp(dr.includerule,"i");
                }
                if(dr.excluderule != null && dr.excluderule != ""){
                    exc_pattern = new RegExp(dr.excluderule,"i");
                }
                if(dr.formatrule != null && dr.formatrule != ""){
                    format_pattern = new RegExp(dr.formatrule,"i");
                }
                data.format_pattern = format_pattern;
                data.inc_pattern = inc_pattern;
                data.exc_pattern = exc_pattern;
                return data;
            },

            getCellValueAndRegexRules(data, cellvalue) {
                if (data.includerule != null && data.includerule != "") {
                    data.inc_pattern = new RegExp(data.includerule, "i");
                }

                if (data.excluderule != null && data.excluderule != "") {
                    data.exc_pattern = new RegExp(data.excluderule, "i");
                }

                if (data.formatrule != null && data.formatrule != "") {
                    data.format_pattern = new RegExp(data.formatrule, "i");
                }

                data.enteredvalue = cellvalue;

                return data;
            },

            getdirectionoptionwithid(FormData,dr){
                const option = JSON.parse(JSON.stringify({
                    visible: true,
                    sectionname:"",
                    isedit:false,
                    id:FormData.id,
                    boxtype:"Small 2 Part Pop up",
                    content:FormData.message,
                    mediacontent:FormData.mediacontent,
                    link:FormData.link,
                    offercloseoption:FormData.offercloseoption,
                    heading:FormData.directionname,
                    directiondegree:FormData.directiondegree,
                    direction:FormData,
                    usedata:false,

                    enteredvalue: FormData.enteredvalue,
                    celltype: FormData.celltype,
                    objectpath: FormData.objectpath,
                    checkvalue: FormData.checkvalue,
                    operation: FormData.operation,

                }));
                Object.assign(option, dr);
                this.$set(option,"uniqueid",1)
                this.$set(option,"elementid",FormData.pagecell.cellname)
                return option;
            },
            getdirectionoption(FormData,dr,indexobj,checkstr){
                const option = JSON.parse(JSON.stringify({
                    visible: true,
                    sectionname:"",
                    isedit:false,
                    id:FormData.id,
                    boxtype:"Small 2 Part Pop up",
                    content:FormData.message,
                    mediacontent:FormData.mediacontent,
                    link:FormData.link,
                    offercloseoption:FormData.offercloseoption,
                    heading:FormData.directionname,
                    directiondegree:FormData.directiondegree,
                    direction:FormData,
                    usedata:true,

                    enteredvalue: FormData.enteredvalue,
                    celltype: FormData.celltype,
                    objectpath: FormData.objectpath,
                    checkvalue: FormData.checkvalue,
                    operation: FormData.operation,

                }));
                this.$set(option,'checkstr',checkstr);
                this.$set(option,'sectionindex',indexobj.sectionindex);
                if(indexobj.sectiondetailindex != undefined){
                    this.$set(option,'sectiondetailindex',indexobj.sectiondetailindex);
                }
                if(indexobj.Bindex != undefined){
                    this.$set(option,'Bindex',indexobj.Bindex);
                }
                if(indexobj.Cindex != undefined){
                    this.$set(option,'Cindex',indexobj.Cindex);
                }
                if(indexobj.Dindex != undefined){
                    this.$set(option,'Dindex',indexobj.Dindex);
                }
                this.$set(option,"uniqueid",indexobj.uniqueid);
                this.$set(option,"rowtype",indexobj.rowtype);
                Object.assign(option, dr);
                if(indexobj.rowtype == "section"){
                    this.$set(option,"elementid",FormData.pagecell.cellname+indexobj.sectionindex);       
                }
                if(indexobj.rowtype == "sectiondetail"){
                    this.$set(option,"elementid",FormData.pagecell.cellname+indexobj.sectionindex+indexobj.sectiondetailindex);
                }
                if(indexobj.rowtype == "B"){
                    this.$set(option,"elementid",FormData.pagecell.cellname+indexobj.sectionindex+indexobj.sectiondetailindex+indexobj.Bindex);
                }
                if(indexobj.rowtype == "C"){
                    this.$set(option,"elementid",FormData.pagecell.cellname+indexobj.sectionindex+indexobj.sectiondetailindex+indexobj.Bindex+indexobj.Cindex)
                }
                if(indexobj.rowtype == "D" || indexobj.rowtype == "E" || indexobj.rowtype == "F" || indexobj.rowtype == "G" || indexobj.rowtype == "H"){
                    this.$set(option,"elementid",FormData.pagecell.cellname+indexobj.sectionindex+indexobj.sectiondetailindex+indexobj.Bindex+indexobj.Cindex+indexobj.Dindex)
                }
             option.validatedirectionsingle = (data)=>{this.validatedirectionsingle(data)}
             
             return option;
            },
            findinchildren(data){
                var errors = [];
                if(data.children != undefined && data.children.length > 0){
                    data.children.forEach((ch,chindex) => {
                        errors.push(ch);
                        var newarraor = this.findinchildren(ch);
                        errors.push(...newarraor);
                    });
                }
                return errors;
            },
            setindex(uniqueid,rowtype,sind,sdind,Bindex,Cindex,Dindex){
                var indexobj = {};
                indexobj.uniqueid = uniqueid;
                indexobj.rowtype = rowtype;
                if(sind != undefined)
                    indexobj.sectionindex = sind;
                if(sdind != undefined)
                    indexobj.sectiondetailindex = sdind;
                if(Bindex != undefined)
                    indexobj.Bindex = Bindex;
                if(Cindex != undefined)
                    indexobj.Cindex = Cindex;
                if(Dindex != undefined)
                    indexobj.Dindex = Dindex;
                return indexobj
            },
            rowexpand(refobj,row,checkrow){
                if(checkrow == false){
                    if(refobj != undefined){
                       refobj.toggleRowExpansion(row,true);
                    }
                }
                return true;
            },

            getcellmatcheddirection(direction, directionidvalue) {
                let matcheddirection = direction.directioncelldetails.find(x => x.pagecell.cellname == directionidvalue);
                let dir = JSON.parse(JSON.stringify(direction));
                if (matcheddirection) {
                    dir.includerule = matcheddirection.includerule;
                    dir.excluderule = matcheddirection.excluderule;
                    dir.formatrule = matcheddirection.formatrule;
                    dir.pagecell = matcheddirection.pagecell;
                    dir.pagecellid = matcheddirection.pagecellid;

                    dir.checkvalue = matcheddirection.checkvalue;
                    dir.operation = matcheddirection.operation;
                    dir.celltype = matcheddirection.pagecell.celltype;
                    dir.objectpath = matcheddirection.pagecell.objectpath;

                    return dir;
                }
            },

            validatedirection(directions,callback){
                var sectionexp = false;
                var sdexp = false;
                var aexp = false;
                var bexp = false;
                // var cexp = false;
                let $tablesection = this.$refs.sectiontable;
                let $tablesectiondetail = this.$refs.sectiondetail;
                let $tableAscope = this.$refs.Ascope;
                let $tableBscope = this.$refs.Bscope;
                // let $tableCscope = this.$refs.Cscope;
                var errors = [];

                for (let i = 0; i < directions.length; i++) {
                const direction = directions[i];

                var templatedr = this.getcellmatcheddirection(direction, this.directionids[0]);    // direction.find(x => x.pagecell.cellname == this.directionids[0]);
                var sectionnamedr = this.getcellmatcheddirection(direction, this.directionids[1]);    // direction.find(x => x.pagecell.cellname == this.directionids[1]);
                var activityAdr = this.getcellmatcheddirection(direction, this.directionids[2]);    // direction.find(x => x.pagecell.cellname == this.directionids[2]);
                var activityALRdr = this.getcellmatcheddirection(direction, this.directionids[3]);    // direction.find(x => x.pagecell.cellname == this.directionids[3]);
                var activityBdr = this.getcellmatcheddirection(direction, this.directionids[4]);    // direction.find(x => x.pagecell.cellname == this.directionids[4]);
                var activityBLRdr = this.getcellmatcheddirection(direction, this.directionids[5]);    // direction.find(x => x.pagecell.cellname == this.directionids[5]);
                var activityCdr = this.getcellmatcheddirection(direction, this.directionids[6]);    // direction.find(x => x.pagecell.cellname == this.directionids[6]);
                var activityCLRdr = this.getcellmatcheddirection(direction, this.directionids[7]);    // direction.find(x => x.pagecell.cellname == this.directionids[7]);
                const findobj = {};
                findobj.activityDdr = this.getcellmatcheddirection(direction, this.directionids[8]); // direction.find(x => x.pagecell.cellname == this.directionids[8]);
                findobj.activityEdr = this.getcellmatcheddirection(direction, this.directionids[9]); // direction.find(x => x.pagecell.cellname == this.directionids[9]);
                findobj.activityFdr = this.getcellmatcheddirection(direction, this.directionids[10]);  // direction.find(x => x.pagecell.cellname == this.directionids[10]);
                findobj.activityGdr = this.getcellmatcheddirection(direction, this.directionids[11]);  // direction.find(x => x.pagecell.cellname == this.directionids[11]);
                findobj.activityHdr = this.getcellmatcheddirection(direction, this.directionids[12]);  // direction.find(x => x.pagecell.cellname == this.directionids[12]);

                findobj.activityDLRdr = this.getcellmatcheddirection(direction, this.directionids[13]);  // direction.find(x => x.pagecell.cellname == this.directionids[13]);
                findobj.activityELRdr = this.getcellmatcheddirection(direction, this.directionids[14]);  // direction.find(x => x.pagecell.cellname == this.directionids[14]);
                findobj.activityFLRdr = this.getcellmatcheddirection(direction, this.directionids[15]);  // direction.find(x => x.pagecell.cellname == this.directionids[15]);
                findobj.activityGLRdr = this.getcellmatcheddirection(direction, this.directionids[16]);  // direction.find(x => x.pagecell.cellname == this.directionids[16]);
                findobj.activityHLRdr = this.getcellmatcheddirection(direction, this.directionids[17]);  // direction.find(x => x.pagecell.cellname == this.directionids[17]);

                findobj.activityDlibrarydr = this.getcellmatcheddirection(direction, this.directionids[18]);  // direction.find(x => x.pagecell.cellname == this.directionids[18]);
                findobj.activityElibrarydr = this.getcellmatcheddirection(direction, this.directionids[19]);  // direction.find(x => x.pagecell.cellname == this.directionids[19]);
                findobj.activityFlibrarydr = this.getcellmatcheddirection(direction, this.directionids[20]);  // direction.find(x => x.pagecell.cellname == this.directionids[20]);
                findobj.activityGlibrarydr = this.getcellmatcheddirection(direction, this.directionids[21]);  // direction.find(x => x.pagecell.cellname == this.directionids[21]);
                findobj.activityHlibrarydr = this.getcellmatcheddirection(direction, this.directionids[22]);  // direction.find(x => x.pagecell.cellname == this.directionids[22]);

                findobj.activityDunitdr = this.getcellmatcheddirection(direction, this.directionids[23]);  // direction.find(x => x.pagecell.cellname == this.directionids[23]);

                findobj.activityEurdr = this.getcellmatcheddirection(direction, this.directionids[24]);  // direction.find(x => x.pagecell.cellname == this.directionids[24]);
                findobj.activityFurdr = this.getcellmatcheddirection(direction, this.directionids[25]);  // direction.find(x => x.pagecell.cellname == this.directionids[25]);

                    /*
                let cellobjarray = [
                    { cellname: "template", templatedr: templatedr },
                ];
                let iscellrulesmatched = false; 
                */

                if(templatedr){
                    let dr = this.getformat(templatedr);
                    let ddata = this.getCellValueAndRegexRules(templatedr, this.base01Detailform.projectname);

                    if(this.base01Detailform.projectname != null && this.$directionutility.directionvaluematch(ddata) == false){
                        errors.push(this.getdirectionoptionwithid(templatedr,dr)); 
                    }
                }
                var uniqueid = 0;
                if(this.base01Detailform.section != undefined){
                    this.base01Detailform.section.forEach((s,sind) => {

                        /*
                        cellobjarray = [
                            { cellname: "sectionname", sectionnamedr: sectionnamedr}
                        ];
                        iscellrulesmatched = false; 
                        */

                        sectionexp = false;
                        if(sectionnamedr){
                            let dr = this.getformat(sectionnamedr);
                            let ddata = this.getCellValueAndRegexRules(sectionnamedr, s.sectionname);

                                 if(s.sectionname && this.$directionutility.directionvaluematch(ddata) == false){
                                    const indexobj = this.setindex(uniqueid,"section",sind)
                                  const option = this.getdirectionoption(sectionnamedr,dr,indexobj,"sectionname");
                                  errors.push(option);
                                  uniqueid ++;
                                  
                                 }
                        }
                            if(s.sectiondetails != undefined){
                                s.sectiondetails.forEach((sd,sdind)=>{

                                    /*
                                    cellobjarray = [
                                        { cellname: "activityA", activityAdr: activityAdr },
                                        { cellname: "activityALR", activityALRdr: activityALRdr },
                                    ];
                                    iscellrulesmatched = false;
                                    */

                                    sdexp = false;
                                    if(activityAdr){
                                        let dr = this.getformat(activityAdr);
                                        let ddata = this.getCellValueAndRegexRules(activityAdr, sd.activity);

                                        if(sd.activity && this.$directionutility.directionvaluematch(ddata) == false){
                                            const indexobj = this.setindex(uniqueid,"sectiondetail",sind,sdind)
                                        const option = this.getdirectionoption(activityAdr,dr,indexobj,"activity");
                                        errors.push(option);
                                        uniqueid ++;
                                        sectionexp = this.rowexpand($tablesection,s,sectionexp);
                                        }
                                    }
                                    if(activityALRdr){
                                        let dr = this.getformat(activityALRdr);
                                        let ddata = this.getCellValueAndRegexRules(activityALRdr, sd.LRs);

                                        if(sd.LRs && this.$directionutility.directionvaluematch(ddata) == false){
                                            const indexobj = this.setindex(uniqueid,"sectiondetail",sind,sdind)
                                        const option = this.getdirectionoption(activityALRdr,dr,indexobj,"LRs");
                                        errors.push(option);
                                        uniqueid ++;
                                        sectionexp = this.rowexpand($tablesection,s,sectionexp);
                                        }
                                    }    
                                    if(sd.children != undefined){
                                        sd.children.forEach((chB,Bindex) => {

                                            /*
                                            cellobjarray = [
                                                { cellname: "activityB", activityBdr: activityBdr },
                                                { cellname: "activityBLR", activityBLRdr: activityBLRdr },
                                            ];
                                            iscellrulesmatched = false;
                                            */

                                            if(activityBdr){
                                                let dr = this.getformat(activityBdr);
                                                let ddata = this.getCellValueAndRegexRules(activityBdr, chB.activity);

                                                if(chB.activity && this.$directionutility.directionvaluematch(ddata) == false){
                                                    const indexobj = this.setindex(uniqueid,"B",sind,sdind,Bindex)
                                                const option = this.getdirectionoption(activityBdr,dr,indexobj,"activity");
                                                errors.push(option);
                                                sectionexp = this.rowexpand($tablesection,s,sectionexp);
                                                sdexp = this.rowexpand($tablesectiondetail,sd,sdexp);
                                                uniqueid ++;
                                                }
                                            }
                                            if(activityBLRdr){
                                                let dr = this.getformat(activityBLRdr);
                                                let ddata = this.getCellValueAndRegexRules(activityBLRdr, chB.LRs);

                                                if(chB.LRs && this.$directionutility.directionvaluematch(ddata) == false){
                                                    const indexobj = this.setindex(uniqueid,"B",sind,sdind,Bindex)
                                                const option = this.getdirectionoption(activityBLRdr,dr,indexobj,"LRs");
                                                errors.push(option);
                                                uniqueid ++;
                                                sectionexp = this.rowexpand($tablesection,s,sectionexp);
                                                sdexp = this.rowexpand($tablesectiondetail,sd,sdexp);
                                                }
                                            }  
                                            if(chB.children != undefined){
                                                    chB.children.forEach((chC,Cindex) => {
                                                        if(activityCdr){
                                                            let dr = this.getformat(activityCdr);
                                                            let ddata = this.getCellValueAndRegexRules(activityCdr, chC.activity);

                                                            if(chC.activity && this.$directionutility.directionvaluematch(ddata) == false){
                                                                const indexobj = this.setindex(uniqueid,"C",sind,sdind,Bindex,Cindex)
                                                            const option = this.getdirectionoption(activityCdr,dr,indexobj,"activity");
                                                            errors.push(option);
                                                            uniqueid ++;
                                                            sectionexp = this.rowexpand($tablesection,s,sectionexp);
                                                            sdexp = this.rowexpand($tablesectiondetail,sd,sdexp);
                                                            aexp = this.rowexpand($tableAscope,chB,aexp);
                                                            }
                                                        }
                                                        if(activityCLRdr){
                                                            let dr = this.getformat(activityCLRdr);
                                                            let ddata = this.getCellValueAndRegexRules(activityCLRdr, chC.LRs);

                                                            if(chC.LRs && this.$directionutility.directionvaluematch(ddata) == false){
                                                                const indexobj = this.setindex(uniqueid,"C",sind,sdind,Bindex,Cindex)
                                                            const option = this.getdirectionoption(activityCLRdr,dr,indexobj,"LRs");
                                                            errors.push(option);
                                                            uniqueid ++;
                                                            sectionexp = this.rowexpand($tablesection,s,sectionexp);
                                                            sdexp = this.rowexpand($tablesectiondetail,sd,sdexp);
                                                            aexp = this.rowexpand($tableAscope,chB,aexp);
                                                            }
                                                        } 
                                                        if(chC.children != undefined){
                                                             
                                                             chC.children.forEach((chD,Dindex) => {
                                                                
                                                                if(findobj[`activity${chD.rowtype}dr`] ){
                                                                     let dr = this.getformat(findobj[`activity${chD.rowtype}dr`]);
                                                                     let ddata = this.getCellValueAndRegexRules(findobj[`activity${chD.rowtype}dr`], chD.activity);

                                                                     if(chD.activity && this.$directionutility.directionvaluematch(ddata) == false){
                                                                         const indexobj = this.setindex(uniqueid,chD.rowtype,sind,sdind,Bindex,Cindex,Dindex)
                                                                         const option = this.getdirectionoption(findobj[`activity${chD.rowtype}dr`],dr,indexobj,"activity");
                                                                         errors.push(option);
                                                                         uniqueid ++;
                                                                         sectionexp = this.rowexpand($tablesection,s,sectionexp);
                                                                         sdexp = this.rowexpand($tablesectiondetail,sd,sdexp);
                                                                         bexp = this.rowexpand($tableBscope,chC,bexp);
                                                                     }
                                                                 } 
                                                                if(findobj[`activity${chD.rowtype}unitdr`] ){
                                                                    
                                                                     let dr = this.getformat(findobj[`activity${chD.rowtype}unitdr`]);
                                                                     let ddata = this.getCellValueAndRegexRules(findobj[`activity${chD.rowtype}unitdr`], chD.unit);

                                                                     if(chD.unit && this.$directionutility.directionvaluematch(ddata) == false){
                                                                        const indexobj = this.setindex(uniqueid,chD.rowtype,sind,sdind,Bindex,Cindex,Dindex)
                                                                        const option = this.getdirectionoption(findobj[`activity${chD.rowtype}unitdr`],dr,indexobj,"unit");
                                                                        errors.push(option);
                                                                        uniqueid ++;
                                                                        sectionexp = this.rowexpand($tablesection,s,sectionexp);
                                                                         sdexp = this.rowexpand($tablesectiondetail,sd,sdexp);
                                                                         bexp = this.rowexpand($tableBscope,chC,bexp);
                                                                     }
                                                                 } 
                                                                 if(findobj[`activity${chD.rowtype}urdr`] ){
                                                                    
                                                                     let dr = this.getformat(findobj[`activity${chD.rowtype}urdr`]);
                                                                     let ddata = this.getCellValueAndRegexRules(findobj[`activity${chD.rowtype}urdr`], chD.URS);

                                                                     if(chD.URS && this.$directionutility.directionvaluematch(ddata) == false){
                                                                        const indexobj = this.setindex(uniqueid,chD.rowtype,sind,sdind,Bindex,Cindex,Dindex)
                                                                        const option = this.getdirectionoption(findobj[`activity${chD.rowtype}urdr`],dr,indexobj,"URS");
                                                                        errors.push(option);
                                                                        uniqueid ++;
                                                                        sectionexp = this.rowexpand($tablesection,s,sectionexp);
                                                                         sdexp = this.rowexpand($tablesectiondetail,sd,sdexp);
                                                                         bexp = this.rowexpand($tableBscope,chC,bexp);
                                                                     }
                                                                 } 
                                                                 if(findobj[`activity${chD.rowtype}librarydr`] ){
                                                                     let dr = this.getformat(findobj[`activity${chD.rowtype}librarydr`]);
                                                                     let ddata = this.getCellValueAndRegexRules(findobj[`activity${chD.rowtype}librarydr`], chD.method);

                                                                     if(chD.method && this.$directionutility.directionvaluematch(ddata) == false){
                                                                        const indexobj = this.setindex(uniqueid,chD.rowtype,sind,sdind,Bindex,Cindex,Dindex)
                                                                        const option = this.getdirectionoption(findobj[`activity${chD.rowtype}librarydr`],dr,indexobj,"method");
                                                                        errors.push(option);
                                                                        uniqueid ++;
                                                                        sectionexp = this.rowexpand($tablesection,s,sectionexp);
                                                                         sdexp = this.rowexpand($tablesectiondetail,sd,sdexp);
                                                                         bexp = this.rowexpand($tableBscope,chC,bexp);
                                                                     }
                                                                 } 
                                                                
                                                                 if(findobj[`activity${chD.rowtype}LRdr`] ){
                                                                     let dr = this.getformat(findobj[`activity${chD.rowtype}LRdr`]);
                                                                     let ddata = this.getCellValueAndRegexRules(findobj[`activity${chD.rowtype}LRdr`], chD.LRs);

                                                                     if(chD.LRs && this.$directionutility.directionvaluematch(ddata) == false){
                                                                        const indexobj = this.setindex(uniqueid,chD.rowtype,sind,sdind,Bindex,Cindex,Dindex)
                                                                        const option = this.getdirectionoption(findobj[`activity${chD.rowtype}LRdr`],dr,indexobj,"LRs");
                                                                        errors.push(option);
                                                                        uniqueid ++;
                                                                        sectionexp = this.rowexpand($tablesection,s,sectionexp);
                                                                         sdexp = this.rowexpand($tablesectiondetail,sd,sdexp);
                                                                         bexp = this.rowexpand($tableBscope,chC,bexp);
                                                                     }
                                                                 } 
                                                             });
                                                         }
                                                        
                                                    });
                                                }
                                        });
                                    }           
                                })
                            }
                    });
                }
            }
                if(errors.length > 0){
                    this.$store.state.directionsdialogue = errors;
                    this.$store.state.currentdirectionindex = 0;
                    this.$store.state.currentdirection = errors[0];
                }else{
                    callback();
                }
            },
            getsection(){
                this.base01Detailform.nrmelementid
                // Get NRM section for each page
                if(this.base01Detailform.nrmelementid != undefined){
                    return this.base01Detailform.nrmelementid;
                }else{
                    return "";
                }
            },
            cellchanged(cellaction,callback,cancall){
                if(cancall){
                    callback();
                    this.canmatchcellid(()=>{},cellaction,this.getsection(),true,(array1)=>{this.validatedirection(array1,callback)} );
                }else{
                    this.canmatchcellid(callback,cellaction,this.getsection(),true,(array1)=>{this.validatedirection(array1,callback)});
                }
            },
            handelselecttemplate(data,event,name){
                if(event){
                    data[name] = event.id;
                    this.$store.state.checklistids[name] = event.id;
                    data[name+'name'] = event.projectname;
                    this.$store.state.checklistids[name+'name'] =  event.projectname; 
                }else{
                    this.$set(data,name+'name',"");
                    this.$set(data,name,null);
                    this.$set(this.$store.state.checklistids,name,null);
                    this.$set(this.$store.state.checklistids,name+'name',"");
                }
                var newgrid = JSON.parse(JSON.stringify(this.grid));
                this.grid = JSON.parse(JSON.stringify(newgrid)); 
            },
            changepagename(data){
                this.$set(data,'cta',[]);
            },
            saveopenonbox(data){
                if(data.id){
                     var obj = {
                         qutime:data.qutime,
                         pageactions:[],
                     };
                     obj.qutime.elementlistsectionid = data.id;
                     var sendarray = [];
                     data.pages.forEach(p => {
                         p.cta.forEach((a)=>{
                             if(a.ischeck){
                                 sendarray.push({pagename:p.pagename,actionname:a.actionname});
                             }
                         })
                     });
                     obj.pageactions = sendarray;
                    //  this.loading = true;
                    this.$store.state.isLoading = true;

                     this.$http.post('ElementList/savequtimeform',obj)
                         .then(response => {
                            //  this.loading = false;
                            this.$store.state.isLoading = false;
                             this.$message({
                               message: 'saved successfully.',
                               type: 'success'
                            });
                             this.getchecklistsections(data);
                             // this.editpageaction = false;
                             // this.getcheckliststemplatepageaction();
                         }).catch(err => {
                            //  this.loading = false;
                            this.$store.state.isLoading = false;
                             this.$bus.$emit('error', err);
                         });
                }else{
                    this.$message({
                               message: 'Please save Checklists Section first.',
                               type: 'warning',
                               howClose: true,
                            });
                }
            },
            openonbox(data){
                if(!data.pages){
                    this.$set(data,'pages',JSON.parse(JSON.stringify(this.pages)));
                }
                this.getchecklistsections(data);
            },
            tagedit(tag,value,id,array){
                this.$set(tag,'isedit',value);
                this.$nextTick(_=>{
                    if(this.$refs["input_" + id][0])
                    {
                        this.$refs["input_" + id][0].focus()
                    }
                })
                if(!value){
                    if(!tag.actionname){
                      array.splice(id, 1);
                    }
                }
            },
            validatepageaction(){
                var isvalide = true;
                var errors = [];
                var isstr = this.pages.find(x => x.pagename == undefined);
                if(isstr != undefined)
                {
                    isvalide = false;
                    errors.push("Page name should not be empty!");
                }
                if(isvalide){
                     var isdup =  this.pages.find((nnn, index) =>{
                         return this.pages.find((x, ind)=> x.pagename.trim() === nnn.pagename.trim() && index !== ind )
                     })
                     if(isdup != undefined)
                     {
                         isvalide = false;
                         errors.push("Page Name <strong>"+isdup.pagename+"</strong> is duplicate");
                     }
                }
                if(isvalide)
                {
                    this.pages.forEach(e => {
                        if(e.cta.length <= 0){
                            isvalide = false;
                            errors.push("please add Actions in <strong>"+ e.pagename +"</strong> Page");
                        }
                        var ismattwo =  e.cta.find((nnn, index) =>{
                                return e.cta.find((x, ind)=> x.actionname.trim() === nnn.actionname.trim() && index !== ind )
                            })  
                            if(ismattwo != undefined){
                                isvalide = false;
                                errors.push("Action Name <strong>"+ismattwo.actionname+"</strong> is duplicate in <strong>"+ e.pagename +"</strong> Page"); 
                            }    
                    });
                }
                if(!isvalide){
                    var style = "<style> .el-message-box {width:30%;} </style>";
                this.$alert(errors.join("</br>")+style, "Warning", {
                    dangerouslyUseHTMLString: true,
                });
                }
                return isvalide;
            },
            savepageaction(){
                if(this.validatepageaction()){
                    //  this.loading = true;
                     this.$store.state.isLoading = true;

                     this.$http.post('ElementList/savecheckliststemplatepageaction',this.pages)
                         .then(response => {
                            //  this.loading = false;
                            this.$store.state.isLoading = false;
                             this.editpageaction = false;
                             this.getcheckliststemplatepageaction();
                         }).catch(err => {
                            //  this.loading = false;
                            this.$store.state.isLoading = false;
                             this.$bus.$emit('error', err);
                         });
                }
            },
            addpage(){
                var tt = {page:"",cta:[]};
                this.pages.push(tt);
            },
            handleDelete(array,tag) {
                var index = array.findIndex((x) =>  x.id == tag)
                array.splice(index, 1);
            },

            showInput(row) {
                this.$set(row,'inputVisible',true);
                this.$nextTick(_ => {
                    if(this.$refs["saveTagInput"])
                    {
                        this.$refs["saveTagInput"].focus()
                    }
                });
            },

            handleInputConfirm(array,row) {
                let inputValue = row.inputvalue;
                if (inputValue) {
                    array.push({actionname:inputValue,});
                }
                row.inputVisible = false;
                row.inputvalue = '';
            },
            getchecklistsections(data){
                if(data.id){
                   var obj = {elementlistsectionid:data.id};
                //    this.loading = true;
                this.$store.state.isLoading = true;

                   this.$http.post('ElementList/getchecklistsections',obj)
                       .then(response => {
                           var dbdata = response.data.cstpa;
                           response.data.pageactions.forEach(p=>{
                               var pagemachind =  dbdata.findIndex(x => x.pagename == p.pagename);
                               if(pagemachind != -1 && dbdata[pagemachind].cta != undefined){
                                  var isnewfind =  dbdata[pagemachind].cta.findIndex(x => x.actionname == p.actionname);
                                  if(isnewfind != -1){
                                   dbdata[pagemachind].cta[isnewfind].ischeck = true;
                                  }
                               }
                           })
                           this.$set(data,'qutime',response.data.qutime);
                           this.$set(data,'pages',dbdata);
                        //    this.loading = false;
                        this.$store.state.isLoading = false;
                           
                       }).catch(err => {
                        //    this.loading = false;
                        this.$store.state.isLoading = false;

                           this.$bus.$emit('error', err);
                       });
                }else{
                    this.$message({
                               message: 'Please save Checklists Section first.',
                               type: 'warning',
                               howClose: true,
                            });
                    if(data.qutime == undefined){
                      this.$set(data,'qutime',{});
                    }
                }
            },
            getcheckliststemplatepageaction(){
                // this.loading = true;
                this.$store.state.isLoading = true;

                this.$http.get('ElementList/getcheckliststemplatepageaction')
                    .then(response => {
                        // var json = JSON.parse(response.data);
                        this.pages = response.data;
                        // this.loading = false;
                        this.$store.state.isLoading = false;
                        
                    }).catch(err => {
                        // this.loading = false;
                        this.$store.state.isLoading = false;
                        this.$bus.$emit('error', err);
                    });
            },
            openEditPage(){
                this.getcheckliststemplatepageaction();
                this.editpageaction = true;
            },
            addzerosinLR(data,action){
                var intval = null;
                if(action == "int" && data.LR != null)
                {
                    this.$set(data,"LRs",data.LR.toString());
                }
                    var intval = parseInt(data.LRs);
                if(Number.isInteger(intval) && intval > 0){
                    var t = data.LRs.padStart(3, '0')
                    this.$set(data,'LRs',t);
                    this.$set(data,'LR',intval);
                
                }else{
                   this.$set(data,'LRs',"");
                   this.$set(data,'LR',null);
                }
            },
            answerchange(event,data,cobj){
                 var t = JSON.parse(JSON.stringify(cobj.children));
                 this.$set(cobj,'children',t);

            },
            changerowtype(row,type)
            {
                    row.rowtype = type;
            },
             applyclick: function(row){
                if (row.activity == "" || row.activity == null) {
                    row.activity = row.sourcelink;
                }
                this.islinkedit = false;
            },
            linkinputchange: function(row){
                    if (row.sourcelink != "") {
                        this.isbtndisable = false;
                    } else {
                        this.isbtndisable = true;
                    }
                },
              commentorlinkfocus: function(row){
                if (row.activity == "" || row.sourcelink == "") {
                    this.islinkedit = true;
                    this.isbtndisable = true;
                } else {
                    this.islinkedit = false;
                }
            },
             commentorlinkchange: function(row, checkvalue){
                if (checkvalue == "isactivity") {
                    this.canedit();
                }
                if (checkvalue == "islink") {
                    this.isbtndisable = false;
                }
            },
            removeidfromchildren(data,action){
                if(!data.children || data.children.length == 0)
                {
                    return data;
                }else{

                    data.children.forEach((c,index) => {
                        if(action == "emptyid"){
                          c.id = 0;
                          c.parentid = 0;
                        }
                        if(action == "convertjson",c.rowtype == 'F')
                        {
                              if(c.answer){
                                c.answerarr = JSON.parse(c.answer);
                                c.answerarroption = [];
                                c.answer = "";
                              }
                        }
                        if(action == "copysection")
                        {
                             c.id = 0;
                            c.parentid = 0;
                             if (c.activity != null) {
                                  c.activity += " - Copy";
                              }
                        }
                        c.srno = index + 1;
                        this.addzerosinLR(c,"int");
                        this.removeidfromchildren(c,action);
                    });
                }
            },
            Addsection(index,row, addorcopy ){
                var rowdata = {};
                if (addorcopy == 1) {
                    let item = { sectionname:'', sectiondetails:this.newsectiondetails, srno:1 };
                    rowdata = JSON.parse(JSON.stringify(item));
                } else {
                    rowdata =  JSON.parse(JSON.stringify(row));
                    rowdata.sectionname += " Copy"
                }
                  rowdata.qutime = {};
                  rowdata.pages = undefined;
                  rowdata.edited = true;
                  rowdata.id = 0
                  rowdata.sectiondetails.forEach((t,i) => {
                     t.id = 0;
                     t.parentid = 0;
                     t.srno = i +1;
                      this.addzerosinLR(t,"int");
                    this.removeidfromchildren(t,'emptyid');
                  });
                  this.$nextTick(function(){
                  if(row.sectiondetails && row.sectiondetails.length > 0)
                  {
                    this.base01Detailform.section.splice(index+1,0,rowdata)
                  }else{
                    row.sectiondetails = JSON.parse(JSON.stringify(this.newsectiondetails));
                  }
                  this.setSectionIndex();
                   let $table = this.$refs.sectiontable;
                     if($table)
                     {
                         $table.toggleRowExpansion(row)
                     }
                  })
            },
            canedit(){
                this.isedit = true;
            },
            goback: function () {
                this.base01Detailform.lavalOneid = parseInt(this.grid.baseOne);
                this.$http.post('ElementList/checkduplicatSection',this.base01Detailform)
                    .then(response => {
                        var answer = true;
                        if (this.isedit) {
                            var answer = window.confirm(
                                "Do you really want to leave? you have unsaved changes!"
                            );
                        }
                        if (answer == true) {
                                this.isedit = false;
                                this.detail = false;
                                this.base01Detail = false;
                                this.base02Detail = false;
                                this.base03Detail = false;
                                this.base01Detailform = {
                                    elementTemplateType: '',
                                    section: []
                                }
                        }
                    }).catch(error => {
                         if (error.response != null && typeof (error.response.data) != "object") 
                         {
                            var style = '<style> .el-message-box {width:60vw;} .el-message-box__message {max-height:60vh; overflow:auto} </style>'
                            this.$alert(error.response.data + style, "", {
                                dangerouslyUseHTMLString: true
                            });
                         }else{
                            this.$bus.$emit('error', err);
                         }
                    });
              

            },

            addRowSection: function (sectionname) {

                //var itm = { sectionName: sectionname, id: this.base01Detailform.elementListSection.section.length + 1, sectionDetails: [{ srno: 1, date: new Date().toISOString().slice(0, 10), activity: '', method: '', unit: '', rowtype: 'A' }] };
                if (sectionname == null) {
                    this.$message.error('Please enter section name!');
                    return;
                }
                this.canedit();
                var itm = { sectionname: sectionname, srno: this.base01Detailform.section.length + 1, sectiondetails: [{ srno: 1, Date: new Date().toISOString().slice(0, 10), activity: '', method: '', unit: '', rowtype: 'C' }] };
                itm.edited = true;
                // this.elementlistsectionrow.rowtype = "C";
                this.base01Detailform.section.push(itm);
            },
            createNewBase01: function () {
                this.base01Detailform.elementTemplateType = 1;
                // this.addRow();
                this.CreateStructureTemplates();
                this.base01Detail = true;
                this.detail = true;
            },
            CreateStructureTemplates(){
                var item = {
                    sectionname:'',
                    sectiondetails:this.newsectiondetails,
                    srno:1,
                };
                //  var t = Object.assign(item);
                this.base01Detailform.section = [];
                this.base01Detailform.section.push(JSON.parse(JSON.stringify(item)));
            },
            createNewBase02: function () {
                if (this.grid.baseOne == null || this.grid.baseOne == "") {
                    this.$message.error('Please select Base 01 template!');
                    return;
                }

                this.getTemplate(this.grid.baseOne, 2);
                // this.addRow();
                this.base01Detail = true;
                this.detail = true;
            },
            createNewBase03: function () {
                if (this.grid.basetwo == null || this.grid.basetwo == "") {
                    this.$message.error('Please select Base 02 template!');
                    return;
                }

                this.getTemplate(this.grid.basetwo, 3);
                // this.addRow();
                this.base01Detail = true;
                this.detail = true;
            },
            editBase01: function () {

                if (this.grid.baseOne == null || this.grid.baseOne == "") {
                    this.$message.error('Please select template to edit!');
                    return;
                }
                this.getTemplateForEdit(this.grid.baseOne);
                // this.addRow();
                this.base01Detail = true;
                this.detail = true;
            },
            editBase02: function () {
                if (this.grid.basetwo == null || this.grid.basetwo == "") {
                    this.$message.error('Please select template to edit!');
                    return;
                }
                this.getTemplateForEdit(this.grid.basetwo);
                // this.addRow();
                this.base01Detail = true;
                this.detail = true;
            },
            editBase03: function () {
                if (this.grid.basethree == null || this.grid.basethree == "") {
                    this.$message.error('Please select template to edit!');
                    return;
                }
                this.getTemplateForEdit(this.grid.basethree);
                // this.addRow();
                this.base01Detail = true;
                this.detail = true;
            },
            copybasetemplates: function (name, templateType) {
                if (this.grid[name] == null || this.grid[name] == "") {
                    this.$message.error('Please select template to Copy!');
                    return;
                }
                var self = this;
                // self.loading = true;
                this.$store.state.isLoading = true;

                var id = this.grid[name];
                this.$http.post("elementlist/GetBaseTemplate?id=" + id, {})
                    .then(response => {
                        // var json = JSON.parse(response.data);

                        self.base01Detailform = response.data;
                        self.base01Detailform.id = 0;
                        if(templateType == 3)
                        {
                            self.base01Detailform.formtype = "";
                            self.base01Detailform.nrmelementid = "";
                        }
                        if (self.base01Detailform.projectname != null) {
                            self.base01Detailform.projectname += " - Copy";
                        }
                        self.base01Detailform.elementTemplateType = templateType;
                         if (self.base01Detailform.section == null || self.base01Detailform.section == 0) {
                            var item = {
                                sectionname: '',
                                sectiondetails: self.newsectiondetails,
                                srno: 1,
                            };
                            self.base01Detailform.section.push(JSON.parse(JSON.stringify(item)));
                        }
                        // if (self.base01Detailform.elementlistVCs != null) {
                        //     self.base01Detailform.elementlistVCs.forEach(item => {
                        //         item.id = 0;
                        //         if (item.description != null) {
                        //             item.description += " - Copy";
                        //         }
                        //     });
                        // }
                        if (self.base01Detailform.section != null) {
                            self.base01Detailform.section.forEach(item => {
                                item.id = 0;
                                if (item.sectionname != null) {
                                    item.sectionname += " - Copy";

                                }
                                if (item.sectiondetails != null) {
                                    item.sectiondetails.forEach(d => {
                                        d.id = 0;
                                        d.sectionid = 0;
                                        if (d.activity != null) {
                                            d.activity += " - Copy";
                                        }
                                         this.addzerosinLR(d,"int");
                                         this.removeidfromchildren(d,'copysection')
                                    });
                                }
                            });
                        }
                        // self.loading = false;
                        this.$store.state.isLoading = false;
                        this.base01Detail = true;
                        this.detail = true;
                    }).catch(err => {
                        // self.loading = false;
                        this.$store.state.isLoading = false;
                        self.$bus.$emit('error', err);
                    });
            },
            // getusers: function () {
            //     this.loading = true;
            //     this.$http.get('account/getallusers')
            //         .then(response => {
            //             var json = JSON.parse(response.data);
            //             this.users = json;
            //             this.loading = false;
            //         }).catch(err => {
            //             this.loading = false;
            //             this.$bus.$emit('error', err);
            //         });
            // },
            getTemplate: function (parentId, templateType) {
                var self = this;
                // self.loading = true;
                this.$store.state.isLoading = true;

                var id = parentId;
                this.$http.post("elementlist/GetBaseTemplate?id=" + id, {})
                    .then(response => {
                        // var json = JSON.parse(response.data);
                        self.base01Detailform = response.data;
                        self.base01Detailform.id = 0;
                        self.base01Detailform.parentTemplateId = id;
                        this.base01Detailform.elementTemplateType = templateType;

                        // if (self.base01Detailform.elementlistVCs != null) {
                        //     self.base01Detailform.elementlistVCs.forEach(item => {
                        //         item.id = 0;
                        //     });
                        // }
                        if (self.base01Detailform.section == null || self.base01Detailform.section == 0) {
                            var item = {
                                sectionname: '',
                                sectiondetails: self.newsectiondetails,
                                srno: 1,
                            };
                            self.base01Detailform.section.push(JSON.parse(JSON.stringify(item)));
                        }
                        if (self.base01Detailform.section != null) {
                            self.base01Detailform.section.forEach(item => {
                                item.id = 0;

                                if (item.sectiondetails != null) {
                                    item.sectiondetails.forEach((t,index) => {
                                        t.id = 0;
                                        t.parentid = 0;
                                        t.srno = index +1;
                                         this.addzerosinLR(t,"int");
                                        this.removeidfromchildren(t,'emptyid')
                                    });
                                }
                            });
                        }

                        // self.loading = false;
                        this.$store.state.isLoading = false;
                    }).catch(err => {
                        // self.loading = false;
                        this.$store.state.isLoading = false;
                        self.$bus.$emit('error', err);
                    });
            },
            getTemplateForEdit: function (templateId) {
                // this.loading = true;
                this.$store.state.isLoading = true;

                var id = templateId;
                this.$http.post("elementlist/GetBaseTemplate?id=" + id, {})
                    .then(response => {
                        // var json = JSON.parse(response.data);
                        this.base01Detailform = response.data;
                        this.base01Detailform.elementTemplateType = this.base01Detailform.elementtemplatetype;
                        // this.loading = false;
                        this.$store.state.isLoading = false;

                        if (this.base01Detailform.section == null || this.base01Detailform.section.length == 0) {
                            var item = {
                                sectionname: '',
                                sectiondetails: this.newsectiondetails,
                                srno: 1,
                            };
                            this.base01Detailform.section.push(JSON.parse(JSON.stringify(item)));
                        }
                         if (this.base01Detailform.section != null) {
                            this.base01Detailform.section.forEach(item => {
                                if (item.sectiondetails != null) {
                                    item.sectiondetails.forEach((t,index) => {
                                         this.addzerosinLR(t,"int");
                                        this.removeidfromchildren(t,'convertjson')
                                    });
                                }
                            });
                        }

                    }).catch(err => {
                        // this.loading = false;
                        this.$store.state.isLoading = false;
                        this.$bus.$emit('error', err);
                    });
            },
            querysearchelementlist(type,q,done){
                if(!q)
                {
                    q = "";
                }
                // this.loading = true;
                this.$store.state.isLoading = true;

                this.$http.post("elementlist/querysearchelementlist?query="+q+"&type="+type,{})
                    .then(response => {
                        done(response.data)
                        // this.loading = false;
                        this.$store.state.isLoading = false;
                    }).catch(err => {
                        // this.loading = false;
                        this.$store.state.isLoading = false;
                        this.$bus.$emit('error', err);
                    });
            },
            getBaseOne: function () {
                // this.loading = true;
                this.$store.state.isLoading = true;
                this.$http.get('elementlist/getallbaseone')
                    .then(response => {
                        var json = JSON.parse(response.data);
                        this.baseones = json;
                        // this.loading = false;
                        this.$store.state.isLoading = false;
                    }).catch(err => {
                        // this.loading = false;
                        this.$store.state.isLoading = false;
                        this.$bus.$emit('error', err);
                    });
            },
            onClearBase01: function (event,istrue) {
                this.basetwos = [];
                this.basethrees = [];
                this.grid.parentTemplateLastModified = '';
                this.grid.parentTemplateAuther = '';
                
                this.grid.basetwo = "";
                this.grid.parentTwoTemplateLastModified = '';
                this.grid.parentTwoTemplateAuther = '';
                

                this.grid.basethree = "";
                this.grid.parentThreeTemplateLastModified = '';
                this.grid.parentThreeTemplateAuther = '';
                
                if(istrue == true){
                this.grid.baseOne = "";
                this.$store.state.checklistids.baseOne = undefined;
                this.$store.state.checklistids.basetwo = undefined;    
                this.$store.state.checklistids.basethree = undefined;
                }
            },
            onClearBase02: function (istrue) {
                this.basethrees = [];
                this.grid.basethree = "";
                this.grid.parentTwoTemplateLastModified = '';
                this.grid.parentTwoTemplateAuther = '';
                if(istrue == true){
                this.grid.basetwo = "";
                this.$store.state.checklistids.basetwo = undefined;    
                this.$store.state.checklistids.basethree = undefined;
                }
            },
            onClearBase03: function () {
                this.grid.parentThreeTemplateLastModified = '';
                this.grid.parentThreeTemplateAuther = '';
                this.grid.basethree = "";
                this.$store.state.checklistids.basethree = undefined;
            },
            dateformatter: function (value) {
                if (value) {
                    return moment(value).format("DD-MMM-YYYY HH:mm");
                }
                return value;
            },
            onSearchBaseTwo: function () {
                this.onClearBase01();
                var self = this;
                // self.loading = true;
                this.$store.state.isLoading = true;

                var id = undefined;
                if(self.grid.baseOne == "" && self.$store.state.checklistids.baseOne != undefined){
                    id =  self.$store.state.checklistids.baseOne;
                    self.grid.baseOne = id;
                }else{
                    id = self.grid.baseOne;
                    self.$store.state.checklistids.baseOne = id;
                }
                 
                
                this.$http.post('elementlist/GetBaseTwoTemplate?baseOneId=' + id, {})
                    .then(response => {
                        if (response.data.length > 0) {
                            self.grid.parentTemplateLastModified = this.dateformatter(response.data[0].parentTemplateLastModified);
                            self.grid.parentTemplateAuther = response.data[0].parentTemplateAuther;

                            self.basetwos = response.data[1].Child;
                            self.grid.parentTwoTemplateLastModified = this.dateformatter(response.data[1].parentTemplateLastModified);
                            self.grid.parentTwoTemplateAuther = response.data[1].parentTemplateAuther;

                            self.grid.parentThreeTemplateLastModified = this.dateformatter(response.data[2].parentTemplateLastModified);
                            self.grid.parentThreeTemplateAuther = response.data[2].parentTemplateAuther;
                        }
                        var isfind =  self.basetwos.find(x => x.id == self.$store.state.checklistids.basetwo);
                         if(self.$store.state.checklistids.basetwo != undefined && isfind)
                         {
                            self.onSearchBaseThree();
                         }else{
                            self.$store.state.checklistids.basetwo = undefined;
                         }   
                        // self.loading = false;
                        this.$store.state.isLoading = false;
                    }).catch(err => {
                        // self.loading = false;
                        this.$store.state.isLoading = false;
                        self.$bus.$emit('error', err);
                    });
            },
            onSearchBaseThree: function () {
                this.onClearBase02();
                var self = this;
                // self.loading = true;
                this.$store.state.isLoading = true;

                // var id = self.grid.basetwo;
                // this.$store.state.checklistids.basetwo = id; 

                var id = undefined;
                if(self.grid.basetwo == "" && self.$store.state.checklistids.basetwo != undefined){
                    id =  self.$store.state.checklistids.basetwo;
                    self.grid.basetwo = id;
                }else{
                    id = self.grid.basetwo;
                    self.$store.state.checklistids.basetwo = id;
                }
                this.$http.post('elementlist/GetBaseThreeTemplate?baseTwoId=' + id, {})
                    .then(response => {
                        if (response.data.length > 0) {
                            self.grid.parentTwoTemplateLastModified = this.dateformatter(response.data[0].parentTemplateLastModified);
                            self.grid.parentTwoTemplateAuther = response.data[0].parentTemplateAuther;
                            self.basethrees = response.data[1].Child;
                            self.grid.parentThreeTemplateLastModified = this.dateformatter(response.data[1].parentTemplateLastModified);
                            self.grid.parentThreeTemplateAuther = response.data[1].parentTemplateAuther;
                        }
                        var isfind =  self.basethrees.find(x => x.id == self.$store.state.checklistids.basethree);
                        if(self.$store.state.checklistids.basethree != undefined && isfind)
                         {
                            self.onChangeBaseThree();
                         }else{
                            self.$store.state.checklistids.basethree = undefined;
                         }   
                        // self.loading = false;
                        this.$store.state.isLoading = false;
                    }).catch(err => {
                        // this.loading = false;
                        this.$store.state.isLoading = false;
                        this.$bus.$emit('error', err);
                    });
            },
            onChangeBaseThree: function () {
                var self = this;
                // self.loading = true;
                this.$store.state.isLoading = true;

                // var id = self.grid.basethree;
                // this.$store.state.checklistids.basethree = id;

                var id = undefined;
                if(self.grid.basethree == "" && self.$store.state.checklistids.basethree != undefined){
                    id =  self.$store.state.checklistids.basethree;
                    self.grid.basethree = id;
                }else{
                    id = self.grid.basethree;
                    self.$store.state.checklistids.basethree = id;
                }
                this.$http.post('elementlist/GetBaseThreeTemplateDetail?id=' + id, {})
                    .then(response => {
                        if (response.data.length > 0) {
                            self.grid.parentThreeTemplateLastModified = this.dateformatter(response.data[0].parentTemplateLastModified);
                            self.grid.parentThreeTemplateAuther = response.data[0].parentTemplateAuther;
                        }
                        // self.loading = false;
                        this.$store.state.isLoading = false;
                    }).catch(err => {
                        // this.loading = false;
                        this.$store.state.isLoading = false;
                        this.$bus.$emit('error', err);
                    });
            },
            // addRow: function (copy) {
            //     var itm = {};
            //     if (copy) {
            //         if (this.base01Detailform.elementlistVCs.length > 0) {
            //             var last = this.base01Detailform.elementlistVCs[this.base01Detailform.elementlistVCs.length - 1];
            //              last = JSON.parse(JSON.stringify(last));
            //             last.id = 0;
            //             if (last.description) {
            //                 last.description += " - Copy";
            //             }

            //             itm = last;
            //         }
            //     }
            //     this.base01Detailform.elementlistVCs.push(itm);
            //     //this.changed();
            // },
            setsrnochildren(obj){
                if(obj.children && obj.children.length > 0)
                {

                    obj.children.forEach((s,i) => {
                           s.srno = i + 1;
                           if(s.rowtype === 'F')
                           {
                             s.answer = JSON.stringify(s.answerarr);
                           }
                            if(s.children && s.children.length > 0){
                               this.setsrnochildren(s);
                            }
                    });
                }
            },
            setsrno(){
                    this.base01Detailform.section.forEach((es,si) => {
                        if(!es.srno){es.srno = 0}
                        es.srno = si + 1;
                        es.sectiondetails.forEach((sd , i) => {
                            if(!sd.srno){sd.srno = 0}
                            sd.srno = i + 1;
                            this.setsrnochildren(sd);
                        });
                    });
            },
            saveBase1: function () {
                this.setsrno();
                this.base01Detailform.lavalOneid = parseInt(this.grid.baseOne); 
                this.$http.post('elementlist/save', this.base01Detailform)
                    .then(response => {
                        this.$message({
                            showClose: true,
                            message: "Save Successful", type: "success"
                        });
                        this.isedit = false;
                        // this.detail = false;
                        this.getTemplateForEdit(response.data.id);
                        // this.base01Detailform = response.data;
                        // this.base01Detailform.changed = false;
                         this.refresh();
                    })
                    .catch(error => {
                         if (error.response != null && typeof (error.response.data) != "object") 
                         {
                            var style = '<style> .el-message-box {width:60vw;} .el-message-box__message {max-height:60vh; overflow:auto} </style>'
                            this.$alert(error.response.data + style, "", {
                                dangerouslyUseHTMLString: true
                            });
                         }else{
                            this.$bus.$emit('error', err);
                         }
                    });


            },
            refresh: function () {
                // Get the base form 1
                // this.getBaseOne();
                // this.onSearchBaseTwo();
                // this.onSearchBaseThree();
                        if(this.$store.state.checklistids.basethree){
                    this.grid.basethree = this.$store.state.checklistids.basethree;
                    this.grid.basethreename = this.$store.state.checklistids.basethreename;
                    }
                    if(this.$store.state.checklistids.basetwo){
                    this.grid.basetwo = this.$store.state.checklistids.basetwo;
                    this.grid.basetwoname = this.$store.state.checklistids.basetwoname;
                    }
                    if(this.$store.state.checklistids.baseOne){
                    this.grid.baseOne = this.$store.state.checklistids.baseOne;
                    this.grid.baseOnename = this.$store.state.checklistids.baseOnename;
                    }
                    var newgrid = JSON.parse(JSON.stringify(this.grid));
                    this.grid = JSON.parse(JSON.stringify(newgrid)); 
            },
            mastertablecellClassName({ row, column, rowIndex, columnIndex }){
                if(column.label == "Library")
                {
                    return{"border-left":"2px #b9afaf solid !important", "background":"#014f46", "color": "white"}
                }
                if(columnIndex == 6)
                {return{"font-size": "12px"}}

                return {"background":"#014f46", "color": "white"}
            },
            tablecellClassName: function ({ row, column, rowIndex, columnIndex }) {
               if(row){
                if (row.rowtype === 'A') {
                    // if(columnIndex == 6)
                    // {
                    //     return{
                    //          background: "lightgray",
                    //            color: "black",
                    //          "font-size": "12px"
                    //     }
                    // }
                    if(column.label == "Library")
                    {
                      return {
                        background: "#027869",
                        color: "white",
                        "border-left":"2px #b9afaf solid !important"
                      };    
                    }
                    return {
                     background: "#027869",
                     color: "white",
                   };

                }else if (row.rowtype === 'B') {
                    //  if(columnIndex == 6)
                    // {
                    //     return{
                    //          background: "#f0f9eb",
                    //          color: "black",
                    //          "font-size": "12px"
                    //     }
                    // }
                     if(column.label == "Library")
                    {
                      return {
                        background: "#03cfb5",
                        color: "black",
                        "border-left":"2px #b9afaf solid !important"
                      };    
                    }
                    return {
                     background: "#03cfb5",
                     color: "black"
                   };
                }else if (row.rowtype === 'C') {
                    //  if(columnIndex == 6)
                    //  {
                    //      return{
                    //           background: "oldlace",
                    //           color: "rad",
                    //           "font-size": "17px"
                    //      }
                    //  }
                    if(column.label == "Library")
                    {
                      return {
                        background: "#0ffdf4",
                        color: "black",
                        "border-left":"2px #b9afaf solid !important",
                        
                      };    
                    }
                    return {
                     background: "#0ffdf4",
                     color: "black"
                   };
                } else if (row.rowtype !== 'A' && row.rowtype !== 'B' && row.rowtype !== 'C') {
                    //  if(columnIndex == 6)
                    //  {
                    //      return{
                    //           background: "#f8f8ff",
                    //           color: "black",
                    //           "font-size": "12px"
                    //      }
                    //  }
                    if(column.label == "Library")
                    {
                      return {
                         background: "#f8f8ff",
                         color: "black",
                        "border-left":"2px #b9afaf solid !important",
                         "padding-left":"4px"
                      };    
                    }
                    return {
                     background: "#f8f8ff",
                     color: "black"
                   };
                }
               }
                 return '';
            },
            // masterTableRowClassName({ row, rowIndex }) {
            //     return 'section-format';
            // },
            // delRow: function (index, row) {
            //     this.canedit();
            //     this.base01Detailform.elementlistVCs.splice(index, 1);
            //     var srno = 1;
            //     for (var i = 0; i < this.base01Detailform.elementlistVCs.length; i++) {
            //         this.base01Detailform.elementlistVCs[i].srno = srno++;
            //     }
            // },
            setSectionIndex(){
                this.base01Detailform.section.forEach((i,ind) => {
                    i.srno = ind + 1;
                });
            },
             delRowSection: function (index, row) {
                 this.canedit();
                this.base01Detailform.section.splice(index, 1);
                if(this.base01Detailform.section && this.base01Detailform.section.length < 1)
                {
                    this.CreateStructureTemplates();
                }
                this.setSectionIndex();
            },
            AddRowSectionDetail(row,data,array,index,ref, addorcopy){
                this.canedit();
                var newrowtype = '';
                if(row.rowtype == 'A'){newrowtype = "B"}
                if(row.rowtype == 'B'){newrowtype = "C"}
                if(row.rowtype == 'C'){newrowtype = "D"}
                if((!row.children || row.children.length == 0) && (row.rowtype == 'A' || row.rowtype == 'B' || row.rowtype == 'C') && addorcopy == 2)
                {
                    if(!row.children){row.children = [];}
                     this.canedit();
                        this.$nextTick(() => {
                         row.children.push({srno:1,id:0,rowtype:newrowtype,activity: '', method: '', unit: '',URS:'',answer:'',answerarr:[],answerarroption:[]});
                           let $table = this.$refs[ref];
                            if($table)
                            {
                                $table.toggleRowExpansion(row)
                            }
                            if($table)
                            {
                                $table.toggleRowExpansion(row)
                            }
                        });
                }else{
                    var ind;
                    if(data){ind = data.length + 1 }
                   var newdata = JSON.parse(JSON.stringify(row));
                   newdata.id = 0;
                   newdata.srno = ind;
                   newdata.activity += " copy";
                    this.addzerosinLR(newdata,"int");
                    if (addorcopy == 1) {
                        newdata.LR = null;
                        newdata.LRs = "";
                        newdata.activity = "";
                        newdata.children = [];
                    } else {
                        this.removeidfromchildren(newdata,'emptyid');
                    }
                    this.canedit();
                    this.$nextTick(() => {
                       data.splice(index+1,0,newdata)
                    });
                }
                if(array.sectiondetails && array.sectiondetails.length > 0)
                {
                     array.sectiondetails.forEach((sd , i) => {
                            if(!sd.srno){sd.srno = 0}
                            sd.srno = i + 1;
                        });
                }else if(array.children && array.children.length > 0){

                    array.children.forEach((sd , i) => {
                            if(!sd.srno){sd.srno = 0}
                            sd.srno = i + 1;
                        });
                }
            },
            delRowSectiondetail(index, data,row,ref){
                this.canedit();
                 this.$nextTick(()=>{
                     data.splice(index, 1);
                     if(data.length == 0){
                        let $table = this.$refs[ref];
                        if($table)
                        {
                            $table.toggleRowExpansion(row)
                        }
                     }
                 });
                  if(row && row.sectiondetails && row.sectiondetails.length >= 0){
                      row.sectiondetails.forEach((sd , i) => {
                            if(!sd.srno){sd.srno = 0}
                            sd.srno = i + 1;
                        });
                   }
                    if(row.children && row.children.length >= 0){
                      row.children.forEach((sd , i) => {
                            if(!sd.srno){sd.srno = 0}
                            sd.srno = i + 1;
                        });
                   }
            },
            removebase1(){
                this.$store.state.checklistids.baseOne = "";
                this.$store.state.checklistids.baseOnename = "";
                this.grid.baseOne = "";    
                this.grid.baseOnename = "";
            },
            removebase2(){
                this.$store.state.checklistids.basetwo = "";
                this.$store.state.checklistids.basetwoname = "";
                this.grid.basetwo = "";    
                this.grid.basetwoname = "";
            },
            removebase3(){
                this.$store.state.checklistids.basethree = "";
                this.$store.state.checklistids.basethreename = "";
                this.grid.basethree = "";    
                this.grid.basethreename = "";
            },
            deletetemplates: function(name) {
                if (this.grid[name] == null || this.grid[name] == "") {
                    this.$message.error('Please select template to Delete!');
                    return;
                }
                this.$confirm('This will permanently delete the Templat. Continue?', 'Warning', {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                    }).then(() => {
                        var id = this.grid[name];
                      this.$http.post("elementlist/deleteTemplate?id=" + id)
                      .then(response => {
                        this.$message({
                            showClose: true,
                            message: response.data, type: "success"
                        });
                        
                        if(name == "baseOne"){
                            this.removebase1();
                            this.removebase2();
                            this.removebase3();
                        }
                        if(name == "basetwo"){
                            this.removebase2();
                            this.removebase3();
                        }
                        if(name = "basethree"){

                            this.removebase3();
                        }

                        this.$store.state.checklistids[name] = undefined;
                        this.$store.state.checklistids[name+'name'] = "";
                        this.grid[name] = "";
                        this.grid[name+'name'] = "";
                        // this.$message({
                        //     type: 'success',
                        //     message: 'Delete completed'
                        // });
                        this.refresh();
                        })
                        .catch(err => {
                            this.$bus.$emit('error', err);
                        });
                    }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: 'Delete canceled'
                    });          
                    });
                // var self = this;
                // self.loading = true;
              
            }
        },
        beforeRouteLeave(to, from, next) {
        var answer = true;
        if (this.isedit) {
            var answer = window.confirm(
                "Do you really want to leave? you have unsaved changes!"
            );
        }
        if (answer == true) {
            next();
            window.onbeforeunload = null;
        } else {
            next(false);
        }

    },
    }
</script>

<style>
.section_input .el-input__inner{
    border:none;
    border-radius: 0%;
    /* background: white; */
    background: #014f46;
    color: white;
    font-weight: bold;
    font-size: 14px;
    padding-left: 0px !important;
}

.section_input .el-textarea__inner{
    border:none;
    border-radius: 0%;
    background: #014f46;
    color: white;
    padding: 5px 0px;
    font-weight: bold;
    font-size: 14px;
    overflow-y: hidden;
    resize: none;
}

.section_input .el-textarea__inner:hover{
    overflow-y: auto;
}

.row_color_A .el-input__inner{
     /* background: lightgray; */
     background: #027869;
     color: white;
     border: none;
     font-weight: bold;
     border-radius: 0%;
     font-size: 14px;
     padding-left: 0px !important;
}

.row_color_A .el-textarea__inner{
     background: #027869;
     color: white;
     padding: 5px 0px;
     border: none;
     font-weight: bold;
     border-radius: 0%;
     font-size: 14px;
     overflow-y: hidden;
     resize: none;
}

.row_color_A .el-textarea__inner:hover{
    overflow-y: auto;
}

/* .row_color_A .el-input__inner::placeholder {
    color:  red;
} */

.row_color_B .el-input__inner{
     /* background: #f0f9eb; */
     background: #03cfb5;
      color: black;
      font-weight: bold;
     border: none;
     border-radius: 0%;
     font-size: 13px;
     padding-left: 0px !important;
}

.row_color_B .el-textarea__inner{
     background: #03cfb5;
     color: black;
     padding: 5px 0px;
     border: none;
     font-weight: bold;
     border-radius: 0%;
     font-size: 13px;
     overflow-y: hidden;
     resize: none;
}

.row_color_B .el-textarea__inner:hover{
    overflow-y: auto;
}

/* .row_color_B .el-input__inner::placeholder {
    color:  red;
} */

.row_color_C .el-input__inner{
     /* background: oldlace; */
     background: #0ffdf4;
      color: black;
     border: none;
    font-weight: bold;
     border-radius: 0%;
     font-size: 12px;
     padding-left: 0px !important;
}

.row_color_C .el-textarea__inner{
     background: #0ffdf4;
     color: black;
     padding: 5px 0px;
     border: none;
     font-weight: bold;
     border-radius: 0%;
     font-size: 12px;
     overflow-y: hidden;
     resize: none;
}

.row_color_C .el-textarea__inner:hover{
    overflow-y: auto;
}

/* .row_color_C .el-input__inner::placeholder {
    color:  red;
} */

.row_color_D .el-input__inner{
     background: #f8f8ff;
      color: black;
         border: none;
      border-radius: 0%;
      font-size: 12px;
      padding-left: 0px !important;
}

.row_color_D .el-textarea__inner{
     background: #f8f8ff;
     color: black;
     padding: 5px 0px;
     border: none;
     border-radius: 0%;
     font-size: 12px;
     overflow-y: hidden;
     resize: none;
}

.row_color_D .el-textarea__inner:hover{
    overflow-y: auto;
}

.row_color_E .el-input__inner{
     background: #f8f8ff;
      color: black;
     border: none;
     border-radius: 0%;
     font-size: 12px;
     padding-left: 0px !important;
}

.row_color_E .el-textarea__inner{
     background: #f8f8ff;
     color: black;
     padding: 5px 0px;
     border: none;
     border-radius: 0%;                                                                                                                                                                                                                
     font-size: 12px;
     overflow-y: hidden;
     resize: none;
}

.row_color_E .el-textarea__inner:hover{
    overflow-y: auto;
}

.row_color_F .el-input__inner{
     background: #f8f8ff;
      color: black;
     border: none;
     border-radius: 0%;
     font-size: 12px;
     padding-left: 0px !important;

}

.row_color_F .el-textarea__inner{
     background: #f8f8ff;
     color: black;
     padding: 5px 0px;
     border: none;
     border-radius: 0%;
     font-size: 12px;
     overflow-y: hidden;
     resize: none;
}

.row_color_F .el-textarea__inner:hover{
    overflow-y: auto;
}

.row_color_G .el-input__inner{
     background: #f8f8ff;
      color: black;
     border: none;
     border-radius: 0%;
     font-size: 12px;
     padding-left: 0px !important;
}

.row_color_G .el-textarea__inner{
     background: #f8f8ff;
     color: black;
     padding: 5px 0px;
     border: none;
     border-radius: 0%;
     font-size: 12px;
     overflow-y: hidden;
     resize: none;
}

.row_color_G .el-textarea__inner:hover{
    overflow-y: auto;
}

.row_color_H .el-input__inner{
     background: #f8f8ff;
      color: black;
     border: none;
     border-radius: 0%;
     font-size: 12px;
     padding-left: 0px !important;
}

.row_color_H .el-textarea__inner{
     background: #f8f8ff;
     color: black;
     padding: 5px 0px;
     border: none;
     border-radius: 0%;
     font-size: 12px;
     overflow-y: hidden;
     resize: none;
}

.row_color_H .el-textarea__inner:hover{
    overflow-y: auto;
}

.row_color_H_2 .el-input__inner{
     background: #f8f8ff;
      color: black;
     border: none;
     border-radius: 0%;
     font-size: 9px;
     font-style: italic;
     padding-left: 0px !important;
}
    .el-form-item--mini.el-form-item, .el-form-item--small.el-form-item {
        margin-bottom: 3px !important;
    }

    div.var_crt + el-form-item {
        margin-left: 0px;
    }

    .el-row {
        margin-bottom: 0px;
    }
    .var_crt {
        background-color: lightgray;
        margin: 2px;
        padding: 2px;
        /* border: 1px solid; */
        font-weight: bold;
    }

    .el-form-item {
        margin-left: 20px;
    }

    .el-table .dark-gray-row {
        background: darkgray;
    }

    .el-table .light-gray-row {
        background: lightgrey;
    }
    .el-table .section-format {
        font-size: medium;
        font-weight: 600;
    }

    .el-col {
        border-radius: 4px;
    }

    .el-form-item {
        margin-left: 0px !important;
    }

    .bg-purple-dark {
        background: #99a9bf;
    }

    .bg-purple {
        background: #d3dce6;
    }

    .bg-purple-light {
        background: #e5e9f2;
    }

    .grid-content {
        border-radius: 4px;
        min-height: 36px;
    }

    .row-bg {
        padding: 10px 0;
        background-color: #f9fafc;
    }

    .el-form-item {
        margin-left: 0px;
        font-size: small;
        font-weight: bold;
    }

    .el-form-item--mini.el-form-item, .el-form-item--small.el-form-item {
        margin-bottom: 18px;
    }

    div.el-form-item__content {
        margin-left: 0px;
    }
    .custom_padding.el-table .cell{
        padding-left: 0px !important;
       padding-right: 4px !important;
    }
.dark-gray-input .el-input__inner {
  background: darkgray;
  color: black;
  border: none;

  border-radius: 0%;
}
.light-gray-input .el-input__inner {
  background: lightgrey;
  color: black;
  border: none;

  border-radius: 0%;
}
.padding7TB{
    padding: 5px !important;
}
.padding0TB .el-button--mini
{   padding: 0px 15px !important;

}
.section_table_header.el-table--mini .el-table__cell{
 padding: 0px 0;
}
.width677px{
    width: 349% !important;
}
.LR-input-fixwidth {
    width: 29px !important; /* or whatever width you want. */
    max-width: 29px !important; /* or whatever width you want. */
    /* padding: 1px; */
}
.border_left_1px{
    border-left: 1px #747474 solid !important;
}
 .el-tag + .el-tag {
    margin-left: 10px;
  }
  .button-new-tag {
    margin-left: 10px !important;
    
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
  }
  .input-new-tag {
    width: 90px !important;
    margin-left: 10px !important;
    vertical-align: bottom;
  }
</style>
