<template>
    <div class="criteriawidth">
        <div class="fixdheader">
            <span class="headerstyle">Independent Measure Template</span>
            <div class="floatright margintb1mm">

                    <el-button :disabled="!editpermission"
                               type="success"
                               id="setup_independentmeasuretemplate_save"
                               @click="cellchanged('setup_independentmeasuretemplate_save',()=>{save()},false)">
                               Save</el-button>
                <el-button icon="el-icon-back" type="info" id="setup_independentmeasuretemplate_back" @click="$router.go(-1)">Back</el-button>
            </div>
            <hr class="hrstyle" />
           <!-- <div class="custom-table" >
            <el-row>
           <div style="width:316px;padding-left: 5px;" class="left">Data Description</div>
           <div style="width:76px" class="left">UR</div>
           <div style="width:47px" class="left">Unit</div>
           <div style="width:283px" class="left">Terms on which Qty Calculated</div>
           </el-row>
           <hr class="custom-table-hr">
        </div> -->
        </div>
        <div class="tablepaddinglr3">
          <el-table-draggable :animate="600">
            <el-table :data="detailform"
            :show-header="true"
                       :height="height"
                      :row-class-name="tableRowClassName"
                      :cell-style="tablecellClassName"
                      class="tableBox fullwidth"
                      row-key="srno">
                <el-table-column prop="srno" label="Data Description" width="300">
                    <template slot-scope="scope">
                        <div v-if="scope.row.type == 'GP'" class="dark-gray-inputIMT">
                            <el-input size="mini"
                                      :spellcheck="isspellcheck"
                                      :readonly="!editpermission"
                                      placeholder="Group Name"
                                      :id="'setup_independentmeasuretemplate_group_name_'+scope.$index"
                                      v-model="scope.row.datadescription"></el-input>
                        </div>
                        <div v-if="scope.row.type == 'NRM'" class="nrm-inputIMT">
                            <el-input :spellcheck="isspellcheck"
                                      :readonly="!editpermission"
                                      placeholder="NRM Name"
                                      :id="'setup_independentmeasuretemplate_nrm_name_'+scope.$index"
                                      v-model="scope.row.datadescription"></el-input>
                        </div>
                        <div v-if="scope.row.type == 'MA'"
                             class="detail-input"
                             style="margin-left: 10px">
                            <el-row :gutter="20">
                                <el-col>
                                    <el-input :spellcheck="isspellcheck"
                                              :readonly="!editpermission"
                                              placeholder="Data Description"
                                              :id="'setup_independentmeasuretemplate_data_description_'+scope.$index"
                                              v-model="scope.row.datadescription"></el-input>
                                </el-col>
                            </el-row>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="ur" label="UR(S)" width="70">
                    <template slot-scope="scope">
                        <div v-if="scope.row.type == 'MA'" class="detail-input">
                                        <el-select v-model="scope.row.ur" clearable remote  :remote-method="getURsRemote" :id="'setup_independentmeasuretemplate_urs_'+scope.$index" reserve-keyword filterable  placeholder="UR(S)">
                                           <el-option
                                             v-for="(item, index) in $store.state.URs"
                                             :key="index"
                                             :label="item.ur"
                                             :value="item.ur">
                                             <!-- <div>
                                                 <div class="left">
                                                 {{item.ur}}
                                                 </div>
                                                 <div v-if="item.id != 0 " class="right ML13">
                                                 <i style="color:#67C23A" class="el-icon-circle-check"></i>
                                                 </div>
                                             </div> -->
                                           </el-option>
                                         </el-select>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="unit" label="Unit" width="65">
                    <template slot-scope="scope">
                        <div v-if="scope.row.type == 'MA'" class="detail-input">
                            <el-select v-model="scope.row.unit" placeholder="Unit" :id="'setup_independentmeasuretemplate_unit_'+scope.$index">
                                <el-option v-for="item in units" :disabled="!editpermission"
                                           :key="item"
                                           :label="item"
                                           :value="item">
                                </el-option>
                            </el-select>
                            <!-- <el-input placeholder="Unit" style=" width: 50%;" v-model="scope.row.unit"></el-input> -->
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="terms"
                                 label="Terms on which Qty Calculated">
                    <template slot-scope="scope" class="fullwidth">
                        <div v-if="scope.row.type == 'MA'" class="detail-input">
                            <el-input :spellcheck="isspellcheck"
                                      :readonly="!editpermission"
                                      :id="'setup_independentmeasuretemplate_terms_on_which_qty_calculated_'+scope.$index"
                                      placeholder="Terms on which Qty Calculated"
                                      v-model="scope.row.terms"></el-input>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column width="27">
                    <template slot-scope="scope">
                        <i v-if="editpermission "
                           class="el-icon-delete deleteicon"
                           :id="'setup_independentmeasuretemplate_delete_'+scope.$index"
                           @click="deleterow(scope.$index, scope.row)">
                        </i>
                    </template>
                </el-table-column>
            </el-table>
          </el-table-draggable>
        </div>
        <div class="spaceBetween"></div>
        <div>
            <span>
                <el-select filterable
                           placeholder="Data Description Types"
                           id="setup_independentmeasuretemplate_data_description_types"
                           v-model="type">
                    <el-option v-for="item in types" :disabled="!editpermission"
                               :key="item.label"
                               :label="item.label"
                               :value="item.value">
                    </el-option>
                </el-select>
            </span>
            <span>
                <el-button :disabled="!editpermission" icon="el-icon-circle-plus" id="setup_independentmeasuretemplate_add_data_description_types" type="primary" @click="addrow"></el-button>
            </span>
            <div class="spaceBetween" />
        </div>
    </div>
</template>
<script>
import ElTableDraggable from './CustomTableDraggable.vue';
export default {
  data() {
    return {
         height: (document.documentElement.clientHeight - 120),
         detailform: [],
         type: "",
         nrmsection: "",
         types: [
           { label: "Group", value: "GP" },
           { label: "NRM Component", value: "NRM" },
           { label: "Measurement", value: "MA" },
         ],
        units: this.$store.state.units,
    };
  },
//   computed:{
//       getur(){
//           return function(data){
//               if(data != ""  && isNaN(data)){
//                   data = parseInt(data);
//                   data = data.toString();
//                    var arr = [];
//                  while(arr.length < 8){
//                      var r = Math.floor(Math.random()*90000) + 10000;
//                      if(arr.indexOf(r) === -1 && r.toString().includes(data)) arr.push({id:0,ur:r});
//                  }
//               }
//             return arr;
//            }
//        },
//   },
  created: function () {
    this.getIMT();
  },
  components: {
    ElTableDraggable
  },
  methods: {
    // urchanged: function (row) {
    //   if (row.ur) {
    //        var orgur = row.ur;
    //        var dmyur = row.ur.replace(/-|\s/g,"");
    //         row.ur = dmyur;
    //      var regExp = /[1-9][0-9]{3}|[0-9]{3}[1-9]|[0-9][1-9][0-9]{2}|[0-9][0-9][1-9][0-9]/;
    //     if (!regExp.test(row.ur)) {
    //       row.ur = "";
    //       this.$alert(
    //         "Please Enter Unique Reference (UR) between 00-01 to 99-99.",
    //         "Warning",
    //         { type: "Warning" }
    //       );
    //     }else {
    //         row.ur = orgur;
    //    }
    //   }
    // },
    getsection(){
            // Get NRM section for each page
            if(false){
                // return this.detailform.section;
            }else{
                return "";
            }
        },
        cellchanged(cellaction,callback,cancall){
        if(cancall){
            callback();
            this.canmatchcellid(()=>{},cellaction,this.getsection());
        }else{
            this.canmatchcellid(callback,cellaction,this.getsection());
        }
    },
    deleterow: function (index, row) {
      this.$confirm(
        "This will permanently delete " + row.datadescription + "?",
        "Delete",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      ).then(() => {
        this.detailform.splice(index, 1);
        this.$message({
          showClose: true,
          type: "success",
          message: "" + row.datadescription + " deleted",
        })
      }).catch(() => {
          this.$message({
            type: 'info',
            message: 'Delete canceled'
          });
        });
    },
    getIMT: function () {
      this.$http
        .get("project/getIMT")
        .then((response) => {
          this.detailform = response.data.imt;
          this.$store.state.URs = [];
          this.$store.state.URd = response.data.urlist;
          // this.$nextTick(this.mymakesortable);
        })
        .catch((err) => {
          this.$bus.$emit("error", err);
        });
    },
    isvalid: function (editedrows) {
      var msg = [];
      for (var i = 0; i < this.detailform.length; i++) {
        var a = this.detailform[i];
        for (var j = i + 1; j < this.detailform.length; j++) {
          var b = this.detailform[j];
          if (
            a.ur == b.ur &&
            a.ur != null &&
            b.ur != null &&
            b.ur != "" &&
            a.ur != ""
          ) {
            var msgname = "UR No. " + a.ur + " is duplicate ";
            var check = msg.find((x) => x == msgname);
            if (!check) {
              msg.push(msgname);
            }
          }
        }
      }
      if (msg.length > 0) {
        this.$alert(msg.join("</br>"), "Invalid Data", {
          dangerouslyUseHTMLString: true,
        });
        return false;
      }
      return true;
    },
    save: function () {
      if (this.isvalid(this.detailform)) {
        var srno = 1;
        var uradd = [];
        this.detailform.forEach((v, i) => {
          v.srno = srno++;
          if(v.ur != "" && v.ur != undefined){
              var urr = this.$store.state.URd.find(x => x.ur == v.ur);
              if(!urr){
                uradd.push({id:0,ur:v.ur})
              }
          }
        });
        var data = {};
        data.imt = this.detailform;
        data.urlist = uradd;
        this.$http
          .post("project/saveIMT", data)
          .then((response) => {
            this.$message({
              showClose: true,
              message: response.data,
              type: "success",
            });
            this.getIMT();
          })
          .catch((err) => {
            this.$bus.$emit("error", err);
          });
      }
    },
    tablecellClassName: function ({ row, column, rowIndex, columnIndex }) {
      if (row.type == "GP") {
        return {
          background: "rgb(105, 104, 104)",
          color: "white",
        };
      }

      if (row.type == "NRM") {
        return "nrmclass";
      }
      if (row.type == "NRM") {
        return "";
      }
    },
    tableRowClassName({ row, rowIndex }) {
      if (row.type == "GP") {
        return "dark-gray-row";
      }
      if (row.type == "NRM") {
        return "nrmclass";
      }
      if (row.type == "MA") {
        return "";
      }
    },
    addrow: function () {
      if (this.type != "") {
        var sr = this.detailform.length + 1;
        var item = {
          type: this.type,
          srno: sr,
          datadescription: "",
          ur: "",
          unit: "",
          terms: "",
        };
        this.detailform.push(item);
      } else {
        this.$message({
          showClose: true,
          message: "Please Select Types First.",
          type: "warning",
        });
      }
    },
    mymakesortable: function () {
        if(this.editpermission){
      const table = document.querySelector(".el-table__body-wrapper tbody");
      const self = this;
      this.$sortable.create(table, {
        onEnd({ newIndex, oldIndex }) {
          const targetRow = self.detailform.splice(oldIndex, 1)[0];
          self.detailform.splice(newIndex, 0, targetRow);
        },
      });
     }
    },
     getheight() {
        this.height = (document.documentElement.clientHeight - 120);
    }
  },
    destroyed() {
       window.removeEventListener('resize', this.getheight);
   },
  mounted: function () {
      window.addEventListener('resize', this.getheight);
    this.$nextTick(() => {
      // Code that will run only after the
      // entire view has been rendered
    });
  },
};
</script>
<style >
/*.noexpand .el-icon {
        display: none;
    }*/
.types {
  background-color: rgb(228, 220, 220) !important;
  color: white;
  font-weight: bold;
}
.el-table .nrmclass {
  font-weight: bold;
  background: #f1f1f1;
  color: rgb(0, 0, 0);
}
.el-table .dark-gray-row {
  background: rgb(105, 104, 104);
  color: white;
}
/* // .el-table  .el-table__body  tr>td{
//           background-color: #1e71ff!important;
//  }

// .tableBox {
//   th {
//     padding: 0 !important;
//     height: 30px;
//     line-height: 30px;
//     padding-top: 0;
//     padding-bottom: 0;
//   }
//   td {
//     padding-top: 0;
//     padding-bottom: 0;
//     padding: 0 !important;
//     height: 20px;
//     line-height: 20px;
//   }
// } */

.tableBox.el-table--mini td, .el-table--mini th {
    padding: 0px 0;
}
.deleteicon {
  font-size: 15px;
  color: #f56c6c;
  font-weight: bold;
}
.dark-gray-inputIMT .el-input__inner {
  background: rgb(105, 104, 104);
  color: white;
  border: none;
  font-weight: bold;
  border-radius: 0%;
}
.nrm-inputIMT .el-input__inner {
  background: #f1f1f1;
  color: rgb(0, 0, 0);
  border: none;
  border-radius: 0%;
}
/* // .detail-input .el-input__inner{
//       background: rgb(255, 255, 255);
//      color: rgb(0, 0, 0);
//       border: none;
// } */
.ML13{
    margin-left: 13px;
}
</style>
