<template>
    <div >
        <div class="fixdheader">
        <el-row>
            <el-col :span="12" class="headerstyle"> CQC Report</el-col>
            <el-col :span="11" style="padding-right:6px;">
                <el-button :disabled="!editpermission" type="success" style="float: right; margin-top: 10px; padding-right: 12px; padding-left: 5px;" icon="el-icon-circle-check" @click="cellchanged('analysis_cqcreport_save',()=>{save()},false)"> Save</el-button>
            </el-col>
        </el-row>

        <projectselectpart @projectsectionchange="projectsectionchange" class="addrowspace"></projectselectpart>
        </div>
        <div v-loading="loading">
        <el-row class="addcomborder" v-if="detail == true" >
            <el-col :span="15">
                <div style="font-size: 12px; padding-top: 2px;">
                    <el-button type="danger" @click="changeprjsetcriteria('projectcriteria')" style="padding:7px 10.5px;">Project Criteria</el-button>
                    <!-- <span style="color: #a52a2a">Project Criteria &nbsp; &nbsp;</span> -->
                    <!-- Common: {{getcriteria('ComCriteria1id')}} {{getcriteria('ComCriteria2id')}} {{getcriteria('ComCriteria3id')}} {{getcriteria('ComCriteria4id')}}&nbsp; &nbsp;
                    <span v-show="showsubcriteria == true">Substructure: {{getcriteria('SubCriteria1id')}} {{getcriteria('SubCriteria2id')}} {{getcriteria('SubCriteria3id')}} {{getcriteria('SubCriteria4id')}}</span>
                    <span v-show="showsupcriteria == true">Superstructure: {{getcriteria('SupCriteria1id')}} {{getcriteria('SupCriteria2id')}} {{getcriteria('SupCriteria3id')}} {{getcriteria('SupCriteria4id')}}</span>
                    <span v-show="showextcriteria == true">Externals: {{getcriteria('ExtCriteria1id')}} {{getcriteria('ExtCriteria2id')}} {{getcriteria('ExtCriteria3id')}} {{getcriteria('ExtCriteria4id')}}</span>
                    &nbsp; &nbsp;<span v-show="showchunksize == true">Chunk Size: {{this.crtqty.qty}} {{this.crtqty.unit}} {{this.crtqty.crtname}}</span> -->
                    <!-- {{getcriterias(detailform.pdes)}} -->
                    <!-- &nbsp; &nbsp;<span v-show="showchunksize == true">Chunk Size: {{this.crtqty.qty}} {{this.crtqty.unit}} {{this.crtqty.crtname}}</span> -->
                    &nbsp;&nbsp;&nbsp;{{this.showsetdata}}
                </div>
                <el-row>
                    <div style="font-size: 12px; padding-top: 2px;">
                        <el-button type="danger" @click="changeprjsetcriteria('set1criteria')">Set 1 Criteria</el-button>
                        &nbsp;&nbsp;&nbsp;{{this.cqcreportprjcrt.showset1crtdata}}
                    </div>
                </el-row>

                <el-row>
                    <div style="font-size: 12px; padding-top: 2px;">
                        <el-button type="danger" @click="changeprjsetcriteria('set2criteria')">Set 2 Criteria</el-button>
                        &nbsp;&nbsp;&nbsp;{{this.cqcreportprjcrt.showset2crtdata}}
                    </div>
                </el-row>
            </el-col>

            <el-col :span="1">
                <h5><b>NOM</b></h5>
            </el-col>

            <el-col :span="1">
                <el-input v-model="crtqty.pspset" readonly :id="'analysis_cqcreport_nom'"></el-input>
            </el-col>

            <el-col :span="5" :offset="1">
                <el-row v-show="showcheck"> <el-checkbox v-model="checked" @change="showchangecrtmp">Show criteria matching projects &nbsp;</el-checkbox> </el-row>
                <el-row>
                    <el-col :span="19"><h5><b>Comparative Project Selector</b></h5></el-col>
                    <el-col :span="5"> <el-button type="primary" plain v-show="checked && crtmthprojects.length > 0" @click="Comparanextproject">Next ></el-button></el-col>
                </el-row>
                <projectselectpart :custom="custom" v-show="checked == false" :lastsavedshow="true" :projectplaceholder="cmpprojplaceholder" @projectsectionchange="cmpprojectsectionchange"></projectselectpart>
                <div v-show="checked">
                <el-row>
                    <el-select v-model="crtmthprojectname" value-key="id" placeholder="Select Project" :id="'analysis_cqcreport_select_project'" @change="getcrtmpsections" class="fullwidth padding2px bold">
                        <el-option
                        v-for="item in crtmthprojects"
                        :key="item.id"
                        :label="item.projectname"
                        :value="item">
                        </el-option>
                    </el-select>
                </el-row>

                <el-row>
                    <el-select v-model="crtmppartid" placeholder="Select Part" @change="changecrtmppart" :id="'analysis_cqcreport_select_part'" class="fullwidth padding2px bold">
                        <el-option
                        v-for="item in crtmpparts"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                        </el-option>
                    </el-select>
                </el-row>

                <el-row>
                    <el-select v-model="crtmpsectionname" placeholder="Select Section" @change="changecrtmpsection" :id="'analysis_cqcreport_select_section'" class="fullwidth padding2px bold">
                        <el-option
                        v-for="item in crtmpsections"
                        :key="item.id"
                        :label="item.sectiondisplay"
                        :value="item.id">
                        </el-option>
                    </el-select>
                </el-row>
                </div>
            </el-col>
        </el-row>


        <div v-if="detail == true" v-loading="loading">
            <el-row>
                <!-- <el-col :span="8">
                    <el-button style="padding-bottom: -40px; border-width: 3px; font-weight: bold" type="info" @click="expandcollapse">{{ expandcollapsetext }}</el-button>
                    <el-select v-model="projectdetail" clearable placeholder="Project Detail" class="padding2px">
                        <el-option
                            v-for="item in ['Full Details', 'Summary']"
                            :key="item"
                            :label="item"
                            :value="item">
                        </el-option>
                    </el-select>
                </el-col> -->

                <el-col :span="2" :offset="8">
                    <el-select v-model="searchtype" clearable placeholder="Filter Type" :id="'analysis_cqcreport_filtertype'" class="padding2px" style="width:95px; margin-left: 70px;">
                        <el-option
                            v-for="item in searchtypes"
                            :key="item"
                            :label="item"
                            :value="item">
                        </el-option>
                    </el-select>

                    <!-- <el-select v-model="psp" clearable placeholder="PSP" multiple class="padding2px">
                        <el-option v-for="item in psps"
                                :key="item"
                                :label="item"
                                :value="item">
                        </el-option>
                    </el-select> -->
                </el-col>
            </el-row>
            <hr class="hrstyle" />

            <!-- <h4 v-show="showcrtupmsg">{{crtunmthmsg}}</h4> -->
            <el-row :gutter="10">
                <el-col :span="18">
                    <el-table ref="cqctable" :data="getdetailform" class="fullwidth" :show-header="true" :expand-row-keys="expandedrows" row-key="id" @expand-change="expandchange" :header-cell-style="mergeheader" :cell-style="{ padding: '0', height: '35px' }" :border="true">
                        <el-table-column type="expand" width="35">
                            <template slot-scope="cqc" class="fullwidth" >
                                <el-row style="padding-left:34px;">
                                    <el-col :span="24">
                                        <el-table :data="cqc.row.cqclibrary.details"  row-key="id" :show-summary="true" sum-text="Any Sum text" :summary-method="(column, data) => { return showcqcsummary(cqc.row, column, data); }" :cell-style="{ padding: '0', height: '35px' }" class="boldsummary" :border="true">
                                            <el-table-column type="expand" width="35">
                                                <template slot-scope="cqcd" class="fullwidth">
                                                    <el-table :data="cqcd.row.projectcqcdetail.details" :border="true" header-cell-class-name="backcyan">
                                                         <el-table-column :label="cqcd.row.ids.nrmsectionSearchIn">
                                                            <el-table-column  label="Search In" :resizable="true">
                                                                <template>
                                                                    <div v-if="cqcd.row.ids.projectsection != undefined">
                                                                   {{cqcd.row.ids.projectsection.sectiondisplay}}
                                                                    </div>
                                                            </template>
                                                            </el-table-column>
                                                        </el-table-column>
                                                        <el-table-column :label="cqcd.row.ids.nrmsection">
                                                            <el-table-column prop="nrmsection" label="NRM1 Ordering" :resizable="true"></el-table-column>
                                                        </el-table-column>

                                                        <el-table-column :label="cqcd.row.ids.section">
                                                            <el-table-column prop="section" label="Section" :resizable="true"></el-table-column>
                                                        </el-table-column>

                                                        <el-table-column :label="cqcd.row.ids.group">
                                                            <el-table-column prop="group" label="Group" :resizable="true"></el-table-column>
                                                        </el-table-column>

                                                        <el-table-column :label="cqcd.row.ids.work">
                                                            <el-table-column prop="work" label="Work" :resizable="true"></el-table-column>
                                                        </el-table-column>

                                                        <el-table-column :label="cqcd.row.ids.level1">
                                                            <el-table-column prop="level1" label="Level 1" :resizable="true"></el-table-column>
                                                        </el-table-column>

                                                        <el-table-column :label="cqcd.row.ids.level2">
                                                            <el-table-column prop="level2" label="Level 2" :resizable="true"></el-table-column>
                                                        </el-table-column>

                                                        <el-table-column :label="cqcd.row.ids.level3">
                                                            <el-table-column prop="level3" label="Level 3" :resizable="true"></el-table-column>
                                                        </el-table-column>

                                                        <el-table-column :label="commaseparatednumber(cqcd.row.projectcqcdetail.prqty, 2)" align="right">
                                                            <el-table-column prop="qty" label="Qty" :resizable="true" align="right">
                                                                <template slot-scope="scope">
                                                                {{ commaseparatednumber(scope.row.qty, 2) }}
                                                                </template>
                                                            </el-table-column>
                                                        </el-table-column>

                                                        <el-table-column :label="cqcd.row.ids.unit">
                                                            <el-table-column prop="unit" label="Unit" :resizable="true"></el-table-column>
                                                        </el-table-column>
                                                    </el-table>

                                                    <el-table :data="[{},{}]" :header-cell-style="{ background: '#F5F7FA' }" border>
                                                        <el-table-column label="x =" align="center" width="60">
                                                            <template slot-scope="scope">
                                                                <div v-if="scope.$index == 0">{{ "x C =" }}</div>
                                                                <div v-if="scope.$index == 1">{{ "x D =" }}</div>
                                                            </template>
                                                        </el-table-column>

                                                        <el-table-column label="UR" align="center" width="60">
                                                            <template slot-scope="scope">
                                                                <div v-if="scope.$index == 0">{{cqcd.row.ur1}}</div>
                                                                <div v-if="scope.$index == 1">{{cqcd.row.ur2}}</div>
                                                            </template>
                                                        </el-table-column>

                                                        <el-table-column label="Description">
                                                            <template slot-scope="scope">
                                                                <div v-if="scope.$index == 0">{{cqcd.row.description1}}</div>
                                                                <div v-if="scope.$index == 1">{{cqcd.row.description2}}</div>
                                                            </template>
                                                        </el-table-column>

                                                        <el-table-column label="Comment">
                                                            <template slot-scope="scope">
                                                                <div v-if="scope.$index == 0">{{cqcd.row.comment1}}</div>
                                                                <div v-if="scope.$index == 1">{{cqcd.row.comment2}}</div>
                                                            </template>
                                                        </el-table-column>

                                                        <el-table-column label="Number" align="right" width="80">
                                                            <template slot-scope="scope">
                                                                <div v-if="scope.$index == 0">{{cqcd.row.imd1.qty}}</div>
                                                                <div v-if="scope.$index == 1">{{cqcd.row.imd2.qty}}</div>
                                                            </template>
                                                        </el-table-column>
                                                    </el-table>
                                                </template>
                                            </el-table-column>

                                            <el-table-column prop="srno" label="123" width="42" align="center" :resizable="true"></el-table-column>

                                            <el-table-column :show-overflow-tooltip="true" prop="ids.idsname" label="IDS Name" min-width="200">

                                            </el-table-column>

                                            <el-table-column prop="projectcqcdetail.prqty" label="Pr-Qty" min-width="50" :resizable="true" align="right">
                                                <template slot-scope="scope">
                                                {{ commaseparatednumber(scope.row.projectcqcdetail.prqty, 2) }}
                                                </template>
                                            </el-table-column>

                                            <el-table-column prop="notes" :show-overflow-tooltip="true" label="Notes" min-width="100" :resizable="true"></el-table-column>

                                            <el-table-column prop="adj1" label="Adj-1" min-width="50" :resizable="true" align="right">
                                                <template slot-scope="scope">
                                                {{ commaseparatednumber(scope.row.adj1, 2) }}
                                                </template>
                                            </el-table-column>

                                            <el-table-column prop="adj2" label="Adj-2" min-width="50" :resizable="true" align="right">
                                                <template slot-scope="scope">
                                                {{ commaseparatednumber(scope.row.adj2, 2) }}
                                                </template>
                                            </el-table-column>

                                            <!-- <el-table-column prop="adj3" label="Adj-3" min-width="60" :resizable="true" align="right">
                                                <template slot-scope="scope">
                                                    {{scope.row.adj3|to2decimal}}
                                                </template>
                                            </el-table-column> -->

                                            <el-table-column prop="projectcqcdetail.totala" label="Total A" min-width="50" :resizable="true" align="right">
                                                <template slot-scope="scope">
                                                {{ commaseparatednumber(scope.row.projectcqcdetail.totala, 2) }}
                                                </template>
                                            </el-table-column>

                                            <el-table-column prop="projectcqcdetail.totalb" label="Total B" min-width="50" :resizable="true" align="right">
                                                <template slot-scope="scope">
                                                {{ commaseparatednumber(scope.row.projectcqcdetail.totalb, 2) }}
                                                </template>
                                            </el-table-column>
                                        </el-table>

                                        <div :style="{height: cqc.row.cqclibrary.ownerpspheight + 'px'}">
                                        <el-row>
                                            <el-row>
                                                <el-col style="color:rgb(82 71 71); background-color:#D3D3D3; border-radius: 0px;padding-left:10px;">
                                                    <div class="bold">{{"Tolerances Set By Check Owner"}}</div>
                                                </el-col>
                                            </el-row>

                                            <!-- <el-col :span="12">
                                                <table class="fullwidth padding2px fixed">
                                                    <tbody>
                                                        <tr class="bold" style="text-align: center;" >
                                                            <td></td>
                                                            <td>{{getsortingtable(cqc.row,'in')[0].heder}}</td>
                                                            <td>{{getsortingtable(cqc.row,'in')[1].heder}}</td>
                                                            <td>{{getsortingtable(cqc.row,'in')[2].heder}}</td>
                                                            <td>{{getsortingtable(cqc.row,'in')[3].heder}}</td>
                                                        </tr>
                                                        <tr>
                                                            <td v-if="checkin(getsortingtable(cqc.row,'in'))" class="bold" ><el-tag type="success" class="tag-in-full">In</el-tag></td>
                                                            <td v-if="!checkin(getsortingtable(cqc.row,'in'))" class="bold"><el-tag type="warning" class="tag-check-full">Check</el-tag></td>

                                                            <td>&lt; &nbsp;{{getsortingtable(cqc.row,'in')[0].value}}</td>
                                                            <td>&lt;&nbsp;{{getsortingtable(cqc.row,'in')[1].value}}</td>
                                                            <td>&lt;&nbsp;{{getsortingtable(cqc.row,'in')[2].value}}</td>
                                                            <td>&lt;&nbsp;{{getsortingtable(cqc.row,'in')[3].value}}</td>
                                                        </tr>
                                                        <tr class="bold" style="text-align: center;" >
                                                            <td></td>
                                                            <td>{{getsortingtable(cqc.row,'check')[0].heder}}</td>
                                                            <td>{{getsortingtable(cqc.row,'check')[1].heder}}</td>
                                                            <td>{{getsortingtable(cqc.row,'check')[2].heder}}</td>
                                                            <td>{{getsortingtable(cqc.row,'check')[3].heder}}</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="bold "  v-if="checkin(getsortingtable(cqc.row,'check'))" ><el-tag type="success" class="tag-in-full">In</el-tag></td>
                                                            <td class="bold "  v-if="!checkin(getsortingtable(cqc.row,'check'))" ><el-tag type="warning" class="tag-check-full">Check</el-tag></td>

                                                            <td>&lt;&nbsp;{{getsortingtable(cqc.row,'check')[0].value}}</td>
                                                            <td>&lt;&nbsp;{{getsortingtable(cqc.row,'check')[1].value}}</td>
                                                            <td>&lt;&nbsp;{{getsortingtable(cqc.row,'check')[2].value}}</td>
                                                            <td>&lt;&nbsp;{{getsortingtable(cqc.row,'check')[3].value}}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </el-col> -->
                                            <el-col :span="20" :offset="2">
                                                <table class="fullwidth padding2px fixed">
                                                    <tbody>
                                                        <tr class="bold" style="text-align: center">
                                                            <th width="55"></th>
                                                            <th width="20%">A/B Ratio Statement</th>
                                                            <th>Min</th>
                                                            <th>Median</th>
                                                            <th>Max</th>
                                                            <th width="265">Actions</th>
                                                            <th>Range</th>
                                                        </tr>

                                                        <tr>
                                                            <td class="bold"><el-tag type="success" class="tag-in">In</el-tag></td>
                                                            <td rowspan="2"><el-input type="textarea" v-model="cqc.row.cqclibrary.abratiostatement" :id="'analysis_cqcreport_table_row_abratiostatement'+cqc.$index" :rows="3" readonly></el-input></td>
                                                            <td><el-input-number v-model="cqc.row.cqclibrary.tolerenceinab" class="disinput" :id="'analysis_cqcreport_table_row_checkowner_min_in'+cqc.$index" :controls="false" :precision="3" disabled></el-input-number></td>
                                                            <td><el-input-number v-model="cqc.row.cqclibrary.inmedian" class="disinput" :id="'analysis_cqcreport_table_row_checkowner_median_in'+cqc.$index" :controls="false" :precision="3" disabled></el-input-number></td>
                                                            <td><el-input-number v-model="cqc.row.cqclibrary.tolerencecheckab" class="disinput" :id="'analysis_cqcreport_table_row_checkowner_max_in'+cqc.$index" :controls="false" :precision="3" disabled></el-input-number></td>
                                                            <td style="color:#00bd00; padding-left: 5px;"><b>Within Owners Set Tolerances</b></td>
                                                            <td><el-input-number v-model="cqc.row.cqclibrary.inrange" class="disinput" :id="'analysis_cqcreport_table_row_checkowner_range_in'+cqc.$index" :controls="false" :precision="3" disabled></el-input-number></td>
                                                        </tr>

                                                        <tr>
                                                            <td class="bold"><el-tag type="warning" class="tag-check">Check</el-tag></td>
                                                            <td><el-input-number v-model="cqc.row.cqclibrary.tolerenceinba" class="disinput" :id="'analysis_cqcreport_table_row_checkowner_min_check'+cqc.$index" :controls="false" :precision="3" disabled></el-input-number></td>
                                                            <td><el-input-number v-model="cqc.row.cqclibrary.checkmedian" class="disinput" :id="'analysis_cqcreport_table_row_checkowner_median_check'+cqc.$index" :controls="false" :precision="3" disabled></el-input-number></td>
                                                            <td><el-input-number v-model="cqc.row.cqclibrary.tolerencecheckba" class="disinput" :id="'analysis_cqcreport_table_row_checkowner_max_check'+cqc.$index" :controls="false" :precision="3" disabled></el-input-number></td>
                                                            <td style="color:#ffbd07; padding-left: 5px;"><b>Owner Proposes that the Result is Checked</b></td>
                                                            <td><el-input-number v-model="cqc.row.cqclibrary.checkrange" class="disinput" :controls="false" :id="'analysis_cqcreport_table_row_checkowner_range_check'+cqc.$index" :precision="3" disabled></el-input-number></td>
                                                        </tr>
                                                        <tr>
                                                           <td class="bold"><el-tag type="warning" class="tag-out">Out</el-tag></td>
                                                           <td></td>
                                                           <td></td>
                                                           <td></td>
                                                           <td></td>
                                                            <td style="color:rgb(128, 0, 0); padding-left: 5px;"><b>The Result Falls Outside 'Check' Tolerances</b></td>
                                                        </tr>

                                                        <!-- <tr>
                                                            <td class="bold"><el-tag type="warning" class="tag-out">Out</el-tag></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td style="color:#ff0505; padding-left: 5px;"><b>The Result Falls Outside 'Check' Tolerances</b></td>
                                                            <td></td>
                                                        </tr> -->
                                                    </tbody>
                                                </table>
                                            </el-col>
                                        </el-row>

                                        <el-row>
                                            <el-row>
                                                <el-col style="color:rgb(82 71 71); background-color:#D3D3D3; border-radius: 0px;padding-left:10px;">
                                                    <div class="bold">{{"Matching PSP Project Based (Algorithmic) Tolerances"}}</div>
                                                </el-col>
                                            </el-row>

                                            <!-- <el-col :span="12">
                                                <table class="fullwidth padding2px fixed">
                                                    <tbody>
                                                        <tr class="bold" style="text-align: center;" >
                                                            <td></td>
                                                            <td>{{getsortingtablepsp(cqc.row,'in')[0].heder}}</td>
                                                            <td>{{getsortingtablepsp(cqc.row,'in')[1].heder}}</td>
                                                            <td>{{getsortingtablepsp(cqc.row,'in')[2].heder}}</td>
                                                            <td>{{getsortingtablepsp(cqc.row,'in')[3].heder}}</td>
                                                        </tr>
                                                        <tr>
                                                            <td v-if="checkin(getsortingtablepsp(cqc.row,'in'))" class="bold" ><el-tag type="success" class="tag-in-full">In</el-tag></td>
                                                            <td v-if="!checkin(getsortingtablepsp(cqc.row,'in'))" class="bold"><el-tag type="warning" class="tag-check-full">Check</el-tag></td>

                                                            <td>&lt; &nbsp;{{getsortingtablepsp(cqc.row,'in')[0].value}}</td>
                                                            <td>&lt;&nbsp;{{getsortingtablepsp(cqc.row,'in')[1].value}}</td>
                                                            <td>&lt;&nbsp;{{getsortingtablepsp(cqc.row,'in')[2].value}}</td>
                                                            <td>&lt;&nbsp;{{getsortingtablepsp(cqc.row,'in')[3].value}}</td>
                                                        </tr>
                                                        <tr class="bold" style="text-align: center;" >
                                                            <td></td>
                                                            <td>{{getsortingtablepsp(cqc.row,'check')[0].heder}}</td>
                                                            <td>{{getsortingtablepsp(cqc.row,'check')[1].heder}}</td>
                                                            <td>{{getsortingtablepsp(cqc.row,'check')[2].heder}}</td>
                                                            <td>{{getsortingtablepsp(cqc.row,'check')[3].heder}}</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="bold "  v-if="checkin(getsortingtablepsp(cqc.row,'check'))" ><el-tag type="success" class="tag-in-full">In</el-tag></td>
                                                            <td class="bold "  v-if="!checkin(getsortingtablepsp(cqc.row,'check'))" ><el-tag type="warning" class="tag-check-full">Check</el-tag></td>

                                                            <td>&lt;&nbsp;{{getsortingtablepsp(cqc.row,'check')[0].value}}</td>
                                                            <td>&lt;&nbsp;{{getsortingtablepsp(cqc.row,'check')[1].value}}</td>
                                                            <td>&lt;&nbsp;{{getsortingtablepsp(cqc.row,'check')[2].value}}</td>
                                                            <td>&lt;&nbsp;{{getsortingtablepsp(cqc.row,'check')[3].value}}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </el-col> -->

                                            <el-col :span="20" :offset="2">
                                                <table class="fullwidth padding2px fixed">
                                                    <tbody>
                                                        <tr class="bold">
                                                            <th width="55"></th>
                                                            <th></th>
                                                            <th>PSP Set</th>
                                                            <th>Min</th>
                                                            <th>Mean</th>
                                                            <th>Max</th>
                                                            <th width="280">Actions</th>
                                                            <th>Range</th>
                                                        </tr>

                                                        <tr>
                                                            <td class="bold">Range</td>
                                                            <td><el-input :value="'100%'" :id="'analysis_cqcreport_table_row_matchingpsp_percentage'+cqc.$index" readonly ></el-input></td>
                                                            <td><el-input v-model="crtqty.pspset" readonly :id="'analysis_cqcreport_table_row_matchingpsp_pspset'+cqc.$index"></el-input></td>
                                                            <td><el-input-number v-model="cqc.row.cqclibrary.pspfullmin" class="disinput" :id="'analysis_cqcreport_table_row_matchingpsp_min'+cqc.$index" :controls="false" :precision="3" disabled></el-input-number></td>
                                                            <td><el-input-number v-model="cqc.row.cqclibrary.pspmean" class="disinput" :id="'analysis_cqcreport_table_row_matchingpsp_mean'+cqc.$index" :controls="false" :precision="3" disabled></el-input-number></td>
                                                            <td><el-input-number v-model="cqc.row.cqclibrary.pspfullmax" class="disinput" :id="'analysis_cqcreport_table_row_matchingpsp_max'+cqc.$index" :controls="false" :precision="3" disabled></el-input-number></td>
                                                            <td style="padding-left: 5px;"><b v-if="!cqc.row.cqclibrary.showaction">Insufficient Comparative Data to Create Report</b></td>
                                                            <td><el-input-number v-model="cqc.row.cqclibrary.pspfullrange" class="disinput" :id="'analysis_cqcreport_table_row_matchingpsp_range'+cqc.$index" :controls="false" :precision="3" disabled></el-input-number></td>
                                                        </tr>

                                                        <tr v-if="cqc.row.cqclibrary.showaction">
                                                            <!-- <td class="bold"><el-tag type="success" :class="cqc.row.cqclibrary.pspclass">{{cqc.row.cqclibrary.pspstatus}}</el-tag></td> -->
                                                            <td class="bold"><el-tag type="success" class="tag-in">In</el-tag></td>
                                                            <td><el-input :value="cqc.row.cqclibrary.tolerenceoutab + '%'" :id="'analysis_cqcreport_table_row_matchingpsp_percentage_in'+cqc.$index" readonly></el-input></td>
                                                            <td></td>
                                                            <td><el-input-number v-model="cqc.row.cqclibrary.pspinmin" class="disinput" :id="'analysis_cqcreport_table_row_matchingpsp_min_in'+cqc.$index" :controls="false" :precision="3" disabled></el-input-number></td>
                                                            <td><el-input-number v-model="cqc.row.cqclibrary.pspmean" class="disinput" :id="'analysis_cqcreport_table_row_matchingpsp_mean_in'+cqc.$index" :controls="false" :precision="3" disabled></el-input-number></td>
                                                            <td><el-input-number v-model="cqc.row.cqclibrary.pspinmax" class="disinput" :id="'analysis_cqcreport_table_row_matchingpsp_max_in'+cqc.$index" :controls="false" :precision="3" disabled></el-input-number></td>
                                                            <!-- <td :style="cqc.row.cqclibrary.pspstyle"><b>{{cqc.row.cqclibrary.pspaction}}</b></td> -->
                                                             <td style="color:#00bd00; padding-left: 5px;"><b>Within PSP Set Tolerances</b></td>
                                                            <td><el-input-number v-model="cqc.row.cqclibrary.pspinrange" class="disinput" :id="'analysis_cqcreport_table_row_matchingpsp_range_in'+cqc.$index" :controls="false" :precision="3" disabled></el-input-number></td>
                                                        </tr>

                                                        <tr v-if="cqc.row.cqclibrary.showaction">
                                                            <!-- <td class="bold"><el-tag type="warning" :class="cqc.row.cqclibrary.pspclass">{{cqc.row.cqclibrary.pspstatus}}</el-tag></td> -->
                                                            <td class="bold"><el-tag type="warning" class="tag-check">Check</el-tag></td>
                                                            <td><el-input :value="cqc.row.cqclibrary.tolerenceoutba + '%'" :id="'analysis_cqcreport_table_row_matchingpsp_percentage_check'+cqc.$index" readonly></el-input></td>
                                                            <td></td>
                                                            <td><el-input-number v-model="cqc.row.cqclibrary.pspcheckmin" class="disinput" :id="'analysis_cqcreport_table_row_matchingpsp_min_check'+cqc.$index" :controls="false" :precision="3" disabled></el-input-number></td>
                                                            <td><el-input-number v-model="cqc.row.cqclibrary.pspmean" class="disinput" :id="'analysis_cqcreport_table_row_matchingpsp_mean_check'+cqc.$index" :controls="false" :precision="3" disabled></el-input-number></td>
                                                            <td><el-input-number v-model="cqc.row.cqclibrary.pspcheckmax" class="disinput" :id="'analysis_cqcreport_table_row_matchingpsp_max_check'+cqc.$index" :controls="false" :precision="3" disabled></el-input-number></td>
                                                            <td style="color:#ffbd07; padding-left: 5px;"><b>PSP Proposes that the Result is Checked</b></td>
                                                            <td><el-input-number v-model="cqc.row.cqclibrary.pspcheckrange" class="disinput" :id="'analysis_cqcreport_table_row_matchingpsp_range_check'+cqc.$index" :controls="false" :precision="3" disabled></el-input-number></td>
                                                        </tr>
                                                         <tr v-if="cqc.row.cqclibrary.showaction">
                                                           <td class="bold"><el-tag type="warning" class="tag-out">Out</el-tag></td>
                                                           <td></td>
                                                           <td></td>
                                                           <td></td>
                                                           <td></td>
                                                            <td></td>
                                                            <td style="color:rgb(128, 0, 0); padding-left: 5px;"><b>The Result Falls Outside 'Check' Tolerances</b></td>
                                                        </tr>

                                                        <!-- <tr v-if="cqc.row.cqclibrary.showaction">
                                                            <td class="bold"><el-tag type="danger" class="tag-out">Out</el-tag></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td style="color:#ff0505; padding-left: 5px;"><b>The Result Falls Outside 'Check' Tolerances</b></td>
                                                            <td></td>
                                                        </tr> -->
                                                    </tbody>
                                                </table>
                                            </el-col>

                                            <!-- <el-row>
                                                <el-col style="color:#A20000;font-size:16px;text-align:center;">
                                                    <div class="bold" v-if="!checkin(getsortingtablepsp(cqc.row,'check')) && (getsortingtablepsp(cqc.row,'in'))">{{"Data is not sufficient for comparison"}}</div>
                                                </el-col>
                                            </el-row> -->
                                        </el-row>

                                        <el-row v-if="cqc.row.cqclibrary.set1pspfullmin != undefined">
                                            <el-row>
                                                <el-col style="color:rgb(82 71 71); background-color:#D3D3D3; border-radius: 0px;padding-left:10px;">
                                                    <div class="bold">{{"User defined Set1 Comparison"}}</div>
                                                </el-col>
                                            </el-row>

                                            <!-- <el-col :span="12">
                                                <table class="fullwidth padding2px fixed">
                                                    <tbody>
                                                        <tr class="bold" style="text-align: center;" >
                                                            <td></td>
                                                            <td>{{getsortingtableset1psp(cqc.row,'in')[0].heder}}</td>
                                                            <td>{{getsortingtableset1psp(cqc.row,'in')[1].heder}}</td>
                                                            <td>{{getsortingtableset1psp(cqc.row,'in')[2].heder}}</td>
                                                            <td>{{getsortingtableset1psp(cqc.row,'in')[3].heder}}</td>
                                                        </tr>
                                                        <tr>
                                                            <td v-if="checkin(getsortingtableset1psp(cqc.row,'in'))" class="bold" ><el-tag type="success" class="tag-in-full">In</el-tag></td>
                                                            <td v-if="!checkin(getsortingtableset1psp(cqc.row,'in'))" class="bold"><el-tag type="warning" class="tag-check-full">Check</el-tag></td>

                                                            <td>&lt;&nbsp;{{getsortingtableset1psp(cqc.row,'in')[0].value}}</td>
                                                            <td>&lt;&nbsp;{{getsortingtableset1psp(cqc.row,'in')[1].value}}</td>
                                                            <td>&lt;&nbsp;{{getsortingtableset1psp(cqc.row,'in')[2].value}}</td>
                                                            <td>&lt;&nbsp;{{getsortingtableset1psp(cqc.row,'in')[3].value}}</td>
                                                        </tr>
                                                        <tr class="bold" style="text-align: center;" >
                                                            <td></td>
                                                            <td>{{getsortingtableset1psp(cqc.row,'check')[0].heder}}</td>
                                                            <td>{{getsortingtableset1psp(cqc.row,'check')[1].heder}}</td>
                                                            <td>{{getsortingtableset1psp(cqc.row,'check')[2].heder}}</td>
                                                            <td>{{getsortingtableset1psp(cqc.row,'check')[3].heder}}</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="bold "  v-if="checkin(getsortingtableset1psp(cqc.row,'check'))" ><el-tag type="success" class="tag-in-full">In</el-tag></td>
                                                            <td class="bold "  v-if="!checkin(getsortingtableset1psp(cqc.row,'check'))" ><el-tag type="warning" class="tag-check-full">Check</el-tag></td>

                                                            <td>&lt;&nbsp;{{getsortingtableset1psp(cqc.row,'check')[0].value}}</td>
                                                            <td>&lt;&nbsp;{{getsortingtableset1psp(cqc.row,'check')[1].value}}</td>
                                                            <td>&lt;&nbsp;{{getsortingtableset1psp(cqc.row,'check')[2].value}}</td>
                                                            <td>&lt;&nbsp;{{getsortingtableset1psp(cqc.row,'check')[3].value}}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </el-col> -->

                                            <el-col :span="20" :offset="2">
                                                <table class="fullwidth padding2px fixed">
                                                    <tbody>
                                                        <tr class="bold">
                                                            <th width="55"></th>
                                                            <th></th>
                                                            <th>PSP Set</th>
                                                            <th>Min</th>
                                                            <th>Mean</th>
                                                            <th>Max</th>
                                                            <th width="280">Actions</th>
                                                            <th>Range</th>
                                                        </tr>

                                                        <tr>
                                                           <td class="bold">Range</td>
                                                            <td><el-input :value="'100%'" :id="'analysis_cqcreport_table_row_set1_percentage'+cqc.$index" readonly></el-input></td>
                                                            <td><el-input v-model="crtqty.pspset" :id="'analysis_cqcreport_table_row_set1_pspset'+cqc.$index" readonly></el-input></td>
                                                            <td><el-input-number v-model="cqc.row.cqclibrary.set1pspfullmin" class="disinput" :id="'analysis_cqcreport_table_row_set1_min'+cqc.$index" :controls="false" :precision="3" disabled></el-input-number></td>
                                                            <td><el-input-number v-model="cqc.row.cqclibrary.set1pspmean" class="disinput" :id="'analysis_cqcreport_table_row_set1_mean'+cqc.$index" :controls="false" :precision="3" disabled></el-input-number></td>
                                                            <td><el-input-number v-model="cqc.row.cqclibrary.set1pspfullmax" class="disinput" :id="'analysis_cqcreport_table_row_set1_max'+cqc.$index" :controls="false" :precision="3" disabled></el-input-number></td>
                                                            <td style="padding-left: 5px;"><b v-if="!cqc.row.cqclibrary.set1showaction">Insufficient Comparative Data to Create Report</b></td>
                                                            <td><el-input-number v-model="cqc.row.cqclibrary.set1pspfullrange" class="disinput" :id="'analysis_cqcreport_table_row_set1_range'+cqc.$index" :controls="false" :precision="3" disabled></el-input-number></td>
                                                        </tr>

                                                        <tr v-if="cqc.row.cqclibrary.set1showaction">
                                                            <!-- <td class="bold"><el-tag type="success" :class="cqc.row.cqclibrary.set1pspclass">{{cqc.row.cqclibrary.set1pspstatus}}</el-tag></td> -->
                                                            <td class="bold"><el-tag type="success" class="tag-in">In</el-tag></td>
                                                            <td><el-input :value="cqc.row.cqclibrary.tolerenceoutab + '%'" :id="'analysis_cqcreport_table_row_set1_percentage_in'+cqc.$index" readonly></el-input></td>
                                                            <td></td>
                                                            <td><el-input-number v-model="cqc.row.cqclibrary.set1pspinmin" class="disinput" :id="'analysis_cqcreport_table_row_set1_min_in'+cqc.$index" :controls="false" :precision="3" disabled></el-input-number></td>
                                                            <td><el-input-number v-model="cqc.row.cqclibrary.set1pspmean" class="disinput" :id="'analysis_cqcreport_table_row_set1_mean_in'+cqc.$index" :controls="false" :precision="3" disabled></el-input-number></td>
                                                            <td><el-input-number v-model="cqc.row.cqclibrary.set1pspinmax" class="disinput" :id="'analysis_cqcreport_table_row_set1_max_in'+cqc.$index" :controls="false" :precision="3" disabled></el-input-number></td>
                                                            <!-- <td :style="cqc.row.cqclibrary.set1pspstyle"><b>{{cqc.row.cqclibrary.set1pspaction}}</b></td> -->
                                                             <td style="color:#00bd00; padding-left: 5px;"><b>Within Set1 Tolerances</b></td>
                                                            <td><el-input-number v-model="cqc.row.cqclibrary.set1pspinrange" class="disinput" :id="'analysis_cqcreport_table_row_set1_range_in'+cqc.$index" :controls="false" :precision="3" disabled></el-input-number></td>
                                                        </tr>

                                                        <tr v-if="cqc.row.cqclibrary.set1showaction">
                                                            <!-- <td class="bold"><el-tag type="success" :class="cqc.row.cqclibrary.set1pspclass">{{cqc.row.cqclibrary.set1pspstatus}}</el-tag></td> -->
                                                             <td class="bold"><el-tag type="warning" class="tag-check">Check</el-tag></td>
                                                            <td><el-input :value="cqc.row.cqclibrary.tolerenceoutba + '%'" :id="'analysis_cqcreport_table_row_set1_percentage_check'+cqc.$index" readonly></el-input></td>
                                                            <td></td>
                                                            <td><el-input-number v-model="cqc.row.cqclibrary.set1pspcheckmin" class="disinput" :id="'analysis_cqcreport_table_row_set1_min_check'+cqc.$index" :controls="false" :precision="3" disabled></el-input-number></td>
                                                            <td><el-input-number v-model="cqc.row.cqclibrary.set1pspmean" class="disinput" :id="'analysis_cqcreport_table_row_set1_mean_check'+cqc.$index" :controls="false" :precision="3" disabled></el-input-number></td>
                                                            <td><el-input-number v-model="cqc.row.cqclibrary.set1pspcheckmax" class="disinput" :id="'analysis_cqcreport_table_row_set1_max_check'+cqc.$index" :controls="false" :precision="3" disabled></el-input-number></td>
                                                            <!-- <td :style="cqc.row.cqclibrary.set1pspstyle"><b>{{cqc.row.cqclibrary.set1pspaction}}</b></td> -->
                                                             <td style="color:#ffbd07; padding-left: 5px;"><b>Set1 Proposes that the Result is Checked</b></td>
                                                            <td><el-input-number v-model="cqc.row.cqclibrary.set1pspcheckrange" class="disinput" :id="'analysis_cqcreport_table_row_set1_range_check'+cqc.$index" :controls="false" :precision="3" disabled></el-input-number></td>
                                                        </tr>
                                                         <tr v-if="cqc.row.cqclibrary.set1showaction">
                                                           <td class="bold"><el-tag type="warning" class="tag-out">Out</el-tag></td>
                                                           <td></td>
                                                           <td></td>
                                                           <td></td>
                                                           <td></td>
                                                            <td></td>
                                                            <td style="color:rgb(128, 0, 0); padding-left: 5px;"><b>The Result Falls Outside 'Check' Tolerances</b></td>
                                                        </tr>
                                                        <!-- <tr v-if="cqc.row.cqclibrary.set1showaction">
                                                            <td class="bold"><el-tag type="danger" class="tag-out">Out</el-tag></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td style="color:#ff0505; padding-left: 5px;"><b>The Result Falls Outside 'Check' Tolerances</b></td>
                                                            <td></td>
                                                        </tr> -->
                                                    </tbody>
                                                </table>
                                            </el-col>

                                            <!-- <el-row>
                                                <el-col style="color:#A20000;font-size:16px;text-align:center;">
                                                    <div class="bold" v-if="!checkin(getsortingtableset1psp(cqc.row,'check')) && (getsortingtableset1psp(cqc.row,'in'))">{{"Data is not sufficient for comparison"}}</div>
                                                </el-col>
                                            </el-row> -->
                                        </el-row>

                                        <el-row v-if="cqc.row.cqclibrary.set2pspfullmin != undefined">
                                            <el-row>
                                                <el-col style="color:rgb(82 71 71); background-color:#D3D3D3; border-radius: 0px;padding-left:10px;">
                                                    <div class="bold">{{"User defined Set2 Comparison"}}</div>
                                                </el-col>
                                            </el-row>
<!--
                                            <el-col :span="12">
                                                <table class="fullwidth padding2px fixed">
                                                    <tbody>
                                                        <tr class="bold" style="text-align: center;" >
                                                            <td></td>
                                                            <td>{{getsortingtableset2psp(cqc.row,'in')[0].heder}}</td>
                                                            <td>{{getsortingtableset2psp(cqc.row,'in')[1].heder}}</td>
                                                            <td>{{getsortingtableset2psp(cqc.row,'in')[2].heder}}</td>
                                                            <td>{{getsortingtableset2psp(cqc.row,'in')[3].heder}}</td>
                                                        </tr>
                                                        <tr>
                                                            <td v-if="checkin(getsortingtableset2psp(cqc.row,'in'))" class="bold" ><el-tag type="success" class="tag-in-full">In</el-tag></td>
                                                            <td v-if="!checkin(getsortingtableset2psp(cqc.row,'in'))" class="bold"><el-tag type="warning" class="tag-check-full">Check</el-tag></td>

                                                            <td>&lt; &nbsp;{{getsortingtableset2psp(cqc.row,'in')[0].value}}</td>
                                                            <td>&lt;&nbsp;{{getsortingtableset2psp(cqc.row,'in')[1].value}}</td>
                                                            <td>&lt;&nbsp;{{getsortingtableset2psp(cqc.row,'in')[2].value}}</td>
                                                            <td>&lt;&nbsp;{{getsortingtableset2psp(cqc.row,'in')[3].value}}</td>
                                                        </tr>
                                                        <tr class="bold" style="text-align: center;" >
                                                            <td></td>
                                                            <td>{{getsortingtableset2psp(cqc.row,'check')[0].heder}}</td>
                                                            <td>{{getsortingtableset2psp(cqc.row,'check')[1].heder}}</td>
                                                            <td>{{getsortingtableset2psp(cqc.row,'check')[2].heder}}</td>
                                                            <td>{{getsortingtableset2psp(cqc.row,'check')[3].heder}}</td>
                                                        </tr>
                                                        <tr>
                                                            <td class="bold "  v-if="checkin(getsortingtableset2psp(cqc.row,'check'))" ><el-tag type="success" class="tag-in-full">In</el-tag></td>
                                                            <td class="bold "  v-if="!checkin(getsortingtableset2psp(cqc.row,'check'))" ><el-tag type="warning" class="tag-check-full">Check</el-tag></td>

                                                            <td>&lt;&nbsp;{{getsortingtableset2psp(cqc.row,'check')[0].value}}</td>
                                                            <td>&lt;&nbsp;{{getsortingtableset2psp(cqc.row,'check')[1].value}}</td>
                                                            <td>&lt;&nbsp;{{getsortingtableset2psp(cqc.row,'check')[2].value}}</td>
                                                            <td>&lt;&nbsp;{{getsortingtableset2psp(cqc.row,'check')[3].value}}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </el-col> -->

                                            <el-col :span="20" :offset="2">
                                                <table class="fullwidth padding2px fixed">
                                                    <tbody>
                                                        <tr class="bold">
                                                            <th width="55"></th>
                                                            <th></th>
                                                            <th>PSP Set</th>
                                                            <th>Min</th>
                                                            <th>Mean</th>
                                                            <th>Max</th>
                                                            <th width="280">Actions</th>
                                                            <th>Range</th>
                                                        </tr>

                                                        <tr>
                                                            <td class="bold">Range</td>
                                                            <td><el-input :value="'100%'" :id="'analysis_cqcreport_table_row_set2_percentage'+cqc.$index" readonly></el-input></td>
                                                            <td><el-input v-model="crtqty.pspset" :id="'analysis_cqcreport_table_row_set2_pspset'+cqc.$index" readonly></el-input></td>
                                                            <td><el-input-number v-model="cqc.row.cqclibrary.set2pspfullmin" class="disinput" :id="'analysis_cqcreport_table_row_set2_min'+cqc.$index" :controls="false" :precision="3" disabled></el-input-number></td>
                                                            <td><el-input-number v-model="cqc.row.cqclibrary.set2pspmean" class="disinput" :id="'analysis_cqcreport_table_row_set2_mean'+cqc.$index" :controls="false" :precision="3" disabled></el-input-number></td>
                                                            <td><el-input-number v-model="cqc.row.cqclibrary.set2pspfullmax" class="disinput" :id="'analysis_cqcreport_table_row_set2_max'+cqc.$index" :controls="false" :precision="3" disabled></el-input-number></td>
                                                            <td style="padding-left: 5px;"><b v-if="!cqc.row.cqclibrary.set2showaction">Insufficient Comparative Data to Create Report</b></td>
                                                            <td><el-input-number v-model="cqc.row.cqclibrary.set2pspfullrange" class="disinput" :id="'analysis_cqcreport_table_row_set2_range'+cqc.$index" :controls="false" :precision="3" disabled></el-input-number></td>
                                                        </tr>

                                                        <tr v-if="cqc.row.cqclibrary.set2showaction">
                                                            <!-- <td class="bold"><el-tag type="success" :class="cqc.row.cqclibrary.set2pspclass">{{cqc.row.cqclibrary.set2pspstatus}}</el-tag></td> -->
                                                            <td class="bold"><el-tag type="success" class="tag-in">In</el-tag></td>
                                                            <td><el-input :value="cqc.row.cqclibrary.tolerenceoutab + '%'" :id="'analysis_cqcreport_table_row_set2_percentage_in'" readonly></el-input></td>
                                                            <td></td>
                                                            <td><el-input-number v-model="cqc.row.cqclibrary.set2pspinmin" :id="'analysis_cqcreport_table_row_set2_min_in'+cqc.$index" class="disinput" :controls="false" :precision="3" disabled></el-input-number></td>
                                                            <td><el-input-number v-model="cqc.row.cqclibrary.set2pspmean" :id="'analysis_cqcreport_table_row_set2_mean_in'+cqc.$index" class="disinput" :controls="false" :precision="3" disabled></el-input-number></td>
                                                            <td><el-input-number v-model="cqc.row.cqclibrary.set2pspinmax" :id="'analysis_cqcreport_table_row_set2_max_in'+cqc.$index" class="disinput" :controls="false" :precision="3" disabled></el-input-number></td>
                                                            <!-- <td :style="cqc.row.cqclibrary.set2pspstyle"><b>{{cqc.row.cqclibrary.set2pspaction}}</b></td> -->
                                                             <td style="color:#00bd00; padding-left: 5px;"><b>Within Set2 Tolerances</b></td>
                                                            <td><el-input-number v-model="cqc.row.cqclibrary.set2pspinrange" class="disinput" :id="'analysis_cqcreport_table_row_set2_range_in'+cqc.$index" :controls="false" :precision="3" disabled></el-input-number></td>
                                                        </tr>

                                                        <tr v-if="cqc.row.cqclibrary.set2showaction">
                                                            <!-- <td class="bold"><el-tag type="success" :class="cqc.row.cqclibrary.set2pspclass">{{cqc.row.cqclibrary.set2pspstatus}}</el-tag></td> -->
                                                             <td class="bold"><el-tag type="warning" class="tag-check">Check</el-tag></td>
                                                            <td><el-input :value="cqc.row.cqclibrary.tolerenceoutba + '%'" :id="'analysis_cqcreport_table_row_set2_percentage_check'" readonly></el-input></td>
                                                            <td></td>
                                                            <td><el-input-number v-model="cqc.row.cqclibrary.set2pspcheckmin" class="disinput" :id="'analysis_cqcreport_table_row_set2_min_check'+cqc.$index" :controls="false" :precision="3" disabled></el-input-number></td>
                                                            <td><el-input-number v-model="cqc.row.cqclibrary.set2pspmean" class="disinput" :id="'analysis_cqcreport_table_row_set2_mean_check'+cqc.$index" :controls="false" :precision="3" disabled></el-input-number></td>
                                                            <td><el-input-number v-model="cqc.row.cqclibrary.set2pspcheckmax" class="disinput" :id="'analysis_cqcreport_table_row_set2_max_check'+cqc.$index" :controls="false" :precision="3" disabled></el-input-number></td>
                                                           <!-- <td :style="cqc.row.cqclibrary.set2pspstyle"><b>{{cqc.row.cqclibrary.set2pspaction}}</b></td> -->
                                                            <td style="color:#ffbd07; padding-left: 5px;"><b>Set2 Proposes that the Result is Checked</b></td>
                                                            <td><el-input-number v-model="cqc.row.cqclibrary.set2pspcheckrange" class="disinput" :id="'analysis_cqcreport_table_row_set2_range_check'+cqc.$index" :controls="false" :precision="3" disabled></el-input-number></td>
                                                        </tr>
                                                         <tr v-if="cqc.row.cqclibrary.set2showaction">
                                                           <td class="bold"><el-tag type="warning" class="tag-out">Out</el-tag></td>
                                                           <td></td>
                                                           <td></td>
                                                           <td></td>
                                                           <td></td>
                                                            <td></td>
                                                            <td style="color:rgb(128, 0, 0); padding-left: 5px;"><b>The Result Falls Outside 'Check' Tolerances</b></td>
                                                        </tr>
                                                        <!-- <tr v-if="cqc.row.cqclibrary.set2showaction">
                                                            <td class="bold"><el-tag type="danger" class="tag-out">Out</el-tag></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td></td>
                                                            <td style="color:#ff0505; padding-left: 5px;"><b>The Result Falls Outside 'Check' Tolerances</b></td>
                                                            <td></td>
                                                        </tr> -->
                                                    </tbody>
                                                </table>
                                            </el-col>

                                            <!-- <el-row>
                                                <el-col style="color:#A20000;font-size:16px;text-align:center;">
                                                    <div class="bold" v-if="!checkin(getsortingtableset2psp(cqc.row,'check')) && (getsortingtableset2psp(cqc.row,'in'))">{{"Data is not sufficient for comparison"}}</div>
                                                </el-col>
                                            </el-row> -->
                                        </el-row>
                                        </div>
                                    </el-col>

                                    <!-- <el-col :span="6">
                                        <table class="fullwidth padding2px">
                                            <thead>
                                                <tr class="bold">
                                                    <th>Totals</th>
                                                    <th class="center">&lt;</th>
                                                    <th class="center">&lt;</th>
                                                    <th class="center">&gt;</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr class="bold" style="text-align:center">
                                                    <td></td>
                                                    <td class="green">In(%)</td>
                                                    <td class="orange">Check(%)</td>
                                                    <td class="red">Out(%)</td>
                                                </tr>
                                                <tr>
                                                    <td class="bold"> A &gt; B  </td>
                                                    <td class="rightimp"> {{cqc.row.cqclibrary.tolerenceinab |to2decimal}} </td>
                                                    <td class="rightimp"> {{cqc.row.cqclibrary.tolerencecheckab |to2decimal}} </td>
                                                    <td class="rightimp"> {{cqc.row.cqclibrary.tolerenceoutab |to2decimal}} </td> -->
                                                    <!--<td> <el-input-number :controls="false" v-model="cqc.row.cqclibrary.tolerenceoutab" @change="changed(cqc.row)"></el-input-number> </td>-->
                                                <!-- </tr>
                                                <tr>
                                                    <td class="bold"> B &gt; A  </td>
                                                    <td class="rightimp"> {{cqc.row.cqclibrary.tolerenceinba |to2decimal}} </td>
                                                    <td class="rightimp"> {{cqc.row.cqclibrary.tolerencecheckba |to2decimal}} </td>
                                                    <td class="rightimp"> {{cqc.row.cqclibrary.tolerenceoutba |to2decimal}} </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </el-col> -->
                                </el-row>
                            </template>
                        </el-table-column>

                        <el-table-column :show-overflow-tooltip="true" prop="cqclibrary.cqcname" label="CQC Name" :resizable="true" min-width="130"></el-table-column>

                        <el-table-column :show-overflow-tooltip="true" prop="cqclibrary.termsofuse" label="Terms of Use" :resizable="true" min-width="60"></el-table-column>
                        <!-- <el-table-column :show-overflow-tooltip="true" prop="cqclibrary.reportin" label="Report In" :resizable="true" min-width="60"></el-table-column> -->

                        <el-table-column prop="cqclibrary.searchtype" label="Type" :resizable="true" :width="columnwidth(detailform, 'row.cqclibrary.searchtype')" align="center"></el-table-column>

                        <el-table-column prop="cqclibrary.owner.loginname" label="Owner" :resizable="true" :width="columnwidth(detailform, 'row.cqclibrary.owner.loginname')" align="center"></el-table-column>

                        <el-table-column label="Tolerance" prop="status" :resizable="true" width="80" align="center">
                            <template slot-scope="scope">
                                <div :class="scope.row.status">{{ scope.row.status }}</div>
                            </template>
                        </el-table-column>

                        <!-- <el-table-column prop="tolerance" :resizable="true" min-width="50" align="right">
                                <template slot-scope="scope">
                                {{ scope.row.tolerance == 0 ? "0.0" : scope.row.tolerance }}%
                                </template>
                        </el-table-column> -->

                        <el-table-column prop="comment" label="Comment" :resizable="true" width="280">
                            <template slot-scope="scope">
                                <el-input  :disabled="!editpermission" v-model="scope.row.comment" :id="'analysis_cqcreport_table_comment_'+scope.$index"></el-input>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-col>

                <el-col :span="5" v-if="cmpdetail == true">
                    <el-table :data="getcmpprojdetailform" :expand-row-keys="expandedrows2" @expand-change="expandchange" row-key="id" :header-cell-style="mergeheader" :cell-style="{ padding: '0', height: '35px' }" :border="true" class="fullwidth" :row-class-name="getRowClassName">
                        <el-table-column :label="cmppname ? cmppname : 'Comparative Project Name'" align="center" >
                            <el-table-column type="expand" :resizable="true" align="center" width="60">
                                <template slot-scope="scope">
                                    <div v-if="scope.row.cqclibrary != undefined">
                                    <el-table :data="scope.row.cqclibrary == undefined ? [] : scope.row.cqclibrary.details" :show-summary="true" :cell-style="{ padding: '0', height: '35px' }" :summary-method=" (column, data) => { return showcmpprojcqcsummary(scope.row, column, data); }" class="boldsummary">
                                        <el-table-column></el-table-column>
                                        <el-table-column prop="projectcqcdetail.totala" label="Total A" style="height: 10%" align="right">
                                            <template slot-scope="scope">
                                            {{ commaseparatednumber(scope.row.projectcqcdetail.totala, 2) }}
                                            </template>
                                        </el-table-column>

                                        <el-table-column prop="projectcqcdetail.totalb" label="Total B" align="right">
                                            <template slot-scope="scope">
                                            {{ commaseparatednumber(scope.row.projectcqcdetail.totalb, 2) }}
                                            </template>
                                        </el-table-column>
                                    </el-table>
                                    <div :style="{height: scope.row.cqclibrary.ownerpspheight + 3 + 'px'}"></div>
                                    </div>
                                    <!-- <table class="totaltable">
                                        <thead>
                                            <tr>
                                            <th></th>
                                            <th>Total A</th>
                                            <th>Total B</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                            <td></td>
                                            <td>{{scope.row.totala|to2decimal}}</td>
                                            <td>{{scope.row.totalb|to2decimal}}</td>
                                            </tr>
                                        </tbody>
                                    </table> -->
                                </template>
                            </el-table-column>

                            <el-table-column prop="status" :resizable="true" width="80" align="center">
                                <template slot-scope="scope">
                                    <div v-if="scope.row.status != undefined">
                                        <el-popover placement="top" title="CQC Name" width="300" trigger="hover" :content="scope.row.cqclibrary.cqcname">
                                            <div :class="scope.row.status" slot="reference">{{ scope.row.status }}</div>
                                        </el-popover>
                                    </div>
                                </template>
                            </el-table-column>

                            <el-table-column prop="tolerance" :resizable="true" min-width="10" align="right">
                                <template slot-scope="scope">
                                    <div v-if="scope.row.tolerance != undefined"> <!--style="  height: 40px;border: 0px solid white;"-->
                                        {{ scope.row.tolerance == 0 ? "0.0%" : scope.row.tolerance + '%'}}
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table-column>
                    </el-table>

                    <!-- <el-row style="text-align: center; margin-top: 20px">
                        <el-popover placement="top" title="Select Next Comparative Project" width="500" trigger="click">
                            <projectselectpart :lastsavedshow="true" :projectplaceholder="cmpprojplaceholder" @projectsectionchange="cmpprojectsectionchange" class="addrowspace"></projectselectpart>
                            <el-button @click="gotocmpproj" id="btnid1" slot="reference" size="small" style="color:#6666FF;font-weight:bold;">Show Next Comparative Project</el-button>
                        </el-popover>
                        <el-button @click="gotocmpproj" size="small" style="color:#6666FF;font-weight:bold;">Show Next Comparative Project</el-button>
                    </el-row> -->
                </el-col>
            </el-row>

        <hr style="margin-top: 20px" />

        <el-footer class="footer">
            <p style="float: right; padding-top: 10px; margin-right: 100px;">
                Sign Off: &nbsp;
                <el-checkbox  :disabled="!editpermission" v-model="detailform.signoff" @change="signoffchange" :id="'analysis_cqcreport_checkbox_signoff'"></el-checkbox>
                &nbsp; By: &nbsp;
                {{ detailform.signoffby ? detailform.signoffby.loginname : "" }} &nbsp;
                {{ detailform.showdate }}
            </p>
        </el-footer>
      </div>
        </div>
        <el-dialog :visible.sync="dialogVisible" width="98%" top="10vh">
            <projectdescriptionform :cqcreportprjcrt="cqcreportprjcrt"></projectdescriptionform>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">Cancel</el-button>
                <el-button type="primary" @click="changeprjsetcriteria('Ok')">Ok</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import projectselectpart from "./projectselectpart.vue";
import projectdescriptionform from "./projectdescriptionform.vue";

export default {
    data() {
        return {
            showsetdata:'',
            cmpprojplaceholder: "Comparative Project View",
            cmpprojdetailform: { pcqclist: [] },
            searchtype: "",
            projectdetail: "",
            expandcollapsetext: "+",
            expandedrows: [],
            expandedrows2: [],
            detailform: { pcqclist: [], pdes: {} },
            loading: false,
            currentcqc: null,
            showids: false,
            ids: null,
            user: this.$store.state.user,
            units: this.$store.state.units,
            searchtypes: ["Required", "Expected", "Testing", "Export Only"],
            // psp: [],
            // psps: [],
            errorvisible: false,
            sectionid: null,
            project: {},
            cmpsectionid: null,
            cmpproject: {},
            detail: false,
            cmpdetail: false,
            cmppname: "",
            pspcriterias: [],
            crtqty: {},
            crtmthprojectobj: {
                project: {},
                sectionid: null,
                part: {},
            },
            crtmthprojects: [],
            crtmthprojectname:"",
            crtmppartid:null,
            crtmpsectionname:"",
            crtmthproject:{},
            crtmppart:{},
            crtmpparts: [],
            crtmppsections:[],
            crtmpsections:[],
            showsubcriteria: false,
            showsupcriteria: false,
            showextcriteria: false,
            showchunksize: false,
            checked: true,
            showcheck:true,
            dialogVisible:false,
            cqcreportprjcrt:{
                showprjcrtdata:"",
                showset1crtdata:"",
                showset2crtdata:"",
            },
            criteriastring:"",
            custom:{
                rowcol:'el-row',
                projectsid:null,
                },
            getcqcdata: false
            //   crtunmthmsg :"",
            //   showcrtupmsg: false
        };
    },

    methods: {
        setdirectionsection() {
            if (this.project.section.section) {
                return this.project.section.section;
            } else {
                return "";
            }
        },

        cellchanged(cellaction, callback, cancall) {
            const app = this;
            this.$directionutility.setcomponent(app);
            if (cancall) {
                callback();
                this.canmatchcellid(() => { }, cellaction, this.setdirectionsection());
            } else {
                this.canmatchcellid(callback, cellaction, this.setdirectionsection());
            }
        },
        
        Comparanextproject(){
           var nproject = {};
            if(this.crtmthprojectname != null){
                var check = "";
                 var str = this.$store.state.section;
            var res = parseInt(str);
            if ( res == 1) {
                  check = "Subqty"
            }
             if ( res != 1 && res != 8) {
                  check = "Supqty"
            }
             if ( res == 8) {
                  check = "Extqty"
            }
              this.crtmthprojects =  this.crtmthprojects.sort(function(a, b) {
                    return a.pds[check] - b.pds[check];
                });
             var index =  this.crtmthprojects.findIndex((x => x.id == this.crtmthprojectname.id));
             if(index == this.crtmthprojects.length-1 || index == -1){
                  nproject = this.crtmthprojects[0];
             }else{
                nproject = this.crtmthprojects[index + 1];
             }
             }else{
                 nproject = this.crtmthprojects[0];
             }
             this.crtmthprojectname = {};
             this.crtmthprojectname = nproject;
             this.getcrtmpsections(nproject).then(()=>{
                var partid= nproject.projectparts.length == 0 ? 0 : nproject.projectparts[0].id;
                this.changecrtmppart(partid)
                var sobj = this.crtmpsections[0]
                this.crtmpsectionname = {};
                this.crtmpsectionname = sobj;
                this.changecrtmpsection(sobj.id);
             });
        },
        checkin(arr){
            var ab = arr.find(x => x.heder == 'A/B');
            var min = arr.find(x => x.heder == 'Min');
            var max = arr.find(x => x.heder == 'Max');
            ab.value = parseFloat(ab.value);
            max.value = parseFloat(max.value);
            if (ab.value >= max.value) {
                return false;
            }else{
                return true;
            }
            //   if(ab.value <= max.value && ab.value >= min.value){
            //       return false;
            //   }else{
            //       return true;
            //   }

        },
        columnwidth: function(cs, colname){
            var maxwidth = 0;

            for(var i = 0; i < cs.pcqclist.length; i++){
                var row = cs.pcqclist[i];
                var value = eval(colname);
                if (value && value.length != 0) {
                    if (value.length > maxwidth) {
                        maxwidth = value.length;
                        var str = value;
                    }
                }
            }
            if (maxwidth != 0) {
                    var size = this.measureTextWidth(str);
                    size = size + 25;
                    if (size < 60) {
                        size = 60;
                    }
                    return size;
            }
            return 60;
        },

        changeprjsetcriteria(name){
            if (name == "projectcriteria") {
                this.cqcreportprjcrt.name = name;
                this.cqcreportprjcrt.prjcrtdata = {};
                this.$bus.$emit('crprjcrtrefresh', null);
                this.dialogVisible = true;
            }
            if (name == "set1criteria") {
                this.cqcreportprjcrt.disofcrprjcrt = false;
                this.cqcreportprjcrt.name = name;
                this.cqcreportprjcrt.set1crtdata = {};
                this.$bus.$emit('crprjcrtrefresh', null);
                this.dialogVisible = true;
            }
            if (name == "set2criteria") {
                this.cqcreportprjcrt.disofcrprjcrt = false;
                this.cqcreportprjcrt.name = name;
                this.cqcreportprjcrt.set2crtdata = {};
                this.$bus.$emit('crprjcrtrefresh', null);
                this.dialogVisible = true;
            }
            if (name == "Ok") {
                if (this.cqcreportprjcrt.name == "projectcriteria") {
                    this.detailform.pdes = this.cqcreportprjcrt.prjcrtdata;
                    this.showsetdata = this.getcriterias(this.detailform.pdes);
                        this.getchunck(this.detailform.pdes).then((value) => {
                        this.showsetdata += value;
                        });
                        this.matchprjset1set2crt(this.cqcreportprjcrt.set1crtdata,"set1criteria");
                        this.matchprjset1set2crt(this.cqcreportprjcrt.set2crtdata,"set2criteria");
                }
                if (this.cqcreportprjcrt.name == "set1criteria") {
                    var set1project = {project:{}};
                    set1project.projectid = this.cqcreportprjcrt.set1crtdata.projectid;
                    set1project.partid = this.cqcreportprjcrt.set1crtdata.partid;
                    set1project.project = this.project;
                    this.matchprjset1set2crt(this.cqcreportprjcrt.set1crtdata,"set1criteria");
                    this.getset1set2ProjectCQC(set1project,"set1criteria");
                }
                if (this.cqcreportprjcrt.name == "set2criteria") {
                    var set2project = {project:{}};
                    set2project.projectid = this.cqcreportprjcrt.set2crtdata.projectid;
                    set2project.partid = this.cqcreportprjcrt.set2crtdata.partid;
                    set2project.project = this.project;
                    this.matchprjset1set2crt(this.cqcreportprjcrt.set2crtdata,"set2criteria");
                    this.getset1set2ProjectCQC(set2project,"set2criteria");
                }
                this.dialogVisible = false;
            }
        },
        matchprjset1set2crt(crtdata,crtbtnname){
            var crtmthdata = {};
            if (crtdata != undefined) {
                for (const [key, value] of Object.entries(this.detailform.pdes)) {
                    if(crtdata[key] == value){
                        crtmthdata[key] = value;
                    }
                }
                if (crtbtnname == "set1criteria") {
                    this.cqcreportprjcrt.showset1crtdata = this.getcriterias(crtmthdata);
                    this.getchunck(crtmthdata).then((value) => {
                        this.cqcreportprjcrt.showset1crtdata += value;
                    });
                }
                if (crtbtnname == "set2criteria") {
                    this.cqcreportprjcrt.showset2crtdata = this.getcriterias(crtmthdata);
                    this.getchunck(crtmthdata).then((value) => {
                        this.cqcreportprjcrt.showset2crtdata += value;
                    });
                }
            }
        },
        getchunck(data){
            var ndata ={projectid:data.projectid,
            partid:data.partid,
            SupUR:data.SupUR == null ? "" : data.SupUR,
            SubUR:data.SubUR == null ? "" : data.SubUR,
            ExtUR:data.ExtUR== null ? "" : data.ExtUR}
            return  this.$http
            .post("project/getchunck", ndata)
            .then((response) => {
                var str = this.$store.state.section;
                var res = parseInt(str);
                if ( res == 1) {
                    return  "  Chunk Size: " + response.data.sub.Result.replace("|"," ") + "  Buliding Footprint (m2)"
                }
                if ( res != 1 && res != 8) {
                    return "  Chunk Size: " + response.data.sup.Result.replace("|"," ") + " Gross Internal Floor (m2)"
                }
                if (res == 8) {
                    return "  Chunk Size: " + response.data.ext.Result.replace("|"," ") + " Hard Surfaces (m2)"
                }
                //   this.cqcreportprjcrt[datastring] += "  Chunk Size:" + response.data.result.Result.replace("|"," ")
            })
            .catch((err) => {
                this.$bus.$emit("error", err);
            });
        },
        getsortingtablepsp(row ,name){
            var  sortarra = [];
            sortarra.push({heder:'Min',value:name == 'in' ? row.cqclibrary.pspfullmin :row.cqclibrary.pspcheckmin });
            sortarra.push({heder:'Mean',value:name == 'in'? row.cqclibrary.pspmean:row.cqclibrary.pspmean});
            sortarra.push({heder:'Max',value:name == 'in'? row.cqclibrary.pspinmax : row.cqclibrary.pspcheckmax});
            sortarra.push({heder:'A/B',value:row.totala/row.totalb});
            sortarra[0].value = sortarra[0].value.toFixed(3);
            sortarra[1].value = sortarra[1].value.toFixed(3);
            sortarra[2].value = sortarra[2].value.toFixed(3);
            sortarra[3].value = sortarra[3].value.toFixed(3);
            if (!isFinite(sortarra[3].value)) {
                sortarra[3].value = "0.000";
            }
            sortarra = sortarra.sort(function(a,b){
                return parseFloat(a.value) - parseFloat(b.value);
            });
            return sortarra;
        },

        getsortingtableset1psp(row ,name){
            var  sortarra = [];
            sortarra.push({heder:'Min',value:name == 'in' ? row.cqclibrary.set1pspfullmin :row.cqclibrary.set1pspcheckmin });
            sortarra.push({heder:'Mean',value:name == 'in'? row.cqclibrary.set1pspmean:row.cqclibrary.set1pspmean});
            sortarra.push({heder:'Max',value:name == 'in'? row.cqclibrary.set1pspinmax : row.cqclibrary.set1pspcheckmax});
            sortarra.push({heder:'A/B',value:row.totala/row.totalb});
            sortarra[0].value = sortarra[0].value.toFixed(3);
            sortarra[1].value = sortarra[1].value.toFixed(3);
            sortarra[2].value = sortarra[2].value.toFixed(3);
            sortarra[3].value = sortarra[3].value.toFixed(3);
            if (!isFinite(sortarra[3].value)) {
                sortarra[3].value = "0.000";
            }
            sortarra = sortarra.sort(function(a,b){
                return parseFloat(a.value) - parseFloat(b.value);
            });
            return sortarra;
        },

        getsortingtableset2psp(row ,name){
            var  sortarra = [];
            sortarra.push({heder:'Min',value:name == 'in' ? row.cqclibrary.set2pspfullmin :row.cqclibrary.set2pspcheckmin });
            sortarra.push({heder:'Mean',value:name == 'in'? row.cqclibrary.set2pspmean:row.cqclibrary.set2pspmean});
            sortarra.push({heder:'Max',value:name == 'in'? row.cqclibrary.set2pspinmax : row.cqclibrary.set2pspcheckmax});
            sortarra.push({heder:'A/B',value:row.totala/row.totalb});
            sortarra[0].value = sortarra[0].value.toFixed(3);
            sortarra[1].value = sortarra[1].value.toFixed(3);
            sortarra[2].value = sortarra[2].value.toFixed(3);
            sortarra[3].value = sortarra[3].value.toFixed(3);
            if (!isFinite(sortarra[3].value)) {
                sortarra[3].value = "0.000";
            }
            sortarra = sortarra.sort(function(a,b){
                return parseFloat(a.value) - parseFloat(b.value);
            });
            return sortarra;
        },

        getsortingtable(row ,name){
            var  sortarra = [];
            sortarra.push({heder:'Min',value:name == 'in' ? row.cqclibrary.tolerenceinab :row.cqclibrary.tolerenceinba });
            sortarra.push({heder:'Median',value:name == 'in'? row.cqclibrary.inmedian:row.cqclibrary.checkmedian});
            sortarra.push({heder:'Max',value:name == 'in'? row.cqclibrary.tolerencecheckab : row.cqclibrary.tolerencecheckba});
            sortarra.push({heder:'A/B',value:row.totala/row.totalb});
            sortarra[0].value = sortarra[0].value.toFixed(3);
            sortarra[1].value = sortarra[1].value.toFixed(3);
            sortarra[2].value = sortarra[2].value.toFixed(3);
            sortarra[3].value = sortarra[3].value.toFixed(3);
            if (!isFinite(sortarra[3].value)) {
                sortarra[3].value = "0.000";
            }
            sortarra = sortarra.sort(function(a,b){
                return parseFloat(a.value) - parseFloat(b.value);
            });
            return sortarra;
        },
        // gotocmpproj(){
        //     document.getElementById('cmpprojid1').focus();
        //     this.projectsectionchange();
        // },

        getRowClassName({row, rowIndex}){
            if (row.cqclibrary == undefined) {
                return 'row-expand-cover';
            }
        },

        projectsectionchange: function (Projectobj) {
            if (this.$route.params.exportonlysections != undefined) {
                let section = Projectobj.project.projectsections.find(a => a.id == this.$route.params.exportonlysections.id);
                Projectobj.sectionid = section.id;
                let d = { 
                    projectid: Projectobj.project.id,
                    partid: Projectobj.part.id,
                    sectionid: Projectobj.sectionid
                };
                this.$bus.$emit('addproject', d);
                this.searchtype = "Export Only";
                this.$route.params.exportonlysections = undefined;
            }
            else {
                this.sectionid = Projectobj.sectionid;
                this.project = Projectobj.project;
                this.project.projectpart = Projectobj.part;
                this.project.needinfo = true;
                this.refresh();
            }
        },

        cmpprojectsectionchange: function (Projectobj) {
            if (!this.checked) {
                this.cmpsectionid = Projectobj.sectionid;
                this.cmpproject = Projectobj.project;
                this.cmppname = Projectobj.project.projectname;
                this.cmpproject.projectpart = Projectobj.part;
                this.cmprefresh();
            }
        },

        crtmthprojectsectionchange: function(crtmthprojectobj){
            this.cmpsectionid = crtmthprojectobj.sectionid;
            this.cmpproject = crtmthprojectobj.project;
            this.cmpproject.projectpart = crtmthprojectobj.part;
            this.cmpproject.needinfo = false;
            this.cmprefresh();
        },

        showchangecrtmp: function(){
            this.cmpprojdetailform.pcqclist = [];
            this.cmppname = null;
        },

        signoffchange: function (value) {
            this.detailform.signoffbyid = value ? this.$store.state.user.id : 0;
            if(value){
                var tm = new Date();
                this.detailform.signoffon = tm;//tm.toLocaleString().replace(/:\d{2}\s/, " ");
            }
            this.save();
        },
        expandchange: function (row, expandedRows) {

            var expanded = expandedRows.map((v) => {
                return v.cqclibrary.id;
            });
            this.expandedrows = expanded;
            this.expandedrows2 = expanded;
            var bigdash = new Option();
            bigdash.innerHTML = "&mdash;";
            this.expandcollapsetext = this.expandedrows.length ? bigdash.text : "+"; //not sure why bigger dash displays like this.
        },

        expandcollapse: function () {
            if (this.expandedrows.length > 0 || this.expandedrows2.length > 0) {
                this.expandedrows = [];
                this.expandedrows2 = [];
            } else {
                this.expandedrows = this.getdetailform.map((v) => {
                    return v.id;
                });
                this.expandedrows2 = this.getcmpprojdetailform.map((v) => {
                    if (v.id == undefined) {
                        return v;
                    }
                    return v.id;
                });
            }
            var bigdash = new Option();
            bigdash.innerHTML = "&mdash;";
            this.expandcollapsetext = this.expandedrows.length || this.expandedrows2.length ? bigdash.text : "+";
        },

        mergeheader({ row, column, rowIndex, columnIndex }) {
            if (rowIndex === 1) {
                return { display: "none" };
            }
        },

        changed: function (row) {
            row.edited = true;
        },

        save: function (item, event) {
            this.$http
                .post("project/savecqcreport", this.detailform)
                .then((response) => {
                this.$message({
                    showClose: true,
                    message: response.data,
                    type: "success",
                });
                this.refresh();
                this.$signalr.checkingdialogboxheaderpage();
            })
            .catch((err) => {
                this.$bus.$emit("error", err);
            });
        },

        showcqcsummary: function (row, param) {
            var sum = [];
            sum[2] = row.cqclibrary.abratiostatement;
            sum[3] = this.commaseparatednumber(row.totala / row.totalb, 3);
            sum[6] = "Totals";
            sum[7] = this.commaseparatednumber(row.totala, 2);
            sum[8] = this.commaseparatednumber(row.totalb, 2);
            if (!isFinite(sum[3])) {
                sum[3] = "0.000";
            }
            return sum;
        },

        showcmpprojcqcsummary: function (row, param) {
            var sum = [];
            sum[0] = this.commaseparatednumber(row.totala / row.totalb, 3);
            sum[1] = this.commaseparatednumber(row.totala, 2);
            sum[2] = this.commaseparatednumber(row.totalb, 2);
            if (!isFinite(sum[0])) {
                sum[0] = "0.000";
            }
            return sum;
        },

        getProjectCQC: function () {
            // this.loading = true;
            this.$store.state.isLoading = true;

            this.currentcqc = null;

            this.crtmthprojectname = null;
            this.crtmppartid = null;
            this.crtmpparts = [];
            this.crtmpsectionname = null;
            this.cmpprojdetailform.pcqclist = [];
            this.cmppname = null;

            this.$http
                .post("project/getcqcreport", this.project)
                .then((response) => {

                if (this.user.id == null) {
                    this.user = this.$store.state.user;
                }
                var json = JSON.parse(response.data);
                this.detailform = json;
                this.getcqcdata = true;
                this.crtmthprojects = this.detailform.pdes.Projects;
                if (this.crtmthprojects == null) {
                    this.crtmthprojects = [];
                }
                this.showsetdata = this.getcriterias(this.detailform.pdes);

                this.getchunck(this.detailform.pdes).then((value) => {
                    this.showsetdata += value;
                });

                  var str = this.$store.state.section;
                var res = parseInt(str);
                if ( res == 1) {
                    this.custom.projectid  = {projectid:this.detailform.pdes.comparativesubprojectid,partid:this.detailform.pdes.comparativesubprojectpartid}
                    this.$bus.$emit('addproject',null);
                }
                if ( res != 1 && res != 8) {
                    this.custom.projectid  = {projectid:this.detailform.pdes.comparativesupprojectid,partid:this.detailform.pdes.comparativesupprojectpartid}
                    this.$bus.$emit('addproject',null);
                }
                if (res == 8) {
                    this.custom.projectid  = {projectid:this.detailform.pdes.comparativeextprojectid,partid:this.detailform.pdes.comparativeextprojectpartid}
                    this.$bus.$emit('addproject',null);
                }
                if (this.crtmthprojects.length != 0) {
                    this.checked = true;
                    this.showcheck = true;
                }else {this.checked = false;this.showcheck = false;}

                if (this.detailform.signoffby.id > 0) {
                    var dt = new Date(this.detailform.signoffon);
                    this.detailform.showdate = dt.toLocaleString().replace(/:\d{2}\s/, " ");
                }
                //   if (this.expandedrows.length == 0) {
                //     this.expandcollapse();
                //   }

                // this.psps = [];
                // json.pcqclist.forEach((c) => {
                //     if (this.psps.indexOf(c.cqclibrary.psp) == -1) {
                //         this.psps.push(c.cqclibrary.psp);
                //     }
                // })
                this.$store.state.cqcreport = this.detailform;
                // this.loading = false;
                this.$store.state.isLoading = false;
                this.detail = true;
            })
            .catch((err) => {
                // this.loading = false;
                this.$store.state.isLoading = false;
                this.$bus.$emit("error", err);
            });
        },

        getcmpProjectCQC() {
            // this.loading = true;
            this.$store.state.isLoading = true;

            this.currentcqc = null;

            this.$http
                .post("project/getcqcreport", this.cmpproject)
                .then((response) => {
                if (this.user.id == null) {
                    this.user = this.$store.state.user;
                }
                var json = JSON.parse(response.data);
                this.cmpprojdetailform = json;
                this.cqcrowheights('this.cmpprojdetailform.pcqclist', 'showaction');
                // this.loading = false;
                this.$store.state.isLoading = false;
                this.cmpdetail = true;
            })
            .catch((err) => {
                // this.loading = false;
                this.$store.state.isLoading = false;
                this.$bus.$emit("error", err);
            });
        },

        getincheckoutrows: function(c, icorow){
            var sumab = c.totala/c.totalb;
            if (!isFinite(sumab)) {
                sumab = 0;
            }
              sumab = parseFloat(sumab.toFixed(3));
            // if (c.cqclibrary[icorow.rowinmin] >= sumab && c.cqclibrary[icorow.rowinmax] <= sumab) {
            //     c.cqclibrary[icorow.rowstatus] = "In";
            //     c.cqclibrary[icorow.rowclass] = "tag-in";
            //     c.cqclibrary[icorow.rowaction] = "Within Owners Set Tolerances";
            //     c.cqclibrary[icorow.rowstyle] = "color:#00bd00; padding-left: 5px;";
            // } else {
            //     c.cqclibrary[icorow.rowstatus] = "";
            // }
            if (sumab >= c.cqclibrary[icorow.rowinmin] && sumab <= c.cqclibrary[icorow.rowinmax]) {
                c.cqclibrary[icorow.rowstatus] = "In";
                c.cqclibrary[icorow.rowclass] = "tag-in";
                c.cqclibrary[icorow.rowaction] = "Within Owners Set Tolerances";
                c.cqclibrary[icorow.rowstyle] = "color:#00bd00; padding-left: 5px;";
            } else {
                c.cqclibrary[icorow.rowstatus] = "";
            }

            // if (( c.cqclibrary[icorow.rowinmin] < sumab && c.cqclibrary[icorow.rowinmax] > sumab)
            // && (c.cqclibrary[icorow.rowcheckmin] >= sumab  &&  c.cqclibrary[icorow.rowcheckmax] <= sumab)) {
            //     c.cqclibrary[icorow.rowstatus] = "Check";
            //     c.cqclibrary[icorow.rowclass] = "tag-check";
            //     c.cqclibrary[icorow.rowaction] = "Owner Proposes that the Result is Checked";
            //     c.cqclibrary[icorow.rowstyle] = "color:#ffbd07; padding-left: 5px;";
            // }
            if ((sumab < c.cqclibrary[icorow.rowinmin] && sumab > c.cqclibrary[icorow.rowinmax])
            && (sumab >= c.cqclibrary[icorow.rowcheckmin] && sumab <= c.cqclibrary[icorow.rowcheckmax])) {
                c.cqclibrary[icorow.rowstatus] = "Check";
                c.cqclibrary[icorow.rowclass] = "tag-check";
                c.cqclibrary[icorow.rowaction] = "Owner Proposes that the Result is Checked";
                c.cqclibrary[icorow.rowstyle] = "color:#ffbd07; padding-left: 5px;";
            }

            // if (c.cqclibrary[icorow.rowcheckmin] < sumab && c.cqclibrary[icorow.rowcheckmax] > sumab) {
            //     c.cqclibrary[icorow.rowstatus] = "Out";
            //     c.cqclibrary[icorow.rowclass]= "tag-out";
            //     c.cqclibrary[icorow.rowaction] = "The Result Falls Outside 'Check' Tolerances";
            //     c.cqclibrary[icorow.rowstyle] = "color:rgb(128, 0, 0); padding-left: 5px;";
            //     //#ff0505
            // }
            if (sumab < c.cqclibrary[icorow.rowcheckmin] && sumab > c.cqclibrary[icorow.rowcheckmax]) {
                c.cqclibrary[icorow.rowstatus] = "Out";
                c.cqclibrary[icorow.rowclass]= "tag-out";
                c.cqclibrary[icorow.rowaction] = "The Result Falls Outside 'Check' Tolerances";
                c.cqclibrary[icorow.rowstyle] = "color:rgb(128, 0, 0); padding-left: 5px;";
                //#ff0505
            }

            // if (c.cqclibrary[icorow.rowstatus] == "") {
            //     c.cqclibrary[icorow.rowstatus] = "In";
            //     c.cqclibrary[icorow.rowclass] = "tag-in";
            //     c.cqclibrary[icorow.rowaction] = "Within Owners Set Tolerances";
            //     c.cqclibrary[icorow.rowstyle] = "color:#00bd00; padding-left: 5px;";
            // }
        },

        cqcrowheights: function(data, keyname){

            var cmpdata = eval(data);
            this.detailform.pcqclist.forEach(cl => {
                cmpdata.find(cmpcl => {
                    if (cmpcl.cqclibrary != undefined) {
                        if (cmpcl.cqclibrary.id == cl.cqclibrary.id) {
                            if (cl.cqclibrary[keyname] == true) {

                                cmpcl.cqclibrary.ownerpspheight = cl.cqclibrary.ownerpspheight;
                            }
                            else{

                                cmpcl.cqclibrary.ownerpspheight = cl.cqclibrary.ownerpspheight;
                            }
                        }
                    }
                });
            });
        },

        getset1set2ProjectCQC(setsproject,setname){
            this.$http.post("project/getset1set2CQCReport",setsproject)
            .then((response) => {
                if (setname == "set1criteria") {
                    var set1project = response.data;
                    this.getset1set2mthcqc(set1project,"set1criteria")
                    this.cqcrowheights('this.getcmpprojdetailform', 'set1showaction');
                }
                if (setname == "set2criteria") {
                    var set2project = response.data;
                    this.getset1set2mthcqc(set2project,"set2criteria")
                    this.cqcrowheights('this.getcmpprojdetailform', 'set2showaction');
                }
            })
            .catch((err) => {
                // this.loading = false;
                this.$store.state.isLoading = false;
                this.$bus.$emit("error", err);
            })
        },

        getset1set2mthcqc(setdata,setname){
            var set1pspicorows = {
                rowinmin: "set1pspinmin",
                rowinmax: "set1pspinmax",
                rowcheckmin: "set1pspcheckmin",
                rowcheckmax: "set1pspcheckmax",
                rowstatus: "set1pspstatus",
                rowclass: "set1pspclass",
                rowaction: "set1pspaction",
                rowstyle: "set1pspstyle",
            }

            var set2pspicorows = {
                rowinmin: "set2pspinmin",
                rowinmax: "set2pspinmax",
                rowcheckmin: "set2pspcheckmin",
                rowcheckmax: "set2pspcheckmax",
                rowstatus: "set2pspstatus",
                rowclass: "set2pspclass",
                rowaction: "set2pspaction",
                rowstyle: "set2pspstyle",
            }

            this.detailform.pcqclist.filter(c => {
            var cl = setdata.pcqclist.find(f => f.cqclibrary.id == c.cqclibrary.id);
                if (cl != null) {
                    if (setname == "set1criteria") {
                        if (c.totalab != null && c.totalab.length != 0) {
                            var set = c.totalab;
                            let cmpmean = c.totalab.reduce((a, b)=>{ return a + b }, 0) / c.totalab.length;
                            c.totalab = c.totalab.map((k)=>{ return (k - cmpmean) ** 2});
                            let squaresum = c.totalab.reduce((a, b)=> a + b, 0);
                            let variance = squaresum / c.totalab.length;
                            let sd = Math.sqrt(variance);

                            c.cqclibrary.set1pspmean = cmpmean;
                            c.cqclibrary.set1pspfullmax = Math.max(...set);
                            c.cqclibrary.set1pspfullmin = Math.min(...set);
                            c.cqclibrary.set1pspfullrange = c.cqclibrary.set1pspfullmax - c.cqclibrary.set1pspfullmin;
                            c.cqclibrary.set1pspinmax = cmpmean + sd;
                            c.cqclibrary.set1pspinmin = cmpmean - sd;
                            c.cqclibrary.set1pspinrange = c.cqclibrary.set1pspinmax - c.cqclibrary.set1pspinmin;
                            c.cqclibrary.set1pspcheckmax = c.cqclibrary.set1pspinmax + sd;
                            c.cqclibrary.set1pspcheckmin = c.cqclibrary.set1pspinmin - sd;
                            c.cqclibrary.set1pspcheckrange = c.cqclibrary.set1pspcheckmax - c.cqclibrary.set1pspcheckmin;
                            c.cqclibrary.set1showaction = true;

                            c.cqclibrary.ownerpspheight = 510;

                            this.getincheckoutrows(c, set1pspicorows);
                        }else{
                            c.cqclibrary.set1pspmean = 0;
                            c.cqclibrary.set1pspfullmax = 0;
                            c.cqclibrary.set1pspfullmin = 0;
                            c.cqclibrary.set1pspfullrange = 0;
                            c.cqclibrary.set1pspinmax = 0;
                            c.cqclibrary.set1pspinmin = 0;
                            c.cqclibrary.set1pspinrange = 0;
                            c.cqclibrary.set1pspcheckmax = 0;
                            c.cqclibrary.set1pspcheckmin = 0;
                            c.cqclibrary.set1pspcheckrange = 0;
                            c.cqclibrary.set1showaction = false;

                            c.cqclibrary.ownerpspheight = 294;
                        }
                    }

                    if (setname == "set2criteria") {
                        if (c.totalab != null && c.totalab.length != 0) {
                            var set = c.totalab;
                            let cmpmean = c.totalab.reduce((a, b)=>{ return a + b }, 0) / c.totalab.length;
                            c.totalab = c.totalab.map((k)=>{ return (k - cmpmean) ** 2});
                            let squaresum = c.totalab.reduce((a, b)=> a + b, 0);
                            let variance = squaresum / c.totalab.length;
                            let sd = Math.sqrt(variance);

                            c.cqclibrary.set2pspmean = cmpmean;
                            c.cqclibrary.set2pspfullmax = Math.max(...set);
                            c.cqclibrary.set2pspfullmin = Math.min(...set);
                            c.cqclibrary.set2pspfullrange = c.cqclibrary.set2pspfullmax - c.cqclibrary.set2pspfullmin;
                            c.cqclibrary.set2pspinmax = cmpmean + sd;
                            c.cqclibrary.set2pspinmin = cmpmean - sd;
                            c.cqclibrary.set2pspinrange = c.cqclibrary.set2pspinmax - c.cqclibrary.set2pspinmin;
                            c.cqclibrary.set2pspcheckmax = c.cqclibrary.set2pspinmax + sd;
                            c.cqclibrary.set2pspcheckmin = c.cqclibrary.set2pspinmin - sd;
                            c.cqclibrary.set2pspcheckrange = c.cqclibrary.set2pspcheckmax - c.cqclibrary.set2pspcheckmin;
                            c.cqclibrary.set2showaction = true;

                            c.cqclibrary.ownerpspheight = 693;

                            this.getincheckoutrows(c, set2pspicorows);
                        }else{
                            c.cqclibrary.set2pspmean = 0;
                            c.cqclibrary.set2pspfullmax = 0;
                            c.cqclibrary.set2pspfullmin = 0;
                            c.cqclibrary.set2pspfullrange = 0;
                            c.cqclibrary.set2pspinmax = 0;
                            c.cqclibrary.set2pspinmin = 0;
                            c.cqclibrary.set2pspinrange = 0;
                            c.cqclibrary.set2pspcheckmax = 0;
                            c.cqclibrary.set2pspcheckmin = 0;
                            c.cqclibrary.set2pspcheckrange = 0;
                            c.cqclibrary.set2showaction = false;

                            c.cqclibrary.ownerpspheight = 380;
                        }
                    }
                }
            });
        },

        refresh: function () {
            if (this.project.id && this.sectionid) {
                this.getProjectCQC();
            } else {
                this.detail = false;
            }
        },

        cmprefresh: function () {
            if (this.cmpproject.id && this.cmpsectionid) {
                this.getcmpProjectCQC();
                //this.getcmpprojdetailform();
            } else {
                this.cmpdetail = false;
            }
        },
        getallpspcriteria: function () {
            this.$http
            .post("project/getallpspcriteria")
            .then((response) => {
                this.pspcriterias = response.data;
            })
            .catch((err) => {
                this.$bus.$emit("error", err);
            });
        },

        errorclose: function (visible) {
            //to fix dialogue boxvisible in front ofthe error dialogue.
            //this.errorvisible = visible;
        },

        getcrtmpsections: function(event){
            this.crtmppartid = null;
            this.crtmpsectionname = null;
            this.crtmpsections = [];
            this.crtmthproject = event;
            this.cmppname = event.projectname;
            this.cmpprojdetailform.pcqclist = [];

            return this.$http
            .post("project/getsections", this.crtmthproject)
            .then((response) => {

                //   var json = JSON.parse(response.data);
                this.crtmpparts = event.projectparts;
                this.crtmppsections = response.data.projectsectionsview;
                if(this.crtmpparts.length== 0 &&  this.crtmpparts.projectname != ""){
                    this.crtmpparts.unshift({ id: 0, name: "Single Bill" });
                }
                return this.crtmpparts;
            })
            .catch((err) => {
                this.$bus.$emit("error", err);
                // this.loading = false;
                this.$store.state.isLoading = false;
            });
        },

        changecrtmppart: function(event){
            this.crtmt = null;
            this.crtmpsections = [];
            if (event == 0) {
                this.crtmppartid = 0;
                this.crtmpsections = this.crtmppsections.filter((s)=>{
                    if (s.projectPartId == 0) {
                        return s;
                    }
                });
            } else {
                this.crtmppartid = event;
                this.crtmpsections = this.crtmppsections.filter((s)=>{
                    if (s.projectPartId == event) {
                        return s;
                    }
                });
            }
            var projectpart = this.crtmpparts.find((p)=> p.id == event);
            this.crtmppart = projectpart;
            this.crtmthprojectobj.project = this.crtmthproject;
            this.crtmthprojectobj.sectionid = this.sectionid;
            this.crtmthprojectobj.part = this.crtmppart;
        },

        changecrtmpsection: function(event) {
            var section = this.crtmpsections.find((s) => s.id == event);
            this.crtmthproject.section = section;
            this.crtmthproject.projectsections = this.crtmpsections;
            this.crtmthprojectobj.project = this.crtmthproject;
            this.crtmthprojectobj.sectionid = this.sectionid;
            this.crtmthprojectobj.part = this.crtmppart;
            this.crtmthprojectsectionchange(this.crtmthprojectobj);
        },

        getpart: function (event) {
            this.crtmppartid = null;
            this.crtmpsectionname = null;
            this.crtmthproject = event;
            this.cmppname = event.projectname;
            this.cmpprojdetailform.pcqclist = [];

            this.$http
            .post("project/getsections", this.crtmthproject)
            .then((response) => {

                //   var json = JSON.parse(response.data);
                this.crtmpparts = event.projectparts;
                this.crtmpsections = response.data.projectsectionsview;
                if(this.crtmpparts.length== 0 &&  this.crtmpparts.projectname != ""){
                    this.crtmpparts.unshift({ id: 0, name: "Single Bill" });
                }
            })
            .catch((err) => {
                this.$bus.$emit("error", err);
                // this.loading = false;
                this.$store.state.isLoading = false;
            });
        },
        getcriterias: function(crtdata){
            var retstring = "";
            var comstring = "Common: ";var substring = "Substructure: ";var supstring = "Superstrcutre: ";var extstring = "External: ";
            var com = "ComCriteria";var sub = "SubCriteria";var sup = "SupCriteria";var ext = "ExtCriteria";
            for (let i = 1; i <= 4; i++) {
                comstring += this.getcriteria(com+i+"id",crtdata);
                substring += this.getcriteria(sub+i+"id",crtdata);
                supstring += this.getcriteria(sup+i+"id",crtdata);
                extstring += this.getcriteria(ext+i+"id",crtdata);
            }

            var str = this.$store.state.section;
            var res = parseInt(str);

            retstring += comstring + "\xa0\xa0\xa0\xa0";

            if (res == 1) {
                retstring += substring + "\xa0\xa0\xa0";
                this.crtqty.pspset = crtdata.SubNom;
            }

            if (res != 1 && res != 8) {
                retstring += supstring + "\xa0\xa0\xa0";
                this.crtqty.pspset = crtdata.SupNom;
            }

            if (res == 8) {
                retstring += extstring + "\xa0\xa0\xa0";
                this.crtqty.pspset = crtdata.ExtNom;
            }

            return retstring;
        },
    },

    computed: {
        getdetailform: function () {
            if (!this.detailform.pcqclist) {
                return [];
            }

            var ownericorows = {
                rowinmin: "tolerenceinab",
                rowinmax: "tolerencecheckab",
                rowcheckmin: "tolerenceinba",
                rowcheckmax: "tolerencecheckba",
                rowstatus: "ownerstatus",
                rowclass: "ownerclass",
                rowaction: "owneraction",
                rowstyle: "ownerstyle",
            }

            var pspicorows = {
                rowinmin: "pspinmin",
                rowinmax: "pspinmax",
                rowcheckmin: "pspcheckmin",
                rowcheckmax: "pspcheckmax",
                rowstatus: "pspstatus",
                rowclass: "pspclass",
                rowaction: "pspaction",
                rowstyle: "pspstyle",
            }

            this.detailform.pcqclist.filter((c) => {
                c.cqclibrary.inmedian = (c.cqclibrary.tolerenceinab + c.cqclibrary.tolerencecheckab) / 2;
                c.cqclibrary.checkmedian = (c.cqclibrary.tolerenceinba + c.cqclibrary.tolerencecheckba) / 2;
                c.cqclibrary.inrange = c.cqclibrary.tolerencecheckab - c.cqclibrary.tolerenceinab;
                c.cqclibrary.checkrange = c.cqclibrary.tolerencecheckba - c.cqclibrary.tolerenceinba;

                this.getincheckoutrows(c, ownericorows);

                // if (divab >= c.cqclibrary.tolerencecheckab) {
                //     c.status = "Check";
                // }else{ c.status = "In"; }

                if (this.getcqcdata == true) {
                    if (c.totalab != null && c.totalab.length != 0 && c.ischeck == undefined) {
                        c.ischeck = true;
                        var set = c.totalab;
                        let cmpmean = c.totalab.reduce((a, b)=>{ return a + b }, 0) / c.totalab.length;
                        c.totalab = c.totalab.map((k)=>{ return (k - cmpmean) ** 2});
                        let squaresum = c.totalab.reduce((a, b)=> a + b, 0);
                        let variance = squaresum / c.totalab.length;
                        let sd = Math.sqrt(variance);

                        c.cqclibrary.pspmean = cmpmean;
                        c.cqclibrary.pspfullmax = Math.max(...set);
                        c.cqclibrary.pspfullmin = Math.min(...set);
                        c.cqclibrary.pspfullrange = c.cqclibrary.pspfullmax - c.cqclibrary.pspfullmin;
                        c.cqclibrary.pspinmax = cmpmean + sd;
                        c.cqclibrary.pspinmin = cmpmean - sd;
                        c.cqclibrary.pspinrange = c.cqclibrary.pspinmax - c.cqclibrary.pspinmin;
                        c.cqclibrary.pspcheckmax = c.cqclibrary.pspinmax + sd;
                        c.cqclibrary.pspcheckmin = c.cqclibrary.pspinmin - sd;
                        c.cqclibrary.pspcheckrange = c.cqclibrary.pspcheckmax - c.cqclibrary.pspcheckmin;
                        c.cqclibrary.showaction = true;

                        c.cqclibrary.ownerpspheight = 334;

                        this.getincheckoutrows(c, pspicorows);
                    }else{
                        c.cqclibrary.pspmean = 0;
                        c.cqclibrary.pspfullmax = 0;
                        c.cqclibrary.pspfullmin = 0;
                        c.cqclibrary.pspfullrange = 0;
                        c.cqclibrary.pspinmax = 0;
                        c.cqclibrary.pspinmin = 0;
                        c.cqclibrary.pspinrange = 0;
                        c.cqclibrary.pspcheckmax = 0;
                        c.cqclibrary.pspcheckmin = 0;
                        c.cqclibrary.pspcheckrange = 0;
                        c.cqclibrary.showaction = false;

                        c.cqclibrary.ownerpspheight = 242;
                    }
                }

                if(c.cqclibrary.showaction == true){
                     if (c.cqclibrary.ownerstatus == "In" && c.cqclibrary.pspstatus == "In") {
                         c.status = "In";
                     } else if (c.cqclibrary.ownerstatus == "In" && c.cqclibrary.pspstatus == "Check") {
                         c.status = "Check";
                     } else if (c.cqclibrary.ownerstatus == "In" && c.cqclibrary.pspstatus == "Out") {
                         c.status = "Out";
                     } else if (c.cqclibrary.ownerstatus == "Check" && c.cqclibrary.pspstatus == "In") {
                         c.status = "Check";
                     } else if (c.cqclibrary.ownerstatus == "Check" && c.cqclibrary.pspstatus == "Check") {
                         c.status = "Check";
                     } else if (c.cqclibrary.ownerstatus == "Check" && c.cqclibrary.pspstatus == "Out") {
                         c.status = "Check";
                     } else if (c.cqclibrary.ownerstatus == "Out" && c.cqclibrary.pspstatus == "In") {
                         c.status = "Out";
                     } else if (c.cqclibrary.ownerstatus == "Out" && c.cqclibrary.pspstatus == "Check") {
                         c.status = "Out";
                     } else if (c.cqclibrary.ownerstatus == "Out" && c.cqclibrary.pspstatus == "Out") {
                         c.status = "Out";
                     } else {
                         c.status = "In";
                     }
                }else{
                    c.status = c.cqclibrary.ownerstatus;
                }
            });

            var data = this.detailform.pcqclist;
            this.getcqcdata = false;

            if (this.searchtype) {
                var search = this.searchtype.toLowerCase();
                data = data.filter((c) => {
                return c.cqclibrary.searchtype.toLowerCase().includes(search);
                });
            }
            // if (this.psp.length > 0) {
            //     data = data.filter((c) => {
            //         return this.psp.indexOf(c.cqclibrary.psp) >= 0;
            //     });
            // }
            return data;
        },

        getcmpprojdetailform: function(){
            if (!this.cmpprojdetailform.pcqclist) {
                return [];
            }
            var mainarray = []; var dfmatcharray = []; var dfunmtharray = [];
            if (this.cmpprojdetailform.pcqclist.length != 0) {
                this.detailform.pcqclist.forEach(cl => {
                    var mth = this.cmpprojdetailform.pcqclist.find(cmpcl => cmpcl.cqclibrary.id == cl.cqclibrary.id);
                    if (mth) {
                        dfmatcharray.push(cl);
                        mainarray.push(mth);
                    }
                    else{
                        dfunmtharray.push(cl);
                        // mainarray.push({});
                    }
                });
                dfmatcharray.push(...dfunmtharray);
                this.detailform.pcqclist = dfmatcharray;
                // var unmatched = [];
                // this.cmpprojdetailform.pcqclist.forEach(cl => {
                //     var unmth = this.detailform.pcqclist.find(cmpcl => cmpcl.cqclibrary.id == cl.cqclibrary.id);
                //     if (!unmth) {
                //         unmatched.push(cl);
                //     }
                // });
                // mainarray.push(...unmatched);
                if (this.searchtype) {
                    var search = this.searchtype.toLowerCase();
                    mainarray = mainarray.filter((c) => {
                        if (c.cqclibrary != undefined) {
                            return c.cqclibrary.searchtype.toLowerCase().includes(search);
                        }
                    });
                }
            }
            return mainarray;
        },

        getcriteria: function () {
            this.showsubcriteria = false;
            this.showsupcriteria = false;
            this.showextcriteria = false;
            this.showchunksize = false;
            var str = this.$store.state.section;
            var res = parseInt(str);
            return function (criterianame,crtdata) {
            var t =  this.pspcriterias.find(e => {
                    if (crtdata[criterianame] == e.id) {
                        return e;
                    }
                    // if (res == 1 && e.grouping == "sub") {
                    //     this.crtqty.pspset = crtdata.SubNom;
                    //     this.crtqty.qty = crtdata.Subqty.toFixed(1);
                    //     this.crtqty.unit = crtdata.subunit;
                    //     this.crtqty.crtname = "Building Footprint (m2)";
                    //     this.showsubcriteria = true;
                    //     this.showchunksize = true;
                    // }
                    // if (res == 8 && e.grouping == "ext") {
                    //     this.crtqty.pspset = crtdata.ExtNom;
                    //     this.crtqty.qty = crtdata.Extqty.toFixed(1);
                    //     this.crtqty.unit = crtdata.extunit;
                    //     this.crtqty.crtname = "Hard Surfaces (m2)";
                    //     this.showextcriteria = true;
                    //     this.showchunksize = true;
                    // }
                    // if (res != 1 && res != 8 && e.grouping == "sup") {
                    //     this.crtqty.pspset = crtdata.SupNom;
                    //     this.crtqty.qty = crtdata.Supqty.toFixed(1);
                    //     this.crtqty.unit = crtdata.supunit;
                    //     this.crtqty.crtname = "Gross Internal Floor (m2)";
                    //     this.showsupcriteria = true;
                    //     this.showchunksize = true;
                    // }
                });
                if (t == undefined) {
                    t = {};
                    return "";
                }
                return t.value + ";";
            }
        },

        // getpartsection: function(){
        //     if (this.crtmpsections.length > 0) {
        //         var partsection = [];
        //         partsection = this.crtmpsections.filter(e => e.projectPartId == this.crtmppartid);
        //         return partsection;
        //     }
        // },

        lastsaved: function () {
            if (this.detailform.signoffbyid > 0) {
                if (this.detailform.signoffon) {
                var tm = new Date(this.detailform.signoffon);
                var dt = tm.toLocaleString().replace(/:\d{2}\s/, " ");
                return dt;
                }
            }
        },
    },

    created: function () {
        this.getallpspcriteria();
        const params = new URLSearchParams(window.location.search);
        if (params.size > 0) {
            this.$training.getprojectusingparam(params);
        }
        this.$bus.$on("errorclose", this.errorclose);
    },

    beforeDestroy() {
        this.$bus.$off("errorclose", this.errorclose);
    },

    components: {
        projectselectpart: projectselectpart,
        projectdescriptionform : projectdescriptionform

    },
};
</script>

<style scoped>
.el-table .cell {
  /*make more space for textboxes inside el-table*/
  padding-left: 3px !important;
  padding-right: 3px !important;
}

.el-input-number .el-input__inner {
  text-align: right !important;
}

.el-input-number--mini {
  width: 100% !important;
}

.padding2px td {
  padding-left: 2px;
  padding-right: 2px;
  padding-top: 4px;
  padding-bottom: 4px;
}

.In {
    font-weight: bold;
    color: white;
    background-color: rgb(198, 224, 180);
    margin: 5px;
}

.Check {
  font-weight: bold;
  color: white;
  background-color: orange;
  margin: 5px;
}

.Out {
    font-weight: bold;
    color: white;
    /*background-color: red;*/
    background-color: rgb(128, 0, 0);
    margin: 5px;
}

.backcyan {
  background-color: #99ebffa3 !important;
}

.addrowspace {
  margin-bottom: 15px;
}

.addcomborder {
  border-top: 2px solid #ebeef5;
}

.tag-in {
    background-color: rgb(198, 224, 180) !important;
    color: white !important;
    border: 1px solid;
    border-radius: 0%;
    height: 28px;
    line-height: 26px;
    padding: 0 18px;
}

.tag-in-full {
  font-weight: bold;
  color: white;
  background-color: rgb(128, 81, 0);
  border: 1px solid;
  border-radius: 0%;
  height: 28px;
  line-height: 26px;
  padding: 0 18px;
}

.tag-check {
    /*background-color: white;
    color: darkorange;*/
    background-color: orange !important;
    color: white !important;
    border: 1px solid;
    border-radius: 0%;
    height: 28px;
    line-height: 26px;
    padding: 0 6px;
}

.tag-check-full {
   font-weight: bold;
  color: white;
  background-color: orange;
  border: 1px solid;
  border-radius: 0%;
  height: 28px;
  line-height: 26px;
  padding: 0 6px;
}

.tag-out {
    /*background-color: white;
    color: red;*/
    background-color: rgb(128, 0, 0) !important;
    color: white !important;
    border: 1px solid;
    border-radius: 0%;
    height: 28px;
    line-height: 26px;
    padding: 0 13px;
}

.totaltable {
    position: relative;
    overflow: hidden;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    width: 100%;
    max-width: 100%;
    font-size: 12px;
    color: #606266;
}

.row-expand-cover .el-table__expand-icon{ visibility:hidden; }

table.fixed { table-layout:fixed; }
table.fixed th { text-align: center; border: none; }
table.fixed td { overflow: hidden; border: none; }

.footer {
    height: 2em;
    bottom: 0;
    position: relative;
    z-index: 1;
}
</style>
