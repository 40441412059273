
const signalR = require("@aspnet/signalr");

class  callhub {
    constructor() {
        this.client = new signalR.HubConnectionBuilder()
            .withUrl('/api/call-hub')
            .configureLogging(signalR.LogLevel.Information)
            .build();
            this.start();
            this.reconnect();
    }
     start() {
        this.client.start().then(() => {
        }).catch(() => {
            window.setTimeout(this.start(), 5000);
        });
    }
     reconnect(){
        this.client.onclose(() => {
            this.start();
        });
    }
    stop() {
        this.client.stop().then(() => {
        }).catch((err) => {
            console.error(err)
        })
    }
   async SentSectionOpen(data) {
       return this.client.invoke('SentSectionOpen', data).then((data)=>{
            return data;
       }).catch(function (err) {
         console.error(err.toSting());
        });
    }

    async checkingdialogboxheaderpage() {
        return this.client.invoke('checkingdialogboxheaderpage').then(() => {
            return;
        }).catch(function (err) {
            console.error(err.toSting());
        });
    }
}

const call = new callhub();

export default{
    install(Vue){
        Vue.prototype.$signalr = call;
    }
}
// export default new callhub();
