<template>
    <div class="descriptinwidth">
        <div class="fixdheader">
            <div>
                <span class="headerstyle">
                    Project Description
                </span>
                <div class="floatright margintb1mm">
                        <el-button v-if="detail && view == 'As Setup'"
                                   :disabled="!editpermission || disbtnforcr"
                                   @click="cellchanged('setup_projectdescription_as_setup_save',()=>{save()},false)"
                                   id="setup_projectdescription_as_setup_save"
                                   type="success">Save</el-button>

                        <el-button v-if="detail && view == 'EH Estimating'"
                                   :disabled="!editpermission || disbtnforcr"
                                   @click="cellchanged('setup_projectdescription_eh_estimating_save',()=>{ehsave()},false)"
                                   id="setup_projectdescription_eh_estimating_save"
                                   type="success" class="marginleft4px">Save</el-button>

                        <el-button icon="el-icon-back"
                                   type="info"
                                   id="setup_projectdescription_back"
                                   @click="$router.go(-1)" class="marginleft4px">Back</el-button>
                </div>
            </div>

            <el-row>
                <el-col class="width110 margin2px">
                    <el-select v-model="view" :id="'setup_projectdescription_projecttypes'"
                               @change="viewimporteh">
                        <el-option v-for="item in ['As Setup', 'EH Estimating']"
                                   :key="item"
                                   :label="item"
                                   :value="item">
                        </el-option>
                    </el-select>
                </el-col>

                <el-col :span="20" v-if="view == 'As Setup'">
                    <selectpart :Similar="Similar2"
                                @projectpartchange="projectpartchange">
                    </selectpart>
                </el-col>

                <el-col :span="20" v-if="view == 'EH Estimating'">
                    <!--when user select "EH Estimating" display Estimate project hours project header-->
                    <ehselectpart :EHSimilar="estimateprojectheader"
                                  @ehprojectpartchange="estimateprojectpartheader">
                    </ehselectpart>
                </el-col>
            </el-row>

            <hr class="hrstyle" />
            <div>
                    <el-button type="primary"
                               @click="$router.push('/pspcriteria')"
                               plain
                               id="setup_projectdescription_psp_criteria_add_edit"
                               :disabled="!editpermission || disbtnforcr">
                        PSP Criteria Add/Edit
                    </el-button>

                    <el-button v-if="detail"
                               type="primary"
                               plain
                               id="setup_projectdescription_psp_summary"
                               @click="callpspsummary"
                               :disabled="disbtnforcr" class="marginleft4px">
                        PSP Summary
                    </el-button>

                    <el-button type="primary"
                               @click="$router.push('/independentmeasure')" class="marginleft4px"
                               plain
                               id="setup_projectdescription_independent_measure">
                        Independent Measure
                    </el-button>

                    <el-button v-if="view == 'As Setup'"
                               type="primary"
                               id="setup_projectdescription_import_estimate_hours"
                               @click="importdialog = true" class="marginleft4px">Import Estimate Hours</el-button>
            </div>
            <hr class="hrstyle" />
        </div>
        <div v-if="detail">
            <div>
                <span class="widthlabel103"><b>Groupings</b></span>
                <span class="paddingr83 marginleft4px"><b>PSP Criteria 1</b></span>
                <span class="widthlabel193 marginleft4px"><b>PSP Criteria 2</b></span>
                <span class="widthlabel193 marginleft4px"><b>PSP Criteria 3</b></span>
                <span class="widthlabel193 marginleft4px"><b>PSP Criteria 4</b></span>
                <span><b class="marginleft8px">NOM</b></span>
                <span class="paddingl13 marginleft4px"><b>UR(D)</b></span>
                <span class="paddingl13 marginleft4px"><b class="marginl5">Chunk Size</b></span>
            </div>
            <hr class="spaceBetween" />
            <div>
                <span class="widthlabel103"><b>Common</b></span>
                <span class="marginleft4px">
                    <el-select v-model="form.ComCriteria1id"
                               class="selectwidth175"
                               clearable
                               id="setup_projectdescription_com_criteria_id_1"
                               placeholder="">
                        <el-option v-for="(item, i) in com_psp1"
                                   :disabled="!editpermission || disofcrprjcrt"
                                   :key="i"
                                   :label="item.value"
                                   :value="item.id">
                        </el-option>
                    </el-select>
                </span>

                <span class="paddingr17 marginleft4px">
                    <el-select v-model="form.ComCriteria2id"
                               class="selectwidth175"
                               clearable
                               id="setup_projectdescription_com_criteria_id_2"
                               placeholder="">
                        <el-option v-for="(item, i) in com_psp2"
                                   :disabled="!editpermission || disofcrprjcrt"
                                   :key="i"
                                   :label="item.value"
                                   :value="item.id">
                        </el-option>
                    </el-select>
                </span>

                <span class="paddingr17 marginleft4px">
                    <el-select v-model="form.ComCriteria3id"
                               class="selectwidth175"
                               clearable
                               id="setup_projectdescription_com_criteria_id_3"
                               placeholder="">
                        <el-option v-for="(item, i) in com_psp3"
                                   :disabled="!editpermission || disofcrprjcrt"
                                   :key="i"
                                   :label="item.value"
                                   :value="item.id">
                        </el-option>
                    </el-select>
                </span>

                <span class="paddingr17 marginleft4px">
                    <el-select v-model="form.ComCriteria4id"
                               class="selectwidth175"
                               id="setup_projectdescription_com_criteria_id_4"
                               clearable
                               placeholder="">
                        <el-option v-for="(item, i) in com_psp4"
                                   :disabled="!editpermission || disofcrprjcrt"
                                   :key="i"
                                   :label="item.value"
                                   :value="item.id">
                        </el-option>
                    </el-select>
                </span>
            </div>
            <div class="spaceBetween" />
            <div>
                <span class="widthlabel103"><b>Substructure</b></span>
                <span class="marginleft4px">
                    <el-select v-model="form.SubCriteria1id"
                               class="selectwidth175"
                               clearable
                               id="setup_projectdescription_substruct_criteria_1"
                               placeholder="">
                        <el-option v-for="(item, i) in sub_psp1"
                                   :disabled="!editpermission || disofcrprjcrt"
                                   :key="i"
                                   :label="item.value"
                                   :value="item.id">
                        </el-option>
                    </el-select>
                </span>

                <span class="marginleft4px">
                    <el-select v-model="form.SubCriteria2id"
                               class="selectwidth175"
                               id="setup_projectdescription_substruct_criteria_2"
                               clearable
                               placeholder="">
                        <el-option v-for="(item,i) in sub_psp2"
                                   :disabled="!editpermission || disofcrprjcrt"
                                   :key="i"
                                   :label="item.value"
                                   :value="item.id">
                                </el-option>
                            </el-select>
                            <el-checkbox class="marginleft4px" size="small"
                                 id="setup_projectdescription_substruct_criteria_2_check"
                                 v-model="form.SubCriteria2Checked"
                                 :disabled="!editpermission || disofcrprjcrt"></el-checkbox>
                </span>

                <span>
                    <el-select v-model="form.SubCriteria3id"
                               class="selectwidth175 marginleft4px"
                               clearable
                               id="setup_projectdescription_substruct_criteria_3"
                               placeholder="">
                        <el-option v-for="(item, i) in sub_psp3"
                                   :disabled="!editpermission || disofcrprjcrt"
                                   :key="i"
                                   :label="item.value"
                                   :value="item.id">
                        </el-option>
                    </el-select>
                    <el-checkbox class="marginleft4px" size="small"
                                 v-model="form.SubCriteria3Checked"
                                 id="setup_projectdescription_substruct_criteria_3_check"
                                 :disabled="!editpermission || disofcrprjcrt"></el-checkbox>
                </span>

                <span>
                    <el-select v-model="form.SubCriteria4id"
                               class="selectwidth175 marginleft4px"
                               clearable
                               id="setup_projectdescription_substruct_criteria_4"
                               placeholder="">
                        <el-option v-for="(item, i) in sub_psp4"
                                   :disabled="!editpermission || disofcrprjcrt"
                                   :key="i"
                                   :label="item.value"
                                   :value="item.id">
                        </el-option>
                    </el-select>
                    <el-checkbox class="marginleft4px" size="small"
                                 v-model="form.SubCriteria4Checked"
                                 id="setup_projectdescription_substruct_criteria_4_check"
                                 :disabled="!editpermission || disofcrprjcrt"></el-checkbox>
                </span>
                <span>
                    <el-input class="rightimp inputwidth45 marginleft4px"
                              :disabled="!editpermission || disofcrprjcrt"
                                 id="setup_projectdescription_substruct_nom"
                              v-model="form.SubNom"></el-input>
                </span>
                <span>
                    <!-- <el-input class="rightimp inputwidth45"
                              maxlength="5"
                              v-model="form.SubUR"
                              :disabled="!editpermission || disofcrprjcrt"
                              onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                              @input.native="checkurinput"
                              @change="urchanged(form.SubUR, 'SubUR')"></el-input> -->
                               <el-select v-model="form.SubUR" class="rightimp inputwidth50 marginleft4px" clearable remote reserve-keyword filterable  placeholder="UR(D)" id="setup_projectdescription_substruct_urd">
                                          <el-option
                                             v-for="(item, i) in urs"
                                             :key="i"
                                             :label="item.ur"
                                             :value="item.ur">
                                           </el-option>
                                         </el-select>
                </span>
                <span class="fontsize12">{{getChunk(form.SubUR)}}</span>
            </div>
            <div class="spaceBetween" />
            <div>
                <span class="widthlabel103"><b>Superstructure</b></span>
                <span class="marginleft4px">
                    <el-select v-model="form.SupCriteria1id"
                               class="selectwidth175"
                               clearable
                                 id="setup_projectdescription_superstructure_criteria_1"
                               placeholder="">
                        <el-option v-for="(item, i) in sup_psp1"
                                   :disabled="!editpermission || disofcrprjcrt"
                                   :key="i"
                                   :label="item.value"
                                   :value="item.id">
                        </el-option>
                    </el-select>
                </span>
                <span class="marginleft4px">
                    <el-select v-model="form.SupCriteria2id"
                               class="selectwidth175"
                               clearable
                               id="setup_projectdescription_superstructure_criteria_2"
                               placeholder="">
                        <el-option v-for="(item, i) in sup_psp2"
                                   :disabled="!editpermission || disofcrprjcrt"
                                   :key="i"
                                   :label="item.value"
                                   :value="item.id">
                        </el-option>
                    </el-select>
                    <el-checkbox class="marginleft4px" size="small"
                                 v-model="form.SupCriteria2Checked"
                                 id="setup_projectdescription_superstructure_criteria_2_check"
                                 :disabled="!editpermission || disofcrprjcrt"></el-checkbox>
                </span>
                <span class="marginleft4px">
                    <el-select v-model="form.SupCriteria3id"
                               class="selectwidth175"
                               clearable
                                 id="setup_projectdescription_superstructure_criteria_3"
                               placeholder="">
                        <el-option v-for="(item, i) in sup_psp3"
                                   :disabled="!editpermission || disofcrprjcrt"
                                   :key="i"
                                   :label="item.value"
                                   :value="item.id">
                        </el-option>
                    </el-select>
                    <el-checkbox class="marginleft4px" size="small"
                                 v-model="form.SupCriteria3Checked"
                                 id="setup_projectdescription_superstructure_criteria_3_check"
                                 :disabled="!editpermission || disofcrprjcrt"></el-checkbox>
                </span>
                <span class="marginleft4px">
                    <el-select v-model="form.SupCriteria4id"
                               class="selectwidth175"
                               clearable
                                 id="setup_projectdescription_superstructure_criteria_4"
                               placeholder="">
                        <el-option v-for="(item, i) in sup_psp4"
                                   :disabled="!editpermission || disofcrprjcrt"
                                   :key="i"
                                   :label="item.value"
                                   :value="item.id">
                        </el-option>
                    </el-select>
                    <el-checkbox class="marginleft4px" size="small"
                                 v-model="form.SupCriteria4Checked"
                                 id="setup_projectdescription_superstructure_criteria_4_check"
                                 :disabled="!editpermission || disofcrprjcrt"></el-checkbox>
                </span>
                <span>
                    <el-input class="rightimp inputwidth45 marginleft4px"
                              :disabled="!editpermission || disofcrprjcrt"
                                 id="setup_projectdescription_superstructure_nom"
                              v-model="form.SupNom"></el-input>
                </span>
                <span>
                    <!-- <el-input class="rightimp inputwidth45"
                              maxlength="5"
                              v-model="form.SupUR"
                              :disabled="!editpermission || disofcrprjcrt"
                              onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                              @input.native="checkurinput"
                              @change="urchanged(form.SupUR, 'SupUR')"></el-input> -->
                                <el-select v-model="form.SupUR" class="rightimp inputwidth50 marginleft4px" clearable remote  reserve-keyword filterable  placeholder="UR(D)" id="setup_projectdescription_superstructure_urd">
                                          <el-option
                                             v-for="(item, i) in urs"
                                             :key="i"
                                             :label="item.ur"
                                             :value="item.ur">
                                           </el-option>
                                         </el-select>
                </span>
                <span class="fontsize12 marginleft4px">{{getChunk(form.SupUR)}}</span>
            </div>

            <div class="spaceBetween" />
            <div>
                <span class="widthlabel103"><b>Externals</b></span>
                <span class="marginleft4px">
                    <el-select v-model="form.ExtCriteria1id"
                               class="selectwidth175"
                               clearable
                                id="setup_projectdescription_externals_criteria_1"
                               placeholder="">
                        <el-option v-for="(item, i) in ext_psp1"
                                   :disabled="!editpermission || disofcrprjcrt"
                                   :key="i"
                                   :label="item.value"
                                   :value="item.id">
                        </el-option>
                    </el-select>
                </span>
                <span class="marginleft4px">
                    <el-select v-model="form.ExtCriteria2id"
                               clearable
                               placeholder=""
                                id="setup_projectdescription_externals_criteria_2"
                               class="selectwidth175">
                        <el-option v-for="(item, i) in ext_psp2"
                                   :disabled="!editpermission || disofcrprjcrt"
                                   :key="i"
                                   :label="item.value"
                                   :value="item.id">
                        </el-option>
                    </el-select>
                    <el-checkbox class="marginleft4px" size="small"
                                 v-model="form.ExtCriteria2Checked"
                                id="setup_projectdescription_externals_criteria_2_check"
                                 :disabled="!editpermission || disofcrprjcrt"></el-checkbox>
                </span>
                <span class="marginleft4px">
                    <el-select v-model="form.ExtCriteria3id"
                               clearable
                               class="selectwidth175"
                                id="setup_projectdescription_externals_criteria_3"
                               placeholder="">
                        <el-option v-for="(item, i) in ext_psp3"
                                   :disabled="!editpermission || disofcrprjcrt"
                                   :key="i"
                                   :label="item.value"
                                   :value="item.id">
                        </el-option>
                    </el-select>
                    <el-checkbox class="marginleft4px" size="small"
                                 v-model="form.ExtCriteria3Checked"
                                id="setup_projectdescription_externals_criteria_3_check"
                                 :disabled="!editpermission || disofcrprjcrt"></el-checkbox>
                </span>
                <span class="marginleft4px">
                    <el-select v-model="form.ExtCriteria4id"
                               clearable
                                id="setup_projectdescription_externals_criteria_4"
                               class="selectwidth175"
                               placeholder="">
                        <el-option v-for="(item, i) in ext_psp4"
                                   :disabled="!editpermission || disofcrprjcrt"
                                   :key="i"
                                   :label="item.value"
                                   :value="item.id">
                        </el-option>
                    </el-select>
                    <el-checkbox class="marginleft4px" size="small"
                                 v-model="form.ExtCriteria4Checked"
                                id="setup_projectdescription_externals_criteria_4_check"
                                 :disabled="!editpermission || disofcrprjcrt"></el-checkbox>
                </span>
                <span>
                    <el-input class="rightimp inputwidth45 marginleft4px"
                              :disabled="!editpermission || disofcrprjcrt"
                                id="setup_projectdescription_externals_nom"
                              v-model="form.ExtNom"></el-input>
                </span>
                <span>
                    <!-- <el-input class="rightimp inputwidth45"
                              maxlength="5"
                              v-model="form.ExtUR"
                              :disabled="!editpermission || disofcrprjcrt"
                              onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                              @input.native="checkurinput"
                              @change="urchanged(form.ExtUR, 'ExtUR')"></el-input> -->
                               <el-select v-model="form.ExtUR" class="rightimp inputwidth50 marginleft4px" clearable remote reserve-keyword filterable  placeholder="UR(D)" id="setup_projectdescription_externals_urd">
                                           <el-option
                                             v-for="(item, i) in urs"
                                             :key="i"
                                             :label="item.ur"
                                             :value="item.ur">
                                           </el-option>
                                         </el-select>
                </span>
                <span class="fontsize12 marginleft4px">{{getChunk(form.ExtUR)}}</span>
            </div>
            <div class="spaceBetween" />
            <hr class="hrspacebetween hrstyle" />
            <hr class="hrspacebetween hrstyle" />
            <el-row>
                <div>
                    <span class="left widthlabel103"><b>Add Similar</b></span>
                    <span>
                        <selectpart :Similar="Similar1"
                                    @projectpartchange="projectpartchangesimilar">
                        </selectpart>
                    </span>
                </div>
            </el-row>
            <el-row>
                <div>
                    <span class="left widthlabel103">
                        <el-button @click="addsimilarproject"
                                   type="primary"
                                    id="setup_projectdescription_add_similar_project"
                                   icon="el-icon-circle-plus"
                                   :disabled="!editpermission || disofcrprjcrt"></el-button>
                    </span>
                    <div>
                        <span class="left marginleft8px">
                            <el-table :data="form.Projectsimilars"
                                      class="fullwidth"
                                      size="mini">
                                <el-table-column prop="projectname"
                                                 width="320"
                                                 label="Project Name">
                                </el-table-column>
                                <el-table-column prop="partname"
                                                 width="320"
                                                 label="Project Part Name">
                                </el-table-column>
                                <el-table-column label="Action " width="65">
                                    <template slot-scope="scope">
                                        <el-button size="mini"
                                                   type="danger"
                                                   icon="el-icon-delete"
                                                    :id="'setup_projectdescription_edit_similar_project_'+scope.$index"
                                                   :disabled="!editpermission || disofcrprjcrt"
                                                   @click="deletesimilar(scope.$index)"></el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </span>
                    </div>
                </div>
            </el-row>

            <hr class="hrspacebetween hrstyle" />
            <hr class="hrspacebetween hrstyle" />
            <div>
                <span class="widthlabel103"><b>SD Exeption</b></span>
                <span class="marginleft8px">
                    <el-input :disabled="!editpermission || disofcrprjcrt"
                              v-model="form.SDExceptions"
                              class="width290"
                              id="setup_projectdescription_sd_exeption"
                              placeholder="SD Exceptions"></el-input>
                </span>
            </div>
            <div class="spaceBetween"></div>
            <div>
                <span class="widthlabel103"><b>File Link</b></span>
                <span class="marginleft8px">
                    <el-input :disabled="!editpermission || disofcrprjcrt"
                              class="width290"
                              id="setup_projectdescription_file_link"
                              v-model="form.FileLink"
                              placeholder="File Link"></el-input>
                </span>
            </div>
            <div class="spaceBetween"></div>

            <el-row>
                <hr class="hrspacebetween hrstyle" />
                <hr class="hrspacebetween hrstyle" />
            </el-row>
            <div>
                <span><b>Comparative Project Selection</b></span>
            </div>
            <div class="spaceBetween" />
            <el-row>
                <div>
                    <span class="left widthlabel103"><b>Substructure</b></span>
                    <span class="left">
                        <selectpart :Similar="SimilarSub"
                                    @projectpartchange="projectpartchangecomparesub">
                        </selectpart>
                    </span>
                </div>
            </el-row>
            <div class="spaceBetween" />
            <el-row>
                <div>
                    <span class="left widthlabel103"><b>Superstructure</b></span>
                    <span>
                        <selectpart :Similar="SimilarSup"
                                    @projectpartchange="Projectpartchangecomparesuper">
                        </selectpart>
                    </span>
                </div>
            </el-row>
            <div class="spaceBetween" />
            <el-row>
                <div>
                    <span class="left widthlabel103"><b>Externals</b></span>
                    <span class="left forminputamt">
                        <selectpart :Similar="SimilarExt"
                                    @projectpartchange="projectpartchangecompareext">
                        </selectpart>
                    </span>
                    <div class="floatright"> 
                        <div class="row">
                            <div class="lastcomby">Completed By: </div>
                            <div class="lastcomby">
                                <div class="el-input el-input--mini bold " v-if="form.CompletedByuser">
                                    <span v-if="form.CompletedByuser">
                                        {{ form.CompletedByuser.loginname }}
                                    </span>
                                </div>
                            </div>
                            <div class="lastcomby">
                                <div class="el-input el-input--mini bold " v-if="form.CompletedByuser">
                                    <span class="paddingr17" v-if="completedOn">{{ completedOn }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </el-row>
            <div class="spaceBetween" />
            <hr class="hrspacebetween hrstyle" />
            <hr class="hrspacebetween hrstyle" />
        </div>
        <div>
            <el-dialog title="Import Estimate Hours"
                       :visible.sync="importdialog"
                       top="25vh" width="680px"
                       class="titlefontchange chngdialgpadding">

                <div class="fontandmargin">Add Project Hours (Estimating Form) PSP Data</div>
                <ehselectpart :EHSimilar="ehsimilerimport"
                              @ehprojectpartchange="ehprojectpartimport"></ehselectpart>

                <div class="fontandmargin margintop20px">To Setup Project (Based on EH Data)</div>
                <selectpart :Similar="importsimilerproject"
                            @projectpartchange="projectpartimport">
                </selectpart>

                <span slot="footer" class="dialog-footer">
                    <el-button @click="importdialog = false" id="setup_projectdescription_importdialog_cancel">Cancel</el-button>
                    <el-button size="mini" type="primary" @click="importconfirm" id="setup_projectdescription_importdialog_confirm">Confirm</el-button>
                </span>
            </el-dialog>
        </div>
    </div>
</template>
<script>
    import moment from "moment";
    import selectpart from "./selectpart.vue";
    import ehselectpart from "./ehselectpart.vue";
    export default {
        props: {
            cqcreportprjcrt: { type: Object }
        },
        data() {
            return {
                imt:[],
                urs:[],
                disofcrprjcrt: false,
                disbtnforcr: false,
                projectsimilar: {},
                projectpartsimilar: {},
                // comparativesubprojectid: {},
                // comparativesubprojectpartid: {},
                // comparativesupprojectid: {},
                // comparativesupprojectpartid: {},
                // comparativeextprojectid: {},
                // comparativeextprojectpartid: {},
                Similar2: {
                    projectPlaceholder: "",
                    partPlaceholder: "",
                    lastsavedshow: true,
                },
                Similar1: {
                    projectPlaceholder: "Similar Projects",
                    partPlaceholder: "Similar Project Parts",
                    lastsavedshow: false,
                    close: true,
                },
                SimilarSub: {
                    projectPlaceholder: "Substructure Projects",
                    partPlaceholder: "Substructure Project Parts",
                    lastsavedshow: false,
                    projectids: null,
                    close: true,
                },
                SimilarSup: {
                    projectPlaceholder: "Superstructure Projects",
                    partPlaceholder: "Superstructure Project Parts",
                    lastsavedshow: false,
                    projectids: null,
                    close: true,
                },
                SimilarExt: {
                    projectPlaceholder: "Externals Projects",
                    partPlaceholder: "Externals Project Parts",
                    lastsavedshow: false,
                    projectids: null,
                    close: true,
                },
                com_psp1: [],
                com_psp2: [],
                com_psp3: [],
                com_psp4: [],
                sub_psp1: [],
                sub_psp2: [],
                sub_psp3: [],
                sub_psp4: [],
                sup_psp1: [],
                sup_psp2: [],
                sup_psp3: [],
                sup_psp4: [],
                ext_psp1: [],
                ext_psp2: [],
                ext_psp3: [],
                ext_psp4: [],
                pspcriterias: [],
                detail: false,
                project: {},
                projectpart: {},
                detailform: {},
                groupings: [],
                grouping: [],
                checked: false,
                form: {},
                view: "As Setup",
                importdialog: false,
                estimateprojectheader: {
                    projectPlaceholder: "",
                    partPlaceholder: "",
                    lastsavedshow: true
                },
                ehsimilerimport: {
                    projectPlaceholder: "",
                    partPlaceholder: "",
                    lastsavedshow: false
                },
                importsimilerproject: {
                    projectPlaceholder: "",
                    partPlaceholder: "",
                    lastsavedshow: false
                },
                ehproject: {},
                ehpart: {},
                impdis: false
            };
        },
        computed: {
            getChunk(){
                return function (data) {
                    if(data != "" && data != null){
                        var selectur = this.imt.find(x=> x.ur == data);
                        if(selectur)
                         {return selectur.datadescription + "" + "("+ selectur.unit +")"}
                        else
                         {
                           return ""
                         }
                    }else{
                        return ""
                    }
                }
            },
            completedOn: function () {
                if (this.form.CompletedOn) {
                    var dt = new Date(this.form.CompletedOn);
                    return moment(dt).format("DD-MMM-YYYY,h:mm"); // dt.toLocaleString().replace(/:\d{2}\s/, " ");
                }
            }
        },
        methods: {
            viewimporteh: function () {
                this.view == "As setup" ? "As Setup" : "EH Estimating";
                this.detail = false;
            },
            estimateprojectpartheader: function (estimateproject) {
                this.ehproject = estimateproject.ehproject;
                this.ehpart = estimateproject.ehpart;
                this.ehrefresh();
            },
            ehprojectpartimport: function (estimateproject) {
                this.ehproject = estimateproject.ehproject;
                this.ehpart = estimateproject.ehpart;
            },
            projectpartimport: function (Projectobj) {
                this.project = Projectobj.project;
                this.projectpart = Projectobj.part;
            },
            ehrefresh: function () {
                if (this.ehproject.id && this.ehpart.id >= 0) {
                    this.detail = true;
                    this.getehprojectdes();
                } else {
                    this.detail = false;
                }
            },
            getehprojectdes: function () {
                var data1 = { projectid: this.ehproject.id, partid: this.ehpart.id, isehproject: true };
                this.$http.post("hoursestimate/getEHProjectDis", data1)
                    .then(response => {
                        this.form = response.data;
                        if (this.form.Projectsimilars == null) {
                            this.form.Projectsimilars = [];
                        }
                        this.getprojectbyid();
                        this.chackCriteriaiszero();
                    })
                    .catch(err => {
                        this.$bus.$emit("error", err);
                    });
            },
            getsection(){
                // Get NRM section for each page
                if(false){
                    // return this.detailform.section;
                }else{
                    return "";
                }
            },
                cellchanged(cellaction,callback,cancall){
                if(cancall){
                    callback();
                    this.canmatchcellid(()=>{},cellaction,this.getsection());
                }else{
                    this.canmatchcellid(callback,cellaction,this.getsection());
                }
            },  
            ehsave: function () {
                this.validate(valid => {
                    if (valid) {
                        this.form.isehproject = true;
                        this.form.groups.find(x => {
                            if (x.groupname == "Substructure") {
                                x.criteria1id = this.form.SubCriteria1id == null ? 0 : this.form.SubCriteria1id;
                                x.criteria2id = this.form.SubCriteria2id == null ? 0 : this.form.SubCriteria2id;
                                x.criteria3id = this.form.SubCriteria3id == null ? 0 : this.form.SubCriteria3id;
                                x.criteria4id = this.form.SubCriteria4id == null ? 0 : this.form.SubCriteria4id;
                            }
                            if (x.groupname == "Superstructure") {
                                x.criteria1id = this.form.SupCriteria1id == null ? 0 : this.form.SupCriteria1id;
                                x.criteria2id = this.form.SupCriteria2id == null ? 0 : this.form.SupCriteria2id;
                                x.criteria3id = this.form.SupCriteria3id == null ? 0 : this.form.SupCriteria3id;
                                x.criteria4id = this.form.SupCriteria4id == null ? 0 : this.form.SupCriteria4id;
                            }
                            if (x.groupname == "External") {
                                x.criteria1id = this.form.ExtCriteria1id == null ? 0 : this.form.ExtCriteria1id;
                                x.criteria2id = this.form.ExtCriteria2id == null ? 0 : this.form.ExtCriteria2id;
                                x.criteria3id = this.form.ExtCriteria3id == null ? 0 : this.form.ExtCriteria3id;
                                x.criteria4id = this.form.ExtCriteria4id == null ? 0 : this.form.ExtCriteria4id;
                            }
                        });
                        this.chackCriteriaisempty(this.form);
                        this.$http.post("hoursestimate/ehsaveprojectdescription", this.form)
                            .then(response => {
                                this.$message({
                                    showClose: true,
                                    message: "Project description saved successfully!",
                                    type: "success"
                                });
                                this.ehrefresh();
                            })
                            .catch(err => {
                                this.$bus.$emit("error", err);
                            });
                    } else {
                        return false;
                    }
                });
            },
            displayimported: function () {
                var data = { projectid: this.ehproject.id, projectpartid: this.ehpart.id };
                this.$http.post("hoursestimate/importehtoSetup", data)
                    .then(response => {
                        response.data.forEach(r => {
                            if (r.groupname == "Substructure") {
                                this.form.SubCriteria1id = r.criteria1id == null ? null : r.criteria1id;
                                this.form.SubCriteria2id = r.criteria2id == null ? null : r.criteria2id;
                                this.form.SubCriteria3id = r.criteria3id == null ? null : r.criteria3id;
                                this.form.SubCriteria4id = r.criteria4id == null ? null : r.criteria4id;
                            }
                            if (r.groupname == "Superstructure") {
                                this.form.SupCriteria1id = r.criteria1id == null ? null : r.criteria1id;
                                this.form.SupCriteria2id = r.criteria2id == null ? null : r.criteria2id;
                                this.form.SupCriteria3id = r.criteria3id == null ? null : r.criteria3id;
                                this.form.SupCriteria4id = r.criteria4id == null ? null : r.criteria4id;
                            }
                            if (r.groupname == "External") {
                                this.form.ExtCriteria1id = r.criteria1id == null ? null : r.criteria1id;
                                this.form.ExtCriteria2id = r.criteria2id == null ? null : r.criteria2id;
                                this.form.ExtCriteria3id = r.criteria3id == null ? null : r.criteria3id;
                                this.form.ExtCriteria4id = r.criteria4id == null ? null : r.criteria4id;
                            }
                        });
                    })
                    .catch(err => {
                        this.$bus.$emit("error", err);
                    });
            },
            importconfirm: function () {
                this.Similar2.projectids = { projectid: this.project.id, partid: this.projectpart.id }
                this.$bus.$emit('addprojectpart', null);
                this.impdis = true;
                this.importdialog = false;
            },
            callpspsummary: function () {
                this.$store.state.descriptiondata = this.form;
                this.$router.push({ path: "/pspsummary" });
            },
            deletesimilar: function (index) {
                this.form.Projectsimilars.splice(index, 1);
            },
            addsimilarproject: function () {
                if (this.projectsimilar.id && this.projectpartsimilar.id >= 0) {
                    if (
                        this.projectsimilar.id != this.project.id ||
                        this.projectpartsimilar.id != this.projectpart.id
                    ) {
                        var find = this.form.Projectsimilars.find(
                            s =>
                                s.similarprojectid == this.projectsimilar.id &&
                                s.similarprojectpartid == this.projectpartsimilar.id
                        );
                        if (find) {
                            this.$alert(
                                "Same project, part already exists",
                                "Warning",
                                { type: "Warning" }
                            );
                        } else {
                            var similardata = {
                                projectname: this.projectsimilar.projectname,
                                partname: this.projectpartsimilar.name,
                                similarprojectid: this.projectsimilar.id,
                                similarprojectpartid: this.projectpartsimilar.id
                            };
                            this.form.Projectsimilars.push(similardata);
                        }
                    } else {
                        this.$alert(
                            "Same project, part already exists",
                            "Warning",
                            { type: "Warning" }
                        );
                    }
                }
            },
            chackCriteriaisempty: function (form) {
                if (form.ComCriteria1id == undefined || form.ComCriteria1id == "") {
                    form.ComCriteria1id = 0;
                }
                if (form.ComCriteria2id == undefined || form.ComCriteria2id == "") {
                    form.ComCriteria2id = 0;
                }
                if (form.ComCriteria3id == undefined || form.ComCriteria3id == "") {
                    form.ComCriteria3id = 0;
                }
                if (form.ComCriteria4id == undefined || form.ComCriteria4id == "") {
                    form.ComCriteria4id = 0;
                }

                if (form.SupCriteria1id == undefined || form.SupCriteria1id == "") {
                    form.SupCriteria1id = 0;
                }
                if (form.SupCriteria2id == undefined || form.SupCriteria2id == "") {
                    form.SupCriteria2id = 0;
                }
                if (form.SupCriteria3id == undefined || form.SupCriteria3id == "") {
                    form.SupCriteria3id = 0;
                }
                if (form.SupCriteria4id == undefined || form.SupCriteria4id == "") {
                    form.SupCriteria4id = 0;
                }

                if (form.SubCriteria1id == undefined || form.SubCriteria1id == "") {
                    form.SubCriteria1id = 0;
                }
                if (form.SubCriteria2id == undefined || form.SubCriteria2id == "") {
                    form.SubCriteria2id = 0;
                }
                if (form.SubCriteria3id == undefined || form.SubCriteria3id == "") {
                    form.SubCriteria3id = 0;
                }
                if (form.SubCriteria4id == undefined || form.SubCriteria4id == "") {
                    form.SubCriteria4id = 0;
                }

                if (form.ExtCriteria1id == undefined || form.ExtCriteria1id == "") {
                    form.ExtCriteria1id = 0;
                }
                if (form.ExtCriteria2id == undefined || form.ExtCriteria2id == "") {
                    form.ExtCriteria2id = 0;
                }
                if (form.ExtCriteria3id == undefined || form.ExtCriteria3id == "") {
                    form.ExtCriteria3id = 0;
                }
                if (form.ExtCriteria4id == undefined || form.ExtCriteria4id == "") {
                    form.ExtCriteria4id = 0;
                }
            },

            chackCriteriaiszero() {
                if (this.form.ComCriteria1id == 0) {
                    this.form.ComCriteria1id = "";
                }
                if (this.form.ComCriteria2id == 0) {
                    this.form.ComCriteria2id = "";
                }
                if (this.form.ComCriteria3id == 0) {
                    this.form.ComCriteria3id = "";
                }
                if (this.form.ComCriteria4id == 0) {
                    this.form.ComCriteria4id = "";
                }

                if (this.form.SupCriteria1id == 0) {
                    this.form.SupCriteria1id = "";
                }
                if (this.form.SupCriteria2id == 0) {
                    this.form.SupCriteria2id = "";
                }
                if (this.form.SupCriteria3id == 0) {
                    this.form.SupCriteria3id = "";
                }
                if (this.form.SupCriteria4id == 0) {
                    this.form.SupCriteria4id = "";
                }

                if (this.form.SubCriteria1id == 0) {
                    this.form.SubCriteria1id = "";
                }
                if (this.form.SubCriteria2id == 0) {
                    this.form.SubCriteria2id = "";
                }
                if (this.form.SubCriteria3id == 0) {
                    this.form.SubCriteria3id = "";
                }
                if (this.form.SubCriteria4id == 0) {
                    this.form.SubCriteria4id = "";
                }

                if (this.form.ExtCriteria1id == 0) {
                    this.form.ExtCriteria1id = "";
                }
                if (this.form.ExtCriteria2id == 0) {
                    this.form.ExtCriteria2id = "";
                }
                if (this.form.ExtCriteria3id == 0) {
                    this.form.ExtCriteria3id = "";
                }
                if (this.form.ExtCriteria4id == 0) {
                    this.form.ExtCriteria4id = "";
                }
                this.form.Projectsimilars.forEach(ps => {
                    this.form.Projects.find(p => {
                        if (p != null) {
                            if (p.id == ps.similarprojectid) {
                                ps.projectname = p.projectname;
                            }
                        }
                    });
                    this.form.parts.find(p => {
                        if (p != null) {
                            if (p.id == ps.similarprojectpartid) {
                                ps.partname = p.name;
                            }
                        }
                    });
                    if (ps.partname == undefined) {
                        ps.partname = "Single Bill";
                    }
                });
            },
            validate: function (callback) {
                var msg = [];
                var msgstr = "";
                if (
                    this.form.ExtUR == this.form.SubUR &&
                    this.form.ExtUR != "" &&
                    this.form.SubUR != ""
                ) {
                    msgstr = "Unique Reference (UR)  is duplicate";
                }
                if (
                    this.form.ExtUR == this.form.SupUR &&
                    this.form.ExtUR != "" &&
                    this.form.SupUR != ""
                ) {
                    msgstr = "Unique Reference (UR)  is duplicate";
                }
                if (
                    this.form.SupUR == this.form.SubUR &&
                    this.form.SupUR != "" &&
                    this.form.SubUR != ""
                ) {
                    msgstr = "Unique Reference (UR)  is duplicate";
                }
                if (
                    this.form.ExtUR == "" &&
                    this.form.SubUR == "" &&
                    this.form.SupUR == ""
                ) {
                    msgstr = "";
                }
                if (
                    this.form.ExtUR == null &&
                    this.form.SubUR == null &&
                    this.form.SupUR == null
                ) {
                    msgstr = "";
                }
                if (this.form.ExtUR == null && this.form.SubUR == null) {
                    msgstr = "";
                }
                if (this.form.ExtUR == null && this.form.SupUR == null) {
                    msgstr = "";
                }
                if (this.form.SupUR == null && this.form.SubUR == null) {
                    msgstr = "";
                }
                if (msgstr != "") {
                    msg.push(msgstr);
                }
                if (msg.length > 0) {
                    var style = "<style> .el-message-box {width:50%;} </style>";
                    this.$alert(msg.join("<br />") + style, "Invalid Data", {
                        dangerouslyUseHTMLString: true
                    });
                    callback(false);
                } else {
                    callback(true);
                }
            },
            save: function () {
                this.validate(valid => {
                    if (valid) {
                        this.form.projectid = this.project.id;
                        this.form.partid = this.projectpart.id;
                        this.form.isehproject = false;
                        this.chackCriteriaisempty(this.form);
                        this.$http
                            .post("project/saveprojectdescription", this.form)
                            .then(response => {
                                this.projectpart.id = response.data.partid;
                                this.project.id = response.data.projectid;
                                this.$message({
                                    showClose: true,
                                    message:
                                        "Project description saved successfully!",
                                    type: "success"
                                });
                                this.refresh();
                            })
                            .catch(err => {
                                this.$bus.$emit("error", err);
                            });
                    } else {
                        return false;
                    }
                });
            },
            checkurinput(event) {
                var cc = event.target.value
                    .replace(/[^\d0-9]/g, "")
                    .replace(/(.{2})/g, "$1 ")
                    .trim();
                event.target.value = cc.replace(/\s/g, "-");
            },
            urchanged: function (row, name) {
                if (row) {
                    var orgur = row;
                    var dmyur = row.replace(/-|\s/g, "");
                    row = dmyur;
                    var regExp = /[1-9][0-9]{3}|[0-9]{3}[1-9]|[0-9][1-9][0-9]{2}|[0-9][0-9][1-9][0-9]/;
                    if (!regExp.test(row)) {
                        this.form[name] = "";
                        row = "";
                        this.$alert(
                            "Please Enter Unique Reference (UR) between 00-01 to 99-99.",
                            "Warning",
                            { type: "Warning" }
                        );
                    } else {
                        row = orgur;
                    }
                }

                //   if (row) {
                //     var regExp = /[0-9]{2}[1-9]|[0-9][1-9][0-9]|[1-9][0-9]{2}/;
                //     if (!regExp.test(row)) {
                //       this.form[name] = "";
                //       row = "";
                //       this.$alert(
                //         "Please Enter Unique Reference (UR) between 001 to 999.",
                //         "Warning",
                //         { type: "Warning" }
                //       );
                //     }
                //   }
            },
            dividecriteria: function () {
                this.pspcriterias.forEach(crt => {
                    if (crt.criteria == "c1" && crt.grouping == "com") {
                        this.com_psp1.push(crt);
                    }
                    if (crt.criteria == "c2" && crt.grouping == "com") {
                        this.com_psp2.push(crt);
                    }
                    if (crt.criteria == "c3" && crt.grouping == "com") {
                        this.com_psp3.push(crt);
                    }
                    if (crt.criteria == "c4" && crt.grouping == "com") {
                        this.com_psp4.push(crt);
                    }

                    if (crt.criteria == "c1" && crt.grouping == "sub") {
                        this.sub_psp1.push(crt);
                    }
                    if (crt.criteria == "c2" && crt.grouping == "sub") {
                        this.sub_psp2.push(crt);
                    }
                    if (crt.criteria == "c3" && crt.grouping == "sub") {
                        this.sub_psp3.push(crt);
                    }
                    if (crt.criteria == "c4" && crt.grouping == "sub") {
                        this.sub_psp4.push(crt);
                    }

                    if (crt.criteria == "c1" && crt.grouping == "sup") {
                        this.sup_psp1.push(crt);
                    }
                    if (crt.criteria == "c2" && crt.grouping == "sup") {
                        this.sup_psp2.push(crt);
                    }
                    if (crt.criteria == "c3" && crt.grouping == "sup") {
                        this.sup_psp3.push(crt);
                    }
                    if (crt.criteria == "c4" && crt.grouping == "sup") {
                        this.sup_psp4.push(crt);
                    }

                    if (crt.criteria == "c1" && crt.grouping == "ext") {
                        this.ext_psp1.push(crt);
                    }
                    if (crt.criteria == "c2" && crt.grouping == "ext") {
                        this.ext_psp2.push(crt);
                    }
                    if (crt.criteria == "c3" && crt.grouping == "ext") {
                        this.ext_psp3.push(crt);
                    }
                    if (crt.criteria == "c4" && crt.grouping == "ext") {
                        this.ext_psp4.push(crt);
                    }
                });
            },
            getcriteria: function () {
                this.$http
                    .post("project/getallpspcriteria")
                    .then(response => {
                        this.pspcriterias = response.data;
                        this.dividecriteria();
                    })
                    .catch(err => {
                        this.$bus.$emit("error", err);
                    });
            },
            projectpartchange: function (Projectobj) {
                this.project = Projectobj.project;
                this.projectpart = Projectobj.part;
                this.refresh();
            },
            projectpartchangesimilar: function (Projectobj) {
                this.projectsimilar = Projectobj.project;
                this.projectpartsimilar = Projectobj.part;
            },

            //added by sunil
            projectpartchangecomparesub: function (Projectobj) {
                // this.comparativesubprojectid = null;
                // this.comparativesubprojectpartid = null;

                if (Projectobj.project.id != 0 && Projectobj.part != null) {
                    this.form.comparativesubprojectid = Projectobj.project.id;
                    this.form.comparativesubprojectpartid = Projectobj.part.id;

                } else {
                    this.form.comparativesubprojectid = null;
                    this.form.comparativesubprojectpartid = null;
                }
            },
            Projectpartchangecomparesuper: function (Projectobj) {
                if (Projectobj.project.id != 0 && Projectobj.part != null) {
                    this.form.comparativesupprojectid = Projectobj.project.id;
                    this.form.comparativesupprojectpartid = Projectobj.part.id;
                } else {
                    this.form.comparativesupprojectid = null;
                    this.form.comparativesupprojectpartid = null;
                }
            },
            projectpartchangecompareext: function (Projectobj) {
                if (Projectobj.project.id != 0 && Projectobj.part != null) {
                    this.form.comparativeextprojectid = Projectobj.project.id;
                    this.form.comparativeextprojectpartid = Projectobj.part.id;
                } else {
                    this.form.comparativeextprojectid = null;
                    this.form.comparativeextprojectpartid = null;
                }
            },
            //up to this by sunil
            getprojectbyid() {
                this.SimilarSub.projectids = { projectid: this.form.comparativesubprojectid, partid: this.form.comparativesubprojectpartid }
                this.$bus.$emit('addprojectpart', null);
                this.SimilarSup.projectids = { projectid: this.form.comparativesupprojectid, partid: this.form.comparativesupprojectpartid }
                this.$bus.$emit('addprojectpart', null);
                this.SimilarExt.projectids = { projectid: this.form.comparativeextprojectid, partid: this.form.comparativeextprojectpartid }
                this.$bus.$emit('addprojectpart', null);
            },
            getprojectdes: function () {
                var data1 = {
                    projectid: this.project.id,
                    partid: this.projectpart.id,
                    isehproject: false
                };
                this.$http
                    .post("project/getProjectDis", data1)
                    .then(response => {
                        //   if(response.data != null){
                        this.form = response.data;
                        this.imt = this.form.iMTview.imt;
                        this.urs = this.form.iMTview.urlist;
                        //   }else{
                        //   this.form = {};
                        //   }
                        if (this.form.Projectsimilars == null) {
                            this.form.Projectsimilars = [];
                        }
                        this.getprojectbyid();
                        // if(this.form.comparativesubprojectid != null && this.form.comparativesubprojectpartid != null){

                        // }
                        this.chackCriteriaiszero();
                        if (this.cqcreportprjcrt != undefined) {
                            if (this.cqcreportprjcrt.name == "projectcriteria") {
                                this.disofcrprjcrt = true;
                                this.disbtnforcr = true;
                                this.cqcreportprjcrt.prjcrtdata = this.form;
                            } else if (
                                this.cqcreportprjcrt.name == "set1criteria"
                            ) {
                                this.disbtnforcr = true;
                                this.disofcrprjcrt = false;
                                this.cqcreportprjcrt.set1crtdata = this.form;
                            } else if (
                                this.cqcreportprjcrt.name == "set2criteria"
                            ) {
                                this.disbtnforcr = true;
                                this.disofcrprjcrt = false;
                                this.cqcreportprjcrt.set2crtdata = this.form;
                            }
                        }
                        if (this.impdis) {
                            this.impdis = false;
                            this.displayimported();
                        }
                    })
                    .catch(err => {
                        this.$bus.$emit("error", err);
                    });
            },
            refresh: function () {
                if (this.project.id && this.projectpart.id >= 0) {
                    this.detail = true;
                    this.getprojectdes();
                } else {
                    this.detail = false;
                }
            }
        },
        created: function () {
            this.$bus.$on("crprjcrtrefresh", () => {
                this.refresh();
            });
            this.getcriteria();
        },
        components: {
            selectpart: selectpart,
            ehselectpart: ehselectpart
        }
    };
</script>
<style>
    .row:after {
        content: "";
        display: table;
        clear: both;
    }
</style>
