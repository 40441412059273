<template>
  <div>
    <div v-if="uploadprogress > 0">
      <el-progress
        :text-inside="true"
        :stroke-width="24"
        :percentage="uploadprogress"
        status="success"
      ></el-progress>
      <hr class="hrstyle" />
    </div>
    <div v-if="!isedit" class="RFIlibrarystyletable">
      <div class="fixdheader">
        <el-row>
          <span class="headerstyle left paddingr83">RFI Library</span>
          <!-- <span class="left" style="width: 430px">
            <el-pagination
              class="pagesizeswidth textalignlpage paddingtop"
              @size-change="rowperpages"
              @current-change="refresh"
              :current-page.sync="view.pageno"
              :page-sizes="pagesizes"
              :page-size="view.pagesize"
              layout="sizes, prev, pager, next"
              :total="view.count"
            >
            </el-pagination>
          </span> -->
          <span class="floatright headerstyle " style="margin-left: 5px;">
            <el-button type="primary" 
                                   style="padding: 4px 8px 2px;" id="libraries_mctemplateslibrary_templatetoarchive" @click="ShowArchiveChange">Show Archive
                        <el-switch v-model="additionalfilter"  @change="ShowArchiveChange"></el-switch>
                    </el-button>
          </span>
          <span class="headerstyle floatright">
            <el-button
              type="primary"
              :disabled="!editpermission"
              @click="createNew()"
              icon="el-icon-circle-plus"
              >Create New&nbsp;</el-button
            >
          </span>
        </el-row>
        <el-row>
          <div class="left padright5px">
            <el-select
              v-model="sectionfilter"
              filterable
              clearable
              placeholder="Section"
              class="bold width260px"
              style="width: 252px"
              @change="changesection"
            >
              <el-option
                v-for="item in rfisectionsfilter"
                :key="item.id"
                :label="item.section"
                :value="item.id"
              >
              </el-option>
            </el-select>
            <!-- <el-input
              :spellcheck="isspellcheck"
              v-model="view.filter[0].value"
              placeholder="Directions Name"
              :id="'library_directionslibrary_filter_directionsname'"
              @keyup.enter.native="onSearch()"
              class="bold width260px"
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                class=""
                circle
                @click="onSearch"
              ></el-button>
            </el-input> -->
          </div>
          <div class="left paddingl13">
            <!-- <el-autocomplete class="inline-input width260px bold" v-model="view.filter[1].value"
                            :fetch-suggestions="querySection" placeholder="Section" @select="onSearch"
                            :spellcheck="isspellcheck">
                            <el-button slot="suffix" @click="clearsection" type="text" size="mini">&times;</el-button>
                        </el-autocomplete> -->
            <!-- <el-select
              v-model="view.filter[1].value"
              @change="onSearch"
              :filterable="true"
              :clearable="true"
              placeholder="Issue"
              class="fullwidth"
            >
              <el-option
                v-for="item in [
                  { value: 'error', label: 'Requirement to Correct' },
                  { value: 'warning', label: 'Warning correction needed' },
                ]"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select> -->
            <el-input
              placeholder="Issue"
              @change="refresh"
              v-model="view.filter[1].value"
            ></el-input>
          </div>
            <span>
                <el-button v-if="isarchiveselect == true" type="primary"
                    class="marginleft4px floatright" @click="addmultiarchive">
                    Add To Archive
                </el-button>
            </span>
          
        </el-row>
        <hr class="hrstyle" />
      </div>
      <div>
        <el-table
          @expand-change="expandchange"
          v-if="canshow"
          :data="tabledata"
          :height="height"
          row-key="id"
          :expand-row-keys="expandedrows"
          :default-sort="{ prop: 'rfis.section', order: 'ascending' }"
          @sort-change="sortChange"
          align="left"
        >
        <el-table-column type="expand">
          <template slot-scope="props">
            <el-descriptions v-if="props.row.subdata" title="" direction="vertical" size="small" :column="4" border>
              <el-descriptions-item label="Section">
                <div >
                  <div v-if="props.row.subdata.section == null">
                  {{ "General Items" }}
                </div>
                <div v-else>
                  {{ props.row.subdata.section }}
                </div>
              </div>
              </el-descriptions-item>
              <el-descriptions-item label="Issue" >{{ props.row.subdata.issue }}</el-descriptions-item>
              <el-descriptions-item label="Author" :span="2">
                {{ props.row.createdbyuser.loginname }}
              </el-descriptions-item>
              <el-descriptions-item label="Required Action">
                {{ props.row.requiredaction }}
              </el-descriptions-item>
              <el-descriptions-item label="Consultation" :span="2" >
                <el-tag size="small">{{ props.row.subdata.consultation }}</el-tag>
              </el-descriptions-item>
              <el-descriptions-item label="Documentlocation" >{{ props.row.subdata.documentlocation }}</el-descriptions-item>
              <el-descriptions-item label="Interim Action" >{{ props.row.subdata.interimaction }}</el-descriptions-item>
              <el-descriptions-item label="Snips / Files" >
                <span style="width: 77%" class="left">
                    <span v-for="svi in props.row.subdata.ssv" :key="svi.id">
                      <el-popover
                        placement="bottom"
                        width="200"
                        v-model="svi.mousehover"
                        trigger="hover"
                      >
                        <div @click="showpreview(svi)">
                          <span class="previewbox" v-if="svi.extention == 'xlsx'">
                            <div class="UR-Link fontsize12">
                              {{ svi.name }}
                            </div>
                            <div class="center">
                              <i class="el-icon-document iconsize90"></i>
                            </div>
                          </span>
                          <span v-if="svi.extention">
                            <span
                              v-if="
                                svi.extention.startsWith('jpg') ||
                                svi.extention.startsWith('jpeg') ||
                                svi.extention.startsWith('gif') ||
                                svi.extention.startsWith('png')
                              "
                            >
                              <img :src="svi.link" class="previewbox" />
                            </span>
                            <span v-if="svi.extention.startsWith('mp4')">
                              <video controls class="previewbox">
                                <source :src="svi.url" type="video/mp4" />
                                Your browser does not support the video tag.
                              </video>
                            </span>
                            <span v-if="svi.extention.startsWith('mp3')">
                              <audio controls class="previewbox">
                                <source :src="svi.link" type="audio/ogg" />
                                <source :src="svi.link" type="audio/mpeg" />
                                Your browser does not support the audio element.
                              </audio>
                            </span>
                          </span>
                        </div>
                        <span
                          slot="reference"
                          class="UR-Link marginl5 marginright5px fontsize12"
                          @mouseenter="previewsnip(svi)"
                        >
                          <el-tag size="mini"> {{ svi.name }} </el-tag></span
                        >
                      </el-popover>
                    </span>
                  </span>
              </el-descriptions-item>
            </el-descriptions>
            <!-- {{getsubdata(props.row)}} -->
          </template>
        </el-table-column>
          <el-table-column
            prop="section.section"
            sortable="custom"
            label="Sections"
            width="300"
          >
            <template slot-scope="scope">
              <div v-if="scope.row.section == null">
                {{ "General Items" }}
              </div>
              <div v-else>
                {{ scope.row.section.section }}
              </div>
            </template>
          </el-table-column>

          <el-table-column
            prop="issue"
            label="Issue"
            sortable="custom"
            width="300"
            class-name="el_coll_space_right"
          ></el-table-column>
          <el-table-column
            prop="interimaction"
            label="Interim Action"
            width="300"
            class-name="el_coll_space_right"
          ></el-table-column>
          <el-table-column
            prop="requiredaction"
            label="Required Action"
            width="300"
            class-name="el_coll_space_right"
          ></el-table-column>
          <el-table-column
            prop="createdbyuser.loginname"
            label="Author"
            width="60"
          ></el-table-column>
          <el-table-column label="Actions" align="center" width="110">
            <template slot-scope="scope">
              <el-button
                size="mini"
                class="padding7"
                type="primary"
                title="Edit"
                :icon="editpermission == true ? 'el-icon-edit' : 'el-icon-view'"
                @click="handleEdit(scope.$index, scope.row)"
              >
              </el-button>
              <el-button
                size="mini"
                class="padding7"
                type="primary"
                title="Copy"
                v-if="additionalfilter == false"
                icon="glyphicon glyphicon-duplicate"
                @click="handleCopy(scope.$index, scope.row)"
                :disabled="!editpermission"
              ></el-button>

              <!-- <el-button
                size="mini"
                class="padding7"
                type="danger"
                title="Delete"
                icon="el-icon-delete"
                @click="handleDelete(scope.$index, scope.row)"
                :disabled="!editpermission"
              ></el-button> -->
            </template>
          </el-table-column>
          <el-table-column v-if="additionalfilter == false" width="40">
            <template slot="header">
              <span>
                <i
                  class="glyphicon glyphicon-eye-close bqcodearchiveiconstyle"
                ></i>
              </span>
            </template>

            <template slot-scope="scope">
              <el-checkbox
                v-model="scope.row.isarchive"
                @change="selectarchive(scope.row, scope.$index)"
                class="archiveborderedtickbox"
                border
              ></el-checkbox>
            </template>
          </el-table-column>
        </el-table>
        <el-row justify="space-around" type="flex">
          <el-col :span="6">
            <span  style="width: 430px">
            <el-pagination
              class="pagesizeswidth textalignlpage paddingtop"
              @size-change="rowperpages"
              @current-change="refresh"
              :current-page.sync="view.pageno"
              :page-sizes="pagesizes"
              :page-size="view.pagesize"
              layout="sizes, prev, pager, next"
              :total="view.count"
            >
            </el-pagination>
          </span>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="RFIwidth" v-else>
      <div class="fixdheader">
        <el-row>
          <span class="headerstyle left paddingr83">Add/Edit RFI Library</span>
        </el-row>
        <el-row>
          <span>
            <el-button
              @click="
                cellchanged(
                  'comms_rfientryform_save',
                  () => {
                    saverfi();
                  },
                  false
                )
              "
              :disabled="!editpermission"
              type="success"
              >Save</el-button
            >
          </span>

          <span>
            <el-button
              icon="el-icon-back"
              type="info"
              @click="(isedit = false), refresh"
              class="marginleft4px"
              >Back</el-button
            >
          </span>
          <span>
            <el-button
              type="primary"
              class="spellbtn marginleft4px"
              style="padding: 3px 5px 1px 5px"
              @click="spellcheckMethod()"
            >
              Spell Check:
              <span>
                <el-switch
                  @change="spellchack()"
                  v-model="spell"
                  style="margin-bottom: 2px"
                  active-color="primary"
                  :active-value="true"
                  :inactive-value="false"
                >
                </el-switch>
              </span>
            </el-button>
          </span>
        </el-row>
        <hr class="hrstyle" />

        <el-row>
          <span>
            <el-select
              v-model="form.rfisectionid"
              filterable
              placeholder="Section"
              class="padding2px bold"
              style="width: 252px"
            >
              <el-option
                v-for="item in rfisections"
                :key="item.id"
                :label="item.section"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </span>
        </el-row>
        <hr class="hrstyle" />
      </div>
      <el-row>
        <div>
          <span class="left" style="width: 96%">
            <el-input
              type="textarea"
              :disabled="!editpermission"
              ref="Issueref"
              :spellcheck="spell"
              :autosize="{ minRows: 2, maxRows: 4 }"
              placeholder="Issue / Question"
              v-model="form.issue"
              :id="'comms_rfientryform_issue_question'"
            >
            </el-input>
          </span>
          <span class="left" style="width: 4%; padding-left: 5px">
            <i
              class="el-icon-info el-popover__reference"
              style="color: rgb(96, 98, 102); font-size: 15px"
              id="info_comms_rfi_issue"
              @click="$training.Gettrainingpopup('info_comms_rfi_issue')"
            ></i>
          </span>
        </div>
      </el-row>
      <div class="spaceBetween" />
      <el-row>
        <div>
          <span class="left" style="width: 96%">
            <el-input
              type="textarea"
              :disabled="!editpermission"
              :spellcheck="spell"
              :autosize="{ minRows: 2, maxRows: 4 }"
              placeholder="Location within documentation"
              v-model="form.documentlocation"
              :id="'comms_rfientryform_document_location'"
            >
            </el-input>
          </span>
          <span class="left" style="width: 4%; padding-left: 5px">
            <i
              class="el-icon-info el-popover__reference"
              style="color: rgb(96, 98, 102); font-size: 15px"
              id="info_comms_rfi__document_location"
              @click="
                $training.Gettrainingpopup('info_comms_rfi__document_location')
              "
            ></i>
          </span>
        </div>
      </el-row>
      <div class="spaceBetween" />
      <el-row>
        <el-row>
          <span style="width: 23%" class="left">
            <el-button
              :disabled="!editpermission"
              @click="isopenaddsnip = isopenaddsnip == true ? false : true"
              type="primary"
              >Add Snip / File</el-button
            >
          </span>
          <span style="width: 77%" class="left">
            <span v-for="svi in form.ssv" :key="svi.id">
              <el-popover
                placement="bottom"
                width="200"
                v-model="svi.mousehover"
                trigger="hover"
              >
                <div @click="showpreview(svi)">
                  <span class="previewbox" v-if="svi.extention == 'xlsx'">
                    <div class="UR-Link fontsize12">
                      {{ svi.name }}
                    </div>
                    <div class="center">
                      <i class="el-icon-document iconsize90"></i>
                    </div>
                  </span>
                  <span v-if="svi.extention">
                    <span
                      v-if="
                        svi.extention.startsWith('jpg') ||
                        svi.extention.startsWith('jpeg') ||
                        svi.extention.startsWith('gif') ||
                        svi.extention.startsWith('png')
                      "
                    >
                      <img :src="svi.link" class="previewbox" />
                    </span>
                    <span v-if="svi.extention.startsWith('mp4')">
                      <video controls class="previewbox">
                        <source :src="svi.url" type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    </span>
                    <span v-if="svi.extention.startsWith('mp3')">
                      <audio controls class="previewbox">
                        <source :src="svi.link" type="audio/ogg" />
                        <source :src="svi.link" type="audio/mpeg" />
                        Your browser does not support the audio element.
                      </audio>
                    </span>
                  </span>
                </div>
                <span
                  slot="reference"
                  class="UR-Link marginl5 marginright5px fontsize12"
                  @mouseenter="previewsnip(svi)"
                >
                  <el-tag size="mini"> {{ svi.name }} </el-tag></span
                >
              </el-popover>
            </span>
          </span>
        </el-row>
        <el-row>
          <span v-if="isopenaddsnip">
            <div class="spaceBetween">
              <span style="width: 50%" class="left">
                <div
                  class="mergeuploadcontrol"
                  :id="'comms_rfientryform_addsnip'"
                >
                  <span class="uploadwidth124 left">
                    <!-- :on-change="()=>{ return editedchanged(sc, null, 'image'); }" -->
                    <el-upload
                      ref="upload"
                      action=""
                      :drag="true"
                      :show-file-list="false"
                      class="dragupload uploadiconsize textupload"
                      :http-request="
                        (event) => {
                          return addAttachment(event, form);
                        }
                      "
                      accept=".png,.jpg,.jpeg,.gif,.mp4,.mp3"
                    >
                      <em class="el-icon-upload"></em>
                      <div class="el-upload__text">
                        Drop file here or <em>click to upload</em>
                      </div>
                    </el-upload>
                  </span>

                  <span class="left">
                    <div @click="pasteimage(form)" class="copycontrol">
                      <div class="copycontroltext">
                        <span class="copycontrolfont"
                          >Paste the snip from the clipboard</span
                        >
                      </div>
                    </div>
                  </span>
                </div>
              </span>
              <span style="width: 50%" class="left">
                <div v-for="(f,i)  in form.files" :key="i">
                  <span class="widthlabel300">
                    <div>
                      <span class="uploadedfilenames">
                        <em class="el-icon-document"></em>
                        <span @click="beforeuploadpreview(f)">{{
                          f.originalname
                        }}</span>
                      </span>
                      <span>
                        <em
                          class="el-icon-close deletefiles"
                          @click="deleteAttachment(f.originalname)"
                        ></em>
                      </span>
                    </div>
                  </span>
                </div>
              </span>
            </div>
          </span>
        </el-row>
      </el-row>
      <div class="spaceBetween" />
      <el-row>
        <div>
          <span class="left" style="width: 96%">
            <el-input
              :disabled="!editpermission "
              type="textarea"
              ref="interimref"
              :spellcheck="spell"
              :autosize="{ minRows: 2, maxRows: 4 }"
              placeholder="Interim Action(S) Taken by TSA"
              v-model="form.interimaction"
              :id="'comms_rfientryform_interim_action'"
            >
            </el-input>
          </span>
          <span class="left" style="width: 4%; padding-left: 5px">
            <i
              class="el-icon-info el-popover__reference"
              style="color: rgb(96, 98, 102); font-size: 15px"
              id="info_comms_rfi_interim_action"
              @click="
                $training.Gettrainingpopup('info_comms_rfi_interim_action')
              "
            ></i>
          </span>
        </div>
      </el-row>
      <div class="spaceBetween" />
      <el-row>
        <span class="left" style="width: 96%">
          <el-select
            :disabled="!editpermission"
            @change="
              (e) => {
                changerequiredaction(e, false);
              }
            "
            v-model="contractoractions"
            class="fullwidth"
            placeholder="Required actions by contractor"
            :id="
              !canshowrequiredaction ? 'comms_rfientryform_action_required' : ''
            "
          >
            <el-option
              v-for="item in contractoractionsarray"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </span>
        <span class="left" style="width: 4%; padding-left: 5px">
          <i
            class="el-icon-info el-popover__reference"
            style="color: rgb(96, 98, 102); font-size: 15px"
            id="info_comms_rfi_action_required"
            @click="
              $training.Gettrainingpopup(
                'info_comms_rfi_action_contractor_actions'
              )
            "
          ></i>
        </span>
      </el-row>
      <div class="spaceBetween" />
      <el-row v-if="canshowrequiredaction">
        <div>
          <span class="left" style="width: 96%">
            <el-input
              :disabled="!editpermission"
              type="textarea"
              ref="requiredref"
              :spellcheck="spell"
              :autosize="{ minRows: 2, maxRows: 4 }"
              placeholder="Action(S) Required by the Contractor"
              v-model="form.requiredaction"
              :id="
                canshowrequiredaction
                  ? 'comms_rfientryform_select_contractoractions'
                  : ''
              "
            >
            </el-input>
          </span>
          <span class="left" style="width: 4%; padding-left: 5px">
            <!-- <i class="el-icon-info el-popover__reference"
                            style="color: rgb(96, 98, 102);font-size: 15px" id="info_comms_rfi_action_required" @click="$training.Gettrainingpopup('info_comms_rfi_action_required')"></i> -->
          </span>
        </div>
      </el-row>
      <div class="spaceBetween" />
      <el-row>
        <el-select
          :disabled="!editpermission "
          v-model="form.consultation"
          placeholder="Classification"
          :id="'comms_rfientryform_select_classification'"
        >
          <el-option
            v-for="item in consultationarray"
            :key="item.value"
            :label="item.name"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </el-row>
    </div>
    <show-media @deleteMedia="deleteMedia" ref="showmedia"></show-media>
  </div>
</template>
<script>
// import { Underline } from "element-tiptap";
import showmedia from "./showmedia.vue";
export default {
  components: {
    "show-media": showmedia,
  },
  data() {
    return {
      expandedrows:[],
      canshow:true,
      filescount: 0,
      extensions: [
        { name: "jpg" },
        { name: "jpeg" },
        { name: "png" },
        { name: "gif" },
        { name: "mp4" },
        { name: "mp3" },
      ],
      sectionfilter: "",
      uploadprogress: 0,
      canshowrequiredaction: false,
      additionalfilter: false,
      height: document.documentElement.clientHeight - 200,
      pagesizes: this.$store.state.pagesizes,
      contractoractions: "Contractor to confirm",
      contractoractionsarray: [
        { value: "Contractor to confirm", name: "Contractor to confirm" },
        {
          value: "Contractor to confirm or state requirements",
          name: "Contractor to confirm or state requirements",
        },
        { value: "For information only", name: "For information only" },
        { value: "Custom Text", name: "Custom Text" },
      ],
      consultationarray: [
        {
          value: "Urgent Attention Required",
          name: "Urgent Attention Required",
        },
        { value: "Outstanding Risk Item", name: "Outstanding Risk Item" },
        { value: "Resolved", name: "Resolved" },
      ],
      isopenaddsnip: false,
      rfisections: [],
      rfisectionsfilter: [],
      form: {},
      spell: false,
      isedit: false,
      tabledata: [],
      view: {
        pagesize: 20,
        filter: [
          { table: "rfi", column: "rfisectionid", isnull: false, value: "" },
          { table: "rfi", column: "issue", value: "" },
        ],
        sortcol: "rfis.section",
        sortorder: "descending",
      },
      archivearray:[],
      isarchiveselect:false,
    };
  },
  computed: {
    getsubdata(){
      return function (data) {
         return data.subdata; 
      }
    }
  },
  methods: {
    expandchange(row ,expandedRows){
      var expanded = expandedRows.map((v) => { return v.id });
      this.expandedrows = expanded;
      if(row.subdata == undefined){
        this.showloading();
        this.$http
          .post("RFI/getrfilibrarybyid?isarchive="+this.additionalfilter, { id: row.id })
          .then((response) => {
            this.hideloading();
            this.$nextTick(()=>{
              this.$set(row,"subdata",response.data)
              // row.subdata = response.data;
              // row = JSON.parse(JSON.stringify(row));  
              // this.tabledata = JSON.parse(JSON.stringify(this.tabledata))
              this.$forceUpdate();
            })
            // this.$message({
            //     showClose: true,
            //     message: "Media deleted successfully!",
            //     type: "success",
            // });
            // this.$refs.showmedia.CloseMedia();
            // this.getsectionalviewimages();
          })
          .catch((err) => {
            this.$bus.$emit("error", err);
            this.hideloading();
          });
      }
    },
    ShowArchiveChange(){
            this.$nextTick(()=>{
                this.view.pageno = 1;
                this.additionalfilter == false ? this.additionalfilter = true : this.additionalfilter = false;
                this.refresh();
            })
        },
    addmultiarchive() {
        var newarray = [];
        this.archivearray.forEach(e => {
            newarray.push(this.tabledata[e]);
        });
        this.$http.post('RFI/movemultiplerfilibrariestoarchive', newarray)
            .then(response => {
                this.$message({
                    showClose: true,
                    message: response.data, type: "success"
                });
                this.archivearray = [];
                this.isarchiveselect = false;
                this.$nextTick(() => {
                    this.refresh();
                })
            })
            .catch(err => {
                this.$bus.$emit('error', err);
            });
    },
    selectarchive(data, index) {
            if (data.isarchive == true) {
                this.archivearray.push(index);
            } else {
                var ind = this.archivearray.findIndex(x => x == index);
                this.archivearray.splice(ind, 1);
            }
            if (this.archivearray.length == 0) {
                this.isarchiveselect = false;
            } else {
                this.isarchiveselect = true;
            }
    },
    handleCopy(index, row) {
      this.$confirm("Would you like to copy the RFI?", "Confirm", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      })
        .then(() => {
          this.showloading();
          this.$http
            .post("RFI/copyrfilibrarybyid", { id: row.id })
            .then((response) => {
              this.$message({
                showClose: true,
                message: response.data,
                type: "success",
              });
              this.refresh();
              this.hideloading();
            })
            .catch((err) => {
              this.$bus.$emit("error", err);
              this.hideloading();
            });
        })
        .catch(() => {
          this.$message({
            showClose: true,
            type: "info",
            message: "Copy canceled",
          });
        });
    },
    handleEdit(index, row) {
      this.showloading();
      this.$http
        .post("RFI/getrfilibrarybyid?isarchive="+this.additionalfilter, { id: row.id })
        .then((response) => {
          this.isedit = true;
          this.form = response.data;
          if (this.form.rfisectionid == undefined) {
            this.form.rfisectionid = null;
          }
          this.contractoractions = "Contractor to confirm";
          this.changerequiredaction(this.form.requiredaction, true);
          this.hideloading();
          // this.$message({
          //     showClose: true,
          //     message: "Media deleted successfully!",
          //     type: "success",
          // });
          // this.$refs.showmedia.CloseMedia();
          // this.getsectionalviewimages();
        })
        .catch((err) => {
          this.$bus.$emit("error", err);
          this.hideloading();
        });
    },
    getsectionalviewimages() {
      this.$http
        .post("RFI/getsectionalviewimages", {
          tableid: this.form.id,
          source: "rfilibrary",
        })
        .then((response) => {
          this.form.ssv = response.data;
        })
        .catch((err) => {
          this.$bus.$emit("error", err);
          this.hideloading();
        });
    },
    deleteMedia(data) {
      this.showloading();
      this.$http
        .post("RFI/rfilibrarydeletemedia", {
          id: data.id,
          tableid: data.tableid,
        })
        .then((response) => {
          this.hideloading();
          this.$message({
            showClose: true,
            message: "Media deleted successfully!",
            type: "success",
          });
          this.$refs.showmedia.CloseMedia();
          this.getsectionalviewimages();
        })
        .catch((err) => {
          this.$bus.$emit("error", err);
          this.hideloading();
        });
    },
    changesection(event) {
      if (event === "") {
        this.view.filter[0].isnull = false;
        this.view.filter[0].value = "";
      } else {
        if (event === 0) {
          this.view.filter[0].isnull = true;
        } else {
          this.view.filter[0].isnull = false;
          this.view.filter[0].value = "" + event;
        }
      }
      this.refresh();
    },
    // setdirectionsection() {
    //     var s = this.sections.find(x => x.id == this.sectionid);
    //     if (s != undefined) {
    //         return s.section;
    //     } else {
    //         return "";
    //     }
    // },
    cellchanged(cellaction, callback, cancall) {
      const app = this;
      this.$directionutility.setcomponent(app);
      if (cancall) {
        callback();
        this.canmatchcellid(() => {}, cellaction, ""); //this.setdirectionsection()
      } else {
        this.canmatchcellid(callback, cellaction, ""); //this.setdirectionsection()
      }
    },
    buildFormData(formData, data, parentKey) {
      if (
        data &&
        typeof data === "object" &&
        !(data instanceof Date) &&
        !(data instanceof File)
      ) {
        Object.keys(data).forEach((key) => {
          this.buildFormData(
            formData,
            data[key],
            parentKey ? `${parentKey}[${key}]` : key
          );
        });
      } else {
        const value = data == null ? "" : data;
        formData.append(parentKey, value);
      }
    },

    jsonToFormData(data) {
      const formData = new FormData();
      this.buildFormData(formData, data);
      return formData;
    },
    validate() {
      var msg = [];
      if (this.form.rfisectionid === undefined) {
        msg.push("Please select RFI section.");
      }
      if (!this.form.issue) {
        msg.push("Issue must not be empty");
      }
      // if (!this.form.documentlocation) {
      //   msg.push("Documentlocation must not be empty");
      // }
      if (!this.form.interimaction) {
        msg.push("Interim Action must not be empty");
      }
      if (!this.form.requiredaction) {
        msg.push("Required Action must not be empty");
      }
      // if (!this.form.consultation) {
      //   msg.push("Classification must not be empty");
      // }
      if (msg.length > 0) {
        var style = "<style> .el-message-box {width:50%;} </style>";
        this.$alert(msg.join("<br />") + style, "Invalid Data", {
          dangerouslyUseHTMLString: true,
        });
        return false;
      } else {
        return true;
      }
    },
    saverfi() {
      let save = () => {
        let onprogress = {
          onUploadProgress: (progress) => {
            this.uploadprogress = Math.floor(
              (100 / progress.total) * progress.loaded
            );
          },
        };

        this.showloading();
        let formdata = this.jsonToFormData(this.form);
        this.$http
          .post("RFI/saveRFIlibrary?isarchive="+this.additionalfilter, formdata, onprogress)
          .then((response) => {
            this.$message({
              showClose: true,
              message: response.data,
              type: "success",
            });
            this.isedit = false;
            this.hideloading();
            this.uploadprogress = 0;
            this.refresh();
          })
          .catch((err) => {
            this.$bus.$emit("error", err);
            this.hideloading();
          });
      };
      if (this.validate()) {
        save();
      }
    },
    changerequiredaction(event, canadd) {
      if (canadd == true) {
        var canfind = this.contractoractionsarray.find((x) => x.name == event);
        if (canfind != undefined) {
          this.contractoractions = canfind.name;
          this.canshowrequiredaction = false;
        } else {
          this.contractoractions = "Custom Text";
          this.canshowrequiredaction = true;
        }
      } else {
        if (event == "Custom Text") {
          // this.requiredaction = "";
          this.$set(this.form, "requiredaction", "");
          // this.form.requiredaction = "";
          this.canshowrequiredaction = true;
        } else {
          // this.requiredaction = event;
          this.$set(this.form, "requiredaction", event);
          // this.form.requiredaction = event;
          this.canshowrequiredaction = false;
        }
      }
    },
    rowperpages: async function (val) {
      try {
        this.usergridrow.ugv.rowperpage = val;
        this.usergridrow = await this.$training.savepagelayout(
          this.usergridrow
        );
        this.view.pageno = 1;
        this.view.pagesize = val;
        this.refresh();
      } catch (error) {
        this.$bus.$emit("error", error);
      }
    },
    beforeuploadpreview(i) {
      this.$refs.showmedia.openmedia(i, "beforemedia");
    },
    deleteAttachment(name) {
      this.$nextTick(()=>{
        let index = this.form.files.findIndex((x) => x.originalname == name);
        this.form.files.splice(index, 1);
        this.$set(this.form,"files",this.form.files);
        this.$forceUpdate();
      })
    },
    async pasteimage(row) {
      try {
        const permission = await navigator.permissions.query({
          name: "clipboard-read",
        });
        if (permission.state === "denied") {
          throw new Error("Please give the clipboard read permission.");
        }
        await navigator.clipboard.read().then((items) => {
          for (let item of items) {
            for (let type of item.types) {
              if (type.startsWith("image/")) {
                const result = this.handleImage(type, item, row);
                if (result) {
                  break;
                }
              }
            }
          }
        });
      } catch (error) {
        var style = "<style> .el-message-box {width:50%;} </style>";
        this.$alert(error.message + style, "Invalid Data", {
          dangerouslyUseHTMLString: true,
        });
      }
    },
    addAttachment(file, row) {
      const name = file.file.name;
      const ext = name.substring(name.lastIndexOf(".") + 1).toLowerCase();
      const existextension = this.extensions.find((x) => x.name == ext);
      const validext = this.extensions
        .map((e) => {
          return e.name;
        })
        .join(", ");
      if (existextension == undefined) {
        var style = "<style> .el-message-box {width:30%;} </style>";
        this.$alert(
          `<span style='color:red;'>${name}</span> has invalid extension, allowed extensions are  ${validext}. ${style}`,
          "Validate extension",
          {
            dangerouslyUseHTMLString: true,
          }
        );
        return;
      }
      this.filescount += 1;
      const rename = `${this.filescount} || ${file.file.name}`;
      const renamefile = new File([file.file], rename);
      const f = {
        sectionalviewid: row.id,
        originalname: name,
        checkingname: rename,
        content: renamefile,
      };
      if (this.form.files == undefined) {
        this.form.files = [];
      }
      this.form.files.push(f);
      this.$forceUpdate();
    },
    previewsnip(i) {
      i.link = i.link.trim();
      i.extention = i.name.substring(i.name.lastIndexOf(".") + 1).toLowerCase();
      if (i.link[i.link.length - 1] == "0") {
        i.link = `${i.link.substring(0, i.link.length - 1)}1`;
      }
      i.mousehover = true;
      i.externalurl = `${i.link.substring(0, i.link.length - 1)}0`;
      if (i.extention === "mp4") {
        i.url = `${i.link.substring(0, i.link.length - 4)}raw=1`;
      }
    },
    showpreview(i) {
      if (!this.additionalfilter) {
        i.mousehover = false;
        i.showdialog = true;
        this.$refs.showmedia.openmedia(i, "selectedmedia");
      }
    },
    spellcheckMethod() {
      this.spell = this.spell == true ? false : true;
    },
    spellchack() {
      this.spell = this.spell == true ? false : true;
    },
    sortChange: function (sort) {
      if (sort.prop == "section.section") {
        this.view.sortcol = "rfis.section";
      } else if (sort.prop == "issue") {
        this.view.sortcol = "rfi.issue";
      } else {
        this.view.sortcol = sort.prop;
      }
      this.view.sortorder = sort.order;
      this.refresh();
    },
    createNew() {
      this.filescount = 0;
      this.isedit = true;
      this.form = {
        requiredaction: "Contractor to confirm",
      };
      this.contractoractions = "Contractor to confirm";
      this.changerequiredaction(this.form.requiredaction, true);
    },
    refresh() {
      this.canshow = false;
      this.$http
        .post("rfi/getRFILibraryGrid?isarchive="+this.additionalfilter, this.view)
        .then((res) => {
          this.view = res.data.view;
          this.tabledata = res.data.data;
          this.canshow = true;
          // this.getAllUsers();
        })
        .catch((err) => {
          this.canshow = true;
          this.$bus.$emit("error", err);
        });
    },
    getsections() {
      if (this.$store.state.rfisections.length <= 0) {
        this.getrifsections().then((data) => {
          this.rfisections = [];
          this.rfisectionsfilter = [];
          this.rfisections = JSON.parse(JSON.stringify(data));
          this.rfisectionsfilter = JSON.parse(JSON.stringify(data));
          this.rfisectionsfilter.unshift({ section: "General Items", id: 0 });
          this.rfisections.unshift({ section: "General Items", id: null });
        });
      } else {
        this.rfisections = [];
        this.rfisectionsfilter = [];
        this.rfisections = JSON.parse(
          JSON.stringify(this.$store.state.rfisections)
        );
        this.rfisectionsfilter = JSON.parse(
          JSON.stringify(this.$store.state.rfisections)
        );
        this.rfisectionsfilter.unshift({ section: "General Items", id: 0 });
        this.rfisections.unshift({ section: "General Items", id: null });
      }
    },
  },
  created() {
    this.getsections();
    let gridrow = this.$training.getusergridrow(this.$route);
    this.view.pagesize = gridrow.ugv.rowperpage;
    this.usergridrow = gridrow;
    this.refresh();
  },
};
</script>
<style>

</style>