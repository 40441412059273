import { Vue, axios, store, EventBus, router } from './common.js';
export default class directionutility extends Vue {
    validatedirectionmatch(data, actionname) {
        var newarray = [];
        if (data.includerule != null && data.includerule != "") {
            data.inc_pattern = new RegExp(data.includerule, "i");
        }
        if (data.excluderule != null && data.excluderule != "") {
            data.exc_pattern = new RegExp(data.excluderule, "i");
        }
        if (data.formatrule != null && data.formatrule != "") {
            data.format_pattern = new RegExp(data.formatrule, "i");
        }
        var checkvalues = document.querySelectorAll(`[id^="${data.pagecell.cellname}"]`);
        if (checkvalues && checkvalues.length > 0) {
            for (let index = 0; index < checkvalues.length; index++) {
                const element = checkvalues[index];
                let enteredvalue = this[actionname](data, element.id);
                data.enteredvalue = enteredvalue;
                if (data.celltype == "checkbox") {
                    let istrue = data.enteredvalue == true ? true : false;
                    data.enteredvalue = istrue;
                }
                // if (data.enteredvalue != undefined) {
                var isshow = this.directionvaluematch(data);
                if (isshow == false) {
                    newarray.push({
                        element: element,
                        id: element.id,
                        inc_pattern: data.inc_pattern,
                        exc_pattern: data.exc_pattern,
                        format_pattern: data.format_pattern,
                        enteredvalue: data.enteredvalue,
                        celltype: data.celltype,
                        objectpath: data.objectpath,
                        checkvalue: data.checkvalue,
                        operation: data.operation,
                    });
                }
                // }
            }
            return newarray;
        } else {
            return newarray;
        }
    }

    directionvaluematch(data) {
        var isinclude = null;
        var isexclude = null;
        var isformat = null;
        if (data.celltype == 'regex' || data.celltype == 'date & time') {
            var value = "";
            data.enteredvalue = data.enteredvalue == undefined ? '' : data.enteredvalue;
            if (typeof data.enteredvalue != "string") {
                value = data.enteredvalue.toString();
            } else {
                value = data.enteredvalue;
            }
            if (data.inc_pattern != null) {
                isinclude = data.inc_pattern.test(value);
            }
            if (data.exc_pattern != null) {
                isexclude = !data.exc_pattern.test(value);
            }
            if (data.format_pattern != null) {
                isformat = data.format_pattern.test(value);
            }

            if (isinclude !== null && isexclude !== null && isformat !== null) {
                return isinclude && isexclude && isformat;
            }
            if (isinclude !== null && isexclude !== null) {
                return isinclude && isexclude;
            }
            if (isinclude !== null && isformat !== null) {
                return isinclude && isformat;
            }
            if (isexclude !== null && isformat !== null) {
                return isexclude && isformat;
            }
            if (isinclude !== null) {
                return isinclude;
            }
            if (isexclude !== null) {
                return isexclude;
            }
            if (isformat !== null) {
                return isformat;
            }
            return true;
        } else if (data.celltype == "number") {
            let ret = true;
            switch (data.operation) {
                case ">":
                    if (!(data.enteredvalue > data.checkvalue)) {
                        ret = false;
                    }
                    break;
                case "<":
                    if (!(data.enteredvalue < data.checkvalue)) {
                        ret = false;
                    }
                    break;
                case "==":
                    if (!(data.enteredvalue == data.checkvalue)) {
                        ret = false;
                    }
                    break;
                case ">=":
                    if (!(data.enteredvalue >= data.checkvalue)) {
                        ret = false;
                    }
                    break;
                case "<=":
                    if (!(data.enteredvalue <= data.checkvalue)) {
                        ret = false;
                    }
                    break;
                default:
                    ret = true;
            }
            return ret;
        } else if (data.celltype == "checkbox") {
            let ret = true;
            if (data.checkvalue != null) {
                if (data.enteredvalue && data.checkvalue === "1") {
                    ret = true;
                } else if (!data.enteredvalue && data.checkvalue === "0") {
                    ret = true;
                } else {
                    ret = false;
                }
            }
            return ret;
        }
    }

    getcommonvalue(data) {
        let evalstr = `this.component.${data.objectpath}`;
        let val = eval(evalstr);
        return val;
    }

    getcommonvalueusingindex(data, strid) {
        // This variable is use in the objectpath string properties so not remove.
        let index1 = this.getlastindexvalue(strid, '_');
        let evalstr = `this.component.${data.objectpath}`;
        let val = eval(evalstr);
        return val;
    }

    analysis_signoff_sections_bill_self_check_update(data) {
        return this.getcommonvalue(data);
    }

    analysis_signoff_sections_external_check_update(data) {
        return this.getcommonvalue(data);
    }

    analysis_external_check_save(data, strid) {
        let parts = strid.split('_');
        let index2 = parts[parts.length - 2]; // This variable is use in the objectpath string properties so not remove.
        let index1 = parts[parts.length - 1]; // This variable is use in the objectpath string properties so not remove.
        let evalstr = `this.component.${data.objectpath}`;
        let val = eval(evalstr);
        const propname = this.getlastindexvalue(data.objectpath, '.');
        if (val && val != null) {
            switch (propname) {
                case "view":
                    return this.findbyid(val, this.component.views, "name");
                case "type":
                    return this.findbyid(val, this.component.formtypes, "name");
                case "action":
                    return this.findbyid(val, this.component.allstatus, "name");
                case "priority":
                    return this.findbyid(val, this.component.priorities, "name");
                case "solution":
                    return this.findbyid(val, this.component.solutions, "name");
                default:
                    return val;
            }
        }
        return val;
    }

    analysis_qa_report_criteria_save(data) {
        let str = `this.component.${data.objectpath}`;
        let val = eval(str);
        const propname = this.getlastindexvalue(data.objectpath, '.');
        if (Array.isArray(val)) {
            if (val.length <= 0) {
                return;
            }
            if (propname == 'projectid') {
                return this.component.multipleselect.selectedproject.map(x => x.projectname);
            } else if (propname == 'correctorid' || propname == 'checkerid') {
                return this.getnamebyid(val, this.component.users);
            } else if (propname == 'typeid' || propname == 'viewid' || propname == 'issueid' || propname == 'priorityid' || propname == 'qasolutionid') {
                return this.getnamebyid(val, this.component.statusoptions);
            } else {
                return val;
            }
        }
        return val;
    }

    analysis_signoff_project_create_new_version(data, strid) {
        return this.analysis_signoff_project_update_version(data, strid);
    }

    analysis_signoff_project_update_version(data, strid) {
        let index1 = this.component.actionindex;
        let idindex = this.getlastindexvalue(strid, '_');
        if (index1 == idindex) {
            let evalstr = `this.component.${data.objectpath}`;
            let val = eval(evalstr);
            return val;
        }
        return true;
    }

    library_rwclibrary_saverwc(data, strid) {
        return this.getcommonvalueusingindex(data, strid);
    }

    library_rwclibrary_save_iflocation(data) {
        return this.getcommonvalue(data);
    }

    library_rwclibrary_save_thenlocation(data) {
        return this.getcommonvalue(data);
    }

    library_directionslibrary_addedit_save(data, strid) {
        let index1 = this.getlastindexvalue(strid, '_');
        let evalstr = `this.component.${data.objectpath}`;
        let val = eval(evalstr);
        let ret = null;
        const propname = this.getlastindexvalue(data.objectpath, '.');
        if (val && val != null) {
            switch (propname) {
                case "pageid":
                    ret = this.component.pagelist.find(x => x.p.id == val);
                    if (ret != undefined) {
                        return ret.p.displayname;
                    }
                case "pagecellid":
                    return this.component.FormData.directioncelldetails[index1].pc.displayname;
                case "cellactionid":
                    return this.findbyid(val, this.component.cellaction, "displayname");
                case "userids":
                    return this.findbyid(val, this.component.userlist, "fullname");
                default:
                    return val;
            }
        }
        return val;
    }

    library_comparativeslibrary_addedit_createreport(data, strid) {
        return this.getcommonvalueusingindex(data, strid);
    }

    library_comparativeslibrary_addedit_save(data, strid) {
        return this.getcommonvalueusingindex(data, strid);
    }

    library_comparativeslibrary_addedit_export(data, strid) {
        return this.getcommonvalueusingindex(data, strid);
    }

    library_momlibrary_edit_change(data) {
        return this.getcommonvalue(data);
    }

    analysis_checklistsection_save(data, strid) {
        let index1 = this.getlastindexvalue(strid, '_');
        let evalstr = `this.component.${data.objectpath}`;
        let val = eval(evalstr);
        return val;
    }

    save_bqcodeproject(data) {
        return this.getcommonvalue(data);
    }

    library_bqcodelibraryaddedit_save(data) {
        return this.getcommonvalue(data);
    }

    library_bqcodelibraryaddedit_saveas(data) {
        return this.getcommonvalue(data);
    }

    setup_project_setup_save(data, strid) {
        if (data.objectpath != "") {
            let index1 = this.getlastindexvalue(strid, '_');
            let evalstr = `this.component.${data.objectpath}`;
            let val = eval(evalstr);
            return val;
        }
        return;
    }
    setup_project_setup_save_as(data, strid) {
        return this.setup_project_setup_save(data, strid);
    }

    savemctemplatelibrary(data, strid) {
        return this.getcommonvalueusingindex(data, strid);
    }

    saveasmctemplatelibrary(data, strid) {
        return this.getcommonvalueusingindex(data, strid);
    }

    save_amtlibrary(data, strid) {
        return this.getcommonvalueusingindex(data, strid);
    }

    analysis_add_ddt_export(data) {
        return this.getcommonvalue(data);
    }

    analysis_add_ddt_save(data) {
        return this.getcommonvalue(data);
    }

    analysis_rwcreport_save(data, strid) {
        if (data.celltype == "checkbox") {
            return this.getcommonvalue(data);
        } else {
            const element = document.getElementById(strid);
            return element.value;
        }
    }

    analysis_cqcreport_save(data, strid) {
        if (data.celltype == "checkbox") {
            return this.getcommonvalue(data);
        } else {
            const element = document.getElementById(strid);
            return element.value;
        }
    }

    comms_rfientryform_save(data) {
        var val = this.getcommonvalue(data);
        return val;
    }

    getnamebyid(idlist, details) {
        let result = details.filter(obj => idlist.some(id => id === obj.id)).map(obj => obj.name);
        return result;
    }

    findbyid(val, details, column) {
        let result = details.find(obj => val === obj.id);
        if (result != undefined) {
            return result[column];
        }
        return result;
    }

    getlastindexvalue(str, separator) {
        return str.substring(str.lastIndexOf(separator) + 1);
    }

    setcomponent(prop) {
        this.component = prop;
    }
}