<template>
    <div>
        <el-dialog title="Imported Files" :visible.sync="newisible" :before-close="mediadialogclose"
            :close-on-click-modal="false" class="dialogboxpadding" width="750px">

            <div class="floatright">
                <el-link type="primary" :underline="true" target="_blank" class="fontsize24px"
                    :href="filehistory.externallink">Go to Dropbox</el-link>
            </div>

            <div class="tablepaddinglr3 tabletexttop">
                <el-table :data="filehistory.medialist" :header-cell-style="reportcellstyle" :border="true"
                    :stripe="true">
                    <el-table-column label="File Name" prop="name" />
                    <el-table-column label="Uploaded By" prop="uploader.loginname" width="130" />
                    <el-table-column label="Uploaded On" prop="uploadedon" :formatter="dateformatter" width="130" />
                    <el-table-column label="Actions" header-align="center" width="158">
                        <template slot-scope="scope">
                            <el-button type="primary" class="padding7">
                                <el-link :underline="false" :href="scope.row.externalurl" target="_blank"
                                    class="externallink">External Link</el-link>
                            </el-button>

                            <el-button type="primary" icon="el-icon-download" class="padding7"
                                @click="downloadmedia(scope.row)">
                            </el-button>

                            <el-button type="danger" icon="el-icon-delete" class="padding7"
                                @click="deletemedia(scope.row)" :disabled="!editpermission">
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import moment from "moment";
export default
    {
        props: { isvisible: Boolean, filehistory: Object },
        data() {
            return {
                newisible: this.isvisible
            };
        },
        methods: {
            reportcellstyle({ row, column, rowIndex }) {
                let firstrow = {
                    "background-color": "rgb(105, 104, 104)",
                    "font-weight": "bold",
                    "color": "white",
                    "padding": "3px"
                };
                if (rowIndex == 0) {
                    return firstrow;
                }
            },

            dateformatter(row, column, cellValue) {
                if (cellValue) {
                    cellValue = moment(cellValue).format("DD-MMM-YYYY HH:mm");
                }
                return cellValue;
            },

            downloadmedia(row) {
                var link = document.createElement("a");
                link.href = row.link;
                link.download = row.name;
                link.click();
            },

            deletemedia(row) {
                this.$confirm('Are you sure you want to Delete?', 'Warning', {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                }).then(() => {
                    this.showloading();
                    this.$http.post("sectional/deletemediafromdropbox", { id: row.id })
                        .then(response => {
                            this.hideloading();
                            this.$message({
                                showClose: true,
                                message: response.data.message,
                                type: "success"
                            });
                            this.filehistory.medialist = this.filehistory.medialist.filter(i => i.id != row.id);
                        })
                        .catch(err => {
                            this.hideloading();
                            this.$bus.$emit('error', err);
                        });
                }).catch(() => {
                    this.$message({
                        showClose: true,
                        type: 'info',
                        message: 'Delete canceled'
                    });
                });
            },

            modifyurl() {
                this.filehistory.medialist.forEach(i => {
                    i.externalurl = `${i.link.substring(0, i.link.length - 1)}0`;
                });
            },

            mediadialogclose(done) {
                this.newisible = false;
                this.filehistory.medialist = [];
                this.filehistory.externallink = '';
                this.$emit('dialogclosed');
                done();
            },
        },

        created() {
            this.modifyurl();
        }
    }
</script>