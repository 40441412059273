<template>
    <div>
        <div>
            <el-row :gutter="5" class="marginleft68px">
                <el-col :span="15">
                    <el-input v-model="search" :id="'project_project_template_searchname'" placeholder="Search Template Name" suffix-icon="el-icon-search"></el-input>
                </el-col>
                <el-col :span="8">
                    <el-checkbox v-model="showselected">
                        <span class="fontsize12"> Show only selected </span>
                    </el-checkbox>
                </el-col>
            </el-row>

            <el-table :data="getTemplates" class="fullwidth"
                      max-height="450"
                      size="mini"
                      @expand-change="expandChange">

                <el-table-column type="expand" width="25">
                    <template slot-scope="pt" class="fullwidth">
                        <el-table :data="pt.row.details"
                                  class="fullwidth"
                                  row-key="id"
                                  ref="detail"
                                  v-loading="pt.row.details == null">
                            <el-table-column label="123" prop="srno" width="35">
                            </el-table-column>
                            <el-table-column prop="category" label="Work Classification">
                            </el-table-column>
                            <el-table-column prop="bqcodelibrary.specification" label="SIL"
                                             width="55">
                            </el-table-column>
                            <el-table-column prop="bqcodelibrary.bqcode"
                                             label="BQ Code"
                                             width="360">
                            </el-table-column>
                            <el-table-column label="Qty Dim" prop="qtydim" width="65" align="right">
                            </el-table-column>
                            <el-table-column prop="unit" label="Unit" width="70">
                            </el-table-column>
                            <el-table-column label="DiR" prop="isrequired" width="65" align="center">
                                <template slot-scope="td">
                                    {{td.row.isrequired ? '&#10004' : '&#10007'}}
                                </template>
                            </el-table-column>
                            <el-table-column label="Asterisk" property="asterisk" min-width="100">
                            </el-table-column>
                            <el-table-column label="Specification" prop="specification">
                            </el-table-column>
                            <el-table-column label="End Notes" prop="other">
                            </el-table-column>
                        </el-table>
                    </template>
                </el-table-column>

                <el-table-column prop="selected"
                                 label="Select"
                                 width="50"
                                 align="center">
                    <template slot-scope="t">
                        <el-checkbox v-model="t.row.selected"></el-checkbox>
                    </template>
                </el-table-column>

                <el-table-column prop="templatename"
                                 label="Template Name" width="258">
                                 <template slot-scope="t">
                                    <div v-if="t.row.copyed == true">
                                        <el-input placeholder="Please input Template Name" v-model="t.row.templatename"></el-input>
                                    </div>
                                    <div v-else>
                                        {{ t.row.templatename }}
                                    </div>
                                 </template>
                </el-table-column>
                <el-table-column prop="unit" label="Unit" width="50"></el-table-column>
                <el-table-column prop="author.loginname" label="Author" width="70"></el-table-column>
                <el-table-column  label="Copy" width="50">
                    <template slot-scope="t">
                        <el-button
                              title="Copy Row"
                              icon="glyphicon glyphicon-duplicate"
                              style="padding: 7px 6px; margin: 0px 4px 2px;"
                              type="primary"
                              @click="copyMCTrow(t.row,t.$index)"
                            >
                          </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>

        <!-- <el-row>
            <el-col>
                <el-button @click="save" type="success" icon="el-icon-circle-check">Save</el-button>
            </el-col>
        </el-row> -->

        <div class="bordertopC868992 marginb5"></div>

        <el-row :gutter="5">
            <el-col :span="3">
                <el-button type="primary" class="padding7px5px" @click="isprojectmct = true">Add MC_T</el-button>
            </el-col>
            <el-col :span="12" class="marginleft12px"  v-if="isprojectmct == true">
                <el-input placeholder="Create New Template Name" :id="'project_project_template_add_createnewtemplatename'" v-model="projectmct.templatename" class="width250px" clearable></el-input> 
            </el-col>
            <el-col :span="3" class="marginleft12px"  v-if="isprojectmct == true">
                <el-select v-model="projectmct.unit" :id="'project_project_template_add_unit'" placeholder="Unit" class="fullwidth">
                    <el-option
                        v-for="item in units"
                        :key="item"
                        :label="item"
                        :value="item">
                    </el-option>
                </el-select>
            </el-col>
            <el-col :span="2"  v-if="isprojectmct == true">
                <el-button type="primary" icon="el-icon-circle-plus" class="padding7px9px" @click="addmctinprojectpage"></el-button>
            </el-col>
        </el-row>
        <el-row  v-if="isprojectmct == true">
            <el-col style="margin-right: -7px;" :span="4">
                Sort code
            </el-col>
            <el-col  :span="5">
                <el-autocomplete
                        class="inline-input"
                        popper-class="morewidth-autocomplete-project"
                        placeholder="Sort Code"
                        v-model="detailform.code"
                        :id="'project_project_template_add_sortcode'"
                        :fetch-suggestions="querySearchBCC"
                        @select="onSearchsortcode"
                        @change="onSearchsortcode"
                        @blur="onSearchsortcode"
                        @clear="onSearchsortcode"
                        value-key="code"
                        clearable
                      >
                      <template slot-scope="{ item }">
                                        <div style="white-space: normal">
                                        {{ item.description }}
                                        </div>
                                    </template>
                      </el-autocomplete>
            </el-col>
        </el-row>

    </div>
</template>
<script>
    var timeout;
    export default {
        data() {
            return {
                count:0,
                search: "",
                showselected: false,
                detailform: {},
                projectmct: {id:0, templatename: "", details:[], selected: true},
                isprojectmct: false,
                units: this.$store.state.units,
                basetemplates: []
            }
        },

        computed: {
            getTemplates: function () {
                if (!this.detailform.templates)
                    return [];
                if (this.search || this.showselected) {
                    var search = this.search.toLowerCase();

                    return this.detailform.templates.filter((t) => t.templatename.toLowerCase().includes(search) && (!this.showselected || t.selected == true))

                } else {
                    return this.detailform.templates;
                }
            },
        },

        methods: {
            copyMCTrow(row,index){
                if(row.templatename.trim().length == 0){
                    this.$alert("Templatename should not be empty.", "Validation Error", { dangerouslyUseHTMLString: true });
                    return;
                }
                this.expandChange(row).then(()=>{
                this.$nextTick(()=>{
                    this.count -= 1;
                    var newmct  = {};
                    newmct.templatename = row.templatename + ' - copy';
                    newmct.id = this.count;
                    newmct.copyed = true;
                    newmct.selected = true;
                    newmct.details = row.details;
                    newmct.pdetails = row.pdetails;
                    newmct.unit = row.unit; 
                    this.detailform.templates.splice(index + 1, 0, newmct);
                })
            })
            },
            onSearchsortcode(data){
                if(data && data.id){
                    this.projectmct.billcompilecodeid = data.id;
                }else{
                    this.projectmct.billcompilecodeid = null;
                }
            },
            querySearchBCC: function (query, done) {
                var obj = {};
                obj.library = this.project .library; 
                var codeid = [];
                this.project.projecttemplates.forEach((ex)=>{
                        if(ex && ex.projecttemplatedetails){
                            ex.projecttemplatedetails.forEach(det => {
                                if(det.bqcodelibraryid != null && det.bqcodelibraryid > 0){
                                    codeid.push({bqcodelibraryid:det.bqcodelibraryid});
                                 }    
                            });
                        }   
                    }); 
                obj.details = codeid;
                if(query == undefined){
                    query = "";
                }
                this.$http
                    .post("billcompilecode/getbccbyprojecttemplate?q=" + query,obj)
                    .then((response) => {
                        done(response.data);
                    })
                    .catch((err) => {
                        this.$bus.$emit("error", err);
                    });
            },
            expandChange: function (row, expandedRows) {
                if (row.templateid >= 0) {
                    this.detailform.ptlist.forEach(pl => {
                        if (pl.label == row.label) {
                            row.details = pl.projecttemplatedetails;
                            row.details.forEach(rd => {
                                rd.qtydim = rd.dim;
                            });
                            row.pdetails = row.details
                        }
                    });
                    return new Promise(function(myResolve, myReject) {
                             myResolve(); 
                        });  
                } else if ((row.details == null || row.details.length == 0) && row.id > 0 && row.templateid == undefined) {
                  return  this.$http.post('template/get', row)
                        .then(response => {
                            var json = JSON.parse(response.data);
                            row.details = json.details;
                        }).catch(err => {
                            this.$bus.$emit('error', err);
                        });
                }
                return new Promise(function(myResolve, myReject) {
                        myResolve(); 
                        });     
            },

            save: function (item, event) {
                if (this.isvalid()) {
                    var pt = []; var ptprojecttemplate = []; var template = [];
                    this.detailform.templates.forEach(t => {
                        if (t.selected && !t.isbase)
                        {
                            pt.push(t.id);
                        }

                        if (!t.isbase) {
                            template.push(t);
                        }

                        if (!t.selected && t.isbase == true) {
                            ptprojecttemplate.push(t)
                        }
                    });
                    
                    this.detailform.projecttemplates = pt;
                    this.detailform.ptlist = ptprojecttemplate;
                    this.detailform.templates = template;

                    this.$http.post('project/saveprojecttemplatelist', this.detailform)
                        .then(response => {
                            this.$message({
                                showClose: true,
                                type: 'success', message: response.data
                            });
                            this.$emit('closedialogue', this.basetemplates);
                        })
                        .catch(err => {
                            this.$bus.$emit('error', err);
                        });
                }
            },

            getProjectTemplate: function () {
                var ps = { section: this.project.section };
                var url = 'project/GetProjectTemplateList'
                this.$http.post(url, ps)
                    .then(response => {
                        this.basetemplates = [];
                        var json = JSON.parse(response.data);
                        json.templates.forEach(t => {
                            t.selected = json.projecttemplates.some(id => id == t.id);
                            if (t.selected == false) {
                                var btemplate = json.ptlist.find(pt => pt.label.trim() == t.templatename.trim());
                                if (btemplate) {
                                    t.selected = true;
                                    btemplate.templateid = t.id;
                                    btemplate.unit = t.unit;
                                    btemplate.measuredunit = t.unit;
                                    btemplate.matchedtemplate = true;
                                    this.basetemplates.push(btemplate);
                                }
                            }
                        });

                        var projectbasetemplatelist = json.ptlist.filter(pt =>{
                            if (pt.templateid == 0 && pt.qty == 0) {
                                pt.templatename = pt.label;
                                pt.selected = true;
                                pt.isbase = true;
                                return pt;
                            }
                        });

                        if (projectbasetemplatelist.length > 0) {
                            json.templates.push(...projectbasetemplatelist);
                        }

                        var sortedjsondata = json.templates.sort((a,b) => (a.templatename > b.templatename) ? 1 : ((b.templatename > a.templatename) ? -1 : 0));
                        json.templates = sortedjsondata;
                        
                        this.detailform = json;
                        this.detail = true;
                    }).catch(err => {
                        this.$bus.$emit('error', err);
                        //console.log(err);
                        //this.$alert(err.response.data, err.response.status + ' ' + err.response.statusText, { dangerouslyUseHTMLString: true });
                    });
            },
            isvalid: function () {
                var msg = [];
                this.detailform.templates.forEach((e,index)=>{
                    if(e.copyed == true){
                        if(e.templatename.trim().length == 0){
                            msg.push("Templatename should not be empty.")
                        }
                        var isfind = this.detailform.templates.findIndex((d,inindex)=> d.templatename == e.templatename && inindex != index);
                        if(isfind != -1){
                            var msgname = "Template Name: "+e.templatename+" already exist. " 
                            var fundmsg = msg.find(x => x == msgname);
                            if(!fundmsg){
                                msg.push(msgname)
                            }  
                        }
                    }
                });
                if (msg.length > 0) {
                    this.$alert(msg.join("</br>"), "Validation Error", { dangerouslyUseHTMLString: true });
                    return false;
                }
                return true;
            },

            addmctinprojectpage: function(){
                var checkduplicate = this.detailform.templates.find(t => t.templatename.replace(/\s+/g, " ").trim() == this.projectmct.templatename.replace(/\s+/g, " ").trim());

                var style = "<style> .el-message-box {width:50%;} </style>";
                if (this.projectmct.templatename.trim().length == 0 || checkduplicate) {
                    var msg = checkduplicate ? 'Template Name: ' + "\"" + this.projectmct.templatename + "\"" + ' already exist.' : 'Templatename should not be empty.';

                    this.$alert(msg + style, "Invalid Data", {
                        dangerouslyUseHTMLString: true,
                    });
                }else if(this.projectmct.billcompilecodeid == null){ 
                    this.$alert("Please select Sort Code first." + style, "Invalid Data", {
                        dangerouslyUseHTMLString: true,
                    });
                }else {
                    let projectmctobj = JSON.parse(JSON.stringify(this.projectmct));
                    this.detailform.templates.unshift(projectmctobj);
                    this.projectmct.templatename = "";
                    this.projectmct.unit = "";
                    this.projectmct.billcompilecodeid = null;
                }
            }
        },

        created: function () {
            this.count = 0;
            this.getProjectTemplate();
            this.$bus.$on("saveprojecttemplatedata", () => {
                this.save();
            });  
        },

        beforeDestroy(){
            this.count = 0;
            this.$bus.$off("saveprojecttemplatedata");
        },

        props: {
            project:Object
        }
    }
</script>
<style>
    .el-transfer-panel {
        /*this style on site.css doesn't work*/
        width: 44%;
    }

    .el-transfer-panel__body {
        height: 400px;
    }

    .el-transfer-panel__list {
        height: 396px;
    }

    .el-transfer-panel__filter i {
        display: none;
    }
</style>
