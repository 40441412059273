<template>
<div>
<el-row><span class="headerstyle"> Configuration</span></el-row>
<el-tabs v-model="activeName" @tab-click="handleClicktabe">
    <el-tab-pane label="Google Sheet" name="googlesheet">
    <h3>Google Sheet</h3>
    <el-row>
        <el-col :span="3" >
            <h5 class="fullwidth">Application Name:</h5>
        </el-col>
        <el-col :span="21" >
            <el-input
            class="fullwidth"
            size="mini"
            placeholder=" Application Name"
            v-model="detailform.googleapplicationname">
        </el-input>
        </el-col>
    </el-row>
     <el-row>
        <el-col :span="3" >
            <h5 class="fullwidth">Google Client Email:</h5>
        </el-col>
        <el-col :span="21" >
            {{detailform.googleclientemail}}
        </el-col>
    </el-row>
    <el-row>
         <el-col :span="5">
     <el-upload ref="upload"
                           action=""
                             :http-request="addAttachment"
                           :file-list="attachments"
                           accept=".json"
                           style="display:inline-block;float:right">
                    <el-button class="btnwidth" slot="trigger" type="primary" icon="el-icon-document">Import JSON</el-button>
                </el-upload>
        </el-col>
        <el-col :span="3">
         <el-button class="btnwidth" style="float:right" slot="" @click="save()"  type="success" icon="el-icon-circle-check">Save</el-button>
         </el-col>
    </el-row>
    </el-tab-pane>
    <el-tab-pane label="Drop Box" name="dropbox">
        Drop Box
    </el-tab-pane>
  </el-tabs>
</div>
</template>
<script>
export default {

      data() {
      return {
        applicationname:"",
        attachments:[],
        detailform:{},
        activeName: 'googlesheet'
      };
    },
    created: function () {
      this.handleClicktabe();
      },
    methods: {
        handleClicktabe(tab, event) {
         if(this.activeName == 'googlesheet')
         {
            this.refresh();
         }
        },
        refresh:function(){
     this.$http
        .get("configuration/GetGoogleSheetConfiguration")
            .then((response) => {
                this.detailform = {};
              this.detailform = response.data;
            })
            .catch((err) => {
            this.$bus.$emit("error", err);
            });
        },
        save:function(){
           this.$http
        .post("configuration/SaveGoogleSheetJson",this.detailform)
        .then((response) => {
          this.$message({
             showClose: true,
            type: 'success', message: response.data,
          });
          this.refresh();
        })
        .catch((err) => {
          this.$bus.$emit("error", err);
        });
        },
        addAttachment(file, fileList) {
                if (file.file.size > 3000) {
                    this.$alert("File size must be less than 3 Kb", "File Size exceeds the limit");
                    return;
                }
                this.attachments = [file.file];
                var ext = file.file.name.substr(file.file.name.length - 4).toLowerCase();
                if (ext == "json") {
                    var projectschedule = { };
                    this.detailform.jsonfile = projectschedule;
                    var fileReader = new FileReader();
                    projectschedule.name = file.file.name;
                    projectschedule.size = file.file.size;
                    projectschedule.type = ext;

                    fileReader.onload = function (e) {
                        var arrayBuffer = fileReader.result;
                        projectschedule.json = arrayBuffer;
                    }
                    fileReader.readAsDataURL(this.attachments[0]);
                }else{
                     this.$alert("Please Upload only JSON extension flle", "File extensions validation");
                    return;
                }
            },
    }
}
</script>
<style scoped>

</style>
