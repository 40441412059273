<template>
   <div >
      <div class="fixdheader">
       <el-row class="signoff_part_s_width">
        <span class="headerstyle">Sign Off - Parts</span> 
        <span class="right">   <el-button icon="el-icon-back"
                                   type="info"
                                   @click="$router.go(-1)" class="marginleft4px">Back</el-button></span>
      </el-row>
       <!-- <selectpart :Similar="Similar" @projectpartchange="projectpartchange">
       </selectpart> -->
       <div class="row">
            <div class="fixwidth">
                <el-autocomplete
                    popper-class="morewidth-autocomplete-project"
                    class="inline-input fullwidth bold padding2px"
                    v-model="project.projectname"
                    :fetch-suggestions="searchProject"
                    @select="selectProject"
                    value-key="projectname"
                    placeholder="Please select Project"
                >
                    <el-button
                        slot="suffix"
                        @click="closeproject"
                        type="text"
                        size="mini"
                        >&times;</el-button
                    >
                    <template slot-scope="{ item }">
                        <span style="float: left">{{ item.projectname }}</span>
                        <span style="float: right">{{
                            item.accessedon | dateformat
                        }}</span>
                    </template>
                </el-autocomplete>
            </div>
            <div  class="left spaceLR5">
                    <div class="el-input el-input--mini bold">
                        {{ project.library }}
                    </div>
                </div>
                <div  class="left spaceLR5">
                    <div class="el-input el-input--mini bold">
                        <span v-if="lastsaved">{{ lastsaved }}</span>
                    </div>
                </div>
        </div>
          <hr v-if="showdata" class="hrstyle left signoff_part_s_width" />
      </div>
       <el-row v-if="showdata" class="signoff_part_s_width">
             <el-table
              :data="tabledata"
              style="width: 100%" 
              :cell-style="getcellstyle"
              >
              <!-- <el-table-column
                prop="ps.sectiondisplay"
                label="NRM Element"
                width="300"
                >
              </el-table-column>
               <el-table-column
                prop="biller"
                label="Biller Self Check"
                width="150">
                <template slot-scope="scop">
                  <div v-if="scop.row.biller != null">{{scop.row.biller.cs.loginname}}</div>
                </template>
              </el-table-column>
               <el-table-column
                prop="biller"
                label="Date & Time"
                width="150">
                <template slot-scope="scop">
                  <div v-if="scop.row.biller != null">{{signoftimechange(scop.row.biller.signoffon)}}</div>
                </template>
              </el-table-column>
               <el-table-column
                prop="external"
                label="External  Check"
                width="150">
                <template slot-scope="scop">
                  <div v-if="scop.row.external != null">{{scop.row.external.cs.loginname}}</div>
                </template>
              </el-table-column>
               <el-table-column
                prop="external"
                label="Date & Time"
                >
                <template slot-scope="scop">
                  <div v-if="scop.row.external != null">{{signoftimechange(scop.row.external.signoffon)}}</div>
                </template>
              </el-table-column> -->
              <el-table-column
                type="expand"
                width="30"
                >
                <template slot-scope="props">
                  <el-table
                    :data="props.row.ssv" :show-header="false">
                        <el-table-column
                        width="30"
                    ></el-table-column>
                      <el-table-column
                          prop="ps.sectiondisplay"
                          label="NRM Element"
                          width="300"
                          >
                          <template slot-scope="scop">
                            <div class="UR-Link" @click="routepushpage(scop.row)">{{scop.row.ps.sectiondisplay}}</div>
                          </template>
                        </el-table-column>
                        <el-table-column
                          prop="biller"
                          label="Biller Self Check"
                          width="150">
                          <template slot-scope="scop">
                            <div v-if="scop.row.biller != null">{{scop.row.biller.cs.loginname}}</div>
                          </template>
                        </el-table-column>
                        <el-table-column
                          prop="biller"
                          label="Date & Time"
                          width="150">
                          <template slot-scope="scop">
                            <div v-if="scop.row.biller != null">{{signoftimechange(scop.row.biller.signoffon)}}</div>
                          </template>
                        </el-table-column>
                        <el-table-column
                          prop="external"
                          label="External  Check"
                          width="150">
                          <template slot-scope="scop">
                            <div v-if="scop.row.external != null">{{scop.row.external.cs.loginname}}</div>
                          </template>
                        </el-table-column>
                        <el-table-column
                          prop="external"
                          label="Date & Time"
                          width="150"
                          >
                          <template slot-scope="scop">
                            <div v-if="scop.row.external != null">{{signoftimechange(scop.row.external.signoffon)}}</div>
                          </template>
                        </el-table-column>
                    </el-table>
                </template>
              </el-table-column>
                        <el-table-column
                          prop="pr.name"
                          label="NRM Element"
                          width="300"
                          >
                        </el-table-column>
                        <el-table-column
                          label="Biller Self Check"
                          width="150">
                        </el-table-column>
                        <el-table-column
                          label="Date & Time"
                          width="150">
                        </el-table-column>
                        <el-table-column
                          label="External  Check"
                          width="150">
                        </el-table-column>
                        <el-table-column
                          label="Date & Time"
                          width="150"
                          >
                        </el-table-column>
            </el-table>
       </el-row>
   </div>
</template>
<script>
  import selectpart from "./selectpart.vue";
  import moment from 'moment'
export default{
  components: {
    selectpart: selectpart,
 },
    data() {
      return {
        showdata:false,
        Similar:{
           projectPlaceholder:'',
           partPlaceholder:'',
           lastsavedshow:true,
        },
        project:{},
        tabledata:[],
      }
  },
  computed:{
    lastsaved: function() {
         if (this.project && this.project.createdon) {
             var dt = new Date(this.project.createdon);
             return moment(dt).format("DD-MMM-YYYY"); // dt.toLocaleString().replace(/:\d{2}\s/, " ");
         }
    },
    signoftimechange: function() {
            return function(row) {
                if (row == undefined) {
                    return "";
                } else {
                    var dt = new Date(row);
                     return moment(dt).format('DD-MMM-YYYY') + " (" + moment(dt).format('hh:mm') + ")"

                }
            };
        }
  },
  methods: {
    getcellstyle(data){
      if(data.row.spr.checkscomplete == false){
        return {'border-bottom':'2px solid #D3D3D3','font-weight':'bold','color':'red'};  
      }
      return {'border-bottom':'2px solid #D3D3D3','font-weight':'bold'};
    },
    routepushpage(row){
      let d = { 
        projectid: row.ps.projectid,
        partid: row.ps.projectPartId,
        sectionid: row.ps.id
      };
      this.$store.state.projectids = d;
      this.$bus.$emit('addproject', d);
      this.$router.push({path: '/signoffnrmelement'})
    },
    closeproject() {
            this.project = {};
            this.refresh();
            this.showdata = false;
        },
    selectProject(event) {
            this.$store.state.project = JSON.parse(JSON.stringify(event));
            this.project = event;
            this.refresh();
        },
    refresh(){
       if (this.project.id){
          this.gepartsignoffdata();
       }
    },
    gepartsignoffdata(){
      var data = { id: this.project.id};
      this.$http.post("signoff/getsectionssummary", data)
         .then(response => {
          this.showdata = true;
          this.tabledata = response.data;
            this.hideloading();
         })
         .catch((err) => {
           this.hideloading();
           this.$bus.$emit("error", err);
    });
    },
  },
  created(){
    if (this.$store.state.project) {
         this.selectProject(this.$store.state.project);
       }
  },
}
</script>
<style>
</style>
