<template>
    <div>
        <transition name="fade" mode="in-out">
            <div ref="whiteBackground_popup" id="whiteBackground_popup" :draggable="isdraggble" class="draggable" v-if="guidancedialogue.visible">
                <div class="row cursorstylemove" @mouseover="isdraggble = true">
                    <!-- <span class="fontsize24px" v-if="!isedited">New Feature</span>
                    <span class="fontsize24px" v-else>Set Position</span> -->
                    <!-- <span class="marginleft40px">
                        <el-select v-model="guidancedialogue.pageid" clearable placeholder="Select Page" @change="getUserGuidanceReminder" class="width50percentage">
                            <el-option v-for="item in pagelist"
                                        :key="item.id"
                                        :label="item.displayname"
                                        :value="item.id">
                            </el-option>
                        </el-select>
                    </span> -->
                    <!-- <span class="glyphicon glyphicon-circle-arrow-up arrowiconstyle" :style="'transform: rotate('+ this.directiondegree +'deg);'"></span> -->
                    <!-- <el-button class="glyphicon glyphicon-circle-arrow-up arrowiconstyle cursorstylemove" :style="'transform: rotate('+ this.directiondegree +'deg);'" circle></el-button> -->
                </div>

                <div v-for="(gditem, index) in guidancedatalist" :key="gditem.id">
                    <div class="row cursorstylemove" v-if="index == currentindex" @mouseover="isdraggble = true">
                        <el-tooltip v-if="!isedited" :disabled="disabledtooltip" :content="gditem.popupname" placement="bottom" effect="light">
                        <span class="D_Tpopupheadingstyle" ref="directionheader">{{gditem.popupname}}</span>
                        </el-tooltip>

                        <span class="fontsize24px" v-else>Set Position</span>
                        <el-button class="glyphicon glyphicon-circle-arrow-up arrowiconstyle cursorstylemove" v-if="getdirectionarrow(gditem)" :style="'transform: rotate('+ gditem.directiondegree +'deg);'" circle></el-button>
                    </div>
                    <div class="row flex" v-if="index == currentindex">

                        <div class="column1" @mouseover="isdraggble = false">
                            <p v-html="gditem.content"></p>
                        </div>

                        <div class="column2" @mouseover="isdraggble = false" :style="gditem.content2 ? 'background-color: ' + gditem.content2 : ''">
                            <div v-for="svi in gditem.mediacontents" :key="svi.id" style="display: flex; align-items: center; justify-content: center; height: 100%;">
                                <span v-if="isImage(svi.name)">
                                    <div class="image-wrapper">
                                        <!-- <img :src="svi.link" class="previewbox" style="border: 1px solid; width: 250px; height: auto;" /> -->
                                        <el-image :preview-src-list="[svi.link]" :src="svi.link" class="previewbox" style="border: 1px solid; width: 250px; height: auto;" />
                                        <span class="fullscreen-icon" @click="openImage(svi.link)">
                                            <span class="el-icon-full-screen"></span>
                                        </span>
                                    </div>
                                </span>

                                <span v-else-if="isVideo(svi.name)">
                                    <video controls class="previewbox" style="margin-bottom: 8px;">
                                        <source :src="svi.link" type="video/mp4">
                                        Your browser does not support the video tag.
                                    </video>
                                </span>

                                <!-- <span v-if="beforemedia.extention == 'mp3'">
                                    <audio controls class="previewbox">
                                        <source :src="beforemedia.link" type="audio/ogg">
                                        <source :src="beforemedia.link" type="audio/mpeg">
                                        Your browser does not support the audio element.
                                    </audio>
                                </span> -->
                            </div>
                        </div>
                    </div>

                    <div class="row margintop6px" v-if="index == currentindex">
                        <!-- <el-button @click="changepage('leftarrow', gditem, index)" type="info" 
                                    icon="el-icon-arrow-left" circle
                                    :class="`leftslidearrow${!hasPreviousContent ? ' disabled' : ''}`" 
                                    :disabled="index == 0">
                        </el-button> -->

                        <el-tooltip v-if="index != 0" ref="nextprevtooltip" effect="dark" content="Previous" placement="bottom">
                            <el-button 
                                @click="changepage('leftarrow', gditem, index)"
                                :class="`padding6px10px guidancdlgnextprebtn${!hasPreviousContent ? ' disabled' : ''}`" 
                                type="primary" icon="el-icon-back"
                                :disabled="index == 0">
                            </el-button>
                        </el-tooltip>
                        <span v-else style="margin-left: 34px;"></span>

                        <span v-if="isedited">
                            <el-select v-model="gditem.positiontype" @change="changePositionType(gditem)" placeholder="Select Position" class="width100px marginleft40px">
                                <el-option v-for="item in boxpositions"
                                            :key="item.label"
                                            :label="item.label"
                                            :value="item.value">
                                </el-option>
                            </el-select>

                            &nbsp; X: <el-input-number :value="positionX" v-model="positionX" @input="setboxpositionXY(gditem)" :controls="false" class="positionbtnstyle disinput" ></el-input-number>
                            Y: <el-input-number :value="positionY" v-model="positionY" @input="setboxpositionXY(gditem)" :controls="false" class="positionbtnstyle disinput" ></el-input-number>

                            <span> <el-button type="success" @click="saveGuidanceBoxPositionData" class="marginleft20px">Save</el-button></span>
                        </span>

                        <span v-else>
                            <el-button type="danger" @click="usergotitguidance" class="red-danger-button  marginleft130px padding6px10px">Got it</el-button>
                            <el-button type="success" @click="dlgClose" class=" padding6px10px">Remind Me Later</el-button>
                        </span>

                        <el-tooltip v-if="index != guidancedatalist.length - 1" ref="nextprevtooltip" effect="dark" content="Next" placement="bottom">
                            <el-button 
                                @click="changepage('rightarrow', gditem, index)"
                                :class="`floatright padding6px10px guidancdlgnextprebtn${!hasNextContent ? ' disabled' : ''}`"
                                type="primary" icon="el-icon-right"
                                :disabled="index == guidancedatalist.length - 1">
                            </el-button>
                        </el-tooltip>

                        <!-- <el-button @click="changepage('rightarrow', gditem, index)" type="info" 
                                    icon="el-icon-arrow-right" circle 
                                    :class="`rightslidearrow${!hasNextContent ? ' disabled' : ''}`" 
                                    :disabled="index == guidancedatalist.length - 1">
                        </el-button> -->
                    </div>
                </div>
                <!-- <div id="dlgbox">
                    <div class="dlg-body" style="overflow-y: auto">
                        <button @click="dlgClose" class="close-button">&times;</button>
                        <div style="background-color: lightgray; padding: 30px 50px 60px 50px; min-height: 90%;">
                            <div>
                                <div>
                                    <b class="heading">
                                        This window will select the BQ code for whole Schedule
                                        &nbsp;&nbsp; section up to next SN/CH row.
                                    </b>
                                </div>
                                <div>
                                    <span class="warnfooter">
                                        Do you confirm that all items within this section
                                        &nbsp;&nbsp; are of similar nature and suit selected
                                        BQ code?
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>
        </transition>
    </div>
</template>

<script>
export default {
    props: { 
        guidancedialogue: { visible: Boolean, pageid: Number}
         },
    data() {
        return {
            guidancepagelist:[],
            guidancesubpagelist:[],
            pagecellname:null,
            pagelist: [],
            pageid: null,
            guidancedatalist: [],
            currentindex: 0,
            directiondegree: 0,
            ugrdatalist: [],
            isdraggble: true,
            boxpositions: [
                { label: 'Top left', value: 1 },
                { label: 'Top right', value: 2 },
                { label: 'Bottom left', value: 3 },
                { label: 'Bottom right', value: 4 }
            ],
            currentpositiontype: 1,
            positionX: 630,
            positionY: 400,
            boxdisplaytype: 1,
            nextprevtooltipdata: {
                nextcontent: "Next",
                prevcontent: "Previous",
                istooltipgenerated: false
            },
            disabledtooltip: true
        };
    },

    // watch: {
    //     // whenever question changes, this function will run
    //     'guidancedialogue.visible'(newQuestion, oldQuestion) {
    //         if (newQuestion) {
    //             this.getUserGuidanceReminder();
    //         }
    //     }
    // },

    computed: {
        getdirectionarrow(){
            return function(gditem){
                return gditem.directiondegree != null
            }
        },
        isedited(){
            return this.$store.state.newfeaturedialgoue.editedialgoue;
        },

        hasPreviousContent() {
            return this.currentindex > 0;
        },

        hasNextContent() {
            return this.currentindex < this.guidancedatalist.length - 1;
        },

        adjustedFontSize() {
            const dh = this.$refs.directionheader;
            if (dh) {
                dh.style.width = "360px";
                const computedStyle = window.getComputedStyle(dh);
                const currentWidth = parseInt(computedStyle.width);
                
                let textWidth = this.getTextWidth(this.myoption.heading, 'bold 20px Helvetica');
                if (textWidth > currentWidth) {
                    this.disabledtooltip = false
                } else {
                    this.disabledtooltip = true;
                }
            }
        }
    },

    methods: {
        setboxpositionXY(row) {
            if(this.positionX > (window.innerWidth - 264)){
                this.positionX = (window.innerWidth - 264);
            }
            if(this.positionY > (window.innerHeight - 180)){
                this.positionY = (window.innerHeight - 180);
            }
            if(this.positionY < 180){
                this.positionY = 180;
            }
            if(this.positionX < 264){
                this.positionX = 264;
            }
            row.positionX = this.positionX;
            row.positionY = this.positionY;

            this.setboxpositionbyxandy()
        },

        getGuidancePages: function () {
            this.showloading();
            this.$http.get("sectional/getGuidancePages")
                .then(response => {
                    this.pagelist = response.data;
                    this.hideloading();
                })
                .catch(err => {
                    this.hideloading();
                    this.$bus.$emit('error', err);
                });
        },
        setboxpositionbyxandy(){
            this.positionX = this.guidancedatalist[this.currentindex].positionX;
            this.positionY = this.guidancedatalist[this.currentindex].positionY;
            this.currentpositiontype = this.guidancedatalist[this.currentindex].positiontype;
            if (this.positionX == 0 && this.positionY == 0) {
                this.positionX = 630;
                this.positionY = 400;
            }
            if (!this.currentpositiontype) {
                this.guidancedatalist[this.currentindex].positiontype = 1;
                this.currentpositiontype = 1;
            }
            if (this.currentpositiontype) {
                this.setBoxPositionByType(this.currentpositiontype, this.positionX, this.positionY);
            } else {
                this.currentpositiontype = 1;
                this.$refs.whiteBackground_popup.style.left = this.positionX + "px";
                this.$refs.whiteBackground_popup.style.top = this.positionY + "px";
            }
        },
        getboxpositionbyid(id){
            var chickvalues = document.getElementById(id);
            if(chickvalues != null){
              var topPos = chickvalues.getBoundingClientRect();
               if(topPos != null){
                const self = this;
                self.$nextTick(()=>{
                    var dd = this.$training.getboxposition(topPos);
                    self.$refs['whiteBackground_popup'].style.left = dd.left;
                    self.$refs['whiteBackground_popup'].style.right = dd.right;
                    self.$refs['whiteBackground_popup'].style.top = dd.top;
                    self.$refs['whiteBackground_popup'].style.bottom = dd.bottom;
                })
                return true;
               }else{
                return false;
               }
            }else{
                return false;
            }
        },
        getUserGuidanceReminder: function() {
            this.showloading();
            this.ugrdatalist = [];
            this.$http.post("sectional/getUserGuideReminder?pageid=" + this.guidancedialogue.pageid + '&displaytype=' + this.boxdisplaytype, {})
                .then(response => {
                    const currentrouter =  this.$router.currentRoute;
                    this.guidancepagelist = response.data.filter(x => x.p.name == currentrouter.name);
                    this.guidancesubpagelist = response.data.filter(x => x.p.name != currentrouter.name);
                    this.guidancedatalist = this.guidancepagelist;
                    if (this.guidancedatalist.length > 0) {
                        this.guidancedatalist = this.guidancedatalist.sort((a, b) => a.trainingnumber - b.trainingnumber);
                        this.guidancedialogue.visible = true;
                        this.$nextTick(() => {
                            this.guidancedatalist.forEach(gditem => {
                                gditem.metadata = { content: gditem.content };
                                // this.directiondegree = gditem.directiondegree;

                                let ugritem = { id: 0, guidanceid: gditem.id, userid: this.$store.state.user.id, status: true };
                                this.ugrdatalist.push(ugritem);
                            });
                            if(this.currentindex  >=  0){
                                   if(this.pagecellname == null){
                                        this.setboxpositionbyxandy();
                                    }else{
                                        var isset = this.getboxpositionbyid(this.guidancedatalist[this.currentindex].pagecell.cellname);   
                                        if(isset == false){
                                            this.setboxpositionbyxandy();
                                        }
                                    }
                           }else{
                            this.guidancedialogue.visible = false;
                           }
                        });
                    }
                    if(this.pagecellname != null){
                        this.currentindex =  this.guidancedatalist.findIndex(item => item.pagecell != null && item.pagecell.cellname == this.pagecellname);    
                        if(this.currentindex < 0){
                            this.guidancedatalist = this.guidancesubpagelist;
                            this.currentindex =  this.guidancedatalist.findIndex(item => item.pagecell != null && item.pagecell.cellname == this.pagecellname);
                        }
                    }else{

                        this.currentindex = 0;
                    }

                    this.hideloading();
                })
                .catch(err => {
                    this.hideloading();
                    this.$bus.$emit('error', err);
                });
        },

        isImage(name) {
            if (name) {
                const extension = name.substring(name.lastIndexOf('.') + 1).toLowerCase();
                return ['jpg', 'jpeg', 'gif', 'png'].includes(extension);
            }
        },

        isVideo(name) {
            if (name) {
                const extension = name.substring(name.lastIndexOf('.') + 1).toLowerCase();
                return extension === 'mp4';
            }
        },

        changepage: function(slide, row, index) {
            if (slide == "leftarrow") {
                this.currentindex = index - 1;
            } else if (slide == "rightarrow") {
                this.currentindex = index + 1;
            }
            // this.directiondegree = row.directiondegree;
            this.currentpositiontype = this.guidancedatalist[this.currentindex].positiontype;
            this.positionX = this.guidancedatalist[this.currentindex].positionX;
            this.positionY = this.guidancedatalist[this.currentindex].positionY;

            if (this.currentpositiontype == 0) {
                this.currentpositiontype = 1;
                this.guidancedatalist[this.currentindex].positiontype = 1;
            }

            if (this.positionX == 0 && this.positionY == 0) {
                this.positionX = 630;
                this.positionY = 400;
            }

            this.setBoxPositionByType(this.currentpositiontype, this.positionX, this.positionY);
            // this.$refs.whiteBackground_popup.style.left = this.positionX + "px";
            // this.$refs.whiteBackground_popup.style.top = this.positionY + "px";
        },

        dlgClose: function () {
            this.guidancedialogue.visible = false;
        },

        usergotitguidance: function() {
            this.guidancedialogue.visible = false;
            if (this.boxdisplaytype == 1) {
                this.showloading();
                this.$http.post("sectional/userGotItGuidance", this.ugrdatalist)
                    .then(response => {
                        this.guidancedialogue.visible = false;
                        this.hideloading();
                    })
                    .catch(err => {
                        this.hideloading();
                        this.$bus.$emit('error', err);
                    });
                }
        },

        saveGuidanceBoxPositionData: function() {
            let guidancedata = { guidancedatalist: this.guidancedatalist };
            let formdata = this.jsonToFormData(guidancedata);
            this.showloading();

            this.$http.post("sectional/saveGuidanceData", formdata)
                .then(response => {
                    this.$message({
                        type: "success",
                        showClose: true,
                        message: response.data.message
                    });
                    this.getUserGuidanceReminder();
                    this.hideloading();
                })
                .catch(err => {
                    this.hideloading();
                    this.$bus.$emit('error', err);
                });
        },

        buildFormData(formData, data, parentKey) {
            if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
                Object.keys(data).forEach(key => {
                    this.buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
                });
            } else {
                const value = data == null ? '' : data;
                formData.append(parentKey, value);
            }
        },

        jsonToFormData(data) {
            const formData = new FormData();
            this.buildFormData(formData, data);
            return formData;
        },

        changePositionType(row) {
            this.currentpositiontype = row.positiontype;
            this.setBoxPositionByType(row.positiontype, row.positionX, row.positionY);
        },

        setBoxPositionByType(positiontype, positionX, positionY) {
            let width = 528;
            let height = 360;
            if (document.getElementById("whiteBackground_popup")) {
                width = positionX + document.getElementById("whiteBackground_popup").offsetWidth;
                height = positionY + document.getElementById("whiteBackground_popup").offsetHeight;
            }

            if (this.$refs.whiteBackground_popup) {

                this.$refs.whiteBackground_popup.style.left = "";
                this.$refs.whiteBackground_popup.style.top = "";
                this.$refs.whiteBackground_popup.style.right = "";
                this.$refs.whiteBackground_popup.style.bottom = "";

                if (positiontype == 1) {
                    this.$refs.whiteBackground_popup.style.left = positionX + "px";
                    this.$refs.whiteBackground_popup.style.top = positionY + "px";

                } else if (positiontype == 2) {
                    this.$refs.whiteBackground_popup.style.right = (window.innerWidth - width) + "px";
                    this.$refs.whiteBackground_popup.style.top = positionY + "px";

                } else if (positiontype == 3) {
                    this.$refs.whiteBackground_popup.style.left = positionX + "px";
                    this.$refs.whiteBackground_popup.style.bottom = (window.innerHeight - height) + "px";
                    
                } else {
                    this.$refs.whiteBackground_popup.style.right = (window.innerWidth - width) + "px";
                    this.$refs.whiteBackground_popup.style.bottom = (window.innerHeight - height) + "px"; 
                }
            }
        },

        popup_drag_start(event) {
            var style = window.getComputedStyle(event.target, null);
            var newstr = "popup_drag,"+
                (parseInt(style.getPropertyValue("left"), 10) - event.clientX) +
                "," +
                (parseInt(style.getPropertyValue("top"), 10) - event.clientY) 
            event.dataTransfer.setData( "text/plain",newstr);
        },

        popup_drag_over(event) {
            event.preventDefault();
            return false;
        },

        popup_drop(event) {
            var offset = event.dataTransfer.getData("text/plain").split(",");
            if(offset[0] ==  "popup_drag"){
            //var dm = this.$refs.whiteBackground_popup;
            
            this.positionX = event.clientX + parseInt(offset[1], 10);
            this.positionY = event.clientY + parseInt(offset[2], 10);
            this.setBoxPositionByType(this.currentpositiontype, this.positionX, this.positionY);
            event.preventDefault();
            }
            return false;
        },

        makeDraggable() {
            var dm = this.$refs.whiteBackground_popup;
            if (dm) {
                dm.addEventListener("dragstart", this.popup_drag_start, false);
                window.addEventListener("dragover", this.popup_drag_over, false);
                window.addEventListener("drop", this.popup_drop, false);
            }
        },

        heightCount() {
            //   this.height = this.$refs.content.clientHeight;
            if (this.height <= 318.5 && this.height != 0) {
                this.verticalcenter = true;
            } else {
                this.verticalcenter = false;
            }
        },

        openImage(imageUrl) {
            const imageElement = new Image();
            imageElement.src = imageUrl;

            imageElement.onload = () => {
                const containerElement = document.createElement('div');
                containerElement.style.display = 'flex';
                containerElement.style.justifyContent = 'center';
                containerElement.style.alignItems = 'center';
                containerElement.style.width = '100vw';
                containerElement.style.height = '100vh';
                containerElement.style.position = 'fixed';
                containerElement.style.top = '0';
                containerElement.style.left = '0';
                containerElement.style.backgroundColor = 'rgba(0, 0, 0, 0.9)';
                containerElement.style.zIndex = '9999';

                const fullScreenImageElement = new Image();
                fullScreenImageElement.src = imageUrl;
                fullScreenImageElement.style.maxWidth = '90vw';
                fullScreenImageElement.style.maxHeight = '90vh';
                fullScreenImageElement.style.objectFit = 'contain';

                const closeIconElement = document.createElement('span');
                closeIconElement.classList.add('close-icon');
                closeIconElement.innerHTML = '&times;';

                containerElement.appendChild(closeIconElement);
                containerElement.appendChild(fullScreenImageElement);
                document.body.appendChild(containerElement);

                const closeFullscreen = () => {
                    document.body.removeChild(containerElement);
                };

                closeIconElement.addEventListener('click', closeFullscreen);
                fullScreenImageElement.addEventListener('click', closeFullscreen);
            };
        }
    },

    updated() {
        if (this.guidancedialogue.visible) {
            this.$nextTick(this.makeDraggable);
            this.$nextTick(this.heightCount);
            
            this.adjustedFontSize;

            /*let nptooltip = this.$refs.nextprevtooltip[0];
            if (!this.nextprevtooltipdata.istooltipgenerated) {
                nptooltip.showPopper = true;
                this.nextprevtooltipdata.istooltipgenerated = true;
            } else {
                nptooltip.showPopper = false;
            }*/
        }

    },
    created() {
        // if (this.$store.state.user.id != undefined) {
        //     this.getGuidancePages();
        // }
        
        this.$nextTick(this.makeDraggable);
        this.$nextTick(this.heightCount);
    },
    destroyed() {
        //  if(this.isemit == true){
        // this.$bus.$off("addproject");
        this.$bus.$off("userguidegotit");
        //  }
    },
    mounted() {
        // this.$bus.$on('getUserGuidanceReminder', (pageid) => {
        //     this.guidancedialogue.pageid = pageid;
        //     this.getUserGuidanceReminder();
        // });
        this.adjustedFontSize;

        if (this.$store.state.pages.length == 0 && this.$store.state.user.id != undefined) {
            this.getPages();
        }

        this.$bus.$on('userguidegotit', (currentpage, currentrouter,pagecellname) => {
            this.pagecellname = pagecellname;
            if (currentrouter.params && currentrouter.params.showguidancedialog) {
                this.boxdisplaytype = 2;
            } else {
                this.boxdisplaytype = 1;
            }

            if (currentrouter.params && currentrouter.params.openediteddialogue) {
                this.boxdisplaytype = 2;
            } else {
                this.$store.state.newfeaturedialgoue.editedialgoue = false;
            }

            if (!currentpage) {
                currentpage = this.$store.state.pages.find(pageitem => pageitem.p.name == currentrouter.name );
            }
            this.guidancedialogue.visible = false;
            if (currentpage && currentpage.p) {
                this.guidancedialogue.pageid = currentpage.p.id;
                this.getUserGuidanceReminder()
            }
        });
    },
};
</script>

<style scoped>
#whiteBackground_popup {
    padding: 4px 10px 4px 10px;
    background-color: #e1e1e1;
    border: 1px solid #f5f5f5;
    border-radius: 4px;
    box-shadow: 0px 0.8px 1.5px 1.5px #666;
    width: 528px;
    height: 360px;
    /* top: 50%;
    left: 50%; */
    position: fixed;
    transform: translate(-50%, -50%);
    opacity: 1;
    z-index: 1200;
    align-items: center;
    resize: none;
}

.column1 {
  float: left;
  width: 250px;
  padding: 10px;
  height: 275px;
  border: 1px solid #888888;
  border-radius: 2px;
  background-color: white;
  overflow: auto;
}

.column2 {
  float: left;
  width: 250px;
  /* padding: 10px; */
  height: 275px;
  border: 1px solid #888888;
  border-radius: 2px;
  background-color: white;
  margin-left: 8px;
  overflow: auto;
}

.row:after {
  content: "";
  display: table;
  clear: both;
}

#dlgbox {
    position: fixed;
    width: 90%;
    height: 50%;
    margin: auto;
    background-color: lightgray;
    z-index: 1;
    /* border: 2px solid red; */
    border-radius: 25px;
}

.dlg-body {
    background-color: lightgray;
    color: black;
    font-size: 14px;
    padding-left: 58px;
    border: 4px solid black;
    min-height: 90%;
    /* border: 2px solid red; */
    border-radius: 25px;
}

.newimg {
    bottom: 0;
    position: absolute;
    left: 0;
}
.vertical-center {
    margin: 0;
    position: absolute;
    top: 38%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.el-checkbox.is-bordered.el-checkbox--mini {
    padding: 4px 8px 7px 8px;
    border-radius: 3px;
    height: 26px;
    border: 1px solid black;
}

.warnfooter {
    font-size: 18px;
    color: darkred;
    font-weight: bold;
}
.red-danger-button {
    color: red !important;
    /* font-weight: bold; */

}
.close-button {
    float: right;
    position: sticky;
    top: 3px;
    right: 3px;
}

p {
    margin: 0 0 0px;
}

.heading {
    font-family: "Calibri", "Comic Sans MS", "Times New Roman", "Arial";
    font-size: 17px;
}

.fade-enter-active {
    animation: fadein 0.5s;
    animation-iteration-count: 1;
}

.fade-leave-active {
    animation: fadeout 0.7s;
    animation-iteration-count: 1;
}

.margin10px0px {
    margin: 10px 0px;
}

.margintop6px {
    margin-top: 6px;
}



.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

@keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fadeout {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}
</style>
  