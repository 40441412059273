<template>
    <div>
        <div class="fixdheader">
         <div style="width:1150px;">
            <span class="headerstyle" >CQC Library </span>

            <div  abel="" class="floatright margintb1mm">
                <el-button  :disabled="!editpermission" @click="cellchanged('library_cqclibrary_save',()=>{save()},false)" type="success" icon="el-icon-circle-check">Save</el-button>
            </div>
        </div>
        <el-row>
            <el-col :span="24">
                <div style="width:41px;margin-left: 5px;display:inline-block">
                    <el-button :class="expandcollapsetext == '+' ? 'icon-expand-btn-width-pluse' : 'icon-expand-btn-width-minus'"   type="info" @click="expandcollapse">{{expandcollapsetext}}</el-button>
                </div>

                <div style="width:309px;display:inline-block">
                    <el-input v-model="search" :id="'library_cqclibrary_filter_cqcname'" placeholder="Q  CQC Name" :spellcheck="isspellcheck" ></el-input>
                </div>

                <div style="min-width: 90px; display: inline-block" class="padleft5px">
                    <el-select v-model="cqcfilterdata" style="width: 90px;" placeholder="Library" :id="'library_cqclibrary_filter_library'" clearable>
                        <el-option
                            v-for="item in libraries"
                                :key="item"
                                :label="item"
                                :value="item">
                        </el-option>
                    </el-select>
                </div>

                <div style="min-width: 381px; display: inline-block">
                  <el-pagination 
                        class="pagesizeswidth textalignlpage center"
                        @size-change="rowperpages"
                        :current-page.sync="page"
                        @current-change="pageCurrentChange"
                        :page-sizes="pagesizes"
                        :page-size="pagesize"
                        layout="sizes, prev, pager, next"
                        :total="total">
                    </el-pagination>
                </div>

                <div style="display: inline-block">
                    <el-select v-model="URfiltersearch" placeholder="UR" :id="'library_cqclibrary_filter_ur'" clearable style="width:76px;">
                        <el-option
                            v-for="item in URfilter"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                        </el-option>
                    </el-select>
                </div>
                <div style="display: inline-block;padding-left: 75px;" >
                    <el-select v-model="searchtypefilter" clearable class="fullwidth" :id="'library_cqclibrary_filter_type'" placeholder="Type" style="width:91px;">
                        <el-option
                            v-for="item in searchtypes"
                                :key="item"
                                :label="item"
                                :value="item">
                        </el-option>
                    </el-select>
                </div>
            </el-col>
        </el-row>

        </div>
         <hr class="hrstyle left"  style="width:1150px;"/>
         <div style="width: 1160px;">        
        <el-table ref="cqctable" :height="height"  :data="getdetailform" :show-header="true" :expand-row-keys="expandedrows" row-key="id" @expand-change="expandchange" highlight-current-row @current-change="currentCQCChange" :border="false">
            <el-table-column type="expand" width="50" label="" >
                <template slot-scope="cqc" class="fullwidth">
                    <el-row>
                        <el-col :span="24" class="padright5px">
                            <el-table class="row-expand-cover" ref="xcxdtable"  :expand-row-keys="expandKeys" :data="getdetail(cqc.row)" row-key="id" @expand-change="xCexpandchange" highlight-current-row @current-change="currentCQCRowChange(cqc.row, $event)">
                                <el-table-column prop="srno" label="123" width="50">
                                    <template slot-scope="scope">
                                        <el-input-number :disabled="!editpermission" :controls="false" :id="`library_cqclibrary_table_sr_no_${cqc.$index}_${scope.$index}`" v-model="scope.row.srno" @change="srnochanged(cqc.row)"></el-input-number>
                                    </template>
                                </el-table-column>
                                <el-table-column label="IDS Name" width="310">
                                    <template slot-scope="scope" >
                                        <el-autocomplete size="mini" class="inline-input fullwidth"  popper-class="morewidth-autocomplete"
                                                         v-model="scope.row.ids.idsname"
                                                         :id="`library_cqclibrary_table_idsname_${cqc.$index}_${scope.$index}`"
                                                         :fetch-suggestions="querySearch"
                                                         placeholder=""
                                                         :disabled="!editpermission"
                                                         @select="handleSelect(cqc.row, scope.row, $event)"
                                                         :select-when-unmatched="true"
                                                         value-key="idsname"
                                                         @blur="idsnameblur(cqc.row, scope.row, $event)"
                                                         :trigger-on-focus="true"
                                                         >
                                            <template slot-scope="{ item }">
                                                <div style="white-space:normal;">{{ item.idsname }}</div>
                                                <div style="white-space:normal;font-size: x-small">{{ item.nrmsection  }} | {{ item.section }} | {{ item.group }} | {{ item.work }} | {{ item.level1 }} | {{ item.level2 }} | {{ item.level3 }}| {{ item.unit }} </div>
                                            </template>
                                            <el-button  slot="suffix" icon="el-icon-edit" @click="editids(scope.row,cqc.row)" type="text" size="mini"></el-button>
                                        </el-autocomplete>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="notes" label="Notes"  width="313">
                                    <template slot-scope="scope">
                                        <el-input type="text" :disabled="!editpermission" :id="`library_cqclibrary_table_notes_${cqc.$index}_${scope.$index}`" v-model="scope.row.notes" :spellcheck="isspellcheck" @change="changed(cqc.row)"></el-input>
                                    </template>
                                </el-table-column>

                                 <!-- this is the sub row of each ids added to import independent measure UR data as in brief CC21.1  -->
                                <el-table-column width="1" type="expand">



                                    <template slot-scope="scope">
                                    <el-row :gutter="20">
                                        <el-col class="headerSO" style="width: 60px;" align="center">
                                            <p>xC = </p>
                                        </el-col>
                                        <el-col  style="width: 100px;padding-left:0px;">
                                            <!-- <el-autocomplete
                                                class="inline-input fullwidth bold padding2px inputcenter"
                                                 v-model="scope.row.ur1"
                                                :spellcheck="isspellcheck"
                                                :fetch-suggestions="searchur"
                                                placeholder="Insert UR(D)"
                                                @select="onSelect1(cqc.row,scope.row.ur1,scope.row)"

                                                clearable
                                                @clear="onClearIMUR1(cqc.row,scope.row)">
                                                  <i
                                                  v-if="scope.row.ur1"
                                                   slot="prefix"
                                                   @click="$router.push('/independentmeasuretemplate')"
                                                   class="el-icon-link" style="color: #409eff; font-weight: 600; padding-top: 7px; font-size: 13px;">
                                                </i>
                                            </el-autocomplete> -->
                                             <el-select class="inline-input fullwidth bold padding2px inputcenter"
                                                    v-model="scope.row.ur1"
                                                    :id="`library_cqclibrary_table_xc_edit_insertur_${cqc.$index}_${scope.$index}`"
                                                    clearable remote
                                                    @change="onSelect1(cqc.row,scope.row.ur1,scope.row)"
                                                    :remote-method="searchur"
                                                    :disabled="!editpermission"
                                                    reserve-keyword filterable
                                                    placeholder="Insert UR(D)">
                                                    <i
                                                      v-if="scope.row.ur1"
                                                       slot="prefix"
                                                       @click="$router.push('/independentmeasuretemplate')"
                                                       class="el-icon-link" style="color: #409eff; font-weight: 600; padding-top: 7px; font-size: 13px;">
                                                    </i>
                                                  <el-option
                                                    v-for="(item, index) in $store.state.URd"
                                                    :key="index"
                                                    :label="item.ur"
                                                    :value="item.ur">
                                                  </el-option>
                                             </el-select>

                                            <!-- <el-input :disabled="!editpermission"
                                             placeholder="UR"
                                              v-model="scope.row.ur1"
                                                maxlength="5"
                                               :spellcheck="isspellcheck"
                                                onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                                @input.native="checkurinput"
                                                @change="urchanged(cqc.row,'ur1')"> </el-input> -->
                                        </el-col>
                                        <el-col  style="width: 244px;padding-left:0px;">
                                            <el-input placeholder=" Data Description" v-model="scope.row.description1" :id="`library_cqclibrary_table_xc_edit_datadescription_${cqc.$index}_${scope.$index}`" @change="changed(cqc.row)" ></el-input>
                                        </el-col >
                                        <el-col  style="width: 230px;padding-left:0px;">
                                            <el-input placeholder="User Comment" v-model="scope.row.comment1" :id="`library_cqclibrary_table_xc_edit_usercomment_${cqc.$index}_${scope.$index}`" @change="changed(cqc.row)"></el-input>
                                        </el-col>
                                        <el-col  style="width: 50px;padding-left:0px;">
                                            <el-input placeholder="Unit" :readonly="true" :id="`library_cqclibrary_table_xc_edit_unit_${cqc.$index}_${scope.$index}`" :value="getunit(scope.row,'ur1').unit"  ></el-input>
                                        </el-col>
                                        <el-col  style="width: 95px;padding-left:0px;">
                                            <el-input-number class="inputright" :precision="2" placeholder="Qty" :controls="false"  v-model="scope.row.adj1" :id="`library_cqclibrary_table_xc_edit_qty_${cqc.$index}_${scope.$index}`" @change="changed(cqc.row)"></el-input-number>
                                        </el-col>
                                    </el-row>
                                     <el-row :gutter="20">
                                        <el-col  class="headerSO"  style="width: 60px;"  align="center">
                                            <p>xD = </p>
                                        </el-col>
                                        <el-col  style="width: 100px;padding-left:0px;">
                                            <!-- @input.native="checkurinput" -->
                                            <!-- we are not able to add @input.native as dash '-" is not input in search to checkurinput method-->
                                           <!-- <el-autocomplete
                                                class="inline-input fullwidth bold padding2px inputcenter"
                                                 v-model="scope.row.ur2"
                                                :spellcheck="isspellcheck"
                                                :fetch-suggestions="searchur"
                                                placeholder="Insert UR(D)"
                                                @select="onSelect2(cqc.row,scope.row.ur2,scope.row)"

                                                clearable
                                                @clear="onClearIMUR2(cqc.row,scope.row)">
                                                 <i
                                                  v-if="scope.row.ur2"
                                                   slot="prefix"
                                                   @click="$router.push('/independentmeasuretemplate')"
                                                   class="el-icon-link" style="color: #409eff; font-weight: 600; padding-top: 7px; font-size: 13px;">
                                                </i>
                                            </el-autocomplete> -->
                                             <el-select class="inline-input fullwidth bold padding2px inputcenter"
                                                     v-model="scope.row.ur2"
                                                    clearable remote
                                                    @change="onSelect2(cqc.row,scope.row.ur2,scope.row)"
                                                    :remote-method="searchur"
                                                    :disabled="!editpermission"
                                                    reserve-keyword filterable
                                                    :id="`library_cqclibrary_table_xd_edit_insertur_${cqc.$index}_${scope.$index}`"
                                                    placeholder="Insert UR(D)">
                                                   <i
                                                  v-if="scope.row.ur2"
                                                   slot="prefix"
                                                   @click="$router.push('/independentmeasuretemplate')"
                                                   class="el-icon-link" style="color: #409eff; font-weight: 600; padding-top: 7px; font-size: 13px;">
                                                </i>
                                                  <el-option
                                                    v-for="(item, index) in $store.state.URd"
                                                    :key="index"
                                                    :label="item.ur"
                                                    :value="item.ur">
                                                  </el-option>
                                             </el-select>
                                               <!-- <el-input placeholder="UR"
                                             v-model="scope.row.ur2"
                                             :spellcheck="isspellcheck"
                                               maxlength="5"
                                                onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                                                @input.native="checkurinput"
                                              @change="urchanged(cqc.row,'ur2')"></el-input> -->


                                        </el-col>
                                        <el-col  style="width: 244px;padding-left:0px;">
                                            <el-input placeholder="Data Description" v-model="scope.row.description2" :id="`library_cqclibrary_table_xd_edit_datadescription_${cqc.$index}_${scope.$index}`" @change="changed(cqc.row)"></el-input>
                                        </el-col >
                                        <el-col  style="width: 230px;padding-left:0px;">
                                            <el-input placeholder="User Comment"  v-model="scope.row.comment2" :id="`library_cqclibrary_table_xd_edit_usercomment_${cqc.$index}_${scope.$index}`" @change="changed(cqc.row)"></el-input>
                                        </el-col>
                                          <el-col  style="width: 50px;padding-left:0px;">
                                            <el-input placeholder="Unit" :readonly="true" :id="`library_cqclibrary_table_xd_edit_unit_${cqc.$index}_${scope.$index}`" :value="getunit(scope.row,'ur2').unit"></el-input>
                                        </el-col>
                                        <el-col  style="width: 245px; padding-left:150px">
                                            <el-input-number class="inputright" :precision="2" :controls="false" placeholder="Qty" v-model="scope.row.adj2" :id="`library_cqclibrary_table_xd_edit_qty_${cqc.$index}_${scope.$index}`" @change="changed(cqc.row)"></el-input-number>
                                        </el-col>
                                    </el-row>
                                    </template>
                                </el-table-column>

                               <!--Note : We have replaced label adj1 and adj2 to xC and xD  respectively as suggested in brief CC.21.1  -->
                                <el-table-column prop="adj1" label="xC" align="center" width="150"  >
                                    <template slot-scope="scope">
                                        <el-row :gutter="2" style="padding-left:0px">
                                            <el-col :span="15">
                                                <el-input-number :precision="2" class="inputright" :id="`library_cqclibrary_table_xc_${cqc.$index}_${scope.$index}`" :disabled="!editpermission" :controls="false" type="text" v-model="scope.row.adj1" @change="changed(cqc.row)">
                                                </el-input-number>
                                            </el-col>
                                            <el-col  :span="9">
                                                <el-button class="icon-btn-width" type="primary" icon="el-icon-edit" @click="EditAdj1(scope.row)" plain> </el-button>
                                            </el-col>
                                        </el-row>
                                    </template>
                                </el-table-column>

                                <el-table-column prop="adj2" label="xD" align="center" width="150">
                                    <template slot-scope="scope">
                                        <el-row :gutter="2">
                                            <el-col :span="15">
                                                <el-input-number :precision="2" class="inputright" :id="`library_cqclibrary_table_xd_${cqc.$index}_${scope.$index}`" :disabled="!editpermission" :controls="false" type="text" v-model="scope.row.adj2" @change="changed(cqc.row)"></el-input-number>
                                            </el-col>
                                            <el-col :span="9">
                                                    <el-button  class="icon-btn-width" type="primary" icon="el-icon-edit" plain @click="EditAdj1(scope.row)">
                                                    </el-button>
                                            </el-col>
                                        </el-row>

                                    </template>
                                </el-table-column>

                                <!-- <el-table-column prop="adj3" label="Adj-3" min-width="60">
                                    <template slot-scope="scope">
                                        <el-input-number :controls="false" type="text" v-model="scope.row.adj3" @change="changed(cqc.row)"></el-input-number>
                                    </template>
                                </el-table-column> -->

                                <el-table-column prop="totala" label="Total A" width="60" align="center">
                                    <template slot-scope="scope">
                                        <el-checkbox :disabled="!editpermission" :id="`library_cqclibrary_table_checkbox_totala_${cqc.$index}_${scope.$index}`" v-model="scope.row.totala" @change="changed(cqc.row)"></el-checkbox>
                                    </template>
                                </el-table-column>

                                <el-table-column prop="totalb" label="Total B" width="60" align="center">
                                    <template slot-scope="scope">
                                        <el-checkbox :disabled="!editpermission" :id="`library_cqclibrary_table_checkbox_totalb_${cqc.$index}_${scope.$index}`" v-model="scope.row.totalb" @change="changed(cqc.row)"></el-checkbox>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <el-row>
                                <div class="spaceBetween" />
                                <el-col class="text-left" style="margin-left: 47px;" label="good">

                                        <el-button class="icon-btn-width" :disabled="!editpermission" :id="'library_cqclibrary_table_add_detailrow'" icon="el-icon-circle-plus" title="Add New" type="primary" @click="addDetailRow(cqc.row, false)"></el-button>

                                        <el-button class="icon-btn-width" :disabled="!editpermission" :id="'library_cqclibrary_table_copy_detailrow'" icon="glyphicon glyphicon-duplicate" title="Add Copy" type="primary" @click="addDetailRow(cqc.row, true)"></el-button>

                                        <el-button class="icon-btn-width" :disabled="!editpermission" :id="'library_cqclibrary_table_delete_detailrow'" icon="el-icon-delete" type="danger" title="Delete" @click="deleteDetailRow(cqc.row)"></el-button>

                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="6" :offset="4">
                                    <table class="fullwidth padding2px">
                                        <tbody>
                                            <tr class="bold" style="text-align:center">
                                                <td class="text-left">A/B ratio statement</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <el-input type="textarea" :rows="3" :disabled="!editpermission" placeholder="Please input" :id="`library_cqclibrary_table_abratio_statement_${cqc.$index}`" v-model="cqc.row.abratiostatement" @change="changed(cqc.row)"></el-input>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </el-col>
                                <el-col :span="7" style="float:left;">
                                    <table class="fullwidth padding2px fixed">
                                        <tbody>
                                            <tr class="bold">
                                                <th width="55"></th>
                                                <th>Min</th>
                                                <th>Max</th>
                                                <th>PSP SD %</th>
                                                <th>SP %</th>
                                            </tr>
                                            <tr class="inputcenter">
                                                <td class="bold"><el-tag type="success" class="tag-in">In</el-tag></td>
                                                <td> <el-input-number :disabled="!editpermission" :controls="false" :id="`library_cqclibrary_table_row_min_in_${cqc.$index}`" v-model="cqc.row.tolerenceinab" @change="changed(cqc.row)" :precision="3"></el-input-number></td>
                                                <td> <el-input-number :disabled="!editpermission" :controls="false" :id="`library_cqclibrary_table_row_max_in_${cqc.$index}`" v-model="cqc.row.tolerencecheckab" @change="changed(cqc.row)" :precision="3"></el-input-number> </td>
                                                <td> <el-input :disabled="!editpermission" v-model="cqc.row.tolerenceoutab" :id="`library_cqclibrary_table_row_sd_in_${cqc.$index}`" :spellcheck="isspellcheck" @blur="focusOut($event,cqc.row,'tolerenceoutab')" @focus="focusIn($event,cqc.row,'tolerenceoutab')" @change="changed(cqc.row)"></el-input> </td>
                                                <td> <el-input :disabled="!editpermission" v-model="cqc.row.inother" :id="`library_cqclibrary_table_row_sp_in_${cqc.$index}`" :spellcheck="isspellcheck" @blur="focusOut($event,cqc.row,'inother')" @focus="focusIn($event,cqc.row,'inother')" @change="changed(cqc.row)"></el-input> </td>
                                            </tr>
                                            <tr class="inputcenter">
                                                <td class="bold"><el-tag type="warning" class="tag-check">Check</el-tag></td>
                                                <td> <el-input-number :disabled="!editpermission" :controls="false" :id="`library_cqclibrary_table_row_min_check_${cqc.$index}`" v-model="cqc.row.tolerenceinba" @change="changed(cqc.row)" :precision="3"></el-input-number> </td>
                                                <td> <el-input-number :disabled="!editpermission" :controls="false" :id="`library_cqclibrary_table_row_max_check_${cqc.$index}`" v-model="cqc.row.tolerencecheckba" @change="changed(cqc.row)" :precision="3"></el-input-number> </td>
                                                <td> <el-input :disabled="!editpermission" v-model="cqc.row.tolerenceoutba" :id="`library_cqclibrary_table_row_sd_check_${cqc.$index}`" :spellcheck="isspellcheck" @blur="focusOut($event,cqc.row,'tolerenceoutba')" @focus="focusIn($event,cqc.row,'tolerenceoutba')" @change="changed(cqc.row)"></el-input> </td>
                                                <td> <el-input :disabled="!editpermission" v-model="cqc.row.checkother" :id="`library_cqclibrary_table_row_sp_check_${cqc.$index}`" :spellcheck="isspellcheck" @blur="focusOut($event,cqc.row,'checkother')" @focus="focusIn($event,cqc.row,'checkother')" @change="changed(cqc.row)"></el-input> </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </el-col>
                            </el-row>
                        </el-col>
                        <!-- <el-col :span="6">
                            <table class="fullwidth padding2px">
                                <thead>
                                    <tr class="bold">
                                        <th>Totals</th>
                                        <th class="center">&lt;</th>
                                        <th class="center">&lt;</th>
                                        <th class="center">&gt;</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="bold" style="text-align:center">
                                        <td></td>
                                        <td class="green">In(%)</td>
                                        <td class="orange">Check(%)</td>
                                        <td class="red">Out(%)</td>
                                    </tr>
                                    <tr>
                                        <td class="bold"> A &gt; B  </td>
                                        <td> <el-input-number :controls="false" v-model="cqc.row.tolerenceinab" @change="changed(cqc.row)"></el-input-number></td>
                                        <td> <el-input-number :controls="false" v-model="cqc.row.tolerencecheckab" @change="changed(cqc.row)"></el-input-number> </td>
                                        <td> <el-input-number :controls="false" v-model="cqc.row.tolerenceoutab" @change="changed(cqc.row)"></el-input-number> </td>
                                    </tr>
                                    <tr>
                                        <td class="bold"> B &gt; A  </td>
                                        <td> <el-input-number :controls="false" v-model="cqc.row.tolerenceinba" @change="changed(cqc.row)"></el-input-number> </td>
                                        <td> <el-input-number :controls="false" v-model="cqc.row.tolerencecheckba" @change="changed(cqc.row)"></el-input-number> </td>
                                        <td> <el-input-number :controls="false" v-model="cqc.row.tolerenceoutba" @change="changed(cqc.row)"></el-input-number> </td>
                                    </tr>
                                </tbody>
                            </table>
                        </el-col> -->
                    </el-row>
                </template>
            </el-table-column>

            <el-table-column prop="cqcname" label="CQC Name" width="310px" >
                <template slot-scope="cqc">
                        <!--<div class="flexlabel">CQC Name</div>-->
                        <div class="">
                            <el-input :disabled="!editpermission" :id="`library_cqclibrary_table_cqcname_${cqc.$index}`" v-model="cqc.row.cqcname" :spellcheck="isspellcheck" @change="changed(cqc.row)"></el-input>
                        </div>
                </template>
            </el-table-column>

            <el-table-column prop="Library"  label="Library"  width="100">
                <template slot-scope="cqc">
                        <!--<div class="flexlabel">CQC Name</div>-->
                        <div class="">
                            <!--<el-input v-model="cqc.row.Library" @change="changed(cqc.row)"></el-input>-->
                            <el-select v-model="cqc.row.Library" :id="`library_cqclibrary_table_library_${cqc.$index}`" class="fullwidth" clearable @change="changed(cqc.row)" @clear="changed(cqc.row)" placeholder="Use in All">
                                <el-option
                                    v-for="item in libraries" :disabled="!editpermission"
                                        :key="item"
                                        :label="item"
                                        :value="item">
                                </el-option>
                            </el-select>
                        </div>
                </template>
            </el-table-column>

            <!--<el-table-column prop="psp" label="PSP" :resizable="true" min-width="200">
                <template slot-scope="cqc">
                    <div class="flex">
                        <div class="flexlabel">PSP</div>
                        <div class="flexcontent">
                            <el-input v-model="cqc.row.psp" @change="changed(cqc.row)"></el-input>
                        </div>
                    </div>
                </template>
            </el-table-column>-->

            <el-table-column prop="termsofuse" label="Terms of Use" width="310">
                <template slot-scope="cqc">
                        <div class="">
                            <el-input :disabled="!editpermission" :id="`library_cqclibrary_table_termsofuse_${cqc.$index}`" v-model="cqc.row.termsofuse" :spellcheck="isspellcheck" @change="changed(cqc.row)"></el-input>
                        </div>
                </template>
            </el-table-column>
               <el-table-column prop="reportin" label="Report In"  width="65">
                <template slot-scope="cqc">
                        <div class="">
                            <!-- <el-input
                                class="inputcenter"
                                :disabled="!editpermission"
                                v-model="cqc.row.reportin"
                                :spellcheck="isspellcheck"
                                @change="changed(cqc.row)"
                                ></el-input> -->
                                 <el-select clearable :id="`library_cqclibrary_table_reportin_${cqc.$index}`" v-model="cqc.row.reportin" remote reserve-keyword filterable :disabled="!editpermission" @change="changed(cqc.row)" placeholder="Report In">
                                  <el-option
                                    v-for="item in sections"
                                    :key="item.id"
                                    :label="item.sectionlabel"
                                    :value="item.sectionvalue">
                                  </el-option>
                                 </el-select>
                        </div>
                </template>
            </el-table-column>

            <el-table-column prop="uniquereference" label="UR(S)"  width="75">
                <template slot-scope="cqc">
                        <div class="">
                          <el-select v-model="cqc.row.uniquereference" :id="`library_cqclibrary_table_ur_${cqc.$index}`" @change="changed(cqc.row)" clearable remote  :remote-method="getURsRemote" :disabled="!editpermission" reserve-keyword filterable  placeholder="UR(S)">
                           <el-option
                             v-for="(item, index) in $store.state.URs"
                             :key="index"
                             :label="item.ur"
                             :value="item.ur">
                             <!-- <div>
                                 <div class="left">
                                 {{item.ur}}
                                 </div>
                                 <div v-if="item.id != 0 " class="right ML13">
                                 <i style="color:#67C23A" class="el-icon-circle-check"></i>
                                 </div>
                             </div> -->
                           </el-option>
                         </el-select>
                         <!-- <el-select v-model="cqc.row.uniquereference"  :remote-method="remoteMethod" @change="changed(cqc.row)" :disabled="!editpermission" clearable remote reserve-keyword filterable  placeholder="UR(S)">
                                 <el-option
                                  v-for="(item, index) in ursoptions"
                                  :key="index"
                                  :label="item.ur"
                                  :value="item.ur">
                                  <div>
                                      <div class="left">
                                      {{item.ur}}
                                      </div>
                                      <div v-if="item.id != 0 " class="right ML13">
                                      <i style="color:#67C23A" class="el-icon-circle-check"></i>
                                      </div>
                                  </div>
                                </el-option>
                              </el-select> -->
                        </div>
                </template>
            </el-table-column>
            <el-table-column prop="unit" label="Unit"  width="70">
                <template slot-scope="cqc">
                        <div class="">
                           <el-select v-model="cqc.row.unit" :id="`library_cqclibrary_table_unit_${cqc.$index}`" @change="changed(cqc.row)"  clearable placeholder="Unit">
                                <el-option v-for="item in units" :disabled="!editpermission"
                                           :key="item"
                                           :label="item"
                                           :value="item">
                                </el-option>
                            </el-select>
                        </div>
                </template>
            </el-table-column>
            <el-table-column prop="searchtype" label="Search Type" width="100">
                <template slot-scope="cqc">
                        <!--<div class="flexlabel">Search Type</div>-->
                        <div class="">
                            <el-select v-model="cqc.row.searchtype" :id="`library_cqclibrary_table_searchtype_${cqc.$index}`" @change="changed(cqc.row)">
                                <el-option
                                    v-for="item in searchtypes" :disabled="!editpermission"
                                        :key="item"
                                        :label="item"
                                        :value="item">
                                </el-option>
                            </el-select>
                        </div>

                </template>
            </el-table-column>

            <el-table-column prop="owner.loginname" label="Owner" width="70px" align="right">
            </el-table-column>
        </el-table>
        </div>
        <el-row>
            <div class="spaceBetween" />
            <el-col class="text-left">

                    <el-button class="icon-btn-width" :disabled="!editpermission" icon="el-icon-circle-plus" :id="'library_cqclibrary_table_add_row'" type="primary" title="Add New" @click="addRow(false)"></el-button>

                    <el-button class="icon-btn-width" :disabled="currentcqc == null || !editpermission" icon="glyphicon glyphicon-duplicate" :id="'library_cqclibrary_table_copy_row'" title="Add Copy" type="primary" @click="addRow(true)"></el-button>

                    <el-button class="icon-btn-width" :disabled="currentcqc == null || !editpermission" icon="el-icon-delete" type="danger" :id="'library_cqclibrary_table_delete_row'" title="Delete" @click="delRow(currentcqc)"></el-button>

            </el-col>

        </el-row>

        <el-dialog v-if="ids != null && errorvisible==false" title="Independent Data Search IDS"
                   :visible.sync="showids"
                   :close-on-press-escape="false"
                   :close-on-click-modal="false"
                   :modalAppendToBody="false"
                   top="10vh" width="510px" class="titlefontchange chngdialgpadding changedialgheader">
            <el-form label-width="100px" class="formitmmarginbtm8px">
                <el-form-item label="IDS Name">
                    <el-input :disabled="!editpermission" :id="'library_cqclibrary_table_edit_ids_filter_idsname'" v-model="ids.idsname" :spellcheck="isspellcheck"></el-input>
                </el-form-item>

                <!-- <el-form-item label-width="100px" class="headerSO">
                    NRM Section
                </el-form-item> -->
                <!-- <el-row>
                    <el-col :span="8">
                    <el-form-item label="Report In">
                            <el-input
                            class="inputcenter"
                             style="width: 90px;"
                             :disabled="!editpermission"
                             v-model="ids.nrmsectionReportIn"
                             :spellcheck="isspellcheck"
                              ></el-input>
                    </el-form-item>
                    </el-col>
                </el-row> -->

                <el-row class="borderbottom marginbottom10px">

                        <el-col class="width320px">
                             <el-form-item label="Search In" >
                                 <span class="bold">NRM Section</span>
                                  <el-select  v-model="ids.nrmsectionSearchIn" :disabled="!editpermission" :id="'library_cqclibrary_table_edit_ids_filter_nrmsection'" placeholder="Select" @input="NRMorURchanged(ids, 'nrmsection')" class="width70px marginleft8px" clearable>
                                  <el-option
                                    v-for="item in sections"
                                    :key="item.id"
                                    :label="item.sectionlabel"
                                    :value="item.sectionvalue">
                                  </el-option>
                                 </el-select>
                                 <span class="bold marginleft8px" style="color: #c53fc7;">~OR~</span>
                            </el-form-item >
                        </el-col>
                        <!-- <el-col :span="8" style="text-align:center">Or

                        </el-col> -->


                        <el-col :span="5" >
                            <div class="left marginleft8px">
                            <el-form-item label="UR(D)" label-width="55px" >
                                 <!-- <el-autocomplete
                                    class="inline-input inputcenter width80px" popper-class="morewidth-autocomplete-idsur"
                                    v-model="ids.nrmsectionUR"
                                    :fetch-suggestions="searchur"
                                    placeholder="Number"
                                    :maxlength="5"
                                    :spellcheck="isspellcheck" @input="NRMorURchanged(ids, 'UR')" clearable>
                                    <i
                                        v-if="ids.nrmsectionUR"
                                        slot="prefix"
                                        @click="$router.push('/independentmeasuretemplate')"
                                        class="el-icon-link" style="color: #409eff; font-weight: 600; padding-top: 7px; font-size: 13px;">
                                    </i>
                                </el-autocomplete> -->

                                   <el-select class="inline-input inputcenter width80px"
                                     popper-class="morewidth-autocomplete-idsur"
                                          v-model="ids.nrmsectionUR"
                                         clearable remote
                                         @change="NRMorURchanged(ids, 'UR')"
                                         :remote-method="searchur"
                                         :disabled="!editpermission"
                                         reserve-keyword filterable
                                         placeholder="UR(D)"
                                         :id="'library_cqclibrary_table_edit_ids_filter_ur'">
                                          <i
                                        v-if="ids.nrmsectionUR"
                                        slot="prefix"
                                        @click="$router.push('/independentmeasuretemplate')"
                                        class="el-icon-link" style="color: #409eff; font-weight: 600; padding-top: 7px; font-size: 13px;">
                                    </i>
                                       <el-option
                                         v-for="(item, index) in $store.state.URd"
                                         :key="index"
                                         :label="item.ur"
                                         :value="item.ur">
                                         <!-- <div>
                                             <div class="left">
                                             {{item.ur}}
                                             </div>
                                             <div v-if="item.id != 0 " class="right ML13">
                                             <i style="color:#67C23A" class="el-icon-circle-check"></i>
                                             </div>
                                         </div> -->
                                       </el-option>
                                     </el-select>
                                <!-- <el-input
                                    class="width70px"
                                    placeholder="Number"
                                    v-model="ids.nrmsectionUR"
                                     maxlength="5"
                                    :disabled="!editpermission"
                                    :spellcheck="isspellcheck"
                                     @input.native="checkurinput"
                                     @change="urchanged2"

                                >
                                </el-input> -->
                            </el-form-item>
                            </div>
                        </el-col>


                </el-row>




                <span class="bold marginleft102px">NRM Section</span>
                <el-form-item label="NRM1 Code" >
                      <el-input :disabled="!editpermission" v-model="ids.nrmsection" :id="'library_cqclibrary_table_edit_ids_nrmsection_nrm1code'" :spellcheck="isspellcheck"></el-input>
                </el-form-item>
                <el-form-item label="MOM Section" label-width="101px" >
                    <el-input :disabled="!editpermission" v-model="ids.section" :id="'library_cqclibrary_table_edit_ids_nrmsection_monsection'" @change="isRE(ids,'section','sectionshow')" :spellcheck="isspellcheck"></el-input>
                    <div class="alert-danger" v-if="ids.sectionshow" >
                        <strong>Invalid Regular Expression.</strong>
                    </div>
                </el-form-item>

                <el-form-item label="Group">
                    <el-input :disabled="!editpermission" v-model="ids.group" :id="'library_cqclibrary_table_edit_ids_nrmsection_group'" @change="isRE(ids,'group','groupshow')" :spellcheck="isspellcheck"></el-input>
                     <div class="alert-danger" v-if="ids.groupshow">
                        <strong>Invalid Regular Expression.</strong>
                    </div>
                </el-form-item>

                <el-form-item label="Work">
                    <el-input :disabled="!editpermission" @change="isRE(ids,'work','workshow')" :id="'library_cqclibrary_table_edit_ids_nrmsection_work'" v-model="ids.work" :spellcheck="isspellcheck"></el-input>
                    <div class="alert-danger" v-if="ids.workshow">
                        <strong>Invalid Regular Expression.</strong>
                    </div>
                </el-form-item>

                <el-form-item label="Level 1">
                    <el-input :disabled="!editpermission" @change="isRE(ids,'level1','level1show')" v-model="ids.level1" :id="'library_cqclibrary_table_edit_ids_nrmsection_level1'" :spellcheck="isspellcheck"></el-input>
                    <div class="alert-danger" v-if="ids.level1show">
                        <strong>Invalid Regular Expression.</strong>
                    </div>
                </el-form-item>

                <el-form-item label="Level 2">
                    <el-input :disabled="!editpermission" v-model="ids.level2" @change="isRE(ids,'level1','level2show')" :id="'library_cqclibrary_table_edit_ids_nrmsection_level2'" :spellcheck="isspellcheck"></el-input>
                    <div class="alert-danger" v-if="ids.level2show">
                        <strong>Invalid Regular Expression.</strong>
                    </div>
                </el-form-item>

                <el-form-item label="Level 3">
                    <el-input :disabled="!editpermission" v-model="ids.level3" @change="isRE(ids,'level1','level2show')" :id="'library_cqclibrary_table_edit_ids_nrmsection_level3'" :spellcheck="isspellcheck"></el-input>
                    <div class="alert-danger" v-if="ids.level3show">
                        <strong>Invalid Regular Expression.</strong>
                    </div>
                </el-form-item>

                <el-form-item label="Unit">
                    <el-input :disabled="!editpermission" v-model="ids.unit" :id="'library_cqclibrary_table_edit_ids_nrmsection_unit'" :spellcheck="isspellcheck"></el-input>
                </el-form-item>
                <div class="borderbottom marginbottom10px"></div>

                <span class="bold marginleft102px">Unique Reference UR</span>
                <el-form-item label="Description">
                    <el-input :value="getunit(ids, 'nrmsectionUR').datadescription" :id="'library_cqclibrary_table_edit_ids_ur_desciption'"></el-input>
                </el-form-item>

                <el-form-item label="Unit">
                    <el-input :value="getunit(ids, 'nrmsectionUR').unit" :id="'library_cqclibrary_table_edit_ids_ur_unit'"></el-input>
                </el-form-item>

               <el-form-item>
                        <el-button v-if="ids.idsname" title="Bill Match" type="primary" class="padding7px14px" :id="'library_cqclibrary_ids_billmatch'" @click="getbillmatchreport(ids)">Bill Match</el-button>
                   
                        <el-button :disabled="!editpermission" icon="el-icon-circle-plus" title="Add New" type="primary" @click="addids(false)" :id="'library_cqclibrary_ids_addids'" class="padding7px14px">Add New</el-button>
                   
                        <el-button :disabled="!(ids.id > 0) || !editpermission" icon="glyphicon glyphicon-duplicate" title="Add Copy" type="primary" :id="'library_cqclibrary_ids_copyids'" @click="addids(true)" class="padding7px14px">Add Copy</el-button>
                    
                        <el-button :disabled="!editpermission" icon="el-icon-circle-check" title="Save" type="success" @click="cellchanged('library_cqclibrary_ids_save',()=>{saveids()},false)" class="padding7px14px">Save</el-button>
                    
                </el-form-item>
            </el-form>
        </el-dialog>

        <el-dialog
            title="Bill (Sections) Match"
            :visible.sync="billmatchprojectshow"
            :close-on-press-escape="false"
            :close-on-click-modal="false"
            :modalAppendToBody="false"
            top="10vh" width="850px" class="titlefontchange chngdialgpadding-billmatch changedialgheader-billmatch">
            <el-pagination
                class="center" layout="prev, pager, next"
                @current-change="pageMPCurrentChange"
                :page-size="pagesizeMP"
                :total="totalMP">
            </el-pagination>
            <el-table :data="getbillmatchproject" class="tableBox tableBox-cell" :border="true" :header-cell-style="headerrowstyleborder" style="width: 100%">
                    <el-table-column prop="projectname" label="Project Name"></el-table-column>
                    <el-table-column prop="projectpart.name" label="Project Part" width="150"></el-table-column>
                    <el-table-column prop="section.sectiondisplay" label="Section Display">
                        <template slot-scope="scope">
                            <div style="color:#409EFF;text-decoration: underline;" @click="getbilpage(scope.row)">{{ scope.row.section.sectiondisplay }}</div>
                        </template>
                    </el-table-column>
                </el-table>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                sections:[],
                isexpand:false,
                isexpandid:null,
                expandKeys:[],
                IMTarray:[],
                search: '',
                expandcollapsetext: "+",
                expandedrows: [],
                detailform: [{}],
                loading: false,
                currentcqc: null,
                showids: false,
                ids: null,
                user: this.$store.state.user,
                units: this.$store.state.units,
                searchtypes: ['Required', 'Expected', 'Testing','Export Only'],
                errorvisible: false,
                searchtypefilter: '',
                cqcfilterdata: '',
                URfiltersearch: '',
                libraries: this.$store.state.libraries,
                URfilter: [
                    { 'label': 'All', 'value': 'All' },
                    { 'label': 'URonly', 'value': 'URonly' },
                ],
                page: 1,
                pagesize: 15,
                total: 0,
                billmatchproject: [],
                billmatchprojectshow: false,
                totalMP:0,
                pageMP:1,
                pagesizeMP: 20,
                height: (document.documentElement.clientHeight -165),
                pagesizes: this.$store.state.pagesizes,
            }
        },
        destroyed() {
            window.removeEventListener('resize', this.getheight);
        },
         mounted() {
            window.addEventListener('resize', this.getheight);
        },
        methods: {
            getdirectionidsavecqcids() {
                return [
                {
                        "column": "idsname1",
                        "id": "library_cqclibrary_table_edit_ids_filter_idsname"
                    },
                    {
                        "column": "nrmsectionSearchIn",
                        "id": "library_cqclibrary_table_edit_ids_filter_nrmsection"
                    },
                    {
                        "column": "nrmsectionUR",
                        "id": "library_cqclibrary_table_edit_ids_filter_ur"
                    },
                    {
                        "column": "nrmsection",
                        "id": "library_cqclibrary_table_edit_ids_nrmsection_nrm1code"
                    },
                    {
                        "column": "section",
                        "id": "library_cqclibrary_table_edit_ids_nrmsection_monsection"
                    },
                    {
                        "column": "group",
                        "id": "library_cqclibrary_table_edit_ids_nrmsection_group"
                    },
                    {
                        "column": "work",
                        "id": "library_cqclibrary_table_edit_ids_nrmsection_work"
                    },
                    {
                        "column": "level1",
                        "id": "library_cqclibrary_table_edit_ids_nrmsection_level1"
                    },
                    {
                        "column": "level2",
                        "id": "library_cqclibrary_table_edit_ids_nrmsection_level2"
                    },
                    {
                        "column": "level3",
                        "id": "library_cqclibrary_table_edit_ids_nrmsection_level3"
                    },
                    {
                        "column": "idsunit",
                        "id": "library_cqclibrary_table_edit_ids_nrmsection_unit"
                    }
                ]
            },
            getdirectionids() {
                return [
                    {
                        "column": "filter_cqcname",
                        "id": "library_cqclibrary_filter_cqcname"
                    },
                    {
                        "column": "filter_library",
                        "id": "library_cqclibrary_filter_library"
                    },
                    {
                        "column": "filter_ur",
                        "id": "library_cqclibrary_filter_ur"
                    },
                    {
                        "column": "filter_type",
                        "id": "library_cqclibrary_filter_type"
                    },
                    {
                        "column": "cqcname",
                        "id": "library_cqclibrary_table_cqcname"
                    },
                    {
                        "column": "Library",
                        "id": "library_cqclibrary_table_library"
                    },
                    {
                        "column": "termsofuse",
                        "id": "library_cqclibrary_table_termsofuse"
                    },
                    {
                        "column": "reportin",
                        "id": "library_cqclibrary_table_reportin"
                    },
                    {
                        "column": "uniquereference",
                        "id": "library_cqclibrary_table_ur"
                    },
                    {
                        "column": "unit",
                        "id": "library_cqclibrary_table_unit"
                    },
                    {
                        "column": "searchtype",
                        "id": "library_cqclibrary_table_searchtype"
                    },
                    {
                        "column": "srno",
                        "id": "library_cqclibrary_table_sr_no"
                    },
                    {
                        "column": "idsname",
                        "id": "library_cqclibrary_table_idsname"
                    },
                    {
                        "column": "notes",
                        "id": "library_cqclibrary_table_notes"
                    },
                    {
                        "column": "adj1",
                        "id": "library_cqclibrary_table_xc"
                    },
                    {
                        "column": "adj2",
                        "id": "library_cqclibrary_table_xd"
                    },
                    {
                        "column": "totala",
                        "id": "library_cqclibrary_table_checkbox_totala"
                    },
                    {
                        "column": "totalb",
                        "id": "library_cqclibrary_table_checkbox_totalb"
                    },
                    {
                        "column": "abratiostatement",
                        "id": "library_cqclibrary_table_abratio_statement"
                    },
                    {
                        "column": "tolerenceinab",
                        "id": "library_cqclibrary_table_row_min_in"
                    },
                    {
                        "column": "tolerencecheckab",
                        "id": "library_cqclibrary_table_row_max_in"
                    },
                    {
                        "column": "tolerenceoutab",
                        "id": "library_cqclibrary_table_row_sd_in"
                    },
                    {
                        "column": "inother",
                        "id": "library_cqclibrary_table_row_sp_in"
                    },
                    {
                        "column": "tolerenceinba",
                        "id": "library_cqclibrary_table_row_min_check"
                    },
                    {
                        "column": "tolerencecheckba",
                        "id": "library_cqclibrary_table_row_max_check"
                    },
                    {
                        "column": "tolerenceoutba",
                        "id": "library_cqclibrary_table_row_sd_check"
                    },
                    {
                        "column": "checkother",
                        "id": "library_cqclibrary_table_row_sp_check"
                    },
                    {
                        "column": "ur1",
                        "id": "library_cqclibrary_table_xc_edit_insertur"
                    },
                    {
                        "column": "description1",
                        "id": "library_cqclibrary_table_xc_edit_datadescription"
                    },
                    {
                        "column": "comment1",
                        "id": "library_cqclibrary_table_xc_edit_usercomment"
                    },
                    {
                        "column": "qtyedit1",
                        "id": "library_cqclibrary_table_xc_edit_qty"
                    },
                    {
                        "column": "ur2",
                        "id": "library_cqclibrary_table_xd_edit_insertur"
                    },
                    {
                        "column": "description2",
                        "id": "library_cqclibrary_table_xd_edit_datadescription"
                    },
                    {
                        "column": "comment2",
                        "id": "library_cqclibrary_table_xd_edit_usercomment"
                    },
                    {
                        "column": "qtyedit2",
                        "id": "library_cqclibrary_table_xd_edit_qty"
                    }                    
                ]
            },

            cellchanged(cellaction, callback, cancall) {
                if (cancall) {
                    callback();
                    this.canmatchcellid(() => { }, cellaction, '', true, (array1) => { this.validatedirection(array1, cellaction, callback) });
                } else {
                    this.canmatchcellid(callback, cellaction, '', true, (array1) => { this.validatedirection(array1, cellaction, callback) });
                }
            },

            getcellmatcheddirection(direction, directionidvalue) {
                let matcheddirection = direction.directioncelldetails.find(x => x.pagecell.cellname == directionidvalue);
                let dir = JSON.parse(JSON.stringify(direction));
                if (matcheddirection) {
                    dir.includerule = matcheddirection.includerule;
                    dir.excluderule = matcheddirection.excluderule;
                    dir.formatrule = matcheddirection.formatrule;
                    dir.pagecell = matcheddirection.pagecell;
                    dir.pagecellid = matcheddirection.pagecellid;

                    dir.checkvalue = matcheddirection.checkvalue;
                    dir.operation = matcheddirection.operation;
                    dir.celltype = matcheddirection.pagecell.celltype;
                    dir.objectpath = matcheddirection.pagecell.objectpath;

                    return dir;
                }
            },

            getCellValueAndRegexRules(data, cellvalue) {
                if (data.includerule != null && data.includerule != "") {
                    data.inc_pattern = new RegExp(data.includerule, "i");
                }

                if (data.excluderule != null && data.excluderule != "") {
                    data.exc_pattern = new RegExp(data.excluderule, "i");
                }

                if (data.formatrule != null && data.formatrule != "") {
                    data.format_pattern = new RegExp(data.formatrule, "i");
                }

                data.enteredvalue = cellvalue;

                return data;
            },

            getformat(dr) {
                const data = {};
                var inc_pattern = null;
                var exc_pattern = null;
                var format_pattern = null;
                if (dr.includerule != null && dr.includerule != "") {
                    inc_pattern = new RegExp(dr.includerule, "i");
                }
                if (dr.excluderule != null && dr.excluderule != "") {
                    exc_pattern = new RegExp(dr.excluderule, "i");
                }
                if (dr.formatrule != null && dr.formatrule != "") {
                    format_pattern = new RegExp(dr.formatrule, "i");
                }
                data.format_pattern = format_pattern;
                data.inc_pattern = inc_pattern;
                data.exc_pattern = exc_pattern;
                return data;
            },

            getdirectionoption(FormData) {
                const option = JSON.parse(JSON.stringify({
                    visible: true,
                    sectionname: "",
                    isedit: false,
                    id: FormData.id,
                    boxtype: "Small 2 Part Pop up",
                    content: FormData.message,
                    mediacontent: FormData.mediacontent,
                    link: FormData.link,
                    offercloseoption: FormData.offercloseoption,
                    heading: FormData.directionname,
                    directiondegree: FormData.directiondegree,
                    direction: FormData,
                    usedata: true,

                    enteredvalue: FormData.enteredvalue,
                    celltype: FormData.celltype,
                    objectpath: FormData.objectpath,
                    checkvalue: FormData.checkvalue,
                    operation: FormData.operation,

                }));
                return option;
            },

            multicelllocationrulesmatch(celldirections, direction, errors) {
                let count = 0;
                let index1 = null;
                let index2 = null;
                let datacolumntype = null;
                for (let i = 0; i < celldirections.length; i++) {
                    const { columntype, firstindex, secondindex, value, celldirection } = celldirections[i];
                    if (celldirection != undefined) {
                        index1 = firstindex;
                        index2 = secondindex;
                        datacolumntype = columntype;
                        const ddata = this.getCellValueAndRegexRules(celldirection, value);
                        if (!this.$directionutility.directionvaluematch(ddata)) {
                            count++;
                        }
                    }
                }

                if (direction.directioncelldetails.length == count) {
                    errors.forEach(x => {
                        if (x.direction.columntype == 'form') {
                            x.ismatchedrulecell = true;
                        }
                        if (x.direction.columntype == 'main') {
                            x.ismatchedrulecell = true;
                        }
                        if (x.direction.columntype == datacolumntype && (x.direction.firstindex && x.direction.firstindex == index1)) {
                            x.ismatchedrulecell = true;
                        }
                        if ((x.direction.firstindex && x.direction.firstindex == index1) && (x.direction.secondindex && x.direction.secondindex == index2)) {
                            x.ismatchedrulecell = true;
                        }
                    });
                    return true;
                }
                else {
                    return false;
                }
            },

            validatedirectionsingle(data) {
                data.value = data.enteredvalue;
            },

            validatedirection(directions, cellaction, callback) {
                var errors = [];
                let filtercol = ['filter_cqcname', 'filter_library', 'filter_ur', 'filter_type'];
                let oneindexcol = ['cqcname', 'Library', 'termsofuse', 'reportin', 'uniquereference', 'unit', 'searchtype', 'abratiostatement', 'tolerenceinab', 'tolerencecheckab', 'tolerenceoutab', 'inother', 'tolerenceinba', 'tolerencecheckba', 'tolerenceoutba', 'checkother'];
                let twoindexcol = ['srno', 'idsname', 'notes', 'adj1', 'adj2', 'totala', 'totalb', 'ur1', 'description1', 'comment1', 'qtyedit1', 'ur2', 'description2', 'comment2', 'qtyedit2'];
                if (cellaction == 'library_cqclibrary_ids_save') {
                    const directionids = this.getdirectionidsavecqcids();
                    // let formindexcol = ['idsname1', 'nrmsectionSearchIn', 'nrmsectionUR', 'nrmsection', 'section', 'group', 'work', 'level1', 'level2', 'level3', 'idsunit'];
                    for (let i = 0; i < directions.length; i++) {
                        const direction = directions[i];
                        let dbdirection = {};
                        for (let j = 0; j < directionids.length; j++) {                            
                            const row = directionids[j];
                            const columnValue = this.getcellmatcheddirection(direction, row.id);
                            dbdirection[row.column] = columnValue; // Set available Direction for checking..
                        }

                        var currentval = {}; // Set Current value for checking direction validation..!
                        currentval.idsname1 = this.ids?.idsname;
                        currentval.nrmsectionSearchIn = this.ids?.nrmsectionSearchIn;
                        currentval.nrmsectionUR = this.ids?.nrmsectionUR;
                        currentval.nrmsection = this.ids?.nrmsection;
                        currentval.section = this.ids?.section;
                        currentval.group = this.ids?.group;
                        currentval.work = this.ids?.work;
                        currentval.level1 = this.ids?.level1;
                        currentval.level2 = this.ids?.level2;
                        currentval.level3 = this.ids?.level3;
                        currentval.idsunit = this.ids?.unit;

                        let objdirections = [];
                        let maindirections = [];
                        for (let a = 0; a < directionids.length; a++) {
                            const obj = directionids[a];
                            if (dbdirection[obj.column] != undefined) {
                                let columntype = 'form';
                                maindirections.push(obj);
                                objdirections.push({ columntype: columntype, celldirection: dbdirection[obj.column], value: currentval[obj.column] });
                            }
                        }

                        for (let ids = 0; ids < maindirections.length; ids++) {
                            const idsrow = maindirections[ids];
                            let mydirection = dbdirection[idsrow.column];
                            if (mydirection != undefined) {
                                let iscellrulesmatched = this.multicelllocationrulesmatch(objdirections, direction, errors);
                                const dr = this.getformat(mydirection);
                                let ddata = this.getCellValueAndRegexRules(mydirection, currentval[idsrow.column]);

                                if (this.$directionutility.directionvaluematch(ddata) == false) {
                                    const option = this.getdirectionoption(mydirection);
                                    Object.assign(option, dr);
                                    option.validatedirectionsingle = (data) => { this.validatedirectionsingle(data) }
                                    this.$set(option, "elementid", idsrow.id);
                                    if (iscellrulesmatched == false) {
                                        option.ismatchedrulecell = false;
                                    } else {
                                        option.ismatchedrulecell = true;
                                    }
                                    errors.push(option);
                                }
                            }
                        }
                    }
                }
                else {
                    const directionids = this.getdirectionids();                    
                    for (let i = 0; i < directions.length; i++) {
                        const direction = directions[i];
                        let dbdirection = {};
                        for (let j = 0; j < directionids.length; j++) {
                            const row = directionids[j];
                            if (row.column == 'filter_library') {
                                
                            }
                            const columnValue = this.getcellmatcheddirection(direction, row.id);
                            dbdirection[row.column] = columnValue; // Set available Direction for checking..
                        }

                        for (let firstindex = 0; firstindex < this.detailform.length; firstindex++) {
                            const currentcqc = this.detailform[firstindex];
                            for (let secondindex = 0; secondindex < currentcqc.details.length; secondindex++) {
                                const currentdetail = currentcqc.details[secondindex];

                                var currentval = {}; // Set Current value for checking direction validation..!
                                currentval.filter_cqcname = this.search;
                                currentval.filter_library = this.cqcfilterdata;
                                currentval.filter_ur = this.URfiltersearch;
                                currentval.filter_type = this.searchtypefilter;

                                currentval.cqcname = currentcqc.cqcname;
                                currentval.Library = currentcqc.Library;
                                currentval.termsofuse = currentcqc.termsofuse;
                                currentval.reportin = currentcqc.reportin;
                                currentval.uniquereference = currentcqc.uniquereference;
                                currentval.unit = currentcqc.unit;
                                currentval.searchtype = currentcqc.searchtype;
                                currentval.abratiostatement = currentcqc.abratiostatement;
                                currentval.tolerenceinab = currentcqc.tolerenceinab;
                                currentval.tolerencecheckab = currentcqc.tolerencecheckab;
                                currentval.tolerenceoutab = currentcqc.tolerenceoutab;
                                currentval.inother = currentcqc.inother;
                                currentval.tolerenceinba = currentcqc.tolerenceinba;
                                currentval.tolerencecheckba = currentcqc.tolerencecheckba;
                                currentval.tolerenceoutba = currentcqc.tolerenceoutba;
                                currentval.checkother = currentcqc.checkother;

                                currentval.srno = currentdetail.srno;
                                currentval.idsname = currentdetail.ids?.idsname;
                                currentval.notes = currentdetail.notes;
                                currentval.adj1 = currentdetail.adj1;
                                currentval.adj2 = currentdetail.adj2;
                                currentval.totala = currentdetail.totala == true ? true : false;
                                currentval.totalb = currentdetail.totalb == true ? true : false;
                                currentval.ur1 = currentdetail.ur1;
                                currentval.description1 = currentdetail.description1;
                                currentval.comment1 = currentdetail.comment1;
                                currentval.qtyedit1 = currentdetail.adj1;
                                currentval.ur2 = currentdetail.ur2;
                                currentval.description2 = currentdetail.description2;
                                currentval.comment2 = currentdetail.comment2;
                                currentval.qtyedit2 = currentdetail.adj2;

                                let objdirections = [];
                                let maindirections = [];
                                for (let a = 0; a < directionids.length; a++) {
                                    const obj = directionids[a];
                                    if (dbdirection[obj.column] != undefined) {
                                        let columntype = '';
                                        if (filtercol.includes(obj.column)) {
                                            columntype = 'filter';
                                        }
                                        else if (oneindexcol.includes(obj.column)) {
                                            columntype = 'main';
                                        } else {
                                            columntype = 'detail';
                                        }
                                        maindirections.push(obj);
                                        objdirections.push({ columntype: columntype, firstindex, secondindex, celldirection: dbdirection[obj.column], value: currentval[obj.column] });
                                    }
                                }

                                for (let ids = 0; ids < maindirections.length; ids++) {
                                    const idsrow = maindirections[ids];
                                    let mydirection = dbdirection[idsrow.column];
                                    let checkedcell = false;

                                    if (filtercol.includes(idsrow.column)) {
                                        checkedcell = this[`${idsrow.column}direction`];
                                    }
                                    else if (oneindexcol.includes(idsrow.column)) {
                                        checkedcell = currentcqc[`${idsrow.column}direction`];
                                    }

                                    if (mydirection != undefined && !checkedcell) {
                                        let iscellrulesmatched = this.multicelllocationrulesmatch(objdirections, direction, errors);
                                        const dr = this.getformat(mydirection);
                                        let ddata = this.getCellValueAndRegexRules(mydirection, currentval[idsrow.column]);

                                        if (this.$directionutility.directionvaluematch(ddata) == false) {
                                            const option = this.getdirectionoption(mydirection);
                                            Object.assign(option, dr);
                                            option.validatedirectionsingle = (data) => { this.validatedirectionsingle(data) }

                                            if (filtercol.includes(idsrow.column)) {
                                                this.$set(option, "elementid", idsrow.id);
                                            } else if (oneindexcol.includes(idsrow.column)) {
                                                this.$set(option, "elementid", `${idsrow.id}_${firstindex}`);
                                            } else if (twoindexcol.includes(idsrow.column)) {
                                                this.$set(option, "elementid", `${idsrow.id}_${firstindex}_${secondindex}`);
                                            }

                                            if (iscellrulesmatched == false) {
                                                option.ismatchedrulecell = false;
                                            } else {
                                                option.ismatchedrulecell = true;
                                                if (filtercol.includes(idsrow.column)) {
                                                    this[`${idsrow.column}direction`] = true;
                                                } else {
                                                    if (oneindexcol.includes(idsrow.column)) {
                                                        currentcqc[`${idsrow.column}direction`] = true;
                                                    }
                                                }
                                            }
                                            errors.push(option);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                errors = errors.filter(x => x.ismatchedrulecell == true);
                // Removed some extra variables created for direction checking.
                filtercol.forEach(col => {
                    if (this[`${col}direction`] != undefined) {
                        this[`${col}direction`] = false;
                    }
                });
                for (let vi = 0; vi < this.detailform.length; vi++) {
                    const element = this.detailform[vi];
                    oneindexcol.forEach(col => {
                        if (element[`${col}direction`] != undefined) {
                            delete element[`${col}direction`];
                        }
                    });
                }

                if (errors.length > 0) {
                    this.$store.state.directionsdialogue = errors;
                    this.$store.state.currentdirectionindex = 0;
                    this.$store.state.currentdirection = errors[0];
                } else {
                    callback();
                }
            },

             getheight() {
               this.height = (document.documentElement.clientHeight - 165);
             },
            NRMorURchanged: function(ids, searchincolumn) {
                if (ids.nrmsectionUR && ids.nrmsectionSearchIn && searchincolumn == "nrmsection") {
                    ids.nrmsectionUR = "";
                    this.$message({
                        dangerouslyUseHTMLString: true,
                        message: 'Please, select either <strong>NRM Section</strong> <i>OR</i> <strong>UR(D)</strong> in the <strong>Search In</strong> row.',
                        type: 'warning',
                        duration: 8000
                    });
                    return;
                } else if (ids.nrmsectionSearchIn && ids.nrmsectionUR && searchincolumn == "UR") {
                    ids.nrmsectionSearchIn = "";
                    this.$message({
                        dangerouslyUseHTMLString: true,
                        message: 'Please, select either <strong>NRM Section</strong> <i>OR</i> <strong>UR(D)</strong> in the <strong>Search In</strong> row.',
                        type: 'warning',
                        duration: 8000
                    });
                    return;
                }
            },
        //      remoteMethod(data) {
        //     if (data !== '') {
        //           if(data != "" ){
        //               var arr = [];
        //               var arr2 = [];
        //               data = parseInt(data);
        //               data = data.toString();
        //             if(data.length <= 4 && data !== "NaN"){
        //                    this.ursoptions = [];
        //                   var tt = this.urs.filter((x=>{
        //                       if(x.ur.includes(data)){
        //                           return x;
        //                       }
        //                   }))
        //                   arr = tt.slice(0, 5);
        //                   this.ursoptions.push(...arr);
        //                      while(arr2.length < 5){
        //                          var r = Math.floor(Math.random()*90000) + 10000;
        //                           var t = arr2.indexOf(r);
        //                          if(t === -1 && r.toString().includes(data)){
        //                              arr2.push({id:0,ur:r});
        //                           }
        //                        }
        //                        this.ursoptions.push(...arr2);
        //                 }else{
        //                  this.ursoptions = [];
        //                   var tt = this.urs.filter((x=>{
        //                       if(x.ur.includes(data)){
        //                           return x;
        //                       }
        //                   }))
        //                   this.ursoptions.push(...tt);
        //                 }

        //         }
        //      } else {
        //        this.ursoptions = this.urs.slice(0, 10);
        //      }
        //   },
             checkvalidpattern(row){

                 var isvalid = false;
                    try {
                       new RegExp(row);
                    } catch(e) {
                        isvalid = true;
                    }
                   return isvalid;
             },
            isRE(row,check,show){
                    this.$set(this.ids,show,this.checkvalidpattern(row[check]));
                    this.$nextTick();
            },
            editids(row,cqc){
                 this.ids=JSON.parse(JSON.stringify(row.ids));

                 this.currentCQCChange(cqc);
                 this.currentCQCRowChange(cqc,row);
                 this.showids=true;
            },
            xCexpandchange(data,expandedRows){
                 this.isexpandid = data.id;

                if(expandedRows != undefined){
                  var expanded = expandedRows.map((v) => { return v.id });
                  if(this.isexpand == false){
                      this.isexpand = true;
                   this.expandKeys = expanded;
                  }else{
                      this.isexpand = false;
                      this.expandKeys = [];
                  }
                }
                // var ur = data.ids.nrmsectionUR;
                // var d1 = this.IMTarray.find((x)=>{ if(x.ur == ur){return x}});
                // if(d1 != undefined && d1.ur != ""){
                //     data.ur1 = d1.ur;
                //     data.description1 = d1.datadescription;
                //     }else{
                //         data.ur1 = "";
                //         data.description1 = "";
                //         data.comment1 ="";
                //         }


                //   var ur = data.ids.nrmsectionUR;
                // var d1 = this.IMTarray.find((x)=>{ if(x.ur == ur){return x}});
                // if(d1 != undefined && d1.ur != ""){
                //   data.ur1 = d1.ur;
                // //   data.ur2 = d1.ur;  // not is should not be displayed on xD row.
                //   data.description1 = d1.datadescription;
                // //   data.description2 = d1.datadescription; //now it should not be displayed on xD row.
                // }else{
                //     data.ur1 = "";
                //     data.description1 = "";
                //     // data.description1 = data.ids.idsname; // it chaged as suggested changed in
                //     data.comment1 ="";

                //     // data.ur2 = "";
                //     // data.description2 = "";
                //     // data.description2 = data.ids.idsname;
                //     // data.comment2="";
                // }

            },
            EditAdj1(row){
                let $table = this.$refs.xcxdtable;
                if($table  == undefined){
                  this.expandcollapse();
                }else{
                   $table.toggleRowExpansion(row)
                }
            },
             searchur(query, done,item,name){
            if (query == null)
                    query = '';
                this.$http.post('project/SearchURD?q=' + query, {})
                    .then(response => {
                       this.$store.state.URd = response.data;
                    })
                    .catch(err => {
                        this.$bus.$emit('error', err);
                    })
            },
            queryURlistfromIM(query,done){
                 query = query ? query : ""; // if null set to empty string
                var result = [];
                this.IMTarray.forEach(x => {
                    if (x.ur != null && x.ur.toLowerCase().indexOf(query.toLowerCase()) >= 0)
                        result.push({ value: x.ur });
                });
                //  this.sections.forEach(x => {
                //     if (x.section.toLowerCase().indexOf(query.toLowerCase()) >= 0)
                //         result.push({ value: x.section });
                // });
                done(result);

            },
            onSelect1(cqcrow,ur,scoperow){
                this.changed(cqcrow);
                // alert(scoperow.description2);
                var d1 = this.IMTarray.find((x)=>{ if(x.ur == ur){return x}});
                if(d1){
                   // alert(row.adj1 + "   " + ur);
                   scoperow.description1 = d1.datadescription;
                   scoperow.unit1 = d1.unit;
                }else{
                    scoperow.description1 = "";
                    scoperow.comment1="";
                   scoperow.unit1 = "";
                }
            },
                onSelect2(cqcrow,ur,scoperow){
                this.changed(cqcrow);
                // alert(scoperow.description2);
                var d1 = this.IMTarray.find((x)=>{ if(x.ur == ur){return x}});
                if(d1){
                // alert(row.adj1 + "   " + ur);
                scoperow.description2 = d1.datadescription;
                scoperow.unit2 = d1.unit;
                }else{
                    scoperow.description2 = "";
                    scoperow.comment2 = "";
                    scoperow.unit2 = "";
                }
            },
            //  onClearIMUR1(cqcrow,scoperow,ur){
            //      scoperow.description1="";
            //      scoperow.comment1="";
            //      scoperow.unit1="";
            //      this.changed(cqcrow);
            //     // alert(cqcrow.description1);
            // },
            // onClearIMUR2(cqcrow,scoperow,ur){
            //     scoperow.description2="";
            //     scoperow.comment2="";
            //     scoperow.unit2="";
            //     this.changed(cqcrow);

            //     // alert(cqcrow.description2);

            // },

            focusOut: function(event,row,rowkey) {
                row[rowkey] = parseInt(event.target.value);
                if (isNaN(row[rowkey])) {
                    row[rowkey] = 0
                }
                if (row[rowkey] >= 100) {
                    row[rowkey] = 100;
                }
                if (row[rowkey] <= 0) {
                    row[rowkey] = 0;
                }
                row[rowkey] = row[rowkey] + "%";
            },

            //  focusOutba: function(event,row) {
            //     row.tolerenceoutba = parseInt(event.target.value);
            //     if (isNaN(row.tolerenceoutba)) {
            //         row.tolerenceoutba = 0
            //     }
            //     if (row.tolerenceoutba >= 100) {
            //         row.tolerenceoutba = 100;
            //     }
            //     if (row.tolerenceoutba <= 0) {
            //         row.tolerenceoutba = 0;
            //     }
            //     row.tolerenceoutba =   row.tolerenceoutba + "%";
            // },
            focusIn: function(event,row,rowkey) {
                row[rowkey] = parseInt(event.target.value);
                if (isNaN(row[rowkey])) {
                    row[rowkey] = 0;
                }
            },
            // focusInab: function(event,row) {
            //     row.tolerenceoutab = parseInt(event.target.value);
            //     if (isNaN(row.tolerenceoutab)) {
            //         row.tolerenceoutab = 0;
            //     }
            // },
            // onInput(evt,row) {
            //     let val = evt.target.value;
            //     let integer = parseInt(val);
            //     if (integer >= this.max) {
            //         row.tolerenceoutab = 100;
            //     }
            //     else if (integer == 0 || integer <= 0) {
            //         row.tolerenceoutab = 0;
            //     }
            //     else {
            //         row.tolerenceoutab = val;
            //     }
            // },
            pageCurrentChange: function (val) {
                this.page = val;
            },

            rowperpages: async function (val) {
                try {
                    this.usergridrow.ugv.rowperpage = val;
                    this.usergridrow = await this.$training.savepagelayout(this.usergridrow);
                    this.page = 1;
                    this.pagesize = val;
                    this.$forceUpdate();
                } catch (error) {
                    this.$bus.$emit('error', error);
                }
            },

            expandchange: function (row, expandedRows) {
                var expanded = expandedRows.map((v) => { return v.id });
                this.expandedrows = expanded;
                var bigdash = new Option()
                bigdash.innerHTML = "&mdash;"
                this.expandcollapsetext = this.expandedrows.length ? bigdash.text : "+"; //not sure why bigger dash displays like this.
            },

            expandcollapse: function () {
                if (this.expandedrows.length > 0) {
                    this.expandedrows = [];
                }
                else {
                    this.expandedrows = this.getdetailform.map((v) => { return v.id });
                }
                var bigdash = new Option()
                bigdash.innerHTML = "&mdash;"
                this.expandcollapsetext = this.expandedrows.length ? bigdash.text : "+";
            },

            currentCQCChange: function (cqc) {
                if (this.currentcqc != null) {
                    this.currentcqc.current = false;
                }
                if (cqc) {
                    cqc.current = true;
                    this.currentcqc = cqc;
                }
            },

            currentCQCRowChange(cqc, row) {
                if (cqc.currentrow != null) {
                    cqc.currentrow.current = false;
                }
                if (row != null) {
                    row.current = true;
                    cqc.currentrow = row;
                    if (this.currentcqc == null || this.currentcqc.id != cqc.id) {
                        this.$refs.cqctable.setCurrentRow(cqc);
                    }
                }
            },

            addDetailRow: function (cqc, copy) {
                var itm = {
                    ids: { idsname: '' }
                };
                if (copy) {
                    if (cqc.currentrow == null) {
                        this.$message({
                            showClose: true,
                            message: 'Please select a row to copy.', type: 'warning'
                        });
                        return;
                    }
                    itm = JSON.parse(JSON.stringify(cqc.currentrow))
                }
                itm.id = cqc.details.length * -1;
                itm.srno = cqc.details.length + 1
                cqc.details.push(itm);
                this.changed(cqc);
            },

            deleteDetailRow: function (cqc) {
                if (cqc.currentrow) {
                    var id = cqc.currentrow.id;
                    var index = cqc.details.findIndex(x => x.id == id);
                    cqc.details.splice(index, 1)
                    cqc.currentrow = null;
                    this.srnochanged(cqc);
                } else {
                    this.$message({
                        showClose: true,
                        message: 'Please select a row to delete.', type: 'warning'
                    });
                }
            },

            srnochanged: function (cqc) {
                var compare = function (a, b) {
                    return (a.srno > b.srno) ? 1 : ((b.srno > a.srno) ? -1 : 0);
                }

                cqc.details = cqc.details.sort(compare);
                var srno = 1;
                cqc.details.forEach((v, i) => { v.srno = srno++; });
                this.changed(cqc)
            },

            addids: function (copy) {
                var ids = {};
                if (copy) {
                    ids = JSON.parse(JSON.stringify(this.ids))
                    ids.idsname = ids.idsname += ' Copy';
                }
                ids.id = 0;
                this.ids = ids;
            },
            // GetNrmSectionUrlist(){
            //     // alert("GetNrmSectionUrlist called");
            //     this.$http.post('cqclibrary/GetNrmSectionUrlist').then(response =>{
            //         var NrmSectionUrlist =response.data;
            //         this.NrmSectionUrlist = NrmSectionUrlist;
            //     })
            // },
            isvalidids: function () {
                var msg = [];
                if(this.ids.sectionshow)
                    msg.push("Invalid Regex Pattern in Section");
                if(this.ids.groupshow)
                    msg.push("Invalid Regex Pattern in Group");
                if(this.ids.workshow)
                    msg.push("Invalid Regex Pattern in Work");
                if(this.ids.level1show)
                    msg.push("Invalid Regex Pattern in Level1");
                if(this.ids.level2show)
                    msg.push("Invalid Regex Pattern in Level2");
                if(this.ids.level3show)
                    msg.push("Invalid Regex Pattern in Level3");
                if (!this.ids.idsname)
                    msg.push("IDS Name must not be empty");
                if (msg.length > 0) {
                    this.$alert(msg.join("</br>"), "Validation Error", { dangerouslyUseHTMLString: true });
                    return false;
                }
                return true;
                }
            ,
            // reportinToids(cqcrow){
            //     this.isvalidtosave = true
            //     this.changed(cqcrow); //it makes edited true for this cqc row.
            //     var RIn1 = cqcrow.reportin;
            //     for(var i=0;i<=cqcrow.details.length;i++){
            //         cqcrow.details[i].ids.nrmsectionReportIn = RIn1;
            //         this.ids = cqcrow.details[i].ids;
            //         this.$http.post("cqclibrary/saveReportIn", this.ids)
            //             .then(response => {
            //                 console.log("save ids:" + response);
            //             });

            //         // this.savereportinids(cqcrow,RIn1);
            //     }
            // },


            saveids: function () {
                if (this.isvalidids()) {
                    this.$http.post("cqclibrary/saveids", this.ids)
                        .then(response => {
                            var json = JSON.parse(response.data);
                            this.currentcqc.currentrow.ids = json;
                            this.currentcqc.currentrow.idsid = json.id;
                            var data = this.currentcqc.details.find(x => x.idsid == json.id);

                        //    if(data != undefined){
                        //        var ur = data.ids.nrmsectionUR;
                        //        var d1 = this.IMTarray.find((x)=>{ if(x.ur == ur){return x}});
                        //        if(d1 != undefined && d1.ur != ""){
                        //          data.ur1 = d1.ur;
                        //         //  data.ur2 = "";
                        //          data.description1 = d1.datadescription;
                        //         //  data.description2 = d1.datadescription;
                        //        }else{
                        //            data.ur1 = "";
                        //            data.description1 = "";
                        //            data.ur1 = "";
                        //            data.comment1=""

                        //         //    data.description1 = data.ids.idsname;
                        //         //    data.ur2 = "";
                        //         //    data.description2 = "";
                        //         //    data.comment2=""
                        //         //    data.adj1=""
                        //         //    data.adj2="";
                        //         //    data.description2 = data.ids.idsname;
                        //        }
                        //    }
                            this.$message({
                                showClose: true,
                                type: 'success', message: "Successful!"
                            });
                            this.showids = false;
                        })
                        .catch(err => {
                            this.$bus.$emit('error', err);
                        });
                }
                this.getIMT();
            },

            addRow: function (copy) {
                var itm = { searchtype: 'Required', details: [], tolerenceoutab: '0%', tolerenceoutba: '0%', inother: '0%', checkother: '0%' };
                if (copy) {
                    if (this.currentcqc != null) {
                        itm = JSON.parse(JSON.stringify(this.currentcqc))
                        itm.cqcname += " Copy";
                    }
                }
                itm.id = this.detailform.length * -1
                itm.owner = this.user;
                itm.ownerid = this.user.id;
                this.changed(itm);
                this.detailform.unshift(itm);

                this.expandedrows.push(itm.id);
                this.$refs.cqctable.setCurrentRow(itm);
            },

            delRow: function (row) {
                this.$confirm('This will permanently delete this row. Continue?', 'Warning', {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                }).then(() => {
                    row.tolerenceoutab = parseInt(row.tolerenceoutab);
                    if (isNaN(row.tolerenceoutab)) {
                        row.tolerenceoutab = 0;
                    }
                    row.tolerenceoutba = parseInt(row.tolerenceoutba);
                    if (isNaN(row.tolerenceoutba)) {
                        row.tolerenceoutba = 0;
                    }
                    row.inother = parseInt(row.inother);
                    if (isNaN(row.inother)) {
                        row.inother = 0;
                    }
                    row.checkother = parseInt(row.checkother);
                    if (isNaN(row.checkother)) {
                        row.checkother = 0;
                    }
                    this.$http.post('cqclibrary/deletecqc', row)
                        .then(response => {
                            this.$message({
                                showClose: true,
                                message: response.data, type: "success"
                            });
                            this.refresh();
                        })
                        .catch(err => {
                            this.$bus.$emit('error', err);
                        });
                }).catch(() => {
                    this.$message({
                        showClose: true,
                        type: 'info',
                        message: 'Delete canceled'
                    });
                });
            },

            changed: function (row) {
                row.edited = true;
            },

            checkurinput(event){

                var cc = event.target.value.replace(/[^\d0-9]/g, '').replace(/(.{2})/g, '$1 ').trim();
                event.target.value = cc.replace(/\s/g, '-');

            },

            urchanged: function (row,name) {
                if (row[name]) {
                    var orgur = row[name];
                    var dmyur = row[name].replace(/-|\s/g,"");
                    row[name] = dmyur;
                    var regExp = /[1-9][0-9]{3}|[0-9]{3}[1-9]|[0-9][1-9][0-9]{2}|[0-9][0-9][1-9][0-9]/;
                    if (!regExp.test(row[name])) {
                        row[name] = "";
                        this.$alert("Please Enter Unique Reference (UR) between 00-01 to 99-99.", "Warning", { type: 'Warning' });
                    }else {
                        row[name] = orgur;
                    }
                }
                row.edited = true;
            },
            urchanged2: function(row){

                var orgur = row;
                var dmyur = row.replace(/-|\s/g,"");
                row = dmyur;
                    if(row != ""){
                         var regExp = /[1-9][0-9]{3}|[0-9]{3}[1-9]|[0-9][1-9][0-9]{2}|[0-9][0-9][1-9][0-9]/;
                        if (!regExp.test(row)) {
                            row = "";
                        this.$alert("Please Enter Unique Reference (UR) between 00-01 to 99-99.", "Warning", { type: 'Warning' });
                        }
                    }else {
                        row = orgur;
                    }

            },

            isvalid: function (editedrows) {

                //cqcname and in cqc and idsname in cqcdetail must not be empty
                var msg = [];
                    for (var i = 0; i < this.detailform.length; i++) {
                        var a = this.detailform[i];
                        if (a.uniquereference == "" || a.uniquereference == undefined) {
                            a.uniquereference = null;
                        }
                         for (var j = i + 1; j < this.detailform.length; j++) {
                            var b = this.detailform[j];
                            if (a.cqcname == b.cqcname && a.cqcname != "" &&  a.cqcname != null && a.cqcname != undefined) {
                                var msgname = "CQC Name: " + a.cqcname + " is duplicate ";
                                var check =  msg.find(x=> x == msgname);
                                if(!check){
                                msg.push(msgname);
                                }
                            }
                            if(a.uniquereference == b.uniquereference && a.uniquereference != null){
                              var msgname = "UR No. " + a.uniquereference + " is duplicate ";
                                var check =  msg.find(x=> x == msgname);
                                if(!check){
                                msg.push(msgname);
                                }
                            }
                         }
                    }
                editedrows.forEach((cqc, i) => {


                    if (!cqc.cqcname) {
                        msg.push("CQC Name must not be empty for row " + i);
                    } else {
                         if(cqc.uniquereference  && !cqc.unit){
                             msg.push("Unit must not be empty for CQC: " + cqc.cqcname);
                        }
                        if((!cqc.tolerenceinab || cqc.tolerenceinab == 0) || (!cqc.tolerencecheckab || cqc.tolerencecheckab == 0)
                        || (!cqc.tolerenceinba || cqc.tolerenceinba == 0) || (!cqc.tolerencecheckba || cqc.tolerencecheckba == 0))
                        {
                            msg.push("In and Check row's Min and Max value must not be zero for CQC: " + cqc.cqcname+".");
                        }
                        cqc.details.forEach((cqcd, j) => {
                            var isempty = false;
                            if (!cqcd.idsid) {
                                msg.push("IDS Name must not be empty for '" + cqc.cqcname + "' row " + (j + 1));
                                isempty = true;
                            }
                            if (!isempty) {
                                var cdr = cqc.details.find((dr)=>dr.ids.idsname == cqcd.ids.idsname && dr.id != cqcd.id);
                                if(cdr){
                                   var dm =  msg.find((dmr)=> dmr == "'" + cqc.cqcname + "' : IDS Name '" + cdr.ids.idsname + "' is duplicate")
                                   if(!dm){
                                       msg.push("'" + cqc.cqcname + "' : IDS Name '" + cdr.ids.idsname + "' is duplicate");
                                   }
                                }
                            }
                        });
                    }

                });

                if (msg.length > 0) {
                    this.$alert(msg.join("</br>"), "Invalid Data", { dangerouslyUseHTMLString: true });
                    return false;
                }
                return true;
            },
            getnumberonsection(){
                  this.sections.forEach(x => {
                                var sectionno =  x.section.match(/\d+\.*\d+/g);
                                var value = sectionno[0];
                                var beforPoint = value.split(".")[0].padStart(2, '0');
                                var afterPoint = value.split(".")[1].padStart(2, '0');
                                x.sectionlabel = beforPoint  +"."+ afterPoint;
                                x.sectionvalue = value;
                            });
            },
            getallsection(){
                this.$http.get('project/GetSectionsProjectCreate')
                        .then(response => {
                            // response.data.forEach(x => {
                            //     var sectionno =  x.section.match(/\d+\.*\d+/g);
                            //     x.sectionno = sectionno[0];
                            // });
                            this.sections = response.data;
                            this.getnumberonsection();
                        }).catch(err => {
                                this.$bus.$emit('error', err);
                            });
            },
            save: function (item, event) {

                var cqctosave = this.detailform.filter((i) => {
                    var show = false
                    if(i.edited == true){
                        i.tolerenceoutab  = parseInt(i.tolerenceoutab);
                        if (isNaN(i.tolerenceoutab)) {
                            i.tolerenceoutab = 0;
                        }
                        i.tolerenceoutba  = parseInt(i.tolerenceoutba);
                        if (isNaN(i.tolerenceoutba)) {
                            i.tolerenceoutba = 0;
                        }
                        i.inother = parseInt(i.inother);
                        if (isNaN(i.inother)) {
                            i.inother = 0;
                        }
                        i.checkother = parseInt(i.checkother);
                        if (isNaN(i.checkother)) {
                            i.checkother = 0;
                        }
                        show = true
                    }
                    return show;
                });

                if (cqctosave.length > 0) {
                    if (this.isvalid(cqctosave)) {
                        this.$http.post('cqclibrary/saveall', cqctosave)
                            .then(response => {
                                this.$message({
                                    showClose: true,
                                    message: response.data, type: "success"
                                });
                                this.refresh();
                            })
                            .catch(err => {
                                this.$bus.$emit('error', err);
                            });
                    }
                }
                else {
                    this.$message({
                        showClose: true,
                        message: "Nothing to save", type: "info"
                    });
                }
            },

            idsnameblur: function (cqc, row, event) {
                if (!row.ids.idsname) {
                    row.idsid = 0;
                    this.changed(cqc);
                }
            },

            querySearch: function (query, done) {
                if (query == null)
                    query = '';
                this.$http.post('cqclibrary/searchids?q=' + query, {})
                    .then(response => {
                        var idslist = JSON.parse(response.data);
                        done(idslist)
                    })
                    .catch(err => {
                        this.$bus.$emit('error', err);
                    })
            },
            getIMT(){
                  this.$http.post('project/getIMT')
                    .then(response => {
                        this.IMTarray = response.data.imt;
                        this.$store.state.URs = [];
                        this.$store.state.URd = response.data.urlist;
                        //   if(this.ursoptions.length == 0){
                        //     this.ursoptions = this.urs.slice(0, 10);
                        //  }
                        // console.log(this.IMTarray);
                        // console.log("nrmidslist :" + response.data);
                        })
                         .catch(err => {
                        this.$bus.$emit('error', err);
                    })
            },
            handleSelect(cqc, row, value) {
                row.idsid = value.id;
                row.ids = JSON.parse(JSON.stringify(value));
                this.changed(cqc);
            },


            refresh: function () {
                // this.loading = true;
                this.$store.state.isLoading = true;

                this.currentcqc = null;
                this.$http.get('cqclibrary/getall')
                    .then(response => {

                        if (this.user.id == null) {
                            this.user = this.$store.state.user;
                        }
                        var json = JSON.parse(response.data);
                        this.detailform = json;
                        this.detailform.forEach(e => {
                            e.details.forEach(d => {
                                // this.xCexpandchange(d,null);
                            });
                            if (e.tolerenceoutab != null) {
                                e.tolerenceoutab = e.tolerenceoutab +"%";
                            }
                            if (e.tolerenceoutba != null) {
                                e.tolerenceoutba = e.tolerenceoutba +"%";
                            }
                            if (e.inother != null) {
                                e.inother = e.inother + "%";
                            }
                            if (e.checkother != null) {
                                e.checkother = e.checkother + "%";
                            }
                        });
                        if (this.$route.params.exporturonly) {
                            this.URfiltersearch = "URonly";
                            this.search = this.$route.params.exporturonly.cqcname;
                        }
                        if (this.expandedrows.length == 0) {
                            // this.expandcollapse();
                        }
                        // this.loading = false;
                        this.$store.state.isLoading = false;
                    }).catch(err => {
                        // this.loading = false;
                        this.$store.state.isLoading = false;
                        this.$bus.$emit('error', err);
                    });
            },

            getbillmatchreport: function(idsdata) {
                if (idsdata.nrmsectionSearchIn && idsdata.nrmsectionUR) {
                    this.$message({
                        dangerouslyUseHTMLString: true,
                        message: 'Please select only one from <strong>NRM Section</strong> or <strong>UR(D)</strong> in <strong>Search In</strong> row.',
                        type: 'warning'
                    });
                    return;
                }
                if (!idsdata.nrmsectionSearchIn && !idsdata.nrmsectionUR) {
                    this.$message({
                        dangerouslyUseHTMLString: true,
                        message: '<strong>Search In: NRM Section</strong> OR <strong>UR(D)</strong> selection required to get <strong>Bill Match</strong> report.',
                        type: 'warning'
                    });
                    return;
                }
                this.showloading();
                var cqcidsdata = {};
                this.currentcqc.tolerenceoutab = parseInt(this.currentcqc.tolerenceoutab);
                if (isNaN(this.currentcqc.tolerenceoutab)) {
                    this.currentcqc.tolerenceoutab = 0;
                }
                this.currentcqc.tolerenceoutba = parseInt(this.currentcqc.tolerenceoutba);
                if (isNaN(this.currentcqc.tolerenceoutba)) {
                    this.currentcqc.tolerenceoutba = 0;
                }
                this.currentcqc.inother = parseInt(this.currentcqc.inother);
                if (isNaN(this.currentcqc.inother)) {
                    this.currentcqc.inother = 0;
                }
                this.currentcqc.checkother = parseInt(this.currentcqc.checkother);
                if (isNaN(this.currentcqc.checkother)) {
                    this.currentcqc.checkother = 0;
                }
                cqcidsdata = JSON.parse(JSON.stringify(this.currentcqc));
                cqcidsdata.details = [];
                cqcidsdata.details.push(this.currentcqc.currentrow);
                this.$http.post('cqclibrary/getidsbillmatchreport', cqcidsdata)
                .then(response => {
                    this.pageMP = 1;
                    this.totalMP = 0;
                    this.billmatchproject = response.data;
                    this.billmatchprojectshow = true;
                    this.hideloading();
                })
                .catch(err => {
                    this.hideloading();
                    this.$bus.$emit('error', err);
                });
            },
            getbilpage: function(data){
                var partid = data.projectpart.id == null ? 0 : data.projectpart.id;
                this.$store.state.projectids = {projectid:data.id,partid:partid,sectionid:data.section.id};
                var data1 = this.$store.state.projectids;
                this.$bus.$emit("addproject", data1);
                this.$router.push("/projectbill");
            },
            pageMPCurrentChange: function(val) {
                this.pageMP = val;
            },
            headerrowstyleborder({ row, column, rowIndex, columnIndex }) {
                if (rowIndex == 0) {
                    return { "color": "black", "background-color": "#BBBBBB", "border-right": "1px solid #878787" }
                }
            }
        },

        computed: {
            getunit(){
                return function(data,ur){
                    var d1 = this.IMTarray.find((x)=>{ if(x.ur && x.ur == data[ur]){return x}});
                    if(d1){
                        return d1
                    }else{
                        return {};
                    }
                }
            },
            getdetail(){
                return function(data){
                    return data.details
                }
            },
            getdetailform: function () {
                if (this.detailform.length == 0)
                    return [];

                var totalcqc = [];
                totalcqc = this.detailform.filter((cqc) => {
                    var show = true;
                    if (this.search) {
                        show = cqc.cqcname.toLowerCase().includes(this.search.toLowerCase());
                    }
                    if (show && this.searchtypefilter) {
                        show = cqc.searchtype.toLowerCase().includes(this.searchtypefilter.toLowerCase());
                    }
                    if (show && this.URfiltersearch) {
                        if (this.URfiltersearch == "URonly") {
                            if (cqc.uniquereference != null) {
                                show = true;
                            } else {
                                show = false;
                            }
                        }
                    }
                    if (show && this.cqcfilterdata) {
                        if (this.cqcfilterdata == "SMM7") {
                            if (cqc.Library == "SMM7") {
                                show = true;
                            } else {
                                show = false;
                            }
                        }
                        if (this.cqcfilterdata == "NRM2") {
                            if (cqc.Library == "NRM2") {
                                show = true;
                            } else {
                                show = false;
                            }
                        }
                        if (this.cqcfilterdata == "ROADWORK") {
                            if (cqc.Library == "ROADWORK") {
                                show = true;
                            } else {
                                show = false;
                            }
                        }
                    }
                    return show;
                });
                this.total = totalcqc.length;
                if (this.total == 0) {
                    return [];
                }
                if (!(Math.ceil(this.total / this.pagesize) >= this.page)) {
                    this.page = Math.ceil(this.total / this.pagesize);
                }
                return totalcqc.slice(this.pagesize * this.page - this.pagesize, this.pagesize * this.page);
                //if (this.search) {
                //    var search = this.search.toLowerCase();
                //    var data = this.detailform.filter((c) => {
                //        return c.cqcname.toLowerCase().includes(search);
                //    });
                //    return data;
                //} else {
                //    return this.detailform;
                //}
            },
            getbillmatchproject: function(){
                if(this.billmatchproject.length == 0){
                    return [];
                }
                this.totalMP = this.billmatchproject.length;
                if (this.totalMP == 0) {
                    return [];
                }
                if (!(Math.ceil(this.totalMP / this.pagesizeMP) >= this.pageMP)) {
                    this.pageMP = Math.ceil(this.totalMP / this.pagesizeMP);
                }
                return this.billmatchproject.slice(this.pagesizeMP * this.pageMP - this.pagesizeMP, this.pagesizeMP * this.pageMP);
            },
        },
        beforeRouteLeave(to, from, next) {
             var answer = true;
             this.detailform.forEach((i) => {
                 if(i.edited == true){
                     answer = false
                 }
             })
             if (answer == false) {
                 var answer = window.confirm(
                     "Do you really want to leave? you have unsaved changes!"
                 );
             }
             if (answer) {
                 next();
             } else {
                 next(false);
             }
        },
        created: function () {
            let gridrow = this.$training.getusergridrow(this.$route);
            this.pagesize = gridrow.ugv.rowperpage;
            this.usergridrow = gridrow;
            this.getIMT();
            this.refresh();
            if(this.$store.state.sections == undefined || this.$store.state.sections.length == 0 ){
                this.getallsection();
            }else{
                this.sections = this.$store.state.sections;
                this.getnumberonsection();
            }
        },
    }
</script>
<style>
.el-table .cell{
    /* it is added to reduced space between column as note 76 of CC.21.1  */
    padding-left: 0 !important;
}

</style>

<style scoped>
    .el-table .cell {
        /*make more space for textboxes inside el-table*/
        padding-left: 3px !important;
        padding-right: 3px !important;
    }

    .padding2px td {
        padding-left: 2px;
        padding-right: 2px;
        padding-top: 4px;
        padding-bottom: 4px;
    }

    .el-input-number .el-input__inner {
        text-align: right !important;
    }

    .el-input-number--mini {
        width: 100% !important;
    }

    .inputcenter .el-input__inner {
        text-align: center;
    }

    hr {
        margin-top: 6px;
        margin-bottom: 0px;
        border: 0;
        border-top: 3px solid #eee;
    }
    .tag-in {
        /*background-color: white;
        color: green;*/
        background-color: rgb(198, 224, 180) !important;
        color: white !important;
        border: 1px solid;
        border-radius: 0%;
        height: 28px;
        line-height: 26px;
        padding: 0 19px;
    }
    .tag-check{
        /*background-color: white;
        color: darkorange;*/
        background-color: orange !important;
        color: white !important;
        border: 1px solid;
        border-radius: 0%;
        height: 28px;
        line-height: 26px;
        padding: 0 6px;
    }

    table.fixed { table-layout:fixed; }
    table.fixed th { text-align: center; border-bottom: 1px solid #EBEEF5; }
    table.fixed td { overflow: hidden; }
    .width45char{
        width: 290px;
    }
 .icon-expand-btn-width-minus {
    padding: 4px;
    padding-left: 5px;
    padding-right: 5px;
     border-width: 3px;
     font-weight: bold;
}
.icon-expand-btn-width-pluse {
    padding: 4px;
    padding-left: 8px;
    padding-right: 8px;
     border-width: 3px;
     font-weight: bold;
}
    .icon-btn-width {
      padding: 7px;
    }
</style>
