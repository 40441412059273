<template>
    <div>
        <div v-if="uploadprogress > 0">
            <el-progress :text-inside="true" :stroke-width="24" :percentage="uploadprogress" status="success"></el-progress>
            <hr class="hrstyle" />
        </div>
        <div class="RFIwidth">
            <div >
                <span class="headerstyle">Request for Information (RFI)</span>
                <hr class="hrstyle" />
                <el-row>
                    <span v-if="isedit">
                        <el-button @click="cellchanged('comms_rfientryform_save',()=>{saverfi()},false)" :disabled="!editpermission" type="success">Save</el-button>
                    </span>

                    <span>
                        <el-button  icon="el-icon-back" type="info" @click="Backrfi()"
                            class="marginleft4px">Back</el-button>
                    </span>
                    <span v-if="isedit">
                        <el-button :disabled="!editpermission" class="marginleft4px" @click="viewrfi" type="success">View
                            RFI</el-button>
                    </span>
                    <span v-if="isedit">
                        <el-button type="primary" class="spellbtn marginleft4px" style="padding: 3px 5px 1px 5px;"
                            @click="spellcheckMethod()">
                            Spell Check:
                            <span>
                                <el-switch @change="spellchack()" v-model="spell" style="margin-bottom:2px;"
                                    active-color="primary" :active-value="true" :inactive-value="false">
                                </el-switch>
                            </span>
                        </el-button>
                    </span>
                    
                    <span v-if="isedit">
                        <el-button v-if="!nrmsectionprops && isnrmshow" type="primary" class="marginleft4px floatright"
                                   style="padding: 4px 8px 2px;"  @click="ShowArchiveChange">Show Archive
                        <el-switch v-model="additionalfilter"  @change="ShowArchiveChange"></el-switch>
                    </el-button>
                    </span>
                    <span v-if="isedit">
                        <el-button @click="gotoeditrfi" type="primary" class="marginleft4px floatright" >
                            Edit Bill
                        </el-button>
                    </span>
                    <span v-if="isedit">
                        <span style="margin-left: 4px;">
                        <i class="el-icon-info el-popover__reference"
                            style="color: rgb(96, 98, 102);font-size: 27px;" id="info_comms_rfi_header"  @click="$training.Gettrainingpopup('info_comms_rfi_header')"></i>
                        </span>
                    </span>
                    <!-- <span v-if="isedit">
                        <el-button v-if="isnrm" :disabled="!editpermission" class="right" type="primary">Edit Bill</el-button>
                    </span> -->
                </el-row>
                <hr class="hrstyle" />
                <el-row>
                    <el-col v-if="isproprfi" :span="10">
                        <!-- <selectpart 
                        
                    :Similar="Similar"
                     @projectpartchange="cmpprojectsectionchange">
                    </selectpart> -->
                    <el-autocomplete
                        class="inline-input fullwidth bold padding2px"
                        v-model="project.projectname"
                        popper-class="morewidth-autocomplete-project"
                        :fetch-suggestions="searchProject"
                        @select="selectProject"
                        value-key="projectname"
                        :disabled="false"
                        :placeholder="'Please select Project'">
                        <el-button slot="suffix" @click="closeproject" type="text" size="mini">&times;</el-button>
                        <template slot-scope="{ item }">
                            <span style="float: left">{{ item.projectname }}</span>
                            <span style="float: right">{{ item.accessedon | dateformat }}</span>
                        </template>
                    </el-autocomplete>
                    <div class="left hrspacebetween ">
                    <!-- :disabled="project.usepart == 1" -->
                    <el-select
                    v-model="projectPartId"
                    filterable
                    placeholder="Project Parts"
                    style="width: 252px;"
                    
                    class="fullwidth padding2px bold"
                    @change="changepart">
                    <el-option
                        v-for="item in ProjectParts"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                    </el-option>
                </el-select>
                </div>
                    <span>
                        <el-select
                        v-if="!nrmsectionprops"
                        v-model="sectionid"
                        filterable
                        placeholder="Section"
                        class="padding2px bold"
                        style="width: 252px;"
                        @change="sectionchange">
                        <el-option
                            v-for="item in sections"
                            :key="item.id"
                            :label="item.section"
                            :value="item.id">
                        </el-option>
                    </el-select>
                    </span>
                        <!-- <projectselectpart :custom="custom" :lastsavedshow="true"
                            @projectsectionchange="cmpprojectsectionchange">
                        </projectselectpart> -->
                    </el-col>
                </el-row>
                <div>
                    <el-row>
                        <el-select v-if="!nrmsectionprops && isnrmshow" v-model="newnrmsection" @change="versionchange" :id="'comms_rfientryform_nrm_section'" style="padding: 2px 0px 0px 2px;" placeholder="NRM / Bill Section">
                            <el-option  v-for="item in versions" :key="item.id" :label="item.nrmsection"
                                :value="item.id">
                                <div>
                                    <span style="float: left">{{ item.nrmsection }} &nbsp;&nbsp;</span>
                                    <el-button v-if="!additionalfilter" icon="el-icon-edit" @click="editnrm(item)" type="text" size="mini" class="floatright"></el-button>
                                </div>
                            </el-option>
                            <el-option v-if="!additionalfilter" :value="null">
                                <span>
                                  <el-button
                                       class="fontsize14px fullwidth"
                                       icon="el-icon-circle-plus-outline"
                                       :disabled="!editpermission"
                                       type="text"
                                       style="float: left"
                                       @click="addnewNRM"
                                       size="mini">
                                   </el-button>
                                </span>
                            </el-option>
                        </el-select>
                    </el-row>
                    <hr class="hrstyle" />
                </div>
            </div>
            <div v-if="isedit">
                <el-row>
                    <div>
                        <span class="left" style="width: 96%;">
                    <el-input type="textarea"  :disabled="!editpermission || additionalfilter || isoldversion" ref="Issueref" :spellcheck="spell" :autosize="{ minRows: 2, maxRows: 4 }" placeholder="Issue / Question"
                        v-model="form.issue" :id="'comms_rfientryform_issue_question'">
                    </el-input>
                </span>
                    <span class="left " style="width: 4%;padding-left:5px;">
                        <i class="el-icon-info el-popover__reference"
                            style="color: rgb(96, 98, 102);font-size: 15px" id="info_comms_rfi_issue" @click="$training.Gettrainingpopup('info_comms_rfi_issue')"></i>
                    </span>
                </div>
                </el-row>
                <div class="spaceBetween" />
                <el-row>
                    <div>
                        <span class="left" style="width: 96%;">
                    <el-input type="textarea" :disabled="!editpermission || additionalfilter || isoldversion"  :spellcheck="spell" :autosize="{ minRows: 2, maxRows: 4 }" placeholder="Location within documentation"
                        v-model="form.documentlocation" :id="'comms_rfientryform_document_location'">
                    </el-input>
                </span>
                    <span class="left " style="width: 4%;padding-left:5px;">
                        <i class="el-icon-info el-popover__reference"
                            style="color: rgb(96, 98, 102);font-size: 15px" id="info_comms_rfi__document_location" @click="$training.Gettrainingpopup('info_comms_rfi__document_location')"></i>
                    </span>
                </div>
                </el-row>
                <div class="spaceBetween" />
                <el-row>
                    <el-row>
                    <span style="width: 23%;" class="left">
                    <el-button  :disabled="!editpermission || additionalfilter || isoldversion" @click="isopenaddsnip = isopenaddsnip == true ? false : true"
                         type="primary">Add Snip / File</el-button>
                    </span>
                    <span style="width: 77%;" class="left">
                        <span v-for="svi in form.ssv" :key="svi.id">
                            <el-popover placement="bottom" width="200" v-model="svi.mousehover" trigger="hover" >
                                <div @click="showpreview(svi)">
                                    <span class="previewbox" v-if="svi.extention == 'xlsx'">
                                        <div class="UR-Link fontsize12">
                                            {{ svi.name }}
                                        </div>
                                        <div class="center">
                                            <i class="el-icon-document iconsize90"></i>
                                        </div>
                                    </span>
                                    <span v-if="svi.extention">
                                    <span
                                        v-if="svi.extention.startsWith('jpg') || svi.extention.startsWith('jpeg') || svi.extention.startsWith('gif') || svi.extention.startsWith('png')">
                                        <img :src="svi.link" class="previewbox" />
                                    </span>
                                    <span v-if="svi.extention.startsWith('mp4')">
                                        <video controls class="previewbox">
                                            <source :src="svi.url" type="video/mp4">
                                            Your browser does not support the video tag.
                                        </video>
                                    </span>
                                    <span v-if="svi.extention.startsWith('mp3')">
                                        <audio controls class="previewbox">
                                            <source :src="svi.link" type="audio/ogg">
                                            <source :src="svi.link" type="audio/mpeg">
                                            Your browser does not support the audio element.
                                        </audio>
                                    </span>
                                </span>
                                </div>
                                <span slot="reference" class="UR-Link marginl5 marginright5px fontsize12"
                                    @mouseenter="previewsnip(svi)"> <el-tag size="mini"> {{ svi.name }} </el-tag></span>
                            </el-popover>
                        </span>
                    </span>
                    </el-row>
                    <el-row>
                    <span v-if="isopenaddsnip">
                        <div class="spaceBetween">
                            <span style="width: 50%;" class="left" >
                                <div class="mergeuploadcontrol" :id="'comms_rfientryform_addsnip'">
                                    <span class="uploadwidth124 left">
                                        <!-- :on-change="()=>{ return editedchanged(sc, null, 'image'); }" -->
                                        <el-upload ref="upload" action="" :drag="true" :show-file-list="false"
                                            class="dragupload uploadiconsize textupload"
                                            :http-request="(event) => { return addAttachment(event, form); }"
                                            accept=".png,.jpg,.jpeg,.gif,.mp4,.mp3">

                                            <em class="el-icon-upload"></em>
                                            <div class="el-upload__text">Drop file here or <em>click to upload</em></div>
                                        </el-upload>
                                    </span>

                                    <span class="left">
                                        <div @click="pasteimage(form)" class="copycontrol">
                                            <div class="copycontroltext">
                                                <span class="copycontrolfont">Paste the snip from the clipboard</span>
                                            </div>
                                        </div>
                                    </span>
                                </div>
                            </span>
                            <span style="width: 50%;" class="left">
                                <div v-for="(f, i) in  form.files" :key="i">
                                    <span class="widthlabel300">
                                        <div>
                                            <span class="uploadedfilenames">
                                                <em class="el-icon-document"></em>
                                                <span @click="beforeuploadpreview(f)">{{ f.originalname }}</span>
                                            </span>
                                            <span>
                                                <em class="el-icon-close deletefiles"
                                                    @click="deleteAttachment(f.originalname, form.files)"></em>
                                            </span>
                                        </div>
                                    </span>
                                </div>
                            </span>
                        </div>
                    </span>
                    </el-row>
                </el-row>
                <div class="spaceBetween" />
                <el-row>
                    <div>
                        <span class="left" style="width: 96%;">
                        <el-input :disabled="!editpermission || additionalfilter || isoldversion" type="textarea" ref="interimref" :spellcheck="spell" :autosize="{ minRows: 2, maxRows: 4 }"
                            placeholder="Interim Action(S) Taken by TSA" v-model="form.interimaction" :id="'comms_rfientryform_interim_action'">
                        </el-input>
                    </span>
                    <span class="left" style="width: 4%;padding-left:5px;">
                        <i class="el-icon-info el-popover__reference"
                            style="color: rgb(96, 98, 102);font-size: 15px" id="info_comms_rfi_interim_action" @click="$training.Gettrainingpopup('info_comms_rfi_interim_action')"></i>
                    </span>
                    </div>
                </el-row>
                <div class="spaceBetween" />
                <el-row>
                    <span class="left" style="width: 96%;">
                    <el-select :disabled="!editpermission || additionalfilter || isoldversion" @change="(e)=>{changerequiredaction(e,false)}" v-model="contractoractions" class="fullwidth" placeholder="Required actions by contractor" :id="!canshowrequiredaction ? 'comms_rfientryform_action_required' : '' ">
                        <el-option v-for="item in contractoractionsarray" :key="item.value" :label="item.name"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </span>
                <span class="left" style="width: 4%;padding-left:5px;">
                        <i class="el-icon-info el-popover__reference"
                            style="color: rgb(96, 98, 102);font-size: 15px" id="info_comms_rfi_action_required" @click="$training.Gettrainingpopup('info_comms_rfi_action_contractor_actions')"></i>
                    </span>
                </el-row>
                <div class="spaceBetween" />
                 <el-row v-if="canshowrequiredaction">
                    <div>
                        <span class="left" style="width: 96%;">
                    <el-input :disabled="!editpermission || additionalfilter || isoldversion" type="textarea" ref="requiredref" :spellcheck="spell" :autosize="{ minRows: 2, maxRows: 4 }"
                        placeholder="Action(S) Required by the Contractor"  v-model="form.requiredaction" :id="canshowrequiredaction ? 'comms_rfientryform_select_contractoractions':''">
                    </el-input>
                </span>
                    <span class="left" style="width: 4%;padding-left:5px;">
                        <!-- <i class="el-icon-info el-popover__reference"
                            style="color: rgb(96, 98, 102);font-size: 15px" id="info_comms_rfi_action_required" @click="$training.Gettrainingpopup('info_comms_rfi_action_required')"></i> -->
                    </span>
                </div>
                </el-row>
                <div class="spaceBetween" />
                <el-row>
                    <el-select :disabled="!editpermission || additionalfilter || isoldversion"  v-model="form.consultation" :clearable="true" placeholder="Classification" :id="'comms_rfientryform_select_classification'">
                        <el-option v-for="item in consultationarray" :key="item.value" :label="item.name"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-row>
                <div class="spaceBetween" />
                <el-row class="el-input--mini">
                    <div v-if="form.createdbyuser"><b class="left">RFI Created By: </b>
                        <div class="left">{{ form.createdbyuser.loginname }} {{ " " }} {{ getdateformat(form.createdon) }}
                        </div>
                    </div>
                </el-row>
                <hr class="hrstyle" />
                <el-row>
                    <div>
                    <span class="left" style="width: 96%;">
                    <el-input :disabled="!editpermission || additionalfilter || isoldversion" type="textarea" ref="responseref" :spellcheck="spell" @change="responsechange" :autosize="{ minRows: 2, maxRows: 4 }"
                        placeholder="Contractors/Clients Response" v-model="form.response" :id="'comms_rfientryform_contractors_response'">
                    </el-input>
                    </span>
                    </div>
                </el-row>
                <div class="spaceBetween" />
                <el-row>
                    <div class="el-input--mini">
                    <span class="left" style="width: 96%;">
                    <div class="left" ><b>Source:</b></div>
                    <div class="right" v-if="form.responseon">
                        <b class="left">Response Date:</b>
                        <div class="left">{{ getdateformat(form.responseon) }}</div>
                    </div>
                    </span>
                    </div>
                </el-row>
                <div class="spaceBetween" />
                <el-row>
                    <div>
                    <span class="left" style="width: 96%;">
                        <el-input :disabled="!editpermission || additionalfilter " type="textarea" ref="actiontakenref" :spellcheck="spell" @change="actiontakenchange" :autosize="{ minRows: 2, maxRows: 4 }" placeholder="Action Taken by TSA"
                            v-model="form.actiontaken" :id="'comms_rfientryform_actiontakenbytsa'">
                        </el-input>
                </span>
                    <span class="left" style="width: 4%;padding-left:5px;">
                        <i class="el-icon-info el-popover__reference"
                            style="color: rgb(96, 98, 102);font-size: 15px" id="info_comms_rfi_action_taken" @click="$training.Gettrainingpopup('info_comms_rfi_action_taken')"></i>
                    </span>
                </div>
                </el-row>
                <div class="spaceBetween" />
                <el-row class="el-input--mini">
                    <b class="left">RFI Resolved By:</b>
                    <div class="left" v-if="form.resolvedbyuser">{{ form.resolvedbyuser.loginname }} {{ " " }} {{ getdateformat(form.resolvedon) }}</div>
                </el-row>
                <div class="spaceBetween" />
                <el-row>
                    <div class="left"><b class="red el-input--mini">Suppress </b> &nbsp;</div>
                    <div class="left"> <el-checkbox :disabled="checkhideuser || isoldversion" @change="canhidechange" v-model="form.canhide" :id="'comms_rfientryform_checkbox_suppress'"></el-checkbox></div>
                    <div class="left" style="padding-left: 40px;" v-if="isoldversion">
                        <el-tooltip class="item" effect="dark" content="Strikethrough" placement="top-start">
                            <div @click="changestrikethrough" :class="form.strikethrough == true ?'strikethrough-icone-skyblue' : 'strikethrough-icone' "></div>
                        </el-tooltip>
                    </div>
                </el-row>
                <div class="spaceBetween" />
                <el-row>
                    <div class="left">
                    <el-button @click="addrfilibrary" type="primary"  >
                            RFI Library
                        </el-button>
                    </div>
                </el-row>
                <div class="spaceBetween" />
            </div>
            <CustomPageDraggable style="    width: 1064px;height: 514px;left: 877px;top: 426px;" :isvisible="showrfilibrary">
                <i @click="showrfilibrary = false" class="el-icon-close right"></i>
                <projectrfi @addtoproject="addtoproject" :sectionfilterid="sectionid"></projectrfi>
            </CustomPageDraggable>
            <show-media @deleteMedia="deleteMedia" ref="showmedia"></show-media>

            <el-dialog :before-close="addnewnrmbeforeclose" :close-on-click-modal="false" width="20%" title="Add New NRM /Bill section" :visible.sync="addnrm">
                <el-input placeholder="Add New NRM/Bill Section" :id="'comms_rfientryform_addnew_nrm_section'" v-model="editnrmobj.nrmsection"></el-input>
                <div class="spaceBetween" />
                <el-row>
                   <el-col :span="6" :offset="10"> 
                    <el-button type="success" @click="submitnrm">Ok</el-button>
                </el-col>
            </el-row>
            </el-dialog>
        </div>
    </div>
</template>
<script>
// import selectpart from "./selectpart.vue";
import moment, { relativeTimeThreshold } from "moment";
import projectrfi from "./projectrfi.vue";
import showmedia from "./showmedia.vue";
import CustomPageDraggable from './CustomPageDraggable.vue'
export default {
    props: {
        projectamosdetailid: Number,
        projecttemplatedetailid:Number,
        nrmsectionprops:String,
        rfiid:Number,
        ispopup:Boolean
    },
    data() {
        return {
            showrfilibrary:false,
            rfisections:[],
            projectPartId:null,
            part:{},
            ProjectParts:[],
            // partsections:[],
            projectname:"",
            contractoractions:"Contractor to confirm",
            canshowrequiredaction:false,
            additionalfilter:false,
            partid:null,
            sectionid:null,
            sections:[],
            Similar: {
                projectPlaceholder: '',
                partPlaceholder: '',
                lastsavedshow: false,
                projectdisabled:false,
                partdisabled:false,
                partstyle:{ width: '289px', 'padding': '3px 0px 3px 3px' }
            },
            isnrmshow:false,
            editnrmobj:{},
            addnrm:false,
            newnrmsection:'',
            filescount: 0,
            user: this.$store.state.user,
            uploadprogress: 0,
            extensions: [
                { name: 'jpg' },
                { name: 'jpeg' },
                { name: 'png' },
                { name: 'gif' },
                { name: 'mp4' },
                { name: 'mp3' }
            ],
            isopenaddsnip: false,
            contractoractionsarray:[
                { value: 'Contractor to confirm', name: 'Contractor to confirm' },
                { value: 'Contractor to confirm or state requirements', name: 'Contractor to confirm or state requirements' },
                { value: 'For information only', name: 'For information only' },
                { value: 'Custom Text', name: 'Custom Text' },
            ],
            consultationarray: [
                { value: 'Urgent Attention Required', name: 'Urgent Attention Required' },
                { value: 'Outstanding Risk Item', name: 'Outstanding Risk Item' },
                { value: 'Resolved', name: 'Resolved' }
            ],
            isedit: false,
            form: {},
            nrmsection: '',
            spell: false,
            // custom: {
            //     rowcol: 'el-row',
            //     projectsid: null,
            // },
            project: {},
            sectionid: null,
            versions: [],
            projectsection:{},
        };
    },
    computed: {
        isoldversion(){
            if(this.form.id == null){
                return false;
            }
            if(this.form.currentrfiversion && this.form.currentrfiversion.id != null && this.form.currentrfiversion.id != this.form.rfiversionid){
                return true;
            }
            return false;
        },
        checkhideuser(){
                if(!this.editpermission){
                    return false;
                }
                if(this.additionalfilter == true && this.form.id == null){
                        return true;
                }
                return false;
        },
        isnrm(){
            if(this.projectamosdetailid != null || this.projecttemplatedetailid != null || this.nrmsectionprops != null){
                return false;
            }else{
                return true;
            }
        },
        isproprfi(){
            if(this.rfiid == undefined){
                return true;
            }else{
                return false;
            }
        },
        getdateformat: function () {
            return function (data) {
                if (data) {
                    var dt = new Date(data);
                    return moment(dt).format("DD-MMM-YYYY  HH:mm"); // dt.toLocaleString().replace(/:\d{2}\s/, " ");
                }else{
                    return "";
                }
            }
        }
    },
    components: {
        // selectpart: selectpart,
        // projectselectpart:projectselectpart,
        "CustomPageDraggable":CustomPageDraggable,
        "projectrfi":projectrfi,
        'show-media': showmedia,
    },
    methods: {
        Backrfi(){
            if(this.ispopup == true){
                this.$emit("closerfi", true);
            }else{
                this.$router.go(-1);
            }
        },
        addtoproject(data){
            this.showrfilibrary = false;
            if(data != undefined && data.length > 0){
                var obj = {
                    projectid:this.project.id,
                    projectpartid:this.part.id,
                    librarys:data
                }
                this.showloading();
                this.addrfitoproject(obj).then(()=>{
                    this.hideloading();
                    this.getRFIversions();
                });
            }
        },
        addrfilibrary(){
            this.showrfilibrary = true;
            
        },
        changestrikethrough(){
            var newvalue = this.form.strikethrough  == true ?  false : true; 
              this.$set(this.form,"strikethrough",newvalue);
        },
        canhidechange(event){
         if(event){
             this.form.canhideon = moment(new Date()).format("DD-MMM-YYYY  HH:mm:ss"); //this.getdateformat();
         }else{
            //  this.form.canhideon = null;
         }
        },
        getCurrentSection: function () {
            this.sectionid = undefined;
            if (this.$store.state.rfisectionid) {
                var section = this.sections.find(
                (s) => s.id == this.$store.state.rfisectionid
                );
                if (section) {
                    this.sectionid = section.id;
                    this.sectionchange(this.sectionid)
                    this.project.section = section;
                    // this.lastsavedbyname = section.lastsavedbyname;
                }
            }
        },
        changepart(event){
            this.sectionid = undefined;
            this.versions = [];
            this.form = {};
            this.contractoractions = "Contractor to confirm";
            this.newnrmsection = null;
            this.isnrmshow = false;
            this.isedit = false;
            // this.section = [];
            if (event == 0) {
                this.projectPartId = 0;
                this.sections = this.rfisections;
                // this.sections = this.partsections.filter((s) => {
                //     if (s.projectPartId == 0) {
                //         return s;
                //     }
                // });
            }else if(event == -1){
                this.$set(this,'projectPartId',-1);
                // this.projectPartId = -1;
                // this.sections = this.partsections;
                // this.sections = this.partsections.filter((value, index, self) =>
                //                 index === self.findIndex((t) => (
                //                     t.sectiondisplay === value.sectiondisplay
                //                 )))
            }
            else {
                // this.sections = this.partsections.filter((s) => {
                //     if (s.projectPartId == event) {
                //         return s;
                //     }
                // });
                this.sections = this.rfisections;
            }
            // this.sections.unshift({sectiondisplay:'General Items',id:null})
            var projectpart = this.ProjectParts.find((p) => p.id == event);
            this.part = projectpart;
            this.$store.state.projectpart = projectpart;
            this.getCurrentSection();
        },
        closeproject(){
            this.project = {
                id: 0,
                projectname: "",
                library: "",
            };
            // this.lastsavedbyname = null;
            this.sectionid = undefined;
            this.projectPartId = null;
            this.sections = [];
            this.part = null;
            // this.partsections = [];
            this.ProjectParts = [];
            this.versions = [];
            this.form = {};
            this.contractoractions = "Contractor to confirm";
            this.newnrmsection = null;
            this.isnrmshow = false;
            this.isedit = false;
        },
        getCurrentPart(){
            if(this.project.usepart == 1){
                this.$set(this,'projectPartId',-1);
                this.sections = this.rfisections;
                // this.projectPartid = -1;
            }else{      
                this.projectPartid = null;
                if (this.$store.state.partname) {
                    var part = this.ProjectParts.find(
                    (s) => s.name == this.$store.state.partname
                    );
                    if (part) {
                        this.projectPartId = part.id;
                        this.part = part;
                        this.changepart(part.id);
                    }
                }
                if (this.projectPartid == null) {
                    if (this.$store.state.projectpart) {
                        var part = this.ProjectParts.find(
                            (s) => s.id == this.$store.state.projectpart.id
                        );
                        if (part) {
                            this.projectPartId = part.id;
                            this.part = part;
                            this.changepart(part.id);
                        }
                    }
                }
            }
        },
        getSections: function () {
            this.$http
            .post("project/getsections", this.project)
            .then((response) => {
            //   var json = JSON.parse(response.data);
            // this.partsections = response.data.projectsectionsview;
            this.ProjectParts = response.data.projectparts;
            if(this.project.usepart == 1){
                this.ProjectParts = [];
                this.ProjectParts.push({ id: -1, name: "All" });
            }else{
                if (this.ProjectParts.length == 0 && this.project.projectname != "") {
                    this.ProjectParts.unshift({ id: 0, name: "Single Bill" });
                }
            }
            this.getCurrentPart();
            })
            .catch((err) => {
            this.$bus.$emit("error", err);
            this.loading = false;
            });
        },
        selectProject: function (item) {
            // if (this.remember) {
                this.$store.state.project = JSON.parse(JSON.stringify(item));
            // }
            this.project = item;
            if(this.project.usepart === undefined || this.project.usepart == null){
                this.project.usepart = 0;
            }
            this.sectionid = undefined;
            // this.sections = [];
            this.getSections();
        },
        changerequiredaction(event,canadd){
            if(canadd == true){
                var canfind = this.contractoractionsarray.find(x => x.name == event);
                if(canfind != undefined){
                this.contractoractions = canfind.name;    
                this.canshowrequiredaction = false;
                }else{
                    this.contractoractions = "Custom Text";
                    this.canshowrequiredaction = true;
                }
            }else{
                if(event == "Custom Text"){
                    // this.requiredaction = "";
                    this.$set(this.form,'requiredaction',"");
                    // this.form.requiredaction = "";
                    this.canshowrequiredaction = true;
                }else{
                    // this.requiredaction = event;
                    this.$set(this.form,'requiredaction',event);
                    // this.form.requiredaction = event;
                    this.canshowrequiredaction = false;
                }
            }
            
        },
        gotoeditrfi(){
            if(this.form.projecttemplatedetailid != undefined && this.form.projectamosdetailid == undefined){
            //     this.$store.state.projectids = {
            //     projectid: this.form.projectid,
            //     partid: this.form.projectpartid,
            //     sectionid: this.form.projectsectionid
            // };
            // let data = this.$store.state.projectids;
            //     var data = {
            //     projectid: this.form.projectid,
            //     partid: this.form.projectpartid,
            //     sectionid: this.form.projectsectionid
            // };
            // let data = this.$store.state.projectids;
            // this.$bus.$emit("addproject", null);
                this.$router.push("/templates");
            }
            if(this.form.projecttemplatedetailid == undefined && this.form.projectamosdetailid != undefined){
            //     var data = {
            //     projectid: this.form.projectid,
            //     partid: this.form.projectpartid,
            //     sectionid: this.form.projectsectionid
            // };
            // // let data = this.$store.state.projectids;
            // this.$bus.$emit("addproject", data);
                this.$router.push ("/projectamos");
            }
            if(this.form.projecttemplatedetailid == undefined && this.form.projectamosdetailid == undefined){
                this.getbillpagename();
            }
        },
        getbillpagename(){
            var obj = {
                projectid:this.project.id,
                projectpartid:this.partid,
                projectsectionid:this.sectionid
            }
            this.$http.post("RFI/getbillpagename",obj)
                .then((response) => {
                    if(response.data == 2){
                        this.$router.push("/templates");
                    }else if(response.data == 1){
                        this.$router.push ("/projectamos");
                    }else{
                        this.$router.push ("/projectbill");
                    }
                })
                .catch((err) => {
                    this.$bus.$emit("error", err);
                });
        },
        ShowArchiveChange(){
            this.additionalfilter = !this.additionalfilter;
            if(this.nrmsectionprops == undefined){
                this.getRFIversions();
            }
            // this.getRFIversions();
            this.form = {};

        },
        sectionchange(event){
            this.projectsection = {};
            this.form = {};
            this.sectionid = event;
            // this.form.projectsectionid = event; 
            if(event >= 0){
                var issection = this.rfisections.find(x => {
                    if(event == x.id){
                        return true;
                    }else{
                        false;
                    }
                    // if(this.partid != null){
                    //     return x.id == event && x.projectPartId == this.partid;
                    // }else{
                    //     return x.id == event;
                    // }

                } );
                if(issection != undefined){
                    this.projectsection = issection;  
                    // this.$store.state.sectiondisplay = issection.sectiondisplay;
                }else{
                    this.sectionid = undefined;
                }
            }
            
            this.$store.state.rfisectionid = this.sectionid;
            // this.form = {};
            // this.newnrmsection = null;
            if(!this.nrmsectionprops){
                 this.getRFIversions();
             }
            // this.getRFIversions();
            // this.isnrmshow = false;
                    this.isedit = false
                    this.isnrmshow = true;
            if(this.nrmsectionprops != undefined){
                    this.newnrmsection = this.nrmsectionprops;
                    this.getRFIbyid();
                }
            //  if (this.form.projectsectionid != undefined) {
            //     // this.form.projectsectionid = Projectobj.sectionid;
            //     // if(this.projectamosdetailid != null || this.projecttemplatedetailid != null){
            //     //     this.getRFIbyid();
            //     // }else
                 
            // } else {
            //     this.form = {};
            //     this.isedit = false;
            //     this.isnrmshow = false;
            //     this.projectsection = {};
            // }
        },
        setdirectionsection() {
            var s = this.sections.find(x => x.id == this.sectionid);
            if (s != undefined) {
                return s.section;
            } else {
                return "";
            }
        },

        cellchanged(cellaction, callback, cancall) {
            const app = this;
            this.$directionutility.setcomponent(app);
            if (cancall) {
                callback();
                this.canmatchcellid(() => { }, cellaction, this.setdirectionsection());
            } else {
                this.canmatchcellid(callback, cellaction, this.setdirectionsection());
            }
        },

        addnewnrmbeforeclose(done){
            this.editnrmobj ={};
            done();
        },
        editnrm(data){
            this.editnrmobj = JSON.parse(JSON.stringify(data)); 
            this.addnrm = true;
        },
        submitnrm() {
            if(this.editnrmobj.nrmsection == "" || this.editnrmobj.nrmsection == undefined){
                this.$confirm(`NRM/Bill section should not be empty`, 'Warning', {
                    confirmButtonText: 'OK',
                    type: 'warning',
                    showCancelButton: false
                });    
                return
            }
            var isfind = this.versions.find(x => x.nrmsection == this.editnrmobj.nrmsection && x.id != this.editnrmobj.id);
            if (isfind == undefined) {
                if (this.editnrmobj.id == undefined) {
                    this.form = JSON.parse(JSON.stringify({}));
                    this.versions.push({ nrmsection: this.editnrmobj.nrmsection });
                    this.$set(this.form, 'nrmsection', this.editnrmobj.nrmsection);
                    this.contractoractions = "Contractor to confirm";
                    this.$set(this.form,'requiredaction',this.contractoractions);
                    // if(this.sectionid == 0){
                    //     this.$set(this.form, 'projectsectionid', null);
                    // }else{
                    //     this.$set(this.form, 'projectsectionid', this.sectionid);
                    // }
                    this.$set(this, 'newnrmsection', this.editnrmobj.nrmsection);
                    // this.$set(this.form, 'projectpartid', this.partid);
                    this.$set(this.form, 'projectid', this.project.id);
                    this.isedit = true;
                    this.addnrm = false;
                } else {
                    this.updatenrmsection(this.editnrmobj);
                    this.addnrm = false;
                    if (this.form.id == this.editnrmobj.id) {
                        this.newnrmsection = this.editnrmobj.nrmsection;
                        this.form.nrmsection = this.editnrmobj.nrmsection;
                    }
                }
            } else {
                this.$confirm(`${isfind.nrmsection} NRM/Bill section is already used. Please create another NRM/Bill section. `, 'Warning', {
                    confirmButtonText: 'OK',
                    type: 'warning',
                    showCancelButton: false
                });
            }
        },
        updatenrmsection(){
            this.$http.post("RFI/updatenrmsection", this.editnrmobj)
                .then((response) => {
                    if(this.nrmsectionprops == undefined){
                        this.getRFIversions();
                    }
                    // this.getRFIversions();
                    this.editnrmobj = {};
                })
                .catch((err) => {
                    this.$bus.$emit("error", err);
                    this.hideloading();
                });
        },
        addnewNRM(){
            const indicesToRemove = [];
            this.versions.forEach((element, index) => {
                if (element.id == undefined || element.id == 0) {
                    indicesToRemove.push(index);
                }
            });

            // Remove elements from the array based on the indices found
            indicesToRemove.reverse().forEach(index => {
                this.versions.splice(index, 1);
            });
            this.editnrmobj = {};
            if(this.sectionid == null){
               var lastversion = this.versions[this.versions.length -1];
               if(lastversion != undefined){
                   var parts = lastversion.nrmsection.split(/\./);
                   this.editnrmobj.nrmsection = "G."+(parseInt(parts[parts.length - 1]) + 1); 
               }
            }else{
                var lastversion = this.versions[this.versions.length -1];
               if(lastversion != undefined){
                   var parts = lastversion.nrmsection.split(/\.(?=[^.]*$)/);
                   this.editnrmobj.nrmsection = parts[0]+'.'+(parseInt(parts[1]) + 1); 
               }
            }
            this.addnrm = true;
        },
        deleteMedia(data) {
            this.showloading();
            this.$http.post("RFI/deletemedia", { id: data.id ,tableid:data.tableid})
                .then((response) => {
                    this.hideloading();
                    this.$message({
                        showClose: true,
                        message: "Media deleted successfully!",
                        type: "success",
                    });
                    this.$refs.showmedia.CloseMedia();
                    this.getsectionalviewimages();
                })
                .catch((err) => {
                    this.$bus.$emit("error", err);
                    this.hideloading();
                });
        },
        getsectionalviewimages() {
            this.$http.post("RFI/getsectionalviewimages", { tableid: this.form.id, source: 'rfidetail' })
                .then((response) => {
                    this.form.ssv = response.data;
                })
                .catch((err) => {
                    this.$bus.$emit("error", err);
                    this.hideloading();
                });
        },
        showpreview(i) {
            if(!this.additionalfilter){
                i.mousehover = false;
                i.showdialog = true;
                this.$refs.showmedia.openmedia(i, 'selectedmedia');
            }
        },
        previewsnip(i) {
            i.link = i.link.trim();
            i.extention = i.name.substring(i.name.lastIndexOf('.') + 1).toLowerCase();
            if(i.link[i.link.length - 1] == "0"){
                i.link = `${i.link.substring(0, i.link.length - 1)}1`;    
            }
            i.mousehover = true;
            i.externalurl = `${i.link.substring(0, i.link.length - 1)}0`;
            if (i.extention === "mp4") {
                i.url = `${i.link.substring(0, i.link.length - 4)}raw=1`;
            }
        },
        beforeuploadpreview(i) {
            this.$refs.showmedia.openmedia(i, 'beforemedia');
        },
        deleteAttachment(name, files) {
            let index = files.findIndex(x => x.originalname == name);
            files.splice(index, 1);

        },
        async pasteimage(row) {
            try {
                const permission = await navigator.permissions.query({ name: 'clipboard-read' });
                if (permission.state === 'denied') {
                    throw new Error('Please give the clipboard read permission.');
                }
                await navigator.clipboard.read().then((items) => {
                    for (let item of items) {
                        for (let type of item.types) {
                            if (type.startsWith("image/")) {
                                const result = this.handleImage(type, item, row);
                                if (result) {
                                    break;
                                }
                            }
                        }
                    }
                });
            }
            catch (error) {
                var style = "<style> .el-message-box {width:50%;} </style>";
                this.$alert(error.message + style, "Invalid Data", { dangerouslyUseHTMLString: true });
            }
        },
        handleImage(type, clipboardItem, row) {
            if (type.startsWith("image/")) {
                clipboardItem.getType(type).then((myBlob) => {
                    let date = moment(new Date()).format("DD-MMM-YYYY HH:mm:ss");
                    const myfile = new File([myBlob], `Image ${date}.jpeg`, {
                        type: myBlob.type,
                    });
                    if (myfile != null) {
                        if (row.files == undefined) {
                            row.files = [];
                        }
                        const existfile = row.files.find(x => x.originalname == myfile.name);
                        if (existfile == undefined) {
                            this.addAttachment(Object.assign({ file: myfile }), row);
                        }
                    }
                });
                return true;
            }
            return false;
        },
        addAttachment(file, row) {
            const name = file.file.name;
            const ext = name.substring(name.lastIndexOf('.') + 1).toLowerCase();
            const existextension = this.extensions.find(x => x.name == ext);
            const validext = this.extensions.map(e => { return e.name; }).join(", ");
            if (existextension == undefined) {
                var style = "<style> .el-message-box {width:30%;} </style>";
                this.$alert(`<span style='color:red;'>${name}</span> has invalid extension, allowed extensions are  ${validext}. ${style}`,
                    "Validate extension", {
                    dangerouslyUseHTMLString: true,
                });
                return;
            }
            this.filescount += 1;
            const rename = `${this.filescount} || ${file.file.name}`;
            const renamefile = new File([file.file], rename);
            const f = {
                sectionalviewid: row.id,
                originalname: name,
                checkingname: rename,
                content: renamefile
            };
            if (this.form.files == undefined) {
                this.form.files = [];
            }
            this.form.files.push(f);
            this.$forceUpdate();
        },
        deleteAttachment(name, files) {
            let index = files.findIndex(x => x.originalname == name);
            files.splice(index, 1);
        },
        actiontakenchange(){
            this.$nextTick(() => {
                if(this.form.actiontaken == "" && this.form.id == null){
                    this.form.resolvedby = 0; //this.user.id;
                    this.form.resolvedbyuser = null;  //this.user;
                    this.form.resolvedon = null; //moment().format('MMMM/DD/YYYY, HH:mm:ss');
                }else{
                    this.form.resolvedby = this.user.id;
                    this.form.resolvedbyuser = this.user;
                    this.form.resolvedon = moment().format('MMMM/DD/YYYY, HH:mm:ss');
                }
                
            })
        },
        responsechange() {
            this.$nextTick(() => {
                if(this.form.response == "" && this.form.id == null){
                    this.form.responseon = null;    
                }else{
                    this.form.responseon = moment().format('MMMM/DD/YYYY, HH:mm:ss');
                }
            })
        },
        versionchange(data) {
            if(data != null && this.addnrm == false){
                var isid = this.versions.find(x => x.id == data);
                if(isid != null){
                    this.newnrmsection = isid.nrmsection;
                    this.form.nrmsection = isid.nrmsection;
                }
                this.form.id = data;
                this.getRFIbyprojectsectionid();
            }else{
                this.newnrmsection = this.form.nrmsection;
                this.$forceUpdate();
            }
        },
        getRFIversions() {
            this.showloading();
            var dd = {
                // projectsectionid:this.sectionid,
                projectid:this.project.id,
                // projectpartid:this.partid,
                canhide:this.additionalfilter,
                usepart:this.project.usepart,
                section:""
            };
            if(this.project.usepart == 1){
                dd.projectpartid = null;
            }else{
                if(this.projectPartId  > 0 && this.projectPartId != null){
                    dd.projectpartid = this.projectPartId;
                }else{
                    dd.projectpartid = 0;
                }
            }
            if(this.projectsection && this.projectsection.section != undefined && this.sectionid > 0){
                dd.section = this.projectsection.section
            }else{
                dd.section = "G";
            }
            dd.rfisectionsid = this.sectionid;
            this.$http.post("RFI/getRFIversions", dd)
                .then((response) => {
                    this.form = {};
                    this.contractoractions = "Contractor to confirm";
                    this.newnrmsection = null;
                    this.versions = response.data;
                    if(this.additionalfilter == true && this.versions.length <= 0){
                        this.additionalfilter = false;
                        this.getRFIversions();
                        this.$message.error('Oops, Archive RFI for this section is currently unavailable');
                    }
                    if(!this.additionalfilter){
                        var newnrm = this.$store.state.nrmbillsection;
                        if(newnrm != null){
                            var ismatch = this.versions.find(x => x.nrmsection == newnrm);  
                            if(ismatch != null){
                                this.form.nrmsection = ismatch.nrmsection; 
                                this.getRFIbyprojectsectionid()
                                this.newnrmsection = newnrm;
                            }else{
                                this.versions.push({ nrmsection: newnrm })
                                this.form.nrmsection = newnrm;    
                                this.isedit = true;
                                this.newnrmsection = newnrm;
                            }
                        this.$store.state.nrmbillsection = null;
                        }else{
                            if(this.versions.length == 0) {
                                this.isedit = true;
                                if(this.sectionid == null){
                                    this.versions.push({ nrmsection: 'G.1', projectid:this.project.id,projectpartid:this.partid,projectsectionid:null })
                                    this.form = { nrmsection: 'G.1', projectid:this.project.id,projectpartid:this.partid,projectsectionid:null };
                                    this.newnrmsection = 'G.1';
                                }else{
                                    var newsectoin = this.rfisections.find(x => x.id == this.sectionid);
                                    
                                    const matches =  newsectoin.section.match(/\d+\.\d+/g);
                                    var newvs = matches[0] + ".1";
                                    this.versions.push({ nrmsection: newvs })
                                    this.form.nrmsection = newvs;
                                    this.newnrmsection = newvs;
                                }
                            }
                        }
                    }
                    this.hideloading();
                })
                .catch((err) => {
                    this.$bus.$emit("error", err);
                    this.hideloading();
                });
        },
        buildFormData(formData, data, parentKey) {
            if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
                Object.keys(data).forEach(key => {
                    this.buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
                });
            } else {
                const value = data == null ? '' : data;
                formData.append(parentKey, value);
            }
        },

        jsonToFormData(data) {
            const formData = new FormData();
            this.buildFormData(formData, data);
            return formData;
        },
        saverfi() {
            if(!this.form.nrmsection){
                this.$message({
                        showClose: true,
                        message:"Please select the NRM/Bill section first!",
                        type: "error",
                    });
                return false;
            }
            let onprogress = {
                onUploadProgress: (progress) => {
                    this.uploadprogress = Math.floor(100 / progress.total * progress.loaded);
                }
            };
            this.showloading();
            if(this.isproprfi == true){
                if(this.projectPartId == -1){
                    this.form.projectpartid = null;
                }else{
                    this.form.projectpartid = this.projectPartId;
                }
                // if(this.projectPartId == -1){
                //     // this.form.projectsectionid = null;
                //     this.form.projectpartid = null;
                // }else if(this.projectPartId == 0){
                //     // this.form.projectsectionid = null;
                //     this.form.projectpartid = this.projectPartId;
                // }
                // else{
                //     this.form.projectpartid = this.projectPartId;
                //     // if(this.sectionid == null){
                //     //     this.form.projectsectionid = null;    
                //     // }else{
                //     //     this.form.projectsectionid = this.sectionid;
                //     // }
                    
                // }
                this.form.rfisectionsid = this.sectionid;
                if(this.projecttemplatedetailid != null || this.projectamosdetailid != null){
                    this.form.projecttemplatedetailid = this.projecttemplatedetailid;
                    this.form.projectamosdetailid = this.projectamosdetailid;
                }
                //  this.form.projectpartid = this.partid;
                this.form.projectid = this.project.id;
            }
            let formdata = this.jsonToFormData(this.form);
            this.$http.post("RFI/saveRFI", formdata, onprogress)
                .then((response) => {
                    this.$message({
                        showClose: true,
                        message: response.data,
                        type: "success",
                    });
                    this.getRFIbyprojectsectionid();
                    if(this.nrmsectionprops == undefined){
                        this.getRFIversions();
                    }
                    this.hideloading();
                    this.uploadprogress = 0;
                    this.$emit("rfisaveed", {});
                })
                .catch((err) => {
                    this.$bus.$emit("error", err);
                    this.hideloading();
                });
        },
        viewrfi() {
            this.$router.push("/rfireport");
        },
        spellcheckMethod() {
            this.spell = this.spell == true ? false : true;
        },
        spellchack() {
            this.spell = this.spell == true ? false : true;
        },
        getRFIbyprojectsectionid() {
            this.isedit = false;
            var obj = {
                nrmsection: this.newnrmsection,
                rfisectionsid:this.sectionid,
                id:this.rfiid,
                projectid:this.project.id
            }
            if(obj.id == undefined && this.form.id && this.form.id > 0){
                obj.id = this.form.id;
            }
            if(this.projectPartId == -1){
                obj.projectpartid = null; 
            }else{
                obj.projectpartid = this.projectPartId;
            }
            this.showloading();
            this.$http.post("RFI/getRFIbyprojectsectionid", obj)
                .then((response) => {
                    this.form = response.data;
                    if(this.additionalfilter == true){
                        if(this.versions.length <= 0){
                            this.$message.error('Oops, Archive RFI for this section is currently unavailable');
                            // this.$message({
                            //     showClose: true,
                            //     message: "Archive RFI for this section is currently unavailable",
                            //     type: "error",
                            // });
                            this.additionalfilter = false;
                            this.getRFIversions();
                        }
                        if(this.form.canhide == false){
                            this.form = {};
                            this.newnrmsection = null;
                        }
                    }
                    if(this.additionalfilter == false){
                        if(this.form.canhide == true){
                            this.form = {};
                            this.newnrmsection = null;
                        }
                    }
                    this.isedit = true;
                    this.contractoractions = "Contractor to confirm";
                    this.changerequiredaction(this.form.requiredaction,true);
                    if(this.isproprfi){
                     const index = this.versions.findIndex(x => x.id == undefined || x.id == 0);
                        if (index > -1 && this.versions[index].nrmsection != this.form.nrmsection) { 
                            this.versions.splice(index, 1);
                        }
                        const indicesToRemove = [];
                        this.versions.forEach((element, index) => {
                            if ((element.id == undefined || element.id == 0) && element.nrmsection != this.form.nrmsection) {
                                indicesToRemove.push(index);
                            }
                        });

                        // Remove elements from the array based on the indices found
                        indicesToRemove.reverse().forEach(index => {
                            this.versions.splice(index, 1);
                        });
                    
                        this.newnrmsection = this.form.nrmsection;
                    }
                    //    if(!this.newnrmsection && this.nrmsectionprops){
                    //    } 
                    // if(this.isnrm){
                    // const index = this.versions.findIndex(x => x.id == undefined || x.id == 0);
                    //     if (index > -1) { 
                    //         this.versions.splice(index, 1);
                    //     }
                    // }
                    // if(!this.newnrmsection && this.versions.length > 0)
                    // {
                    //     this.newnrmsection = this.form.nrmsection; 
                    //     this.nrmsectionprops = this.newnrmsection;
                    // }
                    // if(this.nrmsectionprops != undefined && this.form.projectsectionid != null){
                    //     var issection = this.sections.find(x => x.id == this.form.projectsectionid);
                    //         if(issection != undefined){
                    //             // this.$store.state.sectiondisplay = issection.sectiondisplay;
                    //             this.sectionid = issection.id; 
                    //         }
                    // }
                    this.hideloading();
                    this.filescount = 0;
                    // this.$forceUpdate();
                })
                .catch((err) => {
                    this.$bus.$emit("error", err);
                    this.hideloading();
                });
        },
        getRFIbyid(){
            this.getRFIbyprojectsectionid();
            this.isedit = true;
        },
    },
    created(){
        if(this.$store.state.rfisections.length <= 0){
            this.getrifsections().then((data)=>{
                this.rfisections = [];
                this.rfisections = JSON.parse(JSON.stringify(data));
                this.rfisections.unshift({section:'General Items',id:null})
            });
        }else{
            this.rfisections = [];
            this.rfisections = JSON.parse(JSON.stringify(this.$store.state.rfisections));
            this.rfisections.unshift({section:'General Items',id:null})
        }
        if(this.rfiid != null){
            this.getRFIbyprojectsectionid();
        }else{
            if (this.$store.state.project) {
                        var project = JSON.parse(JSON.stringify(this.$store.state.project));
                        this.selectProject(project);
            }
        }
    }
};
</script>
<style></style>
