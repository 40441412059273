<template>
    <div>
        <div class="fixdheader">
        <el-col class="headerstyle"> <span >CQC / RWC Summary</span> </el-col>
        <selectpart :Similar="Similar" @projectpartchange="projectpartchange"></selectpart>        
         <div class="spaceBetween" />
        <el-row :gutter="5" v-if="detail">
            <el-col :span="1">
              <div class="fullwidth" 
               style="text-align:center">
                Filter By:
              </div>
            </el-col>
            <el-col :span="3">
              <el-select 
              class="fullwidth" 
              v-model="summarytypeshow" 
              placeholder="Select Filter" 
              :id="'analysis_cqcrwcsummary_filter'"
              @change="summarytypechange"
              size="mini">
                 <el-option
                   v-for="item in summarytypearr"
                   :key="item.value"
                   :label="item.name"
                   :value="item.value">
                  </el-option>
               </el-select>
            </el-col>
        </el-row>
          <hr class="hrstyle" />
        </div>
        <el-row>
            <el-col v-if="detail">
                <el-table  :data="getcqcrwcdata" class="fullwidth" @expand-change="expandchangerow" :header-cell-style="getRowClass" :cell-style="getcellstyle">
                    <el-table-column label="Section">
                        <el-table-column type="expand" width="35">
                            <template slot-scope="scope" class="fullwidth">
                                <div v-if="subtabeshow">
                                <el-row class="dark-gray-row" v-if="summarytypeshow == 'cqc' || summarytypeshow == 'all'   ">
                                    <div class="cqcrwclable"> CQC (This Project) - Detailed List </div>
                                </el-row>
                                <el-row style="padding-left:34px;" v-if="summarytypeshow == 'cqc' || summarytypeshow == 'all'">
                                    <el-col :span="22">
                                        <el-table  :data="getdetailform(scope.row.cqc)" class="fullwidth" :border="true">

                                            <el-table-column prop="cqclibrary.cqcname" label="CQC Name" :resizable="true" min-width="130" width="390"></el-table-column>

                                            <el-table-column prop="cqclibrary.termsofuse" label="Terms of Use" :resizable="true" min-width="60" width="100"></el-table-column>

                                            <el-table-column prop="cqclibrary.searchtype" label="Type" :resizable="true" min-width="40" width="80"></el-table-column>

                                            <el-table-column prop="cqclibrary.owner.loginname" label="Owner" :resizable="true" min-width="40" width="80"></el-table-column>

                                            <el-table-column label="Tolerance" prop="status" :resizable="true" min-width="80" width="80" align="center">
                                                <template slot-scope="scope">
                                                    <div :class="scope.row.status"> {{ scope.row.status }} </div>
                                                </template>
                                            </el-table-column>

                                            <el-table-column prop="comment" label="Comment" :resizable="true" width="250">
                                                <template slot-scope="scopecomment">
                                                    <el-input :disabled="!editpermission" v-model="scopecomment.row.comment" :id="'analysis_cqcrwcsummary_cqc_project_comment'+scope.$index+scopecomment.$index" readonly></el-input>
                                                </template>
                                            </el-table-column>
                                        </el-table>
                                    </el-col>
                                    <el-col :span="2">
                                        <div v-if="getdetailform(scope.row.cqc).length != 0">
                                        <el-button style="float:right" size="mini" type="primary" icon="el-icon-view" @click="opencqcreport(scope.row,'cqcreport',scope.$index)"></el-button>
                                        </div>
                                    </el-col>
                                </el-row>

                                <el-row class="dark-gray-row" v-if="summarytypeshow == 'rwc' || summarytypeshow == 'all'">
                                    <div class="cqcrwclable">RWC Matches - Detailed List</div>
                                </el-row>

                                <el-row style="padding-left:68px;" v-if="summarytypeshow == 'rwc' || summarytypeshow == 'all'|| summarytypeshow == 'all'">
                                    <el-col :span="22">
                                        <el-table ref="cqcrwcdata" :data="scope.row.rwc.details" class="fullwidth" :border="true">
                                            <el-table-column prop="cqclibrary" :label="'Section' + '\xa0\xa0\xa0\xa0' + 'RWC Name'" :resizable="true" min-width="250" width="330">
                                                <template slot-scope="d">
                                                    {{ getnrmname(d.row) }}
                                                   
                                                </template>
                                            </el-table-column>

                                            <el-table-column prop="searchtype" label="Type" :resizable="true" min-width="40" width="80">
                                                <template slot-scope="scope">
                                                    {{scope.row.comparesearch.searchtype}}
                                                </template>
                                            </el-table-column>

                                            <el-table-column label="Owner" :resizable="true" min-width="40" width="80">
                                                <template slot-scope="scope">
                                                    <div v-if="scope.row.comparesearch.owner && scope.row.comparesearch.owner.loginname">
                                                     {{scope.row.comparesearch.owner.loginname}}
                                                   </div>
                                                </template>
                                            </el-table-column>

                                            <el-table-column prop="status" label="Status" :resizable="true" align="center" width="80">
                                                <template slot-scope="scope">
                                                    <div :class="scope.row.result">
                                                        {{ scope.row.result }}
                                                    </div>
                                                </template>
                                            </el-table-column>

                                            <el-table-column prop="comment" label="Comment" :resizable="true" width="300">
                                                <template slot-scope="scopecomment">
                                                    <el-input :disabled="!editpermission" v-model="scopecomment.row.comment" :id="'analysis_cqcrwcsummary_rwc_comment'+scope.$index+scopecomment.$index" readonly></el-input>
                                                </template>
                                            </el-table-column>
                                        </el-table>
                                    </el-col>
                                     <el-col :span="2">
                                        <div v-if="scope.row.rwc.details && scope.row.rwc.details.length != 0">
                                        <el-button style="float:right" size="mini" type="primary" icon="el-icon-view" @click="opencqcreport(scope.row,'rwcreport',scope.$index)"></el-button>
                                        </div>
                                    </el-col>
                                </el-row>
                                  <el-row class="dark-gray-row" v-if="summarytypeshow == 'cqccompar' || summarytypeshow == 'all'">
                                    <div class="cqcrwclable"> CQC (Comparative) - Detailed List </div>
                                </el-row>

                                <el-row style="padding-left:34px;" v-if="summarytypeshow == 'cqccompar' || summarytypeshow == 'all'">
                                        <el-table ref="cqcrwcdata" :data="scope.row.comparecqc.pcqclist" class="fullwidth" :border="true">

                                            <el-table-column prop="cqclibrary.cqcname" label="CQC Name" :resizable="true" min-width="130" width="390"></el-table-column>

                                            <el-table-column prop="cqclibrary.termsofuse" label="Terms of Use" :resizable="true" min-width="60" width="100"></el-table-column>
        
                                            

                                            <el-table-column prop="cqclibrary.searchtype" label="Type" :resizable="true" min-width="40" width="80"></el-table-column>

                                            <el-table-column prop="cqclibrary.owner.loginname" label="Owner" :resizable="true" min-width="40" width="80"></el-table-column>

                                            <el-table-column label="Tolerance" prop="status" :resizable="true" min-width="80" width="80" align="center">
                                                <template slot-scope="scope">
                                                    <div :class="scope.row.status"> {{ scope.row.status }} </div>
                                                </template>
                                            </el-table-column>

                                            <el-table-column prop="comment" label="Comment" :resizable="true" width="250">
                                                <template slot-scope="scopecomment">
                                                    <el-input :disabled="!editpermission" v-model="scopecomment.row.comment" :id="'analysis_cqcrwcsummary_cqc_comparative_comment'+scope.$index+scopecomment.$index" readonly></el-input>
                                                </template>
                                            </el-table-column>
                                        </el-table>
                                </el-row> 
                              </div>
                            </template>
                        </el-table-column>

                        <el-table-column prop="sectiondisplayname" label="NRM Name" :resizable="true" :width="colwidth.sectionwidth">
                        </el-table-column>

                    </el-table-column>

                    <el-table-column label="cs" width="10"> </el-table-column>
                
                    <el-table-column  label="CQC (This Project)" v-if="summarytypeshow == 'cqc' || summarytypeshow == 'all'"> 
                      <el-table-column label="Project Signed Off By:">
                        <el-table-column prop="cqcsignoffby" label="By" :resizable="true" :width="colwidth.bywidth">
                        </el-table-column>

                        <el-table-column prop="cqcsignoffon" label="Time" :resizable="true" width="140">
                            <template slot-scope="scope">
                                {{ signoftimechange(scope.row,'cqc') }}
                            </template>
                        </el-table-column>

                        <el-table-column prop="signoff.signoffon" label="Changed" :resizable="true" width="80">
                            <template slot-scope="scope">
                                {{ scope.row.cqcchange == false ? "" : "Yes" }}
                            </template>
                        </el-table-column>
                     </el-table-column>
                        <!-- <el-table-column prop="u.lastlogin" label="Time" :resizable="true" width="200">
                        </el-table-column> -->
                   
                    <!-- <template slot-scope="u">
                                <div class="flex">
                                    <div class="flexcontent">
                                        <el-input v-model="u.row.expectedin"></el-input>
                                    </div>
                                </div>
                        </template> -->
                    <!-- <el-table-column label="cs" width="10"> </el-table-column> -->

                        <el-table-column label="Required Checks" >
                            <el-table-column prop="requiredin" label="In" width="70">
                                <template slot-scope="scope">
                                    <span v-if="scope.row.incheckcount.requiredin != 0">
                                        {{ scope.row.incheckcount.requiredin }}
                                    </span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="requiredcheck" label="Check" width="70">
                                <template slot-scope="scope">
                                    <span v-if="scope.row.incheckcount.requiredcheck != 0">
                                        {{ scope.row.incheckcount.requiredcheck }}
                                    </span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="requiredout" width="70" label="Out">
                                <template slot-scope="scope">
                                    <span v-if="scope.row.incheckcount.requiredout != 0">
                                        {{ scope.row.incheckcount.requiredout }}
                                    </span>
                                </template>
                            </el-table-column>
                        </el-table-column>
                        <el-table-column label="Expected Checks">
                            <el-table-column prop="expectedin"  label="In" width="70">
                                <template slot-scope="scope">
                                    <span v-if="scope.row.incheckcount.expectedin != 0">
                                        {{ scope.row.incheckcount.expectedin }}
                                    </span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="expectedcheck"  label="Check" width="70">
                                <template slot-scope="scope">
                                    <span v-if="scope.row.incheckcount.expectedcheck != 0">
                                        {{ scope.row.incheckcount.expectedcheck }}
                                    </span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="expectedout" width="70" label="Out">
                                <template slot-scope="scope">
                                    <span v-if="scope.row.incheckcount.expectedout != 0">
                                        {{ scope.row.incheckcount.expectedout }}
                                    </span>
                                </template>
                            </el-table-column>
                        </el-table-column>
                    </el-table-column>
                    <el-table-column label="cs" width="10"> </el-table-column>
                    <el-table-column label="RWC (This Project)" width="100" v-if="summarytypeshow == 'rwc' || summarytypeshow == 'all'">
                     <el-table-column label="Project Signed Off By:">
                         <el-table-column prop="rwcsignoffby" label="By" :resizable="true" :width="colwidth.bywidth">
                        </el-table-column>

                       <el-table-column prop="rwcsignoffon" label="Time" :resizable="true" width="140">
                            <template slot-scope="scope">
                                {{ signoftimechange(scope.row,'rwc') }}
                            </template>
                        </el-table-column>

                        <el-table-column prop="signoff.signoffon" label="Changed" :resizable="true" width="100">
                            <template slot-scope="scope">
                                {{ scope.row.rwcchange == false ? "" : "Yes" }}
                            </template>
                        </el-table-column>
                     </el-table-column>
                        <el-table-column label="Required Checks">
                            <el-table-column prop="requiredchecksyes"  label="Yes" width="75">
                                <template slot-scope="scope">
                                    <span v-if="scope.row.incheckcount.requiredchecksyes != 0">
                                        {{ scope.row.incheckcount.requiredchecksyes }}
                                    </span>
                                </template>
                            </el-table-column>

                            <el-table-column prop="requiredchecksno"  label="No" width="75">
                                <template slot-scope="scope">
                                    <span v-if="scope.row.incheckcount.requiredchecksno != 0">
                                        {{ scope.row.incheckcount.requiredchecksno }}
                                    </span>
                                </template>
                            </el-table-column>
                        </el-table-column>

                        <el-table-column label="Expected Checks" width="100">
                            <el-table-column prop="expectedchecksyes" label="Yes" width="75">
                                <template slot-scope="scope">
                                    <span v-if="scope.row.incheckcount.expectedchecksyes != 0">
                                        {{ scope.row.incheckcount.expectedchecksyes }}
                                    </span>
                                </template>
                            </el-table-column>

                            <el-table-column prop="expectedchecksno" label="No" width="75">
                                <template slot-scope="scope">
                                    <span v-if="scope.row.incheckcount.expectedchecksno != 0">
                                        {{ scope.row.incheckcount.expectedchecksno }}
                                    </span>
                                </template>
                            </el-table-column>
                        </el-table-column>
                    </el-table-column>
                     <el-table-column label="cs" width="10"> </el-table-column>
                
                    <el-table-column  label="CQC (Comparative)" v-if="summarytypeshow == 'cqccompar' || summarytypeshow == 'all'"> 
                      <el-table-column label="Project Signed Off By:">
                        <el-table-column prop="cqccomparesignoffby" label="By" :resizable="true" :width="colwidth.bywidth">
                        </el-table-column>

                         <el-table-column prop="cqccomparesignoffon" label="Time" :resizable="true" width="140">
                            <template slot-scope="scope">
                                {{ signoftimechange(scope.row,'cqccom') }}
                            </template>
                        </el-table-column>

                       <el-table-column prop="signoff.signoffon" label="Changed" :resizable="true" width="80">
                            <template slot-scope="scope">
                                {{ scope.row.cqccomparchange == false ? "" : "Yes" }}
                            </template>
                        </el-table-column>
                     </el-table-column>
                        <el-table-column label="Required Checks" >
                            <el-table-column prop="requiredin" label="In" width="70">
                                <template slot-scope="scope">
                                    <span v-if="scope.row.incheckcountcompare.requiredin != 0">
                                        {{ scope.row.incheckcountcompare.requiredin }}
                                    </span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="requiredcheck" label="Check" width="70">
                                <template slot-scope="scope">
                                    <span v-if="scope.row.incheckcountcompare.requiredcheck != 0">
                                        {{ scope.row.incheckcountcompare.requiredcheck }}
                                    </span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="requiredout" width="70" label="Out">
                                <template slot-scope="scope">
                                    <span v-if="scope.row.incheckcountcompare.requiredout != 0">
                                        {{ scope.row.incheckcountcompare.requiredout }}
                                    </span>
                                </template>
                            </el-table-column>
                        </el-table-column>
                        <el-table-column label="Expected Checks">
                            <el-table-column prop="expectedin"  label="In" width="70">
                                <template slot-scope="scope">
                                    <span v-if="scope.row.incheckcountcompare.expectedin != 0">
                                        {{ scope.row.incheckcountcompare.expectedin }}
                                    </span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="expectedcheck"  label="Check" width="70">
                                <template slot-scope="scope">
                                    <span v-if="scope.row.incheckcountcompare.expectedcheck != 0">
                                        {{ scope.row.incheckcountcompare.expectedcheck }}
                                    </span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="expectedout" width="70" label="Out">
                                <template slot-scope="scope">
                                    <span v-if="scope.row.incheckcountcompare.expectedout != 0">
                                        {{ scope.row.incheckcountcompare.expectedout }}
                                    </span>
                                </template>
                            </el-table-column>
                        </el-table-column>
                    </el-table-column>
                </el-table>
            </el-col>
        </el-row>
        <el-dialog  top="5vh" width="100%" hight="100%"  :visible.sync="dialoopenclose">
         <component  v-bind:is="reportname"></component>
        </el-dialog>


        <!-- <div v-if="isLoading" class="loading-indicator">
            <div class="progress-container">
                <div class="progress-wrapper">
                    <el-progress :percentage="percentage"></el-progress>
                    <div class="percentage-text">{{ percentage }}%</div>
                </div>
            </div>

            <div class="estimated-time">
                 {{ remainingTime }}
            </div>

            <div class="css-spinner"></div>
        </div> -->


        <!-- <div v-if="isLoading" class="loading-indicator">
            <div class="progress-container">
                <div class="progress-wrapper">
                    <div class="progress-bar">
                        <div class="progress" :style="{ width: percentage + '%' }"></div>
                    </div>
                    <div class="progress-details">
                        <div class="timeline-text">{{ remainingTime }}</div>
                        <div class="percentage-text">{{ percentage }}%</div>
                    </div>
                    <div class="spinner"></div>
                </div>
            </div>
        </div> -->

        <!-- <div v-if="isLoading" class="loading-indicator">
            <div class="progress-container">
                <div class="progress-details">
                    <div class="timeline-text">Loading &nbsp; {{ remainingTime }}</div>
                    <div class="percentage-text">{{ percentage }}%</div>
                </div>

                <div class="progress-wrapper">
                    <div class="el-icon-loading spinner"></div>
                    <div class="progress-bar">
                        <div class="progress" :style="{ width: percentage + '%' }"></div>
                    </div>
                </div>
            </div>
        </div> -->
    </div>
</template>

<script>
import cqcreport from "./projectcqc.vue";
import rwcreport from "./projectcs.vue";
import selectpart from "./selectpart.vue";
import moment from "moment";
export default {
    data() {
        return {
            subtabeshow:false,
            summarytypeshow:'all',
            summarytypearr:[
                {name:'All',value:'all'},
                {name:'CQC (This Project)',value:'cqc'},
                {name:'RWC Matches (This Project)',value:'rwc'},
                {name:'CQC (Comparative)',value:'cqccompar'}
            ],
            dialoopenclose:false,
            reportname:false,
            colwidth: {},
            Similar: {
                projectPlaceholder: "",
                partPlaceholder: "",
                lastsavedshow: true,
            },
            projectpart: {},
            project: {},
            detail: false,
            cqcrwcdata: [],
            users:[],
            rowindex:null,

            // isLoading: false,
            percentage: 0,
            remainingTime: "",
        };
    },

    methods: {
        expandchangerow(expanded)
        {
              if(expanded.rwc == undefined){
              this.subtabeshow = false;
              this.project.section = expanded.section; 
              this.$http
            .post("project/GetCqcRwcReport", this.project)
            .then((response) => {
                expanded.cqcnrm = response.data.cqcnrm;
                expanded.cqc = response.data.cqc;
                expanded.comparecqc = response.data.comparecqc;
                expanded.rwc = response.data.rwc;
                 this.getInOutCqc(expanded);
                this.subtabeshow = true;
            })
            .catch((err) => {
                this.$bus.$emit("error", err);
                this.hideloading();
            });
           }else{
            this.subtabeshow = true;
           }
        },
        summarytypechange(data){
           this.summarytypeshow = "rwc";
           this.$nextTick(()=>{
             this.summarytypeshow = data; 
            })
        },
        opencqcreport(row,name,index){
            this.rowindex = index;
            this.dialoopenclose = true;
            this.reportname = name;
            // this.$store.state.sectiondisplay = row.sections.sectiondisplay
            this.$store.state.projectids = {projectid:this.project.id,partid:this.projectpart.id,sectionid:row.sections.id};
             var data = this.$store.state.projectids;
             this.$bus.$emit("addproject", data);
        },
        getcellstyle: function (row, column, rowIndex, columnIndex) {
            if (row.column.label == "cs") {
                return {"font-weight": "bold",
                        "border-top-style": "none",
                        "border-bottom-style": "none", };
            }

            if ( row.column.label == "No" &&
                 row.row.requiredchecksno != 0 &&
                 row.columnIndex == 17 ) {
                return {"font-weight": "bold",
                         color: "red", 
                         "background-color": "rgb(250, 204, 200)", 
                         "text-align": "center", };
            }

            if ( row.column.label == "No" &&
                 row.row.expectedchecksno != 0 &&
                 row.columnIndex == 19 ) {
                return {"font-weight": "bold", 
                        color: "red", 
                        "background-color": "rgb(210, 237, 248)", 
                        "text-align": "center", };
            }

            if (row.column.label == "NRM Name") {
                return;
            }
            return { "text-align": "center" };
        },

        getRowClass({ row, column, rowIndex, columnIndex }) {
            if (column.label === "cs") {
                return { color: "white",
                         background: "white",
                        "border-top-style": "none",
                        "border-bottom-style": "none", };
            }

            if (rowIndex == 0) {
                return { color: "white",
                         "background-color": "rgb(105, 104, 104)",
                         "font-weight": "bold", 
                         "text-align": "center", };
            }

            if (rowIndex == 1) {
                return { "vertical-align": "text-top",
                         "text-align": "center", };
            }
            if ( column.label === "Out") {
                return { color: "white",
                         background: "#800000", 
                        "font-weight": "bold",
                        "text-align": "center", };
            }
            if (column.label === "No" ) {
                return { color: "white",
                         background: "rgb(255, 168, 147)",  
                         "font-weight": "bold", 
                         "text-align": "center", };
            }
            if (column.label === "Check") {
                return { color: "white",
                         background: "orange",
                        "font-weight": "bold",
                        "text-align": "center",
                         "font-size": "100%",};
            }
             if (column.label === "In" || column.label === "Yes") {
                return { color: "white",
                         background: "rgb(198, 224, 180)", 
                         "font-weight": "bold",
                         "text-align": "center",
                         "font-size": "100%",};
            }
            
                return { "text-align": "center", };
           
        },
        getInOutCqc(crdt){
            var ownericorows = {
                rowinmin: "tolerenceinab",
                rowinmax: "tolerencecheckab",
                rowcheckmin: "tolerenceinba",
                rowcheckmax: "tolerencecheckba",
                rowstatus: "ownerstatus",
                rowclass: "ownerclass",
                rowaction: "owneraction",
                rowstyle: "ownerstyle",
            }

            var pspicorows = {
                rowinmin: "pspinmin",
                rowinmax: "pspinmax",
                rowcheckmin: "pspcheckmin",
                rowcheckmax: "pspcheckmax",
                rowstatus: "pspstatus",
                rowclass: "pspclass",
                rowaction: "pspaction",
                rowstyle: "pspstyle",
            }

           
                    if(crdt.cqc && crdt.cqc.pcqclist != null){
                     crdt.cqc.pcqclist.filter((c) => {
                           c.cqclibrary.inmedian = (c.cqclibrary.tolerenceinab + c.cqclibrary.tolerencecheckab) / 2;
                          c.cqclibrary.checkmedian = (c.cqclibrary.tolerenceinba + c.cqclibrary.tolerencecheckba) / 2;
                          c.cqclibrary.inrange = c.cqclibrary.tolerencecheckab - c.cqclibrary.tolerenceinab;
                          c.cqclibrary.checkrange = c.cqclibrary.tolerencecheckba - c.cqclibrary.tolerenceinba;

                           this.getincheckoutrows(c, ownericorows);

                      
                               var isstru = false; 
                         
                              if (c.totalab != null && c.totalab.length != 0 && c.ischeck == undefined) {
                                  c.ischeck = true;
                                  var set = c.totalab;
                                  let cmpmean = c.totalab.reduce((a, b)=>{ return a + b }, 0) / c.totalab.length;
                                  c.totalab = c.totalab.map((k)=>{ return (k - cmpmean) ** 2});
                                  let squaresum = c.totalab.reduce((a, b)=> a + b, 0);
                                  let variance = squaresum / c.totalab.length;
                                  let sd = Math.sqrt(variance);

                                  c.cqclibrary.pspmean = cmpmean;
                                  c.cqclibrary.pspfullmax = Math.max(...set);
                                  c.cqclibrary.pspfullmin = Math.min(...set);
                                  c.cqclibrary.pspfullrange = c.cqclibrary.pspfullmax - c.cqclibrary.pspfullmin;
                                  c.cqclibrary.pspinmax = cmpmean + sd;
                                  c.cqclibrary.pspinmin = cmpmean - sd;
                                  c.cqclibrary.pspinrange = c.cqclibrary.pspinmax - c.cqclibrary.pspinmin;
                                  c.cqclibrary.pspcheckmax = c.cqclibrary.pspinmax + sd;
                                  c.cqclibrary.pspcheckmin = c.cqclibrary.pspinmin - sd;
                                  c.cqclibrary.pspcheckrange = c.cqclibrary.pspcheckmax - c.cqclibrary.pspcheckmin;
                                  isstru = true;
                                 

                                   this.getincheckoutrows(c, pspicorows);
                              }else{
                                  c.cqclibrary.pspmean = 0;
                                  c.cqclibrary.pspfullmax = 0;
                                  c.cqclibrary.pspfullmin = 0;
                                  c.cqclibrary.pspfullrange = 0;
                                  c.cqclibrary.pspinmax = 0;
                                  c.cqclibrary.pspinmin = 0;
                                  c.cqclibrary.pspinrange = 0;
                                  c.cqclibrary.pspcheckmax = 0;
                                  c.cqclibrary.pspcheckmin = 0;
                                  c.cqclibrary.pspcheckrange = 0;
                                  isstru = false;
                                 
                              }
                              if(isstru == true){
                                  if (c.cqclibrary.ownerstatus == "In" && c.cqclibrary.pspstatus == "In") {
                                      c.status = "In";
                                  } else if (c.cqclibrary.ownerstatus == "In" && c.cqclibrary.pspstatus == "Check") {
                                      c.status = "Check";
                                  } else if (c.cqclibrary.ownerstatus == "In" && c.cqclibrary.pspstatus == "Out") {
                                      c.status = "Out";
                                  } else if (c.cqclibrary.ownerstatus == "Check" && c.cqclibrary.pspstatus == "In") {
                                      c.status = "Check";
                                  } else if (c.cqclibrary.ownerstatus == "Check" && c.cqclibrary.pspstatus == "Check") {
                                      c.status = "Check";
                                  } else if (c.cqclibrary.ownerstatus == "Check" && c.cqclibrary.pspstatus == "Out") {
                                      c.status = "Check";
                                  } else if (c.cqclibrary.ownerstatus == "Out" && c.cqclibrary.pspstatus == "In") {
                                      c.status = "Out";
                                  } else if (c.cqclibrary.ownerstatus == "Out" && c.cqclibrary.pspstatus == "Check") {
                                      c.status = "Out";
                                  } else if (c.cqclibrary.ownerstatus == "Out" && c.cqclibrary.pspstatus == "Out") {
                                      c.status = "Out";
                                  } else {
                                      c.status = "In";
                                  }
                               }else{
                                   c.status = c.cqclibrary.ownerstatus;
                               }
                     });
                    crdt.incheckcount.requiredin = crdt.cqc.pcqclist.filter(c => c.status == "In" && c.cqclibrary.searchtype == "Required").length;
                    crdt.incheckcount.requiredcheck = crdt.cqc.pcqclist.filter(c => c.status == "Check" && c.cqclibrary.searchtype == "Required").length;
                    crdt.incheckcount.requiredout = crdt.cqc.pcqclist.filter(c => c.status == "Out" && c.cqclibrary.searchtype == "Required").length;

                    crdt.incheckcount.expectedin = crdt.cqc.pcqclist.filter(c => c.status == "In" && c.cqclibrary.searchtype == "Expected").length;
                    crdt.incheckcount.expectedcheck = crdt.cqc.pcqclist.filter(c => c.status == "Check" && c.cqclibrary.searchtype == "Expected").length;
                    crdt.incheckcount.expectedout = crdt.cqc.pcqclist.filter(c => c.status == "Out" && c.cqclibrary.searchtype == "Expected").length;
                    }
                    if(crdt.comparecqc && crdt.comparecqc.pcqclist != null){
                       crdt.comparecqc.pcqclist.filter((c) => {
                          c.cqclibrary.inmedian = (c.cqclibrary.tolerenceinab + c.cqclibrary.tolerencecheckab) / 2;
                          c.cqclibrary.checkmedian = (c.cqclibrary.tolerenceinba + c.cqclibrary.tolerencecheckba) / 2;
                          c.cqclibrary.inrange = c.cqclibrary.tolerencecheckab - c.cqclibrary.tolerenceinab;
                          c.cqclibrary.checkrange = c.cqclibrary.tolerencecheckba - c.cqclibrary.tolerenceinba;

                       

                          if (this.getcqcdata == true) {
                              if (c.totalab != null && c.totalab.length != 0 && c.ischeck == undefined) {
                                  c.ischeck = true;
                                  var set = c.totalab;
                                  let cmpmean = c.totalab.reduce((a, b)=>{ return a + b }, 0) / c.totalab.length;
                                  c.totalab = c.totalab.map((k)=>{ return (k - cmpmean) ** 2});
                                  let squaresum = c.totalab.reduce((a, b)=> a + b, 0);
                                  let variance = squaresum / c.totalab.length;
                                  let sd = Math.sqrt(variance);

                                  c.cqclibrary.pspmean = cmpmean;
                                  c.cqclibrary.pspfullmax = Math.max(...set);
                                  c.cqclibrary.pspfullmin = Math.min(...set);
                                  c.cqclibrary.pspfullrange = c.cqclibrary.pspfullmax - c.cqclibrary.pspfullmin;
                                  c.cqclibrary.pspinmax = cmpmean + sd;
                                  c.cqclibrary.pspinmin = cmpmean - sd;
                                  c.cqclibrary.pspinrange = c.cqclibrary.pspinmax - c.cqclibrary.pspinmin;
                                  c.cqclibrary.pspcheckmax = c.cqclibrary.pspinmax + sd;
                                  c.cqclibrary.pspcheckmin = c.cqclibrary.pspinmin - sd;
                                  c.cqclibrary.pspcheckrange = c.cqclibrary.pspcheckmax - c.cqclibrary.pspcheckmin;
                                 
                                 

                                  // this.getincheckoutrows(c, pspicorows);
                              }else{
                                  c.cqclibrary.pspmean = 0;
                                  c.cqclibrary.pspfullmax = 0;
                                  c.cqclibrary.pspfullmin = 0;
                                  c.cqclibrary.pspfullrange = 0;
                                  c.cqclibrary.pspinmax = 0;
                                  c.cqclibrary.pspinmin = 0;
                                  c.cqclibrary.pspinrange = 0;
                                  c.cqclibrary.pspcheckmax = 0;
                                  c.cqclibrary.pspcheckmin = 0;
                                  c.cqclibrary.pspcheckrange = 0;
                                 
                                 
                              }
                          }
                          if (c.cqclibrary.ownerstatus == "In" && c.cqclibrary.pspstatus == "In") {
                           c.status = "In";
                          } else if (c.cqclibrary.ownerstatus == "In" && c.cqclibrary.pspstatus == "Check") {
                              c.status = "Check";
                          } else if (c.cqclibrary.ownerstatus == "In" && c.cqclibrary.pspstatus == "Out") {
                              c.status = "Out";
                          } else if (c.cqclibrary.ownerstatus == "Check" && c.cqclibrary.pspstatus == "In") {
                              c.status = "Check";
                          } else if (c.cqclibrary.ownerstatus == "Check" && c.cqclibrary.pspstatus == "Check") {
                              c.status = "Check";
                          } else if (c.cqclibrary.ownerstatus == "Check" && c.cqclibrary.pspstatus == "Out") {
                              c.status = "Check";
                          } else if (c.cqclibrary.ownerstatus == "Out" && c.cqclibrary.pspstatus == "In") {
                              c.status = "Out";
                          } else if (c.cqclibrary.ownerstatus == "Out" && c.cqclibrary.pspstatus == "Check") {
                              c.status = "Out";
                          } else if (c.cqclibrary.ownerstatus == "Out" && c.cqclibrary.pspstatus == "Out") {
                              c.status = "Out";
                          } else {
                              c.status = "In";
                          }
                     });
                    crdt.incheckcountcompare.requiredin = crdt.comparecqc.pcqclist.filter(c => c.status == "In" && c.cqclibrary.searchtype == "Required").length;
                    crdt.incheckcountcompare.requiredcheck = crdt.comparecqc.pcqclist.filter(c => c.status == "Check" && c.cqclibrary.searchtype == "Required").length;
                    crdt.incheckcountcompare.requiredout = crdt.comparecqc.pcqclist.filter(c => c.status == "Out" && c.cqclibrary.searchtype == "Required").length;

                    crdt.incheckcountcompare.expectedin = crdt.comparecqc.pcqclist.filter(c => c.status == "In" && c.cqclibrary.searchtype == "Expected").length;
                    crdt.incheckcountcompare.expectedcheck = crdt.comparecqc.pcqclist.filter(c => c.status == "Check" && c.cqclibrary.searchtype == "Expected").length;
                    crdt.incheckcountcompare.expectedout = crdt.comparecqc.pcqclist.filter(c => c.status == "Out" && c.cqclibrary.searchtype == "Expected").length;
                    }
                
        },
        getsummary() {
            // this.showloading();
            this.getusers();
            // var data = { id: this.project.id, projectpart: this.projectpart };
            this.project.projectpart = this.projectpart;

            this.$store.state.isLoading = true;
            
            this.$http
            .post("project/GetCqcRwcSummary", this.project)
            .then((response) => {
                this.cqcrwcdata = response.data;
                // this.getInOutCqc();
                this.cqcrwcdata.sort(function (a, b) {
                    return ("" + a.sectiondisplayname).localeCompare( b.sectiondisplayname);
                });
                this.detail = true;
                this.columnwidth();

                // this.percentage = 100;
                // this.remainingTime = "0 seconds";
                this.$store.state.isLoading = false;

                // this.hideloading();
            })
            .catch((err) => {
                this.$bus.$emit("error", err);

                this.$store.state.isLoading = false;
                // this.hideloading();
            });

            // let interval = setInterval(() => {
            //     if (this.percentage < 100) {
            //         this.percentage += 10; // You can adjust the increment value based on your preference
            //         // Calculate remaining time dynamically based on your logic
            //         this.remainingTime = this.calculateRemainingTime(); // Implement your logic here
            //     } else {
            //         this.percentage = 0;
            //         clearInterval(interval);
            //     }
            // }, 800);
        },

        getusers(){
            this.$http.get("account/getallusers")
            .then((response) => {
                this.users = response.data //JSON.parse(response.data);
            })
            .catch((err) => {
                this.$bus.$emit("error", err);
            })
        },

        projectpartchange: function (Projectobj) {
            this.project = Projectobj.project;
            this.projectpart = Projectobj.part;
            this.refresh();
        },

        refresh: function () {
            if (this.project.id && this.projectpart.id != null) {
                this.getsummary();
            } else {
                this.detail = false;
            }
        },

        columnwidth: function () {
            var maxwidthsection = 0;
            var sectionstr = "";
            var maxwidthby = 0;
            var bystr = "";
            for (var i = 0; i < this.cqcrwcdata.length; i++) {
                var row = this.cqcrwcdata[i];
                var section = row.sectiondisplayname;
                var by = row.cqcsignoffby == null ? "" : row.cqcsignoffby;
                if (section && section.length != 0) {
                    if (section.length > maxwidthsection) {
                        maxwidthsection = section.length;
                        sectionstr = section;
                    }
                }
                if (by.length > maxwidthby) {
                    maxwidthby = by.length;
                    bystr = by;
                }
            }

            if (maxwidthsection != 0) {
                var size = this.measureTextWidth(sectionstr, 14);
                this.colwidth.sectionwidth = size;
            }
            if (maxwidthby != 0) {
                var size = this.measureTextWidth(bystr, 30);
                this.colwidth.bywidth = size;
            } else {
                this.colwidth.bywidth = 70;
            }
        },
        getincheckoutrows: function(c, icorow){
            var sumab = c.totala/c.totalb;
            if (!isFinite(sumab)) {
                sumab = 0;
            }
              sumab = parseFloat(sumab.toFixed(3));
           
            if (sumab >= c.cqclibrary[icorow.rowinmin] && sumab <= c.cqclibrary[icorow.rowinmax]) {
                c.cqclibrary[icorow.rowstatus] = "In";
                c.cqclibrary[icorow.rowclass] = "tag-in";
                c.cqclibrary[icorow.rowaction] = "Within Owners Set Tolerances";
                c.cqclibrary[icorow.rowstyle] = "color:#00bd00; padding-left: 5px;";
            } else {
                c.cqclibrary[icorow.rowstatus] = "";
            }
            if ((sumab < c.cqclibrary[icorow.rowinmin] && sumab > c.cqclibrary[icorow.rowinmax])
            && (sumab >= c.cqclibrary[icorow.rowcheckmin] && sumab <= c.cqclibrary[icorow.rowcheckmax])) {
                c.cqclibrary[icorow.rowstatus] = "Check";
                c.cqclibrary[icorow.rowclass] = "tag-check";
                c.cqclibrary[icorow.rowaction] = "Owner Proposes that the Result is Checked";
                c.cqclibrary[icorow.rowstyle] = "color:#ffbd07; padding-left: 5px;";
            }
            if (sumab < c.cqclibrary[icorow.rowcheckmin] && sumab > c.cqclibrary[icorow.rowcheckmax]) {
                c.cqclibrary[icorow.rowstatus] = "Out";
                c.cqclibrary[icorow.rowclass]= "tag-out";
                c.cqclibrary[icorow.rowaction] = "The Result Falls Outside 'Check' Tolerances";
                c.cqclibrary[icorow.rowstyle] = "color:rgb(128, 0, 0); padding-left: 5px;";
                //#ff0505
            }

        },

        calculateRemainingTime() {
            
            const totalDuration = 300;

            const remainingTimeInSeconds = (totalDuration * (100 - this.percentage)) / 100;

            const minutes = Math.floor(remainingTimeInSeconds / 60);
            const seconds = Math.floor(remainingTimeInSeconds % 60);

            if (minutes > 0) {
                return `${minutes} minute${minutes > 1 ? 's' : ''} ${seconds} second${seconds > 1 ? 's' : ''} left`;
            } else {
                return `${seconds} second${seconds > 1 ? 's' : ''} left`;
            }
        },
    },

    computed: {
        getcqcrwcdata:function(){
            if(this.cqcrwcdata.length == 0){
                return [];
            }
            return this.cqcrwcdata;
        },
        getdetailform: function () {
            return function (data) {
                if (!data || !data.pcqclist) {
                    return [];
                }
                return data.pcqclist;
            };
        },
        getnrmname: function () {
            return function (row) {
                return (
                    row.comparesearch.nrmsection + "  " + row.comparesearch.description
                );
            };
        },

        signoftimechange: function () {
            return function (row,name) {
                var date1 = null;
                if(name == 'cqc' && row.cqcsignoffon != null){
                    date1 = new Date(row.cqcsignoffon);
                }
                 if(name == 'rwc' && row.rwcsignoffon != null){
                    date1 = new Date(row.rwcsignoffon);
                } 
                 if(name == 'cqccom' && row.cqccomparesignoffon != null){
                    date1 = new Date(row.cqccommpesignoffon);
                } 
                if(date1 != null){
                    return ( moment(date1).format("DD-MMM-YYYY") + " (" + moment(date1).format("hh:mm") + ")" );
                }else{
                    return "";
                }
            };
        },
    },

    components: {
        selectpart: selectpart,
        cqcreport:cqcreport,
        rwcreport:rwcreport,
    },
};
</script>

<style scoped>
.cqcrwclable {
  font-weight: 600;
  height: 20px;
  /* border: 1px solid rgb(105, 104, 104); */
  margin-top: 2px;
  margin-left: 5px;
}
.el-table .dark-gray-row {
  background: rgb(211, 211, 211);
  color: rgb(49, 42, 42);
  margin-left: 34px;
}
.Yes {
  font-weight: bold;
  color: white;
  background-color: rgb(198, 224, 180);
}
.No {
  font-weight: bold;
  color: white;
  background-color: rgb(255, 168, 147);
}
.rwcCheck {
  font-weight: bold;
  color: white;
  background-color: rgb(250, 220, 164);
}
    .In {
        font-weight: bold;
        color: white;
        /*background-color: green;*/
        background-color: rgb(198, 224, 180);
    }

.Check {
  font-weight: bold;
  color: white;
  background-color: orange;
}

    .Out {
        font-weight: bold;
        color: white;
        background-color: rgb(128, 0, 0); /*red*/
    }
.g {
  color: rgb(210, 237, 248);
  text-align: center;
}
.grayheader{
    color: "white";
     background-color: rgb(105, 104, 104);
      font-weight: bold; 
      text-align: center; 
}


.loading-indicator {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 90%);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.progress-container {
  width: 400px;
  /* background-color: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 20px; */
}

.progress-wrapper {
  display: flex;
  align-items: center;
}

.progress-bar {
  flex-grow: 1;
  height: 10px;
  /* background-color: #f0f0f0; */
  border-radius: 5px;
  overflow: hidden;
  box-shadow: inset 0px 1px 1px 1px rgb(0 0 0 / 50%), 1px 1px 0px 0px #ffffff40;
}

.progress {
  height: 100%;
  background-color: #29ace4;
  transition: width 0.3s ease;
  border: 1px solid black;
  border-radius: 10px;
}

.progress-details {
  display: flex;
  align-items: center;
  margin-left: 10px;
  justify-content: space-between;
}

.timeline-text {
  font-size: 14px;
  color: #29ace4;
  margin-left: 12px;
  /* margin-right: 10px; */
}

.percentage-text {
  font-size: 14px;
  color: #29ace4;
}

.spinner {
    color: #29ace4;
    font-weight: bold;
    margin-right: 5px;
    margin-top: 3px;
  /* width: 20px;
  height: 20px;
  border: 2px solid #29ace4;
  border-top-color: transparent;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-left: 10px; */
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
