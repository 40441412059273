<template>
    <div>
        <div class="fixdheader">
            <span class="headerstyle">Sorting Sheet</span>
            <div v-if="detail" class="floatright margintb1mm">
                <span class="padright5px">
                    <el-button type="success"
                               :disabled="!editpermission"
                               icon="el-icon-document"
                               @click="getBill()">Create Bill</el-button>
                </span>
                <span>
                    <el-button type="success"
                               :disabled="!editpermission"
                               icon="el-icon-circle-check"
                               @click="savesorting()">Save Sorting</el-button>
                </span>
            </div>
            <div>
                <projectselectpart :training="true" @projectsectionchange="projectsectionchange"></projectselectpart>
            </div>
            <hr class="hrstyle" />
        </div>

        <div v-if="detail" class="tablepaddinglr5 tabletexttop">
            <el-table :data="detailform"
                      class="fullwidth"
                      :stripe="true"
                      :height="height"
                      row-key="srno"
                      v-loading="loading">

                <el-table-column label="Sr"
                                 prop="srno"
                                 align="center"
                                 :width="columnwidth(detailform, 'srno')">
                </el-table-column>

                <el-table-column label="BQ Code"
                                 prop="bqcode"
                                 width="200">
                </el-table-column>

                <el-table-column label="Specification"
                                 prop="specification"
                                 width="200">
                </el-table-column>

                <el-table-column label="Position"
                                 prop="specpos"
                                 width="70">
                </el-table-column>

                <el-table-column label="Asterisk"
                                 prop="asterisk"
                                 width="200">
                </el-table-column>

                <el-table-column label="End Notes"
                                 prop="other"
                                 width="150">
                </el-table-column>

                <el-table-column label="Qty"
                                 prop="qty"
                                 width="70">
                                 <template slot-scope="scope">
                                    {{ getqty(scope.row)  }}
                                </template>
                </el-table-column>

                <el-table-column label="Unit"
                                 prop="unit"
                                 width="70">
                </el-table-column>


                <el-table-column label="NRM1 Ordering"
                                 prop="nrmsection"
                                 width="150">
                </el-table-column>

                <el-table-column label="MOM Section"
                                 prop="section"
                                 width="200">
                </el-table-column>

                <el-table-column label="Group"
                                 prop="group"
                                 width="200">
                </el-table-column>

                <el-table-column label="Item or work to be measured"
                                 prop="work" width="200"></el-table-column>

                <el-table-column label="Level 1"
                                 prop="level1"
                                 width="250">
                </el-table-column>

                <el-table-column label="Level 2"
                                 prop="level2"
                                 width="250">
                </el-table-column>

                <el-table-column label="Level 3"
                                 prop="level3"
                                 width="200">
                </el-table-column>

                <el-table-column label="Label"
                                 prop="label"
                                 width="100">
                </el-table-column>

                <el-table-column label="Errors" width="250">
                    <template slot-scope="er">
                        <ul v-for="e in er.row.errors"
                            :key="e" style="color:red">
                            <li>{{e}}</li>
                        </ul>
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>

<script>
    import projectselectpart from './projectselectpart.vue';
    export default {
        data() {
            return {
                isbill: false,
                issave: false,
                detail: false,
                project: { id: null, projectname: '', library: '' },
                detailform: [],
                sectionid: null,
                loading: false,
                changed: false
            }
        },

        components: {
            'projectselectpart': projectselectpart,
        },

        computed: {
            getqty(){
                return function(data){
                    if(data && data.qty != undefined){
                    return Math.ceil(data.qty);
                    }
                    return "";
                }
            },
            height: function () {
                return window.innerHeight - 180;
            }
        },

        methods: {
            columnwidth: function (details, column) {
                var maxwidth = 0;
                for (var i = 0; i < details.length; i++) {
                    var row = details[i];
                    var value = row[column].toString();
                    if (value && value.length != 0) {
                        if (value.length > maxwidth && value.length <= 40) {
                            maxwidth = value.length;
                            var str = value;
                        }
                        if (value.length > 40) {
                            maxwidth = 40;
                            var str = value.slice(0, 40);
                            break;
                        }
                    }
                }
                if (column == "srno" && maxwidth < 4) {
                    str = "srno";
                }
                if (maxwidth != 0) {
                    var size = this.measureTextWidth(str);
                    return size + 10;
                }
                else
                    return 0;
            },
            projectsectionchange: function (Projectobj) {
                this.sectionid = Projectobj.sectionid;
                this.project = Projectobj.project;
                this.refresh();
            },

            refresh: function () {
                if (this.project.id && this.sectionid) {
                    this.getProject();
                }
                else {
                    this.detail = false;
                }
            },

            getProject: function () {
                var data = {
                    id: this.project.id, section: { id: this.sectionid }
                };

                // this.loading = true;
                this.$store.state.isLoading = true;

                this.$http.post('project/getSortingSheet', data)
                    .then(response => {
                        var json = JSON.parse(response.data);
                        this.detailform = json;
                        this.attachments = [];
                        this.detail = true;
                        // this.loading = false;
                        this.$store.state.isLoading = false;
                        this.$nextTick(this.mymakesortable);
                        this.changed = false;
                    }).catch(err => {
                        // this.loading = false;
                        this.$store.state.isLoading = false;
                        this.$bus.$emit('error', err);
                    });
            },

            savesorting: function () {
                if (!this.issave) {
                    this.issave = true;
                    var srno = 1;
                    this.detailform.forEach((v, i) => { v.srno = srno++; });
                    this.$http.post('project/savesorting', this.detailform)
                        .then(response => {
                            this.issave = false;
                            this.saving = false;
                            this.$message({
                                showClose: true,
                                message: response.data, type: "success"
                            });
                            this.detail = false;
                            this.changed = false;
                            this.refresh();
                        }).catch(err => {
                            this.issave = false;
                            this.saving = false;
                            this.$bus.$emit('error', err);
                        });
                }
            },

            getBill: function () {
                if (!this.isbill) {
                    this.isbill = true;
                    var hasError = false;
                    this.detailform.forEach(function (ss, index) {
                        if (ss.errors && ss.errors.length > 0) {
                            hasError = true;
                        }
                    });
                    if (hasError) {
                        this.$alert("There are few errors in the last column Sorting Sheet you need to review.");
                    }
                    var data = {
                        id: this.project.id, projectpart: this.project.projectpart, section: { id: this.sectionid }
                    };

                    this.$http.post('project/getBill', data, { responseType: 'blob' })
                        .then(response => {
                            this.isbill = false;
                            var blob = response.data;
                            var fileName = this.project.projectname + ' - ' + this.project.section.sectiondisplay + '.xlsx';
                            var link = document.createElement('a');
                            link.href = window.URL.createObjectURL(blob);
                            link.download = fileName;
                            link.click();
                        }).catch(err => {
                            this.isbill = false;
                            this.$bus.$emit('error', err);
                        });
                }
            },

            mymakesortable: function () {
                if (this.editpermission) {
                    const table = document.querySelector('.el-table__body-wrapper tbody')
                    const self = this
                    this.$sortable.create(table, {
                        onEnd({ newIndex, oldIndex }) {
                            const targetRow = self.detailform.splice(oldIndex, 1)[0]
                            self.detailform.splice(newIndex, 0, targetRow)
                            self.changed = true;
                        }
                    })
                }
            },

            beforewindowunload: function (e) {
                if (this.changed) {
                    return "Do you really want to leave ? you have unsaved changes!";
                }
            },

            // searchProject: function (query, done) {
            //     this.$http.post('project/search?q=' + query, {})
            //         .then(response => {
            //             var templates = JSON.parse(response.data);
            //             done(templates)
            //         })
            //         .catch(err => {
            //             this.$bus.$emit('error', err);
            //         });
            // }
        },

        beforeRouteLeave(to, from, next) {
            var answer = true;
            if (this.changed) {
                var answer = window.confirm('Do you really want to leave? you have unsaved changes!');
            }
            if (answer == true) {
                next()
                window.onbeforeunload = null;
            } else {
                next(false)
            }
        },

        created: function () {
            window.onbeforeunload = this.beforewindowunload;
        },

        mounted: function () {
            this.$nextTick(() => {
                // Code that will run only after the
                // entire view has been rendered
            });
        }
    }
</script>
