<template>
    <div>
        <div class="fixdheader width935px">
            <el-row>
                <span class="headerstyle">Sign Off - Project</span>
                <span class="right">
                    <el-button type="info" icon="el-icon-back" class="marginleft4px"
                        @click="$router.go(-1)">Back</el-button>
                </span>
            </el-row>

            <div class="spaceBetween" />
            <div class="row">
                <div class="fixwidth">
                    <el-autocomplete popper-class="morewidth-autocomplete-project"
                        class="inline-input fullwidth bold padding2px" v-model="project.projectname"
                        :fetch-suggestions="searchProject" @select="selectProject" value-key="projectname"
                        placeholder="Please select Project">
                        <el-button slot="suffix" @click="closeproject" type="text" size="mini">&times;</el-button>
                        <template slot-scope="{ item }">
                            <span style="float: left">{{ item.projectname }}</span>
                            <span style="float: right">
                                {{
                                    item.accessedon | dateformat
                                }}
                            </span>
                        </template>
                    </el-autocomplete>
                </div>
            </div>
        </div>
        <hr class="hrstyle" />

        <div class=" container">
            <div v-if="detail" class="max-content-width-as-container border-left-SO border-right-SO border-bottom-SO">
                <el-row>
                    <el-row>
                        <hr class="hrspacebetween hrstyle" />
                        <hr class="hrspacebetween hrstyle" />
                        <el-col class="width20char ">
                            <div class="partname headerSO ">
                                Checking Actions
                            </div>
                            <hr class="hrspacebetween " />

                            <div class="col1c contenttext2">
                                Instructions
                            </div>
                            <hr class="hrspacebetween" />

                            <div class="col1c contenttext2 UR-Link" @click="routepushpage()">
                                Sign Off - Parts
                            </div>
                            <hr class="hrspacebetween " />

                            <div class="col1c contenttext2">
                                RWC / CQC Summary
                            </div>
                            <hr class="hrspacebetween " />

                            <div class="col1c contenttext2">
                                PD Investigation
                            </div>
                            <hr class="hrspacebetween " />

                            <div class="col1c headerSO">
                                Checks Complete
                            </div>
                            <hr class="hrspacebetween " />

                            <div class="col1c contenttext2">
                                Signed Off by
                            </div>
                            <hr class="hrspacebetween" />

                            <div class="col1c contenttext2">Date</div>
                            <hr class="hrspacebetween " />

                            <div class="col1c contenttext2">Time</div>
                            <hr class="hrspacebetween " />

                            <div class="col1c contenttext2">Version</div>
                            <hr class="hrspacebetween " />

                            <div class="col1c contenttext2">Update</div>
                            <hr class="hrspacebetween hrstyle" />
                            <hr class="hrspacebetween hrstyle" />

                            <div class="headerSO col1c">
                                Create New Version
                            </div>
                        </el-col>

                        <el-col class="width20char border-left-SO" v-for="(item, index) in tabledata" :key="item.pr.id">
                            <div class=" center">
                                <div class="partname headerSO">
                                    {{
                                        item.pr.name == null
                                        ? "Single Bill"
                                        : item.pr.name
                                    }}
                                </div>
                                <hr class="hrspacebetween " />

                                <div class="col1c">
                                    <el-checkbox :id="'analysis_signoff_project_instructions_' + index"
                                        :disabled="disabledchack(item)" v-model="item.instructions" @change="changed(item)">
                                    </el-checkbox>
                                </div>
                                <hr class="hrspacebetween " />

                                <div class="col1c">
                                    <el-checkbox :id="'analysis_signoff_project_signoffsummary_' + index"
                                        :disabled="disabledchack(item)" v-model="item.signoffsummary"
                                        @change="changed(item)">
                                    </el-checkbox>
                                </div>
                                <hr class="hrspacebetween " />

                                <div class="col1c">
                                    <el-checkbox :id="'analysis_signoff_project_cqcrwcsummary_' + index"
                                        :disabled="disabledchack(item)" v-model="item.cqcrwcsummary"
                                        @change="changed(item)">
                                    </el-checkbox>
                                </div>
                                <hr class="hrspacebetween " />

                                <div class="col1c">
                                    <el-checkbox :id="'analysis_signoff_project_pdinvestigation_' + index"
                                        :disabled="disabledchack(item)" v-model="item.pdinvestigation"
                                        @change="changed(item)">
                                    </el-checkbox>
                                </div>
                                <hr class="hrspacebetween " />

                                <div class="col1c">
                                    <el-checkbox :id="'analysis_signoff_project_checkscomplete_' + index"
                                        :disabled="disabledchack(item)" v-model="item.checkscomplete"
                                        @change="allchack(item)">
                                    </el-checkbox>
                                </div>
                                <hr class="hrspacebetween " />

                                <div class="col1c">
                                    {{
                                        item.cs == undefined
                                        ? ""
                                        : item.cs.loginname
                                    }}
                                </div>
                                <hr class="hrspacebetween " />

                                <div class="col1c">
                                    {{ getdateformat(item.signoffon) }}
                                </div>
                                <hr class="hrspacebetween " />

                                <div class="col1c">
                                    {{ gettimeformat(item.signoffon) }}
                                </div>
                                <hr class="hrspacebetween" />

                                <div class="col1c padding4px">
                                    <el-select :id="'analysis_signoff_project_version_' + index" style="width: 55%"
                                        v-model="item.version" filterable placeholder="Select" @change="
                                            getdatabyversion(
                                                item,
                                                $event
                                            )
                                            ">
                                        <el-option v-for="vitem in item.versions" :key="vitem.v" :label="vitem.v"
                                            :value="vitem.v">
                                        </el-option>
                                    </el-select>
                                </div>
                                <hr class="hrspacebetween" />

                                <div class="col1c padding4px">
                                    <el-button type="primary" :disabled="!editpermission" class="newbtnwidth"
                                        @click="cellchanged('analysis_signoff_project_update_version', index, () => { updateversion(item) }, false)">Update</el-button>
                                </div>

                                <hr class="hrspacebetween hrstyle" />
                                <hr class="hrspacebetween hrstyle" />

                                <div class="col1c padding4px">
                                    <el-button type="primary" :disabled="!editpermission" class="newbtnwidth"
                                        @click="cellchanged('analysis_signoff_project_create_new_version', index, () => { addnewversion(item) }, false)">New</el-button>
                                </div>
                            </div>
                        </el-col>
                    </el-row>
                </el-row>
            </div>
        </div>
    </div>
</template>
<script>
import moment from "moment";
export default {
    data() {
        return {
            project: {},
            detail: false,
            tabledata: [],
            project: {},
        };
    },
    computed: {
        getdateformat() {
            return function (data) {
                if (data) {
                    var d = new Date(data);
                    return moment(d).format("DD-MMM-YYYY");
                }
                return data;
            };
        },
        gettimeformat() {
            return function (data) {
                if (data) {
                    var d = new Date(data);
                    return moment(d).format("HH:mm");
                }
                return data;
            };
        },
    },
    methods: {
        routepushpage() {
            this.$router.push({ path: '/signoffsectionssummary' })
        },
        closeproject() {
            this.project = {};
            this.refresh();
        },
        selectProject(event) {
            this.$store.state.project = JSON.parse(JSON.stringify(event));
            this.project = event;
            this.refresh();
        },
        disabledchack(item) {
            if (item.versions.length <= 0) {
                return false;
            }
            var b = Math.max.apply(
                Math,
                item.versions.map(function (o) {
                    return o.v;
                })
            );
            if (b != item.version) {
                return true;
            } else {
                return false;
            }
        },
        changed(data) {
            if (
                data.instructions != true ||
                data.signoffsummary != true ||
                data.cqcrwcsummary != true ||
                data.pdinvestigation != true
            ) {
                data.checkscomplete = false;
            }
            data.edit = true;
        },
        allchack(item) {
            item.instructions = true;
            item.signoffsummary = true;
            item.cqcrwcsummary = true;
            item.pdinvestigation = true;
            item.edit = true;
        },
        getdatabyversion(item, version) {
            var vdata = item.versions.find((x) => x.v == version);
            var data = {
                id: vdata.id,
                projectid: item.projectid,
                version: version,
                partid: vdata.partid,
            };
            this.$http.post("/signoff/getsignoffpartbyversion", data)
                .then((response) => {
                    this.tabledata = this.tabledata.filter((x) => {
                        if (x.partid == response.data.partid) {
                            for (const [key, value] of Object.entries(
                                response.data
                            )) {
                                x[key] = value;
                            }
                        }
                        return x;
                    });
                    this.hideloading();
                })
                .catch((err) => {
                    this.hideloading();
                    this.$bus.$emit("error", err);
                });
        },
        addnewversion(data) {
            if (data.edit != true) {
                this.$message({
                    showClose: true,
                    message: "Nothing to update",
                    type: "info",
                });
                return;
            }
            this.$confirm("Are you sure you want to Create New Version?", "Warning", {
                confirmButtonText: "OK",
                cancelButtonText: "Cancel",
                type: "warning",
            }).then(() => {
                var v = data.version.toString().split(".")[0];
                var esum = parseFloat(v) + 1;
                esum = esum < 10 ? "0" + esum.toString() : esum.toString();
                data.version = esum + ".00";
                this.$message({
                    showClose: true,
                    message: "New Version Created",
                    type: "success",
                });
            })
                .catch(() => {
                    this.$message({
                        showClose: true,
                        type: "info",
                        message: "canceled",
                    });
                });
        },
        setversion(data) {
            data.id = 0;
            if (data.version != null) {
                var a = data.version.toString().split(".")[1];
                var num = parseFloat(a) + 1;
                var f = data.version.toString().split(".")[0];
                var l = num < 10 ? "0" + num : num;
                if (l < 10) {
                    data.version = f.toString() + "." + l.toString();
                } else {
                    data.version = f.toString() + "." + l.toString();
                }
            } else {
                data.version = "01.01";
            }
        },
        refresh() {
            if (this.project.id == 0 || this.project.id == undefined) {
                this.tabledata = [];
                this.detail = false;
            } else {
                this.getsignoffparts();
                this.detail = true;
            }
        },
        cellchanged(cellaction, index, callback, cancall) {
            this.actionindex = index;
            const app = this;
            this.$directionutility.setcomponent(app);
            if (cancall) {
                callback();
                this.canmatchcellid(() => { }, cellaction, "");
            } else {
                this.canmatchcellid(callback, cellaction, "");
            }
        },
        updateversion(data) {
            if (data.edit != true) {
                this.$message({
                    showClose: true,
                    message: "Nothing to update",
                    type: "info",
                });
                return;
            }
            this.setversion(data);
            data.projectid = this.project.id;
            data.partid = data.pr.id;
            this.showloading();
            this.$http
                .post("/signoff/savesignoffpart", data)
                .then((response) => {
                    this.$message({
                        showClose: true,
                        message: response.data.message,
                        type: "success",
                    });
                    this.hideloading();
                    this.getsignoffparts();
                })
                .catch((err) => {
                    this.hideloading();
                    this.$bus.$emit("error", err);
                });
        },
        getsignoffparts() {
            this.showloading();
            this.$http
                .post("/signoff/getsignoffparts", this.project)
                .then((response) => {
                    this.tabledata = response.data;

                    this.hideloading();
                })
                .catch((err) => {
                    this.hideloading();
                    this.$bus.$emit("error", err);
                });
        },
    },
    created: function () {
        if (this.$store.state.project) {
            var project = JSON.parse(JSON.stringify(this.$store.state.project));
            this.selectProject(project);
        }
    },
};
</script>
<style>
.newbtnwidth {
    width: 95px;
}

.partname {
    padding: 6px;
    white-space: normal;
    width: 100%;
    height: 60px;
    border: 0px solid white;
}

hr.groove {
    border: 1px solid gray;
}

.container {
    width: 100%;
    overflow-x: auto;
    white-space: nowrap;
    padding: 0%;
}

.topspace {
    margin-top: 17px;
}

.headertext {
    font-weight: bold;
    font-size: 14px;
    padding: 6px;
}

.contenttext {
    font-size: 14px;
    padding: 6px;
}

.contenttext2 {
    font-size: 12;
    color: rgb(96, 98, 102);
    white-space: normal;
}

.col1c {
    border: 0px solid white;
    width: 100%;
    height: 34px;
    padding: 6px;
    vertical-align: middle;
}

.col2c {
    border: 0px solid white;
    width: 100%;
    height: 40px;
    padding: 6px;
}

.formborder {
    border-top: groove;
}

.border-left-SO {
    border-left: 0.5px solid #eee;
    border-collapse: collapse;
}

.border-right-SO {
    border-right: 0.5px solid #eee;
}

.border-bottom-SO {
    border-bottom: 0.5px solid #eee;
}

.width20char {
    width: 155px !important;
}

.max-content-width-as-container {
    width: max-content !important;
}
</style>
