<template>
    <div>
        <div>
            <div class="fixdheader criteriawidth">
                <div>
                    <span class="headerstyle">Criteria</span>
                    <div class="floatright margintb1mm">

                            <el-button type="primary"
                                       @click="addcriteria"
                                        id="setup_criteria_add_psp_criteria">
                                Add PSP Criteria
                            </el-button>

                            <el-button icon="el-icon-back"
                                       type="info"
                                       id="setup_criteria_back"
                                       @click="$router.go(-1)">Back</el-button>

                    </div>
                </div>
                <div class="marginl5">

                        <el-select v-model="searchGroup"
                                   :style="{ width: '110px' }"
                                        id="setup_criteria_search_groups"
                                   clearable placeholder="Search Group">
                            <el-option v-for="item in groupings"
                                       :key="item.value"
                                       :label="item.label"
                                       :value="item.label">
                            </el-option>
                        </el-select>

                        <el-select v-model="searchCriteria" class="marginleft4px"
                                   :style="{ width: '110px' }"
                                   id="setup_criteria_search_criteria"
                                   clearable placeholder="Search Criteria">
                            <el-option v-for="item in criterias"
                                       :key="item.value"
                                       :label="item.label"
                                       :value="item.label">
                            </el-option>
                        </el-select>

                        <el-input v-model="searchName" class="marginleft4px"
                                  placeholder="Search Criteria Name"
                                  id="setup_criteria_search_criteria_name"
                                  suffix-icon="el-icon-search"
                                  style="width: 290px;"></el-input>

                </div>
                <div class="center">
                    <span>
                        <el-pagination layout="prev, pager, next"
                                       @current-change="pageCurrentChange"
                                       :page-size="pagesize"
                                       :total="total">
                        </el-pagination>
                    </span>
                </div>
                <hr class="hrstyle" />
            </div>
            <div class="criteriawidth">
                <el-table :data="getdetailform" class="fullwidth" size="mini">
                    <el-table-column prop="grouping" width="120" label="Group Name"></el-table-column>
                    <el-table-column prop="criteria" width="95" label="Number"></el-table-column>
                    <el-table-column prop="value" width="290" label="Criteria Name"></el-table-column>
                    <el-table-column prop="definition" width="290" label="Definition (Of Criteria Names)"></el-table-column>
                    <!--<el-table-column prop="value" :width="columnwidth(getdetailform,52,'value',100)" label="Criteria Name"></el-table-column>
                    <el-table-column prop="definition" :width="columnwidth(getdetailform,52,'definition',400)" label="Definition (Of Criteria Names)"></el-table-column>-->
                    <el-table-column label="Edit" width="55">
                        <template slot-scope="scope">
                            <el-button title="Edit" size="mini"
                                       type="primary" icon="el-icon-edit"
                                       :id="'setup_criteria_table_edit_'+scope.$index"
                                       @click="handleEdit(scope.$index, scope.row)"></el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>

        <el-dialog v-show="addeditpspcriteria" title="PSP Criteria Add/Edit" :visible.sync="addeditpspcriteria" :close-on-click-modal="false" width="50%"> 
            <template>
                <el-form label-width="120px" size="mini">
                    <el-row>
                        <el-col>
                            <el-form-item label="Groups">
                                <el-select v-model="form.grouping" class="fullwidth" clearable placeholder="Select Group" id="setup_criteria_add_edit_select_group">
                                    <el-option v-for="item in groupings"
                                               :key="item.value"
                                               :label="item.label"
                                               :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>

                        <el-col>
                            <el-form-item label="Number">
                                <el-select v-model="form.criteria" class="fullwidth" clearable placeholder="Select Number" id="setup_criteria_add_edit_select_number">
                                    <el-option v-for="item in criterias"
                                               :key="item.value"
                                               :label="item.label"
                                               :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>

                        <el-col>
                            <el-form-item label="Criteria Name">
                                <el-input v-model="form.value" maxlength="32" id="setup_criteria_add_edit_criteria_name" @input='charcount(form.value)' placeholder="Criteria Name"></el-input>
                            </el-form-item>
                        </el-col>

                        <el-col>
                            <el-form-item label="Definition">
                                <el-input v-model="form.definition" type="textarea" id="setup_criteria_add_edit_criteria_definition" :rows="2" placeholder="Definition"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>

                <el-row type="flex" justify="center">
                    <el-button type="success" id="setup_criteria_add_edit_criteria_save" icon="el-icon-circle-check" @click="cellchanged('setup_criteria_add_edit_criteria_save',()=>{save()},false) ">Ok</el-button>
                </el-row>
            </template>
        </el-dialog>
    </div>
</template>

<script>
export default {
    data(){
        return{
            searchName:'',
            searchCriteria:'',
            searchGroup:'',
            page: 1,
            pagesize: 10,
            total: 0,
            pspcriterias:[],
            form:{},
            criterias:[
                {label:'Criteria 1',value:"c1"},
                {label:'Criteria 2',value:"c2"},
                {label:'Criteria 3',value:"c3"},
                {label:'Criteria 4',value:"c4"},
            ],
            groupings:[
                {label:'Common',value:"com"},
                {label:'Substructure',value:"sub"},
                {label:'Superstructure',value:"sup"},
                {label:'Externals',value:"ext"},
            ],
            addeditpspcriteria:false,
        }
    },

    methods:{ 
        getsection(){
            return "";
        },
        cellchanged(cellaction,callback,cancall){
            if(cancall){
                callback();
                this.canmatchcellid(()=>{},cellaction,this.getsection());
            }else{
                this.canmatchcellid(callback,cellaction,this.getsection());
            }
        },
        handleEdit: function (scope, row) {
            this.form = {};
            var data = {id:row.id};
            this.$http
            .post("project/addeditcriteriabyid", data)
            .then((response) => {
            this.form = response.data;
                this.addeditpspcriteria = true;
            })
            .catch((err) => {
                this.$bus.$emit("error", err);
            });
        },

        charcount: function(val) {
            if (/^\s+$/.test(val) || val == undefined) {
                val = "";
            }
            if (val.length >= 32) {
                var style = "<style> .el-message-box {width:30%;} </style>";
                this.$alert("You have reached the maximum limit of 32 characters."+style, "Length Limitation", {
                    dangerouslyUseHTMLString: true,
                });
            }
        },

        pageCurrentChange: function (val) {
            this.page = val;
        },

        addcriteria:function(){
            this.form = {};
            this.addeditpspcriteria = true;
        },

        validate: function (callback) {
            var msg = [];
            if (/^\s+$/.test(this.form.value)) {
                this.form.value = "";
            }
            if (!this.form.value) {
                msg.push("Name must not be empty");
            }
            if (!this.form.criteria) {
                msg.push("Criteria must not be empty");
            }
            if (!this.form.grouping) {
                msg.push("Group must not be empty");
            }
            if (msg.length > 0) {
                var style = "<style> .el-message-box {width:50%;} </style>";
                this.$alert(msg.join("<br />") + style, "Invalid Data", {
                dangerouslyUseHTMLString: true,
                });
                callback(false);
            }else{
                callback(true);
            }
        },

        criterianamechange:function(){
            this.pspcriterias.forEach(crt => {
                if(crt.criteria == "c1"){
                    crt.criteria = "Criteria 1"
                }
                if(crt.criteria == "c2"){
                    crt.criteria = "Criteria 2"
                }
                if(crt.criteria == "c3"){
                    crt.criteria = "Criteria 3"
                }
                if(crt.criteria == "c4"){
                    crt.criteria = "Criteria 4"
                }
                if(crt.grouping == "com"){
                    crt.grouping = "Common"
                }
                if(crt.grouping == "sub"){
                    crt.grouping = "Substructure"
                }
                if(crt.grouping == "sup"){
                    crt.grouping = "Superstructure"
                }
                if(crt.grouping == "ext"){
                    crt.grouping = "Externals"
                }
            });
        },

        columnwidth: function (pa, collength,colname,deflen) {
            // var col = column.toString();
            var maxwidth = 0;

            for (var i = 0; i < pa.length; i++) {
                var row = pa[i];
                var value = row[colname];
                if (value && value.length != 0) {
                    if (value.length > maxwidth && value.length <= collength) {
                        maxwidth = value.length;
                        var str = value;
                    }
                    if (value.length > collength) {
                        maxwidth = collength;
                        var str = value.slice(0, collength);
                        break;
                    }
                }
            }
            if (maxwidth != 0) {
                var size = this.measureTextWidth(str);
                return size + 10;
            }
            else{
                return deflen;
            }
        },

        refresh:function(){
            this.$http
            .post("project/getallpspcriteria")
            .then((response) => {
                this.pspcriterias = response.data;
                this.criterianamechange();
            })
            .catch((err) => {
                this.$bus.$emit("error", err);
            });
        },

        save:function(){
            this.validate((valid) => {
            if (valid) {
                this.$http
                .post("project/addeditcriteria", this.form)
                .then((response) => {
                    this.$message({
                        showClose: true,
                        message: response.data,
                        type: "success",
                    });
                    this.addeditpspcriteria = false;
                    this.refresh();
                    this.form = {};
                })
                .catch((err) => {
                    this.$bus.$emit("error", err);
                    this.addeditpspcriteria = false;
                });

                } else {
                    return false;
                }
            });
        },
    },

    computed: {
        getdetailform: function () {
            if (this.pspcriterias.length == 0)
                return [];

                var criterias = [];
                criterias = this.pspcriterias.filter((cqc) => {
                var show = true;
                if (this.searchName) {
                    show = cqc.value.toLowerCase().includes(this.searchName.toLowerCase());
                }
                if (show && this.searchCriteria) {
                    show = cqc.criteria.includes(this.searchCriteria);
                }
                if (show && this.searchGroup) {
                    show = cqc.grouping.includes(this.searchGroup);
                }
                return show;
            });
            this.total = criterias.length;
                if (this.total == 0) {
                    return [];
                }
                if (!(Math.ceil(this.total / this.pagesize) >= this.page)) {
                    this.page = Math.ceil(this.total / this.pagesize);
                }
            var data = criterias.slice(this.pagesize * this.page - this.pagesize, this.pagesize * this.page);

            var rdata = [];
            var com =  [];
            var sub = [];
            var sup = [];
            var ext = [];
            data.filter(x =>{
            if(x.grouping == "Common"){
                com.push(x);
            }
            if(x.grouping == "Substructure"){
                sub.push(x);
            }
                if(x.grouping == "Superstructure"){
                sup.push(x);
            }
            if(x.grouping == "Externals"){
                ext.push(x);
            }
            });
            com.sort(function (a, b) {
                return  ('' + a.criteria).localeCompare(b.criteria)
            });
            sub.sort(function (a, b) {
                return  ('' + a.criteria).localeCompare(b.criteria)
            });
            sup.sort(function (a, b) {
                return  ('' + a.criteria).localeCompare(b.criteria)
            });
            ext.sort(function (a, b) {
                return  ('' + a.criteria).localeCompare(b.criteria)
            });
            rdata.push(...com,...sub,...sup,...ext);
            return rdata;
        }
    },

    created: function () {
        this.refresh();
    },
}
</script>
