<template>
    <div>
        <div v-if="isLoadingDelayed" class="loading-indicator">
            <div class="progress-container">
                <div class="progress-details">
                    <div class="timeline-text">Loading &nbsp; {{ remainingTime }}</div>
                    <div class="percentage-text">{{ percentage }}%</div>
                </div>

                <div class="progress-wrapper">
                    <div class="el-icon-loading spinner"></div>
                    <div class="progress-bar">
                        <div class="progress" :style="{ width: percentage + '%' }"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    // props: {
    //   isLoading: {
    //     type: Boolean,
    //     required: true,
    //   },
    // },

    data() {
        return {
            percentage: 0,
            remainingTime: "",
            frozenPercentage: false,
            interval: null,
            isLoadingDelayed: false,
            startloadingTimeout: null,
        }
    },

    computed: {
      isLoading() {
        return this.$store.state.isLoading;
      }
    },
    
    watch: {
      isLoading: {
          immediate: true,
          handler(newValue) {
            clearTimeout(this.startloadingTimeout);
            
            if (newValue) {
              this.startloadingTimeout = setTimeout(() => {
                this.startLoading(); 
              }, 3000);

            } else {
              this.stopLoading();
            }
          }
        // if (newValue) {
        //   // Start loading when isLoading prop is true
        //   this.startLoading();
        // } else {
        //   // Stop loading when isLoading prop is false
        //   this.stopLoading();
        // }
      },
    },


    methods: {
        startLoading() {
            // Generate a random estimate time between 40 and 80 seconds
            const randomEstimateTimeInSeconds = Math.floor(Math.random() * (80 - 40 + 1)) + 40;
            // this.remainingTime = this.formatRemainingTime(randomEstimateTimeInSeconds);
            this.percentage = 0;
            this.frozenPercentage = false;
            // this.isLoading = true;
            this.isLoadingDelayed = true;

            this.interval = setInterval(() => {
                if (!this.isLoadingDelayed) {
                  this.isLoadingDelayed = true;
                }

                if (this.percentage >= 80 && this.percentage <= 95) {
                  this.frozenPercentage = true;
                }

                if (!this.frozenPercentage && this.percentage < 100) {
                  this.percentage += Math.floor(Math.random() * (15 - 7 + 1)) + 7; // Random increment between 7 and 15
                  this.remainingTime = this.formatRemainingTime(randomEstimateTimeInSeconds);

                  if (this.percentage > 100) {
                      this.percentage = 100;
                  }
                }
            }, 800);
        },

        stopLoading() {
            clearInterval(this.interval);
            this.remainingTime = "0 seconds left";
            this.percentage = 100;

            setTimeout(() => {
                this.isLoadingDelayed = false;
            }, 500);
        },

        formatRemainingTime(seconds) {
            const remainingTimeInSeconds = (seconds * (100 - this.percentage)) / 100;

            const minutes = Math.floor(remainingTimeInSeconds / 60);
            const remainingSeconds = Math.floor(remainingTimeInSeconds % 60);
            
            if (minutes > 0) {
                return `${minutes} minute${minutes > 1 ? 's' : ''} ${remainingSeconds} second${remainingSeconds > 1 ? 's' : ''} left`;
            } else {
                return `${remainingSeconds} second${remainingSeconds > 1 ? 's' : ''} left`;
            }
        },
    },

    // created() {
    //     debugger
    //     console.log(this.isLoading);
    // },
}
</script>

<style scoped>
.loading-indicator {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 90%);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.progress-container {
  width: 400px;
  /* background-color: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 20px; */
}

.progress-wrapper {
  display: flex;
  align-items: center;
}

.progress-bar {
  flex-grow: 1;
  height: 10px;
  /* background-color: #f0f0f0; */
  border-radius: 5px;
  overflow: hidden;
  box-shadow: inset 0px 1px 1px 1px rgb(0 0 0 / 50%), 1px 1px 0px 0px #ffffff40;
}

.progress {
  height: 100%;
  background-color: #29ace4;
  transition: width 0.3s ease;
  border: 1px solid black;
  border-radius: 10px;
}

.progress-details {
  display: flex;
  align-items: center;
  margin-left: 10px;
  justify-content: space-between;
}

.timeline-text {
  font-size: 14px;
  color: #29ace4;
  margin-left: 12px;
  /* margin-right: 10px; */
}

.percentage-text {
  font-size: 14px;
  color: #29ace4;
}

.spinner {
    color: #29ace4;
    font-weight: bold;
    margin-right: 5px;
    margin-top: 3px;
  /* width: 20px;
  height: 20px;
  border: 2px solid #29ace4;
  border-top-color: transparent;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-left: 10px; */
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>