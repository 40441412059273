<template>
    <div class="rwcreportwidth">
        <div class="fixdheader">
         <el-row>
            <span class="headerstyle">RWC Report</span>
            <span class="right marginright16px"><el-button :disabled="!editpermission" type="success" @click="cellchanged('analysis_rwcreport_save',()=>{save()},false)" >Save</el-button></span>
        </el-row>

        <projectselectpart @projectsectionchange="projectsectionchange"></projectselectpart>

        <hr class="hrstyle3 marginright16px" />
        <div v-show="detail" class="marginright16px">
            <el-row>
                <el-col :span="18">
                    <div style="width:34px;display:inline-block">
                        <el-button :class="expandcollapsetext == '+' ? 'icon-expand-btn-width-pluse' : 'icon-expand-btn-width-minus'"  type="info" @click="toogleExpand">{{expandcollapsetext}}</el-button>
                    </div>
                    
                    <el-select v-model="searchtype"
                               clearable
                               placeholder="Search Type" class="width100px marginleft75px"
                               :id="'analysis_rwcreport_filter_searchtype'">
                        <el-option v-for="item in searchtypes"
                                   :key="item"
                                   :label="item"
                                   :value="item">
                        </el-option>
                    </el-select>

                    <el-select v-model="ifsearch" clearable
                               placeholder="Select" class="width130px marginleft190px"
                               :id="'analysis_rwcreport_filter_ifcondition'">
                        <el-option v-for="item in ifmatches"
                                   :key="item.value"
                                   :label="item.label"
                                   :value="item.value">
                        </el-option>
                    </el-select>

                    <el-select v-model="search"
                               clearable
                               placeholder="Select" class="width130px marginleft140px"
                               :id="'analysis_rwcreport_filter_thencondition'">
                        <el-option v-for="item in matches"
                                   :key="item.value"
                                   :label="item.label"
                                   :value="item.value">
                        </el-option>
                    </el-select>

                </el-col>
                <el-col :span="5" class="fontsize12">
                    Sign Off:  &nbsp;
                    <el-checkbox  :disabled="!editpermission" v-model="detailform.signoff" @change="signoffchange" :id="'analysis_rwcreport_filter_checkbox_signoff'"></el-checkbox>  &nbsp;
                    By:  &nbsp; {{detailform.signoffby.loginname}}
                </el-col>
            </el-row>
            <hr class="hrstyle3" />
        </div>
        </div>
        <div v-show="detail" class="tablepaddinglr5px">
            <el-table ref="table" :data="getdetailform" :expand-row-keys="expandedrows" :row-key="getrowskey" default-expand-all
                      class="rwcreporttableBox fullwidth lessheight" size="mini">
                <!-- <el-table-column width="20" >
                    <template slot-scope="scope">
                        <div v-if="scope.row.isparent">
                         <el-button type="text" @click="toogleExpand(scope.row)">See details</el-button>
                            <el-button type="text" :icon="scope.row.iswordlist ? 'el-icon-arrow-right rotate down' : 'el-icon-arrow-right rotate'" @click="expandcollpasebtn(scope.row ,scope.$index, false)" style="padding: 5px 0px;"></el-button>
                        </div>
                    </template>
                </el-table-column> -->
                <el-table-column width="35"></el-table-column>
                <el-table-column prop="nrmsection" label="NRM Code" width="90">
                    <template slot-scope="scope">
                        <div v-if="scope.row.show">{{scope.row.comparesearch.nrmsection}}</div>
                    </template>
                </el-table-column>
                <el-table-column prop="searchtype" label="Search Type" width="90">
                    <template slot-scope="scope">
                        <div v-if="scope.row.show">{{scope.row.comparesearch.searchtype}}</div>
                    </template>
                </el-table-column>
                <el-table-column prop="description" label="Search Name" width="205">
                    <template slot-scope="scope">
                        <div v-if="scope.row.show">{{scope.row.comparesearch.description}}</div>
                    </template>
                </el-table-column>
                <el-table-column prop="ifcharacters" label="Search Characters (if)" width="205">
                    <template slot-scope="scope">
                        <div v-if="scope.row.show">{{scope.row.comparesearch.ifcharacters}}</div>
                    </template>
                </el-table-column>
                <!-- <el-table-column prop="ifcharacter" label="Comments(ifcharachters)">
                    <template slot-scope="scope">
                        <el-input  v-model="scope.row.ifcharacter">
                        </el-input>
                    </template>
                </el-table-column> -->
                <!-- <el-table-column width="70">
                    <template slot-scope="scope">
                        <div v-if="scope.row.iswordlist"><b>Word List</b></div>
                    </template>
                </el-table-column> -->
                <el-table-column prop="thencharacter" label="Search Character (then)" width="205">
                    <template slot-scope="scope">
                        <div v-if="!scope.row.iscontainchild">{{scope.row.thencharacter}}</div>
                        <b v-else>Word List</b>
                    </template>
                </el-table-column>
                <el-table-column prop="result" label="Match" width="60" align="center">
                    <template slot-scope="scope">
                        <div v-if="!scope.row.iscontainchild" :class="scope.row.result">{{scope.row.result}}</div>
                    </template>
                </el-table-column>
                <el-table-column prop="comment" label="Comments" width="205">
                    <template slot-scope="scope">
                        <div v-if="!scope.row.iscontainchild"><el-input  :disabled="!editpermission" v-model="scope.row.comment" :id="`analysis_rwcreport_table_comment_${scope.$index}`"></el-input></div>
                    </template>
                </el-table-column>
            </el-table>

        </div>
    </div>
</template>

<script>
    import projectselectpart from './projectselectpart.vue';

    export default {
        data() {
            return {
                detail: false,
                project: { id: null, projectname: '', library: '' },
                sectionid: null,
                detailform: {
                    signoffby: {},
                    details: []
                },
                loading: false,
                ifmatches: [
                    { value: 'All', label: "Match 'If' = All" },
                    { value: 'Yes', label: "Match 'If' = Yes" },
                    { value: 'No', label: "Match 'If' = No" },
                ],
                matches: [
                    { value: 'Yes,No', label: "Match 'Then' = All" },
                    { value: 'Yes', label: "Match 'Then' = Yes" },
                    { value: 'No', label: "Match 'Then' = No" },
                ],
                ifsearch: 'Yes',
                search: 'Yes,No',
                searchtypes: ['Required', 'Expected', 'Testing'],
                searchtype: '',
                expandedrows: [],
                expandcollapsetext: "-"
            }
        },
        computed: {
            getdetailform: function () {
                if (this.detailform.details.length == 0) {
                    return [];
                }
                var data = this.detailform.details;


                data = data.filter(o=>{
                    var isreturn = true;
                    o.children = [];
                    const child = o.oldchildren.filter(is=>{
                        var ischildren = true;
                        if(this.search && ischildren){
                            var include = false;
                            this.search.split(",").forEach((search) => {
                                if (!include && is.result)
                                    include = include || is.result.includes(search);
                            });
                            ischildren = include;
                        }
                        return ischildren;
                    });
                    o.children = child;

                    if (this.ifsearch) {
                        isreturn = this.ifsearch == 'All' ? true : this.ifsearch == 'Yes' && o.result ? true : this.ifsearch == 'No' && !o.result ? true : false;
                    }

                    if (this.searchtype && isreturn) {
                        isreturn =  o.comparesearch.searchtype == this.searchtype;
                    }
                    return isreturn;
                });

                // if (this.ifsearch) {
                //     data = data.filter((is) => {
                //         return this.ifsearch == 'All' ? true : this.ifsearch == 'Yes' && is.result ? true : this.ifsearch == 'No' && !is.result ? true : false;
                //     });
                // }
                // if (this.search) {
                //     data = data.filter((c) => {
                //         var include = false;
                //         this.search.split(",").forEach((search) => {
                //             if (!include && c.result)
                //                 include = include || c.result.includes(search);
                //         });
                //         return include;
                //     });
                // }
                // if (this.searchtype) {
                //     data = data.filter((c) => {
                //         return c.comparesearch.searchtype == this.searchtype;
                //     });
                // }
                var id = null;
                data.forEach((c, i) => {
                    if (c.comparesearchid == id) {
                        c.show = false;
                    } else {
                        c.show = true;
                        id = c.comparesearchid;
                    }
                })
                return data;
            }
        },

        methods: {
            setdirectionsection() {
                if (this.project.section.section) {
                    return this.project.section.section;
                } else {
                    return "";
                }
            },

            cellchanged(cellaction, callback, cancall) {
                const app = this;
                this.$directionutility.setcomponent(app);
                if (cancall) {
                    callback();
                    this.canmatchcellid(() => { }, cellaction, this.setdirectionsection());
                } else {
                    this.canmatchcellid(callback, cellaction, this.setdirectionsection());
                }
            },

            getrowskey: function(row) {
                return row.rowid;
            },
            changed: function(row) {
                row.edited = true;
            },
            toogleExpand() {
                this.detailform.details.forEach((row) => {
                    let $table = this.$refs.table;
                    $table.toggleRowExpansion(row)
                });
                var bigdash = new Option()
                bigdash.innerHTML = "&mdash;"
                this.expandcollapsetext = this.expandcollapsetext == bigdash.text ? "+" : bigdash.text;
             },
            expandcollpasebtn: function( row, rowindex, showallrows ) {
                let rwcreportdata = this.getdetailform;
                let subrowslength = showallrows == true ? rwcreportdata.length : rowindex + row.subrowscount;

                // var rfrt = this.$refs.rwctablecol1.$el;
                // rfrt.style.transform = "rotate(90deg)";

                // var menu = document.querySelector('.rotate');
                // menu.classList.toggle('down');

                // var rotatedown = "rotate(90deg)"
                // document.querySelector('.rotate').style.transform = rotatedown;

                var expandbtnstyle = document.querySelectorAll('.rotate');

                var ishiddenrow = false; var expandindex = 0; var firstcolumn = "";
                let col=[...document.querySelectorAll('tr.el-table__row')];
                for( let i=rowindex; i < subrowslength; i++ ){
                    if (col[i]) {
                        if (rwcreportdata[i].isparent) {
                            col[i].style.display='table-row';
                            expandindex = rwcreportdata[i].parentindex;
                            firstcolumn = col[i].cells[0].innerHTML;
                        } else {
                            col[i].style.display=col[i].style.display=='table-row' || col[i].style.display=='' ? 'none' : 'table-row';
                            col[i].style.display=='none' ? ishiddenrow = true : ishiddenrow = false;

                            if (firstcolumn.includes("el-button")) {
                                expandbtnstyle[expandindex].style.transform = col[i].style.display=='none' ? "rotate(0deg)" : "rotate(90deg)";
                            }
                            firstcolumn = col[i].cells[0].innerHTML;
                        }
                    }
                }

                var bigdash = new Option()
                bigdash.innerHTML = "&mdash;"
                this.expandcollapsetext = ishiddenrow == true ? "+" : bigdash.text;
            },
            //Methods for first page
            projectsectionchange: function (Projectobj) {
                this.sectionid = Projectobj.sectionid;
                this.project =Projectobj.project;
                this.refresh();
            },


            refresh: function () {
                if (this.project.id && this.sectionid) {
                    this.getProjectCS();
                }
                else {
                    this.detail = false;
                }
            },

            signoffchange: function (value) {
                this.detailform.signoffbyid = value ? this.$store.state.user.id : 0;
                if(value){
                    this.detailform.signoffon = new Date();
                }
            },

            save: function () {
                var rwcsavedetail = [];
                this.detailform.details.forEach(d => {
                    if (d.children.length > 0) {
                        d.children.filter(c => {
                            rwcsavedetail.push(c);
                        });
                    } else {
                        rwcsavedetail.push(d);
                    }
                });
                this.detailform.details = rwcsavedetail;
                this.$http.post('project/saveProjectCompareSearch', this.detailform)
                    .then(response => {
                        this.$message({
                            showClose: true,
                            message: response.data, type: "success"
                        });
                        this.refresh();
                        this.$signalr.checkingdialogboxheaderpage();
                    }).catch(err => {
                        this.loading = false;
                        this.$bus.$emit('error', err);
                        //this.$alert(err.response.data, err.response.status + ' ' + err.response.statusText, { dangerouslyUseHTMLString: true });
                    });
            },

            getProjectCS: function (id) {
                var data = {
                    id: this.project.id, section: { id: this.sectionid }
                };

                // var rwcdata = {details: []};

                this.loading = true;
                this.$http.post('project/getProjectCompareSearch', data)
                    .then(response => {
                        var json = JSON.parse(response.data);

                        var id = 0; var parentarray = []
                        json.details.forEach(pr => {
                            if (pr.comparesearchid != id) {
                                var parentrow = { children: [], oldchildren: [] };
                                for(var parentrowkey in pr) {
                                    parentrow[parentrowkey] = pr[parentrowkey];
                                }
                                parentrow.isparent = true;
                                parentarray.push(parentrow);
                            }
                            id = pr.comparesearchid;
                        });

                        parentarray.forEach((cr, crindex) => {
                            cr.rowid = "P" + crindex;
                            if (cr.isparent) {
                                json.details.filter((f, findex) => {
                                    if (f.comparesearchid == cr.comparesearchid) {
                                        f.rowid = "C" + findex;
                                        cr.oldchildren.push(f);
                                    }
                                });
                                if (cr.oldchildren.length == 1) {
                                    cr.oldchildren = [];
                                }
                            }

                            if (cr.oldchildren.length > 0) {
                                cr.iscontainchild = true;
                            } else {
                                cr.iscontainchild = false;
                            }
                        });
                        json.details = parentarray;

                        // var counts = {};
                        // json.details.forEach(function(x) {
                        //     var v = x.comparesearchid;
                        //     counts[v] = (counts[v] || 0) + 1;
                        // });

                        // var id = 0; var addarray = []; var index = 0;
                        // json.details.forEach(rd, rdindex => {
                        //     if (rd.comparesearchid != id) {
                        //         rd.isparent = true;
                        //         index = rdindex;
                        //         rd.subrowscount = counts[rd.comparesearchid];
                        //         rd.iswordlist = rd.subrowscount > 1 ? true : false;
                        //     } else {
                        //         addarray.push(rd);
                        //         json.details[index].children.push(rd);
                        //     }
                        //     id = rd.comparesearchid;
                        // });

                        // var parentrow = {
                        //     children: [], comment: null,
                        //     comparesearch: { description: null, ifcharacters: null, nrmsection: null, searchtype: null, thencharacters: null },
                        //     ifcharacter: null, result: null, thencharacter: null
                        // }

                        // var parentrow = {};
                        // function groupBy(list, keyGetter) {
                        //     const map = new Map();
                        //     list.forEach((item, itemindex) => {
                        //         const key = keyGetter(item);
                        //         const collection = map.get(key);
                        //         if (!collection) {
                        //             for(var parentrowkey in item) {
                        //                 parentrow[parentrowkey] = item[parentrowkey];
                        //             }
                        //             parentrow.rowid = "P" + itemindex;
                        //             item.rowid = "C" + itemindex;
                        //             if (!collection) {
                        //                 map.set(key, { parentrow });
                        //                 parentrow.children = [item];
                        //             } else {
                        //                 map.set(key, { parentrow });
                        //                 parentrow.children = [item];
                        //             }
                        //         } else {
                        //             item.rowid = "C" + itemindex;
                        //             collection.parentrow.children.push(item);
                        //         }
                        //     });
                        //     return map;
                        // }
                        // const grouped = groupBy(json.details, pet => pet.comparesearchid);
                        // json.details = Array.from(grouped.values());

                        var bigdash = new Option()
                        bigdash.innerHTML = "&mdash;"
                        this.expandcollapsetext = bigdash.text;

                        // for(var key in json) {
                        //     if (!key.includes("details")) {
                        //         rwcdata[key] = json[key];
                        //     }
                        // }
                        // var id = 0;
                        // json.details.map((j, index)=>{
                        //     if (j.comparesearchid != id) {
                        //         j.isparent = true;
                        //         j.children = [];
                        //         rwcdata.details.push(j);
                        //     } else {
                        //         rwcdata.details.find((c) => {
                        //             if (c.comparesearchid == j.comparesearchid && !j.isparent) {
                        //                 c.children.push(j);
                        //             }
                        //         });
                        //     }
                        //     id = j.comparesearchid;
                        // });
                        
                        this.detailform = json;
                        this.attachments = [];
                        this.detail = true;
                        this.loading = false;
                        this.$nextTick(this.mymakesortable);
                    }).catch(err => {
                        this.loading = false;
                        this.$bus.$emit('error', err);
                        //this.$alert(err.response.data, err.response.status + ' ' + err.response.statusText, { dangerouslyUseHTMLString: true });
                    });
            },
            headerstyle: function ({ row, column, rowIndex, columnIndex }) {
                return "background-color: #99ebffa3;"
            },
            expandchange: function (row, expandedRows) {
                var elementsArray = document.getElementsByClassName("el-table__expand-icon");
               var expanded = expandedRows.map((v) => { return v.id });
               this.expandedrows = expanded;
               var bigdash = new Option()
               bigdash.innerHTML = "&mdash;"
               this.expandcollapsetext = this.expandedrows.length ? bigdash.text : "+"; //not sure why bigger dash displays like this.
           }
        },
        created: function () {
            const params = new URLSearchParams(window.location.search);
            if (params.size > 0) {
                this.$training.getprojectusingparam(params);
            }
        },
        components: {
            'projectselectpart': projectselectpart,
        }
    }
</script>
<style itemscope>
    .No {
        background-color: rgb(255, 168, 147)
    }

    .Yes {
        background-color: rgb(198, 224, 180)
    }

    div.lessheight > div.el-table__body-wrapper.is-scrolling-none > table > tbody > tr > td {
        padding: 1px 0 !important;
        /*border-bottom: none;*/
    }

    .lessheight .el-table--mini td, .lessheight .el-table--mini th {
    }

    .rwcreporttableBox.el-table--mini td, .el-table--mini th {
        padding: 1px 0;
    }

    .rotate {
        position: relative;
        cursor: pointer;
        color: #666 !important;
        transition: transform .2s ease-in-out,
        -webkit-transform .2s ease-in-out;
        height: 16px;
        font-size: 16px !important;
    }

    .rotate:hover {
        color: #666 !important;
    }

    .rotate.down {
        transform: rotate(90deg);
    }

    .icon-expand-btn-width {
        padding: 4px;
        padding-left: 10px;
        padding-right: 10px;
        border-width: 2px;
        font-weight: bold;
    }
</style>
