<template>
    <div v-if="canshowmedia">
        <div v-if="beforemedia">
            <el-dialog v-if="beforemedia && beforemedia.originalname" :title="beforemedia.originalname" :visible.sync="beforemedia.show"
                :before-close="beforemediaClose" class="dialogboxpadding" width="70%">
                <div>
                    <span
                        v-if="beforemedia.extention == 'jpg' || beforemedia.extention == 'jpeg' || beforemedia.extention == 'gif' || beforemedia.extention == 'png'">
                        <!-- <img :src="beforemedia.link" class="previewbox" /> -->
                        <el-image 
                        class="previewbox"
                            :src="beforemedia.link" 
                            :preview-src-list="[beforemedia.link]">
                        </el-image>
                    </span>
                    <span v-if="beforemedia.extention == 'mp4'">
                        <video controls class="previewbox">
                            <source :src="beforemedia.link" type="video/mp4">
                            Your browser does not support the video tag.
                        </video>
                    </span>
                    <span v-if="beforemedia.extention == 'mp3'">
                        <audio controls class="previewbox">
                            <source :src="beforemedia.link" type="audio/ogg">
                            <source :src="beforemedia.link" type="audio/mpeg">
                            Your browser does not support the audio element.
                        </audio>
                    </span>
                </div>
            </el-dialog>

        </div>
        <div v-if="selectedmedia && selectedmedia.name">
            <el-dialog :title="selectedmedia.name" :visible.sync="selectedmedia.showdialog"
                :before-close="selectedmediaClose" class="dialogboxpadding" width="70%">
                <div>
                    <el-row>
                        <el-col :span="12">
                            <el-button type="primary">
                                <el-link :underline="false" :href="selectedmedia.externalurl" class="externallink"
                                    target="_blank">External Link</el-link>
                            </el-button>
                            <el-button icon="el-icon-download" @click="downloadmedia(selectedmedia)" type="primary">
                                Download
                            </el-button>
                            <el-button icon="el-icon-delete" class="marginl5" @click="deleteMedia(selectedmedia)"
                                type="danger">
                            </el-button>
                        </el-col>
                    </el-row>

                    <span v-if="istype(selectedmedia, 'Image')">
                        <!-- <img :src="selectedmedia.link" class="previewbox" /> -->
                        <el-image 
                        class="previewbox"
                            :src="selectedmedia.link" 
                            :preview-src-list="[selectedmedia.link]">
                        </el-image>
                    </span>

                    <span v-else-if="istype(selectedmedia, 'Video')">
                        <video controls class="previewbox">
                            <source :src="selectedmedia.url" type="video/mp4">
                            Your browser does not support the video tag.
                        </video>
                    </span>

                    <span v-else-if="istype(selectedmedia, 'Audio')">
                        <audio controls class="previewbox">
                            <source :src="selectedmedia.link" type="audio/ogg">
                            <source :src="selectedmedia.link" type="audio/mpeg">
                            Your browser does not support the audio element.
                        </audio>
                    </span>
                </div>
            </el-dialog>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            canshowmedia:false,
            beforemedia: {},
            selectedmedia: {},
        };
    },
    computed: {
        istype() {
            return function (media, type) {
                if (type == 'Image') {
                    // var typesexist = false;
                    return media.extention.startsWith('jpg') || media.extention.startsWith('jpeg') 
                           || media.extention.startsWith('gif') || media.extention.startsWith('png');
                    // return ['jpg', 'jpeg', 'gif', 'png'];
                }
                if (type == 'Video') {
                    return media.extention.startsWith('mp4');
                }
                if (type == 'Audio') {
                    return media.extention.startsWith('mp3');
                }
            }
        }
    },

    methods: {
        selectedmediaClose(done){
            this.selectedmedia = {};
            done();
        },
        beforemediaClose(done) {
            // this.canshowmedia = false;
            this.beforemedia = {};
            done();
        },
        CloseMedia(){
            this.selectedmedia = {};
        },
        deleteMedia(row) {
            this.$confirm('Are you sure you want to Delete?', 'Warning', {
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancel',
                type: 'warning'
            }).then(() => {
                this.$emit("deleteMedia",row);
            }).catch(() => {
                this.$message({
                    showClose: true,
                    type: 'info',
                    message: 'Delete canceled'
                });
            });
        },
        downloadmedia(row) {
            var link = document.createElement("a");
            link.href = row.link;
            link.download = row.name;
            link.click();
        },
        showbeforemedia(i) {
            i.extention = i.originalname.substring(i.originalname.lastIndexOf('.') + 1).toLowerCase();
            i.link = URL.createObjectURL(i.content);
            i.show = true;
            URL.revokeObjectURL(i.content);
            this.beforemedia = i;
            this.$forceUpdate();
        },
        showselectedmedia(i) {
            i.link = i.link.trim();
            i.name = i.name.trim();
            i.extention = i.name.substring(i.name.lastIndexOf('.') + 1).toLowerCase();
            i.showdialog = true;
            if(i.link && i.link[i.link.length - 1] == "0"){
                i.link = `${i.link.substring(0, i.link.length - 1)}1`;    
            }
            i.externalurl = `${i.link.substring(0, i.link.length - 1)}0`;
            if (i.extention === "mp4") {
                i.url = `${i.link.substring(0, i.link.length - 4)}raw=1`;
            }
            this.selectedmedia = i;
            this.$forceUpdate();
        },
        openmedia(media, istype) {
            this.canshowmedia = true;
            if (istype == "beforemedia") {
                this.showbeforemedia(media);
                // this.beforemedia = media; 
            }
            if (istype == "selectedmedia") {
                this.showselectedmedia(media);
                // this.selectedmedia = media;
            }
        },
    },
};
</script>