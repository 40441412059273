<template>
    <div>
        <div style="width: 335px">
            <div class="fixdheader">
                <span class="headerstyle">Unique Reference(UR)</span>
                <el-row>
                    <span class="left" style="width: 70px; display: inline-block">
                        <el-input size="mini" placeholder="Search UR" v-model="search"></el-input>
                    </span>
                <span class="right">
                    <span class="padright8px">
                        <el-button
                            :disabled="!editpermission || isedit"
                            icon="el-icon-circle-plus"
                             class="icon-btn-width"
                            type="primary"
                            @click="AddUR"
                        ></el-button>
                    </span>
                </span>
                </el-row>

                <el-row>
                    <el-col>
                        <span class="left">
                            <el-pagination small
                                style="padding: 5px 0px"
                                class="pagesizeswidth textalignlpage"
                                @size-change="rowperpages"
                                @current-change="pageCurrentChange"
                                :page-sizes="pagesizes"
                                :page-size="pagesize"
                                layout="sizes, prev, pager, next"
                                :total="total">
                            </el-pagination>
                        </span>
                    </el-col>
                </el-row>
                <hr class="hrstyle" />
            </div>
            <div class="tablepaddinglr3">
                <el-table :data="getdetailform" :height="height" size="mini">
                    <el-table-column prop="ur" label="UR" width="250">
                        <template slot-scope="scope">
                            <el-select
                                v-if="scope.row.edit"
                                v-model="scope.row.ur"
                                clearable
                                remote
                                :remote-method="remoteMethod"
                                reserve-keyword
                                filterable
                                placeholder="UR"
                            >
                                <el-option
                                    v-for="(item, index) in ursoptions"
                                    :key="index"
                                    :label="item.ur"
                                    :value="item.ur"
                                >
                                </el-option>
                            </el-select>
                            <div v-else>{{ scope.row.ur }}</div>
                        </template>
                    </el-table-column>
                    <!--:label="'\xa0\xa0'+'Edit' +'\xa0\xa0\xa0\xa0\xa0\xa0'+'Delete' + '\xa0\xa0'"-->
                    <el-table-column label="Actions" width="80" align="center">
                        <template slot-scope="scope">
                            <el-button
                                size="mini"
                                v-if="!scope.row.edit"
                                :disabled="!editpermission || isedit"
                                type="primary"
                                class="icon-btn-width"
                                icon="el-icon-edit"
                                @click="EditUR(scope.row)"
                            ></el-button>
                            <el-button
                                size="mini"
                                v-if="scope.row.edit"
                                type="success"
                                class="icon-btn-width"
                                icon="el-icon-circle-check"
                                @click="AddEditUR(scope.row)"
                                ></el-button>
                                <el-button
                                    size="mini"
                                    type="danger"
                                    class="icon-btn-width"
                                    :disabled="!editpermission || (isedit && scope.row.id != 0)"
                                    icon="el-icon-delete"
                                    @click="DeleteUR(scope.row)"
                                ></el-button>
                        </template>
                    </el-table-column>

                    <!-- <el-table-column label="Delete" width="45" align="center">
                        <template slot-scope="scope">
                            <el-button
                                size="mini"
                                type="danger"
                                class="icon-btn-width"
                                :disabled="!editpermission || (isedit && scope.row.id != 0)"
                                icon="el-icon-delete"
                                @click="DeleteUR(scope.row)"
                            ></el-button>
                        </template>
                    </el-table-column> -->
                </el-table>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            search:"",
            isedit:false,
            urs: [],
            ursoptions: [],
             page: 1,
             pagesize: 10,
             total: 0,
             height: (document.documentElement.clientHeight - 160),
             pagesizes: this.$store.state.pagesizes,
        };
    },
    computed:{
           getdetailform: function () {
                if (this.urs.length == 0)
                    return [];

                var selecturs = [];
                selecturs = this.urs.filter((u) => {
                    var show = true;
                    if (this.search && u.id > 0) {
                        show = u.ur.includes(this.search);

                    }
                    if(!show){
                         show = u.id <= 0;
                    }
                    return show;
                });
                this.total = selecturs.length;
                if (this.total == 0) {
                    return [];
                }
                if (!(Math.ceil(this.total / this.pagesize) >= this.page)) {
                    this.page = Math.ceil(this.total / this.pagesize);
                }
                return selecturs.slice(this.pagesize * this.page - this.pagesize, this.pagesize * this.page);

            }
    },
    methods: {
        getheight() {
            this.height = (document.documentElement.clientHeight - 160);
        },

         pageCurrentChange: function (val) {
            this.page = val;
         },

         rowperpages: async function (val) {
            try {
                this.usergridrow.ugv.rowperpage = val;
                this.usergridrow = await this.$training.savepagelayout(this.usergridrow);
                this.page = 1;
                this.pagesize = val;
                // this.refresh2();
            } catch (error) {
                this.$bus.$emit('error', error);
            }
        },

        remoteMethod(data) {
            if (data !== "") {
                if (data != "") {
                    var arr2 = [];
                    data = parseInt(data);
                    data = data.toString();
                    if (data.length <= 4 && data !== "NaN") {
                        this.ursoptions = [];
                        while (arr2.length < 5) {
                            var r = Math.floor(Math.random() * 90000) + 10000;
                            var t = arr2.indexOf(r);
                            if (t === -1 && r.toString().includes(data)) {
                                arr2.push({ id: 0, ur: r });
                            }
                        }
                        this.ursoptions.push(...arr2);
                    } else {
                        this.ursoptions = [];
                        var tt = this.urs.filter((x) => {
                            if (x.ur.includes(data)) {
                                return x;
                            }
                        });
                        this.ursoptions.push(...tt);
                    }
                }
            } else {
                var myarr = [];
                this.ursoptions = [];
                while (myarr.length < 5) {
                    var r = Math.floor(Math.random() * 90000) + 10000;
                    var t = myarr.indexOf(r);
                    if (t === -1) {
                        myarr.push({ id: 0, ur: r });
                    }
                }
                this.ursoptions = myarr;
            }
        },
        AddUR() {
            this.isedit = true;
            var obj = { ur: "", id: 0, edit: true };
            this.pageCurrentChange(1);
            this.urs.unshift(obj);
        },
        EditUR(data) {
             this.isedit = true;
            this.$set(data, "edit", true);
        },
        DeleteUR(data) {
            this.$http
                .post("project/DeleteUR", data)
                .then((response) => {
                    this.getur();
                    this.$message({
                        showClose: true,
                        type: "success",
                        message: response.data,
                    });
                })
                .catch(err =>{
                    this.$bus.$emit('error', err);
                });
        },
        AddEditUR(data) {
            data.ur = data.ur.toString();
            if (this.isvalid()) {
            this.$http
                .post("project/AddEditUR", data)
                .then((response) => {
                    this.$message({
                        showClose: true,
                        type: "success",
                        message: response.data,
                    });
                    this.getur();
                })
                .catch(err =>{
                    this.$bus.$emit('error', err);
                });
            }
        },
        isvalid: function () {
            var msg = [];
            for (var i = 0; i < this.urs.length; i++) {
                var a = this.urs[i];
                for (var j = i + 1; j < this.urs.length; j++) {
                    var b = this.urs[j];
                    if (
                        a.ur == b.ur &&
                        a.ur != null &&
                        b.ur != null &&
                        b.ur != "" &&
                        a.ur != ""
                    ) {
                        var msgname = "UR No. " + a.ur + " is duplicate ";
                        var check = msg.find((x) => x == msgname);
                        if (!check) {
                            msg.push(msgname);
                        }
                    }
                }
            }
            if (msg.length > 0) {
                this.$alert(msg.join("</br>"), "Invalid Data", {
                    dangerouslyUseHTMLString: true,
                });
                return false;
            }
            return true;
        },
        getur() {
            this.$http
                .get("project/GetUR")
                .then((response) => {
                    this.isedit = false;
                    this.remoteMethod("");
                    this.urs = response.data;
                })
                .catch(err =>{
                    this.$bus.$emit('error', err);
                });
        },
    },

    created() {
        let gridrow = this.$training.getusergridrow(this.$route);
        this.pagesize = gridrow.ugv.rowperpage;
        this.usergridrow = gridrow;
        this.getur();
    },

    destroyed() {
        window.removeEventListener('resize', this.getheight);
    },

    mounted: function () {
        window.addEventListener('resize', this.getheight);
    },
};
</script>
<style>
.el-table th.el-table__cell > .cell {
    padding-left: 3px;
    padding-right: 3px;
}
.icon-btn-width {
    padding: 7px;
}
</style>
