<template>
    <div>
        <el-row>
            <el-col :span="12">
                <el-form ref="detailform" :model="detailform" label-position="left" label-width="108px" size="mini">
                    <el-form-item>
                        <h2>{{title}} - Edit</h2>
                    </el-form-item>

                    <el-form-item label="Library" prop="library" class="labelheight">
                        <el-col :span="19">
                            <el-select class="libraryfixwidth"
                                        v-model="detailform.library" 
                                        filterable placeholder="Library" 
                                        @change="libraryChange" disabled>
                                <el-option v-for="item in libraries"
                                           :key="item"
                                           :label="item"
                                           :value="item">
                                </el-option>
                            </el-select>
                        </el-col>

                        <el-col class="marginl5 center" :span="2">
                            SIL
                            <el-popover placement="top" trigger="hover"
                                        content="Specification Insert Level - within Library">
                                <i slot="reference" class="el-icon-info"></i>
                            </el-popover>
                        </el-col>

                        <el-col class="marginl5" :span="2">MoM Code</el-col>
                    </el-form-item>

                    <el-form-item label="BQ Code">
                        <el-row>
                            <el-col :span="6">
                                <el-input ref="bqcode" 
                                          class="padright5px"
                                          v-model="detailform.bqcode" 
                                          placeholder="BQ Code" disabled></el-input>
                            </el-col>      
                            <el-col :span="13">
                                <el-input v-model="detailform.bqcodename"
                                        placeholder="BQ Code Name" disabled></el-input>
                            </el-col>                      
                        </el-row>
                    </el-form-item>

                    <el-form-item label="Section">
                        <el-row>
                            <el-col :span="19">
                                <el-input type="textarea"
                                          :id="'project_bqcodeedit_section'"
                                          :autosize="{ minRows: 2}"
                                          v-model="detailform.section"
                                          placeholder="Section"></el-input>
                            </el-col>
                            <el-col class="marginl5" :span="2"></el-col>
                            <el-col class="marginl5" :span="2">{{ detailform.sectioncode }}</el-col>
                        </el-row>
                    </el-form-item>

                    <el-form-item label="Group">
                        <el-row>
                            <el-col :span="19">
                                <el-input type="textarea"
                                          :id="'project_bqcodeedit_group'"
                                          :autosize="{ minRows: 2}"
                                          v-model="detailform.group"
                                          placeholder="Group"></el-input>
                            </el-col>
                            <el-col class="marginl5" :span="2"></el-col>
                            <el-col class="marginl5" :span="2">{{ detailform.groupcode }}</el-col>
                        </el-row>
                    </el-form-item>

                    <el-form-item label="Item or Work">
                        <el-row>
                            <el-col :span="19">
                                <el-input type="textarea"
                                          :id="'project_bqcodeedit_itemorwork'"
                                          :autosize="{ minRows: 2}"
                                          v-model="detailform.work" placeholder="Item or Work"></el-input>
                            </el-col>

                            <el-col class="marginl5 padleft2percent" :span="2">
                                <el-radio v-model="detailform.specification" :label="0">&nbsp;</el-radio>
                            </el-col>
                            <el-col class="marginl5" :span="2">{{ detailform.workcode }}</el-col>
                        </el-row>
                    </el-form-item>

                    <el-form-item label="Level 1">
                        <el-row>
                            <el-col :span="19">
                                <el-input type="textarea"
                                          :id="'project_bqcodeedit_level1'"
                                          :autosize="{ minRows: 2}"
                                          v-model="detailform.level1" placeholder="Level 1">
                                </el-input>
                            </el-col>

                            <el-col class="marginl5 padleft2percent" :span="2">
                                <el-radio v-model="detailform.specification" :label="1">&nbsp;</el-radio>
                            </el-col>
                            <el-col class="marginl5" :span="2">{{ detailform.level1code }}</el-col>
                        </el-row>
                    </el-form-item>

                    <el-form-item label="Level 2">
                        <el-row>
                            <el-col :span="19">
                                <el-input type="textarea"
                                          :id="'project_bqcodeedit_level2'"
                                          :autosize="{ minRows: 2}"
                                          v-model="detailform.level2" placeholder="Level 2">
                                </el-input>
                            </el-col>

                            <el-col class="marginl5 padleft2percent" :span="2">
                                <el-radio v-model="detailform.specification" :label="2">&nbsp;</el-radio>
                            </el-col>
                            <el-col class="marginl5" :span="2">{{ detailform.level2code }}</el-col>
                        </el-row>
                    </el-form-item>

                    <el-form-item label="Level 3">
                        <el-row>
                            <el-col :span="19">
                                <el-input type="textarea"
                                          :id="'project_bqcodeedit_level3'"
                                          :autosize="{ minRows: 3}"
                                          v-model="detailform.level3" placeholder="Level 3">
                                </el-input>
                            </el-col>

                            <el-col class="marginl5 padleft2percent" :span="2">
                                <el-radio v-model="detailform.specification" :label="3">&nbsp;</el-radio>
                            </el-col>
                            <el-col class="marginl5" :span="2">{{ detailform.level3code }}</el-col>
                        </el-row>
                    </el-form-item>

                    <el-form-item label="Unit">
                        <el-row>
                            <el-col :span="10">
                                <el-select class="libraryfixwidth" :id="'project_bqcodeedit_unit'" v-model="detailform.unit" placeholder="Unit">
                                    <el-option v-for="item in units"
                                               :key="item"
                                               :label="item"
                                               :value="item">
                                    </el-option>
                                </el-select>
                            </el-col>

                            <el-col :span="9">
                                <el-checkbox :border="true"
                                    class="checkboxwithborder floatright"
                                    label="Composite"></el-checkbox>
                            </el-col>
                        </el-row>
                    </el-form-item>

                    <el-form-item label="Dim Tolerance">
                        <el-row>
                            <el-col :span="5" class="padright5px">
                                <el-checkbox class="checkboxwithborder fullwidth" :border="true" label="N/A"></el-checkbox>
                            </el-col>

                            <el-col :span="7">
                                <el-input placeholder="Minimum"
                                          :id="'project_bqcodeedit_minimum'"
                                          class="padright5px rightimp"
                                          v-model="detailform.lowertolerance"
                                          @change="toleranceChange"></el-input>
                            </el-col>

                            <el-col :span="7">
                                <el-input placeholder="Maximum"
                                          :id="'project_bqcodeedit_maximum'"
                                          :spellcheck="isspellcheck" class="rightimp"
                                          v-model="detailform.uppertolerance"
                                          @change="toleranceChange"></el-input>
                            </el-col>
                        </el-row>
                    </el-form-item>

                    <el-form-item label="">
                        <el-button :disabled="!editpermission" @click="cellchanged('save_bqcodeproject',()=>{save(true)},false)" type="success" icon="el-icon-circle-check">Save</el-button>
                    </el-form-item>

                </el-form>
            </el-col>
            <el-col :span="12" :style="{height: (height+29) + 'px'}" class="verticalline">
                <div>
                    <span>
                        <el-button @click="levelUpClick()" 
                                    class="padding7 floatright"
                                    type="primary"
                                    icon="glyphicon glyphicon-arrow-up">One Level Up</el-button>
                    </span>
                    <span>
                        <div><b>Measurement Library</b></div>
                        <div>Current Level : {{ currentlevelname }}</div>
                    </span>
                </div>
                <div class="tablepaddinglr3">
                    <el-table :data="library"
                                :height="height-15"
                                :header-cell-style="tableheaderpadding"
                                v-loading="loadinglibrary"
                                @row-dblclick="librarydblclick"
                                :stripe="true">
                        <el-table-column prop="code"
                                        label="Code"
                                        align="center" 
                                        sortable width="60"></el-table-column>

                        <el-table-column prop="unit" 
                                        label="Unit" 
                                        align="center" 
                                        sortable width="55"></el-table-column>

                        <el-table-column prop="description" 
                                        label="Description(Click to drill down)" 
                                        sortable></el-table-column>
                    </el-table>
                </div>                
            </el-col>
        </el-row>
    </div>
</template>
<script>
    var timeout;
    export default {
        data() {
            return {
                height: (document.documentElement.clientHeight - 300),
                detail: false,
                tabledata: [],
                grid: {},
                detailform: {},
                errormessage: "",
                units: this.$store.state.units,
                libraries: this.$store.state.libraries,
                loading: true,
                library: [],
                loadinglibrary: false,
                level: 1,
                currentlevelname: 'Section',
                groupid: { 1: 0 },
                title: ""
            }
        },
        methods: {
            cellchanged(cellaction, callback, cancall) {
                const app = this;
                this.$directionutility.setcomponent(app);
                if (cancall) {
                    callback();
                    this.canmatchcellid(() => { }, cellaction, "");
                } else {
                    this.canmatchcellid(callback, cellaction, "");
                }
            },
            
            tableheaderpadding({ row, column, rowIndex, columnIndex }) {
                if (rowIndex === 0) {
                    return 'padding:0px;';
                }
            },
            focusEdit: function () {
                setTimeout(() => {
                    this.$refs.bqcode.focus();
                }, 100)
            },

            addRow: function () {
                var itm = { srno: this.detailform.details.length + 1, qtydim: 1 };
                itm.bqcodelibrary = { bqcode: '' };
                this.detailform.details.push(itm);
            },

            delRow: function (scope, row) {
                this.detailform.details.splice(row.srno - 1, 1);
                var srno = 1;
                for (var i = 0; i < this.detailform.details.length; i++) {
                    this.detailform.details[i].srno = srno++;
                }
            },

            createNew: function () {
                this.detailform = { library: 'SMM7', details: [], specification: 0, unit: null }
                this.addRow();
                this.detail = true;
                this.focusEdit();
                this.level = 1;
                this.currentlevelname = 'Section';
                this.loadlibrary();
            },

            libraryChange: function () {
                //clear selected levels
                this.detailform.section = null;
                this.detailform.sectioncode = null;
                this.detailform.group = null;
                this.detailform.groupcode = null;
                this.detailform.work = null;
                this.detailform.workcode = null;
                this.detailform.level1 = null;
                this.detailform.level1code = null;
                this.detailform.level2 = null;
                this.detailform.level2code = null;
                this.detailform.level3 = null;
                this.detailform.level3code = null;
                this.detailform.unit = null;

                this.level = 1;
                this.currentlevelname = 'Section';
                this.loadlibrary();
            },

            loadlibrary: function () {

                this.loadinglibrary = true;

                //if (this.level == 1 && this.detailform.library == 'NRM2') {
                //    this.groupid[this.level] = 0;
                //}
                //else if (this.level == 1 && this.detailform.library == 'SMM7') {
                //    this.groupid[this.level] = 0;
                //}

                //if (this.detailform.library == 'NRM2') {
                //    var url = "nrm2library/" + this.groupid[this.level];
                //}

                //else {
                //    var url = "smm7library/" + this.groupid[this.level];
                //}

                if (this.level == 1) {
                    this.groupid[this.level] = 0;
                }
                var url = "library/" + this.detailform.library + "/" + this.groupid[this.level];

                this.$http.get(url, {})
                    .then(response => {
                        this.library = JSON.parse(response.data)
                        this.loadinglibrary = false;
                    })
                    .catch(err => {
                        this.loadinglibrary = false;
                        this.$bus.$emit('error', err);
                        //console.log(err.response.data);
                        //this.$alert(err.response.data, err.response.status + ' ' + err.response.statusText, { dangerouslyUseHTMLString: true });
                    });
            },

            levelUpClick: function () {
                if (this.level > 1) {                    
                    switch (this.level) {
                        case 2:
                            this.currentlevelname = 'Section';
                            break;
                        case 3:
                            this.currentlevelname = 'Group';
                            break;
                        case 4:
                            this.currentlevelname = 'Item or Work';
                            break;
                        case 5:
                            this.currentlevelname = 'Level 1';
                            break;
                        case 6:
                            this.currentlevelname = 'Level 2';
                            break;
                    }
                    this.level -= 1;
                }
                else {
                    this.currentlevelname = 'Section';
                }
                this.loadlibrary();
            },

            librarydblclick: function (row, event) {
                switch (this.level) {
                    case 1:
                        this.detailform.section = row.description;
                        this.detailform.sectioncode = row.code;
                        this.currentlevelname = 'Group';
                        break;
                    case 2:
                        this.detailform.group = row.description;
                        this.detailform.groupcode = row.code;
                        this.currentlevelname = 'Item or Work';
                        if (this.detailform.bqcode == null) {
                            if (row.description != null && row.description.indexOf(":") > 0) {
                                this.detailform.bqcode = row.description.split(":")[0];
                            }
                        }
                        break;
                    case 3:
                        this.detailform.work = row.description;
                        this.detailform.workcode = row.code;
                        this.currentlevelname = 'Level 1';
                        if (this.detailform.unit == null && row.unit != null && this.units.indexOf(row.unit) >= 0) {
                            this.detailform.unit = row.unit;
                        }
                        break;
                    case 4:
                        this.detailform.level1 = row.description;
                        this.detailform.level1code = row.code;
                        this.currentlevelname = 'Level 2';
                        if (this.detailform.unit == null && row.unit != null && this.units.indexOf(row.unit) >= 0) {
                            this.detailform.unit = row.unit;
                        }
                        break;
                    case 5:
                        this.detailform.level2 = row.description;
                        this.detailform.level2code = row.code;
                        this.currentlevelname = 'Level 3';
                        if (this.detailform.unit == null && row.unit != null && this.units.indexOf(row.unit) >= 0) {
                            this.detailform.unit = row.unit;
                        }
                        break;
                    case 6:
                        this.detailform.level3 = row.description;
                        this.detailform.level3code = row.code;
                        if (this.detailform.unit == null && row.unit != null && this.units.indexOf(row.unit) >= 0) {
                            this.detailform.unit = row.unit;
                        }
                        break;
                }
                if (this.level < 6 && this.level >= 1) {
                    this.level += 1;
                    this.groupid[this.level] = row.nextgroupid;
                }
                this.loadlibrary();
            },
            save: function (item, event) {
                var url = this.bqcodelibrary ? "bqcodelibrary/save" : "project/saveprojectbqcode"

                if (this.isvalid()) {
                    this.detailform.bqcode = this.detailform.bqcode.concat(this.detailform.bqcodename);
                    this.$http.post(url, this.detailform)
                        .then(response => {
                            this.$message({
                                showClose: true,
                                type: 'success', message: response.data
                            });
                            this.detail = false;
                            //this.refresh();
                            this.$emit('closedialogue');
                            this.$emit('updateprojectbqcode', this.detailform.bqcodelibraryid);
                        })
                        .catch(err => {
                            this.$bus.$emit('error', err);
                        });
                }
            },

            splitprojectbqcode(row) {
                let value = row.bqcode.trim();
                let firstunderscore = value.indexOf('_');
                if (firstunderscore >= 0) {
                    row.bqcode = value.slice(0, firstunderscore);
                    row.bqcodename = value.slice(firstunderscore, value.length);
                } else {
                    row.bqcode = value;
                    row.bqcodename = "";
                }
            },

            getBQCode: function () {
                if (this.bqcodelibrary) {
                    var pb = { };
                    var url = 'bqcodelibrary/' + this.bqcodelibraryid
                }
                else {
                    var pb = { bqcodelibraryid: this.bqcodelibraryid, projectsectionid: this.projectsectionid };
                    var url = 'project/getprojectbqcode'
                }

                this.$http.post(url, pb)
                    .then(response => {
                        let json = JSON.parse(response.data);
                        this.splitprojectbqcode(json);
                        this.detailform = json;
                        this.detail = true;
                        this.level = 1;
                        this.currentlevelname = 'Section';
                        this.loadlibrary();
                        this.toleranceChange();
                    }).catch(err => {
                        this.$bus.$emit('error', err);
                        //console.log(err);
                        //this.$alert(err.response.data, err.response.status + ' ' + err.response.statusText, { dangerouslyUseHTMLString: true });
                    });
            },
            toleranceChange: function () {
                var lower = parseFloat(this.detailform.lowertolerance);
                var upper = parseFloat(this.detailform.uppertolerance);
                window.setTimeout(() => {
                    this.detailform.lowertolerance = (isNaN(lower)) ? null : lower.toFixed(3);
                    this.detailform.uppertolerance = (isNaN(upper)) ? null : upper.toFixed(3);
                }, 100)
            },
            isvalid: function () {
                var msg = [];
                if (!this.detailform.bqcode)
                    msg.push("BQCode must not be empty");
                if (!this.detailform.section)
                    msg.push("Section must not be empty");
                if (!this.detailform.group)
                    msg.push("Group must not be empty");
                if (!this.detailform.work)
                    msg.push("Item or work must not be empty");
                if (!this.detailform.level1)
                    msg.push("Level1 must not be empty");

                //if (!this.detailform.level2)
                //    msg.push("Level2 must not be empty");
                //if (!this.detailform.level3)
                //    msg.push("Level3 must not be empty");

                if (!this.detailform.unit)
                    msg.push("Unit must not be empty");


                if (this.detailform.work && !this.detailform.workcode)
                    msg.push("Item or Work code is empty, please select it from library");

                if (this.detailform.level1 && !this.detailform.level1code)
                    msg.push("Level 1 code is empty, please select it from library");

                /*
                if (this.detailform.level2 && !this.detailform.level2code)
                    msg.push("Level 2 code is empty, please select it from library");

                if (this.detailform.level3 && !this.detailform.level3code)
                    msg.push("Level 3 code is empty, please select it from library");
                */

                if (msg.length > 0) {

                    this.$alert(msg.join("</br>"), "Validation Error", { dangerouslyUseHTMLString: true });

                    return false;
                }
                return true;
            }
        },

        created: function () {
            this.getBQCode();
            this.title = this.bqcodelibrary ? "BQ Code Library" : "Project BQ Code";
        },

        props: {
            bqcodelibraryid: Number,
            projectsectionid: Number,
            bqcodelibrary: Boolean
        }
    }
</script>
<style>
</style>
