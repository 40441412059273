<template>
    <div class="width855px">
        <div class="fixdheader">
            <span class="headerstyle">CQC Export List</span>
            <div class="floatright margintb1mm">
                <span class="padright8px" v-if="detail">
                    <el-button class="right" @click="Excelfile()"
                               :disabled="!editpermission"
                               type="success" size="mini" :id="'analysis_cqcexport_export'">Export</el-button>
                </span>
            </div>
            <div>
                <selectpart :Similar="Similar" @projectpartchange="projectpartchange">
                </selectpart>
            </div>
            <hr class="hrstyle" />
            <!--<hr class="hrstyle marginright160px" />-->
        </div>
        <!-- <el-row >
            <el-col :span="5">
                <h5><b>Export Unique References to:</b></h5>
            </el-col>
            <el-col :span="3">
               <el-checkbox style="margin-top: 6px;" v-model="Etrue"> Excel (CSV)</el-checkbox>
            </el-col>
            <el-col :span="10">
                <el-checkbox v-model="Gtrue">
               Google Sheet:
          <el-input
            v-model="project.googleclientid"
            size="small"
            placeholder="Enter unique google sheet reference"
          ></el-input>
                </el-checkbox>
            </el-col>
        </el-row> -->
        <div v-if="detail">
            <el-table ref="cqctable"
                      :data="urdata"
                      :border="true"
                      :show-header="true"
                      :header-cell-style="tableHeaderColor">

                <!-- <el-table-column prop="cqclibrary" label="UR Nr." width="60">
                    <template slot-scope="t">
                        <div class="UR-Link" @click="$router.push({name:'Libraries > CQC Library', path: '/cqclibrary', params: {exporturonly:t.row.cqclibrary}})">{{t.row.cqclibrary.uniquereference}}</div>
                    </template>
                </el-table-column> -->

                <el-table-column label="Section Name" class-name="padleft5px" align="left" width="290">
                    <template slot-scope="t">
                        {{ t.row.ps.sectiondisplay }}
                    </template>
                </el-table-column>

                <el-table-column prop="cqclibrary" label="CQC Name (Hyperlined to calculation)" class-name="padleft5px" header-align="center">
                    <template slot-scope="t">
                        <div class="UR-Link" @click="$router.push({name:'projectcqc', path: '/projectcqc', params: {exportonlysections:t.row.ps}})">{{t.row.cqclibrary.cqcname}}</div>
                    </template>
                </el-table-column>

                <el-table-column prop="cqclibrary" label="Unit" align="center" width="60">
                    <template slot-scope="t">
                        {{t.row.cqclibrary.unit}}
                    </template>
                </el-table-column>

                <el-table-column prop="cqclibrary" label="Total A" header-align="center" align="right" width="90">
                    <template slot-scope="t">
                        {{t.row.totala == 0 ? null : commaseparatednumber(t.row.totala, 3)}}
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>
<script>
    import selectpart from "./selectpart.vue";
    import Template from './template.vue';
    export default {
        data() {
            return {
                urdata: [],
                Similar: {
                    projectPlaceholder: '',
                    partPlaceholder: '',
                    lastsavedshow: true,
                },
                detail: false,
                project: {},
                currentcqc: null,
                projectpart: null,
            };
        },
        methods: {
            tableHeaderColor({ row, column, rowIndex, columnIndex }) {
                if (rowIndex === 0) {
                    return 'background-color: rgb(105, 104, 104);color: white;font-weight: bold;';
                }
            },
            getURdata: function () {
                //  var data = {id:this.project.id, projectpart:this.project.projectpart , SpreadsheetId:this.SpreadsheetId};
                this.$http
                    .post("project/getexporturdata", this.project)
                    .then((response) => {
                        this.hideloading();
                        this.urdata = response.data;
                    })
                    .catch((err) => {
                        this.hideloading();
                        this.$bus.$emit("error", err);
                    });
            },
            Excelfile: function () {
                this.showloading();
                //  var data = {id:this.project.id, projectpart:this.projectpart , SpreadsheetId:this.SpreadsheetId};
                var url = "project/getexporturfile";
                this.$http.post(url, this.project, { responseType: 'blob' })
                    .then(response => {
                        this.hideloading();
                        var blob = response.data;
                        var fileName = this.project.projectname + ' - ' + this.project.projectpart.name + '.xlsx';
                        var link = document.createElement('a');
                        link.href = window.URL.createObjectURL(blob);
                        link.download = fileName;
                        link.click();

                    }).catch((err) => {
                        this.$bus.$emit('error', err);
                        this.hideloading();
                    });
            },
            googlefile: function () {
                //  var data = {id:this.project.id, projectpart:this.projectpart , SpreadsheetId:this.SpreadsheetId};
                this.$http
                    .post("configuration/URdataToGoogleSheet", this.project)
                    .then((response) => {
                        this.hideloading();
                    })
                    .catch((err) => {
                        this.hideloading();
                        this.$bus.$emit("error", err);
                    });
            },
            // getexportUR() {
            //      if(!this.Gtrue && !this.Etrue){
            //          return this.$message.error('Please select export unique references !');
            //      }
            //     if (this.Gtrue) {
            //         if(this.project.googleclientid == ""){
            //             return this.$message.error('Please enter google sheet id !');
            //         }
            //        this.googlefile();
            //     }
            //     if(this.Etrue){
            //         this.Excelfile();
            //     }

            // },

            //Methods for first page
            projectpartchange: function (Projectobj) {
                this.project.id = Projectobj.project.id;
                this.project.projectname = Projectobj.project.projectname
                this.project.projectpart = Projectobj.part;
                this.refresh();
            },
            refresh: function () {
                if (this.project.id && this.project.projectpart.id >= 0) {
                    this.detail = true;
                    this.getURdata();
                    this.showloading();
                } else {
                    this.detail = false;
                }
            },
        },
        components: {
            selectpart: selectpart,
            Template,
        }
    };
</script>
<!-- <style scoped>
    .tableheader {
        background-color: rgb(0, 0, 0);
        color: white;
    }

    .UR {
        font-weight: bold;
        color: rgb(0, 0, 0);
    }
</style> -->
