<template>
    <div>
        <div :id="options.id"></div>
    </div>
</template>
<script>
import * as echarts from 'echarts';
export default {
    props: {
        options: {
            id:{type:String,default:"mychart"},
            rows: { type: Array, default: [] },
            labels: { type: Object, default: { count: "", lable: "" } },
            barcolor:{type:String,default:"#569bb9"},
            header:{type:String,default:""},
        }
    },
    watch: {
        'options.rows'(newQuestion, oldQuestion) {
            this.createnewchart();
        }
    },
    data() {
        return {
            labelOption:{
                    show: true,
                    rotate: 90,
                    position: 'insideBottom',
                    formatter: function (data) {
                        return data.name;
                    },
                    textStyle: {
                        align: 'left',
                        verticalAlign: 'middle'
                    },
                    color: '#000000',
                    fontFamily: 'Helvetica Neue,Helvetica,Arial,sans-serif',
                    fontSize: 12,
                },
               
        }
    },
    methods: {
        createnewchart() {
            // if (this.options.rows.length > 0) {
                const self = this;
                var labels = [];
                var keys = [];
                this.options.rows.forEach(e => {
                    labels.push(e[this.options.labels.lable]);
                    keys.push(e[this.options.labels.count]);
                });             
                var myChart = echarts.init(document.getElementById(this.options.id));
                var option = {
                    grid: {
                        left: '3%',
                        right: '2%',
                        top: '5%',
                        bottom: '5%'
                    },
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'shadow'
                        },
                        formatter: function (params) {
                            return `${params[0].name} </br> ${params[0].marker} ${self.options.header} : ${params[0].value} `;
                        },
                    },
                    xAxis: [
                        {
                            data: labels,
                            show: false,
                        }
                    ],
                    yAxis: [
                        {
                            type: 'value'
                        }
                    ],
                    series: [
                        {
                            type: 'bar',
                            label: this.labelOption,
                            data: keys,
                            color: this.options.barcolor,
                            barMaxWidth:"50",
                        },
                    ]
                }

                myChart.resize({
                        width: window.innerWidth,
                        height: 400
                    }); 
                    myChart.setOption(option);
                window.addEventListener('resize', function () {
                    myChart.resize({
                        width: window.innerWidth,
                        height: 400
                    });
                });
            // }
        },
    },
    mounted() {
        this.createnewchart();

    },
    created() {
        this.createnewchart();
    }
}
</script>