<template>
    <div>
        <div class="directionseditstyletable" v-if="!displaymode">
            <div class="fixdheader">
                <span class="headerstyle">Header Library</span>
                <div class="floatright margintb1mm">
                    <span>
                        <el-button type="primary" style="padding: 4px 6px 2px;" @click="archivechange">
                            Show Archive
                            <el-switch v-model="additionalfilter" @change="archivechange"></el-switch>
                        </el-button>
                    </span>
                    <span v-if="!additionalfilter">
                        <el-button type="primary" :disabled="!editpermission" icon="el-icon-circle-plus"
                            class="padding7 marginleft4px" @click="addnew">Create New</el-button>
                    </span>
                    <span>
                        <el-button v-if="!additionalfilter && isarchiveselect" type="primary"
                            class="padding7 marginleft4px" @click="addmultiarchive">
                            Add To Archive
                        </el-button>
                    </span>
                </div>
                <div class="spaceBetween" />

                <div class="flexbox">
                    <span>
                        <el-input v-model="view.filter[0].value" placeholder="Header" @keyup.enter.native="filtersearch"
                            class="bold width260px">
                            <el-button slot="append" icon="el-icon-search" class="paddingl13" circle
                                @click="refresh"></el-button>
                        </el-input>
                    </span>

                    <span class="marginleft4px">
                        <el-select class="width155px" placeholder="Section" filterable clearable
                            v-model="view.filter[1].value" @change="filtersearch">
                            <el-option v-for="item in rfisections" :key="item.id" :label="item.section"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </span>

                    <span class="marginleft4px">
                        <el-pagination layout="sizes, prev, pager, next"
                            class="pagesizeswidth paddingunset textalignlpage" @size-change="rowperpages"
                            :total="view.count" @current-change="currentchange" :page-sizes="pagesizes"
                            :current-page.sync="view.pageno" :page-size="view.pagesize">
                        </el-pagination>
                    </span>
                </div>
                <hr class="hrstyle" />
            </div>

            <div class="tabletexttop tablepaddinglr0px tablewordbreak">
                <el-table :data="tabledata" :height="height" stripe
                    :default-sort="{ prop: 'hl.description', order: 'ascending' }" @sort-change="sortchange">
                    <el-table-column label="Section" class-name="columnpaddright" prop="rs.section" sortable="custom"
                        width="280">
                    </el-table-column>

                    <el-table-column label="Header" class-name="columnpaddright" prop="hl.description"
                        sortable="custom">
                    </el-table-column>

                    <el-table-column label="Author" prop="author.loginname" width="88"></el-table-column>

                    <el-table-column label="Actions" header-align="center" class-name="columnpaddlr0px" width="96">
                        <template slot-scope="scope">
                            <el-button type="primary" :icon="editpermission == true ? 'el-icon-edit' : 'el-icon-view'"
                                class="padding7" @click="editrow(scope.row)"></el-button>
                            <el-button type="primary" icon="glyphicon glyphicon-duplicate" class="paddingforcopybutton"
                                @click="copyrow(scope.row)" :disabled="!editpermission"></el-button>
                            <el-button type="danger" icon="el-icon-delete" class="padding7"
                                @click="deleterow(scope.row)" :disabled="!editpermission"></el-button>
                        </template>
                    </el-table-column>

                    <el-table-column v-if="additionalfilter == false" align="center" width="40">
                        <template slot="header">
                            <span>
                                <i class="glyphicon glyphicon-eye-close bqcodearchiveiconstyle"></i>
                            </span>
                        </template>

                        <template slot-scope="scope">
                            <el-checkbox v-model="scope.row.isarchive" @change="selectarchive(scope.row, scope.$index)"
                                class="archiveborderedtickbox" border></el-checkbox>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>

        <div v-if="displaymode" class="directionseditstyletable">
            <div class="fixdheader">
                <span class="headerstyle">Header</span>
                <div class="floatright margintb1mm">
                    <span class="floatright marginleft4px">
                        <el-button type="info" icon="el-icon-back" @click="goback">Back</el-button>
                    </span>
                </div>
                <hr class="hrstyle" />
                <div>
                    <span>
                        <el-button type="success" :disabled="!editpermission" v-loading.fullscreen.lock="saving"
                            @click="cellchanged('library_comparativeslibrary_addedit_save', () => { save() }, false)">Save</el-button>
                    </span>

                    <span class="marginleft4px">
                        <el-checkbox class="checkboxwithborder" :border="true" v-model="detailform.headerlibrary"
                            label="Keep"></el-checkbox>
                    </span>

                    <span>
                        <el-button type="primary" class="spellbtn marginleft4px btnwithswitch"
                            @click="spellcheckMethod()">
                            Spell Check:
                            <span>
                                <el-switch @change="spellcheck()" v-model="spell" class="marginbottom2"
                                    active-color="primary" :active-value="true" :inactive-value="false">
                                </el-switch>
                            </span>
                        </el-button>
                    </span>

                    <span class="verticalalignmiddle marginleft3px">
                        <i class="el-icon-info fontsize24px infocolor"></i>
                    </span>
                </div>
                <hr class="hrstyle" />

                <div>
                    <el-select class="reportname_width" placeholder="Section" filterable clearable
                        v-model="detailform.rfisectionid" @change="changeinputs()">
                        <el-option v-for="item in rfisections" :key="item.id" :label="item.section" :value="item.id">
                        </el-option>
                    </el-select>
                </div>
                <hr class="hrstyle" />
            </div>

            <div class="fullwidth flexbox">
                <div class="flexbox flexcontent marginright5px">
                    <el-input ref="spell" :spellcheck="spell" @click="onspellclick" type="textarea" placeholder="Header"
                        class="spellstyle bqtextarea fullwidth" v-model="detailform.description"
                        :autosize="{ minRows: 2, maxRows: 4 }" maxlength="300" show-word-limit @change="changeinputs()"
                        @input="charcount($event, 300, 'description')">
                    </el-input>
                </div>

                <div class="flex-shrink-zero">
                    <span class="floatright">
                        <i class="el-icon-info fontsize18px infocolor"></i>
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            displaymode: false,
            height: (document.documentElement.clientHeight - 130),
            view: {
                pagesize: 20,
                filter: [
                    { table: 'hl', column: 'description', value: "" },
                    { table: 'hl', column: 'rfisectionid', value: "" },
                ],
                sortcol: "hl.description",
                sortorder: "ascending",
                defaultfilter: ""
            },
            pagesizes: this.$store.state.pagesizes,
            tabledata: [],
            rfisections: [],
            detailform: {},
            archivearray: [],
            additionalfilter: false,
            isarchiveselect: false,
            saving: false,
            spell: true,
            spellclick: null,
            togglespell: true
        }
    },

    methods: {
        cellchanged(cellaction, callback, cancall) {
            const app = this;
            this.$directionutility.setcomponent(app);
            if (cancall) {
                callback();
                this.canmatchcellid(() => { }, cellaction, "");
            } else {
                this.canmatchcellid(callback, cellaction, "");
            }
        },

        getheight() {
            this.height = (document.documentElement.clientHeight - 130);
        },

        currentchange(val) {
            this.view.pageno = val;
            this.refresh();
        },

        rowperpages: async function (val) {
            try {
                this.usergridrow.ugv.rowperpage = val;
                this.usergridrow = await this.$training.savepagelayout(this.usergridrow);
                this.view.pageno = 1;
                this.view.pagesize = val;
                this.refresh();
            } catch (error) {
                this.$bus.$emit('error', error);
            }
        },

        filtersearch() {
            this.view.pageno = 1;
            this.refresh();
        },

        sortchange(sort) {
            this.view.sortcol = sort.prop;
            this.view.sortorder = sort.order;
            this.refresh();
        },

        archivechange() {
            this.view.pageno = 1;
            this.additionalfilter = this.additionalfilter == false ? true : false;
            this.refresh();
        },

        refresh() {
            this.showloading();
            let data = JSON.parse(JSON.stringify(this.view));
            data.filter[1].value = data.filter[1].value.toString();
            this.$http.post("headerlibrary/getheaderview?isarchive=" + this.additionalfilter, data)
                .then(response => {
                    this.tabledata = response.data.data;
                    this.view.count = response.data.view.count;
                    this.hideloading();
                })
                .catch((err) => {
                    this.hideloading();
                    this.$bus.$emit('error', err);
                });
        },

        addnew() {
            this.additionalfilter = false;
            let itm = {
                description: "",
                userid: null,
                rfisectionid: null,
                keepheaderlibrary: false
            };

            this.detailform = itm;
            this.detailform.changed = true;
            this.displaymode = true;
            this.$refs.spell.focus();
        },

        changeinputs() {
            this.detailform.changed = true;
        },

        myvalidate: function (callback) {
            var msg = [];
            if (this.detailform.description.trim() == "") {
                msg.push("Header is required.");
            }
            if (this.detailform.rfisectionid == null && this.detailform.rfisectionid == 0) {
                msg.push("Please select 'Section' from dropdown");
            }
            if (msg.length > 0) {
                var style = "<style> .el-message-box {width:50%;} </style>";
                this.$alert(msg.join("<br />") + style, "Invalid Data", {
                    dangerouslyUseHTMLString: true,
                });
            } else {
                callback();
            }
        },

        save() {
            this.myvalidate(() => {
                this.saving = true;
                if (this.saving) {
                    this.showloading();
                    this.$http.post("headerlibrary/save?isarchive=" + this.additionalfilter, this.detailform)
                        .then((response) => {
                            this.$message({
                                type: "success",
                                showClose: true,
                                message: response.data
                            });
                            this.hideloading();
                            this.displaymode = false;
                            this.saving = false;
                            this.refresh();
                        })
                        .catch((err) => {
                            this.hideloading();
                            this.saving = false;
                            this.$bus.$emit('error', err);
                        });
                }
                else {
                    this.saving = false;
                    this.$message({
                        type: "info",
                        showClose: true,
                        message: "Nothing to save"
                    });
                }
            });
        },

        copyrow(row) {
            this.showloading();
            this.$http.post("headerlibrary/getheaderbyid?isarchive=" + this.additionalfilter, { id: row.hl.id })
                .then((response) => {
                    this.detailform = response.data.hl;
                    this.additionalfilter = false;
                    this.hideloading();
                    this.$prompt('Please enter new Header', 'Tip', {
                        confirmButtonText: 'OK',
                        cancelButtonText: 'Cancel',
                        inputValue: this.detailform.description + " - Copy"
                    }).then(value => {
                        this.detailform.id = 0;
                        this.detailform.description = value.value;
                        this.save();
                    });
                })
                .catch((err) => {
                    this.hideloading();
                    this.$bus.$emit('error', err);
                });
        },

        editrow(row) {
            this.showloading();
            this.$http.post("headerlibrary/getheaderbyid?isarchive=" + this.additionalfilter, { id: row.hl.id })
                .then(response => {
                    let json = response.data.hl;
                    this.detailform = json;
                    this.displaymode = true;
                    this.hideloading();

                }).catch(err => {
                    this.hideloading();
                    this.$bus.$emit('error', err);
                });
        },

        deleterow(row) {
            this.$confirm('This will permanently delete ' + row.hl.description + '. Continue?', 'Warning', {
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancel',
                type: 'warning'
            }).then(() => {
                this.showloading();
                this.$http.post("headerlibrary/deleteheaderlibrary?isarchive=" + this.additionalfilter, { id: row.hl.id })
                    .then(response => {
                        this.hideloading();
                        this.$message({
                            showClose: true,
                            message: response.data.message,
                            type: "success"
                        });
                        this.refresh();
                    })
                    .catch(err => {
                        this.hideloading();
                        this.$bus.$emit('error', err);
                    });
            }).catch(() => {
                this.$message({
                    showClose: true,
                    type: 'info',
                    message: 'Delete canceled'
                });
            });
        },

        addmultiarchive() {
            let newarray = [];
            this.archivearray.forEach(element => {
                let obj = {
                    id: this.tabledata[element].hl.id
                }
                newarray.push(obj);
            });
            this.$http.post('headerlibrary/movemultiplebqcodestoarchive', newarray)
                .then(response => {
                    this.archivearray = [];
                    this.isarchiveselect = false;
                    this.$message({
                        showClose: true,
                        message: response.data, type: "success"
                    });
                    this.tabledata = [];
                    this.refresh();
                })
                .catch(err => {
                    this.$bus.$emit('error', err);
                });
        },

        selectarchive(row, index) {
            if (row.isarchive == true) {
                this.archivearray.push(index);
            } else {
                var ind = this.archivearray.findIndex(x => x == index);
                this.archivearray.splice(ind, 1);
            }
            if (this.archivearray.length == 0) {
                this.isarchiveselect = false;
            } else {
                this.isarchiveselect = true;
            }
        },

        charcount(val, maxlen, colname) {
            if (val.length > maxlen && colname == 'description') {
                let style = "<style> .el-message-box {width:30%;} </style>";
                this.$alert(`You have reached the maximum limit of ${maxlen} characters. ${style}`, "Length Limitation", {
                    dangerouslyUseHTMLString: true,
                });
            }
        },

        spellcheckMethod() {
            if (this.togglespell) {
                this.spell = this.spell == true ? false : true;
            }
            this.spellcheck();
            this.togglespell = true;
        },

        spellcheck() {
            this.togglespell = false;
            this.$refs.spell.focus();
        },

        onspellclick() {
            if (this.spell) {
                this.spell = false;
            }
        },

        goback() {
            var answer = true;
            if (this.detailform.changed) {
                answer = window.confirm('Do you really want to leave? you have unsaved changes!');
            }
            if (answer == true) {
                this.detailform = {
                    description: "",
                    userid: null,
                    rfisectionid: null,
                    keepheaderlibrary: false
                };
                this.displaymode = false;
                this.refresh();
                this.detailform.changed = false;
            }
        },
    },

    destroyed() {
        window.removeEventListener('resize', this.getheight);
    },

    mounted() {
        window.addEventListener('resize', this.getheight);
        if (this.displaymode) {
            this.spell = true;
            this.$set(this.$refs.spell, 'autofocus', true);
            this.$set(this.$refs.spell, 'spellcheck', true);
            this.$refs.spell.focus();
        }
    },

    beforeRouteLeave(to, from, next) {
        var answer = true;
        if (this.detailform.changed) {
            answer = window.confirm('Do you really want to leave? you have unsaved changes!');
        }
        if (answer == true) {
            next()
            window.onbeforeunload = null;
        } else {
            next(false)
        }
    },

    created() {
        this.$training.getallrfisection().then((data) => {
            this.rfisections = data;
        });
        let gridrow = this.$training.getusergridrow(this.$route);
        this.view.pagesize = gridrow.ugv.rowperpage;
        this.usergridrow = gridrow;
        this.refresh();
    }
}
</script>