<template>
    <div>
        <el-row><span class="headerstyle">Project Comparative Quantity Checks</span></el-row>
        <projectselectpart @projectsectionchange="projectsectionchange"></projectselectpart>

        <hr style="margin-top:5px; margin-bottom:5px" />
        <div v-show="detail">
            <el-table :data="detailform"
                      class="fullwidth" size="mini"
                      :default-expand-all="true"
                      >
                <el-table-column type="expand"
                                 >
                    <template slot-scope="ca" class="fullwidth">
                        <el-table :data="ca.row.details"
                                  class="fullwidth" size="mini"
                                  :header-cell-style="headerstyle">
                            <el-table-column prop="unit" label="Unit">
                            </el-table-column>
                            <el-table-column prop="nrmsection" label="NRM Section">
                            </el-table-column>
                            <el-table-column prop="section" label="Section">
                            </el-table-column>
                            <el-table-column prop="group" label="Group">
                            </el-table-column>
                            <el-table-column prop="work" label="Work">
                            </el-table-column>
                            <el-table-column prop="level1" label="Level 1">
                            </el-table-column>
                            <el-table-column prop="level2" label="Level 2">
                            </el-table-column>
                            <el-table-column prop="level3" label="Level 3">
                            </el-table-column>
                            <el-table-column prop="qty" label="Qty">
                            </el-table-column>
                        </el-table>
                    </template>
                </el-table-column>
                <el-table-column prop="unit" label="Unit">
                </el-table-column>
                <el-table-column prop="nrmsection" label="NRM Section">
                </el-table-column>
                <el-table-column prop="section" label="Section">
                </el-table-column>
                <el-table-column prop="group" label="Group">
                </el-table-column>
                <el-table-column prop="work" label="Work">
                </el-table-column>
                <el-table-column prop="level1" label="Level 1">
                </el-table-column>
                <el-table-column prop="level2" label="Level 2">
                </el-table-column>
                <el-table-column prop="level3" label="Level 3">
                </el-table-column>
                <el-table-column prop="qty" label="Qty">
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>

<script>

    import projectselectpart from './projectselectpart.vue';

    export default {
        data() {
            return {
                detail: false,
                project: { id: null, projectname: '', library: '' },
                sectionid: null,
                detailform: [],
                loading: false
            }
        },

        methods: {
            //Methods for first page

            projectsectionchange: function (project, sectionid) {
                this.sectionid = sectionid;
                this.project = project;
                this.refresh();
            },


            refresh: function () {
                if (this.project.id && this.sectionid) {
                    this.getProjectCA();
                }
                else {
                    this.detail = false;
                }
            },

            getProjectCA: function (id) {
                var data = {
                    id: this.project.id, section: { id: this.sectionid }
                };
                
                this.loading = true;
                this.$http.post('project/getComparativeAnalysis', data)
                    .then(response => {
                        var json = JSON.parse(response.data);
                        this.detailform = json;
                        this.attachments = [];
                        this.detail = true;
                        this.loading = false;
                        this.$nextTick(this.mymakesortable);
                    }).catch(err => {
                        this.loading = false;
                        this.$bus.$emit('error', err);
                        //console.log(err);
                        //this.$alert(err.response.data, err.response.status + ' ' + err.response.statusText, { dangerouslyUseHTMLString: true });
                    });
                
            },
            headerstyle: function ({ row, column, rowIndex, columnIndex }) {
                return "background-color: #99ebffa3;"
            }
        },
        created: function () {
        },
        components: {
            'projectselectpart': projectselectpart,
        }
    }
</script>
<style>
</style>
