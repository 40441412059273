<template>
  <div>
    <div class="fixdheader">
    <el-row>

      <el-col :span="4" class="headerstyle"> PSP Summary</el-col>
      <!--<el-col :span="18">
          <selectpart style="margin-top: 6px;"
                      :Similar="prdis"
                      @projectpartchange="projectpartchange">
          </selectpart>
      </el-col>-->
      <el-col :span="20" style="text-align:right; margin-top:5px;">
          <el-button icon="el-icon-back"
                     type="info"
                     id="setup_pspsummay_back"
                     @click="$router.go(-1)">Back</el-button>
      </el-col>
    </el-row>
    <el-row>
        <el-col :span="18">
            <selectpart style="margin-top: 6px;"
                        :Similar="prdis"
                        @projectpartchange="projectpartchange">
            </selectpart>
        </el-col>
    </el-row>
    <el-row >


          <!-- <el-date-picker
            v-model="datearr"
            type="daterange"
            size="mini"
            format="yyyy/MMM/dd"
            value-format="yyyy-MM-dd"
            @change="startenddatechang(datearr)"
            start-placeholder="Start date"
            end-placeholder="End date"
          >
          </el-date-picker> -->
          <el-col :span="4">
         <el-date-picker
            v-model="startdate"
            type="date"
            align="center"
            class="custom-date-picker-psp left"
             format="yyyy/MMM/dd"
             @change="startenddatechang"
            value-format="yyyy-MM-dd"
            placeholder="      Start Date"
            id="setup_pspsummay_startdate"
            :default-value="curentdate">
            </el-date-picker>


           <el-date-picker
            v-model="enddate"
            type="date"
            align="center"
            class="custom-date-picker-psp marginleft4px left"
             format="yyyy/MMM/dd"
             @change="startenddatechang"
            value-format="yyyy-MM-dd"
            id="setup_pspsummay_enddate"
            placeholder="       End Date"
            :default-value="curentdate"
            >
            </el-date-picker>
            </el-col>
      <el-col :span="20">
        <el-row>
          <el-col :span="4">
            <div style="min-width: 40px;
                    min-width: 30px;
                    display: inline-block;
                    margin-left: 40px;
                  " >
              <h5 class="marginTB0" style="color: red;">Common</h5>
            </div>
          </el-col>
          <el-col :span="4">
            <div style="min-width: 30px" class="padding2px fullwidth">
              <el-select
                v-model="com_psp1_search"
                 @change="pspcriteriasearchcom1(com_psp1_search)"
                placeholder="PSP Criteria 1"
                id="setup_pspsummay_psp_criteria_1"
                clearable
                class="fullwidth"
              >
                <el-option
                  v-for="item in com_psp1"
                  :key="item.id"
                  :label="item.value"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </div>
          </el-col>
          <el-col :span="4">
            <div style="min-width: 30px" class="padding2px fullwidth">
              <el-select
                v-model="com_psp2_search"
                  @change="pspcriteriasearchcom2(com_psp2_search)"
                placeholder="PSP Criteria 2"
                id="setup_pspsummay_psp_criteria_2"
                clearable
                class="fullwidth"
              >
                <el-option
                  v-for="item in com_psp2"
                  :key="item.id"
                  :label="item.value"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </div>
          </el-col>
          <el-col :span="4">
            <div style="min-width: 30px" class="padding2px fullwidth">
              <el-select
                v-model="com_psp3_search"
                  @change="pspcriteriasearchcom3"
                placeholder="PSP Criteria 3"
                id="setup_pspsummay_psp_criteria_3"
                clearable
                class="fullwidth"
              >
                <el-option
                  v-for="item in com_psp3"
                  :key="item.id"
                  :label="item.value"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </div>
          </el-col>
          <el-col :span="4">
            <div style="min-width: 30px" class="padding2px fullwidth">
              <el-select
                v-model="com_psp4_search"
                  @change="pspcriteriasearchcom4"
                placeholder="PSP Criteria 4"
                id="setup_pspsummay_psp_criteria_4"
                clearable
                class="fullwidth"
              >
                <el-option
                  v-for="item in com_psp4"
                  :key="item.id"
                  :label="item.value"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </div>
          </el-col>
          <!-- <el-col :span="3">
        <div style="min-width: 50px; display: inline-block">
          <el-select v-model="grouping" placeholder="Chunck Size"
           class="fullwidth">
            <el-option
              v-for="item in groupings"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>

      </el-col> -->
        </el-row>
        <hr class="spaceBetween_top" style="margin-left: 40px" />
      </el-col>
    </el-row>

    <el-row >
      <el-col :span="4">
        <h5 class="marginTB0">Project</h5>
      </el-col>
      <el-col :span="20">
        <div>
          <el-row>
            <el-col :span="4">
              <div style="min-width: 30px" class="padding2px fullwidth">
                <el-select
                  id="setup_pspsummay_project_groupings"
                  v-model="grouping"
                  class="fullwidth"
                  clearable
                  placeholder="Groupings"
                >
                  <el-option
                    v-for="item in groupings"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
            </el-col>
            <!-- <el-col :span="3">
        <div style="min-width: 40px; display: inline-block; margin-left: 30px;">
          <el-select v-model="grouping"  class="fullwidth" placeholder="Grouping">
            <el-option
              v-for="item in groupings"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
           <h5>Comman</h5>
        </div>
              </el-col> -->
            <el-col :span="4">
              <div style="min-width: 30px" class="padding2px fullwidth">
                <el-select
                  v-model="all_psp1_search"
                  @change="pspcriteria1search"
                  placeholder="PSP Criteria 1"
                  id="setup_pspsummay_project_psp_criteria_1"
                  multiple
                  @remove-tag="removetagcriteria"
                  class="fullwidth"
                >
                  <div v-if="grouping == ''">
                    <el-option-group
                      v-for="group in all_psp1_option"
                      :key="group.label"
                      :label="group.label"
                    >
                      <el-option
                        v-for="item in group.options"
                        :key="item.id"
                        :label="item.value"
                        :value="item.id"
                      >
                      </el-option>
                    </el-option-group>
                  </div>
                  <div v-if="grouping != ''">
                    <el-option-group
                      v-for="group in all_psp1"
                      :key="group.label"
                      :label="all_psp1[0][grouping + 'lable']"
                    >
                      <el-option
                        v-for="item in group[grouping]"
                        :key="item.id"
                        :label="item.value"
                        :value="item.id"
                      >
                      </el-option>
                    </el-option-group>
                  </div>
                </el-select>
              </div>
            </el-col>
            <el-col :span="4">
              <div style="min-width: 30px" class="padding2px fullwidth">
                <el-select
                  v-model="all_psp2_search"
                    @change="pspcriteria2search"
                  id="setup_pspsummay_project_psp_criteria_2"
                  placeholder="PSP Criteria 2"
                   @remove-tag="removetagcriteria"
                  multiple
                  class="fullwidth"
                >
                  <div v-if="grouping == ''">
                    <el-option-group
                      v-for="group in all_psp2_option"
                      :key="group.label"
                      :label="group.label"
                    >
                      <el-option
                        v-for="item in group.options"
                        :key="item.id"
                        :label="item.value"
                        :value="item.id"
                      >
                      </el-option>
                    </el-option-group>
                  </div>
                  <div v-if="grouping != ''">
                    <el-option-group
                      v-for="group in all_psp2"
                      :key="group.label"
                      :label="all_psp2[0][grouping + 'lable']"
                    >
                      <el-option
                        v-for="item in group[grouping]"
                        :key="item.id"
                        :label="item.value"
                        :value="item.id"
                      >
                      </el-option>
                    </el-option-group>
                  </div>
                </el-select>
              </div>
            </el-col>
            <el-col :span="4">
              <div style="min-width: 30px" class="padding2px fullwidth">
                <el-select
                  v-model="all_psp3_search"
                  placeholder="PSP Criteria 3"
                  id="setup_pspsummay_project_psp_criteria_3"
                    @change="pspcriteria3search"
                     @remove-tag="removetagcriteria"
                  multiple
                  class="fullwidth"
                >
                  <div v-if="grouping == ''">
                    <el-option-group
                      v-for="group in all_psp3_option"
                      :key="group.label"
                      :label="group.label"
                    >
                      <el-option
                        v-for="item in group.options"
                        :key="item.id"
                        :label="item.value"
                        :value="item.id"
                      >
                      </el-option>
                    </el-option-group>
                  </div>
                  <div v-if="grouping != ''">
                    <el-option-group
                      v-for="group in all_psp3"
                      :key="group.label"
                      :label="all_psp3[0][grouping + 'lable']"
                    >
                      <el-option
                        v-for="item in group[grouping]"
                        :key="item.id"
                        :label="item.value"
                        :value="item.id"
                      >
                      </el-option>
                    </el-option-group>
                  </div>
                </el-select>
              </div>
            </el-col>
            <el-col :span="4">
              <div style="min-width: 30px" class="padding2px fullwidth">
                <el-select
                  v-model="all_psp4_search"
                  placeholder="PSP Criteria 4"
                  id="setup_pspsummay_project_psp_criteria_4"
                    @change="pspcriteria4search"
                     @remove-tag="removetagcriteria"
                  multiple
                  class="fullwidth"
                >
                  <div v-if="grouping == ''">
                    <el-option-group
                      v-for="group in all_psp4_option"
                      :key="group.label"
                      :label="group.label"
                    >
                      <el-option
                        v-for="item in group.options"
                        :key="item.id"
                        :label="item.value"
                        :value="item.id"
                      >
                      </el-option>
                    </el-option-group>
                  </div>
                  <div v-if="grouping != ''">
                    <el-option-group
                      v-for="group in all_psp4"
                      :key="group.label"
                      :label="all_psp4[0][grouping + 'lable']"
                    >
                      <el-option
                        v-for="item in group[grouping]"
                        :key="item.id"
                        :label="item.value"
                        :value="item.id"
                      >
                      </el-option>
                    </el-option-group>
                  </div>
                </el-select>
              </div>
            </el-col>
            <el-col :span="4">
              <div style="min-width: 30px" class="padding2px fullwidth">
                <el-select
                  v-model="chunck"
                  id="setup_pspsummay_project_chunk"
                  placeholder="Chunk Size"
                  class="fullwidth"
                  clearable
                  @change="changechunksize"
                >
                  <el-option
                    v-for="item in chuncks"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
            </el-col>
          </el-row>
        </div>
      </el-col>
    </el-row>
     <hr class="hrstyle" />
    </div>
    <div v-for="item in detailform" :key="item.$index">
      <el-row>
        <el-col :span="4">
          <div class="projectbox">
            <div :style="styleclass(item.Project.projectname)">
              <hr class="spaceBetween" />
              <div class="projecthader">{{ item.Project.projectname }}</div>
              <hr class="spaceBetween" />
              <div
                v-if="item.Project.projectname"
                :style="projectparthader(item.Project.projectpart.name)"
              >
                {{
                  item.Project.projectpart.name == null
                    ? "Part- No Used"
                    : item.Project.projectpart.name
                }}
              </div>
              <hr class="spaceBetween" />
              <!-- <el-table
                :data="item.projectparts"
                class="fullwidth"
                :show-header="false"
                row-key="id"
                highlight-current-row
                :border="false"
                height="100px"
                empty-text="Part- No Used"
              >
                <el-table-column prop="name"> </el-table-column>
              </el-table> -->
              <span style="float: right; margin-right: 2%; font-size: 11px">{{
                getcredon(item.Project.createdon)
              }}</span>
            </div>
          </div>
        </el-col>
        <el-col :span="20">
          <div :style="styleclass">
            <hr class="spaceBetween_top" style="margin-left: 40px" />
            <el-row>
              <el-col :span="4">
                <div
                  style="
                    min-width: 30px;
                    display: inline-block;
                    margin-left: 40px;
                  "
                >
                  <h5 class="marginTB0" style="color: red">Common</h5>
                </div>
              </el-col>
              <el-col :span="4">
                <div style="min-width: 30px" class="padding2px fullwidth">
                  <el-select
                    :value="Criteriaedit(item.ComCriteria1id, com_psp1)"
                    placeholder=""
                    :id="'setup_pspsummay_project_com_psp_1_'+item.$index"
                    clearable
                    class="fullwidth"
                  >
                    <el-option
                      :disabled="true"
                      v-for="item in com_psp1"
                      :key="item.id"
                      :label="item.value"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </div>
              </el-col>
              <el-col :span="4">
                <div style="min-width: 30px" class="padding2px fullwidth">
                  <el-select
                    :value="Criteriaedit(item.ComCriteria2id, com_psp2)"
                    placeholder=""
                    :id="'setup_pspsummay_project_com_psp_2_'+item.$index"
                    clearable
                    class="fullwidth"
                  >
                    <el-option
                      :disabled="true"
                      v-for="item in com_psp2"
                      :key="item.id"
                      :label="item.value"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </div>
              </el-col>
              <el-col :span="4">
                <div style="min-width: 30px" class="padding2px fullwidth">
                  <el-select
                    :value="Criteriaedit(item.ComCriteria3id, com_psp3)"
                    placeholder=""
                    clearable
                    :id="'setup_pspsummay_project_com_psp_3_'+item.$index"
                    class="fullwidth"
                  >
                    <el-option
                      :disabled="true"
                      v-for="item in com_psp3"
                      :key="item.id"
                      :label="item.value"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </div>
              </el-col>
              <el-col :span="4">
                <div style="min-width: 30px" class="padding2px fullwidth">
                  <el-select
                    :value="Criteriaedit(item.ComCriteria4id, com_psp4)"
                    placeholder=""
                    :id="'setup_pspsummay_project_com_psp_4_'+item.$index"
                    clearable
                    class="fullwidth"
                  >
                    <el-option
                      :disabled="true"
                      v-for="item in com_psp4"
                      :key="item.id"
                      :label="item.value"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </div>
              </el-col>
              <!-- <el-col :span="4">
                <h5>This Is Cunck</h5>
              </el-col> -->
            </el-row>
            <div class="spaceBetween_middle" />
            <el-row v-if="grouping == 'sub' || grouping == ''">
              <el-col :span="4">
                <div
                  style="
                    min-width: 30px;
                    display: inline-block;
                    margin-left: 40px;
                  "
                >
                  <h5 class="marginTB0">Substructure</h5>
                </div>
              </el-col>
              <el-col :span="4">
                <div style="min-width: 30px" class="padding2px fullwidth">
                  <el-select
                    :value="Criteriaedit(item.SubCriteria1id, sub_psp1)"
                    :id="'setup_pspsummay_project_sub_psp_1_'+item.$index"
                    placeholder=""
                    clearable
                    class="fullwidth"
                  >
                    <el-option
                      :disabled="true"
                      v-for="item in sub_psp1"
                      :key="item.id"
                      :label="item.value"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </div>
              </el-col>
              <el-col :span="4">
                <div style="min-width: 30px" class="padding2px fullwidth">
                  <el-select
                    :value="Criteriaedit(item.SubCriteria2id, sub_psp2)"
                    :id="'setup_pspsummay_project_sub_psp_2_'+item.$index"
                    placeholder=""
                    clearable
                    class="fullwidth"
                  >
                    <el-option
                      :disabled="true"
                      v-for="item in sub_psp2"
                      :key="item.id"
                      :label="item.value"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </div>
              </el-col>
              <el-col :span="4">
                <div style="min-width: 30px" class="padding2px fullwidth">
                  <el-select
                    :value="Criteriaedit(item.SubCriteria3id, sub_psp3)"
                    :id="'setup_pspsummay_project_sub_psp_3_'+item.$index"
                    placeholder=""
                    clearable
                    class="fullwidth"
                  >
                    <el-option
                      :disabled="true"
                      v-for="item in sub_psp3"
                      :key="item.id"
                      :label="item.value"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </div>
              </el-col>
              <el-col :span="4">
                <div style="min-width: 30px" class="padding2px fullwidth">
                  <el-select
                    :value="Criteriaedit(item.SubCriteria4id, sub_psp4)"
                    :id="'setup_pspsummay_project_sub_psp_4_'+item.$index"
                    placeholder=""
                    clearable
                    class="fullwidth"
                  >
                    <el-option
                      :disabled="true"
                      v-for="item in sub_psp4"
                      :key="item.id"
                      :label="item.value"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </div>
              </el-col>
              <el-col :span="4">
                 Footprint (m2)
                 <br>
                 <span v-if="chunck == 'sub'">
                {{item.SubUR}}/ <span v-if="item.Subqty != 0">
                {{item.Subqty}}
                </span>
                 </span>
              </el-col>
            </el-row>
            <div class="spaceBetween_middle" />
            <el-row v-if="grouping == 'sup' || grouping == ''">
              <el-col :span="4">
                <div
                  style="
                    min-width: 30px;
                    display: inline-block;
                    margin-left: 40px;
                  "
                >
                  <h5 class="marginTB0">Superstructure</h5>
                </div>
              </el-col>
              <el-col :span="4">
                <div style="min-width: 30px" class="padding2px fullwidth">
                  <el-select
                    :value="Criteriaedit(item.SupCriteria1id, sup_psp1)"
                    :id="'setup_pspsummay_project_sup_psp_1_'+item.$index"
                    placeholder=""
                    clearable
                    class="fullwidth"
                  >
                    <el-option
                      :disabled="true"
                      v-for="item in sup_psp1"
                      :key="item.id"
                      :label="item.value"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </div>
              </el-col>
              <el-col :span="4">
                <div style="min-width: 30px" class="padding2px fullwidth">
                  <el-select
                    :value="Criteriaedit(item.SupCriteria2id, sup_psp2)"
                    :id="'setup_pspsummay_project_sup_psp_2_'+item.$index"
                    placeholder=""
                    clearable
                    class="fullwidth"
                  >
                    <el-option
                      :disabled="true"
                      v-for="item in sup_psp2"
                      :key="item.id"
                      :label="item.value"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </div>
              </el-col>
              <el-col :span="4">
                <div style="min-width: 30px" class="padding2px fullwidth">
                  <el-select
                    :value="Criteriaedit(item.SupCriteria3id, sup_psp3)"
                    :id="'setup_pspsummay_project_sup_psp_3_'+item.$index"
                    placeholder=""
                    clearable
                    class="fullwidth"
                  >
                    <el-option
                      :disabled="true"
                      v-for="item in sup_psp3"
                      :key="item.id"
                      :label="item.value"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </div>
              </el-col>
              <el-col :span="4">
                <div style="min-width: 30px" class="padding2px fullwidth">
                  <el-select
                    :value="Criteriaedit(item.SupCriteria4id, sup_psp4)"
                    :id="'setup_pspsummay_project_sup_psp_4_'+item.$index"
                    placeholder=""
                    clearable
                    class="fullwidth"
                  >
                    <el-option
                      :disabled="true"
                      v-for="item in sup_psp4"
                      :key="item.id"
                      :label="item.value"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </div>
              </el-col>
              <el-col :span="4">
                Gross Internal Floor (m2)
                 <br>
                 <span v-if="chunck == 'sup'">
               {{item.SupUR}}/ <span v-if="item.Supqty != 0">
                 {{item.Supqty}}
                </span>
                 </span>
              </el-col>
            </el-row>
            <div class="spaceBetween_middle" />
            <el-row v-if="grouping == 'ext' || grouping == ''">
              <el-col :span="4">
                <div
                  style="
                    min-width: 30px;
                    display: inline-block;
                    margin-left: 40px;
                  "
                >
                  <h5 class="marginTB0">Externals</h5>
                </div>
              </el-col>
              <el-col :span="4">
                <div style="min-width: 30px" class="padding2px fullwidth">
                  <el-select
                    :value="Criteriaedit(item.ExtCriteria1id, ext_psp1)"
                    :id="'setup_pspsummay_project_ext_psp_1_'+item.$index"
                    placeholder=""
                    clearable
                    class="fullwidth"
                  >
                    <el-option
                      :disabled="true"
                      v-for="item in ext_psp1"
                      :key="item.id"
                      :label="item.value"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </div>
              </el-col>
              <el-col :span="4">
                <div style="min-width: 30px" class="padding2px fullwidth">
                  <el-select
                    :value="Criteriaedit(item.ExtCriteria2id, ext_psp2)"
                    :id="'setup_pspsummay_project_ext_psp_2_'+item.$index"
                    placeholder=""
                    clearable
                    class="fullwidth"
                  >
                    <el-option
                      :disabled="true"
                      v-for="item in ext_psp2"
                      :key="item.id"
                      :label="item.value"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </div>
              </el-col>
              <el-col :span="4">
                <div style="min-width: 30px" class="padding2px fullwidth">
                  <el-select
                    :value="Criteriaedit(item.ExtCriteria3id, ext_psp3)"
                    :id="'setup_pspsummay_project_ext_psp_3_'+item.$index"
                    placeholder=""
                    clearable
                    class="fullwidth"
                  >
                    <el-option
                      :disabled="true"
                      v-for="item in ext_psp3"
                      :key="item.id"
                      :label="item.value"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </div>
              </el-col>
              <el-col :span="4">
                <div style="min-width: 30px" class="padding2px fullwidth">
                  <el-select
                    :value="Criteriaedit(item.ExtCriteria4id, ext_psp4)"
                    :id="'setup_pspsummay_project_ext_psp_4_'+item.$index"
                    placeholder=""
                    clearable
                    class="fullwidth"
                  >
                    <el-option
                      :disabled="true"
                      v-for="item in ext_psp4"
                      :key="item.id"
                      :label="item.value"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </div>
              </el-col>
              <el-col :span="4">
               Hard Surfaces (m2)
               <br>
               <span v-if="chunck == 'ext'">
                {{item.ExtUR}}/ <span v-if="item.Extqty != 0">
                {{item.Extqty}}
                </span>
               </span>
              </el-col>
            </el-row>
            <!-- <hr class="spaceBetween_bottom" style="margin-left: 40px" /> -->
          </div>
        </el-col>
      </el-row>
    </div>
    <el-pagination
      layout="prev, pager, next"
      :total="view.count"
      @current-change="CurrentChange"
      :current-page="view.pageno"
    >
    </el-pagination>
  </div>
</template>
<script>
import moment from "moment";
import selectpart from "./selectpart.vue";
export default {
      components: {
    selectpart: selectpart,
  },
  data() {
    return {
        chunck:"",
        prdis: {
            projectPlaceholder: "",
            partPlaceholder: "",
            lastsavedshow: true,
        },
        curentdate:new Date(),
        startdate:null,
        enddate:null,
       customfilter: [],
      view: {
        filter: [
          { table: "p", column: "projectname", value: "" },
          { table: "p", column: "createdon", operation: ">=", value: "" },
          { table: "p", column: "createdon", operation: "<=", value: "" },
          { table: "p", column: "hide", operation: "=", value: "0" },
          { table: "p", column: "id", value: "" ,operation: "="},
          { table: "pr", column: "id", value: "" , operation: "="},
            { table: 'pd', column: 'ComCriteria1id',operation:'=', value: "" },
            { table: 'pd', column: 'ComCriteria2id',operation:'=', value: "" },
            { table: 'pd', column: 'ComCriteria3id',operation:'=', value: "" },
            { table: 'pd', column: 'ComCriteria4id',operation:'=', value: "" },

            { table: 'pd', column: 'SubCriteria1id',operation:'=', value: "" },
            { table: 'pd', column: 'SupCriteria1id',operation:'=', value: "" },
            { table: 'pd', column: 'ExtCriteria1id',operation:'=', value: "" },

            { table: 'pd', column: 'SubCriteria2id',operation:'=', value: "" },
            { table: 'pd', column: 'SupCriteria2id',operation:'=', value: "" },
            { table: 'pd', column: 'ExtCriteria2id',operation:'=', value: "" },

            { table: 'pd', column: 'SubCriteria3id',operation:'=', value: "" },
            { table: 'pd', column: 'SupCriteria3id',operation:'=', value: "" },
            { table: 'pd', column: 'ExtCriteria3id',operation:'=', value: "" },

            { table: 'pd', column: 'SubCriteria4id',operation:'=', value: "" },
            { table: 'pd', column: 'SupCriteria4id',operation:'=', value: "" },
            { table: 'pd', column: 'ExtCriteria4id',operation:'=', value: "" },

            { table: 'pd', column: 'SubUR',operation:'=', value: "" },
            { table: 'pd', column: 'SupUR',operation:'=', value: "" },
            { table: 'pd', column: 'ExtUR',operation:'=', value: "" },
        ],
        sortcol: "p.createdon",
        sortorder: "descending",
      },
      all_psp1: [
        {
          sublable: "Substructure Criteria 1",
          suplable: "Superstructure Criteria 1",
          extlable: "Externals Criteria 1",

          sub: [],
          sup: [],
          ext: [],
        },
      ],
      all_psp2: [
        {
          sublable: "Substructure Criteria 2",
          suplable: "Superstructure Criteria 2",
          extlable: "Externals Criteria 2",
          sub: [],
          sup: [],
          ext: [],
        },
      ],
      all_psp3: [
        {
          sublable: "Substructure Criteria 3",
          suplable: "Superstructure Criteria 3",
          extlable: "Externals Criteria 3",
          sub: [],
          sup: [],
          ext: [],
        },
      ],
      all_psp4: [
        {
          sublable: "Substructure Criteria 4",
          suplable: "Superstructure Criteria 4",
          extlable: "Externals Criteria 4",
          sub: [],
          sup: [],
          ext: [],
        },
      ],
      all_psp1_option: [
        {
          label: "Substructure Criteria",
          options: [],
        },
        {
          label: "Superstructure Criteria",
          options: [],
        },
        {
          label: "Externals Criteria",
          options: [],
        },
      ],
      all_psp2_option: [
        {
          label: "Substructure Criteria",
          options: [],
        },
        {
          label: "Superstructure Criteria",
          options: [],
        },
        {
          label: "Externals Criteria",
          options: [],
        },
      ],
      all_psp3_option: [
        {
          label: "Substructure Criteria",
          options: [],
        },
        {
          label: "Superstructure Criteria",
          options: [],
        },
        {
          label: "Externals Criteria",
          options: [],
        },
      ],
      all_psp4_option: [
        {
          label: "Substructure Criteria",
          options: [],
        },
        {
          label: "Superstructure Criteria",
          options: [],
        },
        {
          label: "Externals Criteria",
          options: [],
        },
      ],
      all_psp1_search: [],
      all_psp2_search: [],
      all_psp3_search: [],
      all_psp4_search: [],
      com_psp1_search: [],
      com_psp2_search: [],
      com_psp3_search: [],
      com_psp4_search: [],
      com_psp1: [],
      com_psp2: [],
      com_psp3: [],
      com_psp4: [],
      sub_psp1: [],
      sub_psp2: [],
      sub_psp3: [],
      sub_psp4: [],
      sup_psp1: [],
      sup_psp2: [],
      sup_psp3: [],
      sup_psp4: [],
      ext_psp1: [],
      ext_psp2: [],
      ext_psp3: [],
      ext_psp4: [],
      groupings: [
        //  {label:'Common',value:"com"},
        { label: "Substructure", value: "sub" },
        { label: "Superstructure", value: "sup" },
        { label: "Externals", value: "ext" },
      ],
      chuncks: [
        //  {label:'Common',value:"com"},
        { label: "Buliding Footprint (m2)", value: "sub" },
        { label: "Gross Internal Floor (m2)", value: "sup" },
        { label: "Hard Surfaces (m2)", value: "ext" },
      ],
      grouping: "",
    //   pspcriteria: "",
      pspcriterias: [],
      detailform: [],
      project:{},
      projectpart:{},
    };
  },
  methods: {
      changechunksize:function(event){
          if (this.project.id && this.projectpart.id >= 0) {
              this.view.filter[4].value = this.project.id.toString();
              this.view.filter[22].value = "";
              this.view.filter[23].value = "";
              this.view.filter[24].value = "";
              if (event == "sub") {
                  if (this.$store.state.descriptiondata.SubUR == null) {
                      this.$store.state.descriptiondata.SubUR = "";
                  }
                  this.view.filter[22].value = this.$store.state.descriptiondata.SubUR.toString();
                  this.view.filter[4].value = this.$store.state.descriptiondata.projectid.toString();
                  this.view.filter[5].value = this.$store.state.descriptiondata.partid.toString();


              }
              if (event == "sup") {
                  if (this.$store.state.descriptiondata.SupUR == null) {
                      this.$store.state.descriptiondata.SupUR = "";
                  }
                  this.view.filter[23].value = this.$store.state.descriptiondata.SupUR.toString();
                  this.view.filter[4].value = this.$store.state.descriptiondata.projectid.toString();
                  this.view.filter[5].value = this.$store.state.descriptiondata.partid.toString();


                  //   }
              }
              if (event == "ext") {
                  if (this.$store.state.descriptiondata.ExtUR == null) {
                      this.$store.state.descriptiondata.ExtUR = ""
                  }
                  this.view.filter[24].value = this.$store.state.descriptiondata.ExtUR.toString();
                  this.view.filter[4].value = this.$store.state.descriptiondata.projectid.toString();
                  this.view.filter[5].value = this.$store.state.descriptiondata.partid.toString();
              }
          }
              this.refresh();
      },
      getprojectdis:function(){
     var data1 = { projectid: this.project.id, partid: this.projectpart.id };
      this.$http
        .post("project/getProjectDis", data1)
        .then((response) => {
          this.$store.state.descriptiondata = response.data;
          this.changechunksize(this.chunck);
        })
        .catch((err) => {
          this.$bus.$emit("error", err);
        });
      },
      projectidget:function(){
           if (this.project.id && this.projectpart.id >= 0) {
            this.getprojectdis();
          }
      },
          projectpartchange: function (Projectobj) {
            this.project = Projectobj.project;
            this.projectpart = Projectobj.part;

            this.projectidget();

        },
      pspcriteriasearchcom1:function(event){
           this.view.pageno = 1;
          this.view.filter[6].value = event.toString();
             this.refresh();
      },
      pspcriteriasearchcom2:function(event){
           this.view.pageno = 1;
            this.view.filter[7].value = event.toString();
             this.refresh();
      },
      pspcriteriasearchcom3:function(event){
          this.view.pageno = 1;
            this.view.filter[8].value = event.toString();
             this.refresh();
      },
      pspcriteriasearchcom4:function(event){
           this.view.pageno = 1;
            this.view.filter[9].value = event.toString();
             this.refresh();
      },
    removetagcriteria: function (event) {
         this.view.pageno = 1;
      var cindex = this.view.filter.findIndex((x) => x.value == event);
      if(cindex != -1){
        //   this.view.filter.splice(cindex, 1);
        this.view.filter[cindex].value = "";
          this.refresh();
      }
    },
    pspcriteria1search: function (event) {
         this.view.pageno = 1;
      event.forEach((e) => {
        var cr = this.pspcriterias.find((x) => x.id == e);

        if (cr != undefined) {
        //   if (this.grouping == "") {
        //     this.grouping = cr.grouping;
        //   }
          var ct = this.view.filter.find((x) => x.table == "pd" && x.value == e);
          if (!ct) {
              if(cr.grouping == "sub"){
                  if (this.view.filter[10].value != "") {
                    var sindex = event.findIndex((x)=> x == this.view.filter[10].value);
                     if(sindex != -1){
                        event.splice(sindex, 1);
                     }
                  }
                   this.view.filter[10].value = e.toString();
              }
              if(cr.grouping == "sup"){
                   if (this.view.filter[11].value != "") {
                    var sindex = event.findIndex((x)=> x == this.view.filter[11].value);
                     if(sindex != -1){
                        event.splice(sindex, 1);
                     }
                  }
                  this.view.filter[11].value = e.toString();
              }
              if(cr.grouping == "ext"){
                   if (this.view.filter[12].value != "") {
                    var sindex = event.findIndex((x)=> x == this.view.filter[12].value);
                     if(sindex != -1){
                        event.splice(sindex, 1);
                     }
                  }
                  this.view.filter[12].value = e.toString();
              }
          }
        }
      });
         var remove = this.view.filter.filter(x => x.table == 'pd' && (x.column == 'ExtCriteria1id' ||x.column == 'SubCriteria1id' || x.column == 'SupCriteria1id') && x.value != '');
         if(remove.length == event.length){
             this.refresh();
         }
    },
    pspcriteria2search: function (event) {
         this.view.pageno = 1;
         event.forEach((e) => {
        var cr = this.pspcriterias.find((x) => x.id == e);

        if (cr != undefined) {
        //   if (this.grouping == "") {
        //     this.grouping = cr.grouping;
        //   }
          var ct = this.view.filter.find((x) => x.table == "pd" && x.value == e);
          if (!ct) {
              if(cr.grouping == "sub"){
                   if (this.view.filter[13].value != "") {
                    var sindex = event.findIndex((x)=> x == this.view.filter[13].value);
                     if(sindex != -1){
                        event.splice(sindex, 1);
                     }
                  }
                  this.view.filter[13].value = e.toString();
              }
              if(cr.grouping == "sup"){
                   if (this.view.filter[14].value != "") {
                    var sindex = event.findIndex((x)=> x == this.view.filter[14].value);
                     if(sindex != -1){
                        event.splice(sindex, 1);
                     }
                  }
                  this.view.filter[14].value = e.toString();
              }
              if(cr.grouping == "ext"){
                   if (this.view.filter[15].value != "") {
                    var sindex = event.findIndex((x)=> x == this.view.filter[15].value);
                     if(sindex != -1){
                        event.splice(sindex, 1);
                     }
                  }
                  this.view.filter[15].value = e.toString();
              }
          }
        }
      });
       var remove = this.view.filter.filter(x => x.table == 'pd' && (x.column == 'ExtCriteria2id' ||x.column == 'SubCriteria2id' || x.column == 'SupCriteria2id') && x.value != '');
         if(remove.length == event.length){
             this.refresh();
         }

    },
    pspcriteria3search: function (event) {
         this.view.pageno = 1;
         event.forEach((e) => {
        var cr = this.pspcriterias.find((x) => x.id == e);

        if (cr != undefined) {
        //   if (this.grouping == "") {
        //     this.grouping = cr.grouping;
        //   }
          var ct = this.view.filter.find((x) => x.table == "pd" && x.value == e);
          if (!ct) {
              if(cr.grouping == "sub"){
                   if (this.view.filter[16].value != "") {
                    var sindex = event.findIndex((x)=> x == this.view.filter[16].value);
                     if(sindex != -1){
                        event.splice(sindex, 1);
                     }
                  }
                  this.view.filter[16].value = e.toString();
              }
              if(cr.grouping == "sup"){
                   if (this.view.filter[17].value != "") {
                    var sindex = event.findIndex((x)=> x == this.view.filter[17].value);
                     if(sindex != -1){
                        event.splice(sindex, 1);
                     }
                  }
                  this.view.filter[17].value = e.toString();
              }
              if(cr.grouping == "ext"){
                   if (this.view.filter[18].value != "") {
                    var sindex = event.findIndex((x)=> x == this.view.filter[18].value);
                     if(sindex != -1){
                        event.splice(sindex, 1);
                     }
                  }
                  this.view.filter[18].value = e.toString();
              }
          }
        }
      });
     var remove = this.view.filter.filter(x => x.table == 'pd' && (x.column == 'ExtCriteria3id' ||x.column == 'SubCriteria3id' || x.column == 'SupCriteria3id') && x.value != '');
         if(remove.length == event.length){
             this.refresh();
         }
    },
    pspcriteria4search: function (event) {
         this.view.pageno = 1;
         event.forEach((e) => {
        var cr = this.pspcriterias.find((x) => x.id == e);

        if (cr != undefined) {
        //   if (this.grouping == "") {
        //     this.grouping = cr.grouping;
        //   }
          var ct = this.view.filter.find((x) => x.table == "pd" && x.value == e);
          if (!ct) {
              if(cr.grouping == "sub"){
                   if (this.view.filter[19].value != "") {
                    var sindex = event.findIndex((x)=> x == this.view.filter[19].value);
                     if(sindex != -1){
                        event.splice(sindex, 1);
                     }
                  }
                  this.view.filter[19].value = e.toString();
              }
              if(cr.grouping == "sup"){
                   if (this.view.filter[20].value != "") {
                    var sindex = event.findIndex((x)=> x == this.view.filter[20].value);
                     if(sindex != -1){
                        event.splice(sindex, 1);
                     }
                  }
                  this.view.filter[20].value = e.toString();
              }
              if(cr.grouping == "ext"){
                   if (this.view.filter[21].value != "") {
                    var sindex = event.findIndex((x)=> x == this.view.filter[21].value);
                     if(sindex != -1){
                        event.splice(sindex, 1);
                     }
                  }
                  this.view.filter[21].value = e.toString();
              }
          }
        }
      });
      var remove = this.view.filter.filter(x => x.table == 'pd' && (x.column == 'ExtCriteria4id' ||x.column == 'SubCriteria4id' || x.column == 'SupCriteria4id') && x.value != '');
         if(remove.length == event.length){
             this.refresh();
         }
    },
    startenddatechang: function () {
         this.view.pageno = 1;
      if (this.startdate != null && this.enddate != null) {
        this.view.filter[1].value = "'" + this.startdate + "'";

           var enddate = new Date(this.enddate);
           enddate.setDate(enddate.getDate() + 1);
           enddate = moment(enddate).format('YYYY-MM-DD');
        this.view.filter[2].value = "'" + enddate + "'";
        this.refresh();
      } else {
        this.view.filter[1].value = "";
        this.view.filter[2].value = "";
        this.refresh();
      }
    },
    dividecriteria: function () {
      this.pspcriterias.forEach((crt) => {
        if (crt.criteria == "c1" && crt.grouping == "com") {
          this.com_psp1.push(crt);
        }
        if (crt.criteria == "c2" && crt.grouping == "com") {
          this.com_psp2.push(crt);
        }
        if (crt.criteria == "c3" && crt.grouping == "com") {
          this.com_psp3.push(crt);
        }
        if (crt.criteria == "c4" && crt.grouping == "com") {
          this.com_psp4.push(crt);
        }

        if (crt.criteria == "c1" && crt.grouping == "sub") {
          this.all_psp1_option[0].options.push(crt);
          this.sub_psp1.push(crt);
          this.all_psp1[0].sub.push(crt);
        }
        if (crt.criteria == "c2" && crt.grouping == "sub") {
          this.all_psp2_option[0].options.push(crt);
          this.sub_psp2.push(crt);
          this.all_psp2[0].sub.push(crt);
        }
        if (crt.criteria == "c3" && crt.grouping == "sub") {
          this.all_psp3_option[0].options.push(crt);
          this.sub_psp3.push(crt);
          this.all_psp3[0].sub.push(crt);
        }
        if (crt.criteria == "c4" && crt.grouping == "sub") {
          this.all_psp4_option[0].options.push(crt);
          this.sub_psp4.push(crt);
          this.all_psp4[0].sub.push(crt);
        }

        if (crt.criteria == "c1" && crt.grouping == "sup") {
          this.all_psp1_option[1].options.push(crt);
          this.sup_psp1.push(crt);
          this.all_psp1[0].sup.push(crt);
        }
        if (crt.criteria == "c2" && crt.grouping == "sup") {
          this.all_psp2_option[1].options.push(crt);
          this.sup_psp2.push(crt);
          this.all_psp2[0].sup.push(crt);
        }
        if (crt.criteria == "c3" && crt.grouping == "sup") {
          this.all_psp3_option[1].options.push(crt);
          this.sup_psp3.push(crt);
          this.all_psp3[0].sup.push(crt);
        }
        if (crt.criteria == "c4" && crt.grouping == "sup") {
          this.all_psp4_option[1].options.push(crt);
          this.sup_psp4.push(crt);
          this.all_psp4[0].sup.push(crt);
        }

        if (crt.criteria == "c1" && crt.grouping == "ext") {
          this.all_psp1_option[2].options.push(crt);
          this.ext_psp1.push(crt);
          this.all_psp1[0].ext.push(crt);
        }
        if (crt.criteria == "c2" && crt.grouping == "ext") {
          this.all_psp2_option[2].options.push(crt);
          this.ext_psp2.push(crt);
          this.all_psp2[0].ext.push(crt);
        }
        if (crt.criteria == "c3" && crt.grouping == "ext") {
          this.all_psp3_option[2].options.push(crt);
          this.ext_psp3.push(crt);
          this.all_psp3[0].ext.push(crt);
        }
        if (crt.criteria == "c4" && crt.grouping == "ext") {
          this.all_psp4_option[2].options.push(crt);
          this.ext_psp4.push(crt);
          this.all_psp4[0].ext.push(crt);
        }
      });
    },
    getcriteria: function () {
      this.$http
        .post("project/getallpspcriteria")
        .then((response) => {
          this.pspcriterias = response.data;
          this.dividecriteria();
        })
        .catch((err) => {
          this.$bus.$emit("error", err);
        });
    },
    createfilter:function(e){
        // data.forEach(e => {
            if(e.ComCriteria1id != ""){
                 this.view.filter[6].value = e.ComCriteria1id.toString();
                 this.com_psp1_search = e.ComCriteria1id;
            }
             if(e.ComCriteria2id != ""){
                 this.view.filter[7].value = e.ComCriteria2id.toString();
                 this.com_psp2_search = e.ComCriteria2id;
            }
             if(e.ComCriteria3id != ""){
                 this.view.filter[8].value = e.ComCriteria3id.toString();
                 this.com_psp3_search = e.ComCriteria3id;
            }
             if(e.ComCriteria4id != ""){
                 this.view.filter[9].value = e.ComCriteria4id.toString();
                 this.com_psp4_search = e.ComCriteria4id;
            }

              if(e.SubCriteria1id != ""){
                this.all_psp1_search.push(e.SubCriteria1id)
                 this.view.filter[10].value = e.SubCriteria1id.toString();
            }
             if(e.SupCriteria1id != ""){
                this.all_psp1_search.push(e.SupCriteria1id)
                 this.view.filter[11].value = e.SupCriteria1id.toString();
            }
             if(e.ExtCriteria1id != ""){
                this.all_psp1_search.push(e.ExtCriteria1id)
                this.view.filter[12].value = e.ExtCriteria1id.toString();
            }

              if(e.SubCriteria2id != "" && e.SubCriteria2Checked == true){

                this.all_psp2_search.push(e.SubCriteria2id)
                this.view.filter[13].value = e.SubCriteria2id.toString();
            }
             if(e.SupCriteria2id != "" && e.SupCriteria2Checked == true){
                this.all_psp2_search.push(e.SupCriteria2id)
                this.view.filter[14].value = e.SupCriteria2id.toString();
            }
             if(e.ExtCriteria2id != "" && e.ExtCriteria2Checked == true){
                this.all_psp2_search.push(e.ExtCriteria2id)
                this.view.filter[15].value = e.ExtCriteria2id.toString();
            }

             if(e.SubCriteria3id != "" && e.SubCriteria3Checked == true){
                this.all_psp3_search.push(e.SubCriteria3id)
                this.view.filter[16].value = e.SubCriteria3id.toString();
            }
             if(e.SupCriteria3id != "" && e.SupCriteria3Checked == true){
                this.all_psp3_search.push(e.SupCriteria3id)
                this.view.filter[17].value = e.SupCriteria3id.toString();
            }
             if(e.ExtCriteria3id != ""  && e.ExtCriteria3Checked == true){
                this.all_psp3_search.push(e.ExtCriteria3id)
                this.view.filter[18].value = e.ExtCriteria3id.toString();
            }

             if(e.SubCriteria4id != "" && e.SubCriteria4Checked == true){
                this.all_psp4_search.push(e.SubCriteria4id)
                this.view.filter[19].value = e.SubCriteria4id.toString();
            }
             if(e.SupCriteria4id != "" && e.SupCriteria4Checked == true){
                this.all_psp4_search.push(e.SupCriteria4id)
                this.view.filter[20].value = e.SupCriteria4id.toString();
            }
             if(e.ExtCriteria4id != "" && e.ExtCriteria4Checked == true){
                this.all_psp4_search.push(e.ExtCriteria4id)
                this.view.filter[21].value = e.ExtCriteria4id.toString();
            }
        // });
        // this.$store.state.descriptiondata = {};
        this.refresh();
        // this.view.filter[5].value = "";
    },
    CurrentChange: function (val) {
      this.view.pageno = val;
      this.refresh();
    },
    refresh: function () {
       this.showloading();
      this.$http
        .post("project/getpspsummary", this.view)
        .then((response) => {
          this.detailform = response.data.data;
          this.view = response.data.view;
            this.hideloading();
        })
        .catch((err) => {
             this.hideloading();
          this.$bus.$emit("error", err);
        });
    },
     getcurentandsexmonthdate:function(){
      var cdate1 = new Date();
      var cdatebackendend = new Date();
      cdatebackendend.setDate(cdate1.getDate() + 1);
      this.view.filter[2].value = "'" + moment(cdatebackendend).format("YYYY-MM-DD") + "'";
      this.enddate = cdate1;
      var cdate2 = new Date();
      cdate2.setMonth(cdate2.getMonth() - 6);
      this.view.filter[1].value = "'" + moment(cdate2).format("YYYY-MM-DD") + "'";
      this.startdate = cdate2;
  },
  },
  computed: {
    projectparthader: function () {
      return function (name) {
        if (name == null) {
          return {
            "margin-left": "5px",
            "font-size": "11px",
            color: "rgb(173, 173, 173)",
          };
        } else {
          return { "margin-left": "5px", "font-size": "11px" };
        }
      };
    },
    styleclass() {
      return function (event) {
        if (this.grouping != "") {
          return { height: "90px" };
        } else {
          return { height: "170px" };
        }
      };
    },
    getcredon: function () {
      return function (date) {
        var dt = new Date(date);
        return dt.toLocaleString("en-US", {
          day: "numeric",
          month: "short",
          year: "numeric",
        });
      };
    },
    Criteriaedit: function () {
      return function (val, arr) {
        var t = arr.find((c) => c.id == val);
        if (t) {
          return t.value;
        } else {
          return "";
        }
      };
    },
  },
  created: function () {
    this.getcriteria();
    if ( this.$store.state.descriptiondata.projectid != undefined) {
       this.getcurentandsexmonthdate();
        this.createfilter(this.$store.state.descriptiondata);
    }else{
        this.getcurentandsexmonthdate();
    }

    //   var cdate1 = new Date();
    //   var cdatebackendend = new Date();
    //   cdatebackendend.setDate(cdate1.getDate() + 1);
    //   this.view.filter[2].value = "'" + moment(cdatebackendend).format("YYYY-MM-DD") + "'";
    //   this.datearr[1] = cdate1;
    //   var cdate2 = new Date();
    //   cdate2.setMonth(cdate2.getMonth() - 6);
    //   this.view.filter[1].value = "'" + moment(cdate2).format("YYYY-MM-DD") + "'";
    //   this.datearr[0] = cdate2;

    this.refresh();
  },
};
</script>
<style scoped>
.el-range-editor--mini.el-input__inner {
  height: 28px;
  width: 100%;
}
.projecthader {
  margin-left: 5px;
  font-weight: bold;
  font-size: 11px;
}

.projectbox {
  background-color: rgb(255, 255, 255);
  /* width: 100%; */
  height: 100%;
  border: 1px solid rgb(214, 214, 214);
  margin-top: 0px;
  margin-bottom: 0px;
}
.fullbox {
  height: 170px;

  /* border: 1px solid rgb(214, 214, 214); */
}
.spaceBetween_middle {
  margin-bottom: 4px;
}
.spaceBetween_top {
  width: 100%;
  margin-top: 3px;
  margin-bottom: 3px;
}
.spaceBetween_bottom {
  width: 100%;
  margin-top: 0px;
  margin-bottom: 0px;

}




</style>
