<template>
    <div class="bqcodeselectreportwidth">
        <div class="fixdheader">
            <div class="bqcodeselectionwidth">
                <span class="headerstyle">BQ_Code Selection Algorithm</span>
                <div class="floatright margintb1mm">
                    <span v-if="sectionid">
                        <el-button :disabled="!editpermission" type="success" @click="savereport">Save</el-button>
                    </span>
                    <span>
                        <el-button @click="$router.go(-1)" icon="el-icon-back" class="marginleft4px"
                            type="info">Back</el-button>
                    </span>
                </div>

                <div>
                    <el-row>
                        <el-col :span="5">
                            <el-row>
                                <el-col class="projectselection heightproject">Project Name</el-col>
                                <el-col class="projectselection heightproject">Part Name</el-col>
                                <el-col class="projectselection">Section Name</el-col>
                            </el-row>
                        </el-col>

                        <el-col :span="1">
                            <projectselectpart :custom="custom" :lastsavedshow="true"
                                @projectsectionchange="projectsectionchange"></projectselectpart>
                        </el-col>

                        <el-col :span="1" class="floatright bold paddingtop5 padleft10px">
                            {{ lastsavedinfo.library }}
                        </el-col>
                    </el-row>
                </div>
            </div>
            <hr class="customhrstyle" />
        </div>

        <div>
            <div class="bqcodeselectionwidth">
                <el-row v-if="detailform.defaultsection">
                    <el-row class="bold">
                        Whole NRM Section Criteria
                    </el-row>
                    <div class="hrspacebetween" />

                    <el-row>
                        <el-col :span="2">Exclude</el-col>
                        <el-col :span="21">
                            <el-input :spellcheck="isspellcheck" type="textarea" class="padleft10px"
                                @change="changenrmregex"
                                @input="$training.checkregex(detailform.defaultsection.exclude,'detailformdefaultsectionexclude')"
                                :autosize="{ minRows: 2, maxRows: 7 }" v-model="detailform.defaultsection.exclude"
                                :disabled="readable.section_exclude || !editpermission">
                            </el-input>
                            <div class=" padleft10px alert-danger" style="display: none;" id="detailformdefaultsectionexclude">
                                <strong>Invalid Regular Expression.</strong>
                            </div>
                        </el-col>

                        <el-col :span="1">
                            <i class="el-icon-edit floatright el-button--primary"
                                @click="isinputreadable(readable, 'section_exclude')"></i>
                        </el-col>
                    </el-row>
                    <div class="hrspacebetween" />

                    <el-row>
                        <el-col :span="2">
                            Include
                        </el-col>
                        <el-col :span="21">
                            <el-input :spellcheck="isspellcheck" type="textarea" class="padleft10px"
                                @change="changenrmregex"
                                @input="$training.checkregex(detailform.defaultsection.include,'detailformdefaultsectioninclude')"
                                :autosize="{ minRows: 2, maxRows: 7 }" v-model="detailform.defaultsection.include"
                                :disabled="readable.section_include || !editpermission">
                            </el-input>
                            <div class=" padleft10px alert-danger" style="display: none;" id="detailformdefaultsectioninclude">
                                <strong>Invalid Regular Expression.</strong>
                            </div>
                        </el-col>

                        <el-col :span="1">
                            <i class="el-icon-edit floatright el-button--primary"
                                @click="isinputreadable(readable, 'section_include')"></i>
                        </el-col>
                    </el-row>
                    <div class="hrspacebetween" />

                    <el-row>
                        Include Data Since:
                        <el-date-picker v-model="detailform.defaultsection.lastdatasince" type="date" placeholder="Date"
                            class="custom-date-picker width80px" format="dd-MMM-yyyy" align="center"
                            value-format="yyyy-MM-dd" :clearable="true">
                        </el-date-picker>
                    </el-row>
                    <hr class="hrstyle" />
                </el-row>

                <el-row v-if="detailform.individual">
                    <el-row class="bold">
                        Individual BQ_Code Criteria
                    </el-row>
                    <el-row>
                        <el-col :span="2">
                            Row
                        </el-col>
                        <el-col :span="22" class="padleft10px">
                            <div>
                                <span class="left inputwidth45 marginright4px">
                                    <el-select v-model="amoscurrentrow" :clearable="false"
                                        :style="{ width: '100%' }" placeholder="RN"
                                        @change="changerownumber($event, detailform.individual)" @blur="checkallregex">
                                        <el-option v-for="item in selectfile.rownumbers" :key="item.name" :label="item.name"
                                            :value="item.name">
                                        </el-option>
                                    </el-select>
                                </span>

                                <span class="left marginright4px">
                                    <!-- <el-select v-model="detailform.individual.bqcodelibrary.bqcode" :clearable="false"
                                        :style="{ width: '210px' }" placeholder="Select BQ_Code"
                                        @change="changebqcode($event, detailform.individual)">
                                        <el-option v-for="(item, index) in bqcodes" :key="index" :label="item.bqcode"
                                            :value="item.bqcode">
                                            <template slot-scope="{ item }">
                                                <div v-if="item">
                                                    <span class="left">{{ item.bqcode }} | {{ item.count }}</span>
                                                </div>
                                            </template>
                                        </el-option>
                                    </el-select> -->
                                    <el-autocomplete class="inline-input fullwidth"
                                                         popper-class="morewidth-autocomplete"
                                                         v-model="detailform.individual.bqcodelibrary.bqcode"
                                                         :fetch-suggestions="(query, done) => {querySearchBQCode(query, done)}"
                                                         placeholder=""
                                                         :disabled="!editpermission"
                                                         :select-when-unmatched="true"
                                                         value-key="bqcode"
                                                         @select="handleSelectBQCode($event)"
                                                         @Clear="handleSelectBQCode($event)"
                                                         @change="changebqcode($event, detailform.individual)"
                                                         :trigger-on-focus="true"
                                                         :clearable="true">
                                            <template slot-scope="{ item }">
                                                <div v-if="item && item.bql">
                                                    <div style="white-space:normal;"><el-row><el-col :span="12">{{ item.bql.bqcode }}</el-col> <el-col :span="12"> <span class="right"> {{ item.count }}</span></el-col></el-row></div>
                                                    <div style="white-space:normal;font-size: x-small"><span>{{ item.bql.section }} | {{ item.bql.group }} | {{ item.bql.work }} | {{ item.bql.level1 }} | {{ item.bql.level2 }} | {{ item.bql.level3 }}</span></div>
                                                </div>
                                            </template>
                                        </el-autocomplete>
                                </span>

                                <span class="left margintop3px">{{ detailform.defaultsection.status }}</span>
                            </div>
                        </el-col>
                    </el-row>
                    <div class="hrspacebetween" />
                    <el-row v-if="amosrow.amosrow != undefined">
                        <el-collapse v-model="collapsename" class="amosrowcollapse" >
                            <el-collapse-item title="AMOS Selected Row" name="1">
                            <div  class="bqcodeselectionwidth"  v-for="itme in 20">
                            <el-row style="border: 1px solid gray; padding: 3px;" v-if="itme > 0 && amosrow.amosrow['col'+itme]" >
                                    <el-col :span="2">
                                        <el-tag   > {{'col'+itme  }}</el-tag>
                                    </el-col>
                                    <el-col :span="12">
                                        <div style="word-wrap: break-word;" >
                                            {{amosrow.amosrow['col'+itme]}}
                                        </div>
                                    </el-col>
                                </el-row>
                                </div>
                            </el-collapse-item>
                    </el-collapse>
                </el-row>
                    <div class="hrspacebetween" />

                    <el-row>
                        <el-col :span="2">
                            Exclude
                        </el-col>
                        <el-col :span="21">
                            <el-input :spellcheck="isspellcheck" type="textarea" class="padleft10px"
                                :autosize="{ minRows: 2, maxRows: 7 }" @input="$training.checkregex(detailform.individual.exclude,'detailformindividualexclude')" v-model="detailform.individual.exclude"
                                :disabled="readable.bqcode_exclude || !editpermission">
                            </el-input>
                            <div class="padleft10px alert-danger" style="display: none;" id="detailformindividualexclude" >
                            <strong>Invalid Regular Expression.</strong>
                        </div>
                        </el-col>
                        <el-col :span="1">
                            <i class="el-icon-edit floatright el-button--primary"
                                @click="isinputreadable(readable, 'bqcode_exclude')"></i>
                        </el-col>
                    </el-row>
                    <div class="hrspacebetween" />

                    <el-row>
                        <el-col :span="2">
                            Include
                        </el-col>
                        <el-col :span="21">
                            <el-input :spellcheck="isspellcheck" v-model="detailform.individual.include" type="textarea"
                                class="padleft10px" :autosize="{ minRows: 2, maxRows: 7 }"
                                :disabled="readable.bqcode_include || !editpermission" @input="$training.checkregex(detailform.individual.include,'detailformindividualinclude')">
                            </el-input>
                            <div class="padleft10px alert-danger" style="display: none;" id="detailformindividualinclude">
                            <strong>Invalid Regular Expression.</strong>
                        </div>
                        </el-col>

                        <el-col :span="1">
                            <i class="el-icon-edit floatright el-button--primary"
                                @click="isinputreadable(readable, 'bqcode_include')"></i>
                        </el-col>
                    </el-row>

                    <hr class="hrstyle" />
                </el-row>
            </div>
            <div v-if="cangetreport">
                <el-row class="bqcodeselectionwidth">
                    <el-col :span="12" class="left bold">
                        Report for Row Matches
                    </el-col>

                    <el-col :span="12" v-if="sectionid">
                        <el-button type="success" class="floatright padding7" @click="createreport">
                            Create Report
                        </el-button>
                    </el-col>
                </el-row>
                <div class="hrspacebetween" />

                <div class="tablepaddinglr5 tabletexttop bqcodeselectreportwidth" v-if="showreport">
                    <el-table  :data="showtabldata" 
                    :border="true" 
                    :stripe="true"
                    row-key="ss.id"
                    v-el-table-infinite-scroll="() => { return load(); }" 
                    :header-cell-style="headerrowstyleborder">

                        <el-table-column label="Ex" align="center" width="29">
                            <template slot-scope="scope">
                                <el-checkbox v-model="scope.row.ss.isignore"
                                    @change="deleteRow($event,scope.$index, scope.row)"></el-checkbox>
                            </template>
                        </el-table-column>

                        <el-table-column label="Sr" prop="ss.srno" align="center" width="40">
                        </el-table-column>

                        <el-table-column label="BQ Code" prop="bq.bqcode" width="200">
                        </el-table-column>

                        <el-table-column label="Specification" width="200">
                            <template scope="scope">
                                <div style="word-wrap: break-word;" >
                                  <span v-html="scope.row.ss.specification"></span>
                                </div>
                            </template>
                        </el-table-column>

                        <el-table-column label="SIL" prop="ss.specpos" align="center" width="40">
                        </el-table-column>

                        <el-table-column label="Asterisk" width="200">
                            <template scope="scope">
                                <div style="word-wrap: break-word;">
                                    <span  v-html="scope.row.ss.asterisk"></span>
                                </div>
                            </template>
                        </el-table-column>

                        <el-table-column label="End Notes" width="150">
                            <template scope="scope">
                                <div style="word-wrap: break-word;">
                                    <span  v-html="scope.row.ss.other"></span>
                                </div>
                            </template>
                        </el-table-column>

                        <el-table-column label="Date" prop="p.editedon" :formatter="dateformatter" width="90">
                        </el-table-column>

                        <el-table-column label="Project Name" prop="p.projectname" width="290">
                        </el-table-column>
                    </el-table>
                   
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import moment from "moment";
import projectselectpart from "./projectselectpart.vue";
import elTableInfiniteScroll from "el-table-infinite-scroll";
export default {
    data() {
        return {
            amoscurrentrow:null,
            mlbqcodes:[],
            amosbqcodes:[],
            checkwords:[],
            endlength:20,
            startlength:0,
            showtabldata:[],
            collapsename:[],
            amosrow:{},
            showreport:false,
            sectionid: null,
            project: {},
            lastsavedinfo: {},
            custom: {
                rowcol: "el-row",
                projectsid: null,
                projectdisabled: true,
                partdisabled: true,
                sectiondisabled: true,
            },
            readable: {
                section_exclude: true,
                section_include: true,
                bqcode_exclude: true,
                bqcode_include: true
            },
            detailform: {},
            bqcodes: [],
            tabledata: [],
            selectfile : {
                id: 0, 
                rownumbers: []
            }
        }
    },
    directives: {
        "el-table-infinite-scroll": elTableInfiniteScroll,
    },
    computed:{
        cangetreport(){
            if(this.detailform  
            && this.detailform.individual 
            && this.detailform.individual.mlbqcode
            && this.detailform.individual.mlbqcode.ss )
            {
                return true;
            }else{
                return false;
            }
            
        }
    },
    methods: {
        changenrmregex(event){
            if(this.checkallregex()){
                // this.mlbqcodes = [];
                if(this.amoscurrentrow){
                    var isfind =  this.selectfile.rownumbers.find(x => x.name == this.amoscurrentrow);
                    this.GetMLBQcodes(isfind.searchtext);
                }
            }
        },
        // showinput(){
        //     console.log(this.myarray);
        // },
        // setinputval($event){
        //     this.myarray[this.ind].val = $event
        // },
        checkallregex(){
            var definc = true;
                var defexc = true;
                var indinc = true;
                var indexc = true;
                if(this.detailform.defaultsection){
                     definc =  this.$training.checkregex(this.detailform.defaultsection.include,'detailformdefaultinclude');
                     defexc = this.$training.checkregex(this.detailform.defaultsection.exclude,'detailformdefaultexclude');
                }
                if(this.detailform.individual){ 
                     indinc = this.$training.checkregex(this.detailform.individual.include,'detailformindividualinclude');
                     indexc = this.$training.checkregex(this.detailform.individual.exclude,'detailformindividualexclude');
                }
             if(!indinc || !indexc || !definc || !defexc){
                    var style = "<style> .el-message-box {width:20%;} </style>";
                    this.$alert("Invalid Regular Expression."+style, "Validation", {
                        dangerouslyUseHTMLString: true,
                    });
                    return false;
                }else{
                    return true;
                }
        },
        load: function () {
            if(this.endlength < this.tabledata.length){
                this.startlength += 20;
                this.endlength += 20;
                if(this.endlength > this.tabledata.length)
                {
                    this.endlength = this.tabledata.length;
                }
                var settext = this.setredtext(this.tabledata.slice(this.startlength, this.endlength)); 
                this.showtabldata.push(...settext);
            }
        },
        setredtext(array){
            var inc_pattern = null;
            var exc_pattern = null;
            
            if(this.detailform.individual.include != null && this.detailform.individual.include != ""){
                inc_pattern = new RegExp(this.detailform.individual.include,"i");
            }
            if(this.detailform.individual.exclude != null && this.detailform.individual.exclude != ""){
                exc_pattern = new RegExp(this.detailform.individual.exclude,"i");
            }
           array.forEach(row => {
                //  var canspe = this.$training.RG_Direction_Match(row.ss.specification,inc_pattern,exc_pattern);
                //  var canast = this.$training.RG_Direction_Match(row.ss.asterisk,inc_pattern,exc_pattern);
                //  var canoth = this.$training.RG_Direction_Match(row.ss.other,inc_pattern,exc_pattern);
                //  if(canspe || canast || canoth){
                      this.checkwords.forEach(word => {
                           word = word.replace(/ +/g, "");
                           if(word != ""){
                               let red_color = `<span style='color:red'>${word}</span>`;
                               var regex = new RegExp(word, "gi");
                               row.ss.specification = row.ss.specification == null ? "" : row.ss.specification.replace(regex, red_color);
                               row.ss.asterisk = row.ss.asterisk == null ? "" : row.ss.asterisk.replace(regex, red_color);
                               row.ss.other = row.ss.other == null ? "" : row.ss.other.replace(regex, red_color);
                           }
                       });
                    //    ddi.push(row);
                //   }
             });
            return array;
        },
        handleSelectBQCode(event){
            this.detailform.individual.bqcodelibraryid = event.bqcodeid;
            this.$http.post("rowdesignation/getindividualbqcode", this.detailform.individual)
                .then((response) => {
                    this.detailform.individual = response.data;
                    this.hideloading();
                    var newobj = JSON.parse(JSON.stringify(event.bql));
                    this.$set(this.detailform.individual,'bqcodelibrary',newobj)
                    this.$set(this.detailform.individual,'mlbqcode',event)
                    this.$set(this.detailform.individual,'bqcodelibraryid',newobj.id)
                    // row.bqcodelibraryid = newobj.id;
                    this.amosbqcodes[this.amosrow.amosrow.id] = newobj;
                    this.showtabldata = [];
                    this.tabledata = [];
                    var isfind = this.selectfile.rownumbers.find(x => x.amosrow.id == this.amosrow.amosrow.id);
                    if(isfind != undefined){
                        this.$set(isfind.amosrow,'bqcodelibraryid',newobj.id);
                        this.$set(isfind.amosrow,'bqcodelibrary',newobj);
                    }
                })
                .catch((err) => {
                    this.hideloading();
                    this.$bus.$emit("error", err);
                });
            
        },
        querySearchBQCode(query, done){
            if(this.bqcodes.length <= 0){
                done([])    
            }else{
                if(query == "" || query == undefined ){
                    done(this.bqcodes)    
                }else{
                    var isfind = this.bqcodes.filter(x => x.bql.bqcode.includes(query));
                    done(isfind)
                }
            }
        },
        headerrowstyleborder({ row, column, rowIndex, columnIndex }) {
            if (rowIndex == 0) {
                return { "color": "white", "background-color": "#BBBBBB" };
            }
        },

        projectsectionchange(Projectobj) {
            this.sectionid = Projectobj.sectionid;
            this.project = Projectobj.project;
            this.lastsavedinfo = Projectobj.lastsavedinfo;
            if (this.sectionid != null) {
                this.project.projectpart = Projectobj.part;
                this.refresh();
            }
        },
        setbqcodes(data){
              this.checkwords =  data.words.split(",");
              this.bqcodes = data.mlbqcodes; 
              var newstatus = "";
                       if (this.bqcodes.length == 0) {
                          newstatus = " No Matches"; 
                          this.showtabldata = [];
                          this.tabledata = []; 
                          this.showreport = false;
                          this.detailform.individual.mlbqcode = {};
                          this.detailform.individual.bqcodelibraryid = null;
                          this.detailform.individual.bqcodelibrary = {};
                      }
                      else if (this.bqcodes.length == 1 ) {
                          newstatus = " Unique Match";
                          this.handleSelectBQCode(this.bqcodes[0]);
                        
                      }
                      else if (this.bqcodes.length > 1) {
                          newstatus = " Choice of Matches";
                      }
                      this.$nextTick(()=>{
                          this.$set(this.detailform.defaultsection,'status',newstatus)
                      })
                      
                      
                      if(this.bqcodes.length > 1){
                            if(this.detailform.individual.bqcodelibraryid != undefined){
                                var isfind = this.bqcodes.find(x => x.bqcodeid == this.detailform.individual.bqcodelibraryid);
                                if(isfind){
                                    this.handleSelectBQCode(isfind);
                                }else{
                                        this.showtabldata = [];
                                        this.tabledata = []; 
                                        this.showreport = false;
                                        this.detailform.individual.mlbqcode = {};
                                }
                            }   
                       }
                //   if(this.detailform.individual.bqcodelibraryid == null){
                       
                //   }else{
                      
                //   }
        },
        GetMLBQcodes(text){
            // if(this.mlbqcodes[this.amosrow.amosrow.id] == undefined){
            this.showloading();
            var obj ={
                text:text,
                sectionname:this.project.section.section,
                library:this.project.library,
                sectionid:null,
                editedon:this.detailform.defaultsection.lastdatasince,
                include:this.detailform.defaultsection.include,
                exclude:this.detailform.defaultsection.exclude
                }
            this.$http.post("rowdesignation/GetMLBQcode", obj)
                .then((response) => {
                    this.hideloading();
                    // this.mlbqcodes[this.amosrow.amosrow.id] = response.data; 
                    this.setbqcodes(response.data); 
                })
                .catch((err) => {
                    this.hideloading();
                    this.$bus.$emit("error", err);
                });
            // }else{
            //     this.setbqcodes(this.mlbqcodes[this.amosrow.amosrow.id]);
            // }
        },

        isinputreadable(row, name) {
            row[name] = row[name] == true ? false : true;
        },

        changebqcode(event, row) {
            if(event == ""){
                // this.detailform.individual = {};
                this.$set(this.detailform,'individual',{});
                this.$set(this.detailform.individual,'bqcodelibrary',{});
                this.$set(this.detailform.individual,'mlbqcode',{});
                this.$set(this.detailform.individual,'bqcodelibraryid',null);
                this.$set(this,'showtabldata',[]);
                this.$set(this,'tabledata',[]);
                this.$set(this,'showreport',false);
                // row.bqcodelibrary = {};
                // row.mlbqcode = {};
                // row.bqcodelibraryid = null;
                // this.showtabldata = [];
                // this.tabledata = []; 
                // this.showreport = false;
                this.amosbqcodes[this.amosrow.amosrow.id] = null;
                var isfind = this.selectfile.rownumbers.find(x => x.amosrow.id == this.amosrow.amosrow.id);
                 if(isfind != undefined){
                    this.$set(isfind.amosrow,'bqcodelibraryid',null);
                    this.$set(isfind.amosrow,'bqcodelibrary',{});
                    //  isfind.amosrow.bqcodelibraryid = null;
                    //  isfind.amosrow.bqcodelibrary = {};
                 }
            }
        },

        changerownumber(event, row) {
            if(this.checkallregex()){
                if (event != null) {
                    this.amoscurrentrow = event;
                    var isfind =  this.selectfile.rownumbers.find(x => x.name == event);
                    if(isfind != null){
                        this.$set(this,'amosrow',isfind);
                        this.$set(this.detailform,'individual',{});
                        // this.detailform.individual = {};
                        // if(this.amosbqcodes[this.amosrow.amosrow.id] == undefined){
                            // if(isfind.amosrow.bqcodelibraryid > 0){
                            // this.$set(this.detailform.individual,'bqcodelibrary',JSON.parse(JSON.stringify(isfind.amosrow.bqcodelibrary)))    
                            // this.$set(this.detailform.individual,'bqcodelibraryid',isfind.amosrow.bqcodelibraryid);   
                            // this.amosbqcodes[this.amosrow.amosrow.id] = JSON.parse(JSON.stringify(isfind.amosrow.bqcodelibrary));
                            // }else{
                                this.$set(this.detailform.individual,'bqcodelibrary',{});
                                this.$set(this.detailform.individual,'bqcodelibraryid',null);
                                this.$set(this,'showtabldata',[]);
                                this.$set(this,'tabledata',[]);
                                this.$set(this,'showreport',false);
                            // }
                        // }else{
                        //     var newbq =  JSON.parse(JSON.stringify(this.amosbqcodes[this.amosrow.amosrow.id]));
                        //     this.$set(this.detailform.individual,'bqcodelibrary',newbq)    
                        //     this.$set(this.detailform.individual,'bqcodelibraryid',newbq.bqcodelibraryid);
                        // }
                        this.GetMLBQcodes(isfind.searchtext)
                    }
                }
            }
        },

        dateformatter: function (_row, _column, cellValue) {
            if (cellValue) {
                return moment(cellValue).format("DD-MMM-YYYY");
            }
            return cellValue;
        },
        cleandata(){
            this.showtabldata = [];
            this.tabledata = []; 
            this.showreport = false;
            // this.detailform.individual.mlbqcode = {};
        },
        refresh: function () {
            let data = {
                library: this.project.library,
                section: this.project.section,
                projectpart: this.project.projectpart,
                id: this.project.id,
            };
            this.showloading();
            this.$http.post("rowdesignation/getbqcodeselection", data)
                .then((response) => {
                    this.cleandata();
                    this.hideloading();
                    this.detailform = response.data;
                    if(this.detailform.defaultsection.lastdatasince == null){
                        this.detailform.defaultsection.lastdatasince = new Date(new Date().setFullYear(new Date().getFullYear() - 1));
                    }
                    if(this.amoscurrentrow != null){
                        this.changerownumber(this.amoscurrentrow,this.detailform.individual);
                    }
                    this.checkallregex();
                })
                .catch((err) => {
                    this.hideloading();
                    this.$bus.$emit("error", err);
                });
        },

        savereport: function () {
            var save = ()=>{
                     var newarray = [];
                     this.amosbqcodes.forEach((itme,index) => {
                            if(index != undefined){
                                newarray.push({bqcodelibraryid:itme == null ? 0 : itme.id,id:index});
                            } 
                     });   
                     var newss = [];
                     this.showtabldata.forEach(e => {
                            if(e.ss && e.ss.isignore == true){
                                newss.push(e.ss.id);   
                            }
                     });
                     this.detailform.ssids = newss.join(",");      
                this.detailform.individual.amosrows = newarray;
                this.showloading();
                this.$http.post("rowdesignation/savebqcodealgorithm", this.detailform)
                    .then((response) => {
                        this.$message({
                            showClose: true,
                            message: response.data.message,
                            type: 'success'
                        });
                        this.hideloading();
                        this.changenrmregex();
                        this.refresh();
                    })
                    .catch((err) => {
                        this.hideloading();
                        this.$bus.$emit("error", err);
                    });
                }
                 if(this.checkallregex()){
                     save();
                 }
        },
        getreportrowmatch(data){
            var inc_pattern = null;
            var exc_pattern = null;
            
            if(this.detailform.individual.include != null && this.detailform.individual.include != ""){
                inc_pattern = new RegExp(this.detailform.individual.include,"i");
            }
            if(this.detailform.individual.exclude != null && this.detailform.individual.exclude != ""){
                exc_pattern = new RegExp(this.detailform.individual.exclude,"i");
            }
            var regxmatch = (data)=>{
            var text = data.ss.specification +" "+data.ss.asterisk +" "+data.ss.other;
                // var canspe = this.$training.RG_Direction_Match(data.ss.specification,inc_pattern,exc_pattern);
                // var canast = this.$training.RG_Direction_Match(data.ss.asterisk,inc_pattern,exc_pattern);
                // var canoth = this.$training.RG_Direction_Match(data.ss.other,inc_pattern,exc_pattern);
                var canoth = this.$training.RG_Direction_Match(text,inc_pattern,exc_pattern);
                if(canoth){
                     return true;
                 }else{
                    return false;
                 }
            }
            var filterdata = data.filter(regxmatch);
             return filterdata;   
        },
        createreport() {
            this.showreport = true;
            this.showloading();
            this.detailform.projectamosid = this.selectfile.projectamosid;
            if(this.detailform.individual && this.detailform.individual.mlbqcode){
                var tt =  this.detailform.individual.mlbqcode.ss;
                let words = tt.split(',');
                let uniqueWords = [...new Set(words)];
                this.detailform.individual.mlbqcode.ss = uniqueWords.join(',');
            }
            this.$http.post("rowdesignation/getbqcodeselectionreport", this.detailform)
                .then((response) => {
                    this.$set(this,'tabledata',this.getreportrowmatch(response.data.individual));
                    this.$set(this,'startlength',0);
                    this.$set(this,'endlength',this.tabledata.length < 20 ? this.tabledata.length : 20);
                    this.$set(this,'showtabldata',[]);
                    // this.tabledata = this.getreportrowmatch(response.data.individual);
                    // this.startlength = 0;
                    // this.endlength = this.tabledata.length < 10 ? this.tabledata.length : 10;
                    // this.showtabldata = [];
                    this.showtabldata.push(...this.setredtext(this.tabledata.slice(this.startlength, this.endlength)));
                    this.hideloading();
                })
                .catch((err) => {
                    this.hideloading();
                    this.$bus.$emit("error", err);
                });
        },
        deleteRow: function (event,index, row) {
            // this.$confirm('Are you sure you want to Exclude this row?', 'Warning', {
            //     confirmButtonText: 'OK',
            //     cancelButtonText: 'Cancel',
            //     type: 'warning'
            // }).then(() => {
                this.$set(row.ss,'isignore',event);
            // }).catch(() => {
            //     this.$set(row.ss,'isignore',false);
            //     this.$message({
            //         showClose: true,
            //         type: 'info',
            //         message: 'Exclude canceled'
            //     });
            // });
        },
    },

    components: {
        projectselectpart: projectselectpart
    },

    created() {
        if (this.$store.state.RDobj.bqcodeselect != undefined) {
            this.selectfile = this.$store.state.RDobj.bqcodeselect;
            this.$store.state.RDobj.bqcodeselect = null;
        }
    }
}
</script>
<style>
.amosrowcollapse .el-collapse-item__header {
  height: 28px;
}
</style>