<template>
    <div>
        <div  id="templates" v-if="!detail" class="createamt">
            <div class="fixdheader">
                <el-row>
                    <span class="headerstyle left paddingr83">MC_Templates Library</span>
                    <span class="left" style="width:430px;">
                        <!-- <el-pagination layout="prev, pager, next"
                                       :total="view.count"
                                       class="paddingtop"
                                       id="libraries_mctemplateslibrary_selectpage"
                                       @current-change="refresh2"
                                       :current-page.sync="view.pageno"
                                       :page-size="view.pagesize">
                        </el-pagination> -->
                        <el-pagination 
                            id="libraries_mctemplateslibrary_selectpage"
                            class="pagesizeswidth textalignlpage paddingtop"
                            @size-change="rowperpages"
                            @current-change="()=>{refresh2(),clickevant('libraries_mctemplateslibrary_selectpage')}"
                            :current-page.sync="view.pageno"
                            :page-sizes="pagesizes"
                            :page-size="view.pagesize"
                            layout="sizes, prev, pager, next"
                            :total="view.count">
                        </el-pagination>
                    </span>
                    <span class=" headerstyle floatright ">
                        <el-button type="primary" v-if="!additionalfilter" id="libraries_mctemplateslibrary_createnewlibrary" :disabled="!editpermission" @click="()=>{clickevant(`libraries_mctemplateslibrary_createnewlibrary`,`libraries_mctemplateslibrary_createnewlibrary`,()=>{createNew()},'MC_Templates Library - Add/Edit')}"
                         icon="el-icon-circle-plus">Create New&nbsp;</el-button>
                    </span>
                </el-row>

                <el-row>
                    <span class="left padright5px padleft22">
                        <el-select v-model="view.filter[0].value" class="libraryfixwidth" id="libraries_mctemplateslibrary_searchlibrary" clearable @change="()=>{onSearchLibrary(),clickevant('libraries_mctemplateslibrary_searchlibrary')}" @clear="onSearchLibrary" placeholder="Library">
                            <el-option v-for="item in libraries"
                                       :key="item"
                                       :label="item"
                                       :value="item">
                            </el-option>
                        </el-select>
                    </span>
                    <div class="left padright5px">
                        <el-autocomplete class="inline-input width260px bold"
                                        id="libraries_mctemplateslibrary_searchsection"
                                         v-model="view.filter[1].value"
                                         :fetch-suggestions="querySection"
                                         placeholder="Section"
                                         @select="()=>{onSearch2(),clickevant('libraries_mctemplateslibrary_searchsection')}"
                                         :spellcheck="isspellcheck">
                                         <el-button
                                         slot="suffix"
                                         @click="clearsection"
                                         type="text"
                                         size="mini"
                                         >&times;</el-button
                                     >
                        </el-autocomplete>
                    </div>
                        <el-input :spellcheck="isspellcheck" v-model="view.filter[2].value" placeholder="Template Name" id="libraries_mctemplateslibrary_templatename" @keyup.enter.native="()=>{onSearch2(),clickevant('libraries_mctemplateslibrary_templatename')}"
                        class="bold width260px">
                            <el-button slot="append" icon="el-icon-search" class="paddingl13" circle @click="()=>{refresh2(),clickevant('libraries_mctemplateslibrary_templatename')}"></el-button>
                        </el-input>
                       
                         <el-button type="primary" class="marginleft4px floatright"
                                   style="padding: 4px 8px 2px;" id="libraries_mctemplateslibrary_templatetoarchive" 
                                   @click="()=>{ShowArchiveChange(),clickevant('libraries_mctemplateslibrary_templatetoarchive')}">Show Archive
                        <el-switch v-model="additionalfilter"  @change="ShowArchiveChange"></el-switch>
                    </el-button>
                    <el-button type="primary" v-if="!additionalfilter && isarchiveselect" class="marginleft4px floatright"
                                    @click="addmultiarchive">Template To Archive 
                    </el-button>
                        <!-- <el-tooltip class="floatright padright8px" content="Show Hidden Templates" placement="top">
                          <span><b>View Hidden:</b>  <el-checkbox v-model="grid.additionalfilter" @change="onSearch" style="padding: 4px 12px 4px 12px !important" border></el-checkbox> </span>
                        </el-tooltip> -->

                    <!-- <span class="floatright padright8px">
                        <el-button type="primary" :disabled="!editpermission" @click="createNew()" icon="el-icon-circle-plus">Create New</el-button>
                    </span> -->
                </el-row>
                <hr class="hrstyle" />
            </div>
            <div class="tablepaddinglr5">
            <el-table :data="tabledata" :height="viewheight" class="fullwidth"
                      :default-sort="{prop: 't.templatename', order: 'ascending'}"
                      @expand-change="expandchangerow"
                      @sort-change="sortChange2"
                      v-loading="loading"
                      align="left">

                <el-table-column type="expand" width="25">
                    <template slot-scope="pt" class="fullwidth">
                        <!--nested table-->
                        <el-table :data="pt.row.details" class="fullwidth" row-key="id" ref="detail">
                            <el-table-column label="123" prop="srno" width="35"></el-table-column>
                            <el-table-column prop="category" label="Work Classification" width="230px"></el-table-column>
                            <el-table-column prop="bqcodelibrary.specification" label="SIL" width="35"></el-table-column>
                            <el-table-column prop="bqcodelibrary.bqcode" label="BQ Code" width="120"></el-table-column>
                            <el-table-column label="Dim" prop="qtydim" width="60" align="right"></el-table-column>
                            <el-table-column prop="unit" label="Unit" width="60"></el-table-column>
                            <el-table-column label="DiR" prop="isrequired" width="65" align="center">
                                <template slot-scope="td">
                                    {{td.row.isrequired ? '&#10004' : '&#10007'}}
                                </template>
                            </el-table-column>
                            <el-table-column label="Asterisk" property="asterisk" min-width="100"></el-table-column>
                            <el-table-column label="Specification" prop="specification" min-width="90"></el-table-column>
                            <el-table-column label="End Notes" prop="other"></el-table-column>
                        </el-table>
                    </template>
                </el-table-column>

                <el-table-column prop="library" label="Library" width="88"></el-table-column>
                <el-table-column prop="section" label="Section" sortable="custom" width="267"></el-table-column>
                <el-table-column prop="templatename" label="Template Name" sortable="custom" width="270"></el-table-column>
                <el-table-column prop="unit" label="Unit" sortable="custom" width="60"></el-table-column>
                <el-table-column prop="author.loginname" label="Author" width="85"></el-table-column>
                <!--<el-table-column></el-table-column>-->
                <!--<el-table-column :label="'\xa0\xa0'+'Edit'+'\xa0\xa0\xa0\xa0\xa0\xa0\xa0'+ 'Save' +'\xa0\xa0\xa0\xa0\xa0\xa0\xa0'+ 'Delete'" fixed="right" width="165">-->
                <el-table-column label="Actions" align="center" width="110">
                    <template slot-scope="scope">

                            <el-button size="mini" class="padding7" :id="'libraries_mctemplateslibrary_editlibrary'+scope.$index" type="primary" title="Edit" :icon="editpermission == true ? 'el-icon-edit' : 'el-icon-view'" 
                            @click="()=>{clickevant(`libraries_mctemplateslibrary_editlibrary`,`libraries_mctemplateslibrary_editlibrary${scope.$index}`,()=>{handleEdit(scope.$index, scope.row)},'MC_Templates Library - Add/Edit')}"> </el-button>
                            <el-button size="mini" class="paddingleftright7 paddingtopbottim6" type="primary" title="Copy" icon="glyphicon glyphicon-duplicate" 
                            @click="()=>{clickevant(`libraries_mctemplateslibrary_copylibrary`,`libraries_mctemplateslibrary_copylibrary${scope.$index}`,()=>{handleSaveAs(scope.$index, scope.row)},'Tip')}" :id="'libraries_mctemplateslibrary_copylibrary'+scope.$index" :disabled="!editpermission"></el-button>
                            <el-button size="mini" class="padding7"  type="danger" title="Delete" icon="el-icon-delete" 
                            @click="()=>{clickevant(`libraries_mctemplateslibrary_deletelibrary`,`libraries_mctemplateslibrary_deletelibrary${scope.$index}`,()=>{handleDelete(scope.$index, scope.row)},'Warning')}" :id="'libraries_mctemplateslibrary_deletelibrary'+scope.$index" :disabled="!editpermission"></el-button>

                    </template>
                </el-table-column>
                <el-table-column v-if="additionalfilter == false" prop="unit" label="Unit" align="center" width="30">
                    <template slot="header" slot-scope="scope">
                        <i class="glyphicon glyphicon-eye-close"  style="font-size: 18px;"></i>
                    </template>
                    <template slot-scope="scope">
                        <el-checkbox v-model="scope.row.isarchive" :id="`libraries_mctemplateslibrary_selectarchive${scope.$index}`" @change="()=>{selectarchive(scope.row,scope.$index),clickevant(`libraries_mctemplateslibrary_selectarchive`,`libraries_mctemplateslibrary_selectarchive${scope.$index}`)}" ></el-checkbox>
                    </template>
                </el-table-column>
            </el-table>
            </div>
        </div>

        <div id="templatedetail" v-show="detail">
             <div class="fixdheader">
           <el-row>
               <span class="headerstyle"> MC_Templates Library - Add/Edit</span>
               <span style="float: right;">
                    <el-button icon="el-icon-back" id="libraries_mctemplateslibraryaddedit_bsckaddedit" type="info" @click="goback">Back</el-button>
                    <!-- <el-button type="primary" class="marginleft4px"
                                   style="padding: 4px 6px 2px;" @click="setSepc()">Fix: Spec / *
                        <el-switch v-model="aseswitch" @change="setSepc()"></el-switch>
                    </el-button> -->
                    <el-button @click="cellchanged('savemctemplatelibrary',()=>{save()},false)" id="libraries_mctemplateslibraryaddedit_savelibrary" :disabled="!editpermission" type="success" icon="el-icon-circle-check" class="marginleft4px">Save</el-button>
                    <el-button v-if="detailform.id" id="libraries_mctemplateslibraryaddedit_saveaslibrary" :disabled="!editpermission" @click="cellchanged('saveasmctemplatelibrary',()=>{saveas()},false)"  type="success" icon="el-icon-circle-check" class="marginleft4px">Save as</el-button>
               </span>
            </el-row>

            <el-form ref="detailform" :model="detailform" size="mini" v-if="detail" class="informnomarginbtm">
                <el-row :gutter="5">
                <el-col class="width280">
                <el-form-item prop="templatename">
                    <el-input :spellcheck="isspellcheck" v-model="detailform.templatename" id="libraries_mctemplateslibraryaddedit_addedittemplatename" placeholder="Template Name" @change="changed"></el-input>
                </el-form-item>
                </el-col>

                <el-col class="width280">
                <el-form-item prop="section">
                    <el-select v-model="detailform.section" id="libraries_mctemplateslibraryaddedit_selectsection" filterable placeholder="Section" class="fullwidth" @change="changed">
                        <el-option
                            v-for="item in sections"
                            :key="item.section"
                            :label="item.section"
                            :value="item.section">
                        </el-option>
                    </el-select>
                </el-form-item>
                </el-col>

                <el-col class="width110">
                <el-form-item >
                    <el-autocomplete
                        class="inline-input"
                        popper-class="morewidth-autocomplete-project"
                        placeholder="NRM1 Code"
                        v-model="detailform.bcc.code"
                        :fetch-suggestions="querySearchBCC"
                        @select="selectsortcode"
                        @change="onSearchsortcode"
                        @blur="onSearchsortcode"
                        @clear="onSearchsortcode"
                        value-key="code"
                        id="libraries_mctemplateslibraryaddedit_selectsortcode"
                        clearable
                        suffix-icon="el-icon-search"
                      >
                      <template slot-scope="{ item }">
                                        <div style="white-space: normal">
                                        {{ item.description }}
                                        </div>
                                    </template>
                      </el-autocomplete>
                </el-form-item>
                </el-col>

                <el-col class="width110">
                <el-form-item prop="library">
                    <el-select v-model="detailform.library" id="libraries_mctemplateslibraryaddedit_selectlibrary" filterable placeholder="Library" @change="changed">
                        <el-option
                            v-for="item in libraries"
                            :key="item"
                            :label="item"
                            :value="item">
                        </el-option>
                    </el-select>
                </el-form-item>
                </el-col>

                <el-col :style="{width: unitwidth}">
                <el-form-item prop="unit">
                    <el-select v-model="detailform.unit" id="libraries_mctemplateslibraryaddedit_selectunit" placeholder="Unit" class="fullwidth" @change="changed">
                        <el-option
                            v-for="item in units"
                            :key="item"
                            :label="item"
                            :value="item">
                        </el-option>
                    </el-select>
                </el-form-item>
                </el-col>

                <el-col :span="2" v-if="detailform.author.loginname">
                <el-form-item prop="author">
                    &nbsp;<b class="fontsize12 left">Author:</b> <div class="fontsize12 left" style="font-weight: 500;">&nbsp; {{ detailform.author.loginname }}</div>
                </el-form-item>
                </el-col>
                <el-col :span="3" v-if="detailform.editedon">
                <el-form-item>
                    <b class="fontsize12 left">Last Used:</b> <div class="fontsize12 left" style="font-weight: 500;"> &nbsp;{{dateformatter}}</div>
                </el-form-item>
                </el-col>
                <div class="right" v-if="!additionalfilter && detailform.id > 0">  
                          <b class="fontsize12 marginleft12px"><i class="glyphicon glyphicon-eye-close"></i></b> &nbsp;<el-checkbox @change="changed" id="libraries_mctemplateslibraryaddedit_checkarchive" v-model="detailform.addarchive"></el-checkbox>
                    </div>
                    <div class="right">
                          <b class="fontsize12 marginleft12px">Keep</b> &nbsp;<el-checkbox @change="changed" v-model="detailform.keepmctemplate" id="libraries_mctemplateslibraryaddedit_checkkeepmctemplate"></el-checkbox>
                    </div>
                </el-row>
            </el-form>
            <hr class="hrstyle" />
           </div>

                <el-table :data="detailform.details" class="fullwidth customheader-no-border-table removeborder tableBox" size="mini" border>
                    <el-table-column prop="srno" align="center" width="35">
                        <template slot="header" slot-scope="{}">
                            <div style="display: grid;">
                                <el-popover placement="top" trigger="hover" content="Renumber rows to change order">
                                    <i slot="reference" class="el-icon-info" style="color:#606266;"></i>
                                </el-popover>
                                <span>123</span>
                            </div>
                        </template>
                        <template slot-scope="scope">
                            <el-input v-model="scope.row.srno" @change="srnochanged" class="hvrinput"></el-input>
                        </template>
                    </el-table-column>

                    <el-table-column prop="category" label="Work Classification" width="160">
                        <template slot-scope="scope">
                            <el-input type="textarea" 
                            :autosize="{ minRows: 2, maxRows: 3 }" 
                            :spellcheck="isspellcheck" 
                            v-model="scope.row.category" 
                            maxlength="32"
                            :id="'libraries_mctemplateslibraryaddedit_workclassification_'+scope.$index" 
                            @input='charcount($event, "workclassification", null)' 
                            @change="changed" 
                            class="hvrtextarea"></el-input>
                        </template>
                    </el-table-column>

                    <el-table-column prop="template" label="BQ Code" width="210">
                        <template slot-scope="scope">
                            <el-autocomplete
                                size="mini" type="textarea" :maxlength="50" :autosize="{ minRows: 2, maxRows: 3}"
                                class="inline-input fullwidth hvrtextarea paddingright15px"
                                popper-class="morewidth-autocomplete"
                                v-model="scope.row.bqcodelibrary.bqcode"
                                :fetch-suggestions="querySearch"
                                placeholder=""
                                :id="'libraries_mctemplateslibraryaddedit_bqcodeselect_'+scope.$index"
                                @select="($event) =>{handleSelect(scope.row, $event)}"
                                :select-when-unmatched="true"
                                value-key="bqcode"
                                @blur="bqcodeblur(scope.row, $event)"
                                @input='charcount($event, "bqcode", null)'
                                :trigger-on-focus="true">
                                <template slot-scope="{ item }">
                                    <div style="white-space:normal;">{{ item.bqcode }}</div>
                                    <div style="white-space:normal;font-size: x-small">{{ item.section }} | {{ item.group }} | {{ item.work }} | {{ item.level1 }} | {{ item.level2 }} | {{ item.level3 }}</div>
                                </template>
                            </el-autocomplete>
                            <span class="iconintextarea">
                                <el-button class="fontsize14px" slot="suffix" icon="el-icon-edit" @click="bqcodelibraryid=scope.row.bqcodelibraryid;projectbqcode=true" type="text" size="mini"></el-button>
                            </span>
                            <!--<div v-if="scope.row.bqcodelibrary != null && scope.row.bqcodelibrary.unit != scope.row.unit" class="red">BQ Code Unit '{{ scope.row.bqcodelibrary.unit }}' doesnot match</div>-->
                        </template>
                    </el-table-column>

                    <el-table-column prop="qtydim" width="180">
                        <template slot="header" slot-scope="{}">
                            <div style="display: flex; align-items: flex-end;">
                            <span style="padding-left: 10px;">Dim</span>
                            <span style="padding: 0 40px 0 50px">Unit</span>
                            <span style="display: grid; justify-items: center;">
                                <el-popover placement="top" trigger="hover" content="Dim is Required (in Dim box)">
                                    <i slot="reference" class="el-icon-info" style="color:#606266;"></i>
                                </el-popover>
                                <span>DiR</span>
                            </span>
                            </div>
                        </template>

                        <template slot-scope="scope">
                            <div class="flex">
                                <div class="flexcontent dimunit" style="padding-left: 5px;">
                                    <el-input-number v-model.number="scope.row.qtydim" @change="changed" :disabled="!scope.row.isrequired || scope.row.tddimcalculations.length > 0" :controls="false" :precision="6"></el-input-number>
                                </div>

                                <div class="flexlabel">
                                    <el-popover placement="bottom-start" title="Dim Pad Calculator" width="500" trigger="click">
                                        {{scope.row.bqcodelibrary.bqcode}}
                                        <el-table :data="scope.row.tddimcalculations" :show-summary="scope.row.tddimcalculations.length > 1" :summary-method="(param) => {return getDimsTotal(scope.row, param);}">
                                            <el-table-column prop="comment" label="Comment">
                                                <template slot-scope="tdd">
                                                    <el-input :spellcheck="isspellcheck" v-model="tdd.row.comment"></el-input>
                                                </template>
                                            </el-table-column>

                                            <el-table-column prop="nr" label="Nr" width="70" align="right">
                                                <template slot-scope="tdd">
                                                    <el-input-number
                                                        class="input-number-width"
                                                        v-model.number="tdd.row.nr"
                                                        @change="calculatedim(scope.row)"
                                                        :controls="false"
                                                        :precision="6">
                                                    </el-input-number>
                                                </template>
                                            </el-table-column>

                                            <el-table-column prop="length" label="Length" width="70" align="right">
                                                <template slot-scope="tdd">
                                                    <el-input-number
                                                        class="input-number-width"
                                                        v-model.number="tdd.row.length"
                                                        @change="calculatedim(scope.row)"
                                                        :controls="false"
                                                        :precision="6">
                                                    </el-input-number>
                                                </template>
                                            </el-table-column>

                                            <el-table-column prop="width" label="Width" width="70" align="right">
                                                <template slot-scope="tdd">
                                                    <el-input-number
                                                        class="input-number-width"
                                                        v-model.number="tdd.row.width"
                                                        @change="calculatedim(scope.row)"
                                                        :controls="false"
                                                        :precision="6">
                                                    </el-input-number>
                                                </template>
                                            </el-table-column>

                                            <el-table-column prop="height" label="Height" width="70" align="right">
                                                <template slot-scope="tdd">
                                                    <el-input-number
                                                        class="input-number-width"
                                                        v-model.number="tdd.row.height"
                                                        @change="calculatedim(scope.row)"
                                                        :controls="false"
                                                        :precision="6">
                                                    </el-input-number>
                                                </template>
                                            </el-table-column>

                                            <el-table-column prop="action" label="Action" width="70" align="right">
                                                <template slot-scope="tdd">
                                                    <el-button icon="el-icon-delete" type="danger" @click="deldim(scope.row, tdd.$index)"></el-button>
                                                </template>
                                            </el-table-column>

                                            <!-- <el-table-column prop="dim" label="Dim" width="70" align="right"></el-table-column> -->
                                        </el-table>
                                        <el-button icon="el-icon-circle-plus" type="primary" @click="adddim(scope.row)">Add</el-button>
                                        <el-button slot="reference" icon="el-icon-edit" type="text" size="mini" :disabled="!scope.row.isrequired"></el-button>
                                    </el-popover>
                                </div>

                                <div style="padding-right: 12px;">
                                    <el-select v-model="scope.row.unit" placeholder="Unit" @change="unitchanged(scope.row)">
                                        <el-option
                                            v-for="item in units"
                                            :key="item"
                                            :label="item"
                                            :value="item">
                                        </el-option>
                                    </el-select>
                                </div>

                                <div style="padding-right: 8px;">
                                    <el-checkbox v-model="scope.row.isrequired" @change="isrequiredchanged(scope.row);"></el-checkbox>
                                </div>
                            </div>
                        </template>
                    </el-table-column>

                    <el-table-column prop="bqcodelibrary.specification" align="center" width="30">
                        <template slot="header" slot-scope="{}">
                            <div style="display: grid;">
                                <el-popover placement="top" trigger="hover" content="Specification Insert Level - within Library">
                                    <i slot="reference" class="el-icon-info" style="color:#606266;"></i>
                                </el-popover>
                                <span>SIL</span>
                            </div>
                        </template>
                    </el-table-column>

                    <!-- <el-table-column prop="unit" label="Unit" width="100">
                        <template slot-scope="scope">
                            <el-select v-model="scope.row.unit" placeholder="Unit" @change="unitchanged(scope.row)">
                                <el-option
                                    v-for="item in units"
                                    :key="item"
                                    :label="item"
                                    :value="item">
                                </el-option>
                            </el-select>
                        </template>
                    </el-table-column>

                    <el-table-column prop="isrequired" label="DiR" width="60" :render-header="appendTip">
                        <template slot-scope="scope">
                            <el-checkbox v-model="scope.row.isrequired" @change="isrequiredchanged(scope.row);"></el-checkbox>
                        </template>
                    </el-table-column> -->

                    <el-table-column prop="specification" label="Specification" min-width="150">
                        <template slot-scope="scope">
                            
                            <el-popover placement="bottom-start" width="400" trigger="click" :open-delay="500">
                                
                                 <!-- <p class="resize2 textarea  el-textarea  el-textarea__inner " @input="setinput" @blur="onInputblur($event,scope.row,'specification')" @focus="onInputfocus" v-html="scope.row.specificationstyle" contentEditable="true"></p> 
                                 <p class="resize textarea  el-textarea  el-textarea__inner " @input="setinput" @blur="onInputblur($event,scope.row,'specification')" @focus="onInputfocus" v-html="scope.row.specificationstyle" slot="reference"  contentEditable="true" ></p>  -->
                                
                                <el-input contenteditable="true" type="textarea"  :spellcheck="isspellcheck" :autosize="{ minRows: 2, maxRows: 7 }" maxlength="255" v-model="scope.row.specification"  @change="changed" @input='charcount($event, "specification", scope.row)'></el-input>

                                <el-input type="textarea" class="hvrresizetxtarea" :rows="2" maxlength="255" :spellcheck="isspellcheck" slot="reference" :ref="scope.row.id" :id="'libraries_mctemplateslibraryaddedit_specification_'+scope.$index" v-model="scope.row.specification" @change="changed" @input='charcount($event, "specification", scope.row)'> </el-input>

                                <!-- <div id="specinput" @input="changecolorinput($event, 'specification', scope.row)" contenteditable>{{scope.row.specification}}</div>
                                <div id="specinput" @input="changecolorinput($event, 'specification', scope.row)" slot="reference" :ref="scope.row.id" contenteditable>{{scope.row.specification}}</div> -->
                            </el-popover>   
                           </template>
                    </el-table-column>

                    <el-table-column prop="asterisk" label="Asterisk" min-width="150">
                        <template slot-scope="scope">
                            <el-popover placement="bottom-start" width="400" trigger="click" :open-delay="500">
                                <el-input type="textarea"  :spellcheck="isspellcheck" :autosize="{ minRows: 2, maxRows: 7 }" maxlength="255" v-model="scope.row.asterisk" @change="changed" @input='charcount($event, "asterisk", scope.row)'></el-input>
                                <el-input type="textarea" class="hvrresizetxtarea " :rows="2" maxlength="255" :spellcheck="isspellcheck" slot="reference" :id="'libraries_mctemplateslibraryaddedit_asterisk_'+scope.$index" :ref="scope.row.id" v-model="scope.row.asterisk" @change="changed" @input='charcount($event, "asterisk", scope.row)'> </el-input>
                                <!-- <p class="resize2 textarea  el-textarea  el-textarea__inner " @input="setinput" @blur="onInputblur($event,scope.row,'asterisk')" @focus="onInputfocus" v-html="scope.row.asteriskstyle" contentEditable="true"></p> 
                                 <p class="resize textarea  el-textarea  el-textarea__inner " @input="setinput" @blur="onInputblur($event,scope.row,'asterisk')" @focus="onInputfocus" v-html="scope.row.asteriskstyle" slot="reference"  contentEditable="true" ></p>  -->
                            </el-popover>
                        </template>
                    </el-table-column>

                    <el-table-column prop="other" label="End Notes" min-width="100">
                        <template slot-scope="scope">
                            <el-popover placement="bottom-start" width="400" trigger="focus" :open-delay="500">
                                <el-input type="textarea"  :spellcheck="isspellcheck" :autosize="{ minRows: 2, maxRows: 7 }" maxlength="255" v-model="scope.row.other" @change="changed" @input='charcount($event, "endnotes", null)'></el-input>
                                <el-input type="textarea" class="hvrresizetxtarea" :rows="2" maxlength="255" :spellcheck="isspellcheck" slot="reference" :ref="scope.row.id" v-model="scope.row.other" :id="'libraries_mctemplateslibraryaddedit_endnotes_'+scope.$index" @change="changed" @input='charcount($event, "endnotes", null)'> </el-input>
                            </el-popover>
                        </template>
                    </el-table-column>

                    <el-table-column width="100">
                        <template slot-scope="scope">

                                <el-button title="Add New" class="padding7" icon="el-icon-circle-plus"  type="primary" :disabled="!editpermission" :id="'libraries_mctemplateslibraryaddedit_addnew'+scope.$index" @click="addRow(scope.row, scope.$index, false)"></el-button>

                                <el-button title="Copy Row" class="paddingleftright7 paddingtopbottim6" icon="glyphicon glyphicon-duplicate" :id="'libraries_mctemplateslibraryaddedit_copyrow'+scope.$index"  type="primary" :disabled="!editpermission" @click="addRow(scope.row, scope.$index, true)"></el-button>


                                <el-button title="Delete Row" class="padding7" :disabled="!editpermission" size="mini" type="danger" icon="el-icon-delete"
                                        :id="'libraries_mctemplateslibraryaddedit_deleterow'+scope.$index"
                                       @click="delRow(scope.$index, scope.row)"></el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <!-- <el-form-item class="text-right">
                    <el-button title="Add New" icon="el-icon-circle-plus" type="primary" :disabled="!editpermission" @click="addRow(false)"></el-button>
                    <el-button title="Copy Row" icon="glyphicon glyphicon-duplicate" type="primary" :disabled="!editpermission" @click="addRow(true)"></el-button>
                </el-form-item> -->

                <!-- <el-form-item label="">
                    <el-button @click="save()" :disabled="!editpermission" type="success" icon="el-icon-circle-check">Save</el-button>
                    <el-button v-if="detailform.id" :disabled="!editpermission" @click="saveas()"  type="success" icon="el-icon-circle-check">Save as</el-button>
                    <el-button icon="el-icon-back" type="info" @click="goback">Back</el-button>
                </el-form-item> -->
            <!-- </el-form> -->

            <el-dialog v-if="projectbqcode" title="BQ Code Library" :visible.sync="projectbqcode" width="90%">
                <!--Bqcode library and Project BQCode both are edited by the same template i.e. projectbqcode-->
                <projectbqcode :projectsectionid="0" :bqcodelibraryid="bqcodelibraryid" :bqcodelibrary="true" @closedialogue="closedialogue"></projectbqcode>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import projectbqcode from './projectbqcode.vue';
import { setTimeout } from 'core-js';
import moment from 'moment';

export default {
    data() {
        return {
            viewheight: (document.documentElement.clientHeight - 133),
            archivearray:[],
            isarchiveselect:false,
            cdata:{
                columns:[{name:'library',width:'88'},{name:'section',width:'267'},{name:'templatename',width:'270'}]
            },
            additionalfilter:false,
            view: {
                pagesize:20,
                filter: [
                { table: 't', column: 'library', value: "" },
                { table: 't', column: 'section', value: "" },
                { table: 't', column: 'templatename', value: "" },
                ],
                sortcol: "t.templatename",
                sortorder: "descending",
            },
            pageview:[],
            ShowArchisve:false,
            detail: false,
            tabledata: [],
            detailform: {},
            errormessage: "",
            units: this.$store.state.units,
            pagesizes: this.$store.state.pagesizes,
            sections: this.$store.state.sections,
            libraries: this.$store.state.libraries,
            loading: false,
            projectbqcode: false,
            bqcodelibraryid: 0,
            unitwidth:"60px"
        }

    },
    inject: {
            clickevant:{from:"clickevant",default:Function}
        },

    computed: {
        // units: function () {
        //     return this.$store.state.units;
        // }

        dateformatter: function() {
            var dt = this.detailform.editedon;
            return moment(dt).format("DD-MMM-YYYY");
        }
    },

    methods: {
        getheight() {
            this.viewheight = (document.documentElement.clientHeight - 133);
        },


        getsection(){
            if(this.detailform.section){
                return this.detailform.section;
            }else{
                return "";
            }
        },
        cellchanged(cellaction,callback,cancall){
            const app = this;
            this.$directionutility.setcomponent(app);
            if(cancall){
                callback();
                this.canmatchcellid(()=>{},cellaction,this.getsection());
            }else{
                this.canmatchcellid(callback,cellaction,this.getsection());
            }
        },
        querySearchBCC: function (query, done) {
            var obj = {};
            obj.library = this.detailform.library; 
            var codeid = [];
           this.detailform.details.forEach((ex)=>{
                if(ex.bqcodelibraryid != null && ex.bqcodelibraryid > 0){
                    codeid.push({bqcodelibraryid:ex.bqcodelibraryid});
                }
            }); 
            obj.details = codeid;
            if(query == undefined){
                query = "";
            }
            this.$http
                .post("billcompilecode/getbccbyprojecttemplate?q=" + query,obj)
                .then((response) => {
                    // var bcc = JSON.parse(response.data);
                    done(response.data);
                })
                .catch((err) => {
                    this.$bus.$emit("error", err);
                });
        },
        selectsortcode(data){
            var ss = () =>{
                this.onSearchsortcode(data)
            }
            this.canmatchcellid("libraries_mctemplateslibraryaddedit_selectsortcode",ss);
        },
        onSearchsortcode(data){
             if(data && data.id){
                 this.detailform.billcompilecodeid = data.id;
             }else{
                 this.detailform.billcompilecodeid = null;
             }
        },
        addmultiarchive(){
            var newarray = [];
            this.archivearray.forEach(e => {
                newarray.push(this.tabledata[e]);
            });
            this.$http.post('template/addmultitemplatetoarchive', newarray)
                    .then(response => {
                        this.$message({
                            showClose: true,
                            message: response.data, type: "success"
                        });
                        this.archivearray = [];
                        this.isarchiveselect = false;
                        this.$nextTick(()=>{
                            this.refresh2();
                        })
                    })
                    .catch(err => {
                        this.$bus.$emit('error', err);
                    });
        },
        selectarchive(data,index){
            if(data.isarchive == true){
                this.archivearray.push(index);
            }else{
                var ind =  this.archivearray.findIndex(x => x == index);
                this.archivearray.splice(ind,1);
            }
            if(this.archivearray.length == 0){
                this.isarchiveselect = false;
            }else{
                this.isarchiveselect = true;
            }
        },
        clearsection(){
            this.view.filter[1].value = "";
            this.view.pageno = 1;
            this.refresh2();
        },
        onSearch2: function () {
            this.view.pageno = 1;
            this.refresh2();
        },

        rowperpages: async function (val) {
            try {
                this.usergridrow.ugv.rowperpage = val;
                this.usergridrow = await this.$training.savepagelayout(this.usergridrow);
                this.view.pageno = 1;
                this.view.pagesize = val;
                this.refresh2();
            } catch (error) {
                this.$bus.$emit('error', error);
            }
        },

        onSearchLibrary(){
            // var selctlibrary =  ()=>{
                this.view.pageno = 1;
                this.refresh2();
            // }
            // this.canmatchcellid("libraries_mctemplateslibrary_searchlibrary",selctlibrary);
            // var isfind = this.$store.state.pagecells.find(x => x.cellname == "libraries_mctemplateslibrary_searchlibrary");
            // if(isfind){
            //     this.getdirectionbycellname(isfind.cellname,"",selctlibrary);
            // } else{
            //     createnewlibrary();
            // }
            
        },
        sortChange2: function (sort) {
          this.view.sortcol = "t."+ sort.prop;
          this.view.sortorder = sort.order;
          this.refresh2();
        },
        ShowArchiveChange(){
            // var ss = () => {
                this.$nextTick(()=>{
                this.view.pageno = 1;
                this.additionalfilter == false ? this.additionalfilter = true : this.additionalfilter = false;
                this.refresh2();
            })
            // };


        //    this.canmatchcellid("libraries_mctemplateslibrary_templatetoarchive",ss);
        },
        expandchangerow(data){
            if(data.isget == undefined){
                data.isget = false;
            }
            if(data.isget == false){
                this.$http.post("template/gettemplatedetailbyid?isarchive="+this.additionalfilter, {id:data.id}).then(
                    (response) => {
                       data.details = response.data;
                       data.isget = true;
                 }).catch(err => {
                        this.$bus.$emit('error', err);
                    })
             }
       },
        refresh2(){
            // this.loading = true;
            this.$store.state.isLoading = true;

            this.$http.post("template/getgridview?isarchive="+this.additionalfilter, this.view).then(
                (response) => {
                    this.$nextTick(()=>{
                    this.$set(this,'tabledata',JSON.parse(JSON.stringify(response.data.data))); 
                        
                        this.tabledata.forEach((e)=>{
                            this.$set(e,'isarchive',false);
                            //   e.isarchive = false;  
                        });
                        this.view = response.data.view;
                        // this.loading = false;
                        this.$store.state.isLoading = false;
                    })
                }).catch(err => {
                        this.$bus.$emit('error', err);
                        //  this.loading = false;
                        this.$store.state.isLoading = false;
                    })
       },
        // onInputblur(e,data,name){
        //     data[name] = "";
        //     data[name] = e.target.innerText;
        //     data[name+'style'] = "";
        //     data[name+'style'] = e.target.innerHTML;
        //     this.setcolorcall();
        // },
        // onInputfocus(){
        //    this.setcolorcall();
        // },
        // setinput(){
        //     if(this.countchangeinput <= 2){
        //        this.countchangeinput ++;
        //        this.setcolorcall();
        //     }else{
        //         this.countchangeinput = 0;
        //     }
        // },
        // setSepc(){
        //     this.$set(this,'aseswitch',this.aseswitch == false ? true : false)
        //     this.setcolorcall();
        // },
        // setcolorcall(){
        //     if(this.aseswitch)
        //     {
        //         this.setcolor('#606266'); 
        //     }else{
        //         this.setcolor('#409EFF');
        //     }
        // },
        // setcolor(color){
        //     this.$nextTick(()=>{
        //     document.execCommand('styleWithCSS', false, true);
        //     document.execCommand('foreColor', false, color);
        //     });
        // },
        focusEdit: function () {
            setTimeout(() => {
                this.$refs.templatename.focus();
            }, 100)
        },

        focusDisplay: function () {
            setTimeout(() => {
                this.$refs.templatename.focus();
            }, 100)
        },

        // changecolorinput: function(val, colname, row) {
        //     //  var selection1 = document.getSelection();
        //     // var childOffset1 = selection1.focusOffset;
        //     // const range = document.createRange();
        //     // var eDiv1 = document.getElementById("specinput");
        //     // range.setStart(eDiv1, 0);
        //     // range.setEnd(selection1.focusNode, childOffset1);
        //     // var sHtml1 = range.toString();
        //     // var p1 = sHtml1.length;
        //     // console.log('caret at:-' + p1);

        //     if (val.target.textContent) {
        //     // row.specification = val.target.textContent;
        //     var istrue = this.aseswitch == true ? "1" : "0";
        //     if (row.specification == "" || !row.specification) {
        //         row.specification = "";
        //         row.binaryspec = "";

        //     }
        //     if (!row.ischeck) {
        //         row.ischeck = "";
        //     }

        //     if (row.ischeck != istrue) {
        //         row.specification += `<span style="color:${istrue == '1' ? 'red' : 'blue'}" >${val.data}</span>`;
        //         row.ischeck = istrue;
        //     } else {
        //         var firststring = row.specification.slice(0, -7);
        //         var newstring = firststring + val.data + "</span>";
        //         row.specification = newstring;
        //     }
        //     }
        //     val.target.innerHTML = row.specification;

        //     this.setCaret();

        //     //  var selection = document.getSelection();
        //     // var childOffset = selection.focusOffset;
        //     // const range2 = document.createRange();
        //     // var eDiv = document.getElementById("specinput");
        //     // range2.setStart(eDiv, 0);
        //     // range2.setEnd(selection.focusNode, childOffset);
        //     // var sHtml = range2.toString();
        //     // var p = sHtml.length;
        //     // console.log('caret at:-' + p);

        //     // if (!row.binaryspec) {
        //     //     row.binaryspec = "";
        //     // }
        //     // var text = row.specification.split("");

        //     // if (val.data) {


        //     // var count = val.data.length;
        //     // var newstring = istrue.repeat(count);
        //     // row.binaryspec += newstring;
        //     // var binary = row.binaryspec.split("");
        //     // console.log(row.binaryspec);

        //     //  var rsstring = "";
        //     //  val.target.innerHTML = "";
        //     // text.forEach((rs, index) => {
        //     //     if (binary[index] == "1") {
        //     //         rsstring += "<span style='color: red;'>" + rs + "</span>";
        //     //     } else {
        //     //         rsstring += "<span>" + rs + "</span>";
        //     //     }
        //     // });
        //     // val.target.innerHTML += rsstring;
        //     // }
        //     // } else {
        //     //     row.specification = "";
        //     //     row.binaryspec = "";
        //     // }

        //     // document.getElementById("specinput").addEventListener('keypress', (e) => {
        //     //     e = e || window.event;
        //     //     var charCode = e.which || e.key;
        //     //     if (charCode == 13) {
        //     //         moveCaret(window, 4);
        //     //         return false;
        //     //     }
        //     // });

        //     // var selection = document.getSelection();
        //     // var childOffset = selection.focusOffset;
        //     // const range = document.createRange();
        //     // var eDiv = document.getElementById("spec");
        //     // range.setStart(eDiv, 0);
        //     // range.setEnd(selection.focusNode, childOffset);
        //     // var sHtml = range.toString();
        //     // var p = sHtml.length;
        //     // console.log(p);

        //     // row.oldspec = val.target.innerText;
        //     // val.target.innerHTML = converttxt;
        //     // var tagstring = "";
        //     // text.forEach(e => {
        //     //     if (istrue == "1") {
        //     //         tagstring += "<span style='color: red;'>" + e + "</span>";
        //     //     } else {
        //     //         tagstring += "<span>" + e + "</span>";
        //     //     }
        //     // });
        //     // val.target.innerHTML = tagstring;
        //     // var count = val.target.innerText.length;
        //     // var newstring = istrue.repeat(count);
        //     // row.binaryspec += newstring;
        //     // this.$set(row, "specification", val.target.innerText);
        //     // console.log(row.binaryspec.length);
        //     // var text = row.specification.split("");
        //     // var binary = row.binaryspec.split("");

        //     // console.log(row.specification.length);

        //     // var rsstring = "";
        //     // text.forEach((rs, index) => {
        //     //     if (binary[index] == "1") {
        //     //         rsstring += "<span style='color: red;'>" + rs + "</span>";
        //     //     } else {
        //     //         rsstring += "<span>" + rs + "</span>";
        //     //     }
        //     // });
        //     // val.target.innerHTML += rsstring;

        //     // this.charcount(val, colname);
        //     // var t = row.specification.split("");
        //     // row.colortext = "10101";
        //     // var ct = row.colortext.split("");
        //     // var string = "";
        //     // val.target.innerHTML = "";
        //     // row.binaryspec.forEach((w, index) => {
        //     //     if (ct[index] && ct[index] == "1") {
        //     //         string += "<span style='color: red;'>" + w + "</span>";
        //     //     } else {
        //     //         string += "<span>" + w + "</span>"
        //     //     }
        //     // });
        //     // val.target.innerHTML += string;
        // },

        // moveCaret: function(win, charCount) {
        //     var sel, range;
        //     if (win.getSelection) {
        //         sel = win.getSelection();
        //         if (sel.rangeCount > 0) {
        //             var textNode = sel.focusNode;
        //             var newOffset = sel.focusOffset + charCount;
        //             sel.collapse(textNode, Math.min(textNode.length, newOffset));
        //         }
        //     } else if ( (sel = win.document.selection) ) {
        //         if (sel.type != "Control") {
        //             range = sel.createRange();
        //             range.move("character", charCount);
        //             range.select();
        //         }
        //     }
        // },

        charcount: function(val, colname, row) {
            if (row && colname == "specification" || colname == "asterisk") {
                this.aseswitch == true ? row[colname + 'style'] = true : row[colname + 'style'] = false;
            }

            if (/^\s+$/.test(val) || val == undefined) {
                val = "";
            }
            if (val.length >= 32  && colname == "workclassification") {
                var style = "<style> .el-message-box {width:30%;} </style>";
                this.$alert("You have reached the maximum limit of 32 characters."+style, "Length Limitation", {
                    dangerouslyUseHTMLString: true,
                });
            }
            if (val.length >= 50 && colname == "bqcode") {
                var style = "<style> .el-message-box {width:30%;} </style>";
                this.$alert("You have reached the maximum limit of 50 characters."+style, "Length Limitation", {
                    dangerouslyUseHTMLString: true,
                });
            }
            if (val.length >= 255 && (colname == "specification" || colname == "asterisk" || colname == "endnotes")) {
                var style = "<style> .el-message-box {width:30%;} </style>";
                this.$alert("You have reached the maximum limit of 255 characters."+style, "Length Limitation", {
                    dangerouslyUseHTMLString: true,
                });
            }
        },

        adddim: function (td) {
            var itm = {
                id: (td.tddimcalculations.length + 1) * -1,
                templateid: td.templateid,
                templatedetailid: td.id,
                nr: 0,
                length: 0,
                width: 0,
                height: 0
            };
            td.tddimcalculations.push(itm);
        },

        isrequiredchanged: function (td) {
            if (!td.isrequired) {
                td.tddimcalculations = [];
            }
            this.changed();
        },

        deldim: function (td, index) {
            td.tddimcalculations.splice(index, 1);
            this.calculatedim(td);
        },

        getDimsTotal(row, param) {
            const sums = [];
            param.columns.forEach((column, index) => {
                if (column.property === "comment") {
                    sums[index] = 'Total';
                    return;
                }
                if (column.property === "action") {
                    sums[index] = '';
                    return;
                }

                const values = param.data.map(item => Number(item[column.property]));
                if (!values.every(value => isNaN(value))) {
                    sums[index] = '' + values.reduce((prev, curr) => {
                        const value = Number(curr);
                        if (!isNaN(value)) {
                            return prev + curr;
                        } else {
                            return prev;
                        }
                    }, 0);

                } else {
                    sums[index] = '0.000';
                }
            });

            sums.forEach((v, i) => {
                if (!isNaN(v) && v != '') {
                    sums[i] = parseFloat(v).toFixed(3);
                }
            });

            return sums;
        },

        calculatedim: function (row) {
            if (row.tddimcalculations.length > 0) {
                var sum = { nr: 0.0, length: 0.0, width: 0.0, height: 0.0 }
                row.tddimcalculations.forEach((a) => {
                    if (!isNaN(a.nr))
                        sum.nr += parseFloat(a.nr);
                    if (!isNaN(a.length))
                        sum.length += parseFloat(a.length);
                    if (!isNaN(a.width))
                        sum.width += parseFloat(a.width);
                    if (!isNaN(a.height))
                        sum.height += parseFloat(a.height);
                });
                row.qtydim = 1;
                if (sum.nr > 0)
                    row.qtydim *= sum.nr;
                if (sum.length > 0)
                    row.qtydim *= sum.length;
                if (sum.width > 0)
                    row.qtydim *= sum.width;
                if (sum.height > 0)
                    row.qtydim *= sum.height;

                var dim = row.qtydim;
            }
        },

        addRow: function (row, rowindex, copy) {
            var itm = { srno: parseInt(row.srno) + 1, qtydim: 1, tddimcalculations: [] };
            itm.bqcodelibrary = { bqcode: '', tddimcalculations: [] };
            if (copy) {
                if (this.detailform.details.length > 0) {
                    var last = this.detailform.details[rowindex];
                    var last = JSON.parse(JSON.stringify(last))
                    last.srno = itm.srno;
                    last.id = 0
                    last.tddimcalculations.forEach((tdd) => {
                        tdd.id = 0;
                    });
                    last.category += "-Copy"
                    itm = last;
                }
            }
            this.detailform.details.splice(rowindex + 1, 0, itm);
            var newsrno = 1;
            this.detailform.details.forEach(sn => {
                sn.srno = newsrno++;
                sn.srno = sn.srno > 9 ? sn.srno : "0" + sn.srno;
            });
            this.changed();
        },

        delRow: function (scope, row) {
            this.detailform.details.splice(row.srno - 1, 1);
            var srno = 1;
            for (var i = 0; i < this.detailform.details.length; i++) {
                var sn = this.detailform.details[i].srno = srno++;
                this.detailform.details[i].srno = sn > 9 ? sn : "0" + sn;
            }
            if (this.detailform.details.length == 0) {
                this.addRow({srno: 0}, 0, false);
            }
            this.changed();
        },

        createNew: function () {
            // var createnewlibrary = () => {
                this.additionalfilter = false;
                this.detailform = {
                    billcompilecodeid:null,
                    bcc:{code:""}, library: 'SMM7', details: [], section: this.$store.state.section, author: {} }
                    
                if (this.$store.state.project.library) {
                    this.detailform.library = this.$store.state.project.library;
                }
                this.addRow({srno: 0}, 0, false);
                this.detail = true;
                this.unitwidth = "60px";
            // }  
            // this.canmatchcellid("libraries_mctemplateslibrary_createnewlibrary",createnewlibrary);   
            // var isfind = this.$store.state.pagecells.find(x => x.cellname == "libraries_mctemplateslibrary_createnewlibrary");
            // if(isfind){
            //     this.getdirectionbycellname(isfind.cellname,"",createnewlibrary);
            // } else{
            //     createnewlibrary();
            // }
            // console.log(this.$store.state.pagecells);
            
            
            // this.focusEdit();
        },

        srnochanged: function () {
            var compare = function (a, b) {
                return (a.srno > b.srno) ? 1 : ((b.srno > a.srno) ? -1 : 0);
            }
            this.detailform.details = this.detailform.details.sort(compare);
            var srno = 1;
            this.detailform.details.forEach((v, i) => { v.srno = srno++; });
            this.changed();
        },

        validationwarning: function (success) {
            var msg = [];
            var wc = [];
            this.detailform.details.forEach((t, i) => {
                if (!t.category) {
                    msg.push("Check Work Classification entry for row " + (i + 1) + ". No empty cells allowed");
                }
                else {
                    wc.push({ key: (i + 1), value: t.category });
                }
                if (!t.unit || !t.bqcodelibraryid) {
                    msg.push("Check bqcode/unit for row " + (i + 1));
                } else {
                    if (t.bqcodelibrary != null && t.unit != null) {
                        if (t.bqcodelibrary.unit != t.unit) {
                            msg.push("Unit for BQ Code " + t.bqcodelibrary.bqcode + " does not match BQ Code library unit " + t.bqcodelibrary.unit + " for row " + (i + 1));
                        }
                    }
                }
                if (t.bqcodelibrary != null && t.bqcodelibrary.uppertolerance > 0) {
                    if (t.qtydim < t.bqcodelibrary.lowertolerance || t.qtydim > t.bqcodelibrary.uppertolerance) {
                        msg.push("Dim should be between " + t.bqcodelibrary.lowertolerance + " and " + t.bqcodelibrary.uppertolerance + " for row " + (i + 1));
                    }
                }
            });
            if (wc.length > 1) {
                for (var a = 0; a < wc.length; a++) {
                    var wc1 = wc[a];
                    for (var b = a+1; b < wc.length; b++) {
                        var wc2 = wc[b];
                        if (wc1.value == wc2.value) {
                            msg.push("Check Work Classification entry for row " + wc1.key + " and " + wc2.key + ". No duplication cells allowed");
                        }
                    }
                }
            }
            if (msg.length > 0) {
                this.$confirm(msg.join('<br />'), "! Warning", {
                    dangerouslyUseHTMLString: true,
                    confirmButtonText: 'Save',
                    cancelButtonText: 'Cancel',
                    customClass: 'custommessageboxstyle'
                }).then(success).catch(() => {/*Cancel clicked*/ });;
            } else {
                success();
            }
        },
        templatetoarchive(){
            this.$http.post('template/templatetoarchive', this.detailform)
                    .then(response => {
                        this.$message({
                            showClose: true,
                            message: response.data, type: "success"
                        });
                        this.detail = false;
                        this.detailform.changed = false;
                        this.$nextTick(()=>{
                            this.refresh2();
                        })
                    })
                    .catch(err => {
                        this.$bus.$emit('error', err);
                    });
        },  
        beforesave(){
            var ss = ()=>{
                this.save()
            }
            this.canmatchcellid("libraries_mctemplateslibraryaddedit_savelibrary",ss);   
            
        },
        beforesaveas(){
            var ss = ()=>{
                this.saveas()
            } 
            this.canmatchcellid("libraries_mctemplateslibraryaddedit_saveaslibrary",ss);
        },
        save: function (item, event) {
            this.validationwarning(() => {
                 if(this.detailform.addarchive){
                    this.templatetoarchive();
                 }else{
                    this.$http.post('template/save?isarchive='+this.additionalfilter, this.detailform)
                        .then(response => {
                            this.$message({
                                showClose: true,
                                message: response.data, type: "success"
                            });
                            this.detail = false;
                            this.detailform.changed = false;
                            this.refresh2();
                        })
                        .catch(err => {
                            this.$bus.$emit('error', err);
                            //console.log(err.response.data);
                            //this.$alert(err.response.data, err.response.status + ' ' + err.response.statusText, { dangerouslyUseHTMLString: true });
                        });
                }
            });
        },

        saveas: function () {
            this.$prompt('Please enter new Template name', 'Tip', {
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancel',
                inputValue: this.detailform.templatename + " - Copy"
            }).then(value => {
                this.detailform.id = 0;
                this.detailform.templatename = value.value;

                this.detailform.details.forEach(function (row, i) {
                    row.id = 0;
                });
                this.save();
            });
        },

        bqcodeblur: function (item, event) {
            if (!item.bqcodelibrary.bqcode) {
                item.bqcodelibraryid = 0;
                this.changed(null, item);
            }
        },

        handleSelect(item, value) {
            item.bqcodelibraryid = value.id;
            item.bqcodelibrary = value;
            item.unit = value.unit;
            this.unitchanged(item);
            //console.log(item);
        },

        closedialogue: function () {
            this.projectbqcode = false;
            //this.projecttemplate = false;
            //if (this.detailform.changed) {
            //    this.save();
            //}
            //else {
            //    this.refresh();
            //}
        },

        querySection: function (query, done) {
            query = query ? query : ""; // ifnull set to empty string
            var result = [];
            this.sections.forEach(x => {
                if (x.section.toLowerCase().indexOf(query.toLowerCase()) >= 0)
                    result.push({ value: x.section });
            });
            done(result);
        },

        querySearch: function (query, done) {
            if (query == null)
                query = '';
            this.$http.post('bqcodelibrary/search?library=' + this.detailform.library + '&q=' + query, {})
                .then(response => {
                    this.bqcodes = JSON.parse(response.data);
                    done(this.bqcodes)
                })
                .catch(err => {
                    this.$bus.$emit('error', err);
                })
        },

        handleSaveAs: function (scope, row) {
            this.$http.post('template/get?isarchive='+this.additionalfilter, row)
                .then(response => {
                    this.additionalfilter = false;
                    var json = JSON.parse(response.data);
                    this.detailform = json;
                    this.saveas();
                }).catch(err => {
                    this.$bus.$emit('error', err);
                    //console.log(err);
                    //this.$alert(err.response.data, err.response.status + ' ' + err.response.statusText, { dangerouslyUseHTMLString: true });
                });
        },

        handleEdit: function (scope, row) {
         this.$http.post('template/get?isarchive='+this.additionalfilter, row)
             .then(response => {
                 var json = JSON.parse(response.data);
                 this.detailform = json;
                 if (this.detailform.unit) {
                     var size = this.measureTextWidth(this.detailform.unit, 12);
                     this.unitwidth = (size + 35) + "px";
                 } else {
                     this.unitwidth = "60px";
                 }
                 this.detailform.details.forEach(r => {
                     var sn = parseInt(r.srno);
                     r.srno = sn > 9 ? sn : "0" + sn;
                 });
                 this.detail = true;
             }).catch(err => {
                 this.$bus.$emit('error', err);
                 //console.log(err);
                 //this.$alert(err.response.data, err.response.status + ' ' + err.response.statusText, { dangerouslyUseHTMLString: true });
             });
        },

        handleDelete: function (scope, row) {
                this.$confirm('This will permanently delete ' + row.templatename + '. Continue?', 'Warning', {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                }).then(() => {
                    this.$http.post('template/delete?isarchive='+this.additionalfilter, row)
                        .then(response => {
                            this.refresh2();
                            this.$message({
                                showClose: true,
                                type: 'success', message: 'Delete completed'
                            });
                        })
                        .catch(err => {
                            this.$bus.$emit('error', err);
                            //console.log(err);
                            //this.$alert(err.response.data, err.response.status + ' ' + err.response.statusText, { dangerouslyUseHTMLString: true });
                        })
                }).catch(() => {
                    this.$message({
                        showClose: true,
                        type: 'info',
                        message: 'Delete canceled'
                    });
                }); 
        },

        // onSearch: function () {
        //     this.grid.pageno = 1;
        //     this.refresh2();
        // },

        // currentChange: function (val) {
        //     this.grid.pageno = val;
        //     this.refresh2();
        // },

        // sortChange: function (sort) {
        //     this.grid.sortcol = sort.prop;
        //     this.grid.sortorder = sort.order;
        //     this.refresh2()
        // },

        // refresh: function () {
        //     if (this.grid.section) {
        //         this.$store.state.section = this.grid.section;
        //     }

        //     this.loading = true;
        //     this.$http.post('template/getgrid', this.grid)
        //         .then(response => {
        //             var json = JSON.parse(response.data);
        //             this.tabledata = json.data;
        //             this.grid = json.grid;
        //             this.loading = false;
        //         }).catch(err => {

        //             this.loading = false;
        //             this.$bus.$emit('error', err);
        //             //console.log(err);
        //             //this.$alert(err.response.data, err.response.status + ' ' + err.response.statusText, { dangerouslyUseHTMLString: true });

        //         });
        //     return; //below code to be removed
        //     this.$http.post('template/getall', {})
        //         .then(response => {
        //             this.items = JSON.parse(response.data);

        //         });
        // },
        appendTip: function (h, { column, $index }) {
            // Function(h, { column, $index })
            var content = ""
            if (column.property == "isrequired") {
                content = "Dim is Required (in Dim box)";
            }
            else if (column.property == "srno") {
                content = "Renumber rows to change order";
            }
            else if (column.property == "bqcodelibrary.specification") {
                content = "Specification Insert Level - within Library";
            }
            else {
                return column.label;
            }

            return h("span", [
                column.label,
                h(
                    "el-popover",
                    {
                        props: {
                            placement: "top",
                            // title: "hello",
                            // width: "200",
                            trigger: "hover",
                            content: content
                        }
                    },
                    [
                        h(
                            "i",
                            {
                                slot: "reference",
                                class: "el-icon-info",
                                style: "color:#606266;margin-left:2px;"
                            },
                            ""
                        )
                    ]
                )
            ]);
        },

        goback: function () {
            // var newgoback = ()=>{
                this.warningtofillWC(() => {
                var answer = true;
                if (this.detailform.changed) {
                    answer = window.confirm('Do you really want to leave? you have unsaved changes!');
                }
                if (answer == true) {
                    this.detail = false;
                    this.detailform.changed = false;
                    this.refresh2();
                }
                });
            // }            
            // this.canmatchcellid("libraries_mctemplateslibraryaddedit_bsckaddedit",newgoback);
        },

        unitchanged: function (row) {
            if (this.detailform.unit == row.unit) {
                row.isrequired = false;
                if (!row.qtydim || row.qtydim == 0) {
                    row.qtydim = 1;
                }
            } else {
                row.isrequired = true;
                if (row.qtydim == 1) {
                    row.qtydim = 0;
                }
            }
        },
       
        changed: function () {
            this.detailform.changed = true;

            if (this.detailform.unit) {
                var size = this.measureTextWidth(this.detailform.unit, 12);
                this.unitwidth = (size + 35) + "px";
            }
        },

        beforewindowunload: function (e) {
            //Prevent accidently close/reload window
            if (this.detailform.changed) {
                //return window.confirm('Do you really want to leave? you have unsaved changes!');
                return "Do you really want to leave ? you have unsaved changes!"
            }
        },

        warningtofillWC: function (success) {
            var msg = [];
            this.detailform.details.forEach((t, i) => {
                if (!t.category) {
                    msg.push("Check Work Classification entry for row " + (i + 1) + ". No empty cells allowed");
                }
            });
            if (msg.length > 0) {
                this.$alert(msg.join('<br />'), "! Warning", {
                    dangerouslyUseHTMLString: true,
                    customClass: 'custommessageboxstyle'
                }).then(success).catch(() => {/*Cancel clicked*/ });;
            } else {
                success();
            }
    },
        getallsection() {
            this.$http.get('project/GetSectionsProjectCreate')
                .then(response => {
                    this.sections = response.data;
                    this.$store.state.sections = this.sections;
                }).catch(err => {
                    this.$bus.$emit('error', err);
                });
        },
    },
   
    beforeRouteLeave(to, from, next) {
        var answer = true;
        if (this.detailform.changed) {
            answer = window.confirm('Do you really want to leave? you have unsaved changes!');
        }
        if (answer == true) {
            next()
            window.onbeforeunload = null;
        } else {
            next(false)
        }
    },
    created: function () {        
        if (this.$store.state.sections == undefined || this.$store.state.sections.length == 0) {
            this.getallsection();
        } else {
            this.sections = this.$store.state.sections;
        }
        if (this.$route.params.bqcodeusagetemplate != undefined) {
            this.view.filter[0].value = this.$route.params.bqcodeusagetemplate.library;
            this.view.filter[2].value = this.$route.params.bqcodeusagetemplate.templatename;
            this.additionalfilter = this.$route.params.bqcodeusagetemplate.isarchive;
            this.$route.params.bqcodeusagetemplate = undefined;
        }
        else {
            if (this.$store.state.project.library != null) {
                this.view.filter[0].value = this.$store.state.project.library;
            }
            if (this.$store.state.section != null) {
                this.view.filter[1].value = this.$store.state.section;
            }
        }
        let gridrow = this.$training.getusergridrow(this.$route);
        this.view.pagesize = gridrow.ugv.rowperpage;
        this.usergridrow = gridrow;
        
        this.refresh2();
        window.onbeforeunload = this.beforewindowunload;
    },

    destroyed() {
        window.removeEventListener('resize', this.getheight);
    },

    mounted: function () {
        window.addEventListener('resize', this.getheight);
    },

    components: {
        'projectbqcode': projectbqcode
    }
}
</script>

<style itemscope>
.input-number-width{
    width: 64px !important;
}
.red {
    color: red !important;
}

.el-table .cell {
    /*make more space for textboxes inside el-table*/
    padding-left: 3px !important;
    padding-right: 3px !important;
}

.el-input-number--mini {
    width: 100%;
}

.el-input-number .el-input__inner {
    text-align: right;
}

.el-switch.is-checked .el-switch__core::after {
    left: 100%;
    margin-left: -10px;
}
.el-switch__core:after {
    height: 10px;
    top: 0px;
    border-radius:15px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    width: 10px;
}
.el-switch__core {
    height: 12px;
    width:20px !important;
    border-radius: 15px;
    top: 0px;
    right: 2px;
    left: 0px;
    bottom: 0px;
}

.custommessageboxstyle {
    width: 460px;
    word-break: break-word;
}

.text {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.resize:hover{
    border-color: #C0C4CC  !important;
    border-style: solid  !important;
    resize: both;
    /* text-align: center !important; */
    overflow-y: auto;
}
.resize{
    resize: both;
    /* overflow: hidden; */
    display: block;
    border-style:none !important;
    padding: 5px 0;
    min-height: 28px;
    /* height: 28px; */
    height: 46px;
    overflow-y: auto;
}
.resize2{
    resize: both;
    /* overflow: hidden; */
    display: block;
    padding: 5px 0;
    min-height: 28px;
    /* height: 28px; */
    height: 46px;
    overflow-y: auto;
}
</style>
