<template>
    <div >
        <div class="phexportreportwidth">
         <div class="fixdheader">
        <el-row>
            <el-col :span="12" class="headerstyle"><span>Export Hours</span></el-col>
            <el-col :span="12" style="text-align:right; margin-top:5px;">
                  <el-button  type="success"
                    id="setup_exportreport_export_hours_report"
                    @click="cellchanged('setup_exportreport_export_hours_report',()=>{exportreport()},false) ">Export Hours Report</el-button>
                <el-button icon="el-icon-back"
                   type="info"
                    id="setup_exportreport_back"
                   @click="$router.go(-1)">Back</el-button>
            </el-col>
        </el-row>
        <el-row>
             <ehselectpart :EHSimilar="Similar"  @ehprojectpartchange="ehprojectpartchange">
          </ehselectpart>
        </el-row>
         <hr class="hrstyle" />
         </div>

        <el-row v-if="detail">
            <el-row>
                <div class="projectcenter">
                     <div class="namewidth"> {{projecthours.projectname}}</div>
                </div>
            </el-row>
            <el-row>
                <div class="projectcenter">
            <div class="namewidth">Library: {{projecthours.library}}</div>
                </div>
            </el-row>
             <el-row>
                <div class="projectcenter">
                    <div class="namewidth">Document Status:{{projecthours.documentstatus}}</div>
                </div>
            </el-row>
              <el-row>
                <div class="projectcenter">
                <div class="namewidth">Quote Staus:  {{projecthours.quotestatus}}</div>
                </div>
            </el-row>
              <el-row>
                <div class="projectcenter">
            <div class="namewidth">Version:  {{projecthours.version}}</div>
                </div>
            </el-row>
             <el-row>
                <div class="projectcenter">
          <div class="namewidth">Saved: {{getcreatedby}} {{getcreatedon}}</div>
                </div>
            </el-row>
             <div class="emptyfixedrow"/>
            <el-row>
                 <div class="projectrow">
                      <div class="projectcenter">
                       <div class="namewidth"> {{projecthours.projectname}}</div> <div class="durationwidth">  {{projecthours.duration}}</div>
                      </div>
                  </div>
            </el-row>

             <el-table
             class="row-expand-cover"
              :cell-style="tablecellClassName"
              :default-expand-all="true"
              :show-header="false"
              :data="projecthours.ehprojectparts"
              >
              <el-table-column width="5" type="expand">
                  <template slot-scope="pr">
                       <el-table
                       class="row-expand-cover"
                       :show-header="false"
                          :default-expand-all="true"
                        :cell-style="tablecellClassNamegroup"
                        :data="pr.row.projectGroups"
                       >
                       <el-table-column width="5" type="expand">
                           <template slot-scope="g">
                                <el-table
                                :show-header="false"
                                 :cell-style="tablecellClassNamesection"
                                 :data="g.row.projectSections"
                                >
                                      <el-table-column
                                        prop="section"
                                        label="section"
                                        width="390">
                                      </el-table-column>
                                       <el-table-column
                                        prop="calc"
                                        label="calc"
                                        width="70">
                                      </el-table-column>
                                </el-table>
                           </template>
                       </el-table-column>
                        <el-table-column
                          prop="groupname"
                          label="groupname"
                          width="385">
                        </el-table-column>
                        <el-table-column
                          prop="total"
                          label="total"
                          width="70">
                        </el-table-column>
                </el-table>
                  </template>
              </el-table-column>
              <el-table-column
                prop="name"
                label="name"
                width="385">
              </el-table-column>
              <el-table-column
                prop="duration"
                label="Duration"
                width="70">
              </el-table-column>
          </el-table>
        </el-row>
        </div>
    </div>
</template>
<script>
import moment from 'moment';
import ehselectpart from "./ehselectpart.vue";
export default {
    components: {
    ehselectpart: ehselectpart,
  },
 data() {
     return {
         detail:false,
         sections:[],
         projecthours:{},
       project:{},
       Similar:{
           lastsavedshow:true,
          projectpartshow:false,
       },


     }
 },
 computed:{
      getcreatedon(){
                 if (this.projecthours.createdon) {
                    var dt = new Date(this.projecthours.createdon);
                    return moment(dt).format("DD-MMM-YYYY HH:mm");
                    // return dt.toLocaleString().replace(/:\d{2}\s/, " ");
                 }else{
                    return "";
                 }
            },
     getcreatedby(){
                 if (this.projecthours.createdbyuser) {
                     if(this.projecthours.createdbyuser.loginname){
                    return this.projecthours.createdbyuser.loginname
                     }else{
                         return ""
                     }
                 }else{
                    return "";
                 }
            },
 },
 methods: {
    getsection(){
                // Get NRM section for each page
                if(false){
                    // return this.detailform.section;
                }else{
                    return "";
                }
            },
            cellchanged(cellaction,callback,cancall){
            if(cancall){
                callback();
                this.canmatchcellid(()=>{},cellaction,this.getsection());
            }else{
                this.canmatchcellid(callback,cellaction,this.getsection());
            }
        },
     exportreport:function(){
                  this.$http
                    .post("HoursEstimate/ehexportreport", this.project, { responseType: "blob" })
                    .then(response => {
                        var blob = response.data;
                         var link = document.createElement("a");
                        link.href = window.URL.createObjectURL(blob);
                        link.download = "Export_Report" + "_" + moment(new Date()).format("DD-MMM-YYYY HH:mm");;
                        link.click();
                    }).catch(err => {
                        this.$bus.$emit("error", err);

                    });
     },
     getreport:function(){
        //   var data = { id:this.project.id,version:this.$store.state.ERproject.version};
                  this.$http
                    .post("HoursEstimate/getehreport",  this.project)
                    .then(response => {
                        this.projecthours = response.data;
                            var ptotal = 0;
                        this.projecthours.ehprojectparts.forEach((pr)=>{
                            var gtotal = 0;
                            pr.projectGroups.forEach((g)=>{
                                     g.projectSections = this.issections(g.projectSections,g.groupname,g)
                                     gtotal += g.total;
                                })
                                pr.duration = gtotal;
                                ptotal += pr.duration
                        })
                        this.projecthours.duration = ptotal;
                    }).catch(err => {
                        this.$bus.$emit("error", err);

                    });
     },
      tablecellClassName: function ({ row, column, rowIndex, columnIndex }) {
                return {
                    background: "#606060 !important",
                    color: "white",
                    font: "bold",
                    "padding-top": "0px",
                    "padding-bottom": "0px",
                };
            },
             tablecellClassNamegroup: function ({ row, column, rowIndex, columnIndex }) {
                return {
                    background: "#efefef !important",
                    color: "black",
                    font: "bold",
                    "padding-top": "0px",
                    "padding-bottom": "0px",
                };
            },
            tablecellClassNamesection:function({ row, column, rowIndex, columnIndex }){
                 return {
                    font: "bold",
                    "padding-top": "0px",
                    "padding-bottom": "0px",
                };
            },
     ehprojectpartchange:function(data){
         this.projecthours = {};
         if(data.ehproject.projectname != "" && data.version != "" && data.version != null){
             this.project = data.ehproject;
             this.project.version = data.version;
             this.getreport();
             this.detail = true;
         }else{
             this.detail = false;
         }
     },
       issections(data,name,g){
                var name1 = "";
                if(name == "Substructure"){name1 = "substructure"}
                if(name == "External"){name1 = "external"}
                if(name == "Superstructure"){name1 = "superstructure"}

                  var arra = [];
                  var total = 0;
                  var s2 = JSON.parse(JSON.stringify(this.sections))
                s2[name1].forEach((s)=>{
                       var d =  data.find(t => t.sectionid == s.id);
                       if(d == null){

                           s.id = null;
                            s.p1bd = 0;
                            s.p1ad = 0;
                            s.p2bd = 0;
                            s.p2ad = 0;
                           arra.push(s);
                       }else{
                           d.isedit = true;
                            d.p1bd = 0;
                            d.p1ad = 0;
                            d.p2bd = 0;
                            d.p2ad = 0;
                            total += d.calc;
                          d.section = s.section;
                       }
                    });
                    this.$set(g,'total',total);
                     var d1 =  [...arra,...data]
                   var r1 =  d1.sort((a,b) => (a.section > b.section) ? 1 : ((b.section > a.section) ? -1 : 0))
                   return r1;

            },
       sectionpart(data) {
                var obj = { substructure: [], superstructure: [], external: [] };
                data.forEach((s) => {
                    s.userdetail = "";
                    s.algo = 0;
                    s.calc = 0;
                    s.ts1 = {};
                    s.ts2 = {};
                    s.comment = "";
                    s.sectionid = s.id;
                    s.projectgroupid = null;
                    var res = parseInt(s.section);
                    if (res == 1) {
                        obj.substructure.push(s);
                    }
                    if (res == 8 || res == 0) {
                        obj.external.push(s);
                    }
                    if (res != 1 && res != 8 && res != 0) {
                        obj.superstructure.push(s);
                    }
                });
                this.sections = obj;
            },
 },
 mounted() {
     if(this.$store.state.ERproject.projectid != null){
       const data = {projectid:this.$store.state.ERproject.projectid,
       version:this.$store.state.ERproject.version}
       this.$store.state.ERproject.projectid = null;
       this.$store.state.ERproject.version = "";
        this.$bus.$emit('addehprojectbyid',data);
     }
 },
 created() {
      if (this.$store.state.sections.length != 0) {
                this.sectionpart(this.$store.state.sections);
            }else{
                this.getsections().then((data)=>{
                     this.sectionpart(data);
                    });
            }
 },

}
</script>
<style >
    .emptyfixedrow{
        height: 25px;
    }
   .projectrow{
   width: 460px;
   height: 25px;
   font-size: 12px;
   background-color: #3f3f3f;
   color:white;
}
.namewidth{
    width: 390px;
    float: left;
}
.durationwidth{
   width: 60px;
   float: right;
}
.projectcenter{
    padding-top: 5px;
}
</style>

