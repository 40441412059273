<template>
    <div>
        <div>
            <el-row>
                <el-col><span class="headerstyle">Measurement Library Editor</span></el-col>
            </el-row>

            <el-row :style="{ height: (height + 30) + 'px' }">
                <el-col :span="12">
                    <el-form :model="form" label-position="left" label-width="100px" size="mini">
                        <el-row>
                            <el-form-item label="Library" prop="library" class="labelheight">
                                <el-col :span="19">
                                    <el-select class="libraryfixwidth" v-model="form.library" filterable
                                        placeholder="Library" @change="refresh" id="library_momlibrary_select_library">
                                        <el-option v-for="item in libraries" :key="item" :label="item" :value="item">
                                        </el-option>
                                    </el-select>

                                    <el-select class="libraryfixwidth marginl5" v-model="form.ishidden.value" filterable
                                        placeholder="usable" @change="changefilters($event)"
                                        id="library_momlibrary_select_usable">
                                        <el-option v-for="item in useoptions" :key="item.name" :label="item.name"
                                            :value="item.value">
                                        </el-option>
                                    </el-select>
                                </el-col>

                                <el-col class="marginl5 center" :span="1">
                                    SIL
                                    <el-popover placement="top" trigger="hover"
                                        content="Specification Insert Level - within Library">
                                        <i slot="reference" class="el-icon-info"></i>
                                    </el-popover>
                                </el-col>
                                <el-col class="marginl5" :span="2">Sort Code</el-col>
                                <el-col class="marginl5" :span="1">
                                    <i class="glyphicon glyphicon-eye-close fontsize18px"></i>
                                </el-col>
                            </el-form-item>

                            <el-form-item label="Section">
                                <el-row>
                                    <el-col :span="19">
                                        <el-input type="textarea"
                                            :class="'bqtextarea ' + (form.section.hidden ? 'hiddenlibrary' : '')"
                                            :spellcheck="isspellcheck" :autosize="{ minRows: 2, maxRows: 10 }"
                                            v-model="form.section.description" :disabled="!measurement[0].value"
                                            placeholder="Section" id="library_momlibrary_section">
                                        </el-input>
                                    </el-col>

                                    <el-col class="marginl5" :span="1"></el-col>
                                    <el-col class="marginl5" :span="2">
                                        <el-input :class="(form.section.hidden ? 'hiddenlibrary' : '')"
                                            v-model="form.section.code" :disabled="!measurement[0].value"
                                            :id="'library_momlibrary_sortcode_section'"></el-input>
                                    </el-col>
                                    <el-col class="marginl5" :span="1">
                                        <el-checkbox class="checkborderblue checkblue" v-model="form.section.hidden"
                                            :disabled="!measurement[0].value"
                                            :id="'library_momlibrary_checkbox_section'"></el-checkbox>
                                    </el-col>
                                </el-row>
                            </el-form-item>

                            <el-form-item label="Group">
                                <el-row>
                                    <el-col :span="19">
                                        <el-input type="textarea"
                                            :class="'bqtextarea ' + (form.group.hidden ? 'hiddenlibrary' : '')"
                                            :spellcheck="isspellcheck" :autosize="{ minRows: 2, maxRows: 10 }"
                                            v-model="form.group.description" :disabled="!measurement[1].value"
                                            placeholder="Group" :id="'library_momlibrary_group'">
                                        </el-input>
                                    </el-col>

                                    <el-col class="marginl5" :span="1"></el-col>
                                    <el-col class="marginl5" :span="2">
                                        <el-input :class="(form.group.hidden ? 'hiddenlibrary' : '')"
                                            v-model="form.group.code" :disabled="!measurement[1].value"
                                            :id="'library_momlibrary_sortcode_group'"></el-input>
                                    </el-col>
                                    <el-col class="marginl5" :span="1">
                                        <el-checkbox class="checkborderblue checkblue" v-model="form.group.hidden"
                                            :disabled="!measurement[1].value"
                                            :id="'library_momlibrary_checkbox_group'"></el-checkbox>
                                    </el-col>
                                </el-row>
                            </el-form-item>

                            <el-form-item label="Item or Work">
                                <el-row>
                                    <el-col :span="19">
                                        <el-input type="textarea"
                                            :class="'bqtextarea ' + (form.work.hidden ? 'hiddenlibrary' : '')"
                                            :spellcheck="isspellcheck" :autosize="{ minRows: 2, maxRows: 10 }"
                                            v-model="form.work.description" :disabled="!measurement[2].value"
                                            placeholder="Item or Work" :id="'library_momlibrary_itemorwork'">
                                        </el-input>
                                    </el-col>

                                    <el-col class="marginl5 padleft3px" :span="1">
                                        <el-radio v-model="form.specification" :label="0">&nbsp;</el-radio>
                                    </el-col>
                                    <el-col class="marginl5" :span="2">
                                        <el-input :class="(form.work.hidden ? 'hiddenlibrary' : '')"
                                            v-model="form.work.code" :disabled="!measurement[2].value"
                                            :id="'library_momlibrary_sortcode_itemorwork'"></el-input>
                                    </el-col>
                                    <el-col class="marginl5" :span="1">
                                        <el-checkbox class="checkborderblue checkblue" v-model="form.work.hidden"
                                            :disabled="!measurement[2].value"
                                            :id="'library_momlibrary_checkbox_itemorwork'"></el-checkbox>
                                    </el-col>
                                </el-row>
                            </el-form-item>

                            <el-form-item label="Level 1">
                                <el-row>
                                    <el-col :span="19">
                                        <el-input type="textarea"
                                            :class="'bqtextarea ' + (form.level1.hidden ? 'hiddenlibrary' : '')"
                                            :spellcheck="isspellcheck" :autosize="{ minRows: 2, maxRows: 10 }"
                                            v-model="form.level1.description" :disabled="!measurement[3].value"
                                            placeholder="Level 1" :id="'library_momlibrary_level1'">
                                        </el-input>
                                    </el-col>

                                    <el-col class="marginl5 padleft3px" :span="1">
                                        <el-radio v-model="form.specification" :label="1">&nbsp;</el-radio>
                                    </el-col>
                                    <el-col class="marginl5" :span="2">
                                        <el-input :class="(form.level1.hidden ? 'hiddenlibrary' : '')"
                                            v-model="form.level1.code" :disabled="!measurement[3].value"
                                            :id="'library_momlibrary_sortcode_level1'"></el-input>
                                    </el-col>
                                    <el-col class="marginl5" :span="1">
                                        <el-checkbox class="checkborderblue checkblue" v-model="form.level1.hidden"
                                            :disabled="!measurement[3].value"
                                            :id="'library_momlibrary_checkbox_level1'"></el-checkbox>
                                    </el-col>
                                </el-row>
                            </el-form-item>

                            <el-form-item label="Level 2">
                                <el-row>
                                    <el-col :span="19">
                                        <el-input type="textarea"
                                            :class="'bqtextarea ' + (form.level2.hidden ? 'hiddenlibrary' : '')"
                                            :spellcheck="isspellcheck" :autosize="{ minRows: 2, maxRows: 10 }"
                                            v-model="form.level2.description" :disabled="!measurement[4].value"
                                            placeholder="Level 2" :id="'library_momlibrary_level2'">
                                        </el-input>
                                    </el-col>

                                    <el-col class="marginl5 padleft3px" :span="1">
                                        <el-radio v-model="form.specification" :label="2">&nbsp;</el-radio>
                                    </el-col>
                                    <el-col class="marginl5" :span="2">
                                        <el-input :class="(form.level2.hidden ? 'hiddenlibrary' : '')"
                                            v-model="form.level2.code" :disabled="!measurement[4].value"
                                            :id="'library_momlibrary_sortcode_level2'"></el-input>
                                    </el-col>
                                    <el-col class="marginl5" :span="1">
                                        <el-checkbox class="checkborderblue checkblue" v-model="form.level2.hidden"
                                            :disabled="!measurement[4].value"
                                            :id="'library_momlibrary_checkbox_level2'"></el-checkbox>
                                    </el-col>
                                </el-row>
                            </el-form-item>

                            <el-form-item label="Level 3">
                                <el-row>
                                    <el-col :span="19">
                                        <el-input type="textarea"
                                            :class="'bqtextarea ' + (form.level3.hidden ? 'hiddenlibrary' : '')"
                                            :spellcheck="isspellcheck" :autosize="{ minRows: 2, maxRows: 10 }"
                                            v-model="form.level3.description" :disabled="!measurement[5].value"
                                            placeholder="Level 3" :id="'library_momlibrary_level3'">
                                        </el-input>
                                    </el-col>

                                    <el-col class="marginl5 padleft3px" :span="1">
                                        <el-radio v-model="form.specification" :label="3">&nbsp;</el-radio>
                                    </el-col>
                                    <el-col class="marginl5" :span="2">
                                        <el-input :class="(form.level3.hidden ? 'hiddenlibrary' : '')"
                                            v-model="form.level3.code" :disabled="!measurement[5].value"
                                            :id="'library_momlibrary_sortcode_level3'"></el-input>
                                    </el-col>
                                    <el-col class="marginl5" :span="1">
                                        <el-checkbox class="checkborderblue checkblue" v-model="form.level3.hidden"
                                            :disabled="!measurement[5].value"
                                            :id="'library_momlibrary_checkbox_level3'"></el-checkbox>
                                    </el-col>
                                </el-row>
                            </el-form-item>

                            <el-form-item label="Unit"
                                v-if="!(measurement[3].value || measurement[4].value || measurement[5].value)">
                                <el-row>
                                    <el-col :span="10">
                                        <el-select :disabled="measurement[2].value != true" v-model="form.work.unit"
                                            class="libraryfixwidth" placeholder="Unit"
                                            :id="'library_momlibrary_select_unit'">
                                            <el-option v-for="item in units" :key="item" :label="item" :value="item">
                                            </el-option>
                                        </el-select>
                                    </el-col>
                                </el-row>
                            </el-form-item>

                            <el-form-item label="">
                                <el-row>
                                    <el-col>
                                        <div class="paddingright15px floatright">
                                            <el-button type="success" icon="el-icon-circle-check"
                                                :disabled="!editpermission"
                                                @click="cellchanged('library_momlibrary_edit_change', () => { savechanges() }, false)">Change</el-button>
                                        </div>
                                    </el-col>
                                </el-row>
                            </el-form-item>
                        </el-row>
                    </el-form>
                </el-col>

                <el-col :span="12" :style="{ height: (height + 29) + 'px' }" class="verticalline">
                    <div>
                        <span>
                            <el-button type="primary" class="padding7 floatright" @click="levelupclick()"
                                :icon="currentlevel.level == 1 ? 'glyphicon glyphicon-arrow-down' : 'glyphicon glyphicon-arrow-up'">
                                {{ currentlevel.level == 1 ? 'One Level Down' : 'One Level Up' }}
                            </el-button>
                        </span>
                        <span>
                            <div><b>Measurement Library</b></div>
                            <div>Current Level : {{ currentlevel.name }}</div>
                        </span>
                    </div>

                    <div
                        :class="'tablepaddinglr3 removetableborder tabletexttop sortingbotton ' + (form.ishidden.value ? 'hiddenlibrary' : '')">
                        <el-table :data="tabledata" :height="height - 15" :header-cell-style="tableheaderpadding"
                            v-loading="loadinglibrary" @row-dblclick="librarydblclick" :border="false" :stripe="true">
                            <el-table-column prop="code" label="Code" align="left" sortable
                                width="40"></el-table-column>
                            <el-table-column prop="unit" label="Unit" align="left" sortable
                                width="40"></el-table-column>
                            <el-table-column prop="description" label="Description(Click to drill down)"
                                sortable></el-table-column>
                            <el-table-column label="Actions" header-align="center">
                                <el-table-column align="center" header-align="center" width="102">
                                    <template slot="header">
                                        <el-button type="primary" icon="el-icon-circle-plus" class="padding7"
                                            :disabled="!editpermission" @click="addnew">Create New</el-button>
                                    </template>
                                    <template slot-scope="scope">
                                        <span v-if="form.ishidden.value">
                                            <el-button type="danger" icon="el-icon-delete" class="padding7"
                                                @click="deleterow(scope.row)" :disabled="!editpermission"></el-button>
                                        </span>
                                        <span class="marginl5">
                                            <el-button type="primary" class="paddingforcopybutton"
                                                :icon="form.ishidden.value ? 'glyphicon glyphicon-eye-open' : 'glyphicon glyphicon-eye-close'"
                                                @click="archiverow(scope.row, form.ishidden.value)"
                                                :disabled="!editpermission"></el-button>
                                        </span>
                                        <span class="marginl5">
                                            <el-button type="primary" icon="glyphicon glyphicon-duplicate"
                                                class="paddingforcopybutton" @click="copyrow(scope.row)"
                                                :disabled="!editpermission"></el-button>
                                        </span>
                                    </template>
                                </el-table-column>
                            </el-table-column>
                        </el-table>
                    </div>
                </el-col>
            </el-row>

            <div>
                <el-dialog :title="`Current Level: ${currentlevel.name}`" :visible.sync="dialogforaddnew"
                    :before-close="mediadialogClose" :close-on-click-modal="false" class="dialogboxpadding" width="40%">
                    <div class="marginb5">
                        <el-input type="textarea" class="bqtextarea" :spellcheck="isspellcheck"
                            :autosize="{ minRows: 2, maxRows: 10 }" v-model="detailform.description"
                            placeholder="Description">
                        </el-input>
                    </div>

                    <div>
                        <span>
                            <el-input class="libraryfixwidth" placeholder="Sort Code"
                                v-model="detailform.code"></el-input>
                        </span>
                        <span>
                            <el-select class="marginl5 libraryfixwidth" placeholder="Unit" v-model="detailform.unit">
                                <el-option v-for="i in units" :key="i" :label="i" :value="i">
                                </el-option>
                            </el-select>
                        </span>
                    </div>

                    <div slot="footer" class="dialog-footer">
                        <el-button @click="dialogforaddnew = false">Cancel</el-button>
                        <el-button type="success" icon="el-icon-circle-check" :disabled="!editpermission"
                            @click="savenewlibrary" v-loading.fullscreen.lock="saving">Save</el-button>
                    </div>
                </el-dialog>
            </div>

            <div>
                <el-dialog title="Tip" :visible.sync="dialogforcopyrow" :before-close="mediadialogClose"
                    :close-on-click-modal="false" class="dialogboxpadding" width="40%">
                    <div class="flexbox">
                        <span class="width-37px marginright5px">
                            <el-input placeholder="Code" v-model="detailform.code"></el-input>
                        </span>
                        <span class="fullwidth marginright4px">
                            <el-input placeholder="Description" v-model="detailform.description"></el-input>
                        </span>
                    </div>

                    <div slot="footer" class="dialog-footer">
                        <el-button @click="dialogforcopyrow = false">Cancel</el-button>
                        <el-button type="primary" @click="confirmcopyrow">OK</el-button>
                    </div>
                </el-dialog>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            height: (document.documentElement.clientHeight - 130),
            tabledata: [],
            loadinglibrary: false,
            currentlevel: {
                level: 1,
                name: 'Section',
            },
            units: this.$store.state.units,
            libraries: ['SMM7', 'NRM2'],
            useoptions: [{ name: 'Usable', value: false }, { name: 'Hidden', value: true }],
            groupid: { 1: 0 },
            measurement: [
                { name: "section", value: false },
                { name: "group", value: false },
                { name: "work", value: false },
                { name: "level1", value: false },
                { name: "level2", value: false },
                { name: "level3", value: false }
            ],
            form: {
                library: 'SMM7',
                ishidden: { name: 'Usable', value: false },
                section: {},
                group: {},
                work: {},
                level1: {},
                level2: {},
                level3: {}
            },
            item: {
                id: 0,
                description: '',
                groupid: null,
                nextgroupid: '',
                unit: '',
                code: '',
                updated: false,
                hidden: false,
                parentid: null
            },
            dialogforaddnew: false,
            detailform: {},
            saving: false,
            dialogforcopyrow: false,
        }
    },
    methods: {
        getheight() {
            this.height = (document.documentElement.clientHeight - 130);
        },

        tableheaderpadding({ row, column, rowIndex, columnIndex }) {
            if (rowIndex === 0) {
                return 'padding:0px;';
            }
        },

        createform: function () {
            this.form.section = { ...this.item };
            this.form.group = { ...this.item };
            this.form.work = { ...this.item };
            this.form.level1 = { ...this.item };
            this.form.level2 = { ...this.item };
            this.form.level3 = { ...this.item };
            this.measurement = this.measurement.map(i => {
                i.value = false;
                return i;
            });
            this.currentlevel = { level: 1, name: 'Section' };
        },

        changefilters: function (event) {
            this.form.ishidden.name = event === true ? 'Hidden' : 'Usable';
            this.tabledata = [];
            this.loadlibrary();
        },

        refresh: function () {
            this.tabledata = [];
            this.createform();
            this.loadlibrary();
        },

        loadlibrary: function () {
            this.loadinglibrary = true;
            if (this.currentlevel.level == 1) {
                this.groupid[this.currentlevel.level] = 0;
            }
            let url = "library/" + this.form.library + "/" + this.form.ishidden.value + "/" + this.groupid[this.currentlevel.level];
            this.$http.get(url, {})
                .then(response => {
                    this.tabledata = JSON.parse(response.data);
                    this.loadinglibrary = false;
                })
                .catch(err => {
                    this.loadinglibrary = false;
                    this.$bus.$emit('error', err);
                });
        },

        levelupclick: function () {
            if (this.currentlevel.level > 1) {
                this.currentlevel.level -= 1;
                switch (this.currentlevel.level) {
                    case 1:
                        this.currentlevel.name = 'Section';
                        break;
                    case 2:
                        this.currentlevel.name = 'Group';
                        break;
                    case 3:
                        this.currentlevel.name = 'Item or Work';
                        break;
                    case 4:
                        this.currentlevel.name = 'Level 1';
                        break;
                    case 5:
                        this.currentlevel.name = 'Level 2';
                        break;
                }
                this.measurement.forEach((i, index) => {
                    let plusindex = index + 1;
                    let newindex = this.currentlevel.level - 1;
                    if (newindex > 0) {
                        i.value = plusindex === newindex ? true : false;
                    }

                    if (this.currentlevel.level > newindex) {
                        this.form[this.measurement[newindex].name] = { ...this.item };
                    }
                });
            }
            else {
                this.currentlevel = { level: 1, name: 'Section' };
                this.measurement.forEach((i) => {
                    if (i.name == 'section' && this.form.section.id > 0) {
                        this.currentlevel = { level: 2, name: 'Group' };
                        i.value = true;
                    }
                    else {
                        i.value = false;
                    }
                });
            }
            this.loadlibrary();
        },

        librarydblclick: function (row) {
            switch (this.currentlevel.level) {
                case 1:
                    this.form.section = row;
                    this.currentlevel.name = 'Group';
                    this.form.section.parentid = null;
                    break;
                case 2:
                    this.form.group = row;
                    this.currentlevel.name = 'Item or Work';
                    this.form.group.parentid = this.form.section.id;
                    break;
                case 3:
                    this.form.work = row;
                    this.currentlevel.name = 'Level 1';
                    this.form.work.parentid = this.form.group.id;
                    break;
                case 4:
                    this.form.level1 = row;
                    this.currentlevel.name = 'Level 2';
                    this.form.level1.parentid = this.form.work.id;
                    break;
                case 5:
                    this.form.level2 = row;
                    this.currentlevel.name = 'Level 3';
                    this.form.level2.parentid = this.form.level1.id;
                    break;
                case 6:
                    this.form.level3 = row;
                    this.form.level3.parentid = this.form.level2.id;
                    break;
            }
            if (this.currentlevel.level <= 6 && this.currentlevel.level >= 1) {
                this.measurement = this.measurement.map((i, index) => {
                    let c = this.currentlevel.level - 1;
                    i.value = index === c ? true : false;
                    return i;
                });
                this.currentlevel.level += 1;
                this.groupid[this.currentlevel.level] = row.nextgroupid;
            }
            this.loadlibrary();
        },

        copyrow: function (row) {
            this.dialogforcopyrow = true;
            this.detailform = JSON.parse(JSON.stringify(row));
            this.detailform.description = `${this.detailform.description} - Copy`;
            this.detailform.id = 0;
            let val = '';
            if (this.tabledata.length > 0) {
                let lastElement = this.tabledata[this.tabledata.length - 1];
                val = lastElement.code.replace(/[^a-zA-Z0-9]/g, '');
            }
            this.detailform.code = this.getNextExcelColumnName(val);
            // const validDescriptions = this.tabledata.map(item => item.code ? item.code.replace(/[^a-zA-Z0-9]/g, '') : "").filter(c => c !== null && c !== "");
            // const highestA = validDescriptions
            //     .filter(str => str.startsWith('A'))
            //     .reduce((a, b) => a.localeCompare(b) > 0 ? a : b);
            // this.detailform.code = this.getNextExcelColumnName(highestA);
        },

        confirmcopyrow() {
            let data = {
                momlibrary: this.detailform,
                library: this.form.library
            };
            this.showloading();
            this.$http.post('library/copylibrary', data)
                .then((response) => {
                    this.dialogforcopyrow = false;
                    this.hideloading();
                    this.$message({
                        showClose: true,
                        message: response.data.message,
                        type: 'success'
                    });
                    this.changefilters(this.form.ishidden.value);
                })
                .catch((err) => {
                    this.hideloading();
                    this.$bus.$emit('error', err);
                });
        },

        addnew() {
            this.detailform = {
                description: '',
                unit: '',
                code: '',
                updated: false,
                hidden: false,
            };
            this.detailform.groupid = this.groupid[this.currentlevel.level];
            let val = '';
            if (this.tabledata.length > 0) {
                let lastElement = this.tabledata[this.tabledata.length - 1];
                val = lastElement.code.replace(/[^a-zA-Z0-9]/g, '');
            }
            this.detailform.code = this.getNextExcelColumnName(val);
            this.dialogforaddnew = true;
        },

        getNextExcelColumnName(val) {
            let result = '';

            if (!(val == null || val == '')) {
                let carry = 1;
                for (let i = val.length - 1; i >= 0; i--) {
                    let charCode = val.charCodeAt(i) - 65 + carry;
                    if (charCode === 26) {
                        result = 'A' + result;
                        carry = 1;
                    } else {
                        result = String.fromCharCode(65 + charCode) + result;
                        carry = 0;
                    }
                }

                if (carry === 1) {
                    result = 'A' + result;
                }
            }

            return result;
        },

        mediadialogClose(done) {
            this.detailform = {};
            this.dialogforaddnew = false;
            this.dialogforcopyrow = false;
            done();
        },

        archiverow(row, event) {
            let data = {
                momlibrary: row,
                library: this.form.library
            };

            data.momlibrary.hidden = !event;
            this.showloading();
            this.$http.post('library/archivelibrary', data)
                .then((response) => {
                    this.hideloading();
                    this.$message({
                        showClose: true,
                        message: response.data.message,
                        type: 'success'
                    });
                    this.changefilters(this.form.ishidden.value);
                })
                .catch((err) => {
                    this.hideloading();
                    this.$bus.$emit('error', err);
                });
        },

        deleterow(row) {
            this.$confirm('Are you sure you want to Delete?', 'Warning', {
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancel',
                type: 'warning'
            }).then(() => {
                let data = {
                    momlibrary: row,
                    library: this.form.library
                };
                this.showloading();
                this.$http.post("library/deletelibrary", data)
                    .then(response => {
                        this.hideloading();
                        this.$message({
                            showClose: true,
                            message: response.data.message,
                            type: "success"
                        });
                        this.refresh();
                    })
                    .catch(err => {
                        this.hideloading();
                        this.$bus.$emit('error', err);
                    });
            }).catch(() => {
                this.$message({
                    showClose: true,
                    type: 'info',
                    message: 'Delete canceled'
                });
            });
        },

        cellchanged(cellaction, callback, cancall) {
            const app = this;
            this.$directionutility.setcomponent(app);
            if (cancall) {
                callback();
                this.canmatchcellid(() => { }, cellaction, "");
            } else {
                this.canmatchcellid(callback, cellaction, "");
            }
        },

        savechanges: function () {
            let currentrow = this.measurement.find(x => x.value === true);
            if (currentrow != undefined) {
                let data = {
                    momlibrary: this.form[currentrow.name],
                    parentid: this.form[currentrow.name].parentid,
                    library: this.form.library
                };
                this.showloading();
                this.$http.post('library/savelibrary', data)
                    .then((response) => {
                        this.hideloading();
                        this.$message({
                            showClose: true,
                            message: response.data.message,
                            type: 'success'
                        });
                    })
                    .catch((err) => {
                        this.hideloading();
                        this.$bus.$emit("error", err);
                    });
            }
        },

        myvalidate(callback) {
            var msg = [];
            if (this.detailform.description.trim() == "") {
                msg.push("Description is required.");
            }
            if (msg.length > 0) {
                var style = "<style> .el-message-box {width:50%;} </style>";
                this.$alert(msg.join("<br />") + style, "Invalid Data", {
                    dangerouslyUseHTMLString: true,
                });
            } else {
                callback();
            }
        },

        savenewlibrary() {
            this.myvalidate(() => {
                this.saving = true;
                let data = {
                    momlibrary: this.detailform,
                    library: this.form.library
                };
                if (this.saving) {
                    this.showloading();
                    this.$http.post('library/savenewlibrary', data)
                        .then((response) => {
                            this.hideloading();
                            this.dialogforaddnew = false;
                            this.saving = false;
                            this.$message({
                                type: "success",
                                showClose: true,
                                message: response.data.message
                            });
                            this.loadlibrary();
                        })
                        .catch((err) => {
                            this.hideloading();
                            this.saving = false;
                            this.$bus.$emit('error', err);
                        });
                }
                else {
                    this.saving = false;
                    this.$message({
                        type: "info",
                        showClose: true,
                        message: "Nothing to save"
                    });
                }
            });
        },
    },

    destroyed() {
        window.removeEventListener('resize', this.getheight);
    },

    mounted: function () {
        window.addEventListener('resize', this.getheight);
    },

    created() {
        this.refresh();
    }
}
</script>