<template>
    <div>
        <div>
            <div :class="!editversion ? 'fixdheader mctversionwidth' : 'fixdheader criteriawidth'">
                <div class="flexbox justifycontentsb">
                    <span class="headerstyle">{{ !editversion ? "MC Versions" : "Add/Edit MC Versions" }}</span>                   

                    <div class="floatright margintb1mm">
                        <el-button type="primary" @click="addversion" v-if="!editversion"
                            id="setup_criteria_add_psp_criteria">
                            Add Version
                        </el-button>

                        <el-button type="success" @click="save" v-if="editversion" id="setup_criteria_add_psp_criteria">
                            Save
                        </el-button>

                        <el-button icon="el-icon-back" type="info" id="setup_criteria_back"
                            @click="!editversion ? $router.go(-1) : getmcversions()">Back</el-button>
                    </div>
                </div>
                <div>
                    <span v-if="!editversion">
                        <el-pagination layout="sizes, prev, pager, next" class="pagesizeswidth textalignlpage margintb1mm" @size-change="rowperpages"
                            :total="view.count" @current-change="currentchange" :page-sizes="pagesizes"
                            :current-page.sync="view.pageno" :page-size="view.pagesize">
                        </el-pagination>
                    </span>
                </div>
                <hr class="hrstyle3" />
            </div>           

            <div class="mctversionwidth">
                <el-table v-if="!editversion" :data="versions" :height="height" :default-sort="{ prop: 'version', order: 'descending' }" stripe @sort-change="sortchange">
                    <el-table-column prop="date" label="MC Update Date" width="180">
                        <template slot-scope="scope">
                            {{ dateformat(scope.row.date) }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="version" sortable="custom" label="Version" width="175">
                    </el-table-column>
                    <el-table-column prop="version" align="center" label="Action" width="85">
                        <template slot-scope="scope">
                            <el-button size="mini" class="padding7" type="primary" title="Edit"
                                :icon="editpermission == true ? 'el-icon-edit' : 'el-icon-view'"
                                @click="editversionbyid(scope.row)"> </el-button>

                                <el-button size="mini" class="padding7" type="danger" title="Delete"
                                icon="el-icon-delete"
                                @click="deletemcupdate(scope.row)"> </el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <div v-if="editversion">
                    <el-row>
                        <div>
                            <div style="width: 100px;" class="left">
                                <span class="el-input el-input--mini bold" style="vertical-align: bottom;">
                                    Version:
                                </span>
                            </div>
                            <div style="width: 50px;" class="left">
                                <el-input placeholder="Version" v-model="form.version" @change="$training.formatversion(form,'version')"></el-input>
                            </div>
                        </div>
                    </el-row>
                    <div class="spaceBetween" />
                    <el-row>
                        <div>
                            <div style="width: 100px;" class="left">
                                <span class="el-input el-input--mini bold" style="vertical-align: bottom;">
                                    MC Update Date:
                                </span>
                            </div>
                            <div style="width: 200px;" class="left">
                                <el-date-picker v-model="form.date" type="date" class="custom-date-picker-psp left"
                                    placeholder="MC Update date" default-value="2010-10-01">
                                </el-date-picker>
                            </div>
                        </div>
                    </el-row>
                    <el-row>
                        <el-row>
                            <el-col :span="4" class="el-input el-input--mini bold">Notes:</el-col>
                        </el-row>
                        <editor :metadata="form"></editor>
                    </el-row>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Editor from "./Editor.vue";
import moment from "moment";
export default {
    data() {
        return {
            form: {},
            versions: [],
            editversion: false,
            view: {
                pagesize: 20,
                filter: [
                    { table: 'mcu', column: 'version', value: "" }
                ],
                sortcol: "mcu.version",
                sortorder: "descending",
                defaultfilter: ""
            },
            pagesizes: this.$store.state.pagesizes,
            height: (document.documentElement.clientHeight - 135),
        }
    },

    methods: {
        getheight() {
            this.height = (document.documentElement.clientHeight - 135);
        },
        validate(callback) {
            var msg = [];
            if (this.form.content == "<p></p>") {
                this.form.content = "";
            }
            if (this.form.version == "") {
                msg.push("Version must not be empty");
            }
            if (this.form.date == null) {
                msg.push("MC Update Date must not be empty");
            }
            if (msg.length > 0) {
                var style = "<style> .el-message-box {width:50%;} </style>";
                this.$alert(msg.join("<br />") + style, "Invalid Data", {
                    dangerouslyUseHTMLString: true,
                });
            } else {
                callback();
            }
        },
        deletemcupdate(row){
            
            this.$confirm('This will permanently delete ' + row.version + ' version. Continue?', 'Warning', {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                }).then(() => {
                    const app = this;
                     var obj = { id: row.id };  
                     app.$http.post("Account/deletemcversion", obj)
                             .then(response => {
                                 app.getmcversions();
                                 app.$message({
                                     showClose: true,
                                     message: response.data, type: "success"
                                 });
                             })
                             .catch(err => {
                                 app.$bus.$emit('error', err);
                             });
                            }).catch(() => {
                    this.$message({
                        showClose: true,
                        type: 'info',
                        message: 'Delete canceled'
                    });
                });
        },
        save() {
            const app = this;
            var savemc = function () {
                app.$http.post("Account/savemcversion", app.form)
                    .then(response => {
                        if (app.form.id != null) {
                            app.editversionbyid(app.form);
                        } else {
                            app.getmcversions();
                        }
                        app.$message({
                            showClose: true,
                            message: response.data, type: "success"
                        });
                    })
                    .catch(err => {
                        app.$bus.$emit('error', err);
                    });
            }
            this.validate(savemc);

        },
        editversionbyid(row) {
            var obj = { id: row.id };
            this.$http.post("Account/getmcversionbyid", obj)
                .then(response => {
                    this.form = {};
                    this.form = response.data;
                    this.editversion = true;
                })
                .catch(err => {
                    this.hideloading();
                    this.$bus.$emit('error', err);
                });
        },
        addversion() {
            this.form = { version: "", date: new Date, content: "" };
            this.editversion = true;
        },
        currentchange(val) {
            this.view.pageno = val;
            this.getmcversions();
        },
        sortchange(sort) {
            this.view.sortcol = "mcu." + sort.prop;
            this.view.sortorder = sort.order;
            this.getmcversions();
        },
        rowperpages: async function (val) {
            try {
                this.usergridrow.ugv.rowperpage = val;
                this.usergridrow = await this.$training.savepagelayout(this.usergridrow);
                this.view.pageno = 1;
                this.view.pagesize = val;
                this.getmcversions();
            } catch (error) {
                this.$bus.$emit('error', error);
            }
        },
        getmcversions() {
            this.showloading();
            this.$http.post("Account/getmcversions", this.view)
                .then(response => {
                    this.editversion = false;
                    this.versions = response.data.data;
                    this.view = response.data.view;
                    this.hideloading();
                })
                .catch(err => {
                    this.hideloading();
                    this.$bus.$emit('error', err);
                });
        }
    },
    components: {
        Editor
    },
    computed: {
        dateformat: function () {
            return function (date) {
                if (date) {
                    return moment(new Date(date)).format("DD-MMM-YYYY");
                }
            }
        }
    },

    created: function () {
        let gridrow = this.$training.getusergridrow(this.$route);
        this.view.pagesize = gridrow.ugv.rowperpage;
        this.usergridrow = gridrow;
        this.getmcversions();
    },

    destroyed() {
        window.removeEventListener('resize', this.getheight);
    },

    mounted: function () {
        window.addEventListener('resize', this.getheight);
    },
}
</script>
