<template>
    <div >
        <el-form @submit.native.prevent>

            <el-row>
                <el-col :span="12" class="headerstyle"> <span>Change Password</span> </el-col>
            </el-row>

            <el-form-item label="Password" class="marginbwidth">
                <el-input type="password" v-model="user.password" placeholder="Password"></el-input>
            </el-form-item>
            <el-form-item label="Confirm Password" class="marginbwidth">
                <el-input type="password" v-model="user.confirmpassword" placeholder="Confirm Password"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="success" @click="save" icon="el-icon-circle-plus">Save</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                user: {
                    password: "",
                    confirmpassword: ""
                },
            }
        },
        methods: {
            save: function () {
                if (this.isvalid()) {
                    this.$http.post("account/updatepassword", this.user)
                        .then(response => {
                            this.$message({
                                showClose: true,
                                type: 'success',
                                message: response.data
                            });
                        })
                        .catch(error => {
                            this.$bus.$emit('logout', null);
                        })
                }
            },
            isvalid: function () {
                var msg = [];
                if (!this.user.password)
                    msg.push("Password must not be empty");
                if (!(this.user.password == this.user.confirmpassword))
                    msg.push("Password does not match with confirm password");
                if (msg.length > 0) {
                    this.$alert(msg.join("</br>"), "Validation Error", { dangerouslyUseHTMLString: true });
                    return false;
                }
                return true;
            }

        }
    }
</script>

<style>
</style>
