<template>
    <div>
        <div class="UniversalReportwidth_header">
            <div class="fixdheader">
                <span class="headerstyle">QA Report</span>
                <div class="floatright margintb1mm">
                    <span v-if="page == 3">
                        <el-button type="success" icon="el-icon-document" class="marginleft4px padding7"
                            @click="exportreport">Export</el-button>
                    </span>
                    <span>
                        <el-button type="info" icon="el-icon-back" class="marginleft4px padding7"
                            @click="back">Back</el-button>
                    </span>
                </div>

                <div v-if="page == 1">
                    <span>
                        <el-pagination layout="sizes, prev, pager, next" class="pagesizeswidth pagepaddzero"
                            @size-change="rowperpages" :total="view.count" @current-change="currentchange"
                            :page-sizes="pagesizes" :current-page.sync="view.pageno" :page-size="view.pagesize">
                        </el-pagination>
                    </span>
                </div>

                <div v-if="page == 1" class="flexbox justifycontentsb margintb5">
                    <span>
                        <el-input :spellcheck="isspellcheck" v-model="view.filter[0].value" placeholder="Report Name"
                            @keyup.enter.native="filtersearch()" class="bold width260px">
                            <el-button slot="append" icon="el-icon-search" circle @click="refresh"></el-button>
                        </el-input>
                    </span>
                    <span>
                        <el-button type="primary" icon="el-icon-circle-plus" class="padding7"
                            :disabled="!editpermission" @click="createnew">Create New</el-button>
                    </span>
                </div>

                <div v-if="page != 1">
                    <hr class="hrstyle" />
                    <el-button type="primary" class="padding7" :disabled="!editpermission" @click="editrow(form)">View
                        Criteria
                    </el-button>

                    <el-date-picker v-model="startdate" placeholder="Start Date" align="center"
                        class="custom-date-picker width110 marginleft4px" type="date" value-format="yyyy-MM-dd"
                        format="dd-MMM-yyyy">
                    </el-date-picker>

                    <el-date-picker v-model="enddate" placeholder="End Date" align="center"
                        class="custom-date-picker width110 marginleft4px" type="date" value-format="yyyy-MM-dd"
                        format="dd-MMM-yyyy">
                    </el-date-picker>

                    <el-button type="primary" class="right padding7" @click="runreport(form)">Run Report</el-button>
                </div>
                <hr class="margintb6 borderblack" />
            </div>
        </div>

        <div v-if="page == 1" class="UniversalReportwidth_header">
            <el-table :data="tabledata" :border="true" :height="height" :stripe="true"
                :default-sort="{ prop: 'reportname', order: 'ascending' }" @sort-change="sortchange">
                <el-table-column label="Report Name" prop="reportname" sortable="custom"
                    class-name="verticalaligntop padleft5px" width="347">
                </el-table-column>

                <el-table-column label="Actions" header-align="center" class-name="verticalaligntop padleft5px"
                    width="105">
                    <template slot-scope="scope">
                        <el-button type="primary" icon="el-icon-edit" class="padding7" @click="editrow(scope.row)"
                            :disabled="!editpermission"></el-button>

                        <el-button type="primary" icon="el-icon-view" class="padding7"
                            @click="runreport(scope.row)"></el-button>

                        <el-button type="danger" icon="el-icon-delete" class="padding7" @click="deleterow(scope.row)"
                            :disabled="!editpermission"></el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>

        <div v-if="page == 2" class="UniversalReportwidth_header">
            <div class="fixdheader">
                <span class="headerstyle">QA Report Criteria</span>
                <hr class="hrstyle" />
            </div>

            <div>
                <el-input id="analysis_report_criteria_reportname" class="reportname_width" placeholder="Report Name"
                    v-model="form.reportname">
                </el-input>
                <span class="right">
                    <el-button type="success" icon="el-icon-circle-check" :disabled="!editpermission"
                        @click="cellchanged('analysis_qa_report_criteria_save', () => { savereport() }, false)">Save</el-button>
                </span>
            </div>

            <div class="spaceBetween" />
            <div class="UniversalReportwidth">
                <el-table :data="[{}]" class="tableBox-cell">
                    <el-table-column label="Report Criteria" width="289"></el-table-column>
                    <el-table-column label="Col" align="center" width="50"></el-table-column>
                    <el-table-column label="Pri" align="center" width="50"></el-table-column>
                </el-table>
            </div>

            <div class="spaceBetween" />
            <div class="UniversalReportwidth">
                <div>
                    <div>
                        <el-select id="analysis_report_criteria_typeid" class="criteria_width" placeholder="Type"
                            filterable multiple clearable v-model="form.typeid"
                            @change="changeselection($event, form, 'typeid')">
                            <el-option v-for="item in getoptionslist('formtype')" :key="item.id" :label="item.name"
                                :value="item.id">
                            </el-option>
                        </el-select>

                        <el-input-number :controls="false" id="analysis_report_criteria_typecol"
                            class="marginleft4px inputcenter inputwidth50" v-model="form.typecol">
                        </el-input-number>

                        <el-input-number :controls="false" id="analysis_report_criteria_typepri"
                            class="marginleft4px inputcenter inputwidth50" v-model="form.typepri">
                        </el-input-number>
                    </div>

                    <div class="spaceBetween" />
                    <div>
                        <el-select id="analysis_report_criteria_projectid" class="criteria_width" placeholder="Project"
                            filterable multiple clearable v-model="form.projectid" @remove-tag="removetagforproject"
                            @change="changeproject($event, form, 'projectid')">
                            <el-option v-for="item in multipleselect.projectoptions" :key="item.id"
                                :label="item.projectname" :value="item.id">
                            </el-option>
                        </el-select>

                        <el-input-number :controls="false" id="analysis_report_criteria_projectcol"
                            class="marginleft4px inputcenter inputwidth50" v-model="form.projectcol">
                        </el-input-number>

                        <el-input-number :controls="false" id="analysis_report_criteria_projectpri"
                            class="marginleft4px inputcenter inputwidth50" v-model="form.projectpri">
                        </el-input-number>
                    </div>

                    <div class="spaceBetween" />
                    <div>
                        <el-select id="analysis_report_criteria_partname" class="criteria_width"
                            placeholder="Project parts"
                            :remote="form.projectid == undefined || form.projectid.length <= 0"
                            :remote-method="remotesearchmultiplepart" filterable multiple clearable
                            v-model="form.partname" @focus="remotesearchmultiplepart('')" @remove-tag="removetagforpart"
                            @change="changemultiplepart($event, form, 'partname')">
                            <el-option v-for="item in multipleselect.displayparts" :key="item.id" :label="item.name"
                                :value="item.name">
                            </el-option>
                        </el-select>

                        <el-input-number :controls="false" id="analysis_report_criteria_partcol"
                            class="marginleft4px inputcenter inputwidth50" v-model="form.partcol">
                        </el-input-number>

                        <el-input-number :controls="false" id="analysis_report_criteria_partpri"
                            class="marginleft4px inputcenter inputwidth50" v-model="form.partpri">
                        </el-input-number>
                    </div>

                    <div class="spaceBetween" />
                    <div>
                        <el-select id="analysis_report_criteria_sectiondisplay" class="criteria_width"
                            placeholder="Section" :remote="form.projectid == undefined || form.projectid.length <= 0"
                            :remote-method="remotesearchmultiplesections" filterable multiple clearable
                            v-model="form.sectiondisplay" @focus="remotesearchmultiplesections('')"
                            @change="changeselection($event, form, 'sectiondisplay')">
                            <el-option v-for="item in multipleselect.sections" :key="item.id"
                                :label="item.sectiondisplay" :value="item.sectiondisplay">
                            </el-option>
                        </el-select>

                        <el-input-number :controls="false" id="analysis_report_criteria_sectioncol"
                            class="marginleft4px inputcenter inputwidth50" v-model="form.sectioncol">
                        </el-input-number>

                        <el-input-number :controls="false" id="analysis_report_criteria_sectionpri"
                            class="marginleft4px inputcenter inputwidth50" v-model="form.sectionpri">
                        </el-input-number>
                    </div>

                    <div class="spaceBetween" />
                    <div>
                        <el-select id="analysis_report_criteria_checkerid" class="criteria_width" placeholder="Checker"
                            filterable multiple clearable v-model="form.checkerid"
                            @change="changeselection($event, form, 'checkerid')">
                            <el-option v-for="item in checkers" :key="item.id" :label="item.fullname" :value="item.id">
                            </el-option>
                        </el-select>

                        <el-input-number :controls="false" id="analysis_report_criteria_checkercol"
                            class="marginleft4px inputcenter inputwidth50" v-model="form.checkercol">
                        </el-input-number>

                        <el-input-number :controls="false" id="analysis_report_criteria_checkerpri"
                            class="marginleft4px inputcenter inputwidth50" v-model="form.checkerpri">
                        </el-input-number>
                    </div>

                    <div class="spaceBetween" />
                    <div>
                        <el-select id="analysis_report_criteria_correctorid" class="criteria_width"
                            placeholder="Corrector" filterable multiple clearable v-model="form.correctorid"
                            @change="changeselection($event, form, 'correctorid')">
                            <el-option v-for="item in correctors" :key="item.id" :label="item.fullname"
                                :value="item.id">
                            </el-option>
                        </el-select>

                        <el-input-number :controls="false" id="analysis_report_criteria_correctorcol"
                            class="marginleft4px inputcenter inputwidth50" v-model="form.correctorcol">
                        </el-input-number>

                        <el-input-number :controls="false" id="analysis_report_criteria_correctorpri"
                            class="marginleft4px inputcenter inputwidth50" v-model="form.correctorpri">
                        </el-input-number>
                    </div>

                    <div class="spaceBetween" />
                    <div>
                        <el-select id="analysis_report_criteria_viewid" class="criteria_width" placeholder="View"
                            filterable multiple clearable v-model="form.viewid"
                            @change="changeselection($event, form, 'viewid')">
                            <el-option v-for="item in getoptionslist('view')" :key="item.id" :label="item.name"
                                :value="item.id">
                            </el-option>
                        </el-select>

                        <el-input-number :controls="false" id="analysis_report_criteria_viewcol"
                            class="marginleft4px inputcenter inputwidth50" v-model="form.viewcol">
                        </el-input-number>

                        <el-input-number :controls="false" id="analysis_report_criteria_viewpri"
                            class="marginleft4px inputcenter inputwidth50" v-model="form.viewpri">
                        </el-input-number>
                    </div>

                    <div class="spaceBetween" />
                    <div>
                        <!-- <el-popover placement="top-start" width="315" trigger="manual" :open-delay="500"
                            v-model="form.issnips">
                            <el-select slot="reference" class="criteria_width" placeholder="Snip" clearable
                                v-model="form.snipsselected" @change="visiblepopover($event, form, 'snips')">
                                <el-option v-for="item in sniplist" :key="item.id" :label="item.name" :value="item.id">
                                </el-option>
                            </el-select>
                        </el-popover> -->

                        <el-input id="analysis_report_criteria_snips" class="criteria_width" placeholder="Snip"
                            v-model="form.snips"></el-input>

                        <el-input-number :controls="false" id="analysis_report_criteria_col_snips"
                            class="marginleft4px inputcenter inputwidth50" v-model="form.snipscol">
                        </el-input-number>

                        <el-input-number :controls="false" id="analysis_report_criteria_pri_snips"
                            class="marginleft4px inputcenter inputwidth50" v-model="form.snipspri">
                        </el-input-number>
                    </div>

                    <div class="spaceBetween" />
                    <div>
                        <!-- <el-popover placement="top-start" width="315" trigger="manual" :open-delay="500"
                            v-model="form.isrefnr">
                            <el-select slot="reference" class="criteria_width" placeholder="Ref" clearable
                                v-model="form.refnrselected" @change="visiblepopover($event, form, 'refnr')">
                                <el-option v-for="item in referencelist" :key="item.id" :label="item.name"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                        </el-popover> -->

                        <el-input-number :controls="false" id="analysis_report_criteria_refnr"
                            class="criteria_width inputleft_number" placeholder="Ref" v-model="form.refnr">
                        </el-input-number>

                        <el-input-number :controls="false" id="analysis_report_criteria_col_refnr"
                            class="marginleft4px inputcenter inputwidth50" v-model="form.refnrcol">
                        </el-input-number>

                        <el-input-number :controls="false" id="analysis_report_criteria_pri_refnr"
                            class="marginleft4px inputcenter inputwidth50" v-model="form.refnrpri">
                        </el-input-number>
                    </div>

                    <div class="spaceBetween" />
                    <div>
                        <el-select id="analysis_report_criteria_issueid" class="criteria_width"
                            placeholder="Action / Issue" filterable multiple clearable v-model="form.issueid"
                            @change="changeselection($event, form, 'issueid')">
                            <el-option v-for="item in getoptionslist('action')" :key="item.id" :label="item.name"
                                :value="item.id">
                            </el-option>
                        </el-select>

                        <el-input-number :controls="false" id="analysis_report_criteria_issuecol"
                            class="marginleft4px inputcenter inputwidth50" v-model="form.issuecol">
                        </el-input-number>

                        <el-input-number :controls="false" id="analysis_report_criteria_issuepri"
                            class="marginleft4px inputcenter inputwidth50" v-model="form.issuepri">
                        </el-input-number>
                    </div>

                    <div class="spaceBetween" />
                    <div>
                        <!-- <el-popover placement="top-start" width="315" trigger="manual" :open-delay="500"
                            v-model="form.isnr">
                            <el-select slot="reference" class="criteria_width" placeholder="Nr" clearable
                                v-model="form.nrselected" @change="visiblepopover($event, form, 'nr')">
                                <el-option v-for="item in nrlist" :key="item.id" :label="item.name" :value="item.id">
                                </el-option>
                            </el-select>
                        </el-popover> -->

                        <el-input-number :controls="false" id="analysis_report_criteria_nr"
                            class="criteria_width inputleft_number" placeholder="Nr" v-model="form.nr">
                        </el-input-number>

                        <el-input-number :controls="false" id="analysis_report_criteria_col_nr"
                            class="marginleft4px inputcenter inputwidth50" v-model="form.nrcol">
                        </el-input-number>

                        <el-input-number :controls="false" id="analysis_report_criteria_pri_nr"
                            class="marginleft4px inputcenter inputwidth50" v-model="form.nrpri">
                        </el-input-number>
                    </div>

                    <div class="spaceBetween" />
                    <div>
                        <!-- <el-popover placement="top-start" width="315" trigger="manual" :open-delay="500"
                            v-model="form.isckcomment">
                            <el-select slot="reference" class="criteria_width" placeholder="CK Comment" clearable
                                v-model="form.ckcommentselected" @change="visiblepopover($event, form, 'ckcomment')">
                                <el-option v-for="item in ckcommentlist" :key="item.id" :label="item.name"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                        </el-popover> -->

                        <el-input id="analysis_report_criteria_ckcomment" class="criteria_width"
                            placeholder="CK Comment" v-model="form.ckcomment">
                        </el-input>

                        <el-input-number :controls="false" id="analysis_report_criteria_col_ckcomment"
                            class="marginleft4px inputcenter inputwidth50" v-model="form.ckcommentcol">
                        </el-input-number>

                        <el-input-number :controls="false" id="analysis_report_criteria_pri_ckcomment"
                            class="marginleft4px inputcenter inputwidth50" v-model="form.ckcommentpri">
                        </el-input-number>
                    </div>

                    <div class="spaceBetween" />
                    <div>
                        <el-select id="analysis_report_criteria_priorityid" class="criteria_width" placeholder="Val"
                            filterable multiple clearable v-model="form.priorityid"
                            @change="changeselection($event, form, 'priorityid')">
                            <el-option v-for="item in getoptionslist('priority')" :key="item.id" :label="item.name"
                                :value="item.id">
                            </el-option>
                        </el-select>

                        <el-input-number :controls="false" id="analysis_report_criteria_prioritycol"
                            class="marginleft4px inputcenter inputwidth50" v-model="form.prioritycol">
                        </el-input-number>

                        <el-input-number :controls="false" id="analysis_report_criteria_prioritypri"
                            class="marginleft4px inputcenter inputwidth50" v-model="form.prioritypri">
                        </el-input-number>
                    </div>

                    <div class="spaceBetween" />
                    <div>
                        <el-select id="analysis_report_criteria_qasolutionid" class="criteria_width"
                            placeholder="QA Solution" filterable multiple clearable v-model="form.qasolutionid"
                            @change="changeselection($event, form, 'qasolutionid')">
                            <el-option v-for="item in solutionswithempty" :key="item.id" :label="item.name"
                                :value="item.id">
                            </el-option>
                        </el-select>

                        <el-input-number :controls="false" id="analysis_report_criteria_qasolutioncol"
                            class="marginleft4px inputcenter inputwidth50" v-model="form.qasolutioncol">
                        </el-input-number>

                        <el-input-number :controls="false" id="analysis_report_criteria_qasolutionpri"
                            class="marginleft4px inputcenter inputwidth50" v-model="form.qasolutionpri">
                        </el-input-number>
                    </div>

                    <div class="spaceBetween" />
                    <div>
                        <!-- <el-popover placement="top-start" width="315" trigger="manual" :open-delay="500"
                            v-model="form.isqacomment">
                            <el-select slot="reference" class="criteria_width" placeholder="QA Comment" clearable
                                v-model="form.qacommentselected" @change="visiblepopover($event, form, 'qacomment')">
                                <el-option v-for="item in qacommentlist" :key="item.id" :label="item.name"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                        </el-popover> -->

                        <el-input id="analysis_report_criteria_qacomment" class="criteria_width"
                            placeholder="QA Comment" v-model="form.qacomment">
                        </el-input>

                        <el-input-number :controls="false" id="analysis_report_criteria_col_qacomment"
                            class="marginleft4px inputcenter inputwidth50" v-model="form.qacommentcol">
                        </el-input-number>

                        <el-input-number :controls="false" id="analysis_report_criteria_pri_qacomment"
                            class="marginleft4px inputcenter inputwidth50" v-model="form.qacommentpri">
                        </el-input-number>
                    </div>

                    <div class="spaceBetween" />
                    <div>
                        <el-select id="analysis_report_criteria_done" class="criteria_width" placeholder="Done"
                            filterable multiple clearable v-model="form.done"
                            @change="changeselection($event, form, 'done')">
                            <el-option v-for="item in bitlist" :key="item.value" :label="item.label"
                                :value="item.value">
                            </el-option>
                        </el-select>

                        <el-input-number :controls="false" id="analysis_report_criteria_col_done"
                            class="marginleft4px inputcenter inputwidth50" v-model="form.donecol">
                        </el-input-number>

                        <el-input-number :controls="false" id="analysis_report_criteria_pri_done"
                            class="marginleft4px inputcenter inputwidth50" v-model="form.donepri">
                        </el-input-number>
                    </div>
                    <div class="spaceBetween" />
                </div>
            </div>
        </div>

        <div v-if="page == 3">
            <div>
                <el-row>
                    <el-col :span="12">
                        <div class="UniversalReportwidth_header">
                            <span class="headerstyle">{{ form.reportname }}</span>
                            <div class="floatright">
                                <span>
                                    <span class="headerstyle">Count: {{ searchbar.count }}</span>
                                </span>
                            </div>
                        </div>
                    </el-col>

                    <el-col :span="12">
                        <div class="el-input el-input--mini verticalalignsub">
                            <span><b>Date Range:</b></span>
                            <span>{{ getdaterange }}</span>
                        </div>
                    </el-col>
                </el-row>
            </div>

            <div class="tablepadd1and3 tablewordbreak tablecellzero tabletexttop">
                <el-table :data="reports" :border="true" :height="height - 20" :show-header="true"
                    :header-cell-style="tableHeaderColor">
                    <el-table-column label="Link" width="50">
                        <el-table-column label="Link" width="50">
                            <template slot="header" slot-scope="{}">
                            </template>
                            <template slot-scope="scope">
                                <div class="UR-Link" @click=" linktoredirect(scope.row)">Link</div>
                            </template>
                        </el-table-column>
                    </el-table-column>

                    <el-table-column v-for="cl in columnarray" :key="cl.name" :label="cl.value">
                        <el-table-column v-if="cl.name == 'project'" :width="cl.defaultwidth">
                            <template slot="header" slot-scope="{}">
                                <el-autocomplete class="inline-input bold" :style="{ width: '100%' }"
                                    v-model="searchbar.project.projectname"
                                    popper-class="morewidth-autocomplete-project" :fetch-suggestions="searchProject"
                                    @select="selectproject" value-key="projectname" placeholder="Project">
                                    <el-button slot="suffix" @click="closeproject" type="text"
                                        size="mini">&times;</el-button>
                                    <template slot-scope="{ item }">
                                        <span style="float: left">{{ item.projectname }}</span>
                                        <span style="float: right">{{ item.accessedon | dateformat }}</span>
                                    </template>
                                </el-autocomplete>
                            </template>
                            <template slot-scope="scope">
                                <div>{{ scope.row.p.projectname }}</div>
                            </template>
                        </el-table-column>

                        <el-table-column v-if="cl.name == 'part'" :width="cl.defaultwidth">
                            <template slot="header" slot-scope="{}">
                                <el-select class="bold" :style="{ width: '100%' }" placeholder="Project Parts"
                                    :remote="searchbar.projectid == undefined || searchbar.projectid <= 0"
                                    :remote-method="remotesearchsinglepart" filterable clearable
                                    v-model="searchbar.partid" @focus="remotesearchsinglepart('')" @change="changepart">
                                    <el-option v-for="item in searchbar.projectparts" :key="item.id" :label="item.name"
                                        :value="item.id">
                                    </el-option>
                                </el-select>
                            </template>
                            <template slot-scope="scope">
                                <div>
                                    <span v-if="scope.row.pr.name != null">{{ scope.row.pr.name }}</span>
                                    <span v-else>Single Bill</span>
                                </div>
                            </template>
                        </el-table-column>

                        <el-table-column v-if="cl.name == 'section'" :width="cl.defaultwidth">
                            <template slot="header" slot-scope="{}">
                                <el-select class="bold" :style="{ width: '100%' }" placeholder="Section"
                                    :remote="searchbar.projectid == undefined || searchbar.projectid <= 0"
                                    :remote-method="remotesearchsinglesections" filterable clearable
                                    v-model="searchbar.sectionid" @focus="remotesearchsinglesections('')"
                                    @change="changesection($event)">
                                    <el-option v-for="item in searchbar.sections" :key="item.id"
                                        :label="item.sectiondisplay" :value="item.id">
                                    </el-option>
                                </el-select>
                            </template>
                            <template slot-scope="scope">
                                <div>{{ scope.row.ps.sectiondisplay }}</div>
                            </template>
                        </el-table-column>

                        <el-table-column v-if="cl.name == 'type'" :width="cl.defaultwidth">
                            <template slot="header" slot-scope="{}">
                                <el-select class="fullwidth" placeholder="Type" filterable clearable
                                    v-model="searchbar.typeid" @change="changesearchbar($event, 'typeid')">
                                    <el-option v-for="item in getoptionslist('formtype')" :key="item.id"
                                        :label="item.name" :value="item.id">
                                    </el-option>
                                </el-select>
                            </template>
                            <template slot-scope="scope">
                                <div>{{ scope.row.otype.name }}</div>
                            </template>
                        </el-table-column>

                        <el-table-column v-if="cl.name == 'checker'" :width="cl.defaultwidth">
                            <template slot="header" slot-scope="{}">
                                <el-select class="fullwidth" placeholder="Checker" filterable clearable
                                    v-model="searchbar.checkerid" @change="changesearchbar($event, 'checkerid')">
                                    <el-option v-for="item in checkers" :key="item.id" :label="item.fullname"
                                        :value="item.id">
                                    </el-option>
                                </el-select>
                            </template>
                            <template slot-scope="scope">
                                <div>{{ scope.row.checkby.fullname }}</div>
                            </template>
                        </el-table-column>

                        <el-table-column v-if="cl.name == 'corrector'" :width="cl.defaultwidth">
                            <template slot="header" slot-scope="{}">
                                <el-select class="fullwidth" placeholder="Corrector" filterable clearable
                                    v-model="searchbar.correctorid" @change="changesearchbar($event, 'correctorid')">
                                    <el-option v-for="item in correctors" :key="item.id" :label="item.fullname"
                                        :value="item.id">
                                    </el-option>
                                </el-select>
                            </template>
                            <template slot-scope="scope">
                                <div>{{ scope.row.correction.fullname }}</div>
                            </template>
                        </el-table-column>

                        <el-table-column v-if="cl.name == 'view'" :width="cl.defaultwidth">
                            <template slot="header" slot-scope="{}">
                                <el-select class="fullwidth" placeholder="View" filterable clearable
                                    v-model="searchbar.viewid" @change="changesearchbar($event, 'viewid')">
                                    <el-option v-for="item in getoptionslist('view')" :key="item.id" :label="item.name"
                                        :value="item.id">
                                    </el-option>
                                </el-select>
                            </template>
                            <template slot-scope="scope">
                                <div>{{ scope.row.oview.name }}</div>
                            </template>
                        </el-table-column>

                        <el-table-column v-if="cl.name == 'snips'" :width="cl.defaultwidth">
                            <template slot="header" slot-scope="{}">
                                <el-popover placement="top-start" width="300" trigger="manual" :open-delay="500"
                                    v-model="searchbar.issnips">
                                    <el-select slot="reference" class="fullwidth" placeholder="Snips" clearable
                                        v-model="searchbar.snipsselected"
                                        @change="visiblepopover($event, searchbar, 'snips')">
                                        <el-option v-for="item in sniplist" :key="item.id" :label="item.name"
                                            :value="item.id">
                                        </el-option>
                                    </el-select>
                                    <el-input class="fullwidth" placeholder="Snips" v-model="searchbar.snips"
                                        @change="changesearchbar($event, 'snips')">
                                    </el-input>
                                </el-popover>
                            </template>
                            <template slot-scope="scope">
                                <div v-if="scope.row.svi && scope.row.svi.length > 0"
                                    class="el-input el-input--mini UR-Link" @click="getMedia(scope.row.svi)">snips</div>
                            </template>
                        </el-table-column>

                        <el-table-column v-if="cl.name == 'refnr'" :width="cl.defaultwidth">
                            <template slot="header" slot-scope="{}">
                                <el-popover placement="top-start" width="300" trigger="manual" :open-delay="500"
                                    v-model="searchbar.isrefnr">
                                    <el-select slot="reference" class="fullwidth" placeholder="Ref" clearable
                                        v-model="searchbar.refnrselected"
                                        @change="visiblepopover($event, searchbar, 'refnr')">
                                        <el-option v-for="item in referencelist" :key="item.id" :label="item.name"
                                            :value="item.id">
                                        </el-option>
                                    </el-select>
                                    <el-input-number :controls="false" class="inputleft_number"
                                        :style="{ width: '100%' }" placeholder="Ref" v-model="searchbar.refnr"
                                        @change="changesearchbar($event, 'refnr')">
                                    </el-input-number>
                                </el-popover>
                            </template>

                            <template slot-scope="scope">
                                <div>{{ scope.row.svd.reference }}</div>
                            </template>
                        </el-table-column>

                        <el-table-column v-if="cl.name == 'issue'" :width="cl.defaultwidth">
                            <template slot="header" slot-scope="{}">
                                <el-select class="fullwidth" placeholder="Action / Issue" filterable clearable
                                    v-model="searchbar.issueid" @change="changesearchbar($event, 'issueid')">
                                    <el-option v-for="item in getoptionslist('action')" :key="item.id"
                                        :label="item.name" :value="item.id">
                                    </el-option>
                                </el-select>
                            </template>
                            <template slot-scope="scope">
                                <div>{{ scope.row.issue.name }}</div>
                            </template>
                        </el-table-column>

                        <el-table-column v-if="cl.name == 'nr'" :width="cl.defaultwidth">
                            <template slot="header" slot-scope="{}">
                                <el-popover placement="top-start" width="300" trigger="manual" :open-delay="500"
                                    v-model="searchbar.isnr">
                                    <el-select slot="reference" class="fullwidth" placeholder="Nr" clearable
                                        v-model="searchbar.nrselected"
                                        @change="visiblepopover($event, searchbar, 'nr')">
                                        <el-option v-for="item in nrlist" :key="item.id" :label="item.name"
                                            :value="item.id">
                                        </el-option>
                                    </el-select>
                                    <el-input-number :controls="false" class="inputleft_number"
                                        :style="{ width: '100%' }" placeholder="Nr" v-model="searchbar.nr"
                                        @change="changesearchbar($event, 'nr')">
                                    </el-input-number>
                                </el-popover>
                            </template>
                            <template slot-scope="scope">
                                <div>{{ scope.row.svd.nr }}</div>
                            </template>
                        </el-table-column>

                        <el-table-column v-if="cl.name == 'ckcomment'" :width="cl.defaultwidth">
                            <template slot="header" slot-scope="{}">
                                <el-popover placement="top-start" width="300" trigger="manual" :open-delay="500"
                                    v-model="searchbar.isckcomment">
                                    <el-select slot="reference" class="fullwidth" placeholder="CK Comment" clearable
                                        v-model="searchbar.ckcommentselected"
                                        @change="visiblepopover($event, searchbar, 'ckcomment')">
                                        <el-option v-for="item in ckcommentlist" :key="item.id" :label="item.name"
                                            :value="item.id">
                                        </el-option>
                                    </el-select>
                                    <el-input class="fullwidth" placeholder="CK Comment" v-model="searchbar.ckcomment"
                                        @change="changesearchbar($event, 'ckcomment')">
                                    </el-input>
                                </el-popover>
                            </template>
                            <template slot-scope="scope">
                                <div>{{ scope.row.svd.comment1 }}</div>
                            </template>
                        </el-table-column>

                        <el-table-column v-if="cl.name == 'priority'" :width="cl.defaultwidth">
                            <template slot="header" slot-scope="{}">
                                <el-select class="fullwidth" placeholder="Val" filterable clearable
                                    v-model="searchbar.priorityid" @change="changesearchbar($event, 'priorityid')">
                                    <el-option v-for="item in getoptionslist('priority')" :key="item.id"
                                        :label="item.name" :value="item.id">
                                    </el-option>
                                </el-select>
                            </template>
                            <template slot-scope="scope">
                                <div>{{ scope.row.priority.name }}</div>
                            </template>
                        </el-table-column>

                        <el-table-column v-if="cl.name == 'qasolution'" :width="cl.defaultwidth">
                            <template slot="header" slot-scope="{}">
                                <div class="flexbox">
                                    <span class="flexcontent">
                                        <el-select class="fullwidth" placeholder="QA Solution" filterable clearable
                                            v-model="searchbar.qasolutionid"
                                            @change="changesearchbar($event, 'qasolutionid')">
                                            <el-option v-for="item in solutionswithempty" :key="item.id"
                                                :label="item.name" :value="item.id">
                                            </el-option>
                                        </el-select>
                                    </span>

                                    <span>
                                        <el-button :type="modify.solution == true ? 'success' : 'primary'"
                                            icon="el-icon-edit" class="padding7" :disabled="!editpermission"
                                            @click="externalcheckcolumn('solution')">
                                        </el-button>
                                    </span>
                                </div>
                            </template>
                            <template slot-scope="scope">
                                <div>
                                    <span v-if="modify.solution == true">
                                        <el-select :clearable="false" class="fullwidth" placeholder="QA Solution"
                                            v-model="scope.row.svd.solution"
                                            @change="editedchanged($event, scope.row, 'solution')">
                                            <el-option v-for="item in solutions" :key="item.id" :label="item.name"
                                                :value="item.id">
                                            </el-option>
                                        </el-select>
                                    </span>

                                    <span v-else>
                                        {{ scope.row.solution.name }}
                                    </span>
                                </div>
                            </template>
                        </el-table-column>

                        <el-table-column v-if="cl.name == 'qacomment'" :width="cl.defaultwidth">
                            <template slot="header" slot-scope="{}">
                                <div class="flexbox">
                                    <span class="flexcontent">
                                        <el-popover placement="top-start" width="300" trigger="manual" :open-delay="500"
                                            v-model="searchbar.isqacomment">
                                            <el-select slot="reference" class="fullwidth" placeholder="QA Comment"
                                                clearable v-model="searchbar.qacommentselected"
                                                @change="visiblepopover($event, searchbar, 'qacomment')">
                                                <el-option v-for="item in qacommentlist" :key="item.id"
                                                    :label="item.name" :value="item.id">
                                                </el-option>
                                            </el-select>

                                            <el-input class="fullwidth" placeholder="QA Comment"
                                                v-model="searchbar.qacomment"
                                                @change="changesearchbar($event, 'qacomment')">
                                            </el-input>
                                        </el-popover>
                                    </span>

                                    <span>
                                        <el-button :type="modify.comment2 == true ? 'success' : 'primary'"
                                            icon="el-icon-edit" class="padding7" :disabled="!editpermission"
                                            @click="externalcheckcolumn('comment2')">
                                        </el-button>
                                    </span>
                                </div>
                            </template>
                            <template slot-scope="scope">
                                <div>
                                    <span v-if="modify.comment2 == true">
                                        <el-input type="textarea" maxlength="255" class="padd3textarea"
                                            v-model="scope.row.svd.comment2"
                                            @change="editedchanged($event, scope.row, 'comment2')"
                                            :rows="$training.calculaterows(scope.row.svd.comment2, 35)"
                                            :style="{ height: $training.calculateheight(scope.row.svd.comment2, 35) + 'px' }">
                                        </el-input>
                                    </span>

                                    <span v-else>
                                        {{ scope.row.svd.comment2 }}
                                    </span>
                                </div>
                            </template>
                        </el-table-column>

                        <el-table-column v-if="cl.name == 'done'" align="center" :width="cl.defaultwidth">
                            <template slot="header" slot-scope="{}">
                                <div class="flexbox">
                                    <span class="flexcontent">
                                        <el-select class="fullwidth" placeholder="Done" filterable clearable
                                            v-model="searchbar.done" @change="changesearchbar($event, 'done')">
                                            <el-option v-for="item in bitlist" :key="item.value" :label="item.label"
                                                :value="item.value">
                                            </el-option>
                                        </el-select>
                                    </span>

                                    <span>
                                        <el-button :type="modify.done2 == true ? 'success' : 'primary'"
                                            icon="el-icon-edit" class="padding7" :disabled="!editpermission"
                                            @click="externalcheckcolumn('done2')">
                                        </el-button>
                                    </span>
                                </div>
                            </template>
                            <template slot-scope="scope">
                                <div>
                                    <el-checkbox v-if="modify.done2 == true" border
                                        class="height24px padding5px fontsize8" v-model="scope.row.svd.done2"
                                        @change="editedchanged($event, scope.row, 'done2')">
                                    </el-checkbox>

                                    <span v-else>
                                        <span v-if="scope.row.svd.done2">Yes</span>
                                        <span v-else>No</span>
                                    </span>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table-column>
                </el-table>
            </div>
        </div>

        <div>
            <el-dialog :before-close="mediadialogClose" :visible.sync="mediadialogVisible">
                <el-tabs v-model="activeName">
                    <el-tab-pane label="Images" name="first">
                        <div class="scroll">
                            <div v-for="i in medialist" :key="i.id">
                                <div v-if="i.ext == 'img'">
                                    <el-row>
                                        <el-col :span="12">
                                            <span>
                                                <h4>{{ i.name }}</h4>
                                            </span>
                                        </el-col>
                                        <el-col :span="12">
                                            <el-button icon="el-icon-download" class="right " @click="DownloadMedia(i)"
                                                type="primary">Download
                                            </el-button>
                                        </el-col>
                                    </el-row>
                                    <img height="90%" width="100%" :src="i.link" alt="" />
                                    <hr class="hrstyle" />
                                </div>
                            </div>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="videos" name="second">
                        <div class="scroll">
                            <div v-for="i in medialist" :key="i.id">
                                <div v-if="i.ext == 'mp4'">
                                    <el-row>
                                        <el-col :span="12">
                                            <span>
                                                <h4>{{ i.name }}</h4>
                                            </span>
                                        </el-col>
                                        <el-col :span="12">
                                            <el-button icon="el-icon-download" class="right" @click="DownloadMedia(i)"
                                                type="primary">Download
                                            </el-button>
                                        </el-col>
                                    </el-row>
                                    <video height="70%" width="100%" controls autoplay>
                                        <source :src="i.link" type="video/mp4">
                                        Your browser does not support the video tag.
                                    </video>
                                    <hr class="hrstyle" />
                                </div>
                            </div>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="Audios" name="third">
                        <div class="scroll">
                            <div v-for="i in medialist" :key="i.id">
                                <div v-if="i.ext == 'mp3'">
                                    <el-row>
                                        <el-col :span="12">
                                            <span>
                                                <h4>{{ i.name }}</h4>
                                            </span>
                                        </el-col>
                                        <el-col :span="12">
                                            <el-button icon="el-icon-download" class="right" @click="DownloadMedia(i)"
                                                type="primary">Download
                                            </el-button>
                                        </el-col>
                                    </el-row>
                                    <audio height="100%" width="100%" controls>
                                        <source :src="i.link" type="audio/ogg">
                                        Your browser does not support the audio element.
                                    </audio>
                                    <hr class="hrstyle" />
                                </div>
                            </div>
                        </div>
                    </el-tab-pane>
                </el-tabs>
            </el-dialog>
        </div>
    </div>
</template>
<script>
import moment from "moment";
export default {
    data() {
        return {
            page: 1,
            height: (document.documentElement.clientHeight - 160),
            pagesizes: this.$store.state.pagesizes,
            view: {
                pagesize: 20,
                filter: [
                    { table: 'ur', column: 'reportname', value: "" }
                ],
                sortcol: "ur.reportname",
                sortorder: "ascending",
                defaultfilter: ""
            },
            tabledata: [],
            mainsections: [],
            multipleselect: {
                projectoptions: [],
                selectedproject: [],
                allparts: [],
                displayparts: [],
                partsections: [],
                sections: []
            },
            defaultpart: { id: 0, projectid: 0, name: "Single Bill" },
            statusoptions: [],
            solutions: [],
            solutionswithempty: [],
            checkers: [],
            correctors: [],
            form: {},
            reports: [],
            columnarray: [],
            colpriarraykeyvalue: [
                { name: "project", value: "Project", defaultwidth: 290 },
                { name: "part", value: "Part", defaultwidth: 290 },
                { name: "section", value: "Section", defaultwidth: 290 },
                { name: "type", value: "Type", defaultwidth: 70 },
                { name: "checker", value: "Checker", defaultwidth: 130 },
                { name: "corrector", value: "Corrector", defaultwidth: 130 },
                { name: "view", value: "View", defaultwidth: 120 },
                { name: "snips", value: "Snips", defaultwidth: 70 },
                { name: "refnr", value: "Ref Nr", defaultwidth: 60 },
                { name: "issue", value: "Issue", defaultwidth: 120 },
                { name: "nr", value: "Nr", defaultwidth: 60 },
                { name: "ckcomment", value: "CK Comment", defaultwidth: 200 },
                { name: "priority", value: "Priority", defaultwidth: 60 },
                { name: "qasolution", value: "QA Solution", defaultwidth: 128 },
                { name: "qacomment", value: "QA Comment", defaultwidth: 228 },
                { name: "done", value: "Done", defaultwidth: 93 },],
            modify: {
                solution: false,
                comment2: false,
                done2: false,
            },
            medialist: [],
            activeName: 'first',
            mediadialogVisible: false,
            startdate: null,
            enddate: null,
            searchbar: {
                project: {
                    projectname: ""
                },
                projectparts: [],
                partsections: [],
                sections: [],
                sectionid: null
            },
            empty: {
                id: -1,
                name: "Empty",
                projectname: "Empty",
                fullname: "Empty",
                loginname: "Empty",
                sectiondisplay: "Empty",
            },
            bitlist: [{ label: 'Yes', value: 1 }, { label: 'No', value: 2 }],
            nrlist: [{ name: 'Nr', id: 1 }, { name: 'Empty', id: -1 }],
            sniplist: [{ name: 'Snip', id: 1 }, { name: 'Empty', id: '-1' }],
            referencelist: [{ name: 'Ref', id: 1 }, { name: 'Empty', id: -1 }],
            ckcommentlist: [{ name: 'CK Comment', id: 1 }, { name: 'Empty', id: '-1' }],
            qacommentlist: [{ name: 'QA Comment', id: 1 }, { name: 'Empty', id: '-1' }],
            qacommentlist: [{ name: 'QA Comment', id: 1 }, { name: 'Empty', id: '-1' }],
        }
    },

    computed: {
        getdaterange() {
            if (this.form.startdate == null && this.form.enddate == null) {
                return "All";
            } else {
                var newdate1 = "";
                var newdate2 = "";
                if (this.form.startdate != null) {
                    newdate1 = moment(this.form.startdate).format("DD-MMM-YYYY");
                }
                if (this.form.enddate != null) {
                    newdate2 = moment(this.form.enddate).format("DD-MMM-YYYY");
                }
                return newdate1 + " To " + newdate2;
            }
        },

        getoptionslist() {
            return function (data) {
                if (this.statusoptions.length == 0) {
                    return [];
                }
                if (data == 'action') {
                    var t = this.statusoptions.filter(x => x.optiontype == data)
                    const uniqueIds = [];
                    const unique = t.filter(element => {
                        const isDuplicate = uniqueIds.includes(element.name);
                        if (!isDuplicate) {
                            uniqueIds.push(element.name);
                            return true;
                        }
                        return false;
                    });
                    unique.unshift(this.empty);
                    return unique;
                }
                let optiontype = ["formtype", "view"];
                let options = this.statusoptions.filter(x => x.optiontype == data);
                if (!optiontype.includes(data)) {
                    options.unshift(this.empty);
                }
                return options;
            }
        },
    },

    methods: {
        getheight() {
            this.height = (document.documentElement.clientHeight - 160);
        },

        filtersearch() {
            this.view.pageno = 1;
            this.refresh();
        },

        rowperpages: async function (val) {
            try {
                this.usergridrow.ugv.rowperpage = val;
                this.usergridrow = await this.$training.savepagelayout(this.usergridrow);
                this.view.pageno = 1;
                this.view.pagesize = val;
                this.refresh();
            } catch (error) {
                this.$bus.$emit('error', error);
            }
        },

        currentchange(val) {
            this.view.pageno = val;
            this.refresh();
        },

        sortchange(sort) {
            this.view.sortcol = "ur." + sort.prop;
            this.view.sortorder = sort.order;
            this.refresh();
        },

        refresh() {
            this.showloading();
            this.$http.post("sectional/getqareportview", this.view)
                .then(response => {
                    this.tabledata = response.data.data;
                    this.view = response.data.view;
                    this.page = 1;
                    this.hideloading();
                }).catch(error => {
                    this.hideloading();
                    this.$bus.$emit('error', error);
                });
        },

        deleterow(row) {
            this.$confirm('Are you sure you want to Delete?', 'Warning', {
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancel',
                type: 'warning'
            }).then(() => {
                this.showloading();
                this.$http.post("sectional/deleteqareport", { id: row.id })
                    .then(response => {
                        this.hideloading();
                        this.$message({
                            showClose: true,
                            message: response.data.message,
                            type: "success"
                        });
                        this.refresh();
                    })
                    .catch(err => {
                        this.hideloading();
                        this.$bus.$emit('error', err);
                    });
            }).catch(() => {
                this.$message({
                    showClose: true,
                    type: 'info',
                    message: 'Delete canceled'
                });
            });
        },

        createnew() {
            this.page = 2;
            this.clearobjects();
            this.startdate = null;
            this.enddate = null;
        },

        remotesearchmultiplepart(query) {
            if (this.form.projectid == undefined || this.form.projectid.length <= 0) {
                this.$training.searchuniqueparts(query).then((data) => {
                    this.multipleselect.displayparts = data;
                }).catch(err => {
                    this.$bus.$emit("error", err);
                });
            }
        },

        remotesearchmultiplesections(query) {
            if (this.form.projectid == undefined || this.form.projectid.length <= 0) {
                if (query == "") {
                    this.multipleselect.sections = this.mainsections;
                }
                else {
                    this.multipleselect.sections = this.mainsections.filter(item => {
                        return item.sectiondisplay.toLowerCase().indexOf(query.toLowerCase()) > -1;
                    });
                }
            }
        },

        getusers: function () {
            this.$http.get('account/getallusers')
                .then(response => {
                    this.checkers = response.data.slice();
                    this.correctors = response.data.slice();
                    this.correctors.unshift(this.empty);

                }).catch(err => {
                    this.$bus.$emit('error', err);
                });
        },

        changeselection(event, data, columnname) {
            data[columnname] = event;
            this.$forceUpdate();
        },

        validatenumberinput(event, data, columnname) {
            const numericRegex = /^[0-9]*$/;
            if (!numericRegex.test(event)) {
                data[columnname] = data[columnname].replace(/\D/g, "");
            }
            if (data[columnname].trim() == "") {
                data[columnname] = null;
            }
        },

        replacecommatodoller(value) {
            const replacedlist = value.map((str) => {
                if (str.includes(',')) {
                    return str.replace(/,/g, '$');
                }
                return str;
            });
            return replacedlist.join(',');
        },

        convertarraytostring(value, columnname) {
            if (value != undefined && value.length > 0) {
                if (typeof (value) != 'string') {
                    if (columnname == 'partname' || columnname == 'sectiondisplay') {
                        let dollerstr = this.replacecommatodoller(value);
                        this.form[columnname] = dollerstr;
                    } else {
                        this.form[columnname] = value.join(',');
                    }
                }
            }
            else {
                this.form[columnname] = null;
            }
        },

        convertallpropertiesarraytostring(row) {
            this.convertarraytostring(row.projectid, 'projectid');
            this.convertarraytostring(row.partname, 'partname');
            this.convertarraytostring(row.sectiondisplay, 'sectiondisplay');
            this.convertarraytostring(row.typeid, 'typeid');
            this.convertarraytostring(row.checkerid, 'checkerid');
            this.convertarraytostring(row.correctorid, 'correctorid');
            this.convertarraytostring(row.viewid, 'viewid');
            this.convertarraytostring(row.issueid, 'issueid');
            this.convertarraytostring(row.priorityid, 'priorityid');
            this.convertarraytostring(row.qasolutionid, 'qasolutionid');
            this.convertarraytostring(row.done, 'done');
        },

        replacedollertocomma(str) {
            let array = str.split(',');
            return array.map(s => {
                if (s.includes('$')) {
                    return s.replace(/\$/g, ',');
                }
                let values = s.split(',');
                return values.join(',');
            });
        },

        convertstringtoarray(value, columnname) {
            if (value != null) {
                if (columnname == 'partname' || columnname == 'sectiondisplay') {
                    let list = this.replacedollertocomma(value);
                    this.form[columnname] = list;
                } else {
                    this.form[columnname] = value.split(',').map(Number);
                }
            } else {
                this.form[columnname] = [];
            }
        },

        convertallpropertiesstringtoarray(row) {
            this.convertstringtoarray(row.projectid, 'projectid');
            this.convertstringtoarray(row.partname, 'partname');
            this.convertstringtoarray(row.sectiondisplay, 'sectiondisplay');
            this.convertstringtoarray(row.typeid, 'typeid');
            this.convertstringtoarray(row.checkerid, 'checkerid');
            this.convertstringtoarray(row.correctorid, 'correctorid');
            this.convertstringtoarray(row.viewid, 'viewid');
            this.convertstringtoarray(row.issueid, 'issueid');
            this.convertstringtoarray(row.priorityid, 'priorityid');
            this.convertstringtoarray(row.qasolutionid, 'qasolutionid');
            this.convertstringtoarray(row.done, 'done');
        },

        setuniquearraybyname(details, column) {
            const setunique = new Set();
            return details.filter(u => {
                if (!setunique.has(u[column])) {
                    setunique.add(u[column]);
                    return true;
                }
                return false;
            });
        },

        editrow(row) {
            this.showloading();
            this.$http.post('Sectional/ViewCriteriabyid', { id: row.id })
                .then(response => {
                    this.page = 2;
                    if (response.data != null) {
                        this.form = response.data;
                        this.convertallpropertiesstringtoarray(this.form);
                        // let proparray = ["ckcomment", "qacomment", "snips", "refnr", "nr"];
                        // for (const [key, value] of Object.entries(this.form)) {
                        //     if (proparray.includes(key)) {
                        //         this.form['is' + key] = true;
                        //         this.form[key + 'selected'] = 1;
                        //     }
                        // }

                        if (this.form.projectid.length > 0) {
                            this.addedproject(this.form.projectid);
                            this.getmultipleprojectsections(this.form.projectid);
                        } else {
                            this.multipleselect.allparts = [];
                            this.multipleselect.displayparts = [];
                            this.multipleselect.sections = [];
                            this.multipleselect.partsections = [];
                        }
                    } else {
                        this.createnew();
                    }
                    this.hideloading();
                })
                .catch(err => {
                    this.hideloading();
                    this.$bus.$emit('error', err);
                });
        },

        getmultipleprojectsections(projectlist) {
            let data = projectlist.join(',');
            let url = `project/getmultipleprojectsections?data=${data}`;
            this.$http.post(url, {})
                .then(response => {
                    this.multipleselect.partsections = response.data.projectsectionsview;
                    this.multipleselect.allparts = response.data.projectparts;
                    this.multipleselect.displayparts = this.setuniquearraybyname(response.data.projectparts, 'name');

                    if (this.multipleselect.displayparts.length == 0 && this.form.projectid != null) {
                        this.multipleselect.allparts.unshift(this.defaultpart);
                        this.multipleselect.displayparts.unshift(this.defaultpart);
                    }

                    for (let i = 0; i < this.multipleselect.selectedproject.length; i++) {
                        let p = this.multipleselect.selectedproject[i];
                        let existsbill = this.multipleselect.allparts.find(x => x.name.trim().toLowerCase() == 'single bill' || p.id === x.projectid);
                        if (existsbill == undefined) {
                            this.multipleselect.allparts.unshift(this.defaultpart);
                            this.multipleselect.displayparts.unshift(this.defaultpart);
                        }
                        let existpart = this.multipleselect.allparts.find(x => x.projectid == p.id);
                        if (existpart == undefined || existpart.name.trim().toLowerCase() == 'single bill') {
                            p.issinglebill = true;
                        }
                        else {
                            p.issinglebill = false;
                        }
                    }

                    if (this.form.partname != null && this.form.partname.length > 0) {
                        let currentpart = this.form.partname.filter(item =>
                            this.multipleselect.allparts.some(x => x.name == item)
                        );
                        this.form.partname = currentpart;
                        this.updatepartsections(this.form.partname);
                    }
                    else {
                        this.multipleselect.sections = [];
                        this.form.sectiondisplay = null;
                    }
                })
                .catch(err => {
                    this.$bus.$emit('error', err);
                });
        },

        addedproject(idlist) {
            this.multipleselect.selectedproject = [];
            for (let i = 0; i < idlist.length; i++) {
                let exist = this.multipleselect.projectoptions.find(p => p.id === idlist[i]);
                this.multipleselect.selectedproject.push(exist);
            }
        },

        changeproject(event, data, columnname) {
            data[columnname] = event;
            if (event.length > 0) {
                this.addedproject(event);
                this.getmultipleprojectsections(data[columnname]);
            }
            else {
                this.multipleselect.allparts = [];
                this.multipleselect.displayparts = [];
                this.multipleselect.sections = [];
                this.multipleselect.partsections = [];
                this.form.partname = null;
                this.form.sectiondisplay = null;
            }
            this.$forceUpdate();
        },

        removetagforproject(event) {
            this.multipleselect.displayparts = this.multipleselect.displayparts.filter(item => {
                let partname = item.name.trim().toLowerCase();
                if (item.projectid === event && partname != 'single bill') {
                    let partindex = this.form.partname.findIndex(n => n == item.name);
                    if (partindex != -1) {
                        this.form.partname.splice(partindex, 1);
                    }
                }

                if (partname == 'single bill') {
                    let checkexits = this.multipleselect.selectedproject.filter(p => p.issinglebill).length;
                    if (checkexits === 0) {
                        let delpart = this.form.partname.findIndex(n => n == item.name);
                        this.form.partname.splice(delpart, 1);
                    }
                }

                if (item.projectid != event) {
                    return item;
                }
                this.$forceUpdate();
            });

            this.multipleselect.sections = this.multipleselect.sections.filter(item => {
                if (item.projectid === event) {
                    if (this.form.sectiondisplay != null && this.form.sectiondisplay.length > 0) {
                        let secionindex = this.form.sectiondisplay.findIndex(s => s == item.sectiondisplay);
                        if (secionindex != -1) {
                            this.form.sectiondisplay.splice(secionindex, 1);
                        }
                    }
                }
                if (item.projectid != event) {
                    return item;
                }
            });
        },

        changemultiplepart(value, data, columnname) {
            data[columnname] = value;
            if (value.length > 0) {
                this.updatepartsections(value);
            }
            else {
                this.multipleselect.sections = [];
                this.form.sectiondisplay = null;
            }
        },

        removetagforpart() {
            this.removeunusedsection(this.multipleselect.sections);
        },

        removeunusedsection(details) {
            let currentsection = this.form.sectiondisplay.filter(item =>
                details.some(x => x.sectiondisplay == item)
            );
            this.form.sectiondisplay = currentsection;
        },

        updatepartsections(selectedparts) {
            this.multipleselect.sections = [];
            let unsortedlist = [];
            if (selectedparts.length > 0) {
                for (let i = 0; i < selectedparts.length; i++) {
                    const element = selectedparts[i];
                    let partlist = this.multipleselect.allparts.filter(p => p.name == element);
                    partlist.forEach(pp => {
                        let sections = [];
                        let singlebill = [];
                        let partname = pp.name.trim().toLowerCase();

                        if (partname == 'single bill' || pp.projectid === 0) {
                            singlebill = this.multipleselect.partsections.filter(s => s.projectPartId === 0);
                        }

                        if (pp.projectid > 0) {
                            sections = this.multipleselect.partsections.filter(s => s.projectPartId === pp.id);
                        }
                        unsortedlist = [...unsortedlist, ...singlebill, ...sections];
                    });
                }
                let uniquesections = this.setuniquearraybyname(unsortedlist, 'sectiondisplay');
                if (this.form.sectiondisplay != null && this.form.sectiondisplay.length > 0) {
                    this.removeunusedsection(uniquesections);
                }
                this.multipleselect.sections = uniquesections.sort((a, b) => {
                    const sectionA = a.sectiondisplay.toLowerCase();
                    const sectionB = b.sectiondisplay.toLowerCase();
                    return sectionA.localeCompare(sectionB);
                });
            }
            else {
                this.multipleselect.sections = [];
                this.form.sectiondisplay = null;
            }
        },

        cellchanged(cellaction, callback, cancall) {
            const app = this;
            this.$directionutility.setcomponent(app);
            if (cancall) {
                callback();
                this.canmatchcellid(() => { }, cellaction, "");
            } else {
                this.canmatchcellid(callback, cellaction, "");
            }
        },

        savereport() {
            this.validationwarning(() => {
                this.convertallpropertiesarraytostring(this.form);
                this.form.startdate = this.startdate;
                this.form.enddate = this.enddate;
                this.showloading();
                this.$http.post('Sectional/saveUniversalQAReport', this.form)
                    .then(response => {
                        this.form = response.data;
                        this.convertallpropertiesstringtoarray(this.form);
                        // this.clearobjects();
                        // this.refresh();
                        this.hideloading();
                        this.$message({
                            showClose: true,
                            message: "Saved successfully!",
                            type: 'success'
                        });
                    })
                    .catch(err => {
                        this.hideloading();
                        this.$bus.$emit('error', err);
                    });
            });
        },

        validationwarning: function (success) {
            var msg = [];
            var colpriarraykeyvalue = this.colpriarraykeyvalue;
            if (this.form.reportname == "" || this.form.reportname == undefined) {
                msg.push("Report Name is required");
            }

            var colarr = [];
            var priarr = [];
            colpriarraykeyvalue.forEach(e => {
                var colltest = colarr.find(x => x == this.form[e.name + 'col']);
                if (!colltest) {
                    colarr.push(this.form[e.name + 'col'])
                } else {
                    var na1 = colltest + " is duplicate in Col Column";
                    var ismsgcol = msg.find(x => x == na1);
                    if (!ismsgcol) {
                        msg.push(na1);
                    }
                }
                var pritest = priarr.find(x => x == this.form[e + 'pri']);
                if (!pritest) {
                    priarr.push(this.form[e + 'pri'])
                } else {
                    var na = pritest + " is duplicate in Pri Column";
                    var ismsgpri = msg.find(x => x == na);
                    if (!ismsgpri) {
                        msg.push(na);
                    }
                }
            });
            if (msg.length > 0) {
                this.$alert(msg.join('<br />'), "Warning", { dangerouslyUseHTMLString: true, type: 'warning' });
            } else {
                success();
            }
        },

        tableHeaderColor({ row, column, rowIndex, columnIndex }) {
            if (rowIndex === 0) {
                return 'background-color: rgb(105, 104, 104);color: white;font-weight: bold;';
            }
        },

        sortFunction(a, b) {
            if (this.form[a.name + 'col'] > this.form[b.name + 'col']) return 1;
            if (this.form[a.name + 'col'] < this.form[b.name + 'col']) return -1;
            return 0;
        },

        runreport(row) {
            this.form = row;
            this.form.filter = this.searchbar;
            this.convertallpropertiesarraytostring(this.form);
            this.form.startdate = this.startdate;
            this.form.enddate = this.enddate;
            this.showloading();
            this.$http.post('Sectional/GetUniversalQAReport', this.form)
                .then(response => {
                    this.reports = response.data.uv;
                    this.page = 3;
                    this.form = response.data.form;
                    this.form.startdate = this.startdate;
                    this.form.enddate = this.enddate;
                    this.searchbar.count = this.form.filter.count;
                    this.columnarray = JSON.parse(JSON.stringify(this.colpriarraykeyvalue));
                    this.columnarray = this.columnarray.filter(x => this.form[x.name + 'col'] != undefined && this.form[x.name + 'col'] != 0);
                    this.columnarray.sort(this.sortFunction);
                    this.hideloading();
                })
                .catch(err => {
                    this.hideloading();
                    this.$bus.$emit('error', err);
                });
        },

        exportreport() {
            this.form.filter = this.searchbar;
            this.form.startdate = this.startdate;
            this.form.enddate = this.enddate;

            this.showloading();
            let url = "sectional/exportqareport";
            this.$http.post(url, this.form, { responseType: "blob" })
                .then(response => {
                    var blob = response.data;

                    let fileName = "QA report.xlsx";
                    if (this.form.reportname != null && this.form.reportname.trim() != "") {
                        fileName = `${this.form.reportname}.xlsx`;
                    }

                    var link = document.createElement("a");
                    link.href = window.URL.createObjectURL(blob);
                    link.download = fileName;
                    link.click();
                    this.hideloading();
                })
                .catch(err => {
                    this.hideloading();
                    this.$bus.$emit('error', err);
                });
        },

        selectproject: function (item) {
            this.searchbar.projectid = item.id;
            this.searchbar.project = item;

            this.searchbar.partid = null;
            this.searchbar.partname = null;
            this.searchbar.projectparts = [];

            this.searchbar.sectionid = null;
            this.searchbar.sectiondisplay = null;
            this.searchbar.partsections = [];
            this.searchbar.sections = [];
            if (item != "") {
                this.searchbar.projectid = item.id;
                this.getsections();
            }
            this.runreport(this.form);
        },

        getsections: function () {
            this.$http.post("project/getsections", this.searchbar.project)
                .then((response) => {
                    this.searchbar.partsections = response.data.projectsectionsview;
                    this.searchbar.projectparts = response.data.projectparts;
                    if (this.searchbar.projectparts.length == 0 && this.searchbar.project.projectname != "") {
                        this.searchbar.projectparts.unshift(this.defaultpart);
                    }
                }).catch((err) => {
                    this.$bus.$emit("error", err);
                });
        },

        changepart: function (event) {
            if (this.searchbar.projectid != undefined && this.searchbar.projectid > 0) {
                this.searchbar.sectionid = null;
                this.searchbar.sectiondisplay = null;
                this.searchbar.sections = [];
            }
            if (event !== "") {
                let p = this.searchbar.projectparts.find(pp => pp.id == event);
                if (p.name.includes(',')) {
                    this.searchbar.partname = p.name.replace(/,/g, '$');
                }
                else {
                    this.searchbar.partname = p.name;
                }
                if (event == 0) {
                    this.searchbar.partid = 0;
                    this.searchbar.sections = this.searchbar.partsections.filter(s => s.projectPartId == 0);
                } else {
                    this.searchbar.partid = event;
                    this.searchbar.sections = this.searchbar.partsections.filter(s => s.projectPartId == event);
                }
            }
            else {
                this.searchbar.partid = null;
                this.searchbar.partname = null;
            }
            this.runreport(this.form);
        },

        changesection: function (event) {
            if (event != "") {
                let s = this.searchbar.sections.find(x => x.id == event);
                this.searchbar.sectionid = s.id;
                let sectiondisplay = s.sectiondisplay;
                if (sectiondisplay.includes(',')) {
                    this.searchbar.sectiondisplay = sectiondisplay.replace(/,/g, '$');
                }
                else {
                    this.searchbar.sectiondisplay = sectiondisplay;
                }
            } else {
                this.searchbar.sectionid = null;
                this.searchbar.sectiondisplay = null;
            }
            this.runreport(this.form);
        },

        closeproject: function () {
            this.searchbar.project = { id: 0, projectname: "", library: "" };
            this.searchbar.projectid = null;

            this.searchbar.partid = null;
            this.searchbar.partname = null;

            this.searchbar.sectionid = null;
            this.searchbar.sectiondisplay = null;
            this.searchbar.sections = [];
            this.searchbar.partsections = [];
            this.searchbar.projectparts = [];
            this.runreport(this.form);
        },

        remotesearchsinglepart(query) {
            if (this.searchbar.projectid == undefined || this.searchbar.projectid <= 0) {
                this.$training.searchuniqueparts(query).then((data) => {
                    this.searchbar.projectparts = data;
                }).catch(err => {
                    this.$bus.$emit("error", err);
                });
            }
        },

        remotesearchsinglesections(query) {
            if (this.searchbar.projectid == undefined || this.searchbar.projectid <= 0) {
                if (query == "") {
                    this.searchbar.sections = this.mainsections;
                }
                else {
                    this.searchbar.sections = this.mainsections.filter(item => {
                        return item.sectiondisplay.toLowerCase().indexOf(query.toLowerCase()) > -1;
                    });
                }
            }
        },

        editedchanged(event, row, column) {
            let col = `isedited${column}`;
            row.svd[col] = true;
            if (column == 'solution') {
                const exist = this.solutions.find(x => x.id == event);
                row.solution = exist;
            }
        },

        externalcheckcolumn(column) {
            let iscurrentcol = this.modify[column];
            let ismodified = iscurrentcol;
            if (!iscurrentcol) {
                iscurrentcol = true;
            }
            if (iscurrentcol) {
                let col = `isedited${column}`;
                const currentedited = this.reports.filter(x => x.svd[col]).map(s => s.svd);
                if (currentedited.length > 0) {
                    let data = {
                        columnname: column,
                        details: currentedited
                    };
                    this.showloading();
                    this.$http.post('Sectional/updatecolumnusingqareport', data)
                        .then(response => {
                            this.reports.forEach(x => {
                                if (x.svd[col]) {
                                    x.svd[col] = false;
                                }
                            });

                            this.modify[column] = false;
                            this.hideloading();
                            this.$message({
                                type: 'success',
                                showClose: true,
                                message: response.data.message
                            });
                        })
                        .catch(err => {
                            this.hideloading();
                            this.modify[column] = true;
                            this.$bus.$emit('error', err);
                        });
                } else {
                    this.modify[column] = true;
                    if (ismodified) {
                        ismodified = false;
                        this.modify[column] = false;
                    }
                }
            }
        },

        changesearchbar(event, columnname) {
            if (event === "") {
                this.searchbar[columnname] = null;
            }
            this.runreport(this.form);
        },

        visiblepopover(event, row, column) {
            if (event === 1) {
                row['is' + column] = true;
                row[column] = null;

                this.searchbar = JSON.parse(JSON.stringify(row));
            }
            else {
                row['is' + column] = false;
                row[column] = event;
                if (this.page == 3) { // QA Report page
                    this.runreport(this.form);
                }
            }
        },

        mediadialogClose(done) {
            this.medialist = [];
            done();
        },

        DownloadMedia(row) {
            var link = document.createElement("a");
            link.href = row.link;
            link.download = row.name;
            link.click();
        },

        getMedia(row) {
            var newarray = [];
            row.forEach(e => {
                if (e.link.toLowerCase().includes(".mp4")) {
                    e.ext = 'mp4';
                    this.activeName = 'second';
                }
                if (e.link.toLowerCase().includes(".mp3")) {
                    e.ext = 'mp3';
                    this.activeName = 'third';
                }
                if (e.link.toLowerCase().includes(".jpg") || e.link.toLowerCase().includes(".jpeg") || e.link.toLowerCase().includes(".gif") || e.link.toLowerCase().includes(".png")) {
                    e.ext = 'img';
                    this.activeName = 'first';
                }
                var tts = newarray.find(x => x.id == e.id);
                if (!tts) {
                    newarray.push(e);
                }
            });
            this.medialist = newarray;
            this.mediadialogVisible = true;
        },

        linktoredirect(row) {
            let projectid = row.p.id;
            let partid = row.pr == null ? 0 : row.pr.id;
            let sectionid = row.ps.id;

            let params = new URLSearchParams();
            params.append('projectid', projectid);
            params.append('partid', partid);
            params.append('sectionid', sectionid);

            let url = '/sectionalcheck?' + params.toString();
            window.open(url, '_blank');
        },

        back() {
            if (this.page == 3 || this.page == 2) {
                this.page = 1;
                this.clearobjects();
                this.refresh();
            }
            else {
                this.$router.go(-1);
            }
        },

        clearobjects() {
            this.reports = [];
            this.form = {};

            this.multipleselect.allparts = [];
            this.multipleselect.displayparts = [];
            this.multipleselect.partsections = [];
            this.multipleselect.sections = [];

            this.searchbar.project = { projectname: "" };
            this.searchbar.projectparts = [];
            this.searchbar.partsections = [];
            this.searchbar.sections = [];
            this.searchbar.sectionid = null;
            this.searchbar.sectiondisplay = null;
        }
    },

    created() {
        let gridrow = this.$training.getusergridrow(this.$route);
        this.view.pagesize = gridrow.ugv.rowperpage;
        this.usergridrow = gridrow;
        this.getstatus().then((data) => {
            this.statusoptions = data;
            let solutionlist = this.statusoptions.filter(x => x.optiontype == 'solution');
            this.solutions = solutionlist.slice();
            this.solutionswithempty = solutionlist.slice();
            this.solutionswithempty.unshift(this.empty);
        });
        this.getusers();
        this.$training.getallprojects().then((data) => {
            this.multipleselect.projectoptions = data;
        });
        this.$training.getallsection().then((data) => {
            data.forEach(s => {
                Object.assign(s, { sectiondisplay: s.section });
            });
            this.mainsections = data;
        });
        this.refresh();
    },

    mounted: function () {
        window.addEventListener('resize', this.getheight);
    },

    destroyed() {
        window.removeEventListener('resize', this.getheight);
    },
}
</script>