<template>
    <div>
    <div class="rwcpagewidthheader">
        <div class="fixdheader">
            <el-row>
                <span class="headerstyle left">Required Wording Check (RWC)</span>
                <span class="left RWC-pagination-fixwidth">

                        <!-- <el-pagination class="center" layout="prev, pager, next"
                                       @current-change="pageCurrentChange"
                                       :page-size="pagesize"
                                        :total=total>
                        </el-pagination> -->

                        <el-pagination 
                            class="pagesizeswidth textalignlpage center"
                            @size-change="rowperpages"
                            @current-change="pageCurrentChange"
                            :current-page.sync="page"
                            :page-sizes="pagesizes"
                            :page-size="pagesize"
                            layout="sizes, prev, pager, next"
                            :total="total">
                        </el-pagination>
                </span>
                <span class="right">

                        <el-button 
                                   @click="cellchanged('library_rwclibrary_saverwc',()=>{save()},false)"
                                   :disabled="!editpermission"
                                   type="success">Save</el-button>
                        <el-button v-if="search.length==0"
                                   :disabled="!editpermission"
                                   icon="el-icon-circle-plus"
                                   class="icon-btn-width"
                                   @click="addRow(null, null)"
                                   type="primary"></el-button>
                                   </span>
            </el-row>
             <el-row>
                 <span class="left">
                      <div style="width:34px;display:inline-block">
                    <el-button :class="expandcollapsetext == '+' ? 'icon-expand-btn-width-pluse' : 'icon-expand-btn-width-minus'"  type="info" @click="expandcollapse">{{expandcollapsetext}}</el-button>
                </div>
                 </span>
            <span class="left marginl5 rwcinputsearch">
                <el-input v-model="search"
                          placeholder="Q   NRM Section, Search Name"
                          @keyup.enter.native="onSearch()"  :spellcheck="isspellcheck">
                </el-input>
            </span>
            <span class="left RWC-Library-fixwidth">

                <el-select v-model="searchlibrary" clearable  placeholder="Library">
                    <el-option
                        v-for="item in libraries" :disabled="!editpermission"
                            :key="item"
                            :label="item"
                            :value="item">
                    </el-option>
                </el-select>
            </span>
             <span class="left RWC-UR-fixwidth">
                <el-select v-model="searchlur" clearable  placeholder="UR">
                    <el-option
                        v-for="item in URfilter" :disabled="!editpermission"
                            :key="item.value"
                            :label="item.label"
                            :value="item.label">
                    </el-option>
                </el-select>
            </span>
             <span class="left RWC-Type-fixwidth">
                <el-select v-model="searchtypesearch" clearable  placeholder="Type">
                    <el-option
                        v-for="item in searchtypes" :disabled="!editpermission"
                            :key="item"
                            :label="item"
                            :value="item">
                    </el-option>
                </el-select>
            </span>
            </el-row>
            <hr class="hrstyle" />
        </div>
        </div>
        <div class="rwcpagewidth">
        <div class="tablepaddinglr3">
            <el-table :height="height" :data="getdetailform" :expand-row-keys="expandedrows" @expand-change="expandchange" row-key="id" size="mini"  default-expand-all>
                <el-table-column type="expand" width="30">
                    <template slot-scope="scope">
                        <el-row>
                            <span class="left RWC-thentextsearch-fixwidth" >
                                <span class="right" style="width: 139px" >
                                  <el-input :readonly="true" style="padding:5px 0px 0px;" :id="'library_rwcsearchlibrary_table_thenlocation_'+scope.$index" :value="getlocationname(scope.row,'then')" type="text" placeholder="Then Location" autosize @change="changed(scope.row)" :disabled="scope.row.disabled || !editpermission" :spellcheck="isspellcheck">
                                       <el-button @click="showIfThenFun(scope.row,'then')" :disabled="!editpermission" style="padding-top: 13px;padding-right: 7px;" slot="suffix" icon="el-icon-edit"  type="text" size="mini"> </el-button>
                                  </el-input>
                                  </span>
                            </span>
                            <span class="left RWC-thentext-fixwidth">
                               <el-input v-model="scope.row.thencharacters" :id="'library_rwcsearchlibrary_table_thencharacters_'+scope.$index" class="thenboxpadding" type="textarea" placeholder="Then Characters" autosize @change="changed(scope.row)" :disabled="scope.row.disabled || !editpermission" :spellcheck="isspellcheck">

                               </el-input>
                            </span>
                        </el-row>

                    </template>
                </el-table-column>
                <!--NRM Section-->
                <el-table-column prop="nrmsection" label="NRM1 Code" width="90">
                    <template slot-scope="scope">
                       <el-autocomplete size="mini" class="inline-input fullwidth" popper-class="morewidth-autocomplete"
                                             v-model="scope.row.nrmsection"
                                             :fetch-suggestions="querySearchbcc"
                                             placeholder=""
                                             :id="'library_rwcsearchlibrary_table_nrm1code_'+scope.$index"
                                             :spellcheck="isspellcheck"
                                             @select="handleSelectBCC(scope.row, $event)"
                                             :select-when-unmatched="true"
                                             clearable
                                             value-key="description"
                                             :trigger-on-focus="true">
                                <template slot-scope="{ item }">
                                    <div style="white-space:normal;">{{ item.description }}</div>
                                </template>
                            </el-autocomplete>
                    </template>
                </el-table-column>



                <!--label="Search Name"-->
                <el-table-column prop="description" label="RWC Name" width="270">
                    <template slot-scope="scope">
                        <el-input v-model="scope.row.description" :id="'library_rwcsearchlibrary_table_rwcname_'+scope.$index" @change="changed(scope.row)" :disabled="scope.row.disabled || !editpermission" :spellcheck="isspellcheck"></el-input>
                    </template>
                </el-table-column>
                   <el-table-column prop="Library"  label="Library"  width="80">
                <template slot-scope="cqc">
                        <!--<div class="flexlabel">CQC Name</div>-->
                        <div class="">
                            <!--<el-input v-model="cqc.row.Library" @change="changed(cqc.row)"></el-input>-->
                            <el-select v-model="cqc.row.library" :id="'library_rwcsearchlibrary_table_library_'+cqc.$index" @change="changed(cqc.row)" class="fullwidth" clearable  placeholder="Library">
                                <el-option
                                    v-for="item in libraries" :disabled="!editpermission"
                                        :key="item"
                                        :label="item"
                                        :value="item">
                                </el-option>
                            </el-select>
                        </div>
                </template>
            </el-table-column>
                <el-table-column prop="ifcharacters" label="If Characters" width="220">
                    <template slot-scope="scope">
                        <el-input id="validpattern" :id="'library_rwcsearchlibrary_table_ifcharacters_'+scope.$index" placeholder="If Characters" v-model="scope.row.ifcharacters" @input="changed(scope.row)" :disabled="scope.row.disabled  || !editpermission" :spellcheck="isspellcheck"></el-input>
                        <div class="alert-danger" v-if="scope.row.show">
                            <strong>Invalid Regular Expression.</strong>
                            <!-- <strong>Invalid Regular Expression : {{scope.row.ifcharacters}} </strong> -->
                        </div>
                    </template>
                </el-table-column>
                 <el-table-column prop="ifcharacters" label="If Location" width="150">
                    <template slot-scope="scope">
                        <el-input placeholder="If Location" :readonly="true" id="validpattern" :id="'library_rwcsearchlibrary_table_iflocation_'+scope.$index" :value="getlocationname(scope.row,'if')" @input="changed(scope.row)" :disabled="scope.row.disabled  || !editpermission" :spellcheck="isspellcheck">
                             <el-button @click="showIfThenFun(scope.row,'if')" :disabled="!editpermission" style="padding-top: 8px;" slot="suffix" icon="el-icon-edit"  type="text" size="mini"> </el-button>
                        </el-input>

                    </template>
                </el-table-column>

                 <el-table-column prop="ur" label="UR(S)" width="70">
                    <template slot-scope="scope">
                        <el-select v-model="scope.row.ur" :id="'library_rwcsearchlibrary_table_ur_'+scope.$index" @change="changed(scope.row);" clearable remote  :remote-method="getURsRemote" :disabled="!editpermission" reserve-keyword filterable  placeholder="UR(S)">
                           <el-option
                             v-for="(item, index) in $store.state.URs"
                             :key="index"
                             :label="item.ur"
                             :value="item.ur">
                             <!-- <div>
                                 <div class="left">
                                 {{item.ur}}
                                 </div>
                                 <div v-if="item.id != 0 " class="right ML13">
                                 <i style="color:#67C23A" class="el-icon-circle-check"></i>
                                 </div>
                             </div> -->
                           </el-option>
                         </el-select>
                          <!-- <el-autocomplete size="mini"  class="inline-input fullwidth"
                                             v-model="scope.row.ur"
                                             :fetch-suggestions="(query,done)=>{searchur(query,done,'all')}"
                                             placeholder="UR(S)"
                                             :spellcheck="isspellcheck"
                                             @select="handleSelectur(scope.row,'ur', $event)"
                                             :select-when-unmatched="true"
                                             clearable
                                             value-key="id"
                                             :trigger-on-focus="true">
                                <template slot-scope="{ item }">
                                    <div class="left">
                                     {{item.ur}}
                                     </div>
                                     <div v-if="item.id != 0 " class="right ML13">
                                     <i style="color:#67C23A" class="el-icon-circle-check"></i>
                                     </div>
                                </template>
                            </el-autocomplete> -->
                    </template>
                </el-table-column>
                <el-table-column prop="searchtype" label="Search Type" width="80">
                    <template slot-scope="scope">
                        <el-select v-model="scope.row.searchtype" :id="'library_rwcsearchlibrary_table_searchtype_'+scope.$index" @change="changed(scope.row)" :disabled="scope.row.disabled">
                            <el-option v-for="item in searchtypes" :disabled="!editpermission"
                                       :key="item"
                                       :label="item"
                                       :value="item">
                            </el-option>
                        </el-select>
                    </template>
                </el-table-column>

                <el-table-column prop="owner.loginname" label="Owner" align="center" width="65">
                </el-table-column>

                <el-table-column label="Actions" width="80" align="center">
                    <template slot-scope="scope">

                        <el-button size="mini" class="paddingleftright7 paddingtopbottim6" type="primary" icon="glyphicon glyphicon-duplicate"
                                   @click="addRow(scope.$index, scope.row)" :disabled="scope.row.disabled || !editpermission"></el-button>

                                                           <el-button size="mini" class="icon-btn-width " type="danger" icon="el-icon-delete"
                                   @click="delRow(scope.$index, scope.row)" :disabled="scope.row.disabled || !editpermission"></el-button>

                    </template>
                </el-table-column>
                <!-- <el-table-column label="Delete" width="45" align="center">
                    <template slot-scope="scope">
                        <el-button size="mini" class="icon-btn-width" type="danger" icon="el-icon-delete"
                                   @click="delRow(scope.$index, scope.row)" :disabled="scope.row.disabled || !editpermission"></el-button>
                    </template>
                </el-table-column> -->
            </el-table>
        </div>
        </div>
        <!--<el-row>
            <el-col class="text-right" style="padding-right:390px">
                <el-button :disabled="!editpermission" v-if="search.length==0" icon="el-icon-circle-plus" type="primary" @click="addRow(null, null)">Add</el-button>
            </el-col>

            <el-col abel="">
                <el-button @click="save()" :disabled="!editpermission" type="success" icon="el-icon-circle-check">Save</el-button>
            </el-col>
        </el-row>-->
          <el-dialog v-if="ifobj != null" title="If Search Location"
                   :visible.sync="showif"
                   :close-on-press-escape="false"
                   :close-on-click-modal="false"
                   :modalAppendToBody="false"
                   top="10vh" width="510px" class="titlefontchange chngdialgpadding changedialgheader">
            <el-form label-width="100px" class="formitmmarginbtm8px">
                <el-form-item label="RWC Name">
                    <el-input :readonly="true" :id="'library_rwcsearchlibrary_ifsearchlocation_rwcname'" :disabled="!editpermission" v-model="ifobj.description" :spellcheck="isspellcheck"></el-input>
                </el-form-item>
                <el-row class="borderbottom marginbottom10px">
                        <el-col class="width330px">
                             <el-form-item label="Search In" >
                                 <span class="bold">NRM Section</span>
                                  <el-select clearable :id="'library_rwcsearchlibrary_ifsearchlocation_nrmsection'" @change="changenrmsection(ifobj,'ifnrmsectionSearchIn','ifNRMcode','ifnrmsectionUR')" v-model="ifobj.ifnrmsectionSearchIn" :disabled="!editpermission" placeholder="Select" class="width70px marginleft8px">
                                  <el-option
                                    v-for="item in sections"
                                    :key="item.id"
                                    :label="item.sectionlabel"
                                    :value="item.sectionvalue">
                                  </el-option>
                                 </el-select>
                                 <span class="bold marginleft12px" style="color: #c53fc7;">~OR~</span>
                            </el-form-item >
                        </el-col>
                        <!-- <el-col :span="8" style="text-align:center">Or

                        </el-col> -->


                        <el-col :span="5" >
                            <div class="left marginleft12px">
                            <el-form-item label="UR(D)" label-width="55px" >
                                  <!-- <el-autocomplete size="mini" class="inline-input width70px"
                                             v-model="ifobj.ifnrmsectionUR"
                                             :fetch-suggestions="(query,done)=>{searchur(query,done,ifobj,'ifnrmsectionUR')}"
                                             placeholder=""
                                             :spellcheck="isspellcheck"
                                             @select="handleSelectur(ifobj,'ifnrmsectionUR', $event,'ifnrmsectionSearchIn')"
                                             :select-when-unmatched="true"
                                              @change="changeurd(ifobj,'ifnrmsectionUR', $event,'ifnrmsectionSearchIn')"
                                             clearable
                                             value-key="id"
                                             :trigger-on-focus="true">
                                <template slot-scope="{ item }">
                                    <div >
                                     {{item.ur}}
                                     </div>

                                </template>
                            </el-autocomplete> -->

                             <el-select class="inline-input width70px"
                             v-model="ifobj.ifnrmsectionUR"
                             clearable remote
                             :id="'library_rwcsearchlibrary_ifsearchlocation_ur'"
                             @change="handleSelectur(ifobj,'ifnrmsectionUR', $event,'ifnrmsectionSearchIn')"
                             :remote-method="searchur"
                             :disabled="!editpermission"
                             reserve-keyword filterable
                             placeholder="UR(D)">
                           <el-option
                             v-for="(item, index) in $store.state.URd"
                             :key="index"
                             :label="item.ur"
                             :value="item.ur">
                             <!-- <div>
                                 <div class="left">
                                 {{item.ur}}
                                 </div>
                                 <div v-if="item.id != 0 " class="right ML13">
                                 <i style="color:#67C23A" class="el-icon-circle-check"></i>
                                 </div>
                             </div> -->
                           </el-option>
                         </el-select>
                            </el-form-item>
                            </div>
                        </el-col>


                </el-row>
                <span class="bold marginleft102px">NRM Section</span>
                <el-form-item label="NRM1 Code" >
                    <div>
                        <span class="left padright5px" >
                             <el-checkbox id="library_rwcsearchlibrary_ifsearchlocation_checkbox_ifisnrmcode" v-model="ifobj.ifisNRMcode"></el-checkbox>
                        </span>
                        <span class="left width94pr" >
                       <el-autocomplete size="mini" class="inline-input fullwidth" popper-class="morewidth-autocomplete"
                                             v-model="ifobj.ifNRMcode"
                                             :fetch-suggestions="querySearchbcc"
                                             placeholder=""
                                             :id="'library_rwcsearchlibrary_ifsearchlocation_nrm1code'"
                                             :spellcheck="isspellcheck"
                                             @select="handleSelectBCCifthen(ifobj,'ifNRMcode', $event)"
                                             :select-when-unmatched="true"
                                             clearable
                                             value-key="description"
                                             :trigger-on-focus="true">
                                <template slot-scope="{ item }">
                                    <div style="white-space:normal;">{{ item.description }}</div>
                                </template>
                            </el-autocomplete>
                      </span>
                    </div>
                </el-form-item>
                <el-form-item label="MOM Section" label-width="101px" >
                      <div>
                        <span class="left padright5px" >
                             <el-checkbox id="library_rwcsearchlibrary_ifsearchlocation_checkbox_ifismomsection" v-model="ifobj.ifisMOMsection"></el-checkbox>
                        </span>
                        <span class="left width94pr" >
                            <el-autocomplete size="mini" class="inline-input fullwidth" popper-class="morewidth-autocomplete"
                                             v-model="ifobj.ifMOMsection"
                                             :fetch-suggestions="(query,done)=>{querySearchMOMsection(query,ifobj,done)}"
                                             placeholder=""
                                             :id="'library_rwcsearchlibrary_ifsearchlocation_momsection'"
                                             :spellcheck="isspellcheck"
                                             @select="handleSelectMOMifthen(ifobj,'ifMOMsection', $event)"
                                             :select-when-unmatched="true"
                                             clearable
                                             value-key="section"
                                             :trigger-on-focus="true">
                                <template slot-scope="{ item }">
                                    <div style="white-space:normal;">{{ item.section }}</div>
                                </template>
                            </el-autocomplete>
                        </span>
                      </div>
                </el-form-item>

                <el-form-item label="Group">
                     <div>
                        <span class="left padright5px" >
                             <el-checkbox id="library_rwcsearchlibrary_ifsearchlocation_checkbox_ifisgroup" v-model="ifobj.ifisgroup"></el-checkbox>
                        </span>
                        <span class="left width94pr" >
                          <!-- <el-input :disabled="!editpermission" v-model="ifobj.ifgroup"  :spellcheck="isspellcheck"></el-input> -->
                           <el-autocomplete size="mini" class="inline-input fullwidth" popper-class="morewidth-autocomplete"
                                             v-model="ifobj.ifgroup"
                                             :fetch-suggestions="(query,done)=>{querySearchgroup(query,done,ifobj.ifMOMsection)}"
                                             placeholder=""
                                             :id="'library_rwcsearchlibrary_ifsearchlocation_group'"
                                             :spellcheck="isspellcheck"
                                             @select="handleSelectgroup(ifobj,'ifgroup', $event)"
                                             :select-when-unmatched="true"
                                             clearable
                                             value-key="group"
                                             :trigger-on-focus="true">
                                <template slot-scope="{ item }">
                                    <div style="white-space:normal;">{{ item.group }}</div>
                                </template>
                            </el-autocomplete>
                        </span>
                     </div>
                </el-form-item>

                <el-form-item label="Work">
                     <el-checkbox id="library_rwcsearchlibrary_ifsearchlocation_checkbox_ifiswork" v-model="ifobj.ifiswork"></el-checkbox>
                </el-form-item>

                <el-form-item label="Level 1">
                     <el-checkbox id="library_rwcsearchlibrary_ifsearchlocation_checkbox_ifislevel1" v-model="ifobj.ifislevel1"></el-checkbox>
                </el-form-item>

                <el-form-item label="Level 2">
                     <el-checkbox id="library_rwcsearchlibrary_ifsearchlocation_checkbox_ifislevel2" v-model="ifobj.ifislevel2"></el-checkbox>
                </el-form-item>

                <el-form-item label="Level 3">
                     <el-checkbox id="library_rwcsearchlibrary_ifsearchlocation_checkbox_ifislevel3" v-model="ifobj.ifislevel3"></el-checkbox>
                </el-form-item>

                <el-form-item label="Unit">
                     <el-checkbox id="library_rwcsearchlibrary_ifsearchlocation_checkbox_ifisunit" v-model="ifobj.ifisunit"></el-checkbox>
                </el-form-item>
                <div class="borderbottom marginbottom10px"></div>
                <span class="bold marginleft102px">Unique Reference UR</span>
                <el-form-item label="Description">
                    <el-checkbox id="library_rwcsearchlibrary_ifsearchlocation_checkbox_ifisurdescription" v-model="ifobj.ifisURdescription"></el-checkbox>
                </el-form-item>

                <el-form-item label="Unit">
                   <el-checkbox id="library_rwcsearchlibrary_ifsearchlocation_checkbox_ifisurqty" v-model="ifobj.ifisURqty"></el-checkbox>
                </el-form-item>

                <el-form-item label="">

                        <el-button title="Bill Match" @click="BillMatch(ifobj,'if','If')" type="primary" class="padding7px14px">Bill Match</el-button>

                        <el-button :disabled="!editpermission" icon="el-icon-circle-check" title="Save" type="success"
                         @click="cellchanged('library_rwclibrary_save_iflocation',()=>{saveifthen(ifobj,'if','If')},false)" class="padding7px14px">Save</el-button>

                </el-form-item>
            </el-form>
        </el-dialog>
          <el-dialog v-if="thenobj != null" title="Then Search Location"
                   :visible.sync="showthen"
                   :close-on-press-escape="false"
                   :close-on-click-modal="false"
                   :modalAppendToBody="false"
                   top="10vh" width="510px" class="titlefontchange chngdialgpadding changedialgheader">
            <el-form label-width="100px" class="formitmmarginbtm8px">
                <el-form-item label="RWC Name">
                    <el-input :readonly="true" :id="'library_rwcsearchlibrary_thensearchlocation_rwcname'" :disabled="!editpermission" v-model="thenobj.description" :spellcheck="isspellcheck"></el-input>
                </el-form-item>
                <el-row class="borderbottom marginbottom10px">

                        <el-col class="width330px">
                             <el-form-item label="Search In" >
                                 <span class="bold">NRM Section</span>
                                  <el-select clearable :id="'library_rwcsearchlibrary_thensearchlocation_nrmsection'" @change="changenrmsection(thenobj,'thennrmsectionSearchIn','thenNRMcode','thennrmsectionUR')" v-model="thenobj.thennrmsectionSearchIn" :disabled="!editpermission" placeholder="Select" class="width70px marginleft8px">
                                  <el-option
                                    v-for="item in sections"
                                    :key="item.id"
                                    :label="item.sectionlabel"
                                    :value="item.sectionvalue">
                                  </el-option>
                                 </el-select>
                                 <span class="bold marginleft12px" style="color: #c53fc7;">~OR~</span>
                            </el-form-item >
                        </el-col>
                        <el-col :span="5" >
                            <div class="left marginleft12px">
                            <el-form-item label="UR(D)" label-width="55px" >
                                  <!-- <el-autocomplete size="mini" class="inline-input width70px"
                                             v-model="thenobj.thennrmsectionUR"
                                             :fetch-suggestions="(query,done)=>{searchur(query,done,thenobj,'thennrmsectionUR')}"
                                             placeholder=""
                                             :spellcheck="isspellcheck"
                                             @select="handleSelectur(thenobj,'thennrmsectionUR', $event,'thennrmsectionSearchIn')"
                                             :select-when-unmatched="true"
                                             clearable
                                             @change="changeurd(thenobj,'thennrmsectionUR', $event,'thennrmsectionSearchIn')"
                                             value-key="id"
                                             :trigger-on-focus="true">
                                <template slot-scope="{ item }">
                                    <div>
                                     {{item.ur}}
                                     </div>
                                </template>
                            </el-autocomplete> -->
                             <el-select class="inline-input width70px"
                             v-model="thenobj.thennrmsectionUR"
                             clearable remote
                             :id="'library_rwcsearchlibrary_thensearchlocation_ur'"
                             @change="handleSelectur(thenobj,'thennrmsectionUR', $event,'thennrmsectionSearchIn')"
                             :remote-method="searchur"
                             :disabled="!editpermission"
                             reserve-keyword filterable
                             placeholder="UR(D)">
                           <el-option
                             v-for="(item, index) in $store.state.URd"
                             :key="index"
                             :label="item.ur"
                             :value="item.ur">
                             <!-- <div>
                                 <div class="left">
                                 {{item.ur}}
                                 </div>
                                 <div v-if="item.id != 0 " class="right ML13">
                                 <i style="color:#67C23A" class="el-icon-circle-check"></i>
                                 </div>
                             </div> -->
                           </el-option>
                         </el-select>
                            </el-form-item>
                            </div>
                        </el-col>


                </el-row>
                <span class="bold marginleft102px">NRM Section</span>
                <el-form-item label="NRM1 Code" >
                    <div>
                        <span class="left padright5px" >
                             <el-checkbox id="library_rwcsearchlibrary_thensearchlocation_checkbox_thenisnrmcode" v-model="thenobj.thenisNRMcode"></el-checkbox>
                        </span>
                        <span class="left width94pr" >
                       <el-autocomplete size="mini" class="inline-input fullwidth" popper-class="morewidth-autocomplete"
                                             v-model="thenobj.thenNRMcode"
                                             :fetch-suggestions="querySearchbcc"
                                             placeholder=""
                                             :id="'library_rwcsearchlibrary_thensearchlocation_nrm1code'"
                                             :spellcheck="isspellcheck"
                                             @select="handleSelectBCCifthen(thenobj,'thenNRMcode', $event)"
                                             :select-when-unmatched="true"
                                             clearable
                                             value-key="description"
                                             :trigger-on-focus="true">
                                <template slot-scope="{ item }">
                                    <div style="white-space:normal;">{{ item.description }}</div>
                                </template>
                            </el-autocomplete>
                      </span>
                    </div>
                </el-form-item>
                <el-form-item label="MOM Section" label-width="101px" >
                      <div>
                        <span class="left padright5px" >
                             <el-checkbox id="library_rwcsearchlibrary_thensearchlocation_checkbox_thenismomsection" v-model="thenobj.thenisMOMsection"></el-checkbox>
                        </span>
                        <span class="left width94pr" >
                           <!-- <el-input :disabled="!editpermission" v-model="thenobj.thenMOMsection" :spellcheck="isspellcheck"></el-input> -->
                                 <el-autocomplete size="mini" class="inline-input fullwidth" popper-class="morewidth-autocomplete"
                                             v-model="thenobj.thenMOMsection"
                                             :fetch-suggestions="(query,done)=>{querySearchMOMsection(query,thenobj,done)}"
                                             placeholder=""
                                             :id="'library_rwcsearchlibrary_thensearchlocation_momsection'"
                                             :spellcheck="isspellcheck"
                                             @select="handleSelectMOMifthen(thenobj,'thenMOMsection', $event)"
                                             :select-when-unmatched="true"
                                             clearable
                                             value-key="section"
                                             :trigger-on-focus="true">
                                <template slot-scope="{ item }">
                                    <div style="white-space:normal;">{{ item.section }}</div>
                                </template>
                            </el-autocomplete>
                        </span>
                      </div>
                </el-form-item>

                <el-form-item label="Group">
                     <div>
                        <span class="left padright5px" >
                             <el-checkbox id="library_rwcsearchlibrary_thensearchlocation_checkbox_thenisgroup" v-model="thenobj.thenisgroup"></el-checkbox>
                        </span>
                        <span class="left width94pr" >
                          <!-- <el-input :disabled="!editpermission" v-model="thenobj.thengroup"  :spellcheck="isspellcheck"></el-input> -->
                           <el-autocomplete size="mini" class="inline-input fullwidth" popper-class="morewidth-autocomplete"
                                             v-model="thenobj.thengroup"
                                             :fetch-suggestions="(query,done)=>{querySearchgroup(query,done,thenobj.thenMOMsection)}"
                                             placeholder=""
                                             :id="'library_rwcsearchlibrary_thensearchlocation_group'"
                                             :spellcheck="isspellcheck"
                                             @select="handleSelectgroup(thenobj,'thengroup', $event)"
                                             :select-when-unmatched="true"
                                             clearable
                                             value-key="group"
                                             :trigger-on-focus="true">
                                <template slot-scope="{ item }">
                                    <div style="white-space:normal;">{{ item.group }}</div>
                                </template>
                            </el-autocomplete>

                        </span>
                     </div>
                </el-form-item>

                <el-form-item label="Work">
                     <el-checkbox id="library_rwcsearchlibrary_thensearchlocation_checkbox_theniswork" v-model="thenobj.theniswork"></el-checkbox>
                </el-form-item>

                <el-form-item label="Level 1">

                     <el-checkbox id="library_rwcsearchlibrary_thensearchlocation_checkbox_thenislevel1" v-model="thenobj.thenislevel1"></el-checkbox>
                </el-form-item>

                <el-form-item label="Level 2">
                     <el-checkbox id="library_rwcsearchlibrary_thensearchlocation_checkbox_thenislevel2" v-model="thenobj.thenislevel2"></el-checkbox>
                </el-form-item>

                <el-form-item label="Level 3">
                     <el-checkbox id="library_rwcsearchlibrary_thensearchlocation_checkbox_thenislevel3" v-model="thenobj.thenislevel3"></el-checkbox>
                </el-form-item>

                <el-form-item label="Unit">
                     <el-checkbox id="library_rwcsearchlibrary_thensearchlocation_checkbox_thenisunit" v-model="thenobj.thenisunit"></el-checkbox>
                </el-form-item>
                <div class="borderbottom marginbottom10px"></div>
                <span class="bold marginleft102px">Unique Reference UR</span>
                <el-form-item label="Description">
                    <el-checkbox id="library_rwcsearchlibrary_thensearchlocation_checkbox_thenisurdescription" v-model="thenobj.thenisURdescription"></el-checkbox>
                </el-form-item>

                <el-form-item label="Unit">
                   <el-checkbox id="library_rwcsearchlibrary_thensearchlocation_checkbox_thenisurqty" v-model="thenobj.thenisURqty"></el-checkbox>
                </el-form-item>

                <el-form-item label="">

                        <el-button title="Bill Match" @click="BillMatch(thenobj,'then','Then')" type="primary" class="padding7px14px">Bill Match</el-button>

                        <el-button :disabled="!editpermission" icon="el-icon-circle-check" title="Save" type="success" @click="cellchanged('library_rwclibrary_save_thenlocation',()=>{saveifthen(thenobj,'then','Then')},false)" class="padding7px14px">Save</el-button>

                </el-form-item>
            </el-form>
        </el-dialog>
          <el-dialog title="Create a Bill (Section) Match Report"
                   :visible.sync="showmatchreportfor"
                   :close-on-press-escape="false"
                   :close-on-click-modal="false"
                   :modalAppendToBody="false"
                   top="23vh" width="510px" class="titlefontchange chngdialgpadding-billmatch changedialgheader">

                       <div style="">Report all other Section Displays that <b>MATCH</b> (Yes) to <b>ALL</b> of the RWC </div>
                       <div>search criteria (selected) for:</div>
                   <el-row style="padding-left: 37%;">
                    <el-radio-group @change="getBillMatchReport()" v-model="matchreportfor">
                         <el-col><el-radio :label="'If'">If</el-radio></el-col>
                        <el-col><el-radio :label="'Then'">Then</el-radio></el-col>
                        <el-col><el-radio :label="'If and Then'">If and Then</el-radio></el-col>
                     </el-radio-group>
                     </el-row>
          </el-dialog>
           <el-dialog title="Bill (Sections) Match"
                   :visible.sync="billmatchprojectshow"
                   :close-on-press-escape="false"
                   :close-on-click-modal="false"
                   :modalAppendToBody="false"
                   top="10vh" width="850px" class="titlefontchange chngdialgpadding-billmatch changedialgheader-billmatch">
                             <el-pagination class="center" layout="prev, pager, next"
                                       @current-change="pageMPCurrentChange"
                                       :page-size="pagesizeMP"
                                        :total=totalMP>
                        </el-pagination>
                        <div  >
                        <el-table
                             class="tableBox tableBox-cell"
                             :border="true"
                             :header-cell-style="headerrowstyleborder"
                             :data="getbillmatchproject"
                             style="width: 100%">
                             <el-table-column
                               prop="projectname"
                               label="Project "
                               >
                             </el-table-column>
                             <el-table-column
                               prop="projectpart.name"
                               label="Project Part"
                               width="150"
                               >
                             </el-table-column>
                             <el-table-column
                               prop="section.sectiondisplay"
                               label="Section Display">
                                <template slot-scope="scope">
                                    <div style="color:#409EFF;text-decoration: underline;" @click="pushToBilPage(scope.row)">{{ scope.row.section.sectiondisplay }}</div>
                                </template>
                             </el-table-column>
                           </el-table>
                           </div>
          </el-dialog>

</div>
</template>

<script>
    export default {
        data() {
            return {
                billmatchprojectshow:false,
                billmatchproject:[],
                showmatchreportfor:false,
                matchreportfor:'',
                 sections:[],
                thenobj:{},
                ifobj:{},
                showif:false,
                showthen:false,
                 expandedrows: [],
                expandcollapsetext: "+",
                searchlibrary:'',
                searchlur:'',
                searchtypesearch:'',
                URfilter: [
                    { 'label': 'All', 'value': 'All' },
                    { 'label': 'URonly', 'value': 'URonly' },
                ],
                libraries: this.$store.state.libraries,
                height: (document.documentElement.clientHeight -130),
                pagesizes: this.$store.state.pagesizes,
                show:false,
                search: '',
                user: this.$store.state.user,
                detailform: [],
                searchtypes: ['Required', 'Expected', 'Testing','Generic'],
                loading: false,
                isValidPattern: false,
                page: 1,
                pagesize: 10,
                total: 0,
                totalMP:0,
                pageMP:1,
                pagesizeMP: 20,
            }
        },

        computed: {
            getbillmatchproject(){
                if(this.billmatchproject.length == 0){
                    return [];
                }
                this.totalMP = this.billmatchproject.length;
                    if (this.totalMP == 0) {
                        return [];
                    }
                    if (!(Math.ceil(this.totalMP / this.pagesizeMP) >= this.pageMP)) {
                        this.pageMP = Math.ceil(this.totalMP / this.pagesizeMP);
                    }
                    return this.billmatchproject.slice(this.pagesizeMP * this.pageMP - this.pagesizeMP, this.pagesizeMP * this.pageMP);

            },
            getlocationname(){
                return function(data,name){
                    var str = "";
                    if(data[name+'nrmsectionSearchIn']){
                        str += data[name+'nrmsectionSearchIn'] +" - ";
                    }
                    if(data[name+'nrmsectionUR']){
                        str += data[name+'nrmsectionUR'];
                    }
                    return str;
                }
            },
            getdetailform: function () {
                if (this.detailform.length == 0) {
                    return [];
                }
                //if (this.search) {
                    var search = this.search.toLowerCase();
                    var data = this.detailform.filter((c) => {
                        var include = true;
                        if(this.search){
                             this.search.toLowerCase().split(",").forEach((search) => {
                                 var include1 = false;
                                 search = search.trim();
                                 if (!include1 && c.description)
                                     include1 = include1 || c.description.toLowerCase().includes(search);
                                 if (!include1 && c.nrmsection)
                                     include1 = include1 || c.nrmsection.toLowerCase().includes(search);

                                 include = include && include1;
                             });
                        }
                        if(this.searchlibrary && include){
                            include = this.searchlibrary == c.library ;
                        }
                         if(this.searchlur == "URonly" && include){
                            include = c.ur ? true : false;
                        }
                          if(this.searchtypesearch && include){
                            include = this.searchtypesearch == c.searchtype;
                        }
                        return include;
                    });
                     if (this.expandedrows.length == 0) {
                            this.expandedrows.push({});
                            this.expandcollapse();
                        }
                    this.total = data.length;
                    if (this.total == 0) {
                        return [];
                    }
                    if (!(Math.ceil(this.total / this.pagesize) >= this.page)) {
                        this.page = Math.ceil(this.total / this.pagesize);
                    }
                    return data.slice(this.pagesize * this.page - this.pagesize, this.pagesize * this.page);
                    //return data;
                //} else {
                //    return this.detailform;
                //}
            }
        },
         destroyed() {
            window.removeEventListener('resize', this.getheight);
        },
         mounted() {
            window.addEventListener('resize', this.getheight);
        },
        methods: {
            cellchanged(cellaction, callback, cancall) {
                const app = this;
                this.$directionutility.setcomponent(app);
                if (cancall) {
                    callback();
                    this.canmatchcellid(() => { }, cellaction, "");
                } else {
                    this.canmatchcellid(callback, cellaction, "");
                }
            },
            changenrmsection(event,search,code,ur){

                if(event[search] != ""){
                      this.$confirm('Are you sure you want to set the NRM Section?', 'Warning', {
                           confirmButtonText: 'OK',
                           cancelButtonText: 'Cancel',
                           type: 'warning'
                         }).then(() => {
                           this.$message({
                             type: 'success',
                             message: 'NRM Section is Set'
                           });
                             event[ur] = "";
                             if(event[code] == "" && event[search] != "")
                             {
                                 event[code] = event[search];
                             }
                         }).catch(() => {
                           this.$message({
                             type: 'info',
                             message: 'NRM Section is not Set'
                           });
                           event[search] = "";
                         });

                }
            },
            headerrowstyleborder({ row, column, rowIndex, columnIndex }) {
                if (rowIndex == 0) {
                    return { "color": "black", "background-color": "#BBBBBB", "border-right": "1px solid #878787" }
                }
            },
            pushToBilPage(data){
                var partid = data.projectpart.id == null ? 0 : data.projectpart.id;
                this.$store.state.projectids = {projectid:data.id,partid:partid,sectionid:data.section.id};
               var data1 = this.$store.state.projectids;
             this.$bus.$emit("addproject", data1);
             this.$router.push("/projectbill");
            },
            getBillMatchReport(){
                this.showloading()
                this.showmatchreportfor = false;
                var data = {};
                var ifthen = "";
                if(this.showif == true){
                    data = this.ifobj;
                    ifthen = 'If';
                }
                if (this.showthen == true) {
                    data = this.thenobj;
                    ifthen = 'Then';
                }
                var cv ={};
                cv.comparesearch = data;
                cv.ifthen = ifthen;
                cv.ifthenreport = this.matchreportfor;
                this.$http.post('project/RWCBillMatch', cv)
                    .then(response => {

                          this.pageMP = 1;
                          this.totalMP = 0;
                        this.billmatchproject = response.data;
                      this.billmatchprojectshow = true;
                      this.matchreportfor = "";
                      this.hideloading()
                    })
                    .catch(err => {
                        this.hideloading();
                        this.matchreportfor = "";
                        this.$bus.$emit('error', err);
                    })

            },
            saveifthen(data,name,header){
                if(!data[name+'nrmsectionSearchIn'] && !data[name+'nrmsectionUR'])
                {
                     this.$message({
                        showClose: true,
                        type: 'info',
                        message: header+' NRM Section Or '+ header+ ' UR(D)' +' is required'
                    });
                    return
                }
                 if(data[name+'nrmsectionSearchIn'] !== "" && data[name+'nrmsectionUR'] !== "")
                {
                     this.$message({
                        showClose: true,
                        type: 'info',
                        message:"Please select only one of NRM Section or UR(D) in Search In"
                    });
                    return
                }
                 this.$http.post('comparesearch/saveifthen', data)
                        .then(response => {
                            this.$message({
                                showClose: true,
                                message: header + ' Search Location save Successful!', type: "success"
                            });
                            this.refresh();
                            this["show"+name] = false;
                        })
                        .catch(err => {
                            this.$bus.$emit('error', err);
                        });
            },
            querySearchgroup(query, done,section){
                 if (!query){
                    query = "";
                 }
                 if(!section){
                     section = "";
                     }
                     var data = {q:query,s:section}
                this.$http.post('comparesearch/searchsectiongroup',data)
                    .then(response => {
                        this.MOMsection = response.data;
                        done(this.MOMsection)
                    })
                    .catch(err => {
                        this.$bus.$emit('error', err);
                    })

            },
             querySearchMOMsection(query,obj, done){


                 if (query == null){
                    query = '';
                 }
                if(obj.library == null)
                {
                    obj.library = "";
                }

                 var data = {q:query,s:obj.library}
                this.$http.post('comparesearch/searchMOMsection', data)
                    .then(response => {
                        this.MOMsection = response.data;
                        done(this.MOMsection)
                    })
                    .catch(err => {
                        this.$bus.$emit('error', err);
                    })

            },
             getallsection(){
                this.$http.get('project/GetSectionsProjectCreate')
                        .then(response => {
                            this.sections = response.data;
                            this.getnumberonsection();
                        }).catch(err => {
                                this.$bus.$emit('error', err);
                            });
            },
              getnumberonsection(){
                  this.sections.forEach(x => {
                                var sectionno =  x.section.match(/\d+\.*\d+/g);
                                var value = sectionno[0];
                                 var beforPoint = value.split(".")[0].padStart(2, '0');
                                 var afterPoint = value.split(".")[1].padStart(2, '0');
                                x.sectionlabel = beforPoint  +"."+ afterPoint;
                                x.sectionvalue = value;
                            });
            },
            showIfThenFun(data,name){
                var sname = 'show'+name;
                var objname = name + "obj";
                this[objname] = {};
                this[objname] = data;
                this[sname] = true;

            },
            BillMatch(data,name,header){
                 if(!data.description)
                {
                     this.$message({
                        showClose: true,
                        type: 'info',
                        message: 'RWC Name is required'
                    });
                    return
                }
                if(!data[name+'nrmsectionSearchIn'] && !data[name+'nrmsectionUR'])
                {
                     this.$message({
                        showClose: true,
                        type: 'info',
                        message: header+' NRM Section Or '+ header+ ' UR(D)' +' is required'
                    });
                    return
                }
                if(data[name+'nrmsectionSearchIn'] !== "" && data[name+'nrmsectionUR'] !== "")
                {
                     this.$message({
                        showClose: true,
                        type: 'info',
                        message:"Please select only one of NRM Section or UR(D) in Search In"
                    });
                    return
                }
                this.showmatchreportfor = true;
            },
            expandchange: function (row, expandedRows) {
               var expanded = expandedRows.map((v) => { return v.id });
               this.expandedrows = expanded;
               var bigdash = new Option()
               bigdash.innerHTML = "&mdash;"
               this.expandcollapsetext = this.expandedrows.length ? bigdash.text : "+"; //not sure why bigger dash displays like this.
           },
            expandcollapse: function () {
                if (this.expandedrows.length > 0) {
                    this.expandedrows = [];
                }
                else {
                    this.expandedrows = this.getdetailform.map((v) => { return v.id });
                }
                var bigdash = new Option()
                bigdash.innerHTML = "&mdash;"
                this.expandcollapsetext = this.expandedrows.length ? bigdash.text : "+";
            },
            handleSelectur(item,name, value,section){
                if(value){
                  this.$confirm('Are you sure you want to set the UR(D)?', 'Warning', {
                           confirmButtonText: 'OK',
                           cancelButtonText: 'Cancel',
                           type: 'warning'
                         }).then(() => {
                           this.$message({
                             type: 'success',
                             message: 'UR(D) is Set'
                           });
                              item[name] = value;
                              if(section != undefined && item[name] != ""){
                                  item[section] = "";
                              }
                              this.changed(item);
                         }).catch(() => {
                           this.$message({
                             type: 'info',
                             message: 'UR(D) is not Set'
                           });
                           item[name] = "";
                         });
                }else
                {
                    item[name] = value;
                }

            },
            searchur(query, done,item,name){
            if (query == null)
                    query = '';
                this.$http.post('project/SearchURD?q=' + query, {})
                    .then(response => {
                       this.$store.state.URd = response.data;
                    })
                    .catch(err => {
                        this.$bus.$emit('error', err);
                    })
            },
             querySearchbcc: function (query, done) {
                if (query == null)
                    query = '';
                this.$http.post('BillCompileCode/search?q=' + query, {})
                    .then(response => {
                        this.nrmsortcodes = JSON.parse(response.data);
                        done(this.nrmsortcodes)
                    })
                    .catch(err => {
                        this.$bus.$emit('error', err);
                    })
            },
              handleSelectMOMifthen(item,name, value) {
                item[name] = value.section;
                this.changed(item);
            },
              handleSelectgroup(item,name, value) {
                item[name] = value.group;
                this.changed(item);
            },
             handleSelectBCCifthen(item,name, value) {
                item[name] = value.code;
                this.changed(item);
            },
             handleSelectBCC(item, value) {
                item.nrmsection = value.code;
                this.changed(item);
            },
             getheight() {
               this.height = (document.documentElement.clientHeight - 130);
             },             
            addRow: function (index, row) {
                var itm = {searchtype: 'Required',
                           library:'',ur:'',
                               ifisNRMcode :true,
                               ifisMOMsection :true,
                               ifisgroup :true,
                               ifiswork :true,
                               ifislevel1 :true,
                               ifislevel2 :true,
                               ifislevel3 :true,
                               ifisunit :true,
                               ifisURdescription :true,
                               ifisURqty :true,
                               thenisNRMcode :true,
                               thenisMOMsection :true,
                               thenisgroup :true,
                               theniswork :true,
                               thenislevel1 :true,
                               thenislevel2 :true,
                               thenislevel3 :true,
                               thenisunit :true,
                               thenisURdescription :true,
                               thenisURqty :true, };
                if (row) {
                    var last = JSON.parse(JSON.stringify(row));
                    last.id = 0;
                    last.description += " Copy";
                    itm = last;
                    this.detailform.splice(index + 1, 0, itm);
                    this.changed(itm);
                }
                else {
                    this.detailform.unshift(itm);
                    window.scrollTo(0, document.body.scrollHeight);
                }
                itm.ownerid = this.user.id;
                itm.owner = this.user;
                // this.changed(itm);
            },

            delRow: function (index, row) {
                this.$confirm('This will permanently delete this row. Continue?', 'Warning', {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                }).then(() => {
                    this.$http.post('comparesearch/delete', row)
                        .then(response => {
                            this.$message({
                                showClose: true,
                                message: response.data,
                                type: "success"
                            });
                            //this.detailform.splice(index, 1);
                            this.refresh();
                        })
                        .catch(err => {
                            this.$bus.$emit('error', err);
                        });
                }).catch(() => {
                    this.$message({
                        showClose: true,
                        type: 'info',
                        message: 'Delete canceled'
                    });
                });
            },
            pageCurrentChange(val) {
                this.page = val;
            },
            rowperpages: async function (val) {
                try {
                    this.usergridrow.ugv.rowperpage = val;
                    this.usergridrow = await this.$training.savepagelayout(this.usergridrow);
                    this.pagesize = val;
                    this.page = 1;
                    this.$forceUpdate();
                } catch (error) {
                    this.$bus.$emit('error', error);
                }
            },
             pageMPCurrentChange(val) {
                this.pageMP = val;
            },
            isedit(row){
                row.edited = true;
            },
            changed: function (row) {
                if((row.nrmsection != null ) && (row.nrmsection !="")){

                    row.edited = true;
                    this.checkvalidpattern(row)
                }
            },
            checkvalidpattern(row){
                     this.isValidPattern = true;
                    try {
                        new RegExp(row.ifcharacters);
                    } catch(e) {
                        this.isValidPattern = false;

                    }
                    if(!this.isValidPattern){
                        // alert("Invalid text/Pattern!" + "\n"+row.ifcharacters+"\nPlease enter ifcharacters in proper format.");
                        this.invalidpatterndangerclass(row);

                        return false;
                    }
                    else{
                        row.show = false;
                        return true;
                    }
            },
              invalidpatterndangerclass:function(row){
                row.show = true;
            },
             isvalid: function (editedrows) {
                //cqcname and in cqc and idsname in cqcdetail must not be empty
                var msg = [];
                 this.detailform.forEach(x => {
                     var urs = this.detailform.filter(t => x.ur && t.ur == x.ur);
                     if(urs.length > 1)
                     {
                       var msgname = "UR No. " + x.ur + " is duplicate ";
                       var check =  msg.find(x=> x == msgname);
                       if(!check){
                          msg.push(msgname);
                       }
                     }
                 });
                 if (msg.length > 0) {
                    this.$alert(msg.join("</br>"), "Invalid Data", { dangerouslyUseHTMLString: true });
                    return false;
                }
                return true;
             },
            save: function (item, event)
            {
                if(this.isvalid(archanged) == false)
                {
                    return
                }
                //save only edited on
                var archanged = this.detailform.filter(x => x.edited == true);
                if (archanged.length > 0){
                        if(this.isValidPattern == false){
                        //   var invalidifcharacters = this.detailform.filter(x => (x.edited == true));
                            alert("Invalid Regex Pattern in ifcharacters!");
                        }
                        else{
                    this.$http.post('comparesearch/saveall', archanged)
                        .then(response => {
                            this.$message({
                                showClose: true,
                                message: response.data, type: "success"
                            });
                            this.refresh();
                        })
                        .catch(err => {
                            this.$bus.$emit('error', err);
                        });}
                }
                else {
                    this.$message({
                        showClose: true,
                        message: "Nothing to save", type: "info"
                    });
                }
            },

            refresh: function () {
                // this.loading = true;
                this.$store.state.isLoading = true;

                this.$http.post('comparesearch/getall', this.grid)
                    .then(response => {
                        if (this.user.id == null) {
                            this.user = this.$store.state.user;
                        }
                       this.detailform = response.data.comparesearchs;
                       this.$store.state.URs = [];
                       this.$store.state.URd = response.data.URds;
                        this.detailform.forEach(cs => {
                            cs.disabled = (this.user.role == 'A' || cs.owner.id == this.user.id) ? false : true;
                        })
                        // this.loading = false;
                        this.$store.state.isLoading = false;
                    }).catch(err => {
                        // this.loading = false;
                        this.$store.state.isLoading = false;
                        this.$bus.$emit('error', err);
                    });
            }
        },

        created: function () {
            this.user = this.$store.state.user;
            
            let gridrow = this.$training.getusergridrow(this.$route);
            this.pagesize = gridrow.ugv.rowperpage;
            this.usergridrow = gridrow;

            this.refresh();
             if(this.$store.state.sections == undefined || this.$store.state.sections.length == 0 ){
                this.getallsection();
            }else{
                this.sections = this.$store.state.sections;
                this.getnumberonsection();
            }
        },
    }
</script>
<style>
.icon-btn-width {
    padding: 7px;
}

element.style {
    border-width: 3px;
    font-weight: bold;
}
.icon-expand-btn-width-minus {
    padding: 4px;
    padding-left: 5px;
    padding-right: 5px;
     border-width: 3px;
     font-weight: bold;
}
.icon-expand-btn-width-pluse {
    padding: 4px;
    padding-left: 8px;
    padding-right: 8px;
     border-width: 3px;
     font-weight: bold;
}
</style>
