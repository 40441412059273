<template>
    <div v-show="showdata">
        <div class="fixdheader billsetwidth padright5px">
            <div class="margintb1mm">
                <span class="headerstyle">NRM1 Component Code</span>
                <div class="floatright">
                    <span>
                        <el-button :disabled="!editpermission" @click="save()" title="Save" type="success">Save</el-button>
                        <!--icon="el-icon-circle-check"-->
                    </span>
                </div>
            </div>

            <el-row>
                <el-col :span="10">
                    <el-row>
                    <span class="left">
                    <el-input :spellcheck="isspellcheck"
                              v-model="view.filter[0].value"
                              placeholder="Q NRM1 Code"
                              style="width:98px;"
                              @change="filterchange">
                    </el-input>
                    </span>
                    <span class="left">
                        <el-input :spellcheck="isspellcheck"
                              v-model="view.filter[1].value"
                              placeholder="Q Search Description"
                              style="min-width: 100px;
                                     margin-left: 13px;"
                              @change="filterchange" >
                    </el-input>
                    </span>
                    </el-row>
                </el-col>
                <el-col :span="13" align="center">
                    <el-pagination class="pagesizeswidth textalignlpage" 
                                    @size-change="rowperpages" 
                                    @current-change="refresh" 
                                    :current-page.sync="view.pageno" 
                                    :page-sizes="pagesizes" 
                                    :page-size="view.pagesize"  
                                    layout="sizes, prev, pager, next" :total="view.count">
                    </el-pagination>
                </el-col>
                <el-col :span="1">
                    <el-button :disabled="!editpermission"
                               class="floatright  icon-btn-width"
                               v-if="search.length==0" title="Add"
                               icon="el-icon-circle-plus" type="primary"
                               @click="addRow(null, null)"></el-button>
                </el-col>
                <div class="spaceBetween"></div>
            </el-row>
            <hr class="hrstyle" />
        </div>

        <el-row class="billsetwidth">
            <!-- :height="height"  Note: it is removed to set page  20 rows as comman css -->
            <div class="tablepaddinglr3">

            <el-table :data="detailform"
                      :height="height"  
                      size="mini"
                      v-loading="loading">

                <el-table-column prop="code" label="NRM1 Code" width="105">
                    <template slot-scope="scope">
                        <el-input :spellcheck="isspellcheck" placeholder="NRM1 Code" v-model="scope.row.code" @change="changed(scope.row)" :disabled="scope.row.disabled || !editpermission"></el-input>
                    </template>
                </el-table-column>

                <el-table-column prop="description" label="Description" width="710">
                    <template slot-scope="scope">
                        <el-input :spellcheck="isspellcheck" placeholder="Description" v-model="scope.row.description" @change="changed(scope.row)" :disabled="scope.row.disabled || !editpermission"></el-input>
                    </template>
                </el-table-column>
                <!--:label="'\xa0\xa0'+'Copy' +'\xa0\xa0\xa0\xa0\xa0'+'Delete'-->
                <el-table-column label="Copy" width="40" align="center">
                    <template slot-scope="scope">
                        <el-button size="mini" class="icon-btn-width" type="primary" icon="glyphicon glyphicon-duplicate"
                                   @click="addRow(scope.$index, scope.row)" :disabled="scope.row.disabled || !editpermission"></el-button>
                    </template>
                </el-table-column>
                <el-table-column label="Delete" width="45" align="right">
                    <template slot-scope="scope">
                        <el-button size="mini" class="icon-btn-width" type="danger" icon="el-icon-delete"
                                   @click="delRow(scope.$index, scope.row)" :disabled="scope.row.disabled || !editpermission"></el-button>
                    </template>
                </el-table-column>
            </el-table>
            </div>
        </el-row>
        <div class="billsetwidth">
            <hr class="hrspacebetween hrstyle" />
            <hr class="hrspacebetween hrstyle" />
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                view: {
                    filter: [{ table: 'bcc',ispercentage:'', column: 'code', value: '' },{ table: 'bcc', column: 'description', value: '' }],
                    sortcol: 'bcc.code'
                },
                height: (document.documentElement.clientHeight - 135),
                pagesizes: this.$store.state.pagesizes,
                search: '',
                user: this.$store.state.user,
                detailform: [],
                searchtypes: ['Required', 'Expected', 'Testing'],
                loading: false,
                showdata: false,
            }
        },

        computed: {
            // getdetailform () {
            //     if (this.search) {
            //         this.loading=true;
            //         var search = this.search.toLowerCase().trim();
            //         var data = this.detailform.filter((c) => {
            //             var include = c.code.toLowerCase().includes(search) || c.description.toLowerCase().includes(search);
            //             return include;
            //         });
            //         this.loading=false;
            //         return data;
            //     } else {
            //         return this.detailform;
            //     }
            // },
            height: function () {
                return window.innerHeight - 250;
            }
        },

        methods: {
            getheight() {
                this.height = (document.documentElement.clientHeight - 130);
            },
            rowperpages: async function (val) {
                try {
                    this.usergridrow.ugv.rowperpage = val;
                    this.usergridrow = await this.$training.savepagelayout(this.usergridrow);
                    this.view.pageno = 1;
                    this.view.pagesize = val;
                    this.refresh();
                } catch (error) {
                    this.$bus.$emit('error', error);
                }
            },
            filterchange(data){
                if(data){
                   this.view.pageno = 1;
                }
                this.refresh();
            },
            addRow: function(index, row) {
                var itm = { searchtype: 'Required' };
                if (row) {
                    var last = JSON.parse(JSON.stringify(row));
                    last.id = 0;
                    if (last.description == null || last.description == undefined) {
                        last.description = "Copy";
                    } else { last.description += " Copy"; }
                    itm = last;
                    this.detailform.splice(index + 1, 0, itm);
                }
                else {
                    this.detailform.push(itm);
                    window.scrollTo(0, document.body.scrollHeight);
                }
                itm.ownerid = this.user.id;
                itm.owner = this.user;
                this.changed(itm);
            },

            delRow: function (index, row) {
                this.$confirm('This will permanently delete this row. Continue?', 'Warning', {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                }).then(() => {
                    this.$http.post('billcompilecode/delete', row)
                        .then(response => {
                            this.$message({
                                showClose: true,
                                message: response.data,
                                type: "success"
                            });
                            this.detailform.splice(index, 1);
                            if (this.detailform.length == 0) {
                                var pr = this.view.pageno - 1;
                                this.view.pageno = pr;
                            }
                            this.refresh();
                        })
                        .catch(err => {
                            this.$bus.$emit('error', err);
                        });
                }).catch(() => {
                    this.$message({
                        showClose: true,
                        type: 'info',
                        message: 'Delete canceled'
                    });
                });
            },

            changed: function (row) {
                row.edited = true;
            },

            validate: function (callback) {
                var msg = [];
                for (var i = 0; i < this.detailform.length; i++) {
                    var a = this.detailform[i];
                    if (a.code == undefined || a.code == "") {
                        var msgname = "Code must not be empty";
                        var check = msg.find(x => x == msgname);
                        if (!check) {
                            msg.push(msgname)
                        }
                    }
                    for (var j = i + 1; j < this.detailform.length; j++) {
                        var b = this.detailform[j];
                        if (a.code == b.code && a.code != null && a.code != "" && a.code != undefined) {
                            var msgname = "Code: " + a.code + " is duplicate";
                            var check = msg.find(x => x == msgname);
                            if (!check) {
                                msg.push(msgname)
                            }
                        }
                    }
                }
                if (msg.length > 0) {
                    var style = "<style> .el-message-box </style>";
                    this.$alert(msg.join("<br />") + style, "Invalid Data", {
                        dangerouslyUseHTMLString: true,
                    });
                    callback(false)
                }
                else { callback(true); }
            },

            save: function (item, event) {
                //save only edited on
                this.validate((valid) => {
                    if (valid) {
                        var archanged = this.detailform.filter(x => x.edited == true);
                        if (archanged.length > 0) {
                            this.$http.post('billcompilecode/saveall', archanged)
                                .then(response => {
                                    this.$message({
                                        showClose: true,
                                        message: response.data, type: "success"
                                    });
                                    this.refresh();
                                })
                                .catch(err => {
                                    this.$bus.$emit('error', err);
                                });
                        }
                        else {
                            this.$message({
                                showClose: true,
                                message: "Nothing to save", type: "info"
                            });
                        }
                    }
                    else {
                        return false;
                    }
                });
            },

            refresh: function () {
                // this.loading = true;
                this.$store.state.isLoading = true;

                 var data  = this.view.filter[0].value + "%";
                 this.view.filter[0].ispercentage = data;
                this.$http.post('billcompilecode/getall', this.view)
                    .then(response => {
                        if (this.user.id == null) {
                            this.user = this.$store.state.user;
                        }
                        // var json = JSON.parse(response.data);
                        // json.forEach(cs => {
                        //     cs.disabled = (this.user.role == 'A' || cs.owner.id == this.user.id) ? false : true;
                        // })
                        // this.detailform = json;
                        this.detailform = response.data.data;
                        this.view = response.data.view;
                        // this.loading = false;
                        this.$store.state.isLoading = false;
                    }).catch(err => {
                        // this.loading = false;
                        this.$store.state.isLoading = false;
                        this.$bus.$emit('error', err);
                    });
            }
        },

        destroyed() {
            window.removeEventListener('resize', this.getheight);
        },

        mounted: function () {
            window.addEventListener('resize', this.getheight);
        },

        created: function () {
            let gridrow = this.$training.getusergridrow(this.$route);
            this.view.pagesize = gridrow.ugv.rowperpage;
            this.usergridrow = gridrow;
            this.refresh();
            this.showdata = true;
            //    if (this.$store.state.user.role == undefined) {
            //          this.$bus.$on('setuser', (user) => {
            //             if (user.role == "A") {
            //                 this.refresh();
            //                 this.showdata = true;
            //             }
            //             else{
            //                 this.$router.push("/");
            //                 this.showdata = false;
            //             }
            //         });
            //     }
            //     else if(this.$store.state.user.role == 'A'){
            //         this.refresh();
            //         this.showdata = true;
            //     }
            //     else{
            //         this.$router.push('/')
            //     }
        },
    }
</script>
<style>
    .billsetwidth {
        max-width: 904px;
        min-width: 400px;
    }
    .icon-btn-width {
    padding: 7px;
}
</style>
