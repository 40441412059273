<template>
    <div>
        <div class="fixdheader">
            <el-row>
                <el-col :span="24" class="headerstyle">
                    Row Designation (RD) - Report Selection</el-col
                >
            </el-row>
            <el-row>
                <div class="rdfixbox">
                    <el-collapse v-model="searchCriteria">
                        <el-collapse-item
                            class="fix-collapse-item custom-item__content"
                            title=" Project Specific Criteria"
                            name="1"
                        >
                            <hr class="hrstyle" />
                            <el-row>
                                <el-col :span="7">
                                    <el-row>
                                        <el-col class="RDporjectnameheader"
                                            >Project Name</el-col
                                        >
                                        <el-col class="RDporjectnameheader"
                                            >Part Name</el-col
                                        >
                                        <el-col class="RDporjectnameheader"
                                            >Section Name</el-col
                                        >
                                    </el-row>
                                </el-col>
                                <el-col :span="1">
                                    <projectselectpart
                                        :custom="custom"
                                        :lastsavedshow="true"
                                        @projectsectionchange="
                                            projectsectionchange
                                        "
                                    ></projectselectpart>
                                </el-col>
                            </el-row>
                            <div class="spaceBetween" />
                            <!-- <el-row>
                                <el-col :span="7">
                                    <el-row>
                                        <el-col class="RDporjectnameheader"
                                            >Search Criteria</el-col
                                        >
                                    </el-row>
                                </el-col>
                                <el-col :span="5">
                                    <el-select
                                        v-model="view.filter[0].value"
                                        class="fullwidth"
                                        clearable
                                        placeholder="Library"
                                    >
                                        <el-option
                                            v-for="item in libraries"
                                            :disabled="!editpermission"
                                            :key="item"
                                            :label="item"
                                            :value="item"
                                        >
                                        </el-option>
                                    </el-select>
                                </el-col>
                                <el-col :span="6">
                                    <el-row>
                                        <el-col
                                            class="
                                                RDporjectnameheader
                                                padleft5px
                                            "
                                            >Search Criteria</el-col
                                        >
                                    </el-row>
                                </el-col>
                                <el-col :span="6">
                                    <el-select
                                        v-model="form.criteria"
                                        class="fullwidth"
                                        clearable
                                        @change="criteriachange"
                                        placeholder="Criteria"
                                    >
                                        <el-option
                                            v-for="item in predicates"
                                            :disabled="!editpermission"
                                            :key="item.value"
                                            :label="item.label"
                                            :value="item.value"
                                        >
                                        </el-option>
                                    </el-select>
                                </el-col>
                            </el-row> -->
                            <!-- <el-row>
                                <el-col :span="7">
                                    <el-row>
                                        <el-col class="RDporjectnameheader"
                                            >Row Number</el-col
                                        >
                                    </el-row>
                                </el-col>
                                <el-col :span="5">
                                    <el-select
                                        v-model="form.rowno"
                                        class="fullwidth"
                                        clearable
                                        placeholder="Row"
                                    >
                                        <el-option
                                            v-for="(item,index) in rowarr"
                                            :disabled="!editpermission"
                                            :key="index"
                                            :label="item.name"
                                            :value="item.value"
                                        >
                                        </el-option>
                                    </el-select>
                                </el-col>
                            </el-row> -->
                        </el-collapse-item>
                    </el-collapse>
                </div>
                <div class="rdfixbox">
                    <el-collapse v-model="searchCriteria">
                        <el-collapse-item
                            class="fix-collapse-item custom-item__content"
                            title="Search Filters"
                            name="1"
                        >
                            <hr class="hrstyle" />
                            <el-row>
                                <el-col :span="7">
                                    <el-row>
                                        <el-col class="RDporjectnameheader"
                                            >Known Matches</el-col
                                        >
                                        <!-- <el-col class="RDporjectnameheader"
                                            >Projects(From)</el-col
                                        > -->
                                        <el-col class="RDporjectnameheader"
                                            >AM_Templates</el-col
                                        >
                                    </el-row>
                                </el-col>
                                <el-col :span="16">
                                    <el-row>
                                        <el-col>
                                            <el-checkbox
                                                v-model="form.knownmatches"
                                            ></el-checkbox>
                                        </el-col>
                                        <el-col>
                                            <!-- <el-row>
                                                <el-col :span="10">
                                                    <el-date-picker
                                                        v-model="
                                                            project.createdon
                                                        "
                                                        type="date"
                                                        class="
                                                            custom-date-picker
                                                        "
                                                        format="yyyy/MMM/dd"
                                                        value-format="yyyy-MM-dd"
                                                        placeholder="Date"
                                                    >
                                                    </el-date-picker>
                                                </el-col>
                                                <el-col
                                                    :span="4"
                                                    class="padding5px"
                                                >
                                                    Or
                                                </el-col>
                                                <el-col :span="10">
                                                    <el-select
                                                        v-model="form.number"
                                                        class="fullwidth"
                                                        clearable
                                                        placeholder="Number"
                                                    >
                                                        <el-option
                                                            v-for="item in numbers"
                                                            :disabled="
                                                                !editpermission
                                                            "
                                                            :key="item"
                                                            :label="item"
                                                            :value="item"
                                                        >
                                                        </el-option>
                                                    </el-select>
                                                </el-col>
                                            </el-row> -->
                                        </el-col>
                                        <el-col>
                                            <el-select
                                                filterable
                                                remote
                                                reserve-keyword
                                                v-model="form.amttemplatid"
                                                class="fullwidth paddingtop5"
                                                :remote-method="remoteSearchAmt"
                                                clearable
                                                placeholder="AM_T Name"
                                            >
                                                <el-option
                                                    v-for="item in AM_Tarr"
                                                    :disabled="!editpermission"
                                                    :key="item.id"
                                                    :label="item.amtemplatename"
                                                    :value="item.id"
                                                >
                                                </el-option>
                                            </el-select>
                                        </el-col>
                                    </el-row>
                                </el-col>
                            </el-row>
                            <div class="spaceBetween" />
                            <el-row>
                                <div class="rdfixbox">
                                    <el-col :span="6">
                                        <div class="center">
                                            <!-- <el-button type="success" plain
                                                >Run Report</el-button
                                            > -->
                                        </div>
                                    </el-col>
                                    <el-col :span="6">
                                        <div class="center">
                                            <el-button type="primary" plain
                                            @click="$router.push('/projectamos')"
                                                >Test In AMOS</el-button
                                            >
                                        </div>
                                    </el-col>
                                    <el-col :span="4">
                                        <div class="center">
                                            <el-button
                                                @click="savereport"
                                                type="success"
                                                plain
                                                >Save</el-button
                                            >
                                        </div>
                                    </el-col>
                                    <el-col :span="4">
                                        <div class="center">
                                            <el-button
                                                icon="el-icon-back"
                                                type="info"
                                                @click="$router.go(-1)"
                                            >
                                                Back
                                            </el-button>
                                        </div>
                                    </el-col>
                                </div>
                            </el-row>
                        </el-collapse-item>
                    </el-collapse>
                </div>
            </el-row>

            <hr class="hrstyle" />
        </div>
        <el-row class="rdfixbox-table" v-if="showRD">
            <el-table class="tableBox" :data="getRdreport" border>
                <el-table-column type="expand" width="20">
                    <template slot-scope="wm">
                        <div v-if="true">

                             <div v-if="wm.row.matched.length != undefined && wm.row.matched.length > 0">

                           <el-table
                                :data="wm.row.matched"
                                border
                                :show-header="false"
                                :default-expand-all="true"
                            >
                            <el-table-column type="expand" width="20" >
                                <template slot-scope="rdds1">
                                      <el-table
                                      class="fullwidth"
                                      :data="rdds1.row.rdd"
                                      border
                                      :row-class-name="tableRowClassName"
                                      :cell-style="tablecellClassName"
                                      >
                                      <el-table-column
                                          prop="wordname"
                                          label="Word Name"
                                          width="307"
                                      >
                                          <template slot-scope="wordname">
                                              <div class="tableinput-input-fixwidth">
                                                  <el-input
                                                      :class="getinputclass(wordname.row)"
                                                      size="small"
                                                      placeholder="Word Name"
                                                      v-model="wordname.row.wordname"
                                                  ></el-input>
                                              </div>
                                          </template>
                                   </el-table-column>
                                <el-table-column
                                    prop="rule"
                                    label="Rule"
                                    width="120"
                                >
                                    <template slot-scope="rule">
                                        <div>
                                            <el-select
                                                 :class="getinputclass(rule.row)"
                                                v-model="rule.row.rule"
                                                placeholder="Rule"
                                            >
                                                <el-option
                                                    v-for="item in rules"
                                                    :key="item.value"
                                                    :label="item.name"
                                                    :value="item.value"
                                                >
                                                </el-option>
                                            </el-select>
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                    prop="value"
                                    label="Value"
                                    width="60"
                                >
                                    <template slot-scope="value">
                                        <div class="Calc-input-fixwidth">
                                            <el-input
                                               :class="getinputclass(value.row)"
                                                size="small"
                                                placeholder="Value"
                                                v-model="value.row.value"
                                            ></el-input>
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                    prop="min"
                                    label="Min"
                                    width="60"
                                >
                                    <template slot-scope="min">
                                        <div class="Calc-input-fixwidth">
                                            <el-input
                                                :class="getinputclass(min.row)"
                                                size="small"
                                                placeholder="Min"
                                                v-model="min.row.min"
                                            ></el-input>
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                    prop="usedin"
                                    label="Used In"
                                    width="65"
                                >
                                </el-table-column>
                                <el-table-column
                                    prop="other"
                                    label="Other"
                                    width="60"
                                >
                                </el-table-column>
                                <el-table-column
                                    prop="uses"
                                    label="Uses"
                                    width="60"
                                >
                                </el-table-column>
                                <el-table-column
                                    prop="lastmatchedon"
                                    label="Date"
                                    width="100"
                                >
                                    <template slot-scope="lastmatchedon">
                                        {{ dateformatterLM(lastmatchedon.row) }}
                                    </template>
                                </el-table-column>
                                <el-table-column
                                    prop="lastmatchedproject.projectname"
                                    label="Project Name"
                                    width="290"
                                >
                                </el-table-column>
                                      </el-table>
                                </template>
                            </el-table-column>
                            <el-table-column>
                                <template slot-scope="pad">
                                    {{pad.row.predicate}}
                                </template>
                            </el-table-column>
                           </el-table>
                             </div>

                             <div v-if="wm.row.notmatched != undefined && wm.row.notmatched.length > 0 && form.knownmatches == true">
                             Not matched
                            <el-table
                                class="fullwidth"
                                :data="wm.row.notmatched"
                                border
                                :row-class-name="tableRowClassName"
                                :cell-style="tablecellClassName"
                            >
                                <el-table-column
                                    prop="wordname"
                                    label="Word Name"
                                    width="307"
                                >
                                    <template slot-scope="wordname">
                                        <div class="tableinput-input-fixwidth">
                                            <el-input
                                                :class="getinputclass(wordname.row)"
                                                size="small"
                                                placeholder="Word Name"
                                                v-model="wordname.row.wordname"
                                            ></el-input>
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                    prop="rule"
                                    label="Rule"
                                    width="120"
                                >
                                    <template slot-scope="rule">
                                        <div>
                                            <el-select
                                                 :class="getinputclass(rule.row)"
                                                v-model="rule.row.rule"
                                                placeholder="Rule"
                                            >
                                                <el-option
                                                    v-for="item in rules"
                                                    :key="item.value"
                                                    :label="item.name"
                                                    :value="item.value"
                                                >
                                                </el-option>
                                            </el-select>
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                    prop="value"
                                    label="Value"
                                    width="60"
                                >
                                    <template slot-scope="value">
                                        <div class="Calc-input-fixwidth">
                                            <el-input
                                               :class="getinputclass(value.row)"
                                                size="small"
                                                placeholder="Value"
                                                v-model="value.row.value"
                                            ></el-input>
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                    prop="min"
                                    label="Min"
                                    width="60"
                                >
                                    <template slot-scope="min">
                                        <div class="Calc-input-fixwidth">
                                            <el-input
                                                :class="getinputclass(min.row)"
                                                size="small"
                                                placeholder="Min"
                                                v-model="min.row.min"
                                            ></el-input>
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                    prop="usedin"
                                    label="Used In"
                                    width="65"
                                >
                                </el-table-column>
                                <el-table-column
                                    prop="other"
                                    label="Other"
                                    width="60"
                                >
                                </el-table-column>
                                <el-table-column
                                    prop="uses"
                                    label="Uses"
                                    width="60"
                                >
                                </el-table-column>
                                <el-table-column
                                    prop="lastmatchedon"
                                    label="Date"
                                    width="100"
                                >
                                    <template slot-scope="lastmatchedon">
                                        {{ dateformatterLM(lastmatchedon.row) }}
                                    </template>
                                </el-table-column>
                                <el-table-column
                                    prop="lastmatchedproject.projectname"
                                    label="Project Name"
                                    width="290"
                                >
                                </el-table-column>
                            </el-table>
                            </div>
                              <el-row>
                                   <el-col :span="1">
                                       <el-button
                                           @click="addnewword(wm.row)"
                                           type="primary"
                                           icon="el-icon-circle-plus"
                                       ></el-button>
                                   </el-col>
                                   <el-col :span="1">
                                       <!-- <el-button
                                           type="primary"
                                           icon="el-icon-document-copy"
                                       ></el-button> -->
                                   </el-col>
                               </el-row>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="amtemplatename"
                    label="AM_Template Name "
                    width="290"
                >
                </el-table-column>
                <el-table-column prop="sortcode" label="Sort Code" width="290">
                    <template slot-scope="sortcode">
                        <div class="tableinput-input-fixwidth">
                            <el-input
                                class="tableinput-input-fixwidth"
                                size="small"
                                placeholder="Sort Code"
                                v-model="sortcode.row.rd.sortcode"
                            ></el-input>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="Match" label="Match" width="60">
                </el-table-column>
                <el-table-column prop="Match" label="Match" width="60">
                </el-table-column>
                <el-table-column> </el-table-column>
            </el-table>
        </el-row>
    </div>
</template>
<script>
import moment from "moment";
import projectselectpart from "./projectselectpart.vue";
import Template from './template.vue';
export default {
    data() {
        return {
            showRD:false,
            rules: [
                { name: "Added to RM", value: "addedtorm" },
                { name: "Exact Match", value: "exactmatch" },
                { name: "Igonor", value: "Igonor" },
            ],
            searchCriteria: ["1"],
            report: {},
            view: {
                filter: [
                    { table: "amt", column: "library", value: "" },
                    { table: "amt", column: "section", value: "" },
                    { table: "amt", column: "id", value: "" },
                ],
            },
            sectionid: null,
            project: {},
            predicates: [
                { label: "SH", value: "SN" },
                { label: "CH", value: "CH" },
                { label: "SAM", value: "Pre" },
            ],
            criteriaarr: [],
            AM_Tarr: [],
            rowarr: [],
            numbers: [],
            form: {amttemplatid:null,rowno:null,knownmatches:true},
            custom: {
                rowcol: "el-row",
                projectsid: null,
            },
            libraries: this.$store.state.libraries,
        };
    },
    components: {
        projectselectpart: projectselectpart,
        Template,
    },
    computed: {
        getamos(){
            return function(data){
                var arr = [];
                 this.report.projectamos.forEach(pa => {
                           var dd1 = pa.details.filter((t)=> {if(t.amtemplateid == data.id){return t}});
                 });
                //   console.log(data);
                return [];
            }
        },
        getrdds(){
         return function(data){
         if(data.notmatched == undefined){data.notmatched = [];}
         if(data.matched == undefined){ data.matched = [];}
         var myrdd = [];
         var removerd = [];
         if (data.rd && data.rd.RDdS){
          data.rd.RDdS.forEach((r)=>{
             var rr = myrdd.find(y=> y.wordname.trim() === r.wordname.trim());
              if(rr == undefined){
               r.ismatch = false;
               myrdd.push(r);
              }
          })
          }
         if(this.report.projectamos.length > 0){
             this.report.projectamos.forEach(pa => {
                var dd1 = pa.details.filter((t)=> {if(t.amtemplateid == data.id){return t}});
                     dd1.forEach(d => {
                        if(d.amtemplateid == data.id)
                        {
                          var wrmatch = [];
                          var wrnotmatch = [];
                          d.rdd.filter((x)=>{
                             var rr =  myrdd.find(y=> y.wordname.trim() === x.wordname.trim());
                              if(rr == undefined)
                              {
                                  x.ismatch = false;
                                  wrnotmatch.push(x);
                              }else{
                                  rr.ismatch = true;
                                  wrmatch.push(rr);
                                  removerd.push(rr);
                              }
                               return x;
                             })
                          var obj1 = {predicate:"CH",rowno:d.rowno};
                          this.$set(obj1,'rdd',[...wrmatch,...wrnotmatch]);
                          data.matched.push(obj1);
                        }
                     });
                    //  if(dd1.length == 0)
                    //  {
                    //     // data.rd.RDdS.forEach((r)=>{
                    //     //     var rr = myrdd.find(y=> y.wordname.trim() === r.wordname.trim());
                    //     //      if(rr == undefined){
                    //     //       r.ismatch = false;
                    //     //       myrdd.push(r);
                    //     //      }
                    //     //  })
                    //  }
                   });
          }
        //   else{
        //     //  data.rd.RDdS.forEach((r)=>{
        //     //      var rr = myrdd.find(y=> y.wordname.trim() === r.wordname.trim());
        //     //       if(rr == undefined){
        //     //        r.ismatch = false;
        //     //        myrdd.push(r);
        //     //       }
        //     //   })
        //   }
         var mymatch = [];
         myrdd.forEach((r)=>{
             var rr = removerd.find((p)=>p.wordname.trim() === r.wordname.trim());
             if(rr == undefined){
                 r.ismatch = false;
                 mymatch.push(r);
             }
         })
         data.notmatched = mymatch;
         if(data.notmatched.length == 0){
             this.addnewword(data)
            }
         }
        },
        getinputclass(){
            return function(data){
               if(data.ismatch == true){
                return " tableinput-input-fixwidth dark-gray-inputIMT";
               }else{
                   return " tableinput-input-fixwidth";
               }
            }
        },
        getRdreport(){
            if(this.report.amts != undefined){
                if(this.report.amts.length == 0){
                    return [];
                }
                if(this.form.amttemplatid  != 0 && this.form.amttemplatid  != null){
                    return this.report.amts.filter(x=> x.id == this.form.amttemplatid);
                }else{
                    return this.report.amts;
                }
            }
        },
        dateformatterLM() {
            return function (value) {
                if (value.lastmatchedon) {
                    var d = new Date(value.lastmatchedon);
                    return moment(d).format("DD-MMM-YYYY");
                } else {
                    return "";
                }
            };
        },
    },
    methods: {
            comparer: function (a, b, isSameUser) {
                const onlyInLeft = (left, right, compareFunction) =>
                    left.filter((leftValue) =>
                        right.some((rightValue) =>
                            compareFunction(leftValue, rightValue)
                        )
                    );

                const onlyInA = onlyInLeft(a, b, isSameUser);
                return onlyInA;
            },
          tablecellClassName: function ({ row, column, rowIndex, columnIndex }) {
            if(row.ismatch == true){
            return {
               background: "#fff2cc",
               color: "#606266",
             };
            }
          },
         tableRowClassName({ row, rowIndex }) {
             if(row.ismatch == true){
                 return "dark-gray-row";
             }
         },
        savereport: function () {
             this.report.amts.forEach(a => {
                  var notmatch = [];
                  var match = [];

                  if (a.notmatched) {
                    var nmatch = a.notmatched.filter((x)=>{
                        if(x.wordname != ""){
                            return x
                        }
                        })
                    notmatch.push(...nmatch);

                 }
                 if (a.matched)
                 {
                 a.matched.forEach((m)=>{
                     m.rdd.forEach((r)=>{
                         if(r.wordname != ""){
                             var ism = match.find(m2 => m2.wordname.trim() === r.wordname.trim());
                             if(ism == null && r.ismatch == true){
                                 match.push(r);
                             }
                         }
                     })
                    })
                }
                 a.notmatch = notmatch;
                a.match = match;
             });
            this.$http
                .post("rowdesignation/saveRDreport", this.report)
                .then((response) => {
                    this.getRDreport();
                })
                .catch((err) => {
                    this.$bus.$emit("error", err);
                });
        },
        addnewword(rd) {
            var obj = {
                rule: "",
                value: 0,
                min: 0,
                usedin: 0,
                other: 0,
                uses: 0,
                rowdesignationid:0,
                wordname:'',
            };
            this.$set(this.form,'knownmatches',true);
            // this.form.knownmatches = true;
            rd.notmatched.push(obj);
        },
        getRDreport: function () {
            this.report.vi = this.view;
            this.$http
                .post("rowdesignation/getRDreport", this.report)
                .then((response) => {
                    this.report = response.data;
                    this.report.p = this.project;
                    this.view = this.report.vi;
                    this.form.amttemplatid = null;
                    this.rowarr = [];
                    this.report.amts.forEach((x)=>{
                        this.getrdds(x);
                    })
                    this.report.projectamos.forEach((p)=>{
                        p.details.forEach((d)=>{
                            if(d.amtemplateid != undefined){
                                this.rowarr.push({name:d.rowno,value:d.rowno})
                            }
                        })
                    })
                })
                .catch((err) => {
                    this.$bus.$emit("error", err);
                });
        },
        criteriachange: function (data) {
            this.$$nextTick(() => {
                this.form.criteria = data;
            });
        },
        remoteSearchAmt: function (query) {
            if (query == null) {
                query = "";
            }
            var data = {
                section: this.project.section.section,
                library: this.project.library,
                amtemplatename: query,
            };
            this.$http
                .post("amt/SearchForAutoComplete", data)
                .then((response) => {
                    this.AM_Tarr = response.data;
                    // var addnew = { id: null, amtemplatename: '' };
                    // row.amtoptions.push(addnew);
                })
                .catch((err) => {
                    this.$bus.$emit("error", err);
                });
        },
        projectsectionchange: function (Projectobj) {
            this.sectionid = Projectobj.sectionid;
            this.project = Projectobj.project;
            if (this.project.id != undefined) {
                this.view.filter[0].value = this.project.library.toString();
            }
            if (this.sectionid != null) {
                this.showRD = true;
                this.project.projectpart = Projectobj.part;
                // this.project.section = {id :}
                this.view.filter[1].value = this.project.section.section.toString();
                this.report.p = this.project;
                this.remoteSearchAmt("");
                this.getRDreport();
            }else{
                this.showRD = false;
                this.report.amts = [];
            }
        },
    },
    created() {
        if (this.$store.state.RDobj.rows != undefined) {
            this.$set(this, "rowarr", this.$store.state.RDobj.rows);
            this.$store.state.RDobj.rows = null;
        }
        if (this.$store.state.RDobj.Criteria != undefined) {
            this.$set(this.form, "criteria", this.$store.state.RDobj.Criteria);
        }
    },
};
</script>
<style>
.custom-date-picker {
    width: 111px !important;
}
.Calc-input-fixwidth .el-input-number--small {
    line-height: 14px;
}
.Calc-input-fixwidth .el-input--small .el-input__inner {
    height: 20px;
}
.fix-collapse-item .el-collapse-item__header {
    height: 24px;
}
.custom-item__content .el-collapse-item__content {
    padding-bottom: 0px;
}
.el-table .dark-gray-row {
  background: #fff2cc;
  color: #606266;
;
}.dark-gray-inputIMT .el-input__inner {
  background: #fff2cc;
  color: #606266;
  border: none;
  font-weight: bold;
  border-radius: 0%;
}
</style>
