<template>
</template>
<script>
    export default {
        data() {
            return {
                loginname: "",
                password: ""
            }
        },
        created: function () {
            this.$bus.$emit('logout', null);
        }
    }
</script>
<style>

</style>
