<template>
    <div  v-loading="loading">
     <!-- header -->
     <div class="ehprojectfixwidth">
     <div class="fixdheader">
         <el-row>
             <el-col :span="12" class="headerstyle"><span>Project Hours(Estimate Form)</span></el-col>
             <el-col :span="12" style="margin-top: 5px">
                 <div class="padding2px right">
                         <el-button
                             type="success"
                             @click="cellchanged('setup_projecthours_save',()=>{save()},false)"
                             id="setup_projecthours_save"
                             icon="el-icon-circle-check"
                             :disabled="!editpermission"
                             plain>
                             save
                         </el-button>
                         <el-button
                             type="primary"
                             @click="$router.push('/projectdescriptionform')"
                             id="setup_projecthours_project_description"
                             plain>
                             Project Description
                            </el-button>
                            <el-button
                            type="success"
                            id="setup_projecthours_project_export_hours"
                            @click="ExportReport"
                            plain>
                            Export Hours
                        </el-button>
                        <el-button
                        type="info"
                        plain
                        id="setup_projecthours_project_back"
                          @click="$router.go(-1)">
                          <i class="el-icon-back"></i>
                          Back
                        </el-button>
                 </div>
             </el-col>
         </el-row>
         <el-row>
             <div class="fix-col-box1">
                 <el-row>
                     <el-col :span="24">
                         <div class="left">
                             <span class="fixwidth">
                                 <el-row :gutter="2">
                                      <el-col class="projecthours" :span="5">
                                          <el-input
                                                placeholder=""
                                                :readonly="true"
                                                id="setup_projecthours_project_name_header"
                                                v-model="form.projectnameheader"></el-input>
                                       </el-col>
                                      <el-col class="projecthours" :span="19">
                                         <el-input
                                                id="setup_projecthours_project_name"
                                             placeholder="Project Name"
                                             v-model="form.projectname">
                                         </el-input>
                                       </el-col>
                                  </el-row>
                             </span>
                             <span class="padding2px">
                              </span>
                         </div>
                     </el-col>
                  </el-row>
                 <el-row>
                     <el-col :span="24">
                         <span class="fixwidth">
                                <el-row :gutter="2">
                                    <el-col class="projecthours" :span="12">
                                        <el-select v-model="form.library"
                                                   class="fullwidth"
                                                   clearable
                                                    id="setup_projecthours_select_library"
                                                    placeholder="Select Library">
                                                    <el-option v-for="item in libraries"
                                                    :disabled="!editpermission"
                                                       :key="item"
                                                       :label="item"
                                                       :value="item">
                                                    </el-option>
                                                </el-select>
                                            </el-col>
                                            <el-col class="projecthours" :span="12">
                                                <el-select v-model="form.ribastage"
                                                        class="fullwidth"
                                                        clearable
                                                        id="setup_projecthours_riba_stage"
                                                        placeholder="RIBA Stage">
                                                        <el-option v-for="item in RIBAarr"
                                                       :disabled="!editpermission"
                                                       :key="item.name"
                                                       :label="item.name"
                                                       :value="item.name">
                                            </el-option>
                                        </el-select>
                                    </el-col>
                                </el-row>
                         </span>
                     </el-col>
                  </el-row>
                 <el-row>
                     <el-col :span="24">
                         <span class="fixwidth">
                             <el-row :gutter="2">
                                 <el-col class="projecthours" :span="12">
                                     <el-select
                                         v-model="form.documentstatus"
                                         class="fullwidth"
                                         clearable
                                         id="setup_projecthours_document_status"
                                         placeholder="Document status">
                                         <el-option v-for="item in documentstatus"
                                             :disabled="!editpermission"
                                             :key="item.value"
                                             :label="item.name"
                                             :value="item.value">
                                            </el-option>
                                     </el-select>
                                 </el-col>
                                 <el-col class="projecthours" :span="12">
                                     <el-select
                                         v-model="form.quotestatus"
                                         class="fullwidth"
                                         clearable
                                         id="setup_projecthours_quote_status"
                                         placeholder="Quote Status">
                                         <el-option v-for="item in quotestatus"
                                             :disabled="!editpermission"
                                             :key="item.value"
                                             :label="item.name"
                                             :value="item.value">
                                           </el-option>
                                     </el-select>
                                    </el-col>
                             </el-row>
                            </span>
                     </el-col>
                  </el-row>
                 <el-row>
                     <el-col :span="24">
                         <span class="fixwidth">
                             <el-row :gutter="2">
                                 <el-col class="projecthours" :span="5">
                                     <el-select
                                         v-model="form.version"
                                         class="fullwidth"
                                         clearable
                                         id="setup_projecthours_versions"
                                         @change="versionschange"
                                         placeholder="">
                                         <el-option v-for="item in form.versions"
                                               :disabled="!editpermission"
                                               :key="item.index"
                                               :label="item.name"
                                               :value="item.name">
                                           </el-option>
                                         <el-option value="">
                                             <template>
                                                 <div>
                                                     <el-button
                                                       icon="el-icon-circle-plus-outline"
                                                       type="text"
                                                       size="mini"
                                                       id="setup_projecthours_add_versions"
                                                       :disabled="!editpermission"
                                                       @click="addversion"
                                                       style="float: left"
                                                     ></el-button>
                                                 </div>
                                              </template>
                                           </el-option>
                                     </el-select>
                                  </el-col>
                                 <el-col class="projecthours" :span="19">
                                      <el-input
                                          id="setup_projecthours_reason_for_version_change"
                                          placeholder="Reason for version change"
                                          v-model="form.versionchange">
                                       </el-input>
                                   </el-col>
                              </el-row>
                          </span>
                       </el-col>
                  </el-row>
             </div>
             <div class="fix-box-scrole" >
                <div  v-for="(s, index) in getehpartcount()" :key="s">
                  <div class="fix-col-box2">
                     <div v-if="s == 1" style="padding-bottom: 1px; padding-top: 4px;">
                         <span class="fixwidthadmin">
                               <el-input
                                :id="'setup_projecthours_administration_'+index"
                                 placeholder="Administration:"
                                 :value="'Administration'"
                                 :readonly="true">
                             </el-input>
                         </span>
                         <span class="padding2px "></span>
                         <span>
                                <el-input
                                    :id="'setup_projecthours_administration_part_calc_'+index"
                                  class="hours-admin-input-fixwidth inputright"
                                   v-model="adcalc"
                                   :readonly="true">
                             </el-input>
                         </span>
                     </div>
                     <div v-if="getehpartarray(s).length > 0">
                      <el-table
                      ref="fixboxscrole"
                         :show-header="false"
                         key="name"
                         :cell-style="{borderBottom: 'none', paddingBottom: '2px'}"
                         class="fullwidth part-cell-padding-TB padding-right-cell-4 padding-left-cell"
                         :data="getehpartarray(s)">
                             <el-table-column
                                 prop="name"
                                 width="200"
                                 min-width="200">
                                 <template slot-scope="scope">
                                    <div >
                                     <el-input
                                    :id="'setup_projecthours_filter_project_part_'+index+scope.$index"
                                     placeholder="Project Part"
                                     class="pficnslctspace "
                                     v-model="scope.row.name">
                                     <i slot="prefix"
                                        v-if="scope.row.id != 0 && scope.row.id != undefined"
                                        @click="linktoIMEH(scope.row)"
                                        class="el-icon-link">
                                    </i>
                                    </el-input>
                                    </div>
                                 </template>
                             </el-table-column>
                             <el-table-column
                                 prop=""
                                 width="52">
                                 <template slot-scope="scope">
                                 <el-input
                                    :id="'setup_projecthours_project_part_calc_'+index+scope.$index"
                                     class="hours-input-fixwidth inputright"
                                     v-model="scope.row.duration"
                                     :readonly="true">
                                 </el-input>
                                 </template>
                              </el-table-column>
                             <el-table-column
                                 prop=""
                                 width="30">
                                 <template slot-scope="scope">
                                      <el-button
                                     class="icon-btn-width"
                                     type="primary"
                                    :id="'setup_projecthours_add_project_'+index+scope.$index"
                                     icon="el-icon-circle-plus"
                                     @click="addprojectpart(scope.$index,s)"
                                     :disabled="!editpermission">
                                      </el-button>
                                      </template>
                                      </el-table-column>
                                    <el-table-column
                                 prop=""
                                 width="30">
                                 <template slot-scope="scope">
                                      <el-button
                                     class="icon-btn-width"
                                     type="danger"
                                     icon="el-icon-delete"
                                    :id="'setup_projecthours_delete_project_part_'+index+scope.$index"
                                     @click="deleteprojectpart(scope.$index,s)"
                                     :disabled="!editpermission">
                                      </el-button>
                                 </template>
                             </el-table-column>
                      </el-table>
                     </div>
                     <div class="spaceBetween"/>
                     <div
                     class="saved-style el-input el-input--mini"
                     v-if="getcreatedby != '' && getehpartcount() == s">
                       <b> Saved:</b>
                        {{getcreatedby}}
                        {{getcreatedon}}
                     </div>
                 </div>
                </div>
             </div>
         </el-row>
         <hr class="hrstyle" />
     </div>
     <!-- body -->
     <el-row>
         <el-table
             class="padding-left-cell"
             :show-header="false"
             :data="form.ehprojectparts">
             <el-table-column>
                 <template slot-scope="prt">
                      <!-- <div v-for="(item, index) in form.ehprojectparts" :key="index"> -->
                     <el-table
                          :show-header="false"
                          :default-expand-all="true"
                          :data="prt.row.projectGroups"
                          :cell-style="tablecellClassName"
                          :row-class-name="tableRowClassName"
                          style="width: 100%">
                          <el-table-column type="expand" width="30">
                             <template slot-scope="scope">
                                 <div class="fix-col-box-section">
                                     <el-button type="danger"
                                        :id="'setup_projecthours_project_criteria_'+prt.$index+scope.$index"
                                          @click="
                                          opencriteriadialog(scope.row,scope.$index,prt.$index)"
                                          plain>Project Criteria
                                      </el-button>
                                      <div class="spaceBetween2" />
                                        Since Date:
                                     <span style="width: 30px">
                                         <el-date-picker
                                              v-model="scope.row.sincedate"
                                               type="month"
                                               :id="'setup_projecthours_since_date_'+prt.$index+scope.$index"
                                               format="MMM-yyyy"
                                               align="center"
                                               class=" custom-date-picker sincs-date "
                                               placeholder="Pick a month">
                                          </el-date-picker>
                                     </span>
                                     <div class="spaceBetween2" />
                                       Chunk Size :{{scope.row.chunk}}(m2)
                                     <div class="spaceBetween2" />
                                 </div>
                                 <div class="vl"></div>
                                 <div class="vl2"></div>
                                 <div class="fix-col-box4">
                                      <el-input
                                        :id="'setup_projecthours_project_name_'+prt.$index+scope.$index"
                                         placeholder="Project Name"
                                         :readonly="true"
                                         v-model="form.projectname">
                                      </el-input>
                                      <div class="spaceBetween2" />
                                      <el-input
                                         class="pficnslctspace"
                                         :readonly="true"
                                        :id="'setup_projecthours_project_part_'+prt.$index+scope.$index"
                                         placeholder="Project Part"
                                         v-model="prt.row.name">
                                             <i
                                                 slot="prefix"
                                                :id="'setup_projecthours_project_part_link_'+prt.$index+scope.$index"
                                                 v-if="prt.row.id != 0 && prt.row.id != undefined"
                                                 @click="linktoIMEH(prt.row)"
                                                 class="el-icon-link">
                                             </i>
                                      </el-input>
                                      <div class="spaceBetween2" />
                                      <el-row :gutter="5">
                                         <el-col :span="6">
                                                 <el-input-number
                                                     placeholder="Qty"
                                                    :id="'setup_projecthours_qty_'+prt.$index+scope.$index"
                                                      class="inputright Calc-input-fixwidth-section"
                                                     :controls="false"
                                                      v-model="scope.row.qty">
                                                  </el-input-number>
                                         </el-col>
                                         <el-col :span="6">
                                                  <el-input
                                                    :id="'setup_projecthours_complexity_index_'+prt.$index+scope.$index"
                                                     placeholder="Complexity Index"
                                                      v-model="scope.row.complexityindex">
                                                  </el-input>
                                         </el-col>
                                         <el-col :span="6">
                                                 <el-input
                                                     placeholder="Library"
                                                     :readonly="true"
                                                    :id="'setup_projecthours_library_'+prt.$index+scope.$index"
                                                      v-model="form.library">
                                                 </el-input>
                                         </el-col>
                                         <el-col :span="6">
                                                 <el-date-picker
                                                     v-model="form.createdon"
                                                     type="date"
                                                     align="center"
                                                     class="custom-date-picker"
                                                     format="MMM-yyyy"
                                                     placeholder="Last date"
                                                    :id="'setup_projecthours_last_date_'+prt.$index+scope.$index">
                                                 </el-date-picker>
                                                 <!-- <el-input placeholder="Project Name" v-model="scope.row.project1.tsalastdate"></el-input> -->
                                         </el-col>
                                      </el-row>
                                  </div>
                                 <div class="vl"></div>
                                 <div class="vl2"></div>
                                 <div class="fix-col-box4">
                                      <el-autocomplete
                                         :clearable="true"
                                         @change="projectchange( $event, scope.row,'project1','projectid1',1) "
                                         popper-class="morewidth-autocomplete-project"
                                         class=" inline-input fullwidth bold padding2px"
                                         v-model="scope.row.project1.projectname"
                                         :fetch-suggestions="searchProject"
                                        :id="'setup_projecthours_select_project_'+prt.$index+scope.$index"
                                         @select="selectProject(  $event, scope.row, 'project1','projectid1' )"
                                         value-key="projectname"
                                         placeholder="Please select Project">
                                         <template slot-scope="{ item }">
                                             <span style="float: left">
                                                 {{item.projectname }}
                                             </span>
                                             <span style="float: right">
                                                 {{item.accessedon | dateformat}}
                                             </span>
                                          </template>
                                     </el-autocomplete>
                                     <div class="spaceBetween2" />
                                      <el-select
                                         clearable
                                         v-model="scope.row.projectpartid1"
                                         class="fullwidth pficnslctspace"
                                         popper-class="inputcenter"
                                        :id="'setup_projecthours_project_part_2_'+prt.$index+scope.$index"
                                         @change="projectpartchange(scope.row,1)"
                                         placeholder="Project Part">
                                          <i slot="prefix"
                                            :id="'setup_projecthours_project_part_link_2_'+prt.$index+scope.$index"
                                             v-if="scope.row.projectpartid1 !== null && scope.row.projectpartid1 !== undefined && scope.row.projectpartid1 !== ''"
                                             @click="linktoIM(scope.row,1)"
                                             class="el-icon-link">
                                          </i>
                                         <el-option v-for="item in scope.row.project1.projectparts"
                                             :key="item.id"
                                             :label="item.name"
                                             :value="item.id">
                                         </el-option>
                                     </el-select>
                                     <div class="spaceBetween2" />
                                      <el-row
                                         :gutter="5">
                                         <el-col
                                             :span="6">
                                             <el-input
                                                :id="'setup_projecthours_project_2_qty_'+prt.$index+scope.$index"
                                                 placeholder="Qty"
                                                 class="inputright"
                                                 :readonly="true"
                                                 v-model="scope.row.project1.qty">
                                             </el-input>
                                         </el-col>
                                         <el-col
                                             :span="6">
                                             <el-input
                                                :id="'setup_projecthours_project_2_complexity_index_'+prt.$index+scope.$index"
                                                 placeholder="Complexity Index"
                                                 :readonly="true"
                                                 v-model="scope.row.project1.complexityindex">
                                             </el-input>
                                         </el-col>
                                         <el-col
                                             :span="6">
                                             <el-input
                                                :id="'setup_projecthours_project_2_library_'+prt.$index+scope.$index"
                                                 placeholder="Library"
                                                 :readonly="true"
                                                 v-model=" scope.row.project1.library">
                                             </el-input>
                                         </el-col>
                                         <el-col
                                             :span="6">
                                             <el-date-picker
                                                :id="'setup_projecthours_project_2_date_'+prt.$index+scope.$index"
                                                  v-model="scope.row.project1.createdon"
                                                  type="date"
                                                  align="center"
                                                  class="custom-date-picker"
                                                  format="MMM-yyyy"
                                                  placeholder="Last date">
                                              </el-date-picker>
                                             <!-- <el-input placeholder="Last date" v-model="scope.row.project2.tsalastdate"></el-input> -->
                                         </el-col>
                                     </el-row>
                                 </div>
                                 <div class="vl"></div>
                                 <div class="vl2"></div>
                                 <div class="fix-col-box4">
                                      <el-autocomplete
                                         :clearable="true"
                                         @change="projectchange($event, scope.row,'project2','projectid2',2) "
                                         popper-class="morewidth-autocomplete-project"
                                         class="inline-input fullwidth bold  padding2px "
                                         v-model="scope.row.project2.projectname"
                                         :fetch-suggestions="searchProject"
                                         @select="selectProject($event, scope.row, 'project2', 'projectid2' )"
                                         value-key="projectname"
                                         :id="'setup_projecthours_project_part_3_'+prt.$index+scope.$index"
                                         placeholder="Please select Project">
                                         <template slot-scope="{ item }">
                                             <span style="float: left">
                                                 {{item.projectname}}
                                             </span>
                                             <span style="float: right">
                                                 {{item.accessedon | dateformat}}
                                             </span>
                                          </template>
                                     </el-autocomplete>
                                     <div class="spaceBetween2" />
                                      <el-select
                                         clearable
                                          v-model="scope.row.projectpartid2"
                                          class="fullwidth pficnslctspace"
                                         :id="'setup_projecthours_select_project_part_3_'+prt.$index+scope.$index"
                                          @change="projectpartchange(scope.row,2)"
                                          placeholder="Project Part">
                                          <i
                                             slot="prefix"
                                             v-if="scope.row.projectpartid2 !== null && scope.row.projectpartid2 !== undefined && scope.row.projectpartid2 !== ''"
                                             @click="linktoIM(scope.row,2)"
                                             class="el-icon-link">
                                          </i>
                                          <el-option v-for="item in scope.row.project2.projectparts"
                                             :key="item.id"
                                             :label="item.name"
                                             :value="item.id">
                                         </el-option>
                                     </el-select>
                                     <div class="spaceBetween2" />
                                     <el-row
                                         :gutter="5">
                                         <el-col
                                             :span="6">
                                             <el-input
                                                :id="'setup_projecthours_project_part_3_qty_'+prt.$index+scope.$index"
                                                 placeholder="Qty"
                                                 class="inputright"
                                                 :readonly="true"
                                                 v-model="scope.row.project2.qty">
                                              </el-input>
                                         </el-col>
                                         <el-col
                                             :span="6">
                                             <el-input
                                                :id="'setup_projecthours_project_part_3_complexity_index_'+prt.$index+scope.$index"
                                                 placeholder="Complexity Index"
                                                 :readonly="true"
                                                 v-model="scope.row.project2.complexityindex">
                                              </el-input>
                                         </el-col>
                                         <el-col
                                             :span="6">
                                             <el-input
                                                :id="'setup_projecthours_project_part_3_library_'+prt.$index+scope.$index"
                                                 placeholder="Library"
                                                 :readonly="true"
                                                 v-model="scope.row.project2.library">
                                             </el-input>
                                         </el-col>
                                         <el-col
                                             :span="6">
                                             <el-date-picker
                                                :id="'setup_projecthours_project_part_3_date_'+prt.$index+scope.$index"
                                                 v-model="scope.row.project2.createdon"
                                                 type="date"
                                                 align="center"
                                                 class="custom-date-picker"
                                                 format="MMM-yyyy"
                                                 placeholder="Last date">
                                             </el-date-picker>
                                         </el-col>
                                     </el-row>
                                 </div>
                                 <div
                                     :span="scope.row.groupname == 'Administration' ? 12 : 24">
                                     <el-table
                                         :header-cell-style="cellclassnameheader"
                                         :cell-style="cellclassname"
                                         class="tableBox padding-left-cell padding-right-cell"
                                         :show-header="true"
                                         :border="true"
                                         :data=" getprojectsection(scope.row)">
                                         <el-table-column
                                             min-width="20"
                                             width="20"

                                             type="expand">
                                             <template slot-scope="scope1">
                                                 <el-table
                                                      class="tableBox padding-left-cell padding-right-cell"
                                                      :border="true"
                                                      :show-header="false"
                                                      :data="gettimesheet(scope1,prt.row,prt.$index)">
                                                       <el-table-column
                                                         prop="u"
                                                         min-width="320"
                                                         width="320">
                                                         <template slot-scope="scope">
                                                             {{scope.row.userdetail }}
                                                         </template>
                                                      </el-table-column>
                                                      <el-table-column
                                                          width="10">
                                                      </el-table-column>
                                                      <el-table-column
                                                         label="Algo"
                                                         width="70"
                                                         align="right"
                                                         min-width="70"
                                                         prop="algo">
                                                      </el-table-column>
                                                      <el-table-column
                                                          label="Calc"
                                                           align="right"
                                                          width="70"
                                                          prop="calc">
                                                      </el-table-column>
                                                      <el-table-column
                                                          label="Comment"
                                                          min-width="173"
                                                          width="173"
                                                          max-width="173"
                                                          prop="comment">
                                                      </el-table-column>
                                                      <el-table-column
                                                         width="10">
                                                      </el-table-column>
                                                      <el-table-column
                                                           label="Base"
                                                           width="70"
                                                           min-width="70"
                                                           align="right"
                                                           prop="ph">
                                                          <template slot-scope="scope">
                                                              {{getbaseduration( scope.row, "ts1")}}
                                                          </template>
                                                      </el-table-column>
                                                      <el-table-column
                                                          label="Actual"
                                                          width="70"
                                                          align="right"
                                                          prop="ph">
                                                          <template slot-scope="scope">
                                                              {{getduration( scope.row,"ts1")}}
                                                          </template>
                                                      </el-table-column>
                                                      <el-table-column
                                                          label="Comment"
                                                          min-width="173"
                                                          width="173"
                                                          max-width="173"
                                                          prop="comment">
                                                         <template slot-scope="scope">
                                                             {{getcomment(scope.row,"ts1") }}
                                                         </template>
                                                      </el-table-column>
                                                      <el-table-column
                                                          width="10">
                                                      </el-table-column>
                                                      <el-table-column
                                                         label="Base"
                                                         width="70"
                                                         min-width="70"
                                                         align="right"
                                                         prop="ph">
                                                         <template slot-scope="scope">
                                                             {{getbaseduration( scope.row,"ts2")}}
                                                         </template>
                                                      </el-table-column>
                                                      <el-table-column
                                                          label="Actual"
                                                          width="70"
                                                          align="right"
                                                          prop="ph">
                                                         <template slot-scope="scope">
                                                            {{getduration(scope.row,"ts2")}}
                                                         </template>
                                                      </el-table-column>
                                                      <el-table-column
                                                          label="Comment"
                                                          prop="comment">
                                                          <template slot-scope="scope">
                                                              {{getcomment(scope.row,"ts2")}}
                                                          </template>
                                                      </el-table-column>
                                                 </el-table>
                                             </template>
                                         </el-table-column>
                                         <el-table-column
                                             label="Section"
                                             min-width="300"
                                             width="300"
                                             prop="section">
                                             <template slot-scope="s">
                                                 <div v-if="s.row.isparent == true">
                                                      <div class="bold">
                                                          {{s.row.section}}
                                                      </div>
                                                   </div>
                                                  <div v-else>
                                                     {{s.row.section}}
                                                  </div>
                                             </template>
                                          </el-table-column>
                                          <el-table-column
                                             width="10">
                                          </el-table-column>
                                          <el-table-column
                                              label="Algo"
                                              width="70"
                                              align="right"
                                              min-width="70"
                                              prop="algo">
                                             <template slot-scope="ca">
                                                <div v-if=" ca.row.isparent == true">
                                                    <div class="bold">
                                                      {{ca.row.algo}}
                                                    </div>
                                                </div>
                                                 <div v-else>
                                                     <el-input-number
                                                         class="inputright Calc-input-fixwidth-section"
                                                         size="small"
                                                         :controls="false"
                                                         placeholder="Algo"
                                                        :id="'setup_projecthours_algo_'+prt.$index+ca.$index"
                                                         @change="algototal(ca.row,prt.row)"
                                                         v-model="ca.row.algo">
                                                      </el-input-number>
                                                 </div>
                                            </template>
                                          </el-table-column>
                                          <el-table-column
                                              label="Calc"
                                              width="70"
                                              align="right"
                                              min-width="70"
                                              prop="calc">
                                              <template slot-scope="ca">
                                                  <div v-if=" ca.row.isparent == true">
                                                     <div class="bold">
                                                         {{ca.row.calc}}
                                                     </div>
                                                 </div>
                                                 <div v-else>
                                                    <el-input-number
                                                         class="inputright Calc-input-fixwidth-section"
                                                         size="small"
                                                         :controls="false"
                                                         :id="'setup_projecthours_calc_'+prt.$index+ca.$index"
                                                         @change="calctotal(ca.row,prt.row)"
                                                         placeholder="Calc"
                                                         v-model="ca.row.calc">
                                                     </el-input-number>
                                                 </div>
                                            </template>
                                          </el-table-column>
                                          <el-table-column
                                               label="Comment"
                                               min-width="173"
                                               width="173"
                                               max-width="173"
                                               prop="calc">
                                               <template slot-scope="ca">
                                                  <div v-if=" ca.row.isparent == true">
                                                     {{""}}
                                                  </div>
                                                  <div v-else>
                                                      <el-input placeholder="Comment"
                                                        :id="'setup_projecthours_comment_'+prt.$index+ca.$index"
                                                          @change="canedit(ca.row)"
                                                          v-model="ca.row.comment">
                                                      </el-input>
                                                  </div>
                                               </template>
                                          </el-table-column>
                                          <el-table-column
                                             width="10">
                                          </el-table-column>
                                          <el-table-column
                                             label="Base"
                                             width="70"
                                             align="right"
                                             min-width="70"
                                             prop="bash">
                                             <template slot-scope="a">
                                                 <div>
                                                     <div v-if="a.row.isparent == true" class="bold">
                                                          {{a.row.p1bd }}
                                                     </div>
                                                      <div v-else>
                                                         {{a.row.p1bd }}
                                                      </div>
                                                 </div>
                                            </template>
                                          </el-table-column>
                                          <el-table-column
                                              label="Actual"
                                              width="70"
                                              align="right"
                                              min-width="70"
                                              prop="actual">
                                             <template slot-scope="a">
                                                 <div>
                                                      <div v-if="a.row.isparent == true" class="bold">
                                                          {{a.row.p1ad }}
                                                     </div>
                                                     <div v-else>
                                                         {{a.row.p1ad }}
                                                     </div>
                                                 </div>
                                            </template>
                                          </el-table-column>
                                          <el-table-column
                                              label="Comment"
                                              min-width="173"
                                              width="173"
                                              max-width="173"
                                              prop="">
                                          </el-table-column>
                                          <el-table-column
                                             width="10">
                                          </el-table-column>
                                          <el-table-column
                                              label="Base"
                                              width="70"
                                              align="right"
                                              min-width="70"
                                              prop="bash">
                                             <template slot-scope="a">
                                                 <div>
                                                      <div v-if="a.row.isparent == true" class="bold">
                                                        {{a.row.p2bd }}
                                                       </div>
                                                       <div v-else>
                                                         {{a.row.p2bd }}
                                                       </div>
                                                 </div>
                                            </template>
                                          </el-table-column>
                                          <el-table-column
                                              label="Actual"
                                              width="70"
                                              align="right"
                                              min-width="70"
                                              prop="actual">
                                              <template slot-scope="a">
                                                  <div>
                                                      <div v-if="a.row.isparent == true" class="bold">
                                                          {{a.row.p2ad }}
                                                      </div>
                                                      <div v-else>
                                                          {{a.row.p2ad }}
                                                      </div>
                                                  </div>
                                              </template>
                                          </el-table-column>
                                          <el-table-column
                                             label="Comment"
                                             prop="">
                                          </el-table-column>
                                     </el-table>
                                 </div>
                             </template>
                          </el-table-column>
                          <el-table-column
                             prop="groupname"
                             width="700"
                             min-width="700"
                             label="groupname">
                             <template slot-scope="p">
                                 <div class="bold">
                                     {{ prt.row.name }} - {{ p.row.groupname }}:
                                     {{findepspcriteriasname(p.row.criterias.criterias1,p.row ,1)}}
                                     {{findepspcriteriasname(p.row.criterias.criterias2,p.row,2)}}
                                     {{ findepspcriteriasname(p.row.criterias.criterias3,p.row,3)}}
                                     {{ findepspcriteriasname(p.row.criterias.criterias4,p.row,4)}}
                                 </div>
                             </template>
                          </el-table-column>
                          <el-table-column
                             prop="nom"
                             label="NOM"
                             width="480">
                             <template slot-scope="p">
                                 <div>
                                     NOM
                                     <el-input-number
                                         class="NOM-input-fixwidth"
                                         size="small"
                                        :id="'setup_projecthours_nom_'+prt.$index+p.$index"
                                         :value="getnom(p.row,prt.row.pd)"
                                         :controls="false">
                                      </el-input-number>
                                 </div>
                             </template>
                          </el-table-column>
                          <el-table-column
                             prop="nom"
                             label=""
                             width="100">
                             <template slot-scope="scope">
                                  <div>
                                     <el-button
                                         type="success"
                                         class="right"
                                         @click="gethours(scope.row)"
                                        :id="'setup_projecthours_get_hours_'+prt.$index+scope.$index"
                                         plain
                                         size="mini"
                                         >Get Hours
                                      </el-button>
                                  </div>
                             </template>
                          </el-table-column>
                     </el-table>
                 </template>
             </el-table-column>
         </el-table>
         <el-row>
             <div class="adminrow">
                 <div class="admincenter bold">
                    Administration
                 </div>
             </div>
         </el-row>
         <el-row>
             <el-col
                 :span="24">
                 <el-table
                      class="tableBox table-cell-space-remove"
                      :header-cell-style="cellclassnameheaderadmin"
                      :default-expand-all="true"
                      :data="[{}]" style="width: 100%">
                      <el-table-column
                            type="expand"
                            width="20">
                           <template slot-scope="">
                              <el-table
                                   class="tableBox"
                                   :show-header="false"
                                   :default-expand-all="true"
                                   :data="form.admins" style="width: 100%">
                                   <el-table-column width="320">
                                       <template slot-scope="g" >
                                           {{g.row.description}}
                                       </template>
                                   </el-table-column>
                                   <el-table-column
                                       width="70"   align="right">
                                      <template slot-scope="g">
                                           <el-input-number
                                               class="Calc-input-fixwidth-section inputright"
                                               size="small"
                                               @change="algochange"
                                               :controls="false"
                                               placeholder="Algo"
                                                :id="'setup_projecthours_algo_2_'+g.$index"
                                               v-model="g.row.algo">
                                          </el-input-number>
                                      </template>
                                   </el-table-column>
                                   <el-table-column
                                     width="70"   align="right">
                                     <template slot-scope="g">
                                         <el-input-number
                                              class="Calc-input-fixwidth-section"
                                              size="small"
                                              @change="algochange"
                                              :controls="false"
                                                :id="'setup_projecthours_calc_2_'+g.$index"
                                              placeholder="Calc"
                                              v-model="g.row.calc">
                                         </el-input-number>
                                     </template>
                                   </el-table-column>
                                   <el-table-column
                                       width="170"  >
                                       <template slot-scope="g">
                                             <el-input
                                                 clear="Calc-input-fixwidth-section"
                                                :id="'setup_projecthours_comment_2_'+g.$index"
                                                 placeholder="Comment"
                                                 v-model="g.row.comment">
                                             </el-input>
                                       </template>
                                   </el-table-column>
                              </el-table>
                          </template>
                      </el-table-column>
                      <el-table-column
                         width="300">
                          <template slot-scope="">
                             {{'Administration Work'}}
                         </template>
                       </el-table-column>
                       <el-table-column
                              align="right"
                              width="70"
                              label="Algo" >
                              <template slot-scope="">
                                  {{adalgo + "%"}}
                              </template>
                       </el-table-column>
                       <el-table-column
                          width="70"
                          label="Calc"  align="right">
                          <template slot-scope="">
                              {{adcalc }}
                          </template>
                       </el-table-column>
                       <el-table-column
                          width="170"
                          label="Comment" >
                          <template slot-scope="">
                              <!-- {{g.row.comment}} -->
                              {{""}}
                          </template>
                       </el-table-column>
                 </el-table>
             </el-col>
         </el-row>
     </el-row>
     </div>
     <el-dialog
         title="Criterias"
         :visible.sync="dialogcriteria"
         width="50%"
         :before-close="closecriteriadialog">
         <el-row
             :gutter="5">
                <el-col
                     :span="6">
                     <el-select
                         v-model="criterias1"
                         class="fullwidth"
                        id="setup_projecthours_2_psp_criterias_1"
                         placeholder="PSP Criterias 1">
                         <el-option v-for="item in getpspcriterias('c1')"
                             :key="item.id"
                             :label="item.value"
                             :value="item.id">
                         </el-option>
                     </el-select>
                </el-col>
                <el-col
                     :span="6">
                      <el-select
                         v-model="criterias2"
                         class="fullwidth"
                        id="setup_projecthours_2_psp_criterias_2"
                         placeholder="PSP Criterias 2">
                         <el-option v-for="item in getpspcriterias('c2')"
                             :key="item.id"
                             :label="item.value"
                             :value="item.id">
                         </el-option>
                    </el-select>
                </el-col>
                <el-col
                    :span="6">
                    <el-select
                         v-model="criterias3"
                         class="fullwidth"
                        id="setup_projecthours_2_psp_criterias_3"
                         placeholder="PSP Criterias 3">
                         <el-option v-for="item in getpspcriterias('c3')"
                             :key="item.id"
                             :label="item.value"
                             :value="item.id">
                         </el-option>
                    </el-select>
                </el-col>
                <el-col
                     :span="6">
                      <el-select
                         v-model="criterias4"
                         class="fullwidth"
                        id="setup_projecthours_2_psp_criterias_4"
                         placeholder="PSP Criterias 4">
                         <el-option v-for="item in getpspcriterias('c4')"
                             :key="item.id"
                             :label="item.value"
                             :value="item.id">
                         </el-option>
                    </el-select>
                </el-col>
         </el-row>
         <span
             slot="footer"
             class="dialog-footer">
             <el-button
                  size="mini"
                  id="setup_projecthours_2_criterias_cancel"
                  @click="closecriteriadialog">Cancel
               </el-button>
             <el-button
                  size="mini"
                  id="setup_projecthours_2_criterias_confirm"
                  type="primary"
                  @click="closecriteriadialog">Confirm
             </el-button>
         </span>
     </el-dialog>
    </div>
</template>
<script>
import moment from 'moment';
    export default {
        data() {
            return {
                loading:false,
                cversion:'',
                adalgo:0,
                adcalc:0,
                quotestatus: [
                    { name: "Provisional ", value: "Provisional" },
                    { name: "Fixed ", value: "Fixed" },
                ],
                documentstatus: [
                    { name: "Representative ", value: "Representative" },
                    { name: "Actual", value: "Actual" },
                ],
                criterias1: null,
                criterias2: null,
                criterias3: null,
                criterias4: null,
                criteriadata: {},
                dialogcriteria: false,
                timesheetprojects: [],
                sections: this.$store.state.sections,
               admins:[
                   {
                       description:"Senior Management (Directors)",
                       algo:0,
                       calc:0,
                       comment:"",
                   },
                    {
                       description:"Team",
                       algo:0,
                       calc:0,
                       comment:"",
                   },
               ],
                projectGroups: [
                    {
                        groupname: "Substructure",
                        chunk:"Building footprint",
                        nom: 0,
                        projectid1: null,
                        projectpartid1: null,
                        projectid2: null,
                        projectpartid2: null,
                        sincedate: new Date(),
                        criteria1id: null,
                        criteria2id: null,
                        criteria3id: null,
                        criteria4id: null,
                        project1: {
                            projectname: "",
                            tsalastdate: new Date(),
                            projectparts: [],
                        },
                        project2: {
                            projectname: "",
                            tsalastdate: new Date(),
                            projectparts: [],
                        },
                        project3: {
                            projectname: "",
                            tsalastdate: new Date(),
                            projectparts: [],
                        },
                       criterias: {},
                       projectSections:[],
                    },
                    {
                        groupname: "Superstructure",
                        chunk:"Gross Internal Floor Area",
                        nom: 0,
                        projectid1: null,
                        projectpartid1: null,
                        projectid2: null,
                        projectpartid2: null,
                        sincedate: new Date(),
                        criteria1id: null,
                        criteria2id: null,
                        criteria3id: null,
                        criteria4id: null,
                        project1: { tsalastdate: new Date(), projectparts: [] },
                        project2: { tsalastdate: new Date(), projectparts: [] },
                        project3: { tsalastdate: new Date(), projectparts: [] },
                       criterias: {},
                        projectSections:[],
                    },
                    {
                        groupname: "External",
                        chunk:"8.2 Hard surfaces",
                        nom: 0,
                        projectid1: null,
                        projectpartid1: null,
                        projectid2: null,
                        projectpartid2: null,
                        sincedate: new Date(),
                        criteria1id: null,
                        criteria2id: null,
                        criteria3id: null,
                        criteria4id: null,
                        project1: {
                            projectname: "",
                            tsalastdate: new Date(),
                            projectparts: [],
                        },
                        project2: {
                            projectname: "",
                            tsalastdate: new Date(),
                            projectparts: [],
                        },
                        project3: {
                            projectname: "",
                            tsalastdate: new Date(),
                            projectparts: [],
                        },
                        criterias: {},
                        projectSections:[],
                    },
                ],
                 criterias: {
                            criterias1: {},
                            criterias2: {},
                            criterias3: {},
                            criterias4: {},
                        },
                ehproejctsection: {
                    userdetail: "",
                    algo: 0,
                    calc: 0,
                    comment: "",
                    ts1: {},
                    ts2: {},
                    sectionid: null,
                    projectgroupid: null,
                },
                pspcriterias: [],
                RIBAarr: [
                    { name: "Stage 2" },
                    { name: "Stage 3" },
                    { name: "Stage 4" },
                ],
                form: {
                     versions: [{name:'01.00',index:0}],
                },
                libraries: this.$store.state.libraries,
            };
        },
        computed: {
            getehpartcount(){
                return function(){
                    if(this.form.ehprojectparts != undefined && this.form.ehprojectparts.length != 0 ){
                        if(this.form.ehprojectparts.length <= 2){
                         return 1;
                        }else{
                            return Math.ceil((this.form.ehprojectparts.length-3)/4)+1;
                        }
                    }
                    return 0;
                }
            },
            getehpartarray(){
                return function(i){
                    if(this.form.ehprojectparts != undefined && this.form.ehprojectparts.length != 0 && i != undefined){
                        if(i == 1){
                         return  this.form.ehprojectparts.slice((i*3)-3,i*3);
                        }else{
                            return  this.form.ehprojectparts.slice((i*4)-5,(i*4)-1);
                        }
                        //   return t;
                    }
                    // return [];
                }
            },
            getnom:function(){
                return function(data,row){
                    if(data.groupname == 'Substructure')
                    {
                        return row.SubNom;
                    }
                    if(data.groupname == 'Superstructure')
                    {
                        return row.SupNom;
                    }
                    if(data.groupname == 'External')
                    {
                         return row.ExtNom;
                    }
                }
            },
            getprojectsection(){
                return function(row){
                   var p1ad=0,
                       p1bd=0,
                       p2ad=0,
                       p2bd=0,
                       algo=0,
                       calc=0;

                    row.projectSections.forEach((t)=>{
                        if(t.isparent != true){
                            this.gettimesheet({row:t})
                            p1ad += parseInt(t.p1ad);
                            p1bd += parseInt(t.p1bd);
                            p2ad += parseInt(t.p2ad);
                            p2bd += parseInt(t.p2bd);
                            algo += t.algo;
                            calc += t.calc;
                        }
                    })
                    var ss = row.projectSections.find(x => x.isparent == true);
                    if(ss != undefined){
                      ss.p1ad = p1ad;
                      ss.p1bd = p1bd;
                      ss.p2ad = p2ad;
                      ss.p2bd = p2bd;
                      ss.algo = algo;
                      ss.calc = calc;
                    }
                    return row.projectSections;
                }
            },
            getcreatedby(){
                 if (this.form.createdbyuser) {
                     if(this.form.createdbyuser.loginname){
                    return this.form.createdbyuser.loginname
                     }else{
                         return ""
                     }
                 }else{
                    return "";
                 }
            },
            getcreatedon(){
                 if (this.form.createdon) {
                    var dt = new Date(this.form.createdon);
                    return moment(dt).format("DD-MMM-YYYY HH:mm");
                    // return dt.toLocaleString().replace(/:\d{2}\s/, " ");
                 }else{
                    return "";
                 }
            },
            isnunornot() {
                return function (data) {
                    if (isNaN(parseInt(data))) {
                        return 0;
                    } else {
                        return data;
                    }
                };
            },
            MinutesToTimeInt() {
                return function (data) {
                    return Math.floor(data / 60);
                };
            },
            getcomment() {
                return function (data, name) {
                    if (data[name].ph == undefined) {
                        return "";
                    } else if (data[name].ph.comment == undefined) {
                        return "";
                    } else {
                        return data[name].ph.comment;
                    }
                };
            },
            getbaseduration() {
                return function (data, name) {
                    var total = 0;
                    if (data[name].ph != undefined) {
                        if (data[name].ph.cleaninghours != undefined) {
                            total =
                                this.isnunornot(
                                    this.MinutesToTimeInt(
                                        data[name].totaldurationint
                                    )
                                ) + data[name].ph.cleaninghours;
                            return total;
                        } else {
                            return "0";
                        }
                    } else {
                        return "0";
                    }
                };
            },
            getduration() {
                return function (data, name) {
                    return (
                        this.isnunornot(
                            this.MinutesToTimeInt(data[name].totaldurationint)
                        )
                    );
                };
            },
            findepspcriteriasname() {
                return function (data,row,index) {
                    if(data != undefined){
                      if (data.id != undefined) {
                          return ", " + this.findepspcriteriasbyid(data.id).value;
                      } else {
                          if(row["criteria"+index+"id"] != undefined){
                          return ", " + this.findepspcriteriasbyid(row["criteria"+index+"id"]).value;
                          }else{
                              return "";
                          }
                      }
                    }else{
                      return "";
                    }
                };
            },
            getpspcriterias() {
                return function (data) {
                    if (this.criteriadata.groupname == "External") {
                        return this.pspcriterias.filter(
                            (x) => x.grouping == "ext" && x.criteria == data
                        );
                    }
                    if (this.criteriadata.groupname == "Superstructure") {
                        return this.pspcriterias.filter(
                            (x) => x.grouping == "sup" && x.criteria == data
                        );
                    }
                    if (this.criteriadata.groupname == "Substructure") {
                        return this.pspcriterias.filter(
                            (x) => x.grouping == "sub" && x.criteria == data
                        );
                    }
                };
            },
            foramatdate() {
                return function (date) {
                    if (date != null) {
                        var dt = new Date(date);
                        return moment(dt).format("DD-MMM-YYYY HH:mm");
                    } else {
                        return "";
                    }
                };
            },
            findepspcriteriasbyid() {
                return function (id) {
                    return this.pspcriterias.find((x) => x.id == id);
                };
            },
            getsectionsdata:function(){
                return function (data) {
                    var obj = [{ isparent: true,
                                 p1bd : 0,
                                 p1ad : 0,
                                 p2bd : 0,
                                 p2ad : 0,
                                 section: data.groupname }];
                    var s1 = JSON.parse(JSON.stringify(this.sections))

                    if (data.groupname == "External") {
                        s1.external.forEach((x)=>{
                            x.id = null;
                            x.isedit = false;
                            x.p1bd = 0;
                            x.p1ad = 0;
                            x.p2bd = 0;
                            x.p2ad = 0;
                        })
                        data.projectSections = [...obj, ...s1.external];
                    }
                    if (data.groupname == "Superstructure") {
                       s1.superstructure.forEach((x)=>{
                            x.id = null;
                            x.isedit = false;
                             x.p1bd = 0;
                             x.p1ad = 0;
                             x.p2bd = 0;
                             x.p2ad = 0;
                        })
                        data.projectSections = [...obj, ...s1.superstructure];
                    }
                    if (data.groupname == "Substructure") {
                         s1.substructure.forEach((x)=>{
                            x.id = null;
                            x.isedit = false;
                             x.p1bd = 0;
                             x.p1ad = 0;
                             x.p2bd = 0;
                             x.p2ad = 0;
                        })
                        data.projectSections = [...obj, ...s1.substructure];
                    }
                    if(data.groupname == "Administration"){
                         var obj2 = [{ isparent: true, section: "Administration Works" }];
                         var entry = [{
                             userdetail : "",
                             algo : 0,
                             calc : 0,
                             ts1 : {},
                             ts2 : {},
                             comment : "",
                             section : "Senior Management (Directors)",
                             projectgroupid : null,
                         },
                         {
                             userdetail : "",
                             algo : 0,
                             calc : 0,
                             ts1 : {},
                             ts2 : {},
                             comment : "",
                             section : "Team",
                             projectgroupid : null,
                         },]
                            data.projectSections = [...obj2, ...entry];
                    }
                };
            },
            getehprojectpart(){
                return this.form.ehprojectparts;
            },
            getprojectgroups(){
                return function(data){
                     return data;
                    }
            },
        },
        methods: {
            getsection(){
                // Get NRM section for each page
                if(false){
                    // return this.detailform.section;
                }else{
                    return "";
                }
            },
                cellchanged(cellaction,callback,cancall){
                if(cancall){
                    callback();
                    this.canmatchcellid(()=>{},cellaction,this.getsection());
                }else{
                    this.canmatchcellid(callback,cellaction,this.getsection());
                }
            },
             linktoIM(project,id){
                 if(project["projectid"+id] != null && project["projectpartid"+id] != null){
                    this.$store.state.ERproject.projectid = project["projectid"+id];
                    this.$store.state.ERproject.partid = project["projectpartid"+id];
                    this.$router.push('/independentmeasure')
                 }
             },
            linktoIMEH:function(row){
                 if(this.form.ehprojectparts[0].id != null && this.form.ehprojectparts[0].id != 0){
                    this.$store.state.ERproject.projectid = this.form.id;
                    this.$store.state.ERproject.partid = row.id;
                     if(this.cversion){
                     this.$store.state.ERproject.version =  this.cversion;
                    }else{
                        this.$store.state.ERproject.version = "01.00";
                    }
                    this.$router.push('/independentmeasure')
                 }
            },
            ExportReport(){
                this.$store.state.ERproject.projectid = this.form.id
                if(this.form.ehprojectparts[0].id != null && this.form.ehprojectparts[0].id != 0){
                    if(this.cversion){
                     this.$store.state.ERproject.version =  this.cversion;
                    }else{
                        this.$store.state.ERproject.version = "01.00";
                    }
                    this.$router.push('/exportreport')
                }else{
                     this.$message({
                         message: 'Warning, Please first save a project to get export hours.',
                          type: 'warning'
                     });
                }
            },
            projectpartchange(data,id){
               var pp = data["projectid"+id];
               var pr  = data["projectpartid"+id];
               if(pp != "" && pr !== '' && pr !== undefined && pr !== null && pp != null){
               var d1 = {projectid:pp,projectpartid:pr}
                 this.$http
                    .post("project/getIM", d1)
                    .then((response) => {
                        var ind = null
                        response.data.IMTtemplate.forEach((imt,index) => {
                            if(imt.datadescription.includes(data.chunk)){
                                this.$nextTick();
                                this.$set(data["project"+id], 'qty', imt.IM.qty)
                                 ind = index;
                            }
                        });
                        if(ind != null){
                            var complexityindex = "";
                            var t1= null,b1 =null,p1 =null;
                            for (let index = ind; index < response.data.IMTtemplate.length; index++) {
                                const element = response.data.IMTtemplate[index];
                                if(element.type == 'GP')
                                {
                                     break;
                                }else{
                                  if(element.datadescription.includes('Takeoff'))
                                  {
                                      t1 = element.IM.qty;
                                  }
                                  if(element.datadescription.includes('Billing'))
                                  {
                                      b1 = element.IM.qty;
                                  }
                                  if(element.datadescription.includes('Preview / RFI'))
                                  {
                                      p1 = element.IM.qty;
                                  }
                                }
                            }
                            if(t1 != null){complexityindex+= t1+"-"};
                            if(b1 != null){complexityindex+= b1+"-"};
                            if(p1 != null){complexityindex+= p1};
                            this.$set(data["project"+id], 'complexityindex', complexityindex)
                        }
                    }).catch((err) => {
                        this.$bus.$emit("error", err);
                    });
               }
            },
            versionschange(data){
                if(data != ""){
                   this.cversion = data;
                   this.getprojecthours(this.form.id,data);

                }else{
                    this.form.version = this.cversion;
                }
            },
            addversion(){
                if(this.form.versions.length != 0){
                var v1 = this.form.versions[this.form.versions.length-1].name;
                var a = v1.toString().split(".")[1];
                var num = parseFloat(a) + 1;
                var f = v1.toString().split(".")[0];
                var l = num < 10 ? "0" + num : num;
                if (l < 10) {
                    v1 = f.toString() + "." + l.toString();
                } else {
                    v1 = f.toString() + "." + l.toString();
                }
                this.form.versions.push({name:v1,index:this.form.versions.length});
                var vt = this.form.versions.find(x => x.name == v1);
                this.cversion = vt.name;
                this.form.version = vt.name;
                this.form.ehprojectparts = [];
                this.form.admins = JSON.parse(JSON.stringify(this.admins));
                this.form.admins.forEach(e => {
                     e.version = v1;
                 });
                this.addprojectpart();
                }else{
                   this.form.versions = [{name:'01.00',index:0}];
                   this.form.version = '01.00';
                }
            },
            algochange(data){
                 this.adalgo = 0;
                 this.adcalc = 0;
                 if(this.form.admins != null){
                    this.form.admins.forEach(x => {
                        this.adalgo += x.algo;
                        this.adcalc += x.calc;
                    });
                 }
                 this.calctotal();
            },
            algototal(row,prt){
                if(prt.projectGroups != undefined){
                    prt.projectGroups.forEach((g)=>{
                        var totala = 0
                        if(g.projectSections != undefined){
                            g.projectSections[0].algo = 0;

                            g.projectSections.forEach((s)=>{
                                if(s.isparent != true){
                                  totala += s.algo;
                                }
                            })
                            g.projectSections[0].algo = totala;
                        }
                    })
                }
            },
            calctotal(row,prt){
                this.form.duration = 0
                this.form.ehprojectparts.forEach((pp)=>{
                 pp.duration = 0;
                if(pp.projectGroups != undefined){
                    pp.projectGroups.forEach((g)=>{
                        var totala = 0
                        if(g.projectSections != undefined){
                            g.projectSections[0].calc = 0;
                            g.projectSections.forEach((s)=>{
                                if(s.isparent != true){
                                    pp.duration += s.calc;
                                    totala += s.calc;
                                    this.form.duration += s.calc;

                                }
                            })
                            g.projectSections[0].calc = totala;
                        }
                    })
                }
                })
                this.form.duration += this.adcalc;
                if(row != undefined){
                  this.canedit(row);
                }
            },
            canedit(row){
                row.isedit = true;
            },
            cellclassnameheaderadmin(){
                 return {
                         background: "#f3f3f3",
                         padding:'0px',
                       };
            },
            cellclassnameheader(row, column, rowIndex, columnIndex){
                 if (row.columnIndex == 2 ||row.columnIndex == 6 ||row.columnIndex == 10){
                    return {
                        "border-top-style": "none",
                        "border-bottom-style": "none",
                        padding:'0px',
                    };
                }else{
                     return {
                         background: "#f3f3f3",
                         padding:'0px',
                       };
                }
            },
            cellclassname(row, column, rowIndex, columnIndex) {
                if (row.columnIndex == 2 ||row.columnIndex == 6 ||row.columnIndex == 10){
                    return {
                        "border-top-style": "none",
                        "border-bottom-style": "none",
                    };
                }
            },
            issections(data,name){
                var name1 = "";
                if(name == "Substructure"){name1 = "substructure"}
                if(name == "External"){name1 = "external"}
                if(name == "Superstructure"){name1 = "superstructure"}

                  var arra = [];
                  var s2 = JSON.parse(JSON.stringify(this.sections))
                s2[name1].forEach((s)=>{
                       var d =  data.find(t => t.sectionid == s.id);
                       if(d == null){
                           s.isedit = false;
                           s.id = null;
                            s.p1bd = 0;
                            s.p1ad = 0;
                            s.p2bd = 0;
                            s.p2ad = 0;
                           arra.push(s);
                       }else{
                           d.isedit = true;
                            d.p1bd = 0;
                            d.p1ad = 0;
                            d.p2bd = 0;
                            d.p2ad = 0;
                          d.section = s.section;
                       }
                    });
                     var d1 =  [...arra,...data]
                   var r1 =  d1.sort((a,b) => (a.section > b.section) ? 1 : ((b.section > a.section) ? -1 : 0))
                   return [...[{ isparent: true, section:name,
                                       p1bd :0,
                                       p1ad :0,
                                       p2bd :0,
                                       p2ad :0, }],...r1];

            },
            projectnamereplace(){
                 if(this.form.id != null){
                 const zeroPad = (num, places) => String(num).padStart(places, '0')
                        var pname = "EH."+zeroPad(this.form.id,3);
                       var p1 =  this.form.projectname.replace(pname,"")
                       this.form.projectname = p1;
                        if(this.form.projectnameheader == undefined){
                            this.form.projectnameheader ="";
                        }
                        this.form.projectnameheader = pname;
                 }
            },
            getprojecthours(projectid,v1) {
                var data = { id: projectid,version:v1};
                this.$http
                    .post("HoursEstimate/getprojecthours", data)
                    .then((response) => {
                        this.form = response.data;
                        if(this.form.admins.length == 0){
                              this.form.admins = JSON.parse(JSON.stringify(this.admins));
                        this.form.admins.forEach(e => {
                            e.version = this.form.version;
                         });
                        }
                        this.projectnamereplace();
                        var arr = []
                        this.form.versions.forEach((x,index)=>{
                               arr.push({name:x,index:index})
                        })
                        if(arr.length == 0){
                          this.form.versions = [{name:"01.00",index:0}];
                          this.form.version = "01.00";
                          this.cversion = "01.00";
                        }else{
                          this.form.versions = arr;
                          this.cversion = this.form.version;
                        }
                        if (this.form.ehprojectparts.length == 0) {
                            this.form.admins = JSON.parse(JSON.stringify(this.admins));
                             this.form.admins.forEach(e => {
                                        e.version = this.cversion;
                             });
                            this.addprojectpart();
                        } else {
                            this.form.ehprojectparts.forEach((pr) => {
                                // if(pr.projectGroups != undefined)
                                // {
                                //     this.algototal(null,pr)
                                // }
                                pr.projectGroups[0].chunk = "Building footprint";
                                pr.projectGroups[1].chunk = "Gross Internal Floor Area";
                                pr.projectGroups[2].chunk = "8.2 Hard surfaces";
                                pr.projectGroups.forEach((g) => {
                                    g.projectSections = this.issections(g.projectSections,g.groupname)
                                    g.project3 = {
                                        projectname: "",
                                        tsalastdate: new Date(),
                                        projectparts: [],
                                    };
                                    g.criterias = {
                                        criterias1: {},
                                        criterias2: {},
                                        criterias3: {},
                                        criterias4: {},
                                    };
                                    this.selectProject(
                                        g.project1,
                                        g,
                                        "project1",
                                        "projectid1"
                                    );
                                    this.projectpartchange(g,1);
                                    this.selectProject(
                                        g.project2,
                                        g,
                                        "project2",
                                        "projectid2"
                                    );
                                    this.projectpartchange(g,2);
                                });
                            });
                        }
                        this.algochange();
                    })
                    .catch((err) => {
                        this.$bus.$emit("error", err);
                    });
            },
            isvalid(){
                  var msg = [];
                  if(this.form.projectname == "" || this.form.projectname == undefined){
                       msg.push("EHProject Name must not be empty");
                  }
                  if(this.form.projectname != undefined){
                     if(this.form.projectname.length >= 49 ){
                          msg.push("EHProject Name must  be 49 characters");
                     }
                  }
                 this.form.ehprojectparts.forEach(p => {
                     if(p.name == "" || p.name == undefined)
                     {
                          msg.push("EHProject part Name must not be empty");
                     }
                     if(p.name != undefined){
                         if(p.name.length >= 56)
                        {
                             msg.push("EHProject part Name must be 55 characters");
                        }
                     }
                 });
                  if (msg.length > 0) {
                    var style = "<style> .el-message-box {width:50%;} </style>";
                    this.$alert(msg.join("<br />") + style, "Invalid Data", {
                      dangerouslyUseHTMLString: true,
                    });
                    return false;
                  }
               return true;
            },
            save() {
                if(this.isvalid()){
                var arr = [];
                 this.form.versions.filter((x) =>{
                      arr.push(x.name)
                     });
                this.form.versions = arr
                // this.form.version = this.cversion;
                this.form.ehprojectparts.forEach((x) => {

                    x.projectGroups.forEach((g) => {
                        var arr = [];
                        g.projectSections.forEach((s) => {
                            if (s.isparent != true && s.isedit == true) {
                                arr.push(s);
                            }
                        });
                        g.projectSections = arr;
                    });
                });
                this.post("HoursEstimate/saveprojecthours", this.form).then(
                    (data) => {
                         this.$message({
                             message: 'Project Hours Save successfully',
                             type: 'success'
                           });
                           this.form = {
                                versions: [{name:"01.00",index:0}],
                           };
                           this.$router.go(-1)
                    }
                );
                }
            },
            projectchange(event, data, name, id,partidname) {
                if (event == "") {
                    data[id] = null;
                    data[name] = {};
                    data[name] = { tsalastdate: new Date(), projectparts: [] };
                    data[name].projectparts = [];
                    data["projectpartid"+partidname] = null;
                }
            },
            getprojectpart(data) {
                this.$http
                    .post("project/getprojectpart", data)
                    .then((response) => {
                        if (response.data.length <= 0) {
                            response.data.push({ id: 0, name: "Single Bill" });
                        }
                        data.projectparts = response.data;
                    })
                    .catch((err) => {
                        this.$bus.$emit("error", err);
                    });
            },
            gettimesheet(d1, row, index) {
                if (d1.row.projectSections == undefined) {
                    return [];
                }
                var proj1aduration = 0;
                var proj1bduration = 0;
                var proj2aduration = 0;
                var proj2bduration = 0;

                d1.row.projectSections.forEach((s) => {
                    if (s.ts1.totaldurationint != undefined) {
                        proj1aduration += this.MinutesToTimeInt(s.ts1.totaldurationint);
                        if (s.ts1.ph != undefined) {

                             proj1bduration += this.MinutesToTimeInt(s.ts1.totaldurationint) + s.ts1.ph.cleaninghours;

                        }
                    }
                    if (s.ts2.totaldurationint != undefined) {
                        proj2aduration += this.MinutesToTimeInt(s.ts2.totaldurationint);
                        if (s.ts2.ph != undefined) {

                           proj2bduration += this.MinutesToTimeInt(s.ts2.totaldurationint) + s.ts2.ph.cleaninghours;

                        }
                    }
                });
                this.$set(d1.row,'p1ad',this.isnunornot(proj1aduration));
                this.$set(d1.row,'p1bd',this.isnunornot(proj1bduration));
                this.$set(d1.row,'p2ad',this.isnunornot(proj2aduration));
                this.$set(d1.row,'p2bd',this.isnunornot(proj2bduration));
                // d1.row.p1ad =
                //     this.isnunornot(proj1aduration) + ".0";
                // d1.row.p1bd =
                //     this.isnunornot(proj1bduration) + ".0";
                // d1.row.p2ad =
                //     this.isnunornot(proj2aduration) + ".0";
                // d1.row.p2bd =
                //     this.isnunornot(proj2bduration) + ".0";

                return d1.row.projectSections;
            },
            opencriteriadialog(data, pr2index, prindex) {
                this.criterias1 = data.criteria1id;
                this.criterias2 = data.criteria2id;
                this.criterias3 = data.criteria3id;
                this.criterias4 = data.criteria4id;
                this.criteriadata = data;
                this.criteriadata.prindex = prindex;
                this.criteriadata.pr2index = pr2index;
                this.dialogcriteria = true;
            },
            closecriteriadialog() {
                var proj2 =
                    this.form.ehprojectparts[this.criteriadata.prindex]
                        .projectGroups[this.criteriadata.pr2index];
                if (this.criterias1 != undefined) {
                    proj2.criterias.criterias1 = this.findepspcriteriasbyid(
                        this.criterias1
                    );
                    proj2.criteria1id = this.criterias1;
                }
                if (this.criterias2 != undefined) {
                    proj2.criterias.criterias2 = this.findepspcriteriasbyid(
                        this.criterias2
                    );
                    proj2.criteria2id = this.criterias2;
                }
                if (this.criterias3 != undefined) {
                    proj2.criterias.criterias3 = this.findepspcriteriasbyid(
                        this.criterias3
                    );
                    proj2.criteria3id = this.criterias3;
                }
                if (this.criterias4 != undefined) {
                    proj2.criterias.criterias4 = this.findepspcriteriasbyid(
                        this.criterias4
                    );
                    proj2.criteria4id = this.criterias4;
                }
                this.form.ehprojectparts[this.criteriadata.prindex].projectGroups[
                    this.criteriadata.pr2index
                ] = proj2;
                this.criteriadata = new Object();
                this.dialogcriteria = false;
            },
            getcriteria: function () {
                this.$http
                    .post("project/getallpspcriteria")
                    .then((response) => {
                        this.pspcriterias = response.data;
                    })
                    .catch((err) => {
                        this.$bus.$emit("error", err);
                    });
            },
            createnewfunction(data) {
                var f1 = (a, b) => {
                    if (a.name == b.section) {
                        if (b.projectSections == undefined) {
                            b.projectSections = [];
                        }else{
                            b.projectSections.forEach(ps => {
                                ps[data] = new Object();
                            });
                        }
                        a.timesheets.forEach((t1) => {
                            var s1 = b.projectSections.find((x) => x.userdetail == t1.u.firstname);
                            if (s1 == null) {
                                var section = {};
                               section = JSON.parse(JSON.stringify( this.ehproejctsection));
                                // section.userdetail = t1.u.firstname;
                                this.$set(section,'userdetail',t1.u.firstname);
                                this.$set(section,data,t1);
                                this.$set(section,'sectionid',a.id);
                                // section[data] = t1;
                                // section.sectionid = a.id;
                                b.projectSections.push(section);
                            }
                            else {
                                this.$set(s1,data,new Object());
                                // s1[data] = new Object();
                                this.$set(s1,data,t1);
                                // s1[data] = t1;
                            }
                        });
                           this.gettimesheet({row:b})
                        return true;
                    }
                };
                return f1;
            },
            gethours(data) {

                var arr = [];
                if (data.project1.projectname != "" && data.project1.projectname != null) {
                    arr.push(data.project1.projectname);
                }else{
                    if(data.projectSections != undefined){
                     data.projectSections.forEach(ed => {
                                    if(ed.projectSections != undefined){
                                        ed.projectSections.forEach((x)=>{
                                            x.ts1 = {};
                                            })
                                    }
                                });
                    }
                }
                if (data.project2.projectname != "" && data.project2.projectname != null) {
                    arr.push(data.project2.projectname);
                }else{
                    if(data.projectSections != undefined){
                     data.projectSections.forEach(ed => {
                                    if(ed.projectSections != undefined){
                                        ed.projectSections.forEach((x)=>{
                                            x.ts2 = {};
                                            })
                                    }
                                });
                    }
                }
                // var arr = [data.project2.projectname,data.project3.projectname];
                if (arr.length != 0) {
                     this.loading = true;
                    this.$http
                        .post("/HoursEstimate/gethoursbyname", arr)
                        .then((response) => {
                            var f1 = this.createnewfunction("ts1");
                            var f2 = this.createnewfunction("ts2");
                            if (data.project1.projectname != "" && data.project1.projectname != null) {
                                var project = response.data.find(
                                    (x) =>
                                        x.p.name.toLowerCase() ==
                                        data.project1.projectname.toLowerCase()
                                );
                                if (project != null) {
                                    var part = data.project1.projectparts.find(
                                        (x) => x.id == data.projectpartid1
                                    );
                                    if (part != null) {
                                        var pr = this.findbyname(
                                            project.p.projectparts,
                                            "name",
                                            part.name
                                        );
                                        if(pr != undefined){
                                          pr.actionsview.forEach((x) => {
                                              var as = this.comparer(x.pactions, data.projectSections,f1);
                                          });
                                        }
                                    }
                                }
                            }
                            if (data.project2.projectname != "" && data.project2.projectname != null) {
                                var project = response.data.find(
                                    (x) =>
                                        x.p.name.toLowerCase() ==
                                        data.project2.projectname.toLowerCase()
                                );
                                if (project != null) {
                                    var part = data.project2.projectparts.find(
                                        (x) => x.id == data.projectpartid2
                                    );
                                    if (part != null) {
                                        var pr = this.findbyname(
                                            project.p.projectparts,
                                            "name",
                                            part.name
                                        );
                                        if(pr != undefined){
                                        pr.actionsview.forEach((x) => {
                                            var as = this.comparer(x.pactions,data.projectSections,f2);

                                        });
                                        }
                                    }
                                }
                            }
                            this.loading = false;
                        })
                        .catch((err) => {
                            this.loading = false;
                            this.$bus.$emit("error", err);
                        });
                }
            },
            findbyname(arr, obj, value) {
                return arr.find((x) => x[obj] == value);
            },
            filterbyname(arr, obj, value) {
                return arr.filter((x) => x[obj] == value);
            },
            deleteprojectpart(index,col) {
                if(col != undefined && col != 1){
                        var colrow = (col * 4)-4;
                         this.form.ehprojectparts.splice((index-1)+colrow, 1);
                }else{
                    this.form.ehprojectparts.splice(index, 1);
                }
                if(this.form.ehprojectparts.length == 0){
                    this.addprojectpart();
                }
            },
            addprojectpart:function(index,col) {
               if (this.form.ehprojectparts == undefined) {
                    this.form.ehprojectparts = [];
                }
               const t1 = [];
                this.projectGroups.forEach((x) => {
                    var group = {};
                    for (const [key, value] of Object.entries(x)) {
                        group[key] = value;
                    }
                    group.criterias = { criterias1: {},
                                        criterias2: {},
                                        criterias3: {},
                                        criterias4: {},};
                    t1.push(group);
                });
                t1.forEach((x)=>{
                    this.getsectionsdata(x);
                })
                 var myobj ={
                        name: "",
                        duration: 0,
                        projectGroups: t1,
                        version:this.form.version,
                        pd:{},
                 };
                 if(index == undefined && col == undefined){
                     this.form.ehprojectparts.push(myobj);
                 }else{
                     if(col != undefined && col != 1){
                        var colrow = (col * 4)-4;
                        this.form.ehprojectparts.splice((index)+colrow,0,myobj);
                     }else{

                        this.form.ehprojectparts.splice(index+1,0,myobj);

                    }
                 }
                 if(this.$refs.fixboxscrole){
                     this.$refs.fixboxscrole.forEach((x)=>{
                         x.doLayout();
                     })
                 }
                 this.$forceUpdate();
            },
            tableRowClassName({ row, rowIndex }) {
                return "dark-gray-row";
            },
            tablecellClassName: function ({ row, column, rowIndex, columnIndex }) {
                return {
                    background: "#a9a8a8",
                    color: "white",
                    font: "bold",
                    "padding-top": "0px",
                    "padding-bottom": "0px",
                };
            },
            selectProject(event, data, name, id) {
                if(event.id != 0){
                  data[id] = event.id;
                  data[name] = event;
                  this.getprojectpart(data[name]);
                }
            },
            sectionpart(data) {
                var obj = { substructure: [], superstructure: [], external: [] };
                data.forEach((s) => {
                    s.userdetail = "";
                    s.algo = 0;
                    s.calc = 0;
                    s.ts1 = {};
                    s.ts2 = {};
                    s.comment = "";
                    s.sectionid = s.id;
                    s.projectgroupid = null;
                    var res = parseInt(s.section);
                    if (res == 1) {
                        obj.substructure.push(s);
                    }
                    if (res == 8 || res == 0) {
                        obj.external.push(s);
                    }
                    if (res != 1 && res != 8 && res != 0) {
                        obj.superstructure.push(s);
                    }
                });
                this.sections = obj;
            },
            comparer: function (a, b, isSameUser) {
                const onlyInLeft = (left, right, compareFunction) =>
                    left.filter((leftValue) =>
                        right.some((rightValue) =>
                            compareFunction(leftValue, rightValue)
                        )
                    );

                const onlyInA = onlyInLeft(a, b, isSameUser);
                return onlyInA;
            },
            addnewprojectornot(){
                if (this.$route.query.id > 0) {
                    this.getprojecthours(this.$route.query.id,null);
                } else {
                    this.get("HoursEstimate/getEHProjectUniqueCode").then((res) => {
                        if (res.data != null) {
                            const zeroPad = (num, places) => String(num).padStart(places, '0')
                            this.form.projectnameheader = "EH."+zeroPad(res.data, 3);
                        }
                        this.form.version= "01.00"
                        this.form.admins = JSON.parse(JSON.stringify(this.admins));
                        this.form.admins.forEach(e => {
                            e.version = this.form.version;
                        });
                        this.addprojectpart();
                    });
                }
            },
        },
        created() {
             if (this.$store.state.sections.length != 0) {
                this.sectionpart(this.$store.state.sections);
                  this.addnewprojectornot();
            }else{
                this.getsections().then((data)=>{
                     this.sectionpart(data);
                       this.addnewprojectornot();
                    });
            }
            // this.$bus.$on("setsection", (sections) => {
            //     this.sectionpart(sections);
            // });
            this.getcriteria();


        },
    };
</script>
<style>
.adminrow{
   width: 100%;
   height: 30px;
   background-color: #a9a8a8;
   color:white;
}
.admincenter{
   top: 50%;
}
.bold{
    font-weight: bold;
}
.commenstyle {
    border-top-style: none;
    border-bottom-style: none;
}
.el-table .dark-gray-row {
    background: #a9a8a8;
    color: white;
}
.NOM-input-fixwidth {
    width: 40px; /* or whatever width you want. */
    max-width: 40px; /* or whatever width you want. */
    /* padding: 1px; */
}

.NOM-input-fixwidth .el-input--small .el-input__inner {
    height: 20px;
}

.Calc-input-fixwidth {
    width: 50px; /* or whatever width you want. */
    max-width: 50px; /* or whatever width you want. */
    /* padding: 1px; */
}
.Calc-input-fixwidth-section{
     width: 100%; /* or whatever width you want. */
    max-width: 100%; /* or whatever width you want. */
}
.Calc-input-fixwidth .el-input--small .el-input__inner {
    height: 20px;
}
.Calc-input-fixwidth .el-input-number--small {
    line-height: 14px;
}
.Calc-input-fixwidth-section .el-input--small .el-input__inner {
    height: 27px;
}
.Calc-input-fixwidth-section .el-input-number--small {
    line-height: 14px;
}
.sincs-date.el-date-editor.el-input,
.el-date-editor.el-input__inner {
    width: 80px;
}
.icon-btn-width {
    padding: 7px;
}

.saved-style {
    margin-left: 10px;
}
.custom-date-picker {
    width: 72px !important;
}
.marginleft{
    margin-left: 8px;
}

 .table-cell-space-remove.el-table th.el-table__cell {
      line-height: 10px;
  }

  .fix-box-scrole{
      width: 73%;
      overflow-x: auto;
      white-space: nowrap;
      display:flex;
  }
</style>
