<template>
  <div v-show="showdata">
    <el-row>
    <span class="headerstyle"> Update Database Structure</span>
    </el-row>

    <div>
      <el-button type="primary" @click="update">Update</el-button>
    </div>
    <br />

    <div class="wrapColumnText">
      <el-table :data="form"  border stripe>
        <!-- <el-table-column prop="id" label="ID" width="40" align="center"> </el-table-column> -->

        <el-table-column prop="querynumber" label="Query Number" width="105" align="center">
        </el-table-column>

        <el-table-column prop="checkquery" label="Check Query">
             <template slot-scope="scope">
                <el-tooltip effect="light" placement="bottom-start" :content="scope.row.checkquery">
                    <span>{{ scope.row.checkquery }}</span>
                </el-tooltip>
            </template>
        </el-table-column>

        <el-table-column prop="updatequery" label="Update Query">
            <template slot-scope="scope">
                <el-tooltip effect="light" placement="bottom-start" :content="scope.row.updatequery">
                    <span>{{ scope.row.updatequery }}</span>
                </el-tooltip>
            </template>
        </el-table-column>

        <el-table-column :formatter="cellValueRenderer" prop="issuccess" label="Status" width="60" align="center">
        </el-table-column>
         <el-table-column align="left" prop="checkmethod" width="100" label="Check Method" >
             <template slot-scope="scope">
                 <div>
                     <div class="left">
                 {{getCheckMethod(scope.row)}}
                    </div>
                 </div>
             </template>
        </el-table-column>

        <el-table-column prop="updatedon" label="Updated On" :formatter="dateformatter" width="140" align="center">
        </el-table-column>

        <el-table-column prop="errordetail" label="Error Message">
        </el-table-column>

        <el-table-column label="Delete" fixed="right" align="center" width="60">
          <template slot-scope="scope">
            <el-button size="mini" type="primary" icon="el-icon-delete" v-if="scope.row.id > 0" @click="deleteRow(scope.row)"></el-button>            
          </template>
        </el-table-column>

        <el-table-column label="Run" fixed="right" align="center" width="60">
          <template slot-scope="scope">
              <el-button size="mini" type="primary" icon="el-icon-refresh" @click="runRow(scope.row)"></el-button>
          </template>
        </el-table-column>
      </el-table>
      <div>
        <el-button type="primary" @click="recycle">Recycle</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: [],
      currentuser: {},
      showdata: false
    };
  },
    computed:{
        getCheckMethod(){
                return function(data){
                  
                  let name = '';
                    if(data.checkmethod == 1){name = 'Data Not Exist'}
                    if(data.checkmethod == 2){name = 'Data Exist'}
                    return name;
                }
        },
    },
  methods: {
    cellValueRenderer: function (row, column, cellValue) {
      let value = cellValue;
      if (typeof row[column.property] === "boolean") {
        value = String(cellValue);
      }
      return value;
    },

    dateformatter: function (row, column, value, index) {
      if (value != "0001-01-01T00:00:00") {
        var d = new Date(value);
        return d.toLocaleString();
      } else {
        return "";
      }
      return value;
    },

    refresh: function () {
      this.showloading();
      this.$http
        .post("account/viewupdatestructure")
        .then((response) => {
          this.hideloading();
          this.form = response.data;
        })
        .catch(err =>{
          this.hideloading();
          this.$bus.$emit("error", err);
        });
    },
    recycle: function () {
      this.showloading();
      this.$http
        .post("account/exit")
        .then((response) => {
          this.hideloading();
          this.$alert(response.data);
        })
        .catch(err =>{
          this.hideloading();
          this.$bus.$emit("error", err);
        });
    },
    update: function () {
      this.showloading();
      this.$http
        .post("account/getupdatehistory")
        .then((response) => {
          this.hideloading();
          this.refresh();
        })
        .catch(err =>{
          this.hideloading();
          this.$bus.$emit("error", err);
        });
    },

    deleteRow: function (row) {
      this.showloading();
      this.$http
        .post("/account/deletequery", { id: row.id })
        .then((response) => {
          this.$message({
            showClose: true,
            message: response.data,
            type: "success",
          });
          this.hideloading();
          this.refresh();
        })
        .catch(err =>{
          this.hideloading();
          this.$bus.$emit("error", err);
        });
    },

    runRow: function (row) {
      this.showloading();
      this.$http
        .post("/account/runquery", {
          querynumber: row.querynumber,
          checkquery: row.checkquery,
          updatequery: row.updatequery,
          checkmethod:row.checkmethod
        })
        .then((response) => {
          this.hideloading();
          this.refresh();
        })
        .catch(err =>{
          this.hideloading();
          this.$bus.$emit("error", err);
        });
    },
  },

  created: function () {
          if (this.$store.state.user.role == undefined) {
                 this.$bus.$on('setuser', (user) => {
                    if (user.role == "A") {
                        this.refresh();
                        this.showdata = true;
                    }
                    else{
                        this.$router.push("/");
                        this.showdata = false;
                    }
                });
            }
            else if(this.$store.state.user.role == 'A'){
                this.refresh();
                this.showdata = true;
            }
            else{
                this.$router.push('/')
            }
  },
};
</script>

<style scoped>
.el-tooltip__popper.is-light {
    background: #FFF;
    border: 1px solid #303133;
    white-space: pre;
}
</style>
