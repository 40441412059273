<template>
    <div>
        <div class="row">
      <div class="fixwidth">
                <el-autocomplete
                 popper-class="morewidth-autocomplete-project"
                    class="inline-input fullwidth bold padding2px"
                    v-model="project.projectname"
                    :disabled="setdisable('project')"
                    :fetch-suggestions="(query, done)=>{return searchProject(query, done,training)}"
                    @select="selectProject"
                    value-key="projectname"
                    :placeholder="
                        Similar.projectPlaceholder == ''
                            ? 'Please select Project'
                            : Similar.projectPlaceholder"
                >
                    <el-button
                        slot="suffix"
                        @click="closeproject"
                        type="text"
                        size="mini"
                        >&times;</el-button
                    >
                    <template slot-scope="{ item }">
                        <span style="float: left">{{ item.projectname }}</span>
                        <span style="float: right">{{
                            item.accessedon | dateformat
                        }}</span>
                    </template>
                </el-autocomplete>
             </div>
             <div class="left">
                <div class="left">
                    <el-select
                    :disabled="setdisable('part')"
                        v-model="ProjectPartid"
                        filterable
                        :placeholder="
                            Similar.partPlaceholder == ''
                                ? 'Project Parts'
                                : Similar.partPlaceholder
                        "
                        class="fullwidth padding2px bold"
                        @change="changepart"
                        v-bind:style="Similar.partstyle == null ? { width: '250px' } : Similar.partstyle"
                    >
                        <el-option
                            v-for="item in ProjectParts"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id"
                        >
                        </el-option>
                    </el-select>
                </div>
             </div>
             <div v-if="Similar.lastsavedshow">
                <div  class="left spaceLR margintop3px">
                    <div class="el-input el-input--mini bold">
                        {{ project.library }}
                    </div>
                </div>
                <div  class="left spaceLR margintop3px">
                    <div class="el-input el-input--mini bold">
                        <span v-if="lastsaved">{{ lastsaved }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import moment from "moment";
export default {
    props: {
        Similar: {
            projectPlaceholder: { type: String, default: "" },
            partPlaceholder: { type: String, default: "" },
            lastsavedshow: { type: Boolean, default: false },
            projectpartshow: { type: Boolean, default: true },
            projectsize: { type: Number, default: 5 },
            projectids:{type:Object,default:null },
            close:{type: Boolean, default: false},
            projectdisabled:{ type: Boolean, default: false },
            partdisabled:{ type: Boolean, default: false },
            partstyle:{ type: Object, default: null }
        },
        remember: { type: Boolean, default: true },
        training:{type:Boolean,default:false},
    },

    data() {
        return {
            part: {},
            Projectobj: {
                project: {},
                part: {}
            },
            project: {
                id: 0,
                projectname: "",
                library: "",
                section: {}
            },

            ProjectParts: [],
            ProjectPartid: null
        };
    },

    computed: {
        setdisable(){
            return function(data){
               if(this.Similar && this.Similar[data+'disabled']){
                   return this.Similar[data+'disabled'];
               }else{
                   return false;    
               }
            }
        },
        lastsaved: function() {
            if (this.project && this.project.createdon) {
                var dt = new Date(this.project.createdon);
                return moment(dt).format("DD-MMM-YYYY"); // dt.toLocaleString().replace(/:\d{2}\s/, " ");
            }
        }
    },
    methods: {
        changepart: function(event) {
            var projectpart = this.ProjectParts.find(p => p.id == event);
            if (projectpart && this.remember == true) {
                this.$store.state.projectpart = projectpart;
                this.$store.state.partname = projectpart.name;
            }
            this.part = projectpart;
            this.$store.state.projectpart = projectpart;
            this.Projectobj.project = this.project;
            this.Projectobj.part = this.part;
            this.Projectobj.ProjectParts = this.ProjectParts;
            this.$emit("projectpartchange", this.Projectobj);
        },
        selectProject: function(item) {

            if (this.remember) {
                this.$store.state.project = JSON.parse(JSON.stringify(item));
            }
            this.project = item;
            this.getPart();
        },

        closeproject: function() {
            this.project = {
                id: 0,
                projectname: "",
                library: ""
            };
            this.part = null;
            this.ProjectParts = [];
            this.ProjectPartid = null;
            this.Projectobj.project = this.project;
            this.Projectobj.part = this.part;
            this.$emit("projectpartchange", this.Projectobj);

        },
         addprojectbyid(proj){
           if(proj.projectid == null && proj.partid == null){
              this.project = {
                id: 0,
                projectname: "",
                library: ""
            };
            this.part = null;
            this.ProjectPartid = null;
               return;
           }
         if(proj.projectid != null){
          return this.post('project/getprojectbyid',{id:proj.projectid}).then((response)=>{
        //     this.$nextTick(()=>{
        //    this.project = response.data;
        //      this.project.projectname = response.data.projectname;
        //      return this.post('project/getsections',this.project).then((response)=>{
                 this.$nextTick(()=>{
                    this.project = response.data;
                      this.project.projectname = response.data.projectname;
                     this.ProjectParts = response.data.projectparts;
                    this.project = response.data;
                    // this.project.section = response.data.projectsectionsview
                    if (
                        this.ProjectParts.length == 0 &&
                        this.project.projectname != ""
                    ) {
                        this.ProjectParts.unshift({
                            id: 0,
                            name: "Single Bill"
                        });
                    }
                    if(proj.partid != null){
                    var part = this.ProjectParts.find(x=> x.id == proj.partid);
                    this.$store.state.partname = part.name;
                    this.getCurrentPart();
                    }
                    // if(proj.sectionid != null){
                    //  var section = this.partsections.find(x=> x.id == proj.sectionid);
                    // this.$store.state.sectiondisplay = section.sectiondisplay;
                    // }
                    // this.getCurrentSection();
                    this.$store.state.projectids = null;
                 });
               });
            //   })
        //   })
         }
    },
        getCurrentPart: function() {
            this.ProjectPartid = null;
            if (this.$store.state.partname) {
                var part = this.ProjectParts.find(
                    s => s.name == this.$store.state.partname
                );
                if (part) {
                    this.ProjectPartid = part.id;
                    this.part = part;
                }
            }
            if (this.ProjectPartid == null) {
                if (this.$store.state.projectpart) {
                    var part = this.ProjectParts.find(
                        s => s.id == this.$store.state.projectpart.id
                    );
                    if (part) {
                        this.ProjectPartid = part.id;
                        this.part = part;
                    }
                }
            }
            if (part != undefined) {
                this.changepart(part.id);
                this.Projectobj.project = this.project;
                this.Projectobj.sectionid = this.sectionid;
                this.Projectobj.part = this.part;
            }
        },
        getPart: function() {

            this.$http
                .post("project/getsections", this.project)
                .then(response => {
                    this.$store.state.projectsections = [];
                    this.$store.state.projectsections = response.data.projectsectionsview;
                    this.ProjectParts = response.data.projectparts;
                    this.project = response.data;
                    // this.project.section = response.data.projectsectionsview
                    if (
                        this.ProjectParts.length == 0 &&
                        this.project.projectname != ""
                    ) {
                        this.ProjectParts.unshift({
                            id: 0,
                            name: "Single Bill"
                        });
                    }
                    this.getCurrentPart();
                })
                .catch(err => {
                    this.$bus.$emit("error", err);
                    this.loading = false;
                });

        }
    },
     destroyed() {
    this.$bus.$off("addprojectpart")
  },
    created:function ()  {
        if(this.training == false && this.$store.state.project != undefined && this.$store.state.project.istraining == true){
            this.$store.state.project = "";   
        }
         this.$bus.$on("addprojectpart", ()=>{
            if(this.Similar.projectids != null){
              var data = this.Similar.projectids;
              this.addprojectbyid(data)
              this.Similar.projectids = null;
           }
         });
         if( this.Similar.close == undefined){
         if(this.Similar.projectids != null) {
            var data = this.Similar.projectids;
            this.addprojectbyid(data);
            this.Similar.projectids = null;
         }else{
            if (this.$store.state.project) {
                var project = JSON.parse(JSON.stringify(this.$store.state.project));
                this.selectProject(project);
            }
         }
         }else{
             if(this.Similar.close == true){
              this.project = {
                id: 0,
                projectname: "",
                library: ""
            };
            this.part = null;
            this.ProjectParts = [];
             }
         }
    }
};
</script>
<style>

</style>
