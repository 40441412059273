import Vue from "vue";
import VueRouter from "vue-router";
import axios from "axios";
import { routes } from "./routes";
import store from "./store";
import EventBus from "./eventbus";
// import training from "./classes/training"
// export const EventBus = new Vue();

// const train = new training();
Vue.use(VueRouter);

// var base = document.getElementsByTagName("a")[0].href; //an @Html.ActionLink placed on index.cshtml to get the base directory
// base = base.replace(location.origin, "");

let router = new VueRouter({
    mode: "history",
    relative: false,
    routes
});


router.beforeEach((to, from, next) => {
    store.state.currentpage = to.name;
    if (to.name == "public") {
        next();
    }
    else {
        var token = localStorage.getItem("token");
    if (token != null) {
        axios.defaults.headers.common["Authorization"] = "Bearer " + token;
        if (store.state.user.id == undefined) {

             axios
                .post("account/getuserdetails")
                .then(response => {
                    store.state.user = response.data;
                    var user = store.state.user;
                    EventBus.$emit("setuser", user);
                    axios.defaults.headers.common["Authorization"] =
                        response.headers.authorization;
                        Vue.prototype.$training.getdirectionsbypagename(to);
                        if (
                            to.name == "access") {
                            return next();
                        }
                    var role = user.userrole.rolepermissionpages.find(x => x.p.name === to.name && x.rp.permission != 0);
                        EventBus.$emit('userguidegotit', role, to,null);

                    store.state.role = null;
                    if(user.role == "A"){
                        store.state.role = { p: {}, rp: { permission:2 }};
                        return next();
                    }
                    if (role) {
                        store.state.role = role;
                        return next();
                    } else {

                        return router.push("/");
                    }
                })
                .catch(error => {
                    EventBus.$emit("error", error);
                    EventBus.$emit("logout", null);

                });
        } else {
            Vue.prototype.$training.getdirectionsbypagename(to);
            var user = store.state.user;
            if(to.name == "access"){
                return next();
            }
            var rout = user.userrole.rolepermissionpages.find(x => x.p.name === to.name && x.rp.permission != 0);
            EventBus.$emit('userguidegotit', rout, to,null);
            
            store.state.role = null;
            if(user.role == "A"){
                store.state.role = { p: {}, rp: { permission:2 }};
                return next();
            }
            if (rout) {
                store.state.role = rout;
                return next();
            } else {
                return router.push("/");
            }
        }
    } else {
        if (to.path !== "/login") {
            next("/login");
        } else {
            next();
        }
    }
    }    
});
export default router;
