<template>
    <div>
        <slot/>
    </div>
</template>
<script>
export default{
    provide(){
        return{
            clickevant:this.clickevant,
            setdirectiononclickevent:this.setdirectiononclickevent
        }
    },
    data(){
        return{
            directions:[],
            directionids:[],
            allevents:[],
        }
    },
    computed:{
        isvalidpage(){
            return function(name){
                if(name != "access" && name != "updatedatabasestructure" && name != "errorlog" && name != "superadmin"){
                    return true;
                }else{
                    return false;
                }
            }
        }
    },
    watch:{
        $route(to, from){
            // this.destroyEventListener();
            this.routerchage(to)
        }
    },
    methods:{
        clickevant(name,id,event,eventname){
            var e = null;
            var dd = this.directionids[name];
            if(id != undefined){
             name = id;
            }
            var e = document.getElementById(name);
            if(dd != undefined){
                var isshow = false;
                if(dd.direction.directionsections != undefined && dd.direction.directionsections.length > 0){
                    var isall = dd.direction.directionsections.find(x => x.section == "All")
                    if(isall){
                        isshow = true;
                    }else{
                        if(this.$store.state.section != undefined){
                            var findsection = dd.direction.directionsections.find(x => x.section == this.$store.state.section);
                            if(findsection != undefined){
                                isshow = true;
                            }
                        }
                    }
                }
                if(isshow == true){
                      if(dd.direction.mediacontent.name == undefined){
                          dd.direction.mediacontent.name = "";
                      }
                      const option = { 
                      setcellbackground:false,
                      sectionname: "",
                      isedit: false,
                      id: dd.direction.id,
                      elementid:name,
                      boxtype: "Small 2 Part Pop up",
                      content: dd.direction.message,
                      mediacontent: dd.direction.mediacontent,
                      link: dd.direction.link,
                      offercloseoption: dd.direction.offercloseoption,
                      heading: dd.direction.directionname,
                      directiondegree: dd.direction.directiondegree,
                      uniqueid : 1,
                      element : e,
                      visible:true,
                      cellactionname:dd.direction?.cellaction?.actionname,
                      };
                      if(event != undefined){
                          option.clickevent = event; 
                          option.clickeventname = eventname;
                      }
                      this.$store.state.currentdirectionindex = 0;
                      this.$store.state.directionsdialogue = [];
                      this.$store.state.directionsdialogue = [option];
                      this.$store.state.currentdirection = option;
                }else{
                    if(event != undefined){
                        event();
                    }    
                }
            }else{
                if(event != undefined){
                    event();
                }
            }
                
            console.log("click event call");
            
        },
        getdirectionoption(e,direction,directioncelldetail){
            if(direction.mediacontent.name == undefined){
                direction.mediacontent.name = "";
            }
            const option = {    element: null,
               setcellbackground:false,
               uniqueid: null,
               visible: true,
               sectionname: "",
               isedit: false,
               id: direction.id,
               elementid:directioncelldetail.pagecell.cellname,
               boxtype: "Small 2 Part Pop up",
               content: direction.message,
               mediacontent: direction.mediacontent,
               link: direction.link,
               offercloseoption: direction.offercloseoption,
               heading: direction.directionname,
               directiondegree: direction.directiondegree,
               uniqueid : 1,
               element : e,
               visible:true,
           };
           return option;
        },
        // onClick(e,direction,directioncelldetail){
        // //     this.$nextTick(()=>{  
        // //     if(!e.target.hasAttribute("data-click-added")){
        // //         var isid = document.querySelector(`[id^="${e.currentTarget.id}"]`);
        // //         isid.addEventListener('click', (event)=>{this.onClick(event,direction,directioncelldetail)});
        // //         isid.setAttribute('data-click-added', 'true');
        // //     }
        // // })
        //     var option = this.getdirectionoption(e,direction,directioncelldetail)
        //     this.$store.state.currentdirectionindex = 0;
        //     this.$store.state.directionsdialogue = [];
        //     this.$store.state.directionsdialogue = [option];
        //     this.$store.state.currentdirection = option;
        // },
        setdirectiononclickevent(){
            // this.$nextTick(()=>{
                if(this.directions && this.directions.length > 0){
                    this.directions.forEach(dd => { 
                        dd.directioncelldetails.forEach(d => {
                            if(d.pagecell && d.pagecell.cellname){
                                this.directionids[d.pagecell.cellname] = {
                                    direction:dd,
                                    detail:d,
                                }  
                                // var clickevants = document.querySelectorAll(`[id^="${d.pagecell.cellname}"]`);
                                // if(clickevants.length > 0 ){
                                //     clickevants.forEach(element => {
                                //         if(!element.hasAttribute('data-click-added')){
                                //             this.allevents.push(element);
                                //             element.addEventListener('click', (event)=>{this.onClick(event,dd,d)});
                                //             element.setAttribute('data-click-added', 'true');
                                //         }
                                //     });
                                // }
                            }
                        });
                    });
                }
            // })
        },
        routerchage(to){
            if(to != null && this.isvalidpage(to.name)){
                this.directions = [];
                this.directionids = [];
                var obj = {name:to.name}
                this.$http.post('Direction/getpageclickdirection', obj)
                    .then(response => {
                        this.directions = response.data;
                        this.setdirectiononclickevent();
                    }).catch(err => {
                        this.$bus.$emit('error', err)
                    });
            }
        },
        // destroyEventListener(){
        //     this.allevents.forEach(e => {
        //         e.removeEventListener('click', this.onClick)
        //     });
        //     this.allevents = [];
        //     this.$store.state.directionsdialogue = [];
        //     this.$store.state.currentdirection = null;
        // },
    },
    beforeDestroy() {
        // this.destroyEventListener();
  },
  mounted(){
  },
}
</script>
