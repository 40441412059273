import { render, staticRenderFns } from "./updatedatabasestructure.vue?vue&type=template&id=7a5dc900&scoped=true"
import script from "./updatedatabasestructure.vue?vue&type=script&lang=js"
export * from "./updatedatabasestructure.vue?vue&type=script&lang=js"
import style0 from "./updatedatabasestructure.vue?vue&type=style&index=0&id=7a5dc900&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7a5dc900",
  null
  
)

export default component.exports