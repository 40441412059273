<template>
    <div >
        <transition name="fade" mode="in-out">
            <div :ref="'DirectionsBackground'+myoption.uniqueid"  :id="'DirectionsBackgrounddirection'+myoption.uniqueid" :draggable="isdraggble" class="draggable Directionsbody direction_box_shadow" v-show="myoption.visible">
                <div class="row cursorstylemove" @mouseover="isdraggble = true">
                        
                    <el-tooltip :disabled="disabledtooltip" :content="myoption.heading" placement="bottom" effect="light">
                        <span class="D_Tpopupheadingstyle" ref="directionheader">{{myoption.heading}} </span>
                    </el-tooltip>
                    
                    <span>
                        <!-- <i class="right el-icon-error" @click="Correctclick" style="cursor: pointer;    margin-left: 0px;font-size: 36px;" ></i> -->
                        <!-- <el-button class="right " style="margin-left: 7px;" @click="Correctclick" icon="el-icon-error" circle></el-button> -->
                        <!-- <button class="right">close</button> -->
                    </span>
                    <el-button class="glyphicon glyphicon-circle-arrow-up arrowiconstyle" v-if="myoption.directiondegree != null" :style="'transform: rotate('+ myoption.directiondegree +'deg); transition: 0.5s linear;'" circle></el-button>
                    <span class="right padding8px">{{this.$store.state.currentdirectionindex + 1}} out of {{this.$store.state.directionsdialogue.length}} </span>
                </div>
                    <div class="row flex" >
                        <div class="Directionscoll1" @mouseover="isdraggble = false">
                            <p class="textbox" v-html="myoption.content"></p>
                        </div>
                        <div class="Directionscoll2"  v-if="myoption.mediacontent"  @mouseover="isdraggble = false" >
                            <div style="display: flex; align-items: center; justify-content: center; height: 100%;">
                                <span  v-if="myoption.mediacontent.name && myoption.mediacontent.name.substring(myoption.mediacontent.name.lastIndexOf('.') + 1).toLowerCase() == 'jpg' || myoption.mediacontent.name.substring(myoption.mediacontent.name.lastIndexOf('.') + 1).toLowerCase() == 'jpeg' || myoption.mediacontent.name.substring(myoption.mediacontent.name.lastIndexOf('.') + 1).toLowerCase() == 'gif' || myoption.mediacontent.name.substring(myoption.mediacontent.name.lastIndexOf('.') + 1).toLowerCase() == 'png'">
                                    <el-image class="width244px auto-height" :preview-src-list="[myoption.mediacontent.link]" id="infoimg" :src="myoption.mediacontent.link" />
                                </span>

                                <span v-if="myoption.mediacontent.name && myoption.mediacontent.name.substring(myoption.mediacontent.name.lastIndexOf('.') + 1).toLowerCase() == 'mp4'">
                                    <video controls class="previewbox bottom-margin-8px">
                                        <source :src="myoption.mediacontent.link" type="video/mp4">
                                        Your browser does not support the video tag.
                                    </video>
                                </span>
                            </div>
                        </div>
                    </div>

                    <div class="row margintop6px" >
                        <el-tooltip v-if="previouspage == true" effect="dark" content="Previous" placement="bottom">
                        <el-button  type="primary" @click="changepage('left')" 
                                    :disabled="!previouspage"
                                    icon="el-icon-arrow-left" 
                                    class="padding6px10px guidancdlgnextprebtn left">
                        </el-button>
                        </el-tooltip>

                        <div class="left  paddingLR10px" :class="myoption.cellactionname == 'Allclick'? 'fullwidth':'width-85percent'">
                            <span class="left" >
                                <div class="policylink" v-if="myoption.link && myoption.link != ''">
                                    <a :href="myoption.link" target="_blank" class="policylink" >  {{ myoption.link }}</a>
                                </div>
                            </span>
                            <!-- {{myoption.cellactionname}} -->
                            <div v-if="myoption">
                                <span  >
                                    <span class="right" >
                                        <el-button v-if="myoption.clickeventname != undefined"  type="success" @click="clickevent" >{{myoption.clickeventname}}</el-button>
                                        <el-button v-if="myoption.offercloseoption == 'error'" type="success" @click="Correctclick" >Correct</el-button>
                                        <el-button v-if="myoption.offercloseoption == 'warning'" type="success" @click="Ignoreclick">Ignore</el-button>
                                        <!-- <el-button v-if="myoption.offercloseoption == 'error'" type="success" @click="Correctclick" >Correct</el-button> -->
                                    </span>
                                </span>
                                <!-- <span class="right" v-else>
                                    
                                </span> -->
                            </div>
                        </div>

                        <el-tooltip v-if="nextpage == true" effect="dark" content="Next" placement="bottom">
                         <el-button  type="primary" :disabled="!nextpage"
                                     @click="changepage('right')" 
                                     icon="el-icon-arrow-right" 
                                     class="padding6px10px guidancdlgnextprebtn right">
                        </el-button>
                        </el-tooltip>
                    </div>
            </div>
        </transition>
    </div>
</template>

<script>

export default {
    name: 'directionsdialogue',
    props: { 
        myoption: {
            sectionname:String, 
            visible: Boolean,
            content:String,
            mediacontent:Object,
            boxtype:String,
            offercloseoption:String,
            heading:String,
            directiondegree:Number,
            isedit:Boolean,
            position:Object,
            clickevent:Function,
            clickeventname:String,
            cellactionname:String

        }
         },
    data() {
        return {
            dragstart:false,
            newcallback:null,
            directiondegree: 0,
            isdraggble: true,
            previouspage:null,
            nextpage:null, 
            disabledtooltip: true
        };
    },

    watch: {
        'myoption'(newQuestion, oldQuestion) {
            if (this.myoption.visible) {
            this.setpostion();
            this.adjustedFontSize;
            }
        }
    },
    computed:{
        hasPreviousContent() {
            return this.$store.state.currentdirectionindex > 0;
        },

        hasNextContent() {
            return this.$store.state.currentdirectionindex < this.$store.state.directionsdialogue.length  - 1;
        },

        adjustedFontSize() {
            const dh = this.$refs.directionheader;
            if (dh) {
                dh.style.width = "360px";
                const computedStyle = window.getComputedStyle(dh);
                const currentWidth = parseInt(computedStyle.width);
                
                let textWidth = this.getTextWidth(this.myoption.heading, 'bold 20px Helvetica');
                if (textWidth > currentWidth) {
                    this.disabledtooltip = false
                } else {
                    this.disabledtooltip = true;
                }
            }
            
            /*let fontSize = 20;
            const maxWidth = 380;
            
            while (textWidth > maxWidth) {
                fontSize -= 2;
                textWidth = this.getTextWidth(this.myoption.heading, `bold ${fontSize}px Helvetica`);
            }

            return fontSize + 'px'; */
        }
    },
    methods: {
        clickevent(){
            if(this.myoption.clickevent != undefined){
               this.myoption.clickevent()
            }
            this.myoption.visible = false;
        },
        chheckvaluenext(canadd){
            if(canadd == 1){
                this.$store.state.currentdirectionindex ++;
            }else{
                this.$store.state.currentdirectionindex --;
            }
            var dd =  this.$store.state.directionsdialogue[this.$store.state.currentdirectionindex];
            if(dd){
                if(dd.usedata != true){
                var node = document.getElementById(dd.elementid);
                //  var show = this.$training.RG_Direction_Match(node.value,dd.inc_pattern,dd.exc_pattern,dd.format_pattern);
                 var show = this.$directionutility.directionvaluematch(dd);
                    if(show == false){
                        return true;
                    }else{
                        return this.chheckvaluenext(canadd);
                    }
                }else{
                   dd.validatedirectionsingle(dd);
                   if(dd.value != undefined){
                    /*
                        var canshow = this.$training.RG_Direction_Match(dd.value,dd.inc_pattern,dd.exc_pattern,dd.format_pattern);
                        */
                        var canshow = this.$directionutility.directionvaluematch(dd);
                    }
                   if(canshow == false){
                        return true;
                    }else{
                        return this.chheckvaluenext(canadd);
                    }
                }
                 
            }
        }, 
        changepage(pagename){
            let isid = document.getElementById(this.myoption.elementid);  
            if (isid) {
                if(this.myoption.setcellbackground == undefined || this.myoption.setcellbackground == true){
                   isid.style.backgroundColor = 'yellow';
                }
                // let currentelementid = this.myoption.elementid.replace(/\d/g, '');
                // let elementidslist = document.querySelectorAll(`[id^="${currentelementid}"]`);

                // elementidslist.forEach(eid => {
                //     eid.style.backgroundColor = '';
                //     eid.style.color = '';
                // });
            }

            if(pagename == 'right'){
                 var isshow = this.chheckvaluenext(1);
                 if(isshow){
                    this.$nextTick(()=>{
                    this.$store.state.currentdirection = this.$store.state.directionsdialogue[this.$store.state.currentdirectionindex];
                    });
                 }
                this.previouspage =  this.hasPreviousContent;
                this.nextpage =  this.hasNextContent;
            }else{
                var isshow = this.chheckvaluenext(-1);
                if(isshow){
                    this.$nextTick(()=>{
                        this.$store.state.currentdirection = this.$store.state.directionsdialogue[this.$store.state.currentdirectionindex];    
                    });
                }
                this.previouspage =  this.hasPreviousContent;
                this.nextpage =  this.hasNextContent;
            }
            if(this.previouspage == false && this.nextpage == false){
                this.myoption = {};
                // this.$store.state.currentdirectionindex = 0;
            }
             
        },
        Ignoreclick(){
            if(this.myoption.isedit == false){
                //  this.ignoredirection(this.myoption.uniqueid,this.myoption.sectionname)
            }
            this.callcallback();
            this.Correctclick();
        },
        drag_start(event) {
            this.dragstart = true;
            var style = window.getComputedStyle(event.target, null);
            event.dataTransfer.setData(
                "text/plain",
                parseInt(style.getPropertyValue("left"), 10) -
                event.clientX +
                "," +
                (parseInt(style.getPropertyValue("top"), 10) - event.clientY)
            );
        },
        drag_over(event) {
            event.preventDefault();
            return false;
        },
        drop(event) {
            if(this.dragstart == true){
                this.dragstart = false;
                var offset = event.dataTransfer.getData("text/plain").split(",");
                this.$refs['DirectionsBackground'+this.myoption.uniqueid].style.left =
                    event.clientX + parseInt(offset[0], 10) + "px";
                this.$refs['DirectionsBackground'+this.myoption.uniqueid].style.top =
                    event.clientY + parseInt(offset[1], 10) + "px";
                event.preventDefault();

                return false;
            }
        },        
        makeDraggable() {
            var dm = this.$refs['DirectionsBackground'+this.myoption.uniqueid];
            var dropid = document.querySelector("#dialoguecontainer")
            if (dm) {
                dm.addEventListener("dragstart", this.drag_start, false);
                dropid.addEventListener("dragover", this.drag_over, false);
                dropid.addEventListener("drop", this.drop, false);
            }
        },
        callcallback(){
            if(this.newcallback != null){
              this.newcallback();
            }
        },
        Correctclick(){
            this.myoption.visible = false;
            this.newcallback = null;

            this.toggleElementHighlightByIds(this.$store.state.directionsdialogue, this.myoption.visible)
            // let isid = document.getElementById(this.myoption.elementid);
            // if (isid) {
                // let currentelementid = this.myoption.elementid.replace(/\d/g, '');
                // let elementidslist = document.querySelectorAll(`[id^="${currentelementid}"]`);
    
                // elementidslist.forEach(eid => {
                //     eid.style.backgroundColor = '';
                //     eid.style.color = '';
                // });
            // }
        },
        setpostion() {
            this.previouspage = this.hasPreviousContent;
            this.nextpage = this.hasNextContent;
            if (this.previouspage == null) {
                this.previouspage = this.hasPreviousContent;
            }
            if (this.nextpage == null) {
                this.nextpage = this.hasNextContent;
            }
            this.$nextTick(() => {
                this.toggleElementHighlightByIds(this.$store.state.directionsdialogue, this.myoption.visible)

                var isid = document.getElementById(this.myoption.elementid);
                var currentdirectionid = document.getElementById('DirectionsBackgrounddirection'+this.myoption.uniqueid);

                if (isid != null && this.myoption.visible) {
                    if(this.myoption.setcellbackground == undefined || this.myoption.setcellbackground == true){
                        isid.style.backgroundColor = '#afd6ff';
                    }
                    // let currentelementid = this.myoption.elementid.replace(/\d/g, '');
                    // let elementidslist = document.querySelectorAll(`[id^="${currentelementid}"]`);

                    // elementidslist.forEach(eid => {
                    //     eid.style.backgroundColor = 'yellow';
                    //     eid.style.color = 'black';
                    // });


                    isid.scrollIntoView();
                    
                    const self = this;
                    const position = isid.getBoundingClientRect();
                    const directionposition = currentdirectionid.getBoundingClientRect();
                    const pos = this.$training.getboxposition(position, directionposition);
                    self.$refs['DirectionsBackground' + this.myoption.uniqueid].style.left = pos.left;
                    self.$refs['DirectionsBackground' + this.myoption.uniqueid].style.right = pos.right;
                    self.$refs['DirectionsBackground' + this.myoption.uniqueid].style.top = pos.top;
                    self.$refs['DirectionsBackground' + this.myoption.uniqueid].style.bottom = pos.bottom;
                }
            });
        },
        handleScroll (event) {
            var isid = document.getElementById(this.myoption.elementid);  
            const self = this;
            this.$nextTick(()=>{
                if(isid != undefined){
                    const position =  isid.getBoundingClientRect(); 
                    const pos =  this.$training.getboxposition(position);
                    self.$refs['DirectionsBackground'+this.myoption.uniqueid].style.left = pos.left;
                    self.$refs['DirectionsBackground'+this.myoption.uniqueid].style.right = pos.right;
                    self.$refs['DirectionsBackground'+this.myoption.uniqueid].style.top = pos.top;
                    self.$refs['DirectionsBackground'+this.myoption.uniqueid].style.bottom = pos.bottom;
                }
            })
        // Any code to be executed when the window is scrolled
        },
        findscroltag(){
            var ElementsWithScrolls = (function() {
                var getComputedStyle = document.body && document.body.currentStyle ? function(elem) {
                    return elem.currentStyle;
                } : function(elem) {
                    return document.defaultView.getComputedStyle(elem, null);
                };

                function getActualCss(elem, style) {
                    return getComputedStyle(elem)[style];
                }

                function isXScrollable(elem) {
                    return elem.offsetWidth < elem.scrollWidth &&
                        autoOrScroll(getActualCss(elem, 'overflow-x'));
                }

                function isYScrollable(elem) {
                    return elem.offsetHeight < elem.scrollHeight &&
                        autoOrScroll(getActualCss(elem, 'overflow-y'));
                }

                function autoOrScroll(text) {
                    return text == 'scroll' || text == 'auto';
                }

                function hasScroller(elem) {
                    return isYScrollable(elem) || isXScrollable(elem);
                }
                return function ElemenetsWithScrolls() {
                    return [].filter.call(document.querySelectorAll('*'), hasScroller);
                };
            })();
        return ElementsWithScrolls();
        },

        getTextWidth(text, font) {
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');
            context.font = font;
            const width = context.measureText(text).width;
            canvas.remove();
            return width;
        },

        toggleElementHighlightByIds(elementIds, shouldHighlight) {
            elementIds.forEach(function (currentitem) {
                if(currentitem.setcellbackground == undefined || currentitem.setcellbackground == true){
                    var element = document.getElementById(currentitem.elementid);
                    if (element) {
                        var isElInputNumber = element && element.classList.contains('el-input-number');
                        if (shouldHighlight) {
                            if (isElInputNumber) {
                                var nestedElement = element.querySelector(".el-input__inner");
                                if (nestedElement) {
                                    nestedElement.style.backgroundColor = 'yellow';
                                    nestedElement.style.color = 'black';
                                }
                            } else {
                                element.style.backgroundColor = 'yellow';
                                element.style.color = 'black';
                                // element.classList.toggle('highlight');
                            }
                        } else {
                            if (isElInputNumber) {
                                var nestedElement = element.querySelector(".el-input__inner");
                                if (nestedElement) {
                                    nestedElement.style.backgroundColor = '';
                                    nestedElement.style.color = '';
                                }
                            } else {
                                element.style.backgroundColor = '';
                                element.style.color = '';
                            }
                        }
                    }
                }
            });
        }
    },
    mounted(){
    if (this.myoption.visible) {
        this.adjustedFontSize;
        this.$nextTick(()=>{
            this.setpostion();
        })
        window.addEventListener('resize', this.setpostion);
    }
    },
    unmounted () {
        window.removeEventListener('resize', this.setpostion);
        window.removeEventListener('scroll', this.handleScroll);
        var dd = this.findscroltag();
        dd.forEach(element => {
            element.removeEventListener('scroll', this.handleScroll);    
        });
    },
    created() { 
        var dd = this.findscroltag();
        dd.forEach(element => {
            element.addEventListener('scroll', this.handleScroll);    
        });
        window.addEventListener('scroll', this.handleScroll);
        this.$nextTick(this.makeDraggable);
    },
};
</script>

<style scoped>
.policylink{
    width: 250px;
    text-overflow: ellipsis !important;
    overflow: hidden;
    height: 23px;
    color: #18bfff !important;
    background-color: white !important;
    font-weight: bold;
    padding: 2px;
}
.policylink:hover{
    width: 250px;
    text-overflow: ellipsis !important;
    overflow: hidden;
    height: 23px;
    color: #18bfff !important;
    background-color: white !important;
    font-weight: bold;
    padding: 2px;
}
.Directionsbody {
    padding: 4px 10px 4px 10px;
    background-color: #d5e2e8;
    /* border: 1px solid; */
    width: 528px;
    height: 360px;
    top: 50%;
    left: 50%;
    position: fixed;
    transform: translate(-50%, -50%);
    opacity: 1;
    z-index: 5000;
    align-items: center;
    /* resize: none; */
}.row:after {
  content: "";
  display: table;
  clear: both;
}

/* #dlgbox {
    position: fixed;
    width: 90%;
    height: 50%;
    margin: auto;
    background-color: lightgray;
    z-index: 1;
    border-radius: 25px;
} */

/* .dlg-body {
    background-color: lightgray;
    color: black;
    font-size: 14px;
    padding-left: 58px;
    border: 4px solid black;
    min-height: 90%;
    border-radius: 25px;
} */







.textbox {
    margin: 0 0 0px;
}
.fade-enter-active {
    animation: fadein 0.5s;
    animation-iteration-count: 1;
}
.fade-leave-active {
    animation: fadeout 0.7s;
    animation-iteration-count: 1;
}
.margintop6px {
    margin-top: 6px;
}

@keyframes fadein {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fadeout {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}
.direction_box_shadow{
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    border-radius: 5px;
}
</style>
  
