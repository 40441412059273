<template>
    <div>
        <div class="fixdheader Base_Template_add_edit">
            <span class="headerstyle">Qu Times: Sub-Form Creator (Filter)</span>
            <span class="right">
                <el-button icon="el-icon-back" type="info" @click="$router.go(-1)">Back</el-button>
                <el-button :disabled="!editpermission" @click="save()" type="success">Save</el-button>
            </span>
            <el-row>
                <el-select v-model="qutimeselect" @change="changequtimelist(qutimeselect)"
                    placeholder="Select Qu Times">
                    <el-option v-for="item in qutimes" :key="item.id" :label="item.qutimename" :value="item.id">
                    </el-option>
                    <!-- <el-option :value="null">
                        
                    </el-option> -->
                </el-select>
                <el-button class="spacebetween_LR5" :disabled="!editpermission" @click="addnew()" type="primary">Add New
                </el-button>
            </el-row>
            <hr class="hrstyle" />
            <el-row v-if="isshow">
                <el-input class="qutime_input_width" placeholder="Qu Times Name" v-model="form.qutime.qutimename">
                </el-input>
                <el-select @change="getchecklistsection()" v-model="form.qutime.elementlisttemplateid"
                    class="spacebetween_LR5 qutime_input_width" placeholder="L3.Check List Name">
                    <el-option v-for="item in elementlisttemplate" :key="item.id" :label="item.projectname"
                        :value="item.id">
                    </el-option>
                </el-select>
                <el-popover placement="bottom-start" title="Open On" trigger="click">
                    <div>
                        <div class="left">
                            <el-table class="tableBox" :data="pages">
                                <el-table-column width="50" prop="page" label="Page">
                                    <template slot-scope="scope">
                                        <div style="height:24px">{{scope.row}}</div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                        <div class="left">
                            <el-table class="tableBox left" :data="pages">
                                <!-- <div v-for="item in actions" :key="item"> -->
                                <el-table-column width="50" :label="actions[0]">
                                    <template slot-scope="scope">
                                        <el-checkbox v-model="pageactionobj[scope.row+actions[0]]"
                                            @change="openoncheck($event,scope.row,actions[0])">
                                        </el-checkbox>
                                    </template>
                                </el-table-column>
                                <el-table-column width="50" :label="actions[1]">
                                    <template slot-scope="scope">
                                        <el-checkbox v-model="pageactionobj[scope.row+actions[1]]"
                                            @change="openoncheck($event,scope.row,actions[1])">
                                        </el-checkbox>
                                    </template>
                                </el-table-column>
                                <el-table-column width="50" :label="actions[2]">
                                    <template slot-scope="scope">
                                        <el-checkbox v-model="pageactionobj[scope.row+actions[2]]"
                                            @change="openoncheck($event,scope.row,actions[2])">
                                        </el-checkbox>
                                    </template>
                                </el-table-column>
                                <el-table-column width="50" :label="actions[3]">
                                    <template slot-scope="scope">
                                        <el-checkbox v-model="pageactionobj[scope.row+actions[3]]"
                                            @change="openoncheck($event,scope.row,actions[3])">
                                        </el-checkbox>
                                    </template>
                                </el-table-column>
                                <!-- </div> -->
                            </el-table>
                        </div>
                    </div>
                    <el-button slot="reference" type="primary" size="mini"> Open On </el-button>
                </el-popover>

                <el-select class="spacebetween_LR5" v-model="form.qutime.response" placeholder="Response Requirements">
                    <el-option
                        v-for="item in [{name:'Reminder Only',value:'reminderonly'},{name:'Must Complete',value:'mustcomplete'}]"
                        :key="item.value" :label="item.name" :value="item.value">
                    </el-option>
                </el-select>
                <el-select class="right" v-model="form.qutime.reminder" placeholder="Reminder Frequency">
                    <el-option
                        v-for="item in [{name:'First use (once)',value:'firstuse'},{name:'Everytime',value:'everytime'}]"
                        :key="item.value" :label="item.name" :value="item.value">
                    </el-option>
                </el-select>
            </el-row>
            <hr v-if="isshow" class="hrstyle" />
            <el-row v-if="form.elementsection.length > 0">
                <div>
                    <div class="var_crt_dark  left" style="width:100% !important">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Shown
                        In Project Checklists</div>
                </div>
            </el-row>
            <el-row v-if="form.elementsection.length > 0">
                <el-table :data="[{}]" class="tableBox-cell">
                    <el-table-column width="25"></el-table-column>
                    <el-table-column label="List"></el-table-column>
                    <el-table-column label="Answer / Library" align="left" width="200"></el-table-column>
                    <el-table-column label="Unit/UR(S)" width="65" align="left"></el-table-column>
                </el-table>
            </el-row>
        </div>
        <div class="Base_Template_add_edit">
            <el-row v-if="form.elementsection.length > 0">
                <el-table default-expand-all :show-header="false" ref="sectiontable" :data="form.elementsection"
                    class="fullwidth custom_padding tableBox section_table_header" size="mini"
                    :cell-style="mastertablecellClassName" :row-class-name="masterTableRowClassName">
                    <el-table-column type="expand" width="35">
                        <template slot-scope="pt" class="fullwidth">
                            <div v-if="pt.row.sectiondetails && pt.row.sectiondetails.length > 0">
                                <el-table default-expand-all :show-header="false" key="id" :data="pt.row.sectiondetails"
                                    class="fullwidth custom_padding tableBox" :cell-style="tablecellClassName"
                                    ref="sectiondetail">
                                    <el-table-column type="expand" width="35">
                                        <template slot-scope="Ascope">
                                            <div v-if="getChildren(Ascope.row).length > 0">
                                                <el-table default-expand-all :show-header="false"
                                                    :data="Ascope.row.children"
                                                    class="fullwidth custom_padding tableBox"
                                                    :cell-style="tablecellClassName" ref="Ascope">
                                                    <el-table-column type="expand" width="35">
                                                        <template slot-scope="Bscope">
                                                            <div v-if="getChildren(Bscope.row).length > 0">
                                                                <el-table default-expand-all :show-header="false"
                                                                    :data="Bscope.row.children"
                                                                    class="fullwidth custom_padding tableBox"
                                                                    :cell-style="tablecellClassName" ref="Bscope">
                                                                    <el-table-column type="expand" width="35">
                                                                        <template slot-scope="Cscope">
                                                                            <div
                                                                                v-if="getChildren(Cscope.row).length > 0">
                                                                                <el-table default-expand-all
                                                                                    :show-header="false" row-key="srno"
                                                                                    :data="getChildren(Cscope.row)"
                                                                                    class="fullwidth custom_padding tableBox"
                                                                                    :cell-style="tablecellClassName"
                                                                                    ref="Cscope">
                                                                                    <el-table-column width="35">
                                                                                    </el-table-column>
                                                                                    <el-table-column width="20">
                                                                                        <template slot-scope="scope">
                                                                                            <div
                                                                                                v-if="scope.row.qutimedetail">
                                                                                                <el-checkbox
                                                                                                    v-model="scope.row.qutimedetail.ischecked">
                                                                                                </el-checkbox>
                                                                                            </div>
                                                                                        </template>
                                                                                    </el-table-column>
                                                                                    <el-table-column prop="activity"
                                                                                        label="List">
                                                                                        <template slot-scope="scope">
                                                                                            <div
                                                                                                v-if="scope.row.rowtype === 'G' && scope.row.sourcelink">
                                                                                                <el-link type="primary"
                                                                                                    :href="scope.row.sourcelink"
                                                                                                    target="_blank"> {{
                                                                                                    scope.row.activity
                                                                                                    }} </el-link>
                                                                                            </div>
                                                                                            <div v-else>
                                                                                                {{ scope.row.activity }}
                                                                                            </div>
                                                                                        </template>
                                                                                    </el-table-column>
                                                                                    <el-table-column
                                                                                        label="Library/Answer"
                                                                                        align="left" prop="method"
                                                                                        width="200">
                                                                                        <template slot-scope="scope">
                                                                                            <div
                                                                                                v-if="scope.row.rowtype === 'D'">
                                                                                                {{ scope.row.method }}
                                                                                            </div>
                                                                                            <div
                                                                                                v-if="scope.row.rowtype === 'F' && scope.row.answer">
                                                                                                <el-dropdown>
                                                                                                    <span
                                                                                                        class="el-dropdown-link">
                                                                                                        Answer's
                                                                                                        <i
                                                                                                            class="el-icon-arrow-down el-icon--right"></i>
                                                                                                    </span>
                                                                                                    <el-dropdown-menu
                                                                                                        slot="dropdown">
                                                                                                        <div v-for="(itme, index) in getanswer(scope.row.answer)"
                                                                                                            :key="index">
                                                                                                            <el-dropdown-item
                                                                                                                divided>
                                                                                                                {{ itme
                                                                                                                }}
                                                                                                            </el-dropdown-item>
                                                                                                        </div>
                                                                                                    </el-dropdown-menu>
                                                                                                </el-dropdown>

                                                                                            </div>
                                                                                            <div
                                                                                                v-if="scope.row.rowtype === 'E'">
                                                                                                <el-tooltip class="item"
                                                                                                    effect="dark"
                                                                                                    :content="scope.row.answer"
                                                                                                    placement="left-start">
                                                                                                    <div
                                                                                                        class="ellipsiswords">
                                                                                                        {{
                                                                                                        scope.row.answer
                                                                                                        }}</div>
                                                                                                </el-tooltip>

                                                                                            </div>
                                                                                        </template>
                                                                                    </el-table-column>
                                                                                    <el-table-column prop="activity"
                                                                                        width="65" label="Unit/UR(S)">
                                                                                        <template slot-scope="scope">
                                                                                            <div
                                                                                                v-if="scope.row.rowtype === 'D'">
                                                                                                {{ scope.row.unit }}
                                                                                            </div>
                                                                                            <div
                                                                                                v-if="scope.row.rowtype === 'E' || scope.row.rowtype === 'F'">
                                                                                                {{ scope.row.URS }}
                                                                                            </div>
                                                                                        </template>
                                                                                    </el-table-column>
                                                                                </el-table>
                                                                            </div>
                                                                        </template>
                                                                    </el-table-column>
                                                                    <el-table-column width="20">
                                                                    </el-table-column>
                                                                    <el-table-column prop="activity" label="List">
                                                                        <template slot-scope="scope">
                                                                            <div class="sectionfont_C"> {{
                                                                            scope.row.activity }}
                                                                            </div>
                                                                        </template>
                                                                    </el-table-column>
                                                                    <el-table-column label="MOM / Answer" align="left"
                                                                        width="200">
                                                                    </el-table-column>
                                                                    <el-table-column label="Unit / UR(S)" prop="unit"
                                                                        width="100">
                                                                    </el-table-column>
                                                                </el-table>
                                                            </div>
                                                        </template>
                                                    </el-table-column>
                                                    <el-table-column width="20">
                                                    </el-table-column>
                                                    <el-table-column prop="activity" label="List">
                                                        <template slot-scope="scope">
                                                            <div class="sectionfont_B"> {{ scope.row.activity }} </div>
                                                        </template>
                                                    </el-table-column>
                                                    <el-table-column label="MOM / Answer" align="left" width="200">
                                                    </el-table-column>
                                                    <el-table-column label="Unit / UR(S)" prop="unit" width="100">
                                                    </el-table-column>
                                                </el-table>
                                            </div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column width="20">
                                    </el-table-column>
                                    <el-table-column prop="activity" label="List">
                                        <template slot-scope="scope">
                                            <div class="sectionfont_A"> {{ scope.row.activity }} </div>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="MOM / Answer" align="left" width="200"></el-table-column>
                                    <el-table-column label="Unit / UR(S)" prop="unit" width="100"></el-table-column>
                                </el-table>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="srno" width="30" style="font-size: small;font-weight: 600;">
                    </el-table-column>
                    <el-table-column label="List" prop="sectionname" :disabled="!editpermission"
                        style="font-size: small;font-weight: 500;">
                        <template slot-scope="scope">
                            {{ scope.row.sectionname }}
                        </template>
                    </el-table-column>
                    <el-table-column label="MOM / Answer" align="left" width="200"></el-table-column>
                    <el-table-column label="Unit / UR(S)" prop="unit" width="100"></el-table-column>
                </el-table>
            </el-row>
        </div>
        <div>

        </div>
    </div>
</template>
<script>
import moment from "moment";
export default {
    data() {
        return {
            isshow: false,
            pageactionobj: {},
            pages: ["Amos", "Project", "Bill", "Other"],
            actions: ["Open", "Import", "Export", "Other"],
            postform: {},
            elementlisttemplate: [],
            form: {
                qutime: {},
                pageactions: [],
                qutimedetails: [],
                elementsection: [],
            },
            qutimes: [],
            qutimeselect: "",
        }
    },
    computed: {
        getanswer() {
            return function (data) {
                if (data) {
                    var t = JSON.parse(data)
                    return t;
                } else {
                    return [];
                }
            }
        },
        dateformat() {
            return function (data) {
                if (data) {
                    var dd = new Date(data);
                    return moment(dd).format("DD-MMM-YYYY  hh:mm") //+ " " + moment().utc(data).format("hh:mm");
                } else {
                    return "";
                }
            }
        },
        getChildren() {
            return function (data) {
                if (!data.children || data.children.length == 0) {
                    return [];
                }
                return data.children;
            }
        },
    },
    methods: {
        addnew() {
            this.qutimeselect = "";
            this.postform.id = 0;
            this.getquform();
            this.isshow = true;
        },
        openoncheck(event, page, action) {
            if (event) {
                this.pageactionobj[page + action] = true;
            } else {
                this.pageactionobj[page + action] = false;
            }
            this.$nextTick(() => {
                var tt = JSON.parse(JSON.stringify(this.pageactionobj))
                this.pageactionobj = tt;
                this.pages = JSON.parse(JSON.stringify(this.pages))
                this.actions = JSON.parse(JSON.stringify(this.actions))
            });
        },
        changequtimelist(data) {
            if (this.qutimeselect == null || this.qutimeselect > 0) {
                this.isshow = true;
            } else {
                this.isshow = false;
            }
            if (data) {
                this.postform.id = data;
                this.getquform();
            } else {
                this.postform.id = 0;
                this.getquform();
            }
        },
        FormatChildren(data, action) {
            if (!data.children || data.children.length == 0) {
                return data;
            } else {
                data.children.forEach((c, index) => {
                    if (action == "get") {
                        if (c.qutimedetail && c.qutimedetail.id > 0) {
                         this.$nextTick(() => {
                            c.qutimedetail.ischecked = true;
                          });
                        }
                    }
                    if (action == "save") {
                        if (c.qutimedetail && c.qutimedetail.ischecked) {
                            c.qutimedetail.elementalistsectiondetailid = c.id;
                            this.form.qutimedetails.push(c.qutimedetail);
                        }
                    }
                    this.FormatChildren(c, action);
                });
            }
        },
        masterTableRowClassName({ row, rowIndex }) {
            return 'section-format';
        },
        mastertablecellClassName({ row, column, rowIndex, columnIndex }) {
            if (columnIndex == 6) {
                return {
                    "font-size": "12px"
                }
            }
        },
        tablecellClassName: function ({ row, column, rowIndex, columnIndex }) {
            if (row) {
                if (row.rowtype === 'A') {
                    if (columnIndex == 6) {
                        return {
                            background: "lightgray",
                            color: "black",
                            "font-size": "12px"
                        }
                    }
                    return {
                        background: "lightgray",
                        color: "black",
                    };

                } else if (row.rowtype === 'B') {
                    if (columnIndex == 6) {
                        return {
                            background: "#f0f9eb",
                            color: "black",
                            "font-size": "12px"
                        }
                    }
                    return {
                        background: "#f0f9eb",
                        color: "black"
                    };
                } else if (row.rowtype === 'C') {
                    if (columnIndex == 6) {
                        return {
                            background: "oldlace",
                            color: "black",
                            "font-size": "12px"
                        }
                    }
                    return {
                        background: "oldlace",
                        color: "black"
                    };
                } else if (row.rowtype !== 'A' && row.rowtype !== 'B' && row.rowtype !== 'C') {
                    if (columnIndex == 6) {
                        return {
                            background: "#f8f8ff",
                            color: "black",
                            "font-size": "12px"
                        }
                    }
                    return {
                        background: "#f8f8ff",
                        color: "black"
                    };
                }
            }
            return '';
        },
        save() {

            if (!this.form.qutime.qutimename) {
                this.$message({
                    showClose: true,
                    message: "Qu Time Name must be required!", type: "error"
                });
                return;
            }
            if (!this.form.qutime.elementlisttemplateid) {
                this.$message({
                    showClose: true,
                    message: "L3. Check List Name must be required!", type: "error"
                });
                return;
            }
            this.form.pageactions = [];
            this.pages.forEach(p => {
                this.actions.forEach(a => {
                    if (this.pageactionobj[p + a]) {
                        var obj = { pagename: p, actionname: a };
                        this.form.pageactions.push(obj)
                    }
                });
            });
            this.form.qutimedetails = [];
            this.form.elementsection.forEach(e => {
                e.sectiondetails.forEach(ed => {
                    this.FormatChildren(ed, 'save');
                }); 1
            });
            this.$http
                .post("ElementList/savequtimeform", this.form).then((response) => {
                    this.$message({
                        showClose: true,
                        message: "Qu Time saved successfully!", type: "success"
                    });
                    this.getqutimelist();
                    this.getchecklistsection();
                }).catch((err) => {
                    this.$bus.$emit("error", err);
                });
        },
        setpageactions() {
            this.pageactionobj = JSON.parse(JSON.stringify(new Object()));
            this.form.pageactions.forEach((x) => {
                this.pageactionobj[x.pagename + x.actionname] = true;
            });
            this.pages.forEach((p) => {
                this.actions.forEach((a) => {
                    if (!this.pageactionobj[p + a]) {
                        this.pageactionobj[p + a] = false;
                    } else {
                        this.pageactionobj[p + a] = true;
                    }
                })
            })
        },
        getquform() {
            this.post("ElementList/getqutimesform", this.postform).then((response) => {
                this.form.elementsection = response.data.elementsection;
                this.form.qutime = response.data.qutime;
                this.form.pageactions = response.data.pageactions;
                if (this.form.qutime.elementlisttemplateid == 0) {
                    this.form.qutime.elementlisttemplateid = null;
                }
                this.form.elementsection.forEach(e => {
                    e.sectiondetails.forEach(ed => {
                        this.FormatChildren(ed, 'get');
                    });
                });
                this.form.elementsection.sort(this.sortFunction);
                this.setpageactions()
            });
        },
        getchecklistsection(data) {
            this.postform.elementlisttemplateid = this.form.qutime.elementlisttemplateid;
            this.post("ElementList/getqutimeSD", this.postform).then((response) => {
                this.form.elementsection = response.data;
                this.form.elementsection.forEach(e => {
                    e.sectiondetails.forEach(ed => {
                        this.FormatChildren(ed, 'get');
                    });
                });
                this.form.elementsection.sort(this.sortFunction);
                // this.form.qutime = response.data.qutime;
            });
        },
        sortFunction(a, b) {
            if (a.srno > b.srno) return 1;
            if (a.srno < b.srno) return -1;
            return 0;
        },
        getqutimelist() {
            this.get("ElementList/getqutimelist").then((response) => {
                this.qutimes = response.data;
            });
        },
        getchecklisttemplate() {
            this.post("ElementList/getelementlisttemplatebytype?type=" + 3,).then((response) => {
                this.elementlisttemplate = response.data;
            });
        },
    },
    created() {
        this.getqutimelist();
        this.getchecklisttemplate();
    },
};
</script>
<style>
</style>
