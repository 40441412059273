<template>
  <div>
        <div >
        <span class="headerstyle">RFI Library</span>
        <el-row>
            <el-col :span="12">
                <el-select
                v-if="sectionfilteridval === undefined"
                v-model="sectionfilter"
                filterable
                clearable
                placeholder="Section"
                class="bold width260px"
                style="width: 252px"
                @change="changesection"
                >
                <el-option
                    v-for="item in rfisectionsfilter"
                    :key="item.id"
                    :label="item.section"
                    :value="item.id"
                >
                </el-option>
                </el-select>
            </el-col>
            <el-col :span="12">
                <el-button class="right" @click="AddtoProject" :disabled="!editpermission" type="success">Add to Project</el-button>
            </el-col>
        </el-row>

        <hr class="hrstyle" />

        </div>
    <el-row>
        <el-col :span="24">
    <!-- <div> -->
      <el-table
        @expand-change="expandchange"
        v-if="canshow"
        :data="tabledata"
        row-key="id"
        :expand-row-keys="expandedrows"
        :default-sort="{ prop: 'rfis.section', order: 'ascending' }"
        @sort-change="sortChange"
        align="left"
        :show-header="true"
      >
        <el-table-column type="expand">
          <template slot-scope="props">
            <el-descriptions
              v-if="props.row.subdata"
              title=""
              direction="vertical"
              size="small"
              :column="4"
              border
            >
              <el-descriptions-item label="Section">
                <div>
                  <div v-if="props.row.subdata.section == null">
                    {{ "General Items" }}
                  </div>
                  <div v-else>
                    {{ props.row.subdata.section }}
                  </div>
                </div>
              </el-descriptions-item>
              <el-descriptions-item label="Issue">{{
                props.row.subdata.issue
              }}</el-descriptions-item>
              <el-descriptions-item label="Author" :span="2">
                {{ props.row.createdbyuser.loginname }}
              </el-descriptions-item>
              <el-descriptions-item label="Required Action">
                {{ props.row.requiredaction }}
              </el-descriptions-item>
              <el-descriptions-item label="Consultation" :span="2">
                <el-tag size="small">{{
                  props.row.subdata.consultation
                }}</el-tag>
              </el-descriptions-item>
              <el-descriptions-item label="Documentlocation">{{
                props.row.subdata.documentlocation
              }}</el-descriptions-item>
              <el-descriptions-item label="Interim Action">{{
                props.row.subdata.interimaction
              }}</el-descriptions-item>
              <el-descriptions-item label="Snips / Files">
                <span style="width: 77%" class="left">
                  <span v-for="svi in props.row.subdata.ssv" :key="svi.id">
                    <el-popover
                      placement="bottom"
                      width="200"
                      v-model="svi.mousehover"
                      trigger="hover"
                    >
                      <div @click="showpreview(svi)">
                        <span class="previewbox" v-if="svi.extention == 'xlsx'">
                          <div class="UR-Link fontsize12">
                            {{ svi.name }}
                          </div>
                          <div class="center">
                            <i class="el-icon-document iconsize90"></i>
                          </div>
                        </span>
                        <span v-if="svi.extention">
                          <span
                            v-if="
                              svi.extention.startsWith('jpg') ||
                              svi.extention.startsWith('jpeg') ||
                              svi.extention.startsWith('gif') ||
                              svi.extention.startsWith('png')
                            "
                          >
                            <img :src="svi.link" class="previewbox" />
                          </span>
                          <span v-if="svi.extention.startsWith('mp4')">
                            <video controls class="previewbox">
                              <source :src="svi.url" type="video/mp4" />
                              Your browser does not support the video tag.
                            </video>
                          </span>
                          <span v-if="svi.extention.startsWith('mp3')">
                            <audio controls class="previewbox">
                              <source :src="svi.link" type="audio/ogg" />
                              <source :src="svi.link" type="audio/mpeg" />
                              Your browser does not support the audio element.
                            </audio>
                          </span>
                        </span>
                      </div>
                      <span
                        slot="reference"
                        class="UR-Link marginl5 marginright5px fontsize12"
                        @mouseenter="previewsnip(svi)"
                      >
                        <el-tag size="mini"> {{ svi.name }} </el-tag></span
                      >
                    </el-popover>
                  </span>
                </span>
              </el-descriptions-item>
            </el-descriptions>
            <!-- {{getsubdata(props.row)}} -->
          </template>
        </el-table-column>
        <el-table-column
          prop="section.section"
          sortable="custom"
          label="Sections"
          width="280"
        >
          <template slot-scope="scope">
            <div v-if="scope.row.section == null">
              {{ "General Items" }}
            </div>
            <div v-else>
              {{ scope.row.section.section }}
            </div>
          </template>
        </el-table-column>

        <el-table-column
          prop="issue"
          label="Issue"
          sortable="custom"
          width="300"
          class-name="el_coll_space_right"
        ></el-table-column>
        <el-table-column
            prop="interimaction"
            label="Interim Action"
            width="300"
            class-name="el_coll_space_right"
          ></el-table-column>
        <el-table-column
          prop="requiredaction"
          label="Required Action"
          width="300"
          class-name="el_coll_space_right"
        ></el-table-column>
        <el-table-column
          prop="createdbyuser.loginname"
          label="Author"
          width="60"
        ></el-table-column>
        <!-- <el-table-column label="Actions" align="center" width="110">
          <template slot-scope="scope">
            <el-button
              size="mini"
              class="padding7"
              type="primary"
              title="Edit"
              :icon="editpermission == true ? 'el-icon-edit' : 'el-icon-view'"
              @click="handleEdit(scope.$index, scope.row)"
            >
            </el-button>
            <el-button
              size="mini"
              class="padding7"
              type="primary"
              title="Copy"
              v-if="additionalfilter == false"
              icon="glyphicon glyphicon-duplicate"
              @click="handleCopy(scope.$index, scope.row)"
              :disabled="!editpermission"
            ></el-button>

            <el-button
                size="mini"
                class="padding7"
                type="danger"
                title="Delete"
                icon="el-icon-delete"
                @click="handleDelete(scope.$index, scope.row)"
                :disabled="!editpermission"
              ></el-button>
          </template>
        </el-table-column> -->
        <el-table-column width="40">
          <!-- <template slot="header">
            <span>
              <i
                class="glyphicon glyphicon-eye-close bqcodearchiveiconstyle"
              ></i>
            </span>
          </template> -->

          <template slot-scope="scope">
            <el-checkbox
              class="greencheckbox"
              @change="selectrfilibrary(scope.row, scope.$index)"
              v-model="scope.row.isselected"
            ></el-checkbox>
          </template>
        </el-table-column>
      </el-table>
      <el-row justify="space-around" type="flex">
        <el-col :span="6">
          <span style="width: 430px">
            <el-pagination
              class="pagesizeswidth textalignlpage paddingtop"
              @size-change="rowperpages"
              @current-change="refresh"
              :current-page.sync="view.pageno"
              :page-sizes="pagesizes"
              :page-size="view.pagesize"
              layout="sizes, prev, pager, next"
              :total="view.count"
            >
            </el-pagination>
          </span>
        </el-col>
      </el-row>
    </el-col>
    </el-row>
    <!-- </div> -->
  </div>
</template>
<script>
export default {
    props: {
        sectionfilterid: { type:  Number, default: undefined },
    },
  data() {
    return {
      sectionfilteridval:this.sectionfilterid,
      isrfiselect:false,
      selectrfilibraryarray:[],
      rfisectionsfilter:[],
      sectionfilter: "",
      expandedrows:[],
      canshow: true,
      tabledata: [],
      view: {
        pagesize: 20,
        filter: [
          { table: "rfi", column: "rfisectionid", operation:"=", isnull: false, value: "" },
          { table: "rfi", column: "issue", value: "" },
        ],
        sortcol: "rfis.section",
        sortorder: "descending",
    },
    pagesizes: this.$store.state.pagesizes,
    };
  },
  methods: {
    previewsnip(i) {
      i.link = i.link.trim();
      i.extention = i.name.substring(i.name.lastIndexOf(".") + 1).toLowerCase();
      if (i.link[i.link.length - 1] == "0") {
        i.link = `${i.link.substring(0, i.link.length - 1)}1`;
      }
      i.mousehover = true;
      i.externalurl = `${i.link.substring(0, i.link.length - 1)}0`;
      if (i.extention === "mp4") {
        i.url = `${i.link.substring(0, i.link.length - 4)}raw=1`;
      }
    },
    selectrfilibrary(data, index){
      if (data.isselected == true) {
                this.selectrfilibraryarray.push(data);
            } else {
                var ind = this.selectrfilibraryarray.findIndex(x => x.id == data.id);
                this.selectrfilibraryarray.splice(ind, 1);
            }
            if (this.selectrfilibraryarray.length == 0) {
                this.isrfiselect = false;
            } else {
                this.isrfiselect = true;
            }

    },
    AddtoProject(){
        this.$emit("addtoproject", this.selectrfilibraryarray);
    },
    getsections() {
      if (this.$store.state.rfisections.length <= 0) {
        this.getrifsections().then((data) => {
        //   this.rfisections = [];
          this.rfisectionsfilter = [];
        //   this.rfisections = JSON.parse(JSON.stringify(data));
          this.rfisectionsfilter = JSON.parse(JSON.stringify(data));
          this.rfisectionsfilter.unshift({ section: "General Items", id: 0 });
        //   this.rfisections.unshift({ section: "General Items", id: null });
        });
      } else {
        // this.rfisections = [];
        this.rfisectionsfilter = [];
        // this.rfisections = JSON.parse(
        //   JSON.stringify(this.$store.state.rfisections)
        // );
        this.rfisectionsfilter = JSON.parse(
          JSON.stringify(this.$store.state.rfisections)
        );
        this.rfisectionsfilter.unshift({ section: "General Items", id: 0 });
        // this.rfisections.unshift({ section: "General Items", id: null });
      }
    },
    changesection(event) {
      if (event === "") {
        this.view.filter[0].isnull = false;
        this.view.filter[0].value = "";
      } else {
        if (event === 0) {
          this.view.filter[0].isnull = true;
        } else {
          this.view.filter[0].isnull = false;
          this.view.filter[0].value = "" + event;
        }
      }
      this.refresh();
    },
    rowperpages: async function (val) {
      try {
        this.usergridrow.ugv.rowperpage = val;
        this.usergridrow = await this.$training.savepagelayout(
          this.usergridrow
        );
        this.view.pageno = 1;
        this.view.pagesize = val;
        this.refresh();
      } catch (error) {
        this.$bus.$emit("error", error);
      }
    },
    sortChange: function (sort) {
      if (sort.prop == "section.section") {
        this.view.sortcol = "rfis.section";
      } else if (sort.prop == "issue") {
        this.view.sortcol = "rfi.issue";
      } else {
        this.view.sortcol = sort.prop;
      }
      this.view.sortorder = sort.order;
      this.refresh();
    },
    expandchange(row ,expandedRows){
      var expanded = expandedRows.map((v) => { return v.id });
      this.expandedrows = expanded;
      if(row.subdata == undefined){
        this.showloading();
        this.$http
          .post("RFI/getrfilibrarybyid?isarchive="+this.additionalfilter, { id: row.id })
          .then((response) => {
            this.hideloading();
            this.$nextTick(()=>{
              this.$set(row,"subdata",response.data)
              // row.subdata = response.data;
              // row = JSON.parse(JSON.stringify(row));  
              // this.tabledata = JSON.parse(JSON.stringify(this.tabledata))
              this.$forceUpdate();
            })
           
          })
          .catch((err) => {
            this.$bus.$emit("error", err);
            this.hideloading();
          });
      }
    },
    refresh() {
      this.canshow = false;
      this.$http
        .post("rfi/getRFILibraryGrid?isarchive=" + false, this.view)
        .then((res) => {
          this.view = res.data.view;
          this.tabledata = res.data.data;
          this.canshow = true;
          // this.getAllUsers();
        })
        .catch((err) => {
          this.canshow = true;
          this.$bus.$emit("error", err);
        });
    },
  },
  created() {
    this.getsections();
    let gridrow = this.$training.getusergridrow(this.$route);
    this.view.pagesize = gridrow.ugv.rowperpage;
    this.usergridrow = gridrow;
    if(this.sectionfilteridval !== undefined){
        if(this.sectionfilteridval == null){
            this.sectionfilteridval = 0;
        }
        this.changesection(this.sectionfilteridval);
    }else{
      this.refresh();
    }
  },
};
</script>
<style>
 .greencheckbox .el-checkbox__input.is-checked .el-checkbox__inner,
    .el-checkbox__input.is-indeterminate .el-checkbox__inner {
        background-color: #00ff2a;
        border-color: #00fc0d;
    }
    .greencheckbox .el-checkbox__inner:hover {
        border-color: #00fc0d;
    }
</style>