<template>
    <div class="createamt">
        <div class="fixdheader padright8px">
            <span class="headerstyle">{{mode}} Automated Measurement Template</span>
            <div class="floatright margintb1mm marginright5px">
                    <el-button v-if="!fromamos " icon="el-icon-back" type="info" @click="$router.go(-1)">Back</el-button>
                    <el-button @click="cellchanged('save_amtlibrary',()=>{save(true)},false)" :disabled="!editpermission" type="success">Save</el-button>
            </div>

        <el-form ref="detailform" :model="detailform" size="mini">
            <el-row :gutter="5">            <el-col class="width290">
            <el-form-item prop="section">
                <el-select v-model="detailform.section" filterable placeholder="Section" class="fullwidth" @change="changed">
                    <el-option v-for="item in sections"
                               :key="item.section"
                               :label="item.section"
                               :value="item.section">
                    </el-option>
                </el-select>
            </el-form-item>
            </el-col>

            <el-col :span="3">
            <el-form-item prop="library">
                <el-select v-model="detailform.library" filterable placeholder="Library" @change="changed">
                    <el-option v-for="item in libraries"
                               :key="item"
                               :label="item"
                               :value="item">
                    </el-option>
                </el-select>
            </el-form-item>
            </el-col>

            <el-col class="width300px">
            <el-form-item prop="amtemplatename">
                <el-input :spellcheck="isspellcheck" :id="'library_amtemplatelibraryaddedit_templatename'" v-model="detailform.amtemplatename" ref="amtemplatename" placeholder="Template Name" @change="changed"></el-input>
            </el-form-item>
            </el-col>

            <el-col :span="4" class="paddingleft30px">
            <el-form-item label="Author" prop="author" v-if="detailform.author">
                {{ detailform.author.loginname }}
            </el-form-item>
            </el-col>
            </el-row>
            </el-form>
            <hr class="hrstyle" />
        </div>

            <!-- <div class="headerstyle">Nrm Sort Codes</div> -->
            <div class="tablepaddinglr3">

                <el-table :data="detailform.nrmsorts" size="mini">

                    <el-table-column prop="srno" label="123"
                                     width="55">
                        <template slot-scope="scope">
                            <span>{{scope.row.srno}}</span>
                        </template>
                    </el-table-column>

                    <el-table-column prop="bccid"
                                     label="Nrm Sort Code"
                                     width="700">
                        <template slot-scope="scope">
                            <el-autocomplete size="mini" class="inline-input fullwidth" popper-class="morewidth-autocomplete"
                                             v-model="scope.row.billcompilecode.description"
                                             :fetch-suggestions="querySearchbcc"
                                             placeholder=""
                                             :id="'library_amtemplatelibraryaddedit_nrmsortcode_'+scope.$index"
                                             :spellcheck="isspellcheck"
                                             @select="handleSelectBCC(scope.row, $event)"
                                             :select-when-unmatched="true"
                                             clearable
                                             value-key="description"
                                             @blur="bccblur(scope.row, $event)"
                                             :trigger-on-focus="true">
                                <template slot-scope="{ item }">
                                    <div style="white-space:normal;">{{ item.description }}</div>
                                </template>
                            </el-autocomplete>
                        </template>
                    </el-table-column>

                    <el-table-column align="center" label="Action"
                                     width="120">
                        <template slot-scope="scope">
                            <el-button title="Add New" icon="el-icon-circle-plus" :disabled="!editpermission" type="primary" @click="addNrmSortRow(scope.row, scope.$index)"></el-button>
                            <el-button title="Delete Row" size="mini" type="danger" icon="el-icon-delete"
                                       :disabled="!editpermission" @click="delNrmSortRow(scope.$index, scope.row)"></el-button>
                        </template>
                    </el-table-column>

                </el-table>
            </div>

            <!-- <el-form-item class="padright8px paddtop5">
                <el-button class="floatright" icon="el-icon-circle-plus" :disabled="!editpermission" type="primary" @click="addNrmSortRow()"></el-button>
            </el-form-item> -->
            <br>
            <!-- <div class="headerstyle">BQ Codes</div> -->
            <div class="tablepaddinglr3">

                <el-table :data="detailform.bqcodes"
                          class="fullwidth" size="mini">

                    <el-table-column prop="srno" label="123"
                                     width="55">
                        <template slot-scope="scope">
                            <!--<el-input v-model="scope.row.srno"></el-input>-->
                            <span>{{scope.row.srno}}</span>
                        </template>
                    </el-table-column>

                    <el-table-column prop="bqcodelibraryid"
                                     label="BQ Code"
                                     width="700">
                        <template slot-scope="scope">
                            <el-autocomplete size="mini" class="inline-input fullwidth" popper-class="morewidth-autocomplete"
                                             v-model="scope.row.bqcodelibrary.bqcode"
                                             :fetch-suggestions="querySearchbq"
                                             placeholder=""
                                             :id="'library_amtemplatelibraryaddedit_bqcode_'+scope.$index"
                                             :spellcheck="isspellcheck"
                                             @select="handleSelectBQ(scope.row, $event)"
                                             :select-when-unmatched="true"
                                             value-key="bqcode"
                                             @blur="bqcodeblur(scope.row, $event)"
                                             :trigger-on-focus="true">
                                <template slot-scope="{ item }">
                                    <div style="white-space:normal;">{{ item.bqcode }}</div>
                                    <div style="white-space:normal;font-size: x-small">{{ item.section }} | {{ item.group }} | {{ item.work }} | {{ item.level1 }} | {{ item.level2 }} | {{ item.level3 }}</div>
                                </template>
                                <el-button :disabled="!scope.row.bqcodelibrary.bqcode" slot="suffix" icon="el-icon-edit" @click="bqcodelibraryid=scope.row.bqcodelibraryid;projectbqcode=true" type="text" size="mini"></el-button>
                            </el-autocomplete>
                        </template>
                    </el-table-column>

                    <el-table-column label="Action" align="center"
                                     width="120">
                        <template slot-scope="scope">
                            <el-button title="Add New" icon="el-icon-circle-plus" :disabled="!editpermission" type="primary" @click="addbqcodeRow(scope.row, scope.$index)"></el-button>
                            <el-button title="Delete Row" size="mini" type="danger" icon="el-icon-delete"
                                       :disabled="!editpermission" @click="delbqcodeRow(scope.$index, scope.row)"></el-button>
                        </template>
                    </el-table-column>

                </el-table>
            </div>

                <!-- <el-form-item class="padright8px paddtop5">
                    <el-button icon="el-icon-circle-plus" class="floatright" :disabled="!editpermission" type="primary" @click="addbqcodeRow()"></el-button>
                </el-form-item> -->

                <!--<el-form-item label="">
                    <el-button @click="save()" :disabled="!editpermission" type="success" icon="el-icon-circle-check">Save</el-button>
                    <el-button v-if="!fromamos " icon="el-icon-back" type="info" @click="$router.go(-1)">Back</el-button>
                </el-form-item>-->
        <!-- </el-form> -->
        <el-dialog v-if="projectbqcode" title="BQ Code Library"
                   :visible.sync="projectbqcode"
                   width="90%"
                   append-to-body>
            <projectbqcode :projectsectionid="0"
                           :bqcodelibraryid="bqcodelibraryid"
                           :bqcodelibrary="true"
                           @closedialogue="closedialogue">
            </projectbqcode>
        </el-dialog>
    </div>
</template>

<script>
    import projectbqcode from './projectbqcode.vue';

    export default {
        data() {
            return {
                detailform: {},
                sections: this.$store.state.sections,
                libraries: this.$store.state.libraries,
                projectbqcode: false
            }
        },
        computed: {
            mode: function(){
                return this.detailform.id == 0 ? "Create" : "Edit";
            }
        },
        methods: {
            getsection(){
                if(this.detailform.section){
                    return this.detailform.section;
                }else{
                    return "";
                }
            },
            cellchanged(cellaction,callback,cancall){
            const app = this;
            this.$directionutility.setcomponent(app);
            if(cancall){
                callback();
                this.canmatchcellid(()=>{},cellaction,this.getsection());
            }else{
                this.canmatchcellid(callback,cellaction,this.getsection());
            }
        },
            save: function () {
                this.validationwarning(() => {
                    this.$http.post('amt/Save', this.detailform)
                        .then(response => {
                            this.$message({
                                showClose: true,
                                message: response.data, type: "success"
                            });
                            this.detailform.changed = false;
                            if (this.fromamos) {
                                this.$emit('closeamtemplatedialogue');
                            }
                            else {
                                this.$router.go(-1);
                            }
                        })
                        .catch(err => {
                            this.$bus.$emit('error', err);
                        });
                });
            },
            validationwarning: function (success) {
                var msg = [];
                if (!this.detailform.amtemplatename) {
                    msg.push("Template Name is required");
                }
                if (!this.detailform.section) {
                    msg.push("Section is required");
                }
                if (!this.detailform.library) {
                    msg.push("Library is required");
                }
                this.detailform.nrmsorts.forEach((nrm) => {
                    if (!nrm.bccid) {
                        msg.push("Nrm Sort Code is required for row no :" + nrm.srno);
                    }
                });

                this.detailform.bqcodes.forEach((bq) => {
                    if (!bq.bqcodelibraryid) {
                        msg.push("BQ Code is required for row no :" + bq.srno);
                    }
                });

                if (msg.length > 0) {
                    this.$alert(msg.join('<br />'), "Warning", { dangerouslyUseHTMLString: true, type: 'warning' });
                    //this.$confirm(msg.join('<br />'), "Warning", {
                    //    dangerouslyUseHTMLString: true,
                    //    confirmButtonText: 'Save',
                    //    cancelButtonText: 'Cancel',
                    //    type: 'warning'
                    //}).then(success).catch(() => {/*Cancel clicked*/ });
                } else {
                    success();
                }
            },
            focusEdit: function () {
                setTimeout(() => {
                    this.$refs.amtemplatename.focus();
                }, 100);
            },
            handleSelectBCC(item, value) {
                item.bccid = value.id;
                item.billcompilecode = value;
                //this.unitchanged(item);
                //console.log(item);
            },
            bccblur: function (item, event) {
                if (!item.billcompilecode.description) {
                    item.bccid = 0;
                    this.changed(null, item);
                }
            },
            querySearchbcc: function (query, done) {
                if (query == null)
                    query = '';
                this.$http.post('BillCompileCode/search?q=' + query, {})
                    .then(response => {
                        this.nrmsortcodes = JSON.parse(response.data);
                        done(this.nrmsortcodes)
                    })
                    .catch(err => {
                        this.$bus.$emit('error', err);
                    })
            },
            querySearchbq: function (query, done) {
                if (query == null)
                    query = '';
                this.$http.post('bqcodelibrary/search?library=' + this.detailform.library + '&q=' + query, {})
                    .then(response => {
                        this.bqcodes = JSON.parse(response.data);
                        done(this.bqcodes)
                    })
                    .catch(err => {
                        this.$bus.$emit('error', err);
                    })
            },
            handleSelectBQ(item, value) {
                item.bqcodelibraryid = value.id;
                item.bqcodelibrary = value;
                //item.unit = value.unit;
                //this.unitchanged(item);
                //console.log(item);
            },
            bqcodeblur: function (item, event) {
                if (!item.bqcodelibrary.bqcode) {
                    item.bqcodelibraryid = 0;
                    this.changed(null, item);
                }
            },
            addNrmSortRow: function (row, rowindex) {
                var item = { srno: parseInt(row.srno) + 1, billcompilecode: { description: '' } };
                this.detailform.nrmsorts.splice(rowindex + 1, 0, item)
                var newsrno = 1;
                this.detailform.nrmsorts.forEach(sn => {
                    sn.srno = newsrno++;
                    sn.srno = sn.srno > 9 ? sn.srno : "0" + sn.srno;
                });
                this.changed();
            },
            delNrmSortRow: function (scope, row) {
                this.detailform.nrmsorts.splice(row.srno - 1, 1);
                var srno = 1;
                for (var i = 0; i < this.detailform.nrmsorts.length; i++) {
                    var sn = this.detailform.nrmsorts[i].srno = srno++;
                    this.detailform.nrmsorts[i].srno = sn > 9 ? sn : "0" + sn;
                }
                if (this.detailform.nrmsorts.length == 0) {
                    this.addNrmSortRow({srno: 0}, 0);
                }
                this.changed();
            },
            addbqcodeRow: function (row, rowindex) {
                var item = { srno: parseInt(row.srno) + 1 };
                item.bqcodelibrary = { bqcode: '', tddimcalculations: [] };
                this.detailform.bqcodes.splice(rowindex + 1, 0, item)
                var newsrno = 1;
                this.detailform.bqcodes.forEach(sn => {
                    sn.srno = newsrno++;
                    sn.srno = sn.srno > 9 ? sn.srno : "0" + sn.srno;
                });
                this.changed();
            },
            delbqcodeRow: function (scope, row) {
                this.detailform.bqcodes.splice(row.srno - 1, 1);
                var srno = 1;
                for (var i = 0; i < this.detailform.bqcodes.length; i++) {
                    var sn = this.detailform.bqcodes[i].srno = srno++;
                    this.detailform.bqcodes[i].srno = sn > 9 ? sn : "0" + sn;
                }
                if (this.detailform.bqcodes.length == 0) {
                    this.addbqcodeRow({srno: 0}, 0);
                }
                this.changed();
            },
            refresh: function () {
                this.$http.post("amt/Get", this.detailform)
                    .then(response => {
                        var json = JSON.parse(response.data);
                        this.detailform = json;
                        this.detailform.nrmsorts.forEach(r => {
                            var sn = parseInt(r.srno);
                            r.srno = sn > 9 ? sn : "0" + sn;
                        });
                        this.detailform.bqcodes.forEach(r => {
                            var sn = parseInt(r.srno);
                            r.srno = sn > 9 ? sn : "0" + sn;
                        });
                    }).catch(err => {
                        this.$bus.$emit('error', err);
                    });
            },

            changed: function () {
                this.detailform.changed = true;
            },
            closedialogue: function () {
                this.projectbqcode = false;
            },
            appendTip: function (h, { column, $index }) {
                // Function(h, { column, $index })
                var content = ""
                if (column.property == "srno") {
                    content = "Renumber rows to change order";
                }
                else {
                    return column.label;
                }

                return h("span", [
                    column.label,
                    h(
                        "el-popover",
                        {
                            props: {
                                placement: "top",
                                // title: "hello",
                                // width: "200",
                                trigger: "hover",
                                content: content
                            }
                        },
                        [
                            h(
                                "i",
                                {
                                    slot: "reference",
                                    class: "el-icon-info",
                                    style: "color:#606266;margin-left:2px;"
                                },
                                ""
                            )
                        ]
                    )
                ]);
            }
        },
        created: function () {
            if (this.$route.query.id > 0) {
                this.detailform.id = this.$route.query.id;
                this.refresh();
            }
            else {
                if (this.amtemplateid) {
                    this.detailform.id = this.amtemplateid;
                    this.refresh();
                }
                else {
                    this.detailform = { id: 0, library: 'SMM7', nrmsorts: [], bqcodes: [], section: this.$store.state.section, author: {} };
                    if (this.$store.state.project.library) {
                        this.detailform.library = this.$store.state.project.library;
                    }
                    this.addNrmSortRow({srno: 0}, 0);
                    this.addbqcodeRow({srno: 0}, 0);
                    this.focusEdit();
                }
            }
        },
        components: {
            'projectbqcode': projectbqcode
        },
        props: {
            amtemplateid: Number,
            fromamos:Boolean
        }
    }
</script>

<style scoped>
    .el-form-item--mini.el-form-item, .el-form-item--small.el-form-item {
        margin-bottom: 0px;
    }
</style>
